import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ScaleToParentDirective} from './scale-to-parent.directive';

@NgModule({
  imports: [CommonModule],
  exports: [ScaleToParentDirective],
  declarations: [ScaleToParentDirective],
})
export class ScaleToParentModule {}
