import {Serializable} from 'common';

class SentenceInternal {
  value: string;

  author: string;

  constructor() {}
}

export class Sentence extends Serializable(SentenceInternal) {
  static createFromBackend(frase: any) {
    let s = new Sentence();
    s.value = frase.frase;
    s.author = frase.autor;

    return s;
  }
}
