import {updateImmutable} from '../util/immutable/update';

import {StepFlow} from './step-flow';
import {StepFlowLinkType} from './step-flow-link-type';

export abstract class StepsController implements Iterable<StepFlow> {
  protected constructor(private steps: Array<StepFlow>) {}

  public [Symbol.iterator](): Iterator<StepFlow> {
    return this.steps[Symbol.iterator]();
  }

  protected setStepValue(index: number, value: string): Array<StepFlow> {
    const newStep = updateImmutable(this.steps[index], {value: value});
    return this.updateStep(index, newStep);
  }

  protected setStepLabelKey(index: number, value: string): Array<StepFlow> {
    const newStep = updateImmutable(this.steps[index], {labelKey: value});
    return this.updateStep(index, newStep);
  }

  protected setStepLabel(index: number, value: string): Array<StepFlow> {
    const newStep = updateImmutable(this.steps[index], {label: value});
    return this.updateStep(index, newStep);
  }

  protected setStepColor(index: number, color: string): Array<StepFlow> {
    const newStep = updateImmutable(this.steps[index], {color: color});
    return this.updateStep(index, newStep);
  }

  protected setStepLinkType(index: number, type: StepFlowLinkType): Array<StepFlow> {
    const newStep = updateImmutable(this.steps[index], {link: type});
    return this.updateStep(index, newStep);
  }

  protected setStepBorderColor(index: number, color: string): Array<StepFlow> {
    const newStep = updateImmutable(this.steps[index], {borderColor: color});
    return this.updateStep(index, newStep);
  }

  protected setStepBackColor(index: number, color: string): Array<StepFlow> {
    const newStep = updateImmutable(this.steps[index], {backColor: color});
    return this.updateStep(index, newStep);
  }

  private updateStep(index: number, newStep: StepFlow): Array<StepFlow> {
    const newSteps = this.steps.clone();
    newSteps[index] = newStep;
    return newSteps;
  }
}
