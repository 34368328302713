import {Pipe, PipeTransform} from '@angular/core';
import {OrderType} from './order-type';

@Pipe({
  name: 'sort',
  pure: false,
})
export class SortPipe<T> implements PipeTransform {
  transform(array: T[], order?: OrderType, field?: keyof T | T | string): T[] {
    if (!Array.isArray(array)) {
      return array;
    }

    if (!!field) {
      const fieldParts: Array<string> =
        typeof field === 'string' ? field.split('.') : [];
      return array.slice().sort((a: T, b: T): number => {
        const valueA = this.getFieldValue(a, fieldParts);
        const valueB = this.getFieldValue(b, fieldParts);

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return this.compareStrings(valueA, valueB, order);
        }

        if (valueA === valueB) {
          return 0;
        }

        if (order === OrderType.ASC) {
          return valueA < valueB ? -1 : 1;
        } else {
          return valueA > valueB ? -1 : 1;
        }
      });
    } else {
      return array.slice().sort((a: T, b: T): number => {
        if (typeof a === 'string' && typeof b === 'string') {
          return this.compareStrings(a, b, order);
        }

        if (a === b) {
          return 0;
        }

        if (order === OrderType.ASC) {
          return a < b ? -1 : 1;
        } else {
          return a > b ? -1 : 1;
        }
      });
    }
  }

  private compareStrings(a: string, b: string, order?: OrderType): number {
    const valueA = a
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const valueB = b
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    if (order === OrderType.ASC) {
      return valueA.localeCompare(valueB);
    } else {
      return valueB.localeCompare(valueA);
    }
  }

  private getFieldValue(obj: any, fieldParts: Array<string>): any {
    let value = obj;

    for (const field of fieldParts) {
      value = value[field];
      if (value === undefined || value === null) {
        break;
      }
    }

    return value;
  }
}
