import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {InfoModule} from '../../info/info.module';
import {SelectableModeModule} from '../selectable-mode/selectable-mode.module';
import {ToggleButtonModule} from '../toggle-button/toggle-button.module';
import {TranslatableTextModule} from '../../i18n/translatable-text/translatable-text.module';

import {ChoiceRowComponent} from './choice-row.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InfoModule,
    ToggleButtonModule,
    SelectableModeModule,
    ReactiveFormsModule,
    TranslatableTextModule,
  ],
  exports: [ChoiceRowComponent],
  declarations: [ChoiceRowComponent],
})
export class ChoiceRowModule {}
