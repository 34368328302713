import {utf8_to_b64} from 'common';

/**
 * Represents Http basic authentication
 */
export class BasicAuth {
  /**
   * @param username the username
   * @param password the password
   * @returns the token
   */
  static getToken(username: string, password: string): string {
    return utf8_to_b64(username + ':' + password);
  }
}
