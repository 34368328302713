import {Injectable} from '@angular/core';
import {AbstractDao} from '../../common/model/abstract.dao';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {environment} from '~environments/environment';
import {addGeolocationHeaders} from '../../common/http/http';
import {State} from './state';
import {StateByGpsResponse} from './stateByGpsResponse';
import {LocationIpResponse} from './locationIpResponse';

@Injectable({
  providedIn: 'root',
})
export class StatesDao extends AbstractDao {
  constructor(protected http: HttpClient) {
    super();
  }

  getStateCodeByIp(): Observable<string> {
    return this.http.get<LocationIpResponse>(`${this.baseUrl}/location`).pipe(
      map((response: LocationIpResponse) => {
        return response?.country.toLowerCase() === environment.id
          ? response?.region
          : environment.geolocation.defaultAnonimousStateCode;
      }),
    );
  }

  getUserGeolocationByIp(): Observable<{latitude: number; longitude: number}> {
    return this.http.get<LocationIpResponse>(`${this.baseUrl}/location`).pipe(
      map((response: LocationIpResponse) => {
        return {latitude: response.latitude, longitude: response.longitude};
      }),
    );
  }

  getStateCodeByLocation(extras?: any): Observable<string> {
    let headers = new HttpHeaders();

    if (extras?.geolocation) {
      headers = addGeolocationHeaders(headers, extras.geolocation);
    }

    return this.http
      .get<StateByGpsResponse>(`${this.baseUrl}/users/location/gps`, {
        headers: headers,
      })
      .pipe(map((response: StateByGpsResponse) => response?.stateCode));
  }

  getNotify(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${this.baseUrl}/users/states/notifyme`);
  }

  notify(state: State): Observable<{status: string; message: string}> {
    return this.http.put<any>(`${this.baseUrl}/users/states/notifyme`, {
      stateCode: state.code,
    });
  }

  notifyAnonymous(
    state: State,
    email: string,
  ): Observable<{status: string; message: string}> {
    return this.http.put<any>(`${this.baseUrl}/states/notifyme`, {
      stateCode: state.code,
      mail: email,
    });
  }
}
