<div *ngIf="src && !error; else initialsCircle" class="wrapper">
  <img
    class="img-profile rounded-circle"
    [loading]="lazy ? 'lazy' : undefined"
    [src]="src"
    (error)="error = true" />
</div>
<ng-template #initialsCircle>
  <div class="square-responsive">
    <div class="square-responsive-content">
      <table>
        <tr>
          <td>{{ initials }}</td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>
