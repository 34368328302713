import {Serializable} from 'common';

class IconMetadataInternal {
  type: 'ball' | 'star' | 'label' | 'text' | 'image';

  backgroundColor: string;

  textColor: string;

  borderColor: string;

  content: string;

  url: string;
}

export class IconMetadata extends Serializable(IconMetadataInternal) {
  static createFromBackend(obj: any) {
    let i = new IconMetadata();

    i.type = obj.type;
    i.backgroundColor = obj.backgroundColor;
    i.textColor = obj.textColor;
    i.borderColor = obj.borderColor;
    i.content = obj.content;

    return i;
  }
}
