import {
  animate,
  AnimationTransitionMetadata,
  style,
  transition,
} from '@angular/animations';

export function slideFromTop(
  width: string,
  duration = 500,
): Array<AnimationTransitionMetadata> {
  return [
    transition(':enter', [
      style({transform: `translateY(${width})`}),
      animate(`${duration}ms ease`),
    ]),
    transition(':leave', [
      animate(`${duration}ms ease`, style({transform: `translateY(${width})`})),
    ]),
  ];
}
