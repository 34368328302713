import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';

import {FailedWithdrawals} from './failed-withdrawals';

@Injectable({
  providedIn: 'root',
})
export class FailedWithdrawalsService extends AbstractObservableDataService<
  Array<FailedWithdrawals>
> {
  constructor() {
    super();
  }
}
