import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../common/model/abstract.dao';

@Injectable({providedIn: 'root'})
export class InstallationDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  create(campaign?: string, source?: string): Observable<number> {
    let body = new HttpParams()
      .append(
        'jsonInfo',
        JSON.stringify({
          af_status: campaign ? 'Non-organic' : 'Organic',
          media_source: source ? source : 'Organic',
          campaign: campaign,
        }),
      )
      .append('tipo', 'WEB');
    return this.http.post<number>(this.baseUrl + '/installation', body.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
}
