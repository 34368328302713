import {
  Comparable,
  Serializable,
  SerializableProperty,
  SerializableType,
} from 'common';
import {isFuture, isToday} from 'date-fns';

import {ShipmentInfo} from '../../../shipment/data/shipment-info';
import {GenericBet} from '../../bet/data/generic-bet';
import {Club} from '../../clubs/data/club';
import {GenericResult} from '../../results/data/generic-result';
import {Share} from '../../share/data/share';

import {PendingPrizeAppInfo} from './pending-prize-app-info';
import {Subscription} from './subscription';
import {TicketOptions} from './ticket-options';

class GenericTicketInternal implements Comparable<GenericTicket> {
  id: number;

  name: string;

  customName: string;

  hash: string;

  date: number;

  orderDate: number;

  raffleDates: Array<number>;

  archiveDate: number;

  /**
   * Contains the price and additional relevant information which backend wants
   * to add.
   *
   * @example 1€ (50%)
   */
  priceString: string;

  /**
   * It's the price of your participation in the ticket right now.
   *
   * @example You buy a ticket of 1€, then price = 1€, if you share 50% with
   * any of you friends, price will be 0.5€
   *
   */
  price: number;

  /**
   * It's the initial price of your participation.
   *
   * @example
   * You buy a club for 1€, so paidPrice = 1€, but your participation is only
   * 0.67€ so, price = originalPrice = 0.67€.
   * Now you share half of your ticket, then:
   * paidPrice = 1€,
   * price= 0.337€,
   * originalPrice=0.67€.
   */
  originalPrice: number;

  /**
   * It's the price you pay when you buy the ticket.
   *
   * @example Example with Primitiva:
   * Ticket: 1€
   * Club:   1€ (In this case, it's you club participation)
   */
  paidPrice: number;

  temporalPrize: number;

  prize: number;

  html: string;

  imgTicket: string;

  imgDefault: string;

  uiFromGame: string;

  lotteryTicketImages: Array<string>;

  /**
   * It's the string which is replaced by play combination in the ticket.
   *
   * @example "Jornada no iniciada"
   */
  status: string;

  participations: number;

  @SerializableProperty(GenericBet, SerializableType.OBJECT)
  bet: GenericBet;

  /**
   * Stores the bet of the ticket as it comes from backend, not parsed here for
   * efficiency, only used when repeating tickets to rebuild the bet and
   * calculate the price.
   */
  fullBet: any;

  @SerializableProperty(GenericResult, SerializableType.OBJECT)
  result: GenericResult;

  @SerializableProperty(TicketOptions, SerializableType.OBJECT)
  options: TicketOptions;

  @SerializableProperty(Subscription, SerializableType.OBJECT)
  subscription: Subscription;

  proof: string;

  groupId: number;

  @SerializableProperty(Club, SerializableType.OBJECT)
  club: Club;

  /**
   * Owner of the original shared ticket.
   */
  @SerializableProperty(Share, SerializableType.OBJECT)
  share: Share;

  /**
   * Recipients of this shared ticket.
   */
  @SerializableProperty(Share)
  shares: Array<Share>;

  @SerializableProperty(ShipmentInfo, SerializableType.OBJECT)
  shipmentInfo: ShipmentInfo;

  /**
   * Information about big prices
   */
  @SerializableProperty(PendingPrizeAppInfo, SerializableType.OBJECT)
  pendingPrizeAppInfo: PendingPrizeAppInfo;

  /**
   * When has value, it indicates a check is needed in order to show/hide scrutiny
   * button after raffle is finished.
   * {@see TicketManagerService.updateTicketWhenRaffleFinished for further details}
   *
   * It will only have value after prizes are ready.
   *
   * Once scrutiny is ready and ticket info is updated, this field won't have value.
   */
  resultIdWhenRaffleFinished: number;

  compareTo(o: GenericTicket): number {
    if (this.date !== o.date) {
      return this.date - o.date;
    } else if (this.bet.gameId !== o.bet.gameId) {
      return this.bet.gameId.localeCompare(o.bet.gameId);
    } else {
      return this.id - o.id;
    }
  }

  isActive(): boolean {
    return this.raffleDates.some(d => isToday(d) || isFuture(d));
  }

  isExternal(): boolean {
    return this.bet.external;
  }

  isGroup(): boolean {
    return !!this.groupId;
  }

  isValidated(): boolean {
    return !!this.bet.validationDate;
  }

  isSubscribed(): boolean {
    return !(!this.subscription || !!this.subscription?.endDate);
  }
}

export class GenericTicket extends Serializable(GenericTicketInternal) {
  static createFromBackend(obj: any) {
    let ticket = new GenericTicket();

    ticket.id = obj.id;
    ticket.name = obj.descNombre;
    ticket.customName = obj.customName;
    ticket.hash = obj.hash;
    ticket.date = obj.fechaSorteo;
    ticket.orderDate = obj.fechaPedido;
    ticket.raffleDates = obj.fechasSorteo;
    ticket.archiveDate = obj.fechaArchivado;
    ticket.priceString = obj.descPrecio;
    ticket.price = obj.precio;
    ticket.originalPrice = obj.precioOriginal;
    ticket.paidPrice = obj.precioPagado;
    ticket.temporalPrize = obj.premioProvisional;
    ticket.prize = obj.premio;
    ticket.imgTicket = obj.imagenMini || obj.imagenMiniSVG;
    ticket.imgDefault = obj.imagenType || obj.imagenTypeSVG;
    ticket.uiFromGame = obj.useDescriptorUI;
    ticket.html = obj.boletoHtml;
    ticket.result = GenericResult.createFromBackend(obj.sorteo);
    ticket.options = TicketOptions.createFromBackend(obj.config);
    ticket.proof = obj.proof;
    ticket.groupId = obj.groupId;
    ticket.status = obj.status;
    ticket.participations = obj.numParticipaciones;

    ticket.resultIdWhenRaffleFinished = obj.invalidateWhenSorteoFinished;

    ticket.share = obj.compartidoPor
      ? Share.createFromBackend(obj.compartidoPor)
      : undefined;

    ticket.shipmentInfo = ShipmentInfo.createFromBackend(obj);

    if (obj.comparticiones && obj.comparticiones.length > 0) {
      ticket.shares = obj.comparticiones.map(share =>
        Share.createFromBackend(share),
      );
    }

    ticket.subscription = obj.abono
      ? Subscription.createFromBackend(obj.abono)
      : undefined;

    ticket.club = obj.penya ? Club.createFromBackend(obj.penya) : undefined;

    if (obj.imagenes && obj.imagenes.length > 0) {
      ticket.lotteryTicketImages = obj.imagenes;
    }

    ticket.bet = obj.apuesta ? GenericBet.createFromBackend(obj.apuesta) : undefined;
    ticket.fullBet = obj.apuesta.betInfo;

    ticket.pendingPrizeAppInfo = obj.pendingPrizeAppInfo
      ? PendingPrizeAppInfo.createFromBackend(obj.pendingPrizeAppInfo)
      : undefined;

    return ticket;
  }
}
