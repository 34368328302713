import {SelectableWeekday, SelectableWeekdayId} from './day-week-selector.types';

export class DayWeekSelectorUtils {
  static generateDaysMap(): Map<SelectableWeekdayId, SelectableWeekday> {
    const ids: SelectableWeekdayId[] = [
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Sun',
    ];

    const names = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    const weekdaysMap = new Map<SelectableWeekdayId, SelectableWeekday>();

    ids.forEach((id, i) =>
      weekdaysMap.set(id, {
        id: id,
        text: {
          xs: {
            key: `games.play.checkSubscription.subscribeDaysAliasShort.${names[i]}`,
          },
          sm: {
            key: `games.play.checkSubscription.subscribeDays.${names[i]}`,
          },
          lg: {
            key: `games.play.checkSubscription.subscribeDaysAlias.${names[i]}`,
          },
        },
        disabled: true,
      }),
    );

    return weekdaysMap;
  }
}
