import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Logger, UriMapper} from 'common';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SwRedirectGuard implements CanActivate {
  constructor(
    private logger: Logger,
    private router: Router,
    private uriMapper: UriMapper,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    try {
      const url = new URLSearchParams(state.url.split('?')[1]);

      if (url.has('url')) {
        return this.uriMapper.map(url.get('url')).pipe(
          catchError(() => {
            this.logger.warn(`Push redirect error: failed to map ${state.url}`);
            this.navigateHome();
            return of(false);
          }),
          map(dest => {
            if (dest) {
              this.router.navigate([dest]);
            } else {
              this.logger.warn(`Push redirect error: url not found ${state.url}`);
              this.navigateHome();
            }

            return false;
          }),
        );
      }
    } catch (e) {
      this.logger.warn(`Push redirect error: unable to redirect to ${state.url}`, e);
    }

    this.navigateHome();
  }

  private navigateHome(): void {
    this.router.navigate(['/']);
  }
}
