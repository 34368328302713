export class GoogleContact {
  constructor(
    public name: string,
    public phones: Array<string>,
    public initials?: string,
  ) {
    if (!initials && name.length > 0) {
      const chunks = name.trim().split(' ');
      this.initials = chunks[0].charAt(0) + (chunks[1] ? chunks[1].charAt(0) : '');
    }
  }

  public static fromJSON(json: any): GoogleContact {
    if (typeof json === 'string') {
      return JSON.parse(json, GoogleContact.reviver) as GoogleContact;
    } else if (json !== undefined && json !== null) {
      return Object.assign(Object.create(GoogleContact.prototype), json);
    } else {
      return json;
    }
  }

  public static reviver(key: string, value: any): any {
    return key === '' ? GoogleContact.fromJSON(value) : value;
  }

  toJSON() {
    return Object.assign({}, this);
  }
}
