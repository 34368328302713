import {Serializable} from 'common';

class TicketGroupInternal {
  id: number;
}

export class TicketGroup extends Serializable(TicketGroupInternal) {
  static createFromBackend(obj: any): TicketGroup {
    let t = new TicketGroup();
    TicketGroup.fillFromBackend(t, obj);
    return t;
  }

  protected static fillFromBackend(t: TicketGroup, obj: any) {
    t.id = obj.id;
  }
}
