import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HTTP_DEFAULT_HEADERS, HTTP_ENDPOINT} from './config-tokens';

@Injectable()
export class DefaultRequestInterceptor implements HttpInterceptor {
  constructor(
    @Inject(HTTP_ENDPOINT) private endpoint: string,
    @Inject(HTTP_DEFAULT_HEADERS) private defaultHeaders: Record<string, string>,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf(this.endpoint) >= 0) {
      let def = req.headers;
      Object.keys(this.defaultHeaders).forEach(key => {
        if (!req.headers.has(key)) {
          def = def.set(key, this.defaultHeaders[key]);
        }
      });
      return next.handle(req.clone({headers: def}));
    }

    return next.handle(req);
  }
}
