import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {InfoModule} from '../../../info/info.module';
import {SanitizerModule} from '../../../sanitizer/sanitizer.module';
import {FormRowModule} from '../form-row.module';
import {FormSubRowComponent} from './form-sub-row.component';
import {UtilModule} from '../../../util/util-module';
import {TranslatableTextModule} from '../../../i18n/translatable-text/translatable-text.module';
import {ResponsiveUtilsModule} from '../../../responsive/responsive-utils.module';

@NgModule({
  imports: [
    CommonModule,
    FormRowModule,
    InfoModule,
    ResponsiveUtilsModule,
    SanitizerModule,
    UtilModule,
    TranslatableTextModule,
  ],
  exports: [FormSubRowComponent],
  declarations: [FormSubRowComponent],
})
export class FormSubRowModule {}
