/**
 * Map that save values in both directions, it's not intended for duplicated
 * keys.
 */
export class BidirectionalMap<T> implements Map<T, T> {
  private map: Map<T, T>;

  /**
   * Map contructor
   *
   * @param iterable default values
   * @param bidirectionalValues If true indicates default values are
   *     in both directions, false indicates the constructor will put in both
   *     directions.
   */
  constructor(iterable: any, bidirectionalValues: boolean = false) {
    if (bidirectionalValues) {
      this.map = new Map(iterable);
    } else {
      this.map = new Map();
      if (Array.isArray(iterable)) {
        iterable.forEach(v => {
          this.set(v[0], v[1]);
          this.set(v[1], v[0]);
        });
      } else if (iterable !== undefined) {
        throw new TypeError('Invalid Map');
      }
    }
  }

  /**
   * @inheritDoc
   */
  set(key: T, key2: T) {
    this.map.set(key, key2);
    this.map.set(key2, key);
    return this;
  }

  /**
   * @inheritDoc
   */
  clear(): void {
    this.map.clear();
  }

  /**
   * @inheritDoc
   */
  forEach(callbackfn: (value: T, key: T, map: Map<T, T>) => void, thisArg?: any) {
    this.map.forEach(callbackfn, thisArg);
  }

  /**
   * @inheritDoc
   */
  get(key: T): T | undefined {
    return this.map.get(key);
  }

  /**
   * @inheritDoc
   */
  has(key: T): boolean {
    return this.map.has(key);
  }

  get size() {
    return this.map.size;
  }

  // noinspection JSUnusedGlobalSymbols
  get [Symbol.toStringTag]() {
    return this.map[Symbol.toStringTag];
  }

  /**
   * @inheritDoc
   */
  delete(key: T): boolean {
    if (this.has(key)) {
      const v = this.get(key);
      this.map.delete(key);
      this.map.delete(v);
      return true;
    } else {
      return false;
    }
  }

  /**
   * @inheritDoc
   */
  [Symbol.iterator](): IterableIterator<[T, T]> {
    return this.map[Symbol.iterator]();
  }

  /**
   * @inheritDoc
   */
  entries(): IterableIterator<[T, T]> {
    return this.map.entries();
  }

  /**
   * @inheritDoc
   */
  keys(): IterableIterator<T> {
    return this.map.keys();
  }

  /**
   * @inheritDoc
   */
  values(): IterableIterator<T> {
    return this.map.values();
  }

  /**
   * @inheritDoc
   */
  toJSON(): any {
    return this.map.toJSON();
  }
}
