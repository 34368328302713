import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '~environments/environment';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  constructor(@Inject('window') private window: Window) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(event => {
        if (event instanceof HttpErrorResponse && event.status === 503) {
          this.window.location.href = environment.maintenance.redirectUrl;
        }
        return throwError(() => event);
      }),
    );
  }
}
