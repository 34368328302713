import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, distinctUntilChanged, map, shareReplay, switchMap} from 'rxjs';

import {LocaleService, Logger, TranslationsService} from 'common';

import {StateAppDataService} from '../states/data/state-app-data.service';
import {State} from '../states/data/state';
import {environment} from '~environments/environment';

@Injectable()
export class TranslationsUsService extends TranslationsService {
  private readonly STATE_CODE = 'NY';

  private readonly replaceKeys: Map<string, string> = new Map([
    [
      'games.upcomingGames.gameRow.button.play',
      'games.upcomingGames.gameRow.button.order',
    ],
    ['games.play.playBottomBar.cta', 'games.upcomingGames.gameRow.button.order'],
    [
      'games.play.dialogConfirmPlay.title',
      'games.play.dialogConfirmPlay.titleOrder',
    ],
    ['games.play.buttonPlay', 'games.play.buttonOrder'],
    [
      'payments.load.success.actionFinishPlay',
      'payments.load.success.actionFinishOrder',
    ],
    ['kyc.kycOk.content', 'kyc.kycOk.contentNY'],
    ['kyc.kycOk.contentUsa', 'kyc.kycOk.contentNY'],
    ['kyc.kycOk.accept', 'kyc.kycOk.acceptNY'],
    [
      'kyc.steps.verifyIdentity.selfie.confirmationBarcode.playNow',
      'kyc.kycOk.acceptNY',
    ],
  ]);
  private shouldReplaceKeys$: Observable<boolean>;

  constructor(
    http: HttpClient,
    localeService: LocaleService,
    logger: Logger,
    stateAppDataService: StateAppDataService,
  ) {
    super(environment, http, localeService, logger);
    const stateCode$ = stateAppDataService.getData().pipe(
      map((state: State) => state?.code),
      distinctUntilChanged(),
    );

    this.shouldReplaceKeys$ = stateCode$.pipe(
      map((stateCode: string) => stateCode === this.STATE_CODE),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  protected getKeyTranslation(key: string) {
    return this.shouldReplaceKeys$.pipe(
      map((shouldReplace: boolean) =>
        shouldReplace && this.replaceKeys.has(key) ? this.replaceKeys.get(key) : key,
      ),
      switchMap((translationKey: string) => super.getKeyTranslation(translationKey)),
    );
  }
}
