export class Slugger {
  public static toUrl(id: string) {
    return id
      .trim()
      .toLowerCase()
      .replace(/[\W^_]+/g, '-');
  }

  public static toId(sluggedName: string) {
    return sluggedName.replace(/[\W^_]+/g, '_').toUpperCase();
  }
}
