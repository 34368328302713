import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logger} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {FormService} from './form/form.service';
import {LotteryFormService} from './form/lottery-form.service';
import {SelectionFormService} from './form/selection-form.service';
import {PlayDao} from './play.dao';
import {addGeolocationHeaders} from '../../../common/http/http';
import {GameId} from '../../data/game-id';

// TODO · ADAPTER · remove
@Injectable()
export class PlayDaoAdapter extends PlayDao {
  constructor(protected http: HttpClient, logger: Logger) {
    super(http, logger);
  }

  play(formService: FormService, extras?: any): Observable<unknown> {
    if (!formService.gameMetadata.upgrade) {
      return super.play(formService, extras);
    }

    this.measurePlayTime();

    const adaptedData = this.adaptFormService(formService);
    let headers = new HttpHeaders();

    if (extras?.geolocation) {
      headers = addGeolocationHeaders(headers, extras.geolocation);
    }

    if (formService instanceof LotteryFormService) {
      return this.http.post(
        this.baseUrl + '/users/play/loteria',
        JSON.stringify(adaptedData),
        {headers: headers},
      );
    } else if (formService.form.value.promo) {
      return this.playPromo(adaptedData, headers);
    } else {
      return this.http.post(
        this.baseUrl + '/users/play',
        JSON.stringify(adaptedData),
        {headers: headers},
      );
    }
  }

  saveUnfinishedPlay(formService: FormService): Observable<void> {
    if (!formService.gameMetadata.upgrade) {
      return super.saveUnfinishedPlay(formService);
    }

    const adaptedData = this.adaptFormService(formService);

    if (formService instanceof LotteryFormService) {
      return this.http
        .post(
          this.baseUrl + '/users/unfinish/jugar/loteria',
          JSON.stringify(adaptedData),
        )
        .pipe(map(() => void 0));
    } else {
      return this.http
        .post(this.baseUrl + '/users/unfinish/play', JSON.stringify(adaptedData))
        .pipe(map(() => void 0));
    }
  }

  protected playPromo(
    data: Record<string, unknown>,
    headers?: HttpHeaders,
  ): Observable<any> {
    return this.http.post(
      this.baseUrl + '/users/prereserves/play',
      JSON.stringify(data),
      {headers: headers},
    );
  }

  private adaptFormService(formService: FormService): Record<string, unknown> {
    let data: any = formService.toBackend();
    let adaptedData: any = {
      almanaque: data.almanaque,
      aleatorio: data.aleatorio,
      quickPlay: data.quickPlay,
      abonarse: data.abonarse,
      abonoDays: data.abonoDays,
      abonoMinJackpotAmount: data.abonoMinJackpotAmount,
      groupIdToSpoof: data.groupIdToSpoof,
    };

    if (data.comparticion) {
      adaptedData['comparticion'] = data.comparticion;
    }

    if (formService instanceof LotteryFormService) {
      Object.assign(adaptedData, {
        sorteoId: data.sorteoIds[0],
        decimos: data.combinacionJugada.bets.map(bet => {
          let reservation = false;
          let adminId = bet.types[1].value[0];
          if (adminId === 'reserva') {
            adminId = bet.searchAdminId ? bet.searchAdminId : null;
            reservation = true;
          }

          return {
            numero: bet.types[0].value[0],
            adminId: adminId,
            cantidad: bet.amountBet,
            almanaquePrecio: data.almanaque ? data.totalApuesta : undefined,
            reservation: reservation,
          };
        }),
      });

      if (adaptedData.decimos.length === 0) {
        adaptedData.randomLoteria = {stock: data.numApuestas};

        if (data.boothId) {
          adaptedData.randomLoteria['adminId'] = data.boothId;
        }
      }
      if (data.envioCasaRequest) {
        adaptedData.envioCasaRequest = data.envioCasaRequest;
      }
      if (data.recogida) {
        adaptedData.recogida = data.recogida;
      }
    } else {
      Object.assign(adaptedData, {
        juego: data.juego,
        numApuestas: formService.multiplier,
        sorteoIds: data.sorteoIds,
        joker: false,
        combinacionJugada: {juego: data.juego},
      });

      if (formService instanceof SelectionFormService) {
        // Backend made required bet price for selection
        adaptedData.precioTotal = data.totalApuesta;
        adaptedData.numApuestas = formService.multiplier;
      }

      adaptedData.combinacionJugada.apuestas = [];

      data.combinacionJugada.bets.forEach(bet => {
        let apuesta: any = {};
        let add = false;

        bet.types.forEach((type, index) => {
          if (type.typeId === 'joker') {
            adaptedData.joker = type.value[0].toString();
          } else if (type.typeId === 'reintegro') {
            adaptedData.combinacionJugada.reintegro = {
              numero: type.value[0].toString(),
            };
          } else if (type.typeId === 'complementario') {
            if (formService.gameMetadata.version === 1) {
              adaptedData.combinacionJugada.complementario = {
                numero: type.value[0].toString(),
              };
            } else {
              add = true;
              apuesta['clave'] = {numero: type.value[0].toString()};
            }
          } else if (type.typeId === 'elige8') {
            type.value.forEach((value, idx) => {
              if (value) {
                adaptedData.combinacionJugada.apuestas[0].numeros[idx].numero +=
                  '-E';
              }
            });
          } else if (type.typeId === 'pleno15') {
            adaptedData.combinacionJugada.plenoAl15 = {
              numero:
                this.concatSelections(type.value[0].selections) +
                ',' +
                this.concatSelections(type.value[1].selections),
            };
          } else if (type.typeId === 'partidos') {
            add = true;

            if (
              bet.types[index + 1] &&
              bet.types[index + 1].typeId === 'reducciones'
            ) {
              apuesta.numeros = type.value.map((v, vIdx) => ({
                numero:
                  this.concatSelections(v.selections) +
                  (!!bet.types[index + 1].value[vIdx] ? '-R' : ''),
              }));
            } else {
              apuesta.numeros = type.value.map(v => ({
                numero: this.concatSelections(v.selections),
              }));
            }
          } else if (type.typeId !== 'reducciones') {
            add = true;
            apuesta[type.typeId] = type.value.map(v => ({numero: v.toString()}));
          }
        });

        if (add) {
          adaptedData.combinacionJugada.apuestas.push(apuesta);
        }
      });

      if (
        formService.gameMetadata.id === GameId.BONOLOTO &&
        formService.form.value.betGroup === 'weekly'
      ) {
        adaptedData.combinacionJugada['extras'] = [{id: 'semanal'}];
      }
    }

    return adaptedData;
  }

  private concatSelections(selections): string {
    return selections
      .reduce((str, selection) => str + selection.value + '-', '')
      .replace(/-$/, '');
  }
}
