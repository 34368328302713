import {filter, first, from, map, Observable, of, switchMap, toArray} from 'rxjs';
import {GameMetadataService} from '../game-metadata/data/game-metadata.service';
import {GameMenuWidget} from './data/game-menu-widget';
import {GamesMenuWidgetProcessDataService} from './games-menu-widget-process-data.service';
import {Injectable} from '@angular/core';
import {State} from '../../states/data/state';
import {GameMetadata} from '../game-metadata/data/game-metadata';
import {StateAppDataService} from '../../states/data/state-app-data.service';

@Injectable()
export class GamesMenuWidgetProcessStatesService
  implements GamesMenuWidgetProcessDataService
{
  constructor(
    private gameMetadataService: GameMetadataService,
    private stateAppDataService: StateAppDataService,
  ) {}

  processList(list: GameMenuWidget[]): Observable<GameMenuWidget[]> {
    return of(list).pipe(
      switchMap((games: GameMenuWidget[]) => this.filterGamesByState(games)),
    );
  }

  private filterGamesByState(games: GameMenuWidget[]): Observable<GameMenuWidget[]> {
    return this.stateAppDataService.getData().pipe(
      switchMap((state: State) =>
        !!state
          ? from(games).pipe(
              switchMap((game: GameMenuWidget) =>
                this.gameMetadataService.getGameMetadata(game.game).pipe(
                  first(),
                  map((metadata: GameMetadata) => ({
                    game: game,
                    metadata: metadata,
                  })),
                ),
              ),
              filter(
                ({game, metadata}: {game: GameMenuWidget; metadata: GameMetadata}) =>
                  !!metadata.visibleOnStates.find(
                    (code: string) => code === state.code,
                  ),
              ),
              map(
                ({game, metadata}: {game: GameMenuWidget; metadata: GameMetadata}) =>
                  game,
              ),
              toArray(),
            )
          : of(games),
      ),
    );
  }
}
