import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FullHeightModule} from '../../full-height/full-height.module';
import {TranslatableTextModule} from '../../i18n/translatable-text/translatable-text.module';
import {SanitizerModule} from '../../sanitizer/sanitizer.module';
import {UtilModule} from '../../util/util-module';

import {ModalDialogComponent} from './modal-dialog.component';
import {ScrollableModule} from '../../scrolling/scrollable/scrollable.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FormRowModule} from '../../forms/row/form-row.module';
import {SwitchModule} from '../../forms/switch/switch.module';

@NgModule({
  imports: [
    CommonModule,
    FullHeightModule,
    UtilModule,
    SanitizerModule,
    ScrollableModule,
    TranslatableTextModule,
    FormRowModule,
    SwitchModule,
    ReactiveFormsModule,
  ],
  exports: [ModalDialogComponent],
  declarations: [ModalDialogComponent],
})
export class ModalDialogModule {}
