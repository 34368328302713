import {Observable, of} from 'rxjs';
import {environment} from '~environments/environment';

import {GeolocationService} from './geolocation.service';
import {Injectable} from '@angular/core';

@Injectable()
export class GeolocationServiceFake extends GeolocationService {
  getCurrentLocation(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition> {
    return of(environment.fakeLocation);
  }
}
