export class Team {
  name: string;

  emblem: string;

  info: string;

  score: number;

  static createFromBackend(obj: any) {
    let team = new Team();

    team.name = obj.name;
    team.emblem = obj.pictureUrl;
    team.info = obj.info;
    team.score = obj.score;

    return team;
  }

  public static fromJSON(json: any): Team {
    if (typeof json === 'string') {
      return JSON.parse(json, Team.reviver) as Team;
    } else if (json !== undefined && json !== null) {
      let pMatch = Object.create(Team.prototype);
      return Object.assign(pMatch, json, {});
    } else {
      return json;
    }
  }

  public static reviver(key: string, value: any): any {
    return key === '' ? Team.fromJSON(value) : value;
  }

  toJSON() {
    return Object.assign({}, this);
  }
}
