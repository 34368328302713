import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MoneyCodePipe} from './money-code.pipe';
import {MoneyFree} from './money-free.pipe';
import {MoneyPipe} from './money.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [MoneyCodePipe, MoneyFree, MoneyPipe],
  declarations: [MoneyCodePipe, MoneyFree, MoneyPipe],
})
export class CurrencyModule {}
