import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ENVIRONMENT, LocalStorage, Logger} from 'common';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {NoDeleteAllInfo} from './delete-all-info';

/**
 * Service to check if the allInfo should be deleted
 *
 * @class
 * @public
 */
@Injectable({
  providedIn: 'root',
})
export class DeleteAllInfoInVersionService {
  constructor(
    private http: HttpClient,
    private logger: Logger,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    private localStorage: LocalStorage,
  ) {}

  /**
   * Check for updates
   */
  deleteAllInfo(): Observable<boolean> {
    return this.http
      .get(
        `${this.environment.noDeleteAllInfoInVersionURL}?ngsw-bypass=1&cache-bust=` +
          Math.random(),
      )
      .pipe(
        map(({noDeleteAllInfoInVersion}: any) =>
          NoDeleteAllInfo.createFromBackend(noDeleteAllInfoInVersion),
        ),
        map((noDeleteAllInfo: NoDeleteAllInfo) =>
          this.mustDeleteAllInfo(noDeleteAllInfo),
        ),
        catchError(e => {
          this.logger.warn('No se ha podido obtener el fichero versions-web.json', {
            error: e,
          });
          // If there's an error, we delete the allInfo.
          return of(true);
        }),
      );
  }

  private mustDeleteAllInfo(noDeleteAllInfo: NoDeleteAllInfo): boolean {
    if (!noDeleteAllInfo) {
      return true;
    }

    if (noDeleteAllInfo.max < noDeleteAllInfo.min) {
      this.logger.error(
        'File versions-web.json has an incorrect min-max range,' +
          ' as the min value is greater than the max value or vice versa.',
      );
    }

    const storedVersion = Number(
      this.localStorage.getItem(this.environment.localStorageKeys.appVersion),
    );
    const currentVersion = this.environment.appVersion;

    return !(
      noDeleteAllInfo.max >= noDeleteAllInfo.min &&
      storedVersion >= noDeleteAllInfo.min &&
      currentVersion <= noDeleteAllInfo.max
    );
  }
}
