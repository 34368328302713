import {Pipe, PipeTransform} from '@angular/core';

/**
 * Returns an array with length items starting at start.
 * ex. arrayFromRange:3:6 // [3,4,5,6,7,8]
 */
@Pipe({name: 'arrayFromRange'})
export class ArrayFromRangePipe implements PipeTransform {
  transform(start: number, length: number): any {
    return Array.from(Array(length).keys()).map(i => i + start);
  }
}
