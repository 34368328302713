import {NgModule} from '@angular/core';

import {IsRouteActiveDirective} from './is-url-active.directive';
import {MatchUrlRouterLinkActiveDirective} from './match-url-router-link-active.directive';
import {RouterOutletClassDirective} from './router-outlet-class.directive';

@NgModule({
  exports: [
    IsRouteActiveDirective,
    MatchUrlRouterLinkActiveDirective,
    RouterOutletClassDirective,
  ],
  declarations: [
    IsRouteActiveDirective,
    MatchUrlRouterLinkActiveDirective,
    RouterOutletClassDirective,
  ],
})
export class TlRouterModule {}
