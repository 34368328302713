import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../../common/model/abstract.dao';
import {ShipmentAddress} from './shipment-address';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ShipmentAddressDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  getShipmentAddress(): Observable<ShipmentAddress> {
    return this.http
      .get<ShipmentAddress>(`${this.baseUrl}/users/lastEnvioAddress`)
      .pipe(map(ShipmentAddress.createFromBackend));
  }
}
