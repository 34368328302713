import {Observable, of, switchMap} from 'rxjs';

export function switchMapArray<T>(
  data: T,
  processList: Array<any>,
  functionName: string,
): Observable<T> {
  if (!processList || processList.length === 0) {
    return of(data);
  } else {
    return processList.reduce(
      (result, process) =>
        result.pipe(switchMap(currentData => process[functionName](currentData))),
      of(data),
    );
  }
}
