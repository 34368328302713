import {NgZone} from '@angular/core';
import {EventManager} from '@angular/platform-browser';

export enum SwipeDirection {
  RIGHT = 'tlswiperight',
  LEFT = 'tlswipeleft',
  UP = 'tlswipeup',
  DOWN = 'tlswipedown',
}

export class SwipeManager {
  direction = false;

  manager: EventManager;

  private horizontal: boolean;

  private startTime: number;

  private startPoint: any;

  private lastTime: number;

  private cancelTimer: any;

  private onFrame = false;

  /**
   * Minimum horizontal travel to recognice a swipe when the touch ends.
   */
  private readonly MAIN_AXIS_THRESHOLD = 30;

  /**
   * Maximum vertical travel in each step.
   */
  private readonly OFF_AXIS_THRESHOLD = 100;

  /**
   * Maximun time between touch events, if no event is received the swipe is
   * cancelled.
   */
  private readonly STEP_TIMEOUT = 100;

  /**
   * Maximum total time of the wipe gesture.
   */
  private readonly MAXIMUM_DURATION = 1000;

  constructor(
    private element: HTMLElement,
    eventName: string,
    private handler: (...args: any[]) => any,
    private zone: NgZone,
    private window: Window,
  ) {
    this.direction =
      eventName === SwipeDirection.RIGHT || eventName === SwipeDirection.DOWN;
    this.horizontal =
      eventName === SwipeDirection.RIGHT || eventName === SwipeDirection.LEFT;

    this.start = this.start.bind(this);
    this.move = this.move.bind(this);
    this.end = this.end.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.destroy = this.destroy.bind(this);

    this.zone.runOutsideAngular(() =>
      this.element.addEventListener('touchstart', this.start, {passive: true}),
    );
  }

  start(touchEvent: TouchEvent): void {
    if (touchEvent.touches.length === 1) {
      this.startPoint = {
        x: touchEvent.touches[0].screenX,
        y: touchEvent.touches[0].screenY,
      };
      this.lastTime = Date.now();
      this.startTime = Date.now();

      this.element.addEventListener('touchmove', this.move, {passive: true});
      this.element.addEventListener('touchend', this.end, {passive: true});

      this.window.addEventListener('scroll', this.onScroll, {
        capture: true,
        passive: true,
      });

      this.refreshTimer();
    }
  }

  move(touchEvent: TouchEvent): void {
    if (
      touchEvent.touches.length !== 1 ||
      !this.isValidTouch(touchEvent.touches[0], true)
    ) {
      this.cancel();
    } else {
      this.refreshTimer();
      touchEvent.stopPropagation();
    }
  }

  end(touchEvent: TouchEvent): void {
    if (
      touchEvent.changedTouches.length === 1 &&
      this.isValidTouch(touchEvent.changedTouches[0])
    ) {
      this.zone.runGuarded(() => this.handler(touchEvent));
    }

    this.cancel();
  }

  cancel(): void {
    this.lastTime = this.startTime = null;
    clearTimeout(this.cancelTimer);
    this.element.removeEventListener('touchmove', this.move);
    this.element.removeEventListener('touchend', this.end);

    this.window.removeEventListener('scroll', this.onScroll, {
      capture: true,
    });
  }

  destroy(): void {
    this.element.removeEventListener('touchstart', this.start);
    this.cancel();
  }

  onScroll(scrollEvent: Event): void {
    // if scrollleft is not 0 we assume its scrolling sideways
    // and cancel the swipe

    if (!this.onFrame) {
      this.window.requestAnimationFrame(() => {
        const property = this.horizontal ? 'scrollLeft' : 'scrollTop';

        if ((<HTMLElement>scrollEvent.target)[property] !== 0) {
          this.cancel();
        }
        this.onFrame = false;
      });

      this.onFrame = true;
    }
  }

  private isValidTouch(touch: Touch, partial = false): boolean {
    const deltaX = touch.screenX - this.startPoint.x;
    const deltaY = touch.screenY - this.startPoint.y;
    const elapsedTime = Date.now() - this.startTime;
    const stepTime = Date.now() - this.lastTime;

    return this.isValidSwipe(deltaX, deltaY, elapsedTime, stepTime, partial);
  }

  private isValidSwipe(
    deltaX: number,
    deltaY: number,
    elapsedTime: number,
    stepTime: number,
    partial = false,
  ): boolean {
    const mainAxisDelta = this.horizontal ? deltaX : deltaY;
    const offAxisDelta = this.horizontal ? deltaY : deltaX;

    return (
      (partial || Math.abs(mainAxisDelta) > this.MAIN_AXIS_THRESHOLD) &&
      Math.abs(offAxisDelta) < this.OFF_AXIS_THRESHOLD &&
      elapsedTime < this.MAXIMUM_DURATION &&
      stepTime < this.STEP_TIMEOUT &&
      mainAxisDelta * (this.direction ? 1 : -1) > 0
    );
  }

  private refreshTimer(): void {
    this.lastTime = Date.now();
    clearTimeout(this.cancelTimer);
    this.cancelTimer = setTimeout(() => this.cancel(), 100);
  }
}
