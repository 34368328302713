import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {Logger} from 'common';
import {environment} from '~environments/environment';

import {ShareService} from '../../common/social/share.service';

import {GamesDao} from './games.dao';
import {GamesDaoAdapter} from './games.dao.adapter';

export function gamesDaoFactory(
  http: HttpClient,
  logger: Logger,
  shareService: ShareService,
) {
  if (environment.locale.locale === 'es-ES') {
    return new GamesDaoAdapter(http, logger, shareService);
  } else {
    return new GamesDao(http, logger, shareService);
  }
}

@NgModule({
  providers: [
    // TODO · ADAPTER · remove
    {
      provide: GamesDao,
      useFactory: gamesDaoFactory,
      deps: [HttpClient, Logger, ShareService],
    },
  ],
})
export class GamesModelModule {}
