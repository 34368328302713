import {BetRuleTypeMetadata} from './bet-rule-type-metadata';

/**
 * Rule applied in types that require a number of choices
 */
export class RequiredBetRuleTypeMetadata extends BetRuleTypeMetadata {
  /**
   * Number of choices to select
   */
  required: number;

  constructor() {
    super();
  }

  static createFromBackend(obj: any) {
    let requiredBRTMD = new RequiredBetRuleTypeMetadata();

    requiredBRTMD.required = obj.needed;
    requiredBRTMD.optional = !!obj.optional;

    return requiredBRTMD;
  }

  // Serializable inherited from parent.
}
