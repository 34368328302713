import {Injectable} from '@angular/core';
import {Logger} from 'common';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {TuLoteroServerError} from '../../error/tulotero-server-error';

import {Task} from './task';
import {TaskContext} from './task-context';

export type TaskResults = Record<string, unknown>;

@Injectable({providedIn: 'root'})
export class TaskManager {
  scheduledTasks: Map<TaskContext, Map<string, Task>>;

  constructor(private logger: Logger) {
    this.scheduledTasks = new Map<TaskContext, Map<string, Task>>();
  }

  scheduleTask(context: TaskContext | Array<TaskContext>, task: Task): void {
    const contexts = Array.isArray(context) ? context : [context];
    contexts.forEach(contxt => {
      if (this.scheduledTasks.has(contxt)) {
        this.scheduledTasks.get(contxt).set(task.id, task);
      } else {
        const taskMap = new Map<string, Task>();
        taskMap.set(task.id, task);

        this.scheduledTasks.set(contxt, taskMap);
      }
    });
  }

  scheduleNewTask(
    context: TaskContext | Array<TaskContext>,
    id: string,
    method: () => Observable<any>,
    persistent = false,
  ): void {
    this.scheduleTask(context, new Task(id, method, persistent));
  }

  removeTaskFromContext(taskId: string, context: TaskContext): void {
    if (this.scheduledTasks.has(context)) {
      this.scheduledTasks.get(context).delete(taskId);

      if (this.scheduledTasks.get(context).size === 0) {
        this.scheduledTasks.delete(context);
      }
    }
  }

  removeTask(taskId: string) {
    Array.from(this.scheduledTasks.keys()).forEach(context => {
      this.removeTaskFromContext(taskId, context);
    });
  }

  removeAllInContext(context: TaskContext): void {
    this.scheduledTasks.delete(context);
  }

  moveTask(taskId: string, contextFrom: TaskContext, contextTo: TaskContext) {
    const task = this.scheduledTasks.get(contextFrom).get(taskId);
    this.scheduleTask(contextTo, task);
  }

  executeInContext(context: TaskContext): Observable<TaskResults> {
    if (
      this.scheduledTasks.has(context) &&
      this.scheduledTasks.get(context).size > 0
    ) {
      return forkJoin(
        Array.from(this.scheduledTasks.get(context).entries()).map(
          (taskEntry: [string, Task]) => {
            if (!taskEntry[1].persistent) {
              this.removeTaskFromContext(taskEntry[1].id, context);
            }
            return taskEntry[1].run().pipe(
              catchError(error => {
                if (!(error instanceof TuLoteroServerError)) {
                  this.logger.error(
                    `Error executing task '${taskEntry[0]}' in context '${context}'.`,
                    null,
                    error,
                  );
                }
                return of(null);
              }),
              map(res => [taskEntry[0], res]),
            );
          },
        ),
      ).pipe(
        map(forkedResults => {
          let taskResults: TaskResults = {};
          forkedResults.forEach(result => (taskResults[result[0]] = result[1]));
          return taskResults;
        }),
      );
    } else {
      return of(null);
    }
  }
}
