import {NgModule} from '@angular/core';

import {LimitReachedModalModule} from './limit-reached-modal/limit-reached-modal.module';
import {TransferLimitsContentModule} from './transfer-limits-content/transfer-limits-content.module';
import {LimitsResolver} from './limits.resolver';

@NgModule({
  imports: [LimitReachedModalModule, TransferLimitsContentModule],
  providers: [LimitsResolver],
})
export class ExclusionModule {}
