export {};

declare global {
  interface Array<T> {
    filterUnique<U>(callbackFunction: (element: T) => U): Array<T>;

    unique(): Array<T>;

    clone(): Array<T>;
  }
}

Array.prototype.unique = function () {
  let u = {};
  let a = [];

  for (let i = 0, l = this.length; i < l; ++i) {
    if (u.hasOwnProperty(this[i])) {
      continue;
    }
    a.push(this[i]);
    u[this[i]] = 1;
  }
  return a;
};

/**
 * Return unique objects by a given key.
 *
 * @example [{id:1}, {id:1}, {id:2}].filterUnique(el => el.id) =>
 * [{id:1}, {id:2}]
 * @param fn Callback function
 */
Array.prototype.filterUnique = function (fn: (arg: any) => any) {
  const items = {};
  for (let i = 0, l = this.length; i < l; ++i) {
    const key = fn(this[i]);
    if (!items[key]) {
      items[key] = this[i];
    }
  }
  return Object.values(items);
};

Array.prototype.clone = function () {
  return this.slice();
};
