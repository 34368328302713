import {ResponsiveService} from '../responsive/responsive.service';

import {PRESS_TRESHOLD, PressEvent} from './press-event';

export class Press extends PressEvent {
  constructor(
    protected responsiveService: ResponsiveService,
    protected element: HTMLElement,
    protected originalHandler: (...args: any[]) => any,
    protected doc: Document,
  ) {
    super(responsiveService, element, originalHandler, doc);
  }

  /**
   * Fires the press original handler after {@link PRESS_TRESHOLD} millis.
   * If end handler {@link endHandler} is fired before {@link PRESS_TRESHOLD}
   * millis, the press event is cancelled.
   *
   * @param event dom event which is starting press event. This will be
   *   mousedown/touchstart
   * for desktop/mobile respectively.
   */
  protected startHandler(event: Event) {
    this.timer = setTimeout(() => {
      this.originalHandler(event);
      clearTimeout(this.timer);
    }, PRESS_TRESHOLD);
  }

  /**
   * Press event is cancelled if this end handler is fired before
   * {@link PRESS_TRESHOLD} millis. Nothing will be done otherwise.
   * Mouseup/touchend for desktop/mobile respectively, will fire this handler.
   */
  protected endHandler() {
    clearTimeout(this.timer);
  }
}
