import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, first, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AlertsService, ResponsiveService} from 'common';

import {BoothSelectedService} from '../../booth-selected.service';
import {BoothsSearchViewService} from '../../booths-search-view.service';
import {LotteryBooth} from '../../data/lottery-booth';
import {SessionService} from '../../../user/auth/session.service';
import {environment} from '~environments/environment';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({template: 'no-ui'})
export abstract class LotteryBoothsListBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('search', {static: true})
  searchInput: ElementRef;

  booths: Array<LotteryBooth>;

  @Input()
  mode: 'favorite' | 'select';

  protected destroySubject = new Subject<void>();

  constructor(
    protected boothsSearchViewService: BoothsSearchViewService,
    protected boothSelectedService: BoothSelectedService,
    protected sessionService: SessionService,
    protected alertsService: AlertsService,
    protected router: Router,
    protected responsiveService: ResponsiveService,
    protected cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.boothsSearchViewService
      .getFavoriteList()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(booths => {
        this.booths = booths;
        this.cdr.markForCheck();
      });
  }

  ngAfterViewInit() {
    if (this.searchInput) {
      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(debounceTime(500))
        .subscribe((event: any) =>
          this.boothsSearchViewService.searchSubject.next(event.target.value),
        );
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  trackByBoothId(index: number, booth: LotteryBooth): string {
    return booth.id;
  }

  onBoothSelected(booth) {
    this.sessionService
      .isLoggedIn()
      .pipe(first())
      .subscribe(isLogged => {
        if (isLogged) {
          this.boothSelectedService.selectedBooth.next(booth);
        } else {
          this.alertsService.notifyInfo({
            key: 'games.play.warningRequiredData.registrationRequired',
          });
          const routeMap = environment.locale.routes;
          this.router.navigate([
            (this.responsiveService.isDesktop() ? '/' : '/m/') + routeMap.register,
          ]);
        }
      });
  }
}
