import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../i18n/i18n.module';
import {ModalDialogModule} from '../modal/modal-dialog/modal-dialog.module';

import {CameraInUseModalComponent} from './camera-in-use-modal/camera-in-use-modal.component';
import {CameraComponent} from './camera.component';
import {CameraService} from './camera.service';

@NgModule({
  imports: [CommonModule, ModalDialogModule, I18nModule],
  exports: [CameraComponent],
  declarations: [CameraComponent, CameraInUseModalComponent],
  providers: [CameraService],
})
export class CameraModule {}
