export type CreditCardType = 'AMEX' | 'DC' | 'D' | 'MC' | 'V';

export interface CreditCard {
  id: CreditCardType;
  matcher: RegExp;
  name: string;
  image: string;
  mask: Array<number>;
}

const CREDIT_CARDS: Array<CreditCard> = [
  {
    id: 'AMEX',
    name: 'American Express',
    matcher: new RegExp('^3[47]'),
    image: 'assets/img/saldo/tarjeta-credito/amex.svg',
    mask: [3, 6, 5],
  },
  {
    id: 'DC',
    name: 'Diners Club',
    matcher: new RegExp('^(30[0-9]|36|38)'),
    image: 'assets/img/saldo/tarjeta-credito/diners-club.svg',
    mask: [4, 4, 4, 2],
  },
  {
    id: 'D',
    name: 'Discover',
    matcher: new RegExp(
      '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1]' +
        '[0-9]|92[0-5]|64[4-9])|65)',
    ),
    image: 'assets/img/saldo/tarjeta-credito/discover.svg',
    mask: [4, 4, 4, 4],
  },
  {
    id: 'MC',
    name: 'Master Card',
    matcher: new RegExp(
      '^(5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)',
    ),
    image: 'assets/img/saldo/tarjeta-credito/mastercard.svg',
    mask: [4, 4, 4, 4],
  },
  {
    id: 'V',
    name: 'Visa',
    matcher: new RegExp('^4'),
    image: 'assets/img/saldo/tarjeta-credito/visa.svg',
    mask: [4, 4, 4, 4],
  },
];

export function detectCreditCardType(value: string) {
  return typeof value === 'string'
    ? CREDIT_CARDS.find(card => !!value.match(card.matcher))
    : null;
}

export function applyCreditCardMask(
  card: CreditCard,
  input: HTMLInputElement,
  separator: string,
  isTextInserted: boolean,
) {
  let caretStart = input.selectionStart;
  let caretEnd = input.selectionStart;
  let regex = new RegExp(separator, 'g');
  let newValue = input.value.replace(regex, '');
  let cursor = 0;

  if (card) {
    card.mask.forEach((maskChunk, index) => {
      cursor += maskChunk;

      if (
        newValue.length > cursor + index &&
        newValue.charAt(cursor + index) !== '-'
      ) {
        newValue =
          newValue.substr(0, cursor + index) +
          separator +
          newValue.substr(cursor + index);

        if (isTextInserted && caretStart >= cursor + index) {
          caretStart++;
          caretEnd++;
        }
      }
    });
  } else {
    input.value = newValue;
  }

  if (newValue.endsWith(separator)) {
    newValue = newValue.substr(0, newValue.length - 1);
  }

  input.value = newValue;
  input.setSelectionRange(caretStart, caretEnd);
}

export function applyExpiryDateMask(input: HTMLInputElement) {
  let newValue = input.value.replace('/', '');
  let caretStart = input.selectionStart;
  let caretEnd = input.selectionStart;

  if (newValue.length > 2) {
    newValue = newValue.substr(0, 2) + '/' + newValue.substr(2);
    caretStart++;
    caretEnd++;
  }

  if (newValue.endsWith('/')) {
    newValue = newValue.substr(0, newValue.length - 1);
  }

  input.value = newValue;
  input.setSelectionRange(caretStart, caretEnd);
}
