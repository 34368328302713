import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderButtonComponent} from './header-button.component';
import {TranslatableTextModule} from '../i18n/translatable-text/translatable-text.module';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule, TranslatableTextModule],
  exports: [HeaderButtonComponent],
  declarations: [HeaderButtonComponent],
})
export class HeaderButtonModule {}
