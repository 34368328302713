import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({
  selector: 'tl-set-table-item',
  templateUrl: './set-table-item.component.html',
  styleUrls: ['./set-table-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetTableItemComponent {
  @Input()
  label: any;

  @Input()
  selected: boolean;

  @Input()
  disabled: boolean;

  @HostBinding('class.tl-set-table-item')
  readonly hostClass = true;
}
