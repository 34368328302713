import {Injectable} from '@angular/core';

import {AbstractObservableDataService} from '../model/abstract-observable-data.service';
import {Country} from './country';

@Injectable({providedIn: 'root'})
export class CountryService extends AbstractObservableDataService<Array<Country>> {
  constructor() {
    super();
  }
}
