import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  ViewEncapsulation,
} from '@angular/core';

import {ngModelProvider} from '../../../model/ng-model-config';
import {ResponsiveService} from '../../../responsive/responsive.service';
import {CounterComponent} from '../counter.component';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: 'tl-counter-3',
  templateUrl: '../counter.component.html',
  styleUrls: ['./counter3.component.scss'],
  providers: [ngModelProvider(Counter3Component), DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class Counter3Component extends CounterComponent {
  @HostBinding('class.tl-counter-3')
  readonly hostClass = true;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected responsiveService: ResponsiveService,
    @Inject(LOCALE_ID) protected locale: string,
  ) {
    super(cdr, responsiveService, locale);
  }
}
