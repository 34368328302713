import {Group} from '../../groups/data/group';

import {ShareItemFormGroup} from './share-item-form-group';

export class ShareItemGroupFormGroup extends ShareItemFormGroup {
  constructor(group: Group) {
    super(group);
  }

  getGroup(): Group {
    return <Group>super.getShare();
  }

  toBackend(): any {
    const group: Group = this.controls['share'].value;
    return {idusuario: group.id, nombre: group.name};
  }
}
