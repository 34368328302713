import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BoxComponent {
  @Input('color-band')
  @HostBinding('class.tl-box__band')
  @HostBinding('style.border-left-color')
  colorBand: string;

  @HostBinding('style.background-image')
  _backgroundImage: any;

  @HostBinding('class.tl-box')
  readonly hostClass = true;

  @Input('background-image')
  set backgroundImage(value: string) {
    if (value) {
      this._backgroundImage = 'url("' + value + '")';
    }
  }
}
