import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {isToday} from 'date-fns';
import {Observable} from 'rxjs';

import {ENVIRONMENT} from '../../environment/environment-token';
import {TranslationsService} from '../../i18n/translations.service';
import {formatDate} from '../date-fns-wrapper';

@Pipe({name: 'timestampToDayNameMonth'})
export class TimestampToDayNameMonthPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    cdr: ChangeDetectorRef,
    private translationsService: TranslationsService,
  ) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(value: number): any {
    let observableToReturn: Observable<string>;
    if (isToday(value)) {
      observableToReturn =
        this.translationsService.getTranslation('games.time.today');
    } else {
      observableToReturn = this.translationsService.getCompiledTranslation(
        'games.time.onDate',
        {
          date: formatDate(
            value,
            this.environment.locale.dateFormats.dayNameMonthDate,
          ),
        },
      );
    }
    return this.asyncPipe.transform(observableToReturn);
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
