import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {isNumeric} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractDao} from '../../../common/model/abstract.dao';
import {CoordinatePoint} from '../../../geolocation/coordinate-point';
import {CombinationType} from '../../combination/data/combination-type';
import {CombinationTypeValue} from '../../combination/data/combination-type-declaration';
import {CombinationValue} from '../../combination/data/combination-value';
import {GameMetadata} from '../../game-metadata/data/game-metadata';

import {LotteryTicketPaginationData} from './lottery-ticket-pagination-data';

@Injectable({providedIn: 'root'})
export class LotteryDao extends AbstractDao {
  constructor(protected http: HttpClient) {
    super();
  }

  redeemLotteryShare(code: string): Observable<any> {
    return this.http.post(this.baseUrl + '/users/papeletas', code);
  }

  searchLotteryTickets(
    raffleId: number,
    index = 0,
    gameMetadata: GameMetadata,
    typeParams?: Array<CombinationTypeValue>,
    minStock?: number,
  ): Observable<LotteryTicketPaginationData> {
    return this.searchLotteryTicketsUrl(
      '/lottery/search',
      raffleId,
      index,
      gameMetadata,
      typeParams,
      minStock,
    );
  }

  searchLotteryTicketsAsUser(
    raffleId: number,
    index = 0,
    gameMetadata: GameMetadata,
    typeParams?: Array<CombinationTypeValue>,
    minStock?: number,
  ): Observable<LotteryTicketPaginationData> {
    return this.searchLotteryTicketsUrl(
      '/users/lottery/search',
      raffleId,
      index,
      gameMetadata,
      typeParams,
      minStock,
    );
  }

  getLotteryTickets(
    raffleId: number,
    gameMetadata: GameMetadata,
    index = 0,
    combinationTypes?: Array<CombinationTypeValue>,
    minNumber?: number,
    location?: CoordinatePoint,
    companySearch = false,
    includeReservations = false,
  ): Observable<LotteryTicketPaginationData> {
    return this.getLotteryTicketsGeneric(
      '/lottery/decimos/',
      raffleId,
      gameMetadata,
      index,
      combinationTypes,
      minNumber,
      location,
      companySearch,
      includeReservations,
    );
  }

  getUserLotteryTickets(
    raffleId: number,
    gameMetadata: GameMetadata,
    index = 0,
    combinationTypes?: Array<CombinationTypeValue>,
    minNumber?: number,
    location?: CoordinatePoint,
    companySearch = false,
    includeReservations = false,
  ): Observable<LotteryTicketPaginationData> {
    return this.getLotteryTicketsGeneric(
      '/users/lottery/decimos/',
      raffleId,
      gameMetadata,
      index,
      combinationTypes,
      minNumber,
      location,
      companySearch,
      includeReservations,
    );
  }

  getStock(
    raffleId: number,
    boothId: string,
  ): Observable<{stock: number; boothId: string}> {
    return this.getStockUrl('/lottery/info/', raffleId, boothId);
  }

  getStockUser(
    raffleId: number,
    boothId: string,
  ): Observable<{stock: number; boothId: string}> {
    return this.getStockUrl('/users/lottery/info/', raffleId, boothId);
  }

  getStockExcludeBooth(
    raffleId: number,
    excludeBoothId: string,
    minStock: number,
  ): Observable<{stock: number; boothId: string}> {
    return this.getStockUrl(
      '/lottery/info/',
      raffleId,
      null,
      excludeBoothId,
      minStock,
    );
  }

  getStockUserExcludeBooth(
    raffleId: number,
    excludeBoothId: string,
    minStock: number,
  ): Observable<{stock: number; boothId: string}> {
    return this.getStockUrl(
      '/users/lottery/info/',
      raffleId,
      null,
      excludeBoothId,
      minStock,
    );
  }

  protected getStockUrl(
    url: string,
    raffleId: number,
    boothId: string,
    excludeBoothId?: string,
    minStock?: number,
  ): Observable<{stock: number; boothId: string}> {
    let params: HttpParams = new HttpParams();
    if (!!boothId) {
      params = params.set('adminId', boothId);
    } else {
      if (!!excludeBoothId) {
        params = params.append('adminExclude', excludeBoothId);
        params = params.append('amount', minStock);
      }
    }
    return this.http
      .get<any>(this.baseUrl + url + raffleId, {params: params})
      .pipe(map(({maxStock, adminId}) => ({stock: maxStock, boothId: adminId})));
  }

  protected searchLotteryTicketsUrl(
    url: string,
    raffleId: number,
    index = 0,
    gameMetadata: GameMetadata,
    typeParams?: Array<CombinationTypeValue>,
    minStock?: number,
  ): Observable<LotteryTicketPaginationData> {
    let request = {sorteoId: raffleId, types: [], firstResult: index};

    if (typeParams) {
      typeParams.forEach((type: CombinationType<CombinationValue>) => {
        request.types.push({
          typeId: type.typeId,
          value: [...type.value.map((cv: CombinationValue) => cv.value)],
        });
      });
    } else {
      gameMetadata
        .getFirstBet()
        .rules[0].ruleTypes.forEach(ruleType =>
          request.types.push({typeId: ruleType.type.id, value: ['']}),
        );
    }

    if (minStock > 0) {
      request['minAmount'] = minStock;
    }

    return this.http
      .post(this.baseUrl + url, request)
      .pipe(map((obj: any) => LotteryTicketPaginationData.createFromBackend(obj)));
  }

  protected getLotteryTicketsGeneric(
    url: string,
    raffleId: number,
    gameMetadata: GameMetadata,
    index = 0,
    combinationTypes?: Array<CombinationTypeValue>,
    minNumber?: number,
    location?: CoordinatePoint,
    companySearch = false,
    includeReservations = false,
  ): Observable<LotteryTicketPaginationData> {
    // TODO · MADE UP, change when backend supports pagination with gameMetadata
    let params = new HttpParams().append('firstResult', index.toString());

    if (combinationTypes) {
      combinationTypes.forEach(
        (type: CombinationType<CombinationValue>) =>
          (params = params.append(type.typeId, type.value[0].value)),
      );
    } else {
      gameMetadata
        .getFirstBet()
        .rules[0].ruleTypes.forEach(ruleType => params.append(ruleType.type.id, ''));
    }

    if (minNumber) {
      params = params.append('cantidadMinima', minNumber.toString());
    }

    if (location && isNumeric(location.latitude) && isNumeric(location.longitude)) {
      params = params.append('latitud', location.latitude.toString());
      params = params.append('longitud', location.longitude.toString());
    }

    if (companySearch) {
      params = params.append('loteriaEmpresa', 'true');
    }

    if (includeReservations) {
      params = params.append('includeReservations', 'true');
    }

    return this.http
      .get(this.baseUrl + url + raffleId, {params: params})
      .pipe(map((obj: any) => LotteryTicketPaginationData.createFromBackend(obj)));
  }
}
