import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logger, PaginationData, ResponsiveService} from 'common';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {AbstractDao} from '../../common/model/abstract.dao';

import {MoneyActivity} from './money-activity';
import {addGeolocationHeaders} from '../../common/http/http';
import {MoneySummary} from './money-summary';
import {MoneyActivityStatus} from './money-activity-status';

@Injectable({providedIn: 'root'})
export class MoneyDao extends AbstractDao {
  /**
   * Measure time among two credit natives.
   *
   * @link https://trello.com/c/ja2ZOOnN
   */
  private lastCreditNative: number;

  constructor(
    protected http: HttpClient,
    protected logger: Logger,
    protected responsiveService: ResponsiveService,
  ) {
    super();
  }

  moreActivities(
    index: number,
    type?: Array<string>,
  ): Observable<PaginationData<MoneyActivity>> {
    let params = new HttpParams().set('firstResult', index.toString());

    if (type && type.length > 0) {
      params = params.set('tipoMovimiento', type.join(','));
    }

    const url = `${this.baseUrl}/users/movimientos/me`;
    return this.http.get(url, {params: params}).pipe(
      map((obj: any) => {
        let tickets: Array<MoneyActivity>;
        tickets = [];
        for (let movimiento of obj.movimientos) {
          tickets.push(MoneyActivity.createFromBackend(movimiento));
        }

        return new PaginationData<MoneyActivity>(tickets, obj.total);
      }),
    );
  }

  statusMovement(id: number): Observable<MoneyActivityStatus> {
    const url = `${this.baseUrl}/users/movimientos/status/${id}`;
    return this.http.get(url).pipe() as Observable<MoneyActivityStatus>;
  }

  /**
   * Get the html form configured to submit iframe tpv
   *
   * Choices:
   * - amount
   * - amount + alias
   * - amount + alias + autocredit
   * - amount + savedCard
   * - amount + savedCard + autocredit
   */
  getTPV(
    amount: number,
    useSavedCreditCard: boolean,
    creditCardAlias: string,
    autoCreditMultiple: number,
  ): Observable<any> {
    let params = new HttpParams()
      .set('amount', amount.toString())
      .set('redirectUrlAfter', environment.money.tpvRedirect);
    if (useSavedCreditCard) {
      params = params.set('useSavedCreditCard', 'true');
    } else {
      if (creditCardAlias) {
        params = params.set('creditCardAlias', encodeURI(creditCardAlias));
      }
    }
    if (autoCreditMultiple) {
      params = params.set('autoCreditMultiple', autoCreditMultiple.toString());
    }

    return this.http
      .get(this.baseUrl + '/users/psp/redsys', {
        params: params,
        responseType: 'text',
      })
      .pipe(
        catchError(err => {
          if (typeof err?.error === 'string') {
            err.error = JSON.parse(err.error) as any;
          }
          throw err;
        }),
      );
  }

  getTPVForBizum(id: string, amount: number, phoneNumber: string): Observable<any> {
    const isDesktop = this.responsiveService.isDesktop();
    const routeMap = isDesktop
      ? environment.locale.routes.desktop
      : environment.locale.routes.mobile;
    // eslint-disable-next-line max-len
    const path: string = isDesktop
      ? `${routeMap.balance}/${routeMap.money.bizum.path}/${routeMap.money.bizum.tpv}`
      : `m/${routeMap.load}/${routeMap.money.bizum}`;
    const redirectUrlAfter = !!id
      ? `${document.location.origin}/${path}/${id}`
      : `${document.location.origin}/${path}`;
    return this.getPhoneTPV(amount, phoneNumber, redirectUrlAfter);
  }

  getTPVForMbWay(id: string, amount: number, phoneNumber: string): Observable<any> {
    const isDesktop = this.responsiveService.isDesktop();
    const routeMap = isDesktop
      ? environment.locale.routes.desktop
      : environment.locale.routes.mobile;
    // eslint-disable-next-line max-len
    const path: string = isDesktop
      ? `${routeMap.balance}/${routeMap.money.mbWay.path}/${routeMap.money.mbWay.tpv}`
      : `m/${routeMap.load}/${routeMap.money.mbWay}`;
    const redirectUrlAfter = !!id
      ? `${document.location.origin}/${path}/${id}`
      : `${document.location.origin}/${path}`;
    return this.getPhoneTPV(amount, phoneNumber, redirectUrlAfter);
  }

  sendTPVParams(merchantParams: string, signature: string): Observable<void> {
    let params = new HttpParams()
      .set('Ds_MerchantParameters', merchantParams)
      .set('Ds_Signature', signature);

    return this.http.get<void>(this.baseUrl + '/users/movimientos/pspResponse', {
      params: params,
      headers: new HttpHeaders().append('Accept', 'application/json'),
    });
  }

  withdraw(
    account: string,
    amount: number,
    saveAccount: boolean,
  ): Observable<{message: string}> {
    let body = new HttpParams()
      .append('cuenta', account)
      .append('cantidad', amount.toString())
      .append('persist', saveAccount.toString());

    return this.http.post<{message: string}>(
      this.baseUrl + '/users/withdraw',
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      },
    );
  }

  removeCreditCard(): Observable<void> {
    return this.http.delete<void>(this.baseUrl + '/users/creditCard');
  }

  /**
   *
   * @param autoCreditMultiple 0 => disable autoCredit, number => set multAmount
   */
  updateAutoCredit(autoCreditMultiple: number): Observable<void> {
    const body = new URLSearchParams();
    body.set('autoCreditMultiple', autoCreditMultiple.toString());

    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded',
      ),
    };

    return this.http.post<void>(this.baseUrl + '/users/autoCredit', body, options);
  }

  removeAutoCredit(): Observable<void> {
    return this.http.delete<void>(this.baseUrl + '/users/autoCredit');
  }

  removeBankAccount(): Observable<void> {
    return this.http.delete<void>(this.baseUrl + '/users/bankaccount');
  }

  sendInternalCharge(
    data: any,
    extras?: any,
  ): Observable<{status: string; html: string}> {
    let headers = new HttpHeaders();

    if (extras?.geolocation) {
      headers = addGeolocationHeaders(headers, extras.geolocation);
    }

    if (!this.lastCreditNative) {
      this.lastCreditNative = Date.now();
    } else {
      const last = this.lastCreditNative;
      this.lastCreditNative = Date.now();

      if (this.lastCreditNative - last < 2000) {
        this.logger.warn('Two request credit native in less than 2 seconds');
      }
    }
    return this.http.post<{status: string; html: string}>(
      this.baseUrl + '/users/credit/native',
      data,
      {headers: headers},
    );
  }

  requestWithdraw(withdrawalRequestId: number): Observable<any> {
    return this.http.put<any>(
      this.baseUrl +
        `/users/withdrawalrequest/markAsDisplayed/${withdrawalRequestId}`,
      {},
    );
  }

  getSumary(year: number): Observable<MoneySummary> {
    return this.http
      .get(`${this.baseUrl}/users/movimientos/summary`, {
        params: new HttpParams().append('year', year),
      })
      .pipe(map(MoneySummary.createFromBackend));
  }

  downloadSummaryPdf(year: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('year', year);
    const responseType = 'blob';

    return this.http.get(`${this.baseUrl}/users/movimientos/summary/pdf`, {
      params,
      responseType,
    });
  }

  private getPhoneTPV(
    amount: number,
    phoneNumber: string,
    redirectUrlAfter: string,
  ): Observable<any> {
    return this.http
      .post(this.baseUrl + '/users/credit/external', {
        amount: amount,
        phoneNumber: phoneNumber,
        paymentMethod: 'PHONE',
        redirectUrlAfter: redirectUrlAfter,
      })
      .pipe(
        catchError(err => {
          if (typeof err?.error === 'string') {
            err.error = JSON.parse(err.error) as any;
          }
          throw err;
        }),
      );
  }
}
