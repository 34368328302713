import {Observable} from 'rxjs';

import {MessageContext} from './data/message-context';
import {MessageHookDisplayOptions} from './message-hook-display-options';
import {MessageHookStorageService} from './message-hook-storage.service';

export abstract class MessageHookDisplayer {
  constructor(protected storage: MessageHookStorageService) {}

  abstract display(id: MessageContext): Observable<boolean>;

  abstract displayCustomMessage(
    id: string,
    options: MessageHookDisplayOptions,
  ): Observable<boolean>;

  hasDisplay(id: string) {
    return !this.storage.exists(id);
  }

  markAsRead(id: string) {
    this.storage.save(id);
  }
}
