import {SerializableProperty, SerializableType} from 'common';

import {UITypeMetadata} from './ui-type-metadata';

export abstract class AbstractGameTypeMetadata {
  id: string;

  label: string;

  type: string;

  playType: string;

  price: number;

  defaultValue: boolean;

  playable: boolean;

  optional: boolean;

  hasResult: boolean;

  multBet: number;

  /**
   * Global fields are included in every combination (and taken into account for the
   * price) but share the same value at all times across all combinations
   */
  global: boolean;

  @SerializableProperty(UITypeMetadata, SerializableType.OBJECT)
  uiMetadata: UITypeMetadata;

  /**
   * This property is filled in the parent.
   *
   */
  dependsOn: AbstractGameTypeMetadata;

  static fillFromBackend(child: AbstractGameTypeMetadata, obj: any) {
    child.id = obj.typeId;
    child.label = obj.label;
    child.type = obj.type;
    child.playType = obj.playType;
    child.price = obj.extraPrice;
    child.defaultValue = !!obj.defaultValue;
    child.optional = obj.optional;
    child.playable = false;
    child.uiMetadata = obj.uiInfo
      ? UITypeMetadata.createFromBackend(obj.uiInfo)
      : undefined;
    child.dependsOn = null;
    child.hasResult = obj.hasResult;
    child.global = !!obj.global;
    child.multBet = obj.multBet ?? 1;
  }
}
