import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {encodeURLParameters} from '../../util/url';
import {Shareable} from '../shareable';

@Injectable()
export class TelegramService implements Shareable {
  notify = false;

  constructor(@Inject('window') private window: Window) {}

  share(title: string, text: string): Observable<any> {
    this.window.open(
      `https://t.me/share/url?url=${encodeURLParameters(text)}`,
      '_blank',
    );
    return of(true);
  }
}
