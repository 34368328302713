import {Injectable} from '@angular/core';
import {Filter} from '../games/game-filter/data/filter';
import {
  filter as filterRxjs,
  first,
  from,
  map,
  Observable,
  of,
  switchMap,
  toArray,
} from 'rxjs';
import {State} from './data/state';
import {GameMetadata} from '../games/game-metadata/data/game-metadata';
import {UpcomingGame} from '../games/upcoming/data/upcoming.game';
import {UpcomingGamesService} from '../games/upcoming/data/upcoming-games.service';
import {GameMetadataService} from '../games/game-metadata/data/game-metadata.service';
import {FiltersViewProcessDataService} from '../games/game-filter/data/filters-view-process-data.service';
import {StateAppDataService} from './data/state-app-data.service';

@Injectable({
  providedIn: 'root',
})
export class FilterViewProcessStatesService
  implements FiltersViewProcessDataService
{
  constructor(
    protected gameMetadataService: GameMetadataService,
    protected stateAppDataService: StateAppDataService,
    protected upcomingGamesService: UpcomingGamesService,
  ) {}

  processList(list: Array<Array<Filter>>): Observable<Array<Array<Filter>>> {
    return of(list).pipe(
      switchMap((filters: Array<Array<Filter>>) => this.filterByState(filters)),
    );
  }

  private filterByState(
    filters: Array<Array<Filter>>,
  ): Observable<Array<Array<Filter>>> {
    return this.stateAppDataService.getData().pipe(
      switchMap((state: State) => {
        return !!state
          ? from(filters).pipe(
              switchMap((subFilters: Array<Filter>) => {
                return from(subFilters).pipe(
                  switchMap((filter: Filter) => this.getMetadata(filter)),
                  filterRxjs(
                    (data: {filter: Filter; metadata: GameMetadata}) =>
                      !data.metadata ||
                      !!data.metadata.visibleOnStates.find(
                        (code: string) => code === state.code,
                      ),
                  ),
                  map(
                    (data: {filter: Filter; metadata: GameMetadata}) => data.filter,
                  ),
                  toArray(),
                );
              }),
              toArray(),
            )
          : of(filters);
      }),
    );
  }

  private getMetadata(
    filter: Filter,
  ): Observable<{filter: Filter; metadata: GameMetadata}> {
    return !filter.gameIds
      ? of({filter: filter, metadata: undefined})
      : this.upcomingGamesService.getGamesByGameId(filter.gameIds[0]).pipe(
          first(),
          map((games: Array<UpcomingGame>) => games[0]),
          switchMap((game: UpcomingGame) =>
            !game
              ? of(undefined)
              : this.gameMetadataService
                  .getGameMetadata(game.gameId, game.gameVersion)
                  .pipe(first()),
          ),
          map((metadata: GameMetadata) => ({filter: filter, metadata: metadata})),
        );
  }
}
