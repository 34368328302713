import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

import {BackendTransformable} from '../../backend/util/backend-transformable';
import {GenericTicket} from '../../games/tickets/data/generic-ticket';

import {ShippingAddressFormGroup} from './shipping-address-form-group';
import {ShippingFormBoothArray} from './shipping-form-booth-array';
import {TicketShippingDao} from './ticket-shipping.dao';
import {TicketsByBoothFormGroup} from './tickets-by-booth-form-group';
import {Country} from 'common';

export class ShippingFormGroup extends FormGroup implements BackendTransformable {
  totalTickets = new ReplaySubject<number>(1);

  constructor(
    ticketShippingDao: TicketShippingDao,
    states: Array<string>,
    countries: Array<Country>,
    address?: ShippingAddressFormGroup,
  ) {
    super({
      ship: new FormControl(null, Validators.required),
      ticketsByBooth: new ShippingFormBoothArray(),
      // For reuse address between services
      address:
        address ??
        new ShippingAddressFormGroup(ticketShippingDao, states, countries),
    });
    this.controls['ticketsByBooth'].valueChanges.subscribe(value =>
      this.totalTickets.next(
        value.reduce((total, groupValue) => groupValue.amount + total, 0),
      ),
    );
    this.controls['ship'].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(isShipment => {
        if (isShipment) {
          this.address.enable();
        } else {
          this.address.disable();
        }
      });

    this.totalTickets.next(0);
  }

  get address(): ShippingAddressFormGroup {
    return this.controls['address'] as ShippingAddressFormGroup;
  }

  get showNoLocalProfileAddressWarning() {
    return (
      !this.address.isLocalProfileAddress &&
      !this.value.address?.stateZipCode?.state &&
      this.get('ship').value
    );
  }

  addTicket(ticket: GenericTicket) {
    (<ShippingFormBoothArray>this.controls['ticketsByBooth']).addTicket(ticket);
  }

  removeTicket(ticket: GenericTicket, keepEmptyBooth?: boolean) {
    const formGroup = <ShippingFormBoothArray>this.controls['ticketsByBooth'];
    formGroup.removeTicket(ticket, keepEmptyBooth);
  }

  getTicketsByBooth(boothId: string): Record<string, any> {
    return this.get('ticketsByBooth').value.find(group => group.boothId === boothId);
  }

  clearEmptyGroups() {
    (<ShippingFormBoothArray>this.controls['ticketsByBooth']).controls.forEach(
      (group: TicketsByBoothFormGroup) => {
        if (group.value.amount === 0) {
          (<ShippingFormBoothArray>this.controls['ticketsByBooth']).remove(group);
        }
      },
    );
  }

  toBackend(): Record<string, unknown> {
    const selectedTickets = (<ShippingFormBoothArray>(
      this.controls['ticketsByBooth']
    )).value.reduce((tickets, groupForm) => tickets.concat(groupForm.tickets), []);

    let body = {boletoIds: selectedTickets};

    if (this.value.ship) {
      // shipping
      Object.assign(body, this.address.toBackend());
    }

    return body;
  }
}
