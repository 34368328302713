import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {I18nModule} from '../i18n/i18n.module';
import {AppleButtonComponent} from './button/apple-button.component';
import {AppleLoginComponent} from './login/apple-login.component';
import {AppleAuthService} from './apple-auth.service';
import {AppleAuthSdkService} from './apple-auth-sdk.service';

@NgModule({
  imports: [I18nModule, CommonModule],
  exports: [AppleButtonComponent, AppleLoginComponent],
  declarations: [AppleButtonComponent, AppleLoginComponent],
  providers: [
    AppleAuthSdkService,
    {
      provide: AppleAuthService,
      useExisting: AppleAuthSdkService,
    },
  ],
})
export class AppleModule {}
