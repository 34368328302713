import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'tl-input-password-validations',
  templateUrl: './input-password-validations.component.html',
  styleUrls: ['./input-password-validations.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPasswordValidationsComponent {
  @Input()
  form!: FormGroup;

  @Input()
  control!: FormControl;

  @Input()
  messagesFieldName = 'password';

  @Input()
  submitted = false;

  messages = {
    'password.uppercaseNeeded': 'authentication.userRegister.validations.upperCase',
    'password.lowercaseNeeded': 'authentication.userRegister.validations.lowerCase',
    'password.numberNeeded': 'authentication.userRegister.validations.number',
    'password.symbolNeeded': 'authentication.userRegister.validations.specialChar',
    'password.length': 'authentication.userRegister.validations.minChars',
  };

  get showSuccess(): boolean {
    return this.control.valid;
  }

  constructor() {}

  sortNull() {
    return null;
  }
}
