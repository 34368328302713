import {Injectable, Injector, Type} from '@angular/core';
import {Logger} from 'common';

import {SessionService} from '../../../../user/auth/session.service';
import {GameMetadata} from '../../../game-metadata/data/game-metadata';
import {LotteryBetFormService} from '../form/lottery-bet-form.service';
import {LotenalBetFormService} from '../search/lotenal-bet-form-service';
import {LotenalSearchDao} from '../search/lotenal-search.dao';
import {GameId} from '../../../data/game-id';
import {FractionLotteryBetFormService} from '../form/fraction-lottery-bet-form.service';
import {AbstractLotteryBetFormService} from '../form/abstract-lottery-bet-form.service';

@Injectable({providedIn: 'root'})
export class DynamicLotteryBetFormService {
  private injector: Injector;

  constructor(parentInjector: Injector) {
    this.injector = Injector.create({
      providers: [
        {provide: LotteryBetFormService, useClass: LotteryBetFormService, deps: []},
        {
          provide: LotenalBetFormService,
          useClass: LotenalBetFormService,
          deps: [Logger, LotenalSearchDao, SessionService, 'window'],
        },
        {
          provide: FractionLotteryBetFormService,
          useClass: FractionLotteryBetFormService,
          deps: [],
        },
      ],
      parent: parentInjector,
    });
  }

  get(
    type: Type<any>,
  ): LotteryBetFormService | LotenalBetFormService | FractionLotteryBetFormService {
    return this.injector.get(type);
  }

  getInstance(gameMetadata: GameMetadata): AbstractLotteryBetFormService {
    const injectionToken =
      gameMetadata.id === GameId.LOTARIA_POPULAR
        ? FractionLotteryBetFormService
        : gameMetadata.customSearch
        ? LotenalBetFormService
        : LotteryBetFormService;

    return this.injector.get<AbstractLotteryBetFormService>(injectionToken);
  }
}
