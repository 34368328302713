import {Pipe, PipeTransform} from '@angular/core';
import {TranslatableText} from './translatable-text';

@Pipe({
  name: 'isTranslatableText',
})
export class IsTranslatableTextPipe implements PipeTransform {
  transform(value: any): value is TranslatableText {
    return (
      value !== null &&
      typeof value === 'object' &&
      (('key' in value &&
        typeof value.key === 'string' &&
        Object.keys(value).length === 1) ||
        ('keyData' in value &&
          typeof value.keyData === 'object' &&
          'key' in value &&
          typeof value.key === 'string' &&
          Object.keys(value).length === 2))
    );
  }
}
