import {Injectable, OnDestroy} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Logger, RouterHistoryService} from 'common';
import {
  distinctUntilChanged,
  first,
  map,
  take,
  takeUntil,
  takeWhile,
} from 'rxjs/operators';

import {AdditionalBetPicksBetRuleTypeMetadata} from '../../../game-metadata/data/additional-bet-picks-bet-rule-type-metadata';
import {BetRuleTypeMetadata} from '../../../game-metadata/data/bet-rule-type-metadata';
import {RequiredMultiplesBetRuleTypeMetadata} from '../../../game-metadata/data/required-multiples-bet-rule-type-metadata';
import {SelectionGameTypeMetadata} from '../../../game-metadata/data/selection-game-type-metadata';
import {Match} from '../../../matches/data/match';
import {generateSelectionsArray, multiplierFromMultiples} from '../utils/bet-utils';

import {FormNotReadyError} from './form-not-ready-error';
import {NumbersFormService} from './numbers-form.service';
import {CombinationForm} from './combination-form';
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';

@Injectable()
export class SelectionFormService extends NumbersFormService implements OnDestroy {
  // Indicates if the user should be allowed to select more items
  private disableSelectMore: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  constructor(
    protected fb: FormBuilder,
    protected logger: Logger,
    protected historyService: RouterHistoryService,
  ) {
    super(fb, logger, historyService);
  }

  /**
   * To be called when the raffle or matches change and only bet form needs to be
   * rebuilt (to keep exclusions).
   */
  recreateForNewRaffle() {
    this.createBetForm();
  }

  /**
   * Called to exclude revancha field when there are no revancha matches in the
   * matches list.
   */
  excludeMissingTypeMatches(matches: Map<string, Array<Match>>) {
    this.excludedTypes = [];
    Array.from(this.gameMetadata.types.values())
      .filter(type => type.playType === 'SELECTION')
      .forEach(type => {
        if (!matches.get(type.id)) {
          this.excludeType(type.id);
        }
      });
  }

  getDisableSelectMore(): Observable<boolean> {
    return this.disableSelectMore.asObservable().pipe(distinctUntilChanged());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.disableSelectMore.complete();
  }

  protected getBetExtrafields() {
    let extraFields = [];
    // skip extrafields if it only has global types
    if (
      Object.keys((this.form.get('bet.extraFields') as FormGroup).controls).every(
        key => !this.gameMetadata.getTypeMetadata(key).global,
      )
    ) {
      extraFields = [this.form.get('bet.extraFields')];
    } else {
      // Filter global fields
      // Requires a global type that is not included within the combination.
      const combinations: Array<CombinationForm> = (
        this.form.get('bet.combinations') as FormArray
      ).controls as Array<CombinationForm>;
      const globalTypesInnerCombination: Array<string> = Object.keys(
        (combinations[0] as FormGroup).controls,
      ).filter(key => this.gameMetadata.getTypeMetadata(key).global);

      // Exclude types that are in the combination
      const excludedTypes = globalTypesInnerCombination.filter(key =>
        combinations.every(combination => combination.get(key)),
      );

      const globalExtraFields = Object.keys(
        (this.form.get('bet.extraFields') as FormGroup).controls,
      )
        .filter(key => !excludedTypes.includes(key))
        .filter(controlKey => this.gameMetadata.getTypeMetadata(controlKey).global)
        .reduce((acc, controlKey) => {
          acc[controlKey] = (this.form.get('bet.extraFields') as FormGroup).get(
            controlKey,
          );
          return acc;
        }, {} as {[key: string]: AbstractControl});

      extraFields = [this.fb.group(globalExtraFields)];
    }
    return extraFields;
  }

  protected calculatePrice(): void {
    if (!this.form.get('bet').valid) {
      super.calculatePrice();
      return;
    }

    try {
      const betMetadata = this.currentBetMetadata();
      // build a map with all ruletypes for this bet for easy access
      const ruleTypes = new Map(
        betMetadata
          .getAllRuleTypes()
          .map(
            ruleType => <[string, BetRuleTypeMetadata]>[ruleType.type.id, ruleType],
          ),
      );

      const combinations = <Array<FormGroup>>(
        (<FormArray>this.form.get('bet.combinations')).controls
      );
      const extraControls = (<FormGroup>this.form.get('bet.extraFields')).controls;

      let price = 0;
      let multiplier = 0;
      let multipliedFields = [];

      // For multiplied fields store them until we have the full combinations
      // added fields can be just computed and added to the total
      Object.keys(extraControls)
        .filter(key =>
          this.shouldCalculatePriceForControl(
            extraControls[key],
            ruleTypes.get(key),
          ),
        )
        .forEach(key => {
          if (!ruleTypes.has(key)) {
            throw new FormNotReadyError();
          }

          // grouped types are to be multiplied, not grouped are added
          if (betMetadata.groupedTypes.has(key)) {
            multipliedFields.push({
              multiplier: this.computeMultiplier(
                extraControls[key].value,
                ruleTypes.get(key),
                combinations[0].controls,
              ),
              price: this.getFieldPrice(key),
            });
          } else {
            const fm = this.computeMultiplier(
              extraControls[key].value,
              ruleTypes.get(key),
              combinations[0].controls,
            );

            price += fm * this.getFieldPrice(key);
            // looks like we dont want global fields showing as extra bets
            // multiplierFromAddedFields += fm;
          }
        });

      combinations.forEach(combination => {
        let combinationMultipliedFields = [];

        Object.keys(combination.controls)
          .filter(key =>
            this.shouldCalculatePriceForControl(
              combination.controls[key],
              ruleTypes.get(key),
            ),
          )
          .forEach(key => {
            if (!ruleTypes.has(key)) {
              throw new FormNotReadyError();
            }

            if (betMetadata.groupedTypes.has(key)) {
              combinationMultipliedFields.push({
                multiplier: this.computeMultiplier(
                  combination.controls[key].value,
                  ruleTypes.get(key),
                  combination.controls,
                ),
                price: this.getFieldPrice(key),
              });
            } else {
              const fm = this.computeMultiplier(
                combination.controls[key].value,
                ruleTypes.get(key),
              );

              price += fm * this.getFieldPrice(key);
              multiplier += fm;
            }
          });

        let basePrice = 0;
        const combinationMultiplier = multipliedFields
          .concat(combinationMultipliedFields)
          .reduce((cmult, field) => {
            // when fields are grouped only one should have a price
            basePrice = field.price || basePrice;
            return (cmult || 1) * field.multiplier;
          }, 0);

        multiplier += combinationMultiplier;
        price += basePrice * combinationMultiplier;
      });

      this.multiplier = multiplier;

      const rafflesValue = this.form.get('raffles').value;
      price *= Array.isArray(rafflesValue) ? rafflesValue.length : 1;

      // when manual wait until 'betsNumber' is validated to know if the form is
      // is valid so we can emit the price or 0 if the form is still invalid
      if (!this.form.get('random').value) {
        this.form.statusChanges
          .pipe(first())
          .pipe(map(status => (status === 'VALID' ? price : 0)))
          .subscribe(nextValue => {
            if (this.form.get('promo').value === 'prereserveShared') {
              this.price.next(0);
            } else {
              this.price.next(nextValue);
            }
          });
        this.form.get('betsNumber').setValue(this.multiplier);
      } else {
        if (this.form.get('promo').value === 'prereserveShared') {
          this.price.next(0);
        } else {
          this.price.next(price);
        }
      }
    } catch (err) {
      // Changing the bet type fires the calculation of the price before the
      // combinations are removed, causing the fields in the combination
      // to be different from the fields in the current bet, shortly after, the
      // combinations are cleared and the price calculation can resume as usual.
      // This could be avoided if we had better control over form state,
      // and change events, say with a custom ready event to calculate the price
      // that would wait after a bet change for the combinations to be cleared
      if (!(err instanceof FormNotReadyError)) {
        throw err;
      }

      this.multiplier = 0;
      this.price.next(0);
    }
  }

  /**
   * Calculates the multiplier of the bet from the combination of simple, double,
   * triple, etc... selections when the bet has no fixed multiplier.
   * Used to show the actual number of bets and calculate the price.
   */
  protected computeMultiplier(
    value: any,
    ruleType?: BetRuleTypeMetadata,
    combination?: {[key: string]: AbstractControl},
  ): number {
    if (ruleType instanceof AdditionalBetPicksBetRuleTypeMetadata) {
      const sourceControl = combination[ruleType.betsSourceType.type.id];
      const selectedValues = sourceControl.value.filter(
        (v, index) => !!value[index],
      );

      return multiplierFromMultiples(generateSelectionsArray(selectedValues));
    } else if (ruleType instanceof RequiredMultiplesBetRuleTypeMetadata) {
      const multiples = generateSelectionsArray(value);
      const minMultiples = [0, ruleType.minDoubles, ruleType.minTriples];

      return multiplierFromMultiples(
        multiples,
        minMultiples,
        ruleType.baseMultiplier,
      );
    } else if (ruleType.type instanceof SelectionGameTypeMetadata) {
      return multiplierFromMultiples(generateSelectionsArray(value));
    } else {
      return 1;
    }
  }

  /**
   * Listens to changes on the given combination form to add or remove it
   * from the main form structure.
   *
   * Only valid combinations can be included in the form to be able to validate
   * it globally.
   * This is only used in manual since in automatic all the combinations are
   * always generated in a valid state.
   *
   * @combinationForm the form to listen for changes
   */
  protected keepValidCombinationsOnChanges(
    combinationForm: CombinationForm,
    updateBetsNumber = false,
  ): void {
    const combinations = this.form.get('bet.combinations') as FormArray;

    this.combinationSubscriptions.push(
      combinationForm.statusChanges
        .pipe(
          distinctUntilChanged(),
          takeWhile(() => !this.form.get('random').value),
        )
        .subscribe(status => {
          let i = combinations.controls.indexOf(combinationForm);
          if (status === 'VALID' && i < 0) {
            // only ensures the first board to always be the first, not the
            // order of every panel
            combinations.insert(combinationForm.index, combinationForm);
          } else if (status === 'INVALID' && i >= 0) {
            const combination = combinations.at(i) as CombinationForm;
            const hasAllowedMultiplierError = Object.keys(combination.controls).some(
              control => {
                const error = combination.get(control).getError('allowedMultiplier');
                return error && error.actualMultiplier > error.maxMultiplier;
              },
            );

            if (!hasAllowedMultiplierError) {
              // remove invalid combination
              combinations.removeAt(i);
            }
            const destroy: Subject<void> = new Subject<void>();
            const extrafields = this.form.get('bet.extraFields') as FormGroup;
            this.combinationSubscriptions.push(
              merge(extrafields.valueChanges, combinationForm.valueChanges)
                .pipe(take(1), takeUntil(destroy))
                .subscribe(() => {
                  this.disableSelectMore.next(false);
                }),
            );
          }
          combinations.updateValueAndValidity();

          if (updateBetsNumber) {
            this.keepValidCombinationsOnChangesUpdateBetsNumber();
          }
        }),
    );
    const bet = this.form.get('bet') as FormGroup;

    this.betFormSubscriptions.push(
      bet.statusChanges
        .pipe(takeWhile(() => !this.form.get('random').value))
        .subscribe(status => {
          if (status === 'INVALID') {
            const allowedMultiplierError = bet.errors
              ? bet.errors['allowedMultiplier']
              : null;
            const hasAllowedMultiplierErrorExtrafield =
              allowedMultiplierError &&
              allowedMultiplierError.actualMultiplier >
                allowedMultiplierError.maxMultiplier;
            combinations.controls.indexOf(combinationForm);
            const i = combinations.controls.indexOf(combinationForm);
            const combination = combinations.at(i) as CombinationForm;
            let hasAllowedMultiplierErrorCombination = false;
            if (combination) {
              const controlWithMultiplierError = Object.keys(
                combination.controls,
              ).find(
                control => !!combination.get(control).getError('allowedMultiplier'),
              );
              hasAllowedMultiplierErrorCombination =
                combination &&
                controlWithMultiplierError &&
                !!combination
                  .get(controlWithMultiplierError)
                  .getError('allowedMultiplier')?.maxMultiplier &&
                Object.keys(combination.get(controlWithMultiplierError).errors)
                  .length === 1;
            }

            const disable =
              !!hasAllowedMultiplierErrorExtrafield ||
              (!!hasAllowedMultiplierErrorCombination &&
                bet.get('extraFields').valid);
            this.disableSelectMore.next(disable);
          } else {
            this.disableSelectMore.next(false);
          }
        }),
    );
  }
}
