import {Comparable, Serializable, SerializableDisableWarning} from 'common';

class FilterInternal {
  id: string;

  @SerializableDisableWarning()
  gameIds: null | Array<string>;

  label: string;

  translationKey: string;

  assetType: 'image' | 'icon' | 'game' | null;

  assetTranslated?: boolean;

  asset: string;

  assetMobile: string;

  renderLabel = true;

  enableGroupedTickets = true;

  order: number;

  isEqual(filter: Filter) {
    return filter && this.id === filter.id;
  }

  hasGameIds(): boolean {
    return Array.isArray(this.gameIds) && this.gameIds.length > 0;
  }
}

export class Filter
  extends Serializable(FilterInternal)
  implements Comparable<Filter>
{
  static createFromBackend(obj: any) {
    let filter = new Filter();

    filter.id = obj.id;
    filter.gameIds = obj.descriptors;
    filter.label = obj.label;
    filter.assetType = 'image';
    filter.assetTranslated = obj.assetTranslated ?? false;
    filter.asset = obj.image || obj.imageSVG;
    filter.assetMobile = obj.image;
    filter.renderLabel = !obj.imageHasLabel;
    filter.order = obj.order;

    return filter;
  }

  compareTo(o: Filter): number {
    return this.order - o.order;
  }
}
