import {HttpClient} from '@angular/common/http';
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {Subscription} from 'rxjs';

/**
 * Loads and image using different strategies.
 *
 * -src: classic method.
 * - xhr: do the request and encapsulate the image as a local blob and inserts
 * the blob url in the src attribute.
 */
@Directive({selector: 'img[tlDownloadImage]'})
export class DownloadImageDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  type: 'src' | 'xhr' = 'xhr';

  @Input('tlDownloadImage')
  url: string;

  private requestObservable: Subscription;

  private objectURL: string;

  constructor(
    protected element: ElementRef,
    protected http: HttpClient,
    protected renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('url') || changes.hasOwnProperty('type')) {
      if (this.type === 'xhr') {
        this.doXhr(this.url);
      } else {
        this.doSrc(this.url);
      }
    }
  }

  ngOnInit(): void {
    if (!this.type) {
      throw new Error('DownloadImageDirective: param type is empty.');
    }
  }

  ngOnDestroy(): void {
    if (this.requestObservable) {
      this.requestObservable.unsubscribe();
    }
    if (this.objectURL) {
      URL.revokeObjectURL(this.objectURL);
    }
  }

  /**
   * Get the image over ajax request and insert it as a blob.
   */
  protected doXhr(url: string) {
    if (this.requestObservable && !this.requestObservable.closed) {
      this.requestObservable.unsubscribe();
    }

    if (url) {
      this.requestObservable = this.http.get(url, {responseType: 'blob'}).subscribe({
        next: image => {
          this.objectURL = URL.createObjectURL(image);
          this.doSrc(this.objectURL);
        },
        error: () => this.doSrc(''), // Setting this up to empty string avoids doing
        // request and throws the error anyway.
      });
    } else {
      this.doSrc(null);
    }
  }

  /**
   * Insert the image in the DOM.
   */
  protected doSrc(url: string): void {
    this.renderer.setAttribute(this.element.nativeElement, 'src', url);
  }
}
