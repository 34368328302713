import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {differenceInCalendarDays} from 'date-fns';

import {ENVIRONMENT} from '../../environment/environment-token';
import {TranslationsService} from '../../i18n/translations.service';
import {formatDate} from '../date-fns-wrapper';

@Pipe({name: 'timestampToPastDate'})
export class TimestampToPastDatePipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    cdr: ChangeDetectorRef,
    private translationsService: TranslationsService,
  ) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(value: number): any {
    if (!value) {
      return '';
    }

    const date = new Date(value);

    if (Math.abs(differenceInCalendarDays(Date.now(), date)) <= 7) {
      let daysDiff = differenceInCalendarDays(date, Date.now());

      if (daysDiff === 0) {
        return this.asyncPipe.transform(
          this.translationsService.getCompiledTranslation('games.time.atTime', {
            time: formatDate(date, 'HH:mm'),
          }),
        );
      } else {
        return formatDate(date, 'eeee, HH:mm');
      }
    } else {
      return formatDate(date, this.environment.locale.dateFormats.dayMonthTime);
    }
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
