import {Validators} from '@angular/forms';
import {ExtendedFormArray} from 'common';

import {GenericTicket} from '../../games/tickets/data/generic-ticket';

import {TicketsByBoothFormGroup} from './tickets-by-booth-form-group';

export class ShippingFormBoothArray extends ExtendedFormArray<TicketsByBoothFormGroup> {
  constructor() {
    super([], Validators.compose([Validators.required, Validators.minLength(1)]));
  }

  addTicket(ticket: GenericTicket) {
    const group = <TicketsByBoothFormGroup>(
      this.controls.find(formGroup => formGroup.value.boothId === ticket.bet.boothId)
    );

    if (group) {
      group.addTicket(ticket);
    } else {
      const newGroup = TicketsByBoothFormGroup.createFormTicket(ticket);
      this.push(newGroup);
    }
  }

  removeTicket(ticket: GenericTicket, keepEmptyBooth: boolean) {
    const group = <TicketsByBoothFormGroup>(
      this.controls.find(formGroup => formGroup.value.boothId === ticket.bet.boothId)
    );

    if (group) {
      group.removeTicket(ticket);

      if (group.value.amount === 0 && !keepEmptyBooth) {
        this.remove(group);
      }
    }
  }
}
