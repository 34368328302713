const LAST_COMMA = /,\s([^,]+)$/;

export function compileString(
  source: string,
  pattern: RegExp,
  values: Record<string, string>,
): string {
  if (!pattern) {
    return source;
  }

  return (
    source &&
    source.replace(pattern, (subs, match) => (values && values[match]) || '')
  );
}

export function escapeRegExp(str: string): string {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

export function replaceLastCommaWith(
  replacement: string,
  originalString: string,
): string {
  return originalString.replace(LAST_COMMA, `${replacement} $1`);
}
