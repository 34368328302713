import {
  animate,
  animateChild,
  AnimationEvent,
  query,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {of, timeout, zip} from 'rxjs';
import {first} from 'rxjs/operators';

import {
  slideInHorizontal,
  slideInVertical,
  slideOutHorizontal,
  slideOutVertical,
} from '../../animation/angular/slide';

import {DelayedRouteActivation} from './delayed-route-activation';
import {SlidableModalAnimation} from './slidable-modal-animation';
import {
  transitionBottom,
  transitionLeft,
  transitionRight,
  transitionSlideOutToBottom,
  transitionTop,
} from './slidable-modal-transitions';

/**
 * Allows for two behaviours:
 * Note: Both animate router-outlet siblings.
 *
 *  · The first one is a regular animation in the chosen direction, 'in' and
 * 'out'.
 *
 *  · The second one is equivalent but has a delayed 'in' trigger.
 *
 * In all cases an animation queue is used to execute animations tidily.
 * This is needed since animateChild() is not working properly in nested
 * route-outlets.
 *
 * The 'out' animation is he same for both, from <direction>, to any state.
 */
/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-slidable-modal',
  templateUrl: './slidable-modal.component.html',
  styleUrls: ['./slidable-modal.component.scss'],
  providers: [SlidableModalAnimation],
  animations: [
    trigger('RouterTransition', [
      state(
        'waitingright',
        style({transform: `translate3D(100%, 0, 0)`, opacity: 0}),
      ),
      transition('waitingright => right', animate('500ms ease')),
      transition(transitionRight, [
        query(
          'router-outlet ~ *',
          useAnimation(slideInHorizontal, {
            params: {start: '100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),
      transition('right => *', [
        query('@*', animateChild(), {optional: true}),
        query(
          'router-outlet ~ *',
          useAnimation(slideOutHorizontal, {
            params: {end: '100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),

      state(
        'waitingleft',
        style({transform: `translate3D(-100%, 0 ,0)`, opacity: 0}),
      ),
      transition('waitingleft => left', animate('500ms ease')),
      transition(transitionLeft, [
        query(
          'router-outlet ~ *',
          useAnimation(slideInHorizontal, {
            params: {start: '-100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),
      transition('left => *', [
        query(
          'router-outlet ~ *',
          useAnimation(slideOutHorizontal, {
            params: {end: '-100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),

      state(
        'waitingtop',
        style({transform: `translate3D(0, -100%, 0)`, opacity: 0}),
      ),
      transition('waitingtop => top', animate('500ms ease')),
      transition(transitionTop, [
        query(
          'router-outlet ~ *',
          useAnimation(slideInVertical, {
            params: {start: '-100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),
      transition('top => *', [
        query(
          'router-outlet ~ *',
          useAnimation(slideOutVertical, {
            params: {end: '-100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),

      state(
        'waitingbottom',
        style({transform: `translate3D(0, 100%, 0)`, opacity: 0}),
      ),
      transition('waitingbottom => bottom', animate('500ms ease')),
      transition(transitionBottom, [
        query(
          'router-outlet ~ *',
          useAnimation(slideInVertical, {
            params: {start: '100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),
      transition('bottom => *', [
        query(
          'router-outlet ~ *',
          useAnimation(slideOutVertical, {
            params: {end: '100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),

      state(
        'waitingslideouttobottom',
        style({transform: `translate3D(0, 100%, 0)`, opacity: 0}),
      ),
      transition(
        'waitingslideouttobottom => slideouttobottom',
        animate('500ms ease'),
      ),
      transition(transitionSlideOutToBottom, [
        query(
          'router-outlet ~ *',
          useAnimation(slideInVertical, {
            params: {start: '100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),
      transition('slideouttobottom => *', [
        query(
          'router-outlet ~ *',
          useAnimation(slideOutVertical, {
            params: {end: '100%', timing: '500ms ease'},
          }),
          {optional: true},
        ),
      ]),
    ]),
  ],
})
export class SlidableModalComponent {
  @Input()
  @HostBinding('style.zIndex')
  zindex = 1;

  /**
   * Enables or disables exiting when clicking on the backdrop.
   */
  @Input()
  backdropClickEnabled = false;

  @Input()
  waitingToAnime = true;

  @HostBinding('style.display')
  display = 'none';

  @HostBinding('class.left')
  left = false;

  @HostBinding('class.right')
  right = false;

  @HostBinding('class.center')
  center = false;

  @HostBinding('class.slideOutToBottom')
  slideOutToBottom = false;

  constructor(
    public cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private slidableModalAnimation: SlidableModalAnimation,
  ) {}

  @HostListener('click')
  onHostClick() {
    this.router.navigate(['./'], {relativeTo: this.route});
  }

  stopEvent(e: Event, div: HTMLElement) {
    if (!this.backdropClickEnabled || e.target !== div) {
      e.stopPropagation();
    }
  }

  activate(outlet: RouterOutlet) {
    this.display = 'flex';
    this.right = outlet.activatedRouteData.position === 'right';
    this.left = outlet.activatedRouteData.position === 'left';
    this.center = ['center', 'top', 'bottom'].includes(
      outlet.activatedRouteData.position,
    );
    this.slideOutToBottom = ['slideOutToBottom'].includes(
      outlet.activatedRouteData.position,
    );
    this.waitingToAnime = true;

    zip(
      this.slidableModalAnimation.animate(),
      outlet.activatedRouteData.delayed
        ? (<DelayedRouteActivation>outlet.component).activationTrigger.pipe(
            first(),
            timeout({
              each: 1000,
              with: () => of(true),
            }),
          )
        : of(void 0),
    ).subscribe(() => this.animate());
  }

  deactivate() {
    this.display = 'none';
  }

  prepareRouterOutlet(outlet: any) {
    if (!outlet.activatedRouteData) {
      return '';
    }

    return `${this.waitingToAnime ? 'waiting' : ''}${
      outlet.activatedRouteData.position
    }`;
  }

  onDone(outlet: RouterOutlet, event: AnimationEvent) {
    this.slidableModalAnimation.transitionDone(outlet, event);

    if (!outlet.isActivated) {
      this.deactivate();
    }
  }

  /**
   * Subscribing to deactivate event triggers a change detection to update
   * animation state via 'preparerouterOutlet' even if we do nothing with it.
   */
  noop(): void {}

  private animate(): void {
    this.waitingToAnime = false;
    this.cdr.markForCheck();
  }
}
