<button
  class="btn tl-stepper__handle tl-stepper__less"
  type="button"
  (click)="previous()"
  [class.tl-stepper__handle--disabled]="prevDisabled">
  &#8722;
</button>
<input
  type="text"
  class="form-control tl-stepper__number"
  [readOnly]="readOnly"
  [value]="valueText" />
<button
  class="btn tl-stepper__handle tl-stepper__plus"
  type="button"
  (click)="next()"
  [class.tl-stepper__handle--disabled]="nextDisabled">
  &#43;
</button>
