import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {I18nModule} from '../../i18n/i18n.module';
import {DayWeekSelectorComponent} from './day-week-selector.component';
import {SetTableModule} from '../../forms/set-table/set-table.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    SetTableModule,
  ],
  exports: [DayWeekSelectorComponent],
  declarations: [DayWeekSelectorComponent],
})
export class DayWeekSelectorModule {}
