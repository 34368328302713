import {Serializable} from 'common';

class ShareInternal {
  userId: number;

  name: string;

  initials: string;

  price: number;

  date: number;

  image: string;

  phone: string;
}

export class Share extends Serializable(ShareInternal) {
  public static createFromBackend(obj: any) {
    let s = new Share();

    s.userId = obj.clienteId;
    s.name = obj.nombre;
    s.initials = obj.iniciales;
    s.price = obj.precio;
    s.date = obj.fecha;
    s.image = obj.urlFoto;
    s.phone = obj.phone;

    return s;
  }
}
