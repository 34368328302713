import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {
  I18nModule,
  ModalDialogModule,
  ProfileImageModule,
  ResponsiveUtilsModule,
  ScrollableModule,
} from 'common';

import {NewCompanyClubSuccessDialogComponent} from './new-company-club-success-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ModalDialogModule,
    NgbModalModule,
    ProfileImageModule,
    ResponsiveUtilsModule,
    ScrollableModule,
  ],
  exports: [NewCompanyClubSuccessDialogComponent],
  declarations: [NewCompanyClubSuccessDialogComponent],
})
export class NewCompanyClubSuccessDialogModule {}
