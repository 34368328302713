import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../model/abstract.dao';

@Injectable({providedIn: 'root'})
export class GoogleDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  login(token: string, instalationId?: number): Observable<any> {
    let body = new HttpParams()
      .append('token', token)
      .append('deviceType', 'WEB')
      .append('instalacionId', instalationId.toString());
    return this.http.post(this.baseUrl + '/google/login', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
}
