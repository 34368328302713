import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../../../i18n/i18n.module';

import {DropdownMenuItemComponent} from './dropdown-menu-item.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  exports: [DropdownMenuItemComponent],
  declarations: [DropdownMenuItemComponent],
})
export class DropdownMenuItemModule {}
