import {Injectable} from '@angular/core';
import {UpcomingGamesProcessDataService} from './upcoming-games-process-data.service';
import {UpcomingGame} from './upcoming.game';
import {first, from, map, Observable, of, reduce, switchMap} from 'rxjs';
import {GameMetadataService} from '../../game-metadata/data/game-metadata.service';
import {State} from '../../../states/data/state';
import {GameMetadata} from '../../game-metadata/data/game-metadata';
import {StateAppDataService} from '../../../states/data/state-app-data.service';

@Injectable()
export class UpcomingGamesProcessStatesService
  implements UpcomingGamesProcessDataService
{
  constructor(
    private gameMetadataService: GameMetadataService,
    private stateAppDataService: StateAppDataService,
  ) {}

  processList(list: Array<UpcomingGame>): Observable<Array<UpcomingGame>> {
    return of(list).pipe(
      switchMap((games: Array<UpcomingGame>) =>
        this.generateTupleGameMetadata(games),
      ),
      switchMap((dataList: Array<{game: UpcomingGame; metadata: GameMetadata}>) =>
        this.filterGamesByState(dataList),
      ),
      map((dataList: Array<{game: UpcomingGame; metadata: GameMetadata}>) =>
        dataList.map(
          (data: {game: UpcomingGame; metadata: GameMetadata}) => data.game,
        ),
      ),
    );
  }

  private generateTupleGameMetadata(
    upcomingGames: Array<UpcomingGame>,
  ): Observable<Array<{game: UpcomingGame; metadata: GameMetadata}>> {
    return from(upcomingGames).pipe(
      switchMap(game =>
        this.gameMetadataService.getGameMetadata(game.gameId, game.gameVersion).pipe(
          first(),
          map(metadata => <any>{game: game, metadata: metadata}),
        ),
      ),
      reduce((all, data) => all.concat([data]), []),
    );
  }

  private filterGamesByState(
    dataList: Array<{game: UpcomingGame; metadata: GameMetadata}>,
  ): Observable<Array<{game: UpcomingGame; metadata: GameMetadata}>> {
    return this.stateAppDataService
      .getData()
      .pipe(
        map((state: State) =>
          !!state
            ? dataList.filter(
                (data: {game: UpcomingGame; metadata: GameMetadata}) =>
                  !!data.metadata.visibleOnStates.find(
                    (code: string) => code === state.code,
                  ),
              )
            : dataList,
        ),
      );
  }
}
