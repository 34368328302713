import {Injectable} from '@angular/core';

import {LocalStorage} from './local-storage';

@Injectable()
export class MemoryStorageService implements LocalStorage {
  protected data = new Map<string, string>();

  constructor() {}

  get length() {
    return this.data.size;
  }

  clear(): void {
    this.data.clear();
  }

  getItem(key: string): string | null {
    const value = this.data.get(key);
    return value ? value : null;
  }

  key(index: number): string | null {
    // Local storage stores index by the end
    const key = Array.from(this.data.keys())[this.length - 1 - index];
    return key ? key : null;
  }

  removeItem(key: string): void {
    this.data.delete(key);
  }

  setItem(key: string, data: string): boolean {
    try {
      this.data.set(key, data);
      return true;
    } catch (e) {
      return false;
    }
  }
}
