import {Serializable} from 'common';

class StatePostalCodeInternal {
  prefix: string;
}

export class StatePostalCode extends Serializable(StatePostalCodeInternal) {
  static createFromBackend(obj: any) {
    let postalCode = new StatePostalCode();

    postalCode.prefix = obj.prefix;

    return postalCode;
  }
}
