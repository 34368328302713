import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {I18nModule} from '../../i18n/i18n.module';
import {DatePipesModule} from '../pipes/datepipes.module';

import {DateFieldComponent} from './datefield.component';

@NgModule({
  imports: [CommonModule, DatePipesModule, FormsModule, I18nModule],
  exports: [DateFieldComponent],
  declarations: [DateFieldComponent],
})
export class DateFieldModule {}
