import {
  getLocaleCurrencySymbol,
  getLocaleNumberSymbol,
  NumberSymbol,
} from '@angular/common';

export function parseCurrency(
  value: string,
  locale: string,
  allowDecimals: boolean,
  fallbackFormatedValue: string,
  min: number,
): number {
  let valueFixed: string = value;
  const currencySymbol = getLocaleCurrencySymbol(locale);
  const thousandsSymbol = getLocaleNumberSymbol(locale, NumberSymbol.CurrencyGroup);
  const decimalsSymbol = getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal);

  // remove currency symbol
  valueFixed = valueFixed.replace(currencySymbol, '').trim();

  // Check if it is correct format
  const regExpString: string = !allowDecimals
    ? `^([0-9]{1,3})([${thousandsSymbol}]?[0-9]{3})*$`
    : `^([0-9]{1,3})([${thousandsSymbol}]?[0-9]{3})*([${decimalsSymbol}][0-9]+)?$`;
  const regExp = new RegExp(regExpString);
  if (!regExp.test(valueFixed)) {
    valueFixed = String(fallbackFormatedValue ?? min ?? 0);
  }

  // Remove thousands symbol
  valueFixed = valueFixed.replace(thousandsSymbol, '');

  // Change decimal symbol to . for parse number
  valueFixed = valueFixed.replace(decimalsSymbol, '.');
  return Number(valueFixed);
}
