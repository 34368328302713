import {
  Attribute,
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[tlToggleableElement]',
  exportAs: 'tlToggleableElement',
})
export class ToggleableElementDirective implements OnInit {
  private enabled = false;

  constructor(
    @Attribute('tlToggleableElement') private initialStatus = false,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this.enabled = this.initialStatus;
  }

  ngOnInit(): void {
    this.setVisibility(this.enabled);
  }

  toggle(): void {
    this.setVisibility(!this.enabled);
  }

  isEnabled(): boolean {
    return this.enabled;
  }

  private setVisibility(show: boolean): void {
    if (show === this.enabled) {
      return;
    }

    this.enabled = show;
    if (show) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
