import {Injectable} from '@angular/core';
import {LocalStorage} from 'common';
import {Observable, ReplaySubject} from 'rxjs';
import {first} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {CampaignService} from './campaign.service';
import {InstallationDao} from './installation.dao';

@Injectable({providedIn: 'root'})
export class InstallationService {
  private id = new ReplaySubject<number>(1);

  private initialized = false;

  constructor(
    private campaignService: CampaignService,
    private installationDao: InstallationDao,
    private localStorage: LocalStorage,
  ) {}

  get idAsync(): Observable<number> {
    this.initialize();
    return this.id.asObservable();
  }

  initialize(): void {
    if (!this.initialized) {
      this.initialized = true;
      let installationId = this.localStorage.getItem(
        environment.localStorageKeys.installation,
      );
      if (installationId) {
        this.id.next(parseInt(installationId, 10));
      } else {
        let campaign;
        if (this.campaignService.hasCampaign()) {
          campaign = this.campaignService.getCampaignParams();
        }
        this.installationDao
          .create(campaign ? campaign.campaign : '', campaign ? campaign.source : '')
          .pipe(first())
          .subscribe({
            next: (id: any) => {
              this.localStorage.setItem(
                environment.localStorageKeys.installation,
                id,
              );
              this.id.next(id);
            },
            error: () => (this.initialized = false),
          });
      }
    }
  }
}
