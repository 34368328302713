import {ComponentRef, Injectable, OnDestroy} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ToastComponent} from './toast.component';
import {TranslatableText} from '../i18n/translatable-text';
import {Destroyable} from '../util/destroyable';
import {first, Subject, takeUntil, timer} from 'rxjs';

@Injectable()
export class ToastService implements OnDestroy {
  private overlayRef: OverlayRef;

  @Destroyable()
  private destroy: Subject<void> = new Subject<void>();

  constructor(private overlay: Overlay) {}

  show(
    message: string | TranslatableText,
    duration?: number,
    destroy?: Subject<any>,
  ): void {
    this.overlayRef = this.createOverlay();
    const toastPortal: ComponentPortal<ToastComponent> = new ComponentPortal(
      ToastComponent,
    );

    const toastRef: ComponentRef<ToastComponent> =
      this.overlayRef.attach(toastPortal);
    toastRef.instance.message = message;
    if (duration) {
      timer(duration)
        .pipe(takeUntil(this.destroy))
        .subscribe(() => this.close());
    }
    if (destroy) {
      destroy.subscribe(() => this.close());
    }
  }

  isToastVisible(): boolean {
    return !!this.overlayRef && this.overlayRef.hasAttached();
  }

  close(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.destroyOverlay();
    }
  }

  ngOnDestroy(): void {
    this.destroyOverlay();
  }

  private createOverlay(): OverlayRef {
    const overlayConfig: OverlayConfig = new OverlayConfig({
      hasBackdrop: false,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
    return this.overlay.create(overlayConfig);
  }

  private destroyOverlay(): void {
    if (this.overlayRef) {
      timer(1000)
        .pipe(first())
        .subscribe(() => this.overlayRef.dispose());
    }
  }
}
