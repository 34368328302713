import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import {CellOptions} from './cell-options';
import {GridCell} from './grid-cell';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnChanges {
  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  cellOptions: CellOptions;

  places: Map<string, GridCell>;

  rows: number;

  columns: number;

  containerStyle: Record<string, unknown>;

  cellStyle: Record<string, unknown>;

  cropStyle: Record<string, unknown>;

  @Input()
  set areas(areaArray: Array<Array<string>>) {
    if (!areaArray) {
      return;
    }
    this.rows = areaArray.length;
    this.columns = areaArray[0].length;

    this.places = new Map<string, GridCell>();

    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.columns; x++) {
        if (!this.places.has(areaArray[y][x])) {
          const data = areaArray[y][x].split(':');
          this.places.set(
            areaArray[y][x],
            new GridCell(data[0], +data[1], x, y, 1, 1),
          );
        } else {
          this.places.get(areaArray[y][x]).setXBound(x).setYBound(y);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.hasOwnProperty('areas') && changes['areas'].currentValue) ||
      (changes.hasOwnProperty('cellOptions') && this.cellOptions)
    ) {
      this.buildContainerStyle();
    }
  }

  mergeStyles(
    styles: Record<string, unknown>,
    styles2: Record<string, unknown>,
  ): Record<string, unknown> {
    return Object.assign(styles, styles2);
  }

  protected buildContainerStyle(): void {
    this.containerStyle = {};
    this.cropStyle = {};
    this.cellStyle = {};

    if (this.cellOptions && this.cellOptions.width) {
      this.containerStyle['width'] = `${
        (this.cellOptions.width + (this.cellOptions.gap || 0)) * this.columns
      }px`;
    } else {
      // use all available space for columns if no defined width
      this.containerStyle['width'] = '100%';
    }

    if (this.cellOptions && this.cellOptions.height) {
      this.containerStyle[`height`] = `${
        (this.cellOptions.height + (this.cellOptions.gap || 0)) * this.rows
      }px`;
    } else {
      // use padding to get a perfect square if no height is defined
      this.containerStyle['padding-top'] = `${(this.rows / this.columns) * 100}%`;
    }

    if (this.cellOptions && this.cellOptions.gap) {
      const gapColor = this.cellOptions.gapColor || 'transparent';

      this.cellStyle = {
        'border-right': `${this.cellOptions.gap}px solid ${gapColor}`,
        'border-bottom': `${this.cellOptions.gap}px solid ${gapColor}`,
      };

      // remove 1 extra pixel to prevent flickering borders
      this.cropStyle['margin'] = `-1px -${this.cellOptions.gap + 1}px -${
        this.cellOptions.gap + 1
      }px -1px`;
    }
  }
}
