import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ScrollableModule} from '../../scrolling/scrollable/scrollable.module';

import {DropdownMenuComponent} from './dropdown-menu.component';
import {DropdownMenuItemModule} from './menu-item/dropdown-menu-item.module';

@NgModule({
  imports: [CommonModule, DropdownMenuItemModule, ScrollableModule],
  exports: [DropdownMenuComponent],
  declarations: [DropdownMenuComponent],
})
export class DropdownMenuModule {}
