import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DateComponent} from './date.component';
import {DatePipesModule} from './pipes/datepipes.module';

@NgModule({
  imports: [CommonModule, DatePipesModule],
  exports: [DateComponent],
  declarations: [DateComponent],
})
export class DateModule {}
