import {NgModule} from '@angular/core';
import {FacebookConfigurationLoader} from 'common';

import {BackendFacebookConfiguration} from './configuration';

@NgModule({
  providers: [
    {provide: FacebookConfigurationLoader, useClass: BackendFacebookConfiguration},
  ],
})
export class FacebookProvidersModule {}
