import {FormArray, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {TlContact} from '../../../contacts/data/tl-contact';
import {Share} from '../data/share';
import {ShareMode} from '../data/share-mode';

import {ShareFormGroup} from './share-form-group';
import {ShareItemContactFormGroup} from './share-item-contact-form-group';
import {ShareOptionsFormGroup} from './share-options-form-group';

export class ShareContactsFormGroup extends ShareFormGroup {
  /**
   * Percent increment to use when balancing shares across all contacts
   */
  shareIncrement = 0.01;

  shareMode: ShareMode;

  reseted: Observable<void>;

  protected percentSubscriptions = new WeakMap<
    ShareItemContactFormGroup,
    Subscription
  >();

  private usrPercent = new BehaviorSubject(100);

  private onReset = new Subject<void>();

  get userPercent(): Observable<number> {
    return this.usrPercent.asObservable();
  }

  constructor(shares?: Array<ShareItemContactFormGroup>) {
    super(shares);

    this.addControl('options', new ShareOptionsFormGroup());

    // Subscribe controls for percents updates
    (<FormArray>this.get('shares')).controls.forEach(c =>
      this.subscribePercent(<ShareItemContactFormGroup>c),
    );
    this.balancePercents();

    this.reseted = this.onReset.asObservable();
  }

  static createFromContacts(contacts: Array<TlContact>): ShareContactsFormGroup {
    const controls = contacts.map(
      c => new ShareItemContactFormGroup(c, null, false),
    );
    const group = new ShareContactsFormGroup(controls);
    group.balancePercents();

    return group;
  }

  static createFromShares(
    shares: Array<Share>,
    price: number,
  ): ShareContactsFormGroup {
    const controls = shares
      ? shares.map(share => {
          const contact = TlContact.createFromShare(share);
          const percent = (share.price * 100) / price;
          return new ShareItemContactFormGroup(contact, percent, true);
        })
      : [];

    return new ShareContactsFormGroup(controls);
  }

  setRequired(min: number, max?: number): void {
    const validators = [Validators.required, Validators.minLength(min)];
    if (max) {
      validators.push(Validators.maxLength(max));
    }

    this.get('shares').setValidators(validators);
  }

  setOptional(): void {
    this.get('shares').setValidators([]);
  }

  reset(value?: any, options?: {onlySelf?: boolean; emitEvent?: boolean}): void {
    this.getOptions().reset(
      value && value['options'] ? value['options'] : undefined,
      options,
    );

    super.reset(value, options);
    this.onReset.next();
  }

  getOptions(): ShareOptionsFormGroup {
    return <ShareOptionsFormGroup>this.get('options');
  }

  hasEditableContacts(): boolean {
    return !!this.getShares().controls.find(
      (c: ShareItemContactFormGroup) => !c.isBlocked(),
    );
  }

  getSelectedItems(skipBlocked = false): Array<TlContact> {
    return this.getShares()
      .controls.filter(
        (control: ShareItemContactFormGroup) => !skipBlocked || !control.isBlocked(),
      )
      .map((control: ShareItemContactFormGroup) => control.getContact());
  }

  addShare(contact: TlContact): void {
    const share = new ShareItemContactFormGroup(contact);
    (<FormArray>this.controls['shares']).push(share);
    this.subscribePercent(share);
    this.balancePercents();
  }

  removeShare(contact: TlContact): ShareItemContactFormGroup {
    const item = <ShareItemContactFormGroup>super.removeShare(contact);
    this.unsubscribePercent(item);
    this.balancePercents();
    return item;
  }

  toBackend(price: number, asPercent = false): Record<string, unknown> {
    const shares = (<FormArray>this.controls['shares']).controls
      .filter(control => !control.get('blocked').value)
      .map((control: ShareItemContactFormGroup) => {
        if (this.get('options.distributePrize').value) {
          return asPercent ? control.toBackendPercent() : control.toBackend(price);
        } else {
          return control.toBackend();
        }
      })
      .map(entry => {
        (<any>entry).mensaje = this.get('options.shareMessage').value;
        return entry;
      });
    return shares.length > 0
      ? {comparticiones: shares, publicMode: this.get('options.sharePercents').value}
      : undefined;
  }

  setShareIncrement(increment: number): void {
    if (increment !== this.shareIncrement) {
      this.shareIncrement = increment;
      this.balancePercents();
    } else {
      this.shareIncrement = increment;
    }
  }

  /**
   * Balance to all active controls the remaining percent. All controls have
   * the same percent.
   */
  private balancePercents(): void {
    const shares = (<FormArray>this.get('shares')).controls;
    const activeControls = shares.filter(
      (c: ShareItemContactFormGroup) => !c.isBlocked(),
    );
    const blockedControls = shares.filter((c: ShareItemContactFormGroup) =>
      c.isBlocked(),
    );
    const availablePercent =
      100 -
      blockedControls.reduce(
        (t, c: ShareItemContactFormGroup) => t + c.getPercent(),
        0,
      );

    const availableChunks = Math.round(availablePercent / this.shareIncrement);
    const contactPercent =
      this.shareIncrement *
      Math.floor(availableChunks / (activeControls.length + 1));
    const userPercent = availablePercent - contactPercent * activeControls.length;

    this.usrPercent.next(userPercent);
    activeControls.forEach(control =>
      control.get('percent').setValue(contactPercent, {emitEvent: false}),
    );
    this.setMaximums(contactPercent);
  }

  /**
   * Subscribe a control for balancing percent changes, keeping the sum of all
   * controls amoung a value less than 100.
   */
  private subscribePercent(control: ShareItemContactFormGroup) {
    const subscription = control
      .get('percent')
      .valueChanges.pipe(filter(c => c !== control))
      .subscribe(() => {
        const totalPercent = (<FormArray>this.get('shares')).controls.reduce(
          (total, cntrl: ShareItemContactFormGroup) => total + cntrl.getPercent(),
          0,
        );
        const userPercent = 100 - totalPercent;

        this.usrPercent.next(userPercent);
        this.setMaximums(userPercent);
      });
    this.percentSubscriptions.set(control, subscription);
  }

  /**
   * Unsubscribe a control of percent changes.
   */
  private unsubscribePercent(control: ShareItemContactFormGroup) {
    const subscription = this.percentSubscriptions.get(
      <ShareItemContactFormGroup>control,
    );
    if (subscription) {
      subscription.unsubscribe();
      this.percentSubscriptions.delete(control);
    }
  }

  /**
   * Set a maximum value a control can reach or null if is in disable mode.
   */
  private setMaximums(userPercent: number) {
    (<FormArray>this.get('shares')).controls.forEach(
      (share: ShareItemContactFormGroup) =>
        share.setMaximum(share.getPercent() + userPercent),
    );
  }
}
