import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {Logger} from 'common';

import {environment} from '~environments/environment';

import {TicketsDao} from './tickets.dao';
import {TicketsDaoAdapter} from './tickets.dao.adapter';

export function ticketsDaoFactory(http: HttpClient) {
  switch (environment.locale.locale) {
    case 'es-ES':
      return new TicketsDaoAdapter(http);
    default:
      return new TicketsDao(http);
  }
}

@NgModule({
  providers: [
    // TODO · ADAPTER · remove
    {provide: TicketsDao, useFactory: ticketsDaoFactory, deps: [HttpClient, Logger]},
  ],
})
export class TicketModelModule {}
