import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertsService, Logger, TranslatableText} from 'common';

import {TuLoteroServerError} from './tulotero-server-error';

const STATUS_ERRORS = {
  SORTEO_CERRADO: 'games.upcomingGames.gameRow.messageClosedAdmin',
  NOSALDO: 'games.play.warningBalance.description',
};

@Injectable({providedIn: 'root'})
export class ErrorService {
  constructor(private alertService: AlertsService, private logger: Logger) {}

  processErrorManually(e: any): TuLoteroServerError {
    return this.processError(e);
  }

  processErrorGlobalContext(
    e: any,
    generalMessage: string | TranslatableText,
    forceModal = false,
  ): void {
    let tlE = this.processError(e);
    if (tlE instanceof TuLoteroServerError) {
      this.alertService.notifyError(tlE.message ?? {key: tlE.messageKey}, {
        forceModal: forceModal,
      });
    } else {
      this.alertService.notifyError(generalMessage, {forceModal: forceModal});
    }
  }

  processTuLoteroErrorGlobalContext(e: any, forceModal = false): void {
    let tlE = this.processError(e);
    if (tlE instanceof TuLoteroServerError) {
      this.alertService.notifyError(tlE.message, {forceModal: forceModal});
    }
  }

  // noinspection JSMethodCanBeStatic
  getErrorMessageByStatus(status: string): string {
    return STATUS_ERRORS[status];
  }

  processError(e: any): TuLoteroServerError {
    if (e instanceof HttpErrorResponse) {
      let parsedError;
      try {
        parsedError = e.error;
      } catch (ex) {
        /* No json body */
      }
      if (parsedError && parsedError.hasOwnProperty('status')) {
        let message = this.getErrorMessageByStatus(parsedError['status']);
        return new TuLoteroServerError(
          e.status,
          parsedError['status'],
          message
            ? message
            : parsedError['appAlertConfig']?.['appAlertTitle'] ??
              parsedError['message'],
          !!message,
          parsedError.url || undefined,
          parsedError['appAlertConfig']?.['appAlertDescription'] ??
            parsedError['subtitle'],
          parsedError['description'],
          parsedError['isUserNotifyMeStateAvailable'],
        );
      } else {
        this.logger.debug('Error de tipo response pero desconocido', e);
        return null;
      }
    } else if (e instanceof TuLoteroServerError) {
      return e;
    }

    this.logger.debug('Error al parsear error controlado', e);

    return null;
  }
}
