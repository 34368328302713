<img class="icon" [src]="logo" />
<span [innerHTML]="rowText | htmlSafe"></span>
<i class="icon-info2-icon row-info" (click)="info.emit()"></i>
<div
  *ngIf="infoTooltipId"
  class="info-tooltip-anchor"
  [tlTooltip]="infoTooltipText"
  [tooltipClass]="infoTooltipClass"
  [id]="infoTooltipId"
  [placement]="isDesktop ? 'left' : 'right'"></div>

<div
  *ngIf="switchTooltipId"
  class="switch-tooltip-anchor"
  [tlTooltip]="switchTooltipText"
  [tooltipClass]="switchTooltipClass"
  [id]="switchTooltipId"
  placement="left"></div>
<tl-switch
  [class]="switchClass"
  [value]="enabled"
  (toggle)="toggleSwitch.emit($event)">
</tl-switch>
