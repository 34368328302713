import {Serializable, SerializableProperty, SerializableType} from 'common';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';
import {BetRuleMetadata} from './bet-rule-metadata';
import {BetRuleTypeMetadata} from './bet-rule-type-metadata';
import {ConstrainedMultiplier} from './constrained-multiplier';
import {ConstrainedPotentialPrize} from './constrained-potential-prize';

class BetMetadataInternal {
  id: string;

  groups: Array<string>;

  name: string;

  description: string;

  buttonLabel: string;

  multiplier: number;

  minMultiplier: number;

  // Limits the total number of bets for a type of bet.
  // It doesn't matter if they are the same number or not.
  maxMultiplier: number;

  maxDoubles: number;

  maxTriples: number;

  fixedPrice: number;

  /**
   * To calculate the number of bets, grouped types stack multiplicatively
   * and all other types stack additively.
   *
   * @example
   * Take types A, B and C with 3, 4 and 5 bets each.
   *
   * With groupedTypes null: Total is A + B + C => 3 + 4 + 5 = 12
   * With groupedTypes=[A,B]: Total is A * B + C => 3 * 4 + 5 = 17
   */
  @SerializableProperty(Set, SerializableType.SET)
  groupedTypes: Set<string>;

  potentialPrize: number;

  potentialPrizeMultiplier: number;

  @SerializableProperty(BetRuleMetadata)
  rules: Array<BetRuleMetadata>;

  /**
   * Event if present min and maxMultipliers have to be respected
   */
  @SerializableProperty(ConstrainedMultiplier)
  allowedMultipliers: Array<ConstrainedMultiplier>;

  /**
   * If present overrides potentialPrize.
   * Used to show a different prize based on current bet (even if incomplete)
   */
  @SerializableProperty(ConstrainedPotentialPrize)
  potentialPrizeValues: Array<ConstrainedPotentialPrize>;

  protected static setRuleTypeScope(betMetadata: BetMetadata) {
    if (Array.isArray(betMetadata.rules) && betMetadata.rules.length > 0) {
      betMetadata.rules[0].scope = 'local';
    }
  }

  hasVariableMultiplier(): boolean {
    return this.multiplier === 0;
  }

  isMultiplierGlobal(): boolean {
    return !!this.groupedTypes.size;
  }

  getLocalRule(): BetRuleMetadata {
    return this.rules[0];
  }

  getLocalTypes(): Array<AbstractGameTypeMetadata> {
    return this.getLocalRule().getAllTypes();
  }

  getGlobalRules(): Array<BetRuleMetadata> {
    return this.rules.slice(1);
  }

  getGlobalTypes(): Array<AbstractGameTypeMetadata> {
    return this.getGlobalRules()
      .reduce((all, rule) => all.concat(...rule.ruleTypes), [])
      .map(ruleType => ruleType.type);
  }

  getGlobalRuleTypes(): Array<BetRuleTypeMetadata> {
    return this.getGlobalRules()
      .map(rule => rule.ruleTypes)
      .reduce((all, types) => all.concat(...types), <any>[])
      .concat(
        this.getLocalRule().ruleTypes.filter(ruleType => ruleType.type.global),
      );
  }

  getRuleTypeForType(typeId: string): BetRuleTypeMetadata {
    let ruleType = null;
    this.rules.find(rule => !!(ruleType = rule.getRuleTypeWithId(typeId)));

    return ruleType;
  }

  /**
   * Returns an array of all the types from the rules of this bet.
   */
  getAllTypes(): Array<AbstractGameTypeMetadata> {
    return this.rules.reduce(
      (total, rule) => total.concat(...rule.getAllTypes()),
      [],
    );
  }

  /**
   * Returns every ruleType inside every rule in this bet.
   */
  getAllRuleTypes(): Array<BetRuleTypeMetadata> {
    return this.rules.reduce(
      (total, rule) =>
        total.concat(
          rule.ruleTypes.reduce(
            (innerTotal, ruleType) => innerTotal.concat(ruleType),
            [],
          ),
        ),
      [],
    );
  }

  getForcedLocalTypes(): Array<BetRuleTypeMetadata> {
    return this.getAllRuleTypes().filter(
      rule => !!rule.type.uiMetadata && rule.type.uiMetadata.displayAsLocal,
    );
  }
}

export class BetMetadata extends Serializable(BetMetadataInternal) {
  static fromJSON(json: any): BetMetadata {
    const obj = super.fromJSON(json);
    if (json !== undefined && json !== null) {
      BetMetadata.setRuleTypeScope(obj);
    }

    return obj;
  }

  static createFromBackend(obj: any) {
    let betMetadata = new BetMetadata();

    betMetadata.id = obj.betId;
    betMetadata.groups = obj.groups || ['default'];
    betMetadata.name = obj.label;
    betMetadata.description = obj.desc;
    betMetadata.buttonLabel = obj.buttonLabel;
    betMetadata.multiplier = obj.multBet;
    betMetadata.minMultiplier = obj.multBetMin;
    betMetadata.maxMultiplier = obj.multBetMax;
    betMetadata.potentialPrize = obj.potentialPrize;
    betMetadata.potentialPrizeMultiplier = obj.potentialPrizeMultiplier;
    betMetadata.fixedPrice = obj.fixedPrice;

    betMetadata.maxDoubles = obj.maxPairs;
    betMetadata.maxTriples = obj.maxTriples;
    betMetadata.groupedTypes = new Set(obj.groupedTypes);

    betMetadata.rules = obj.types
      ? obj.types.map(type => BetRuleMetadata.createFromBackend(type))
      : undefined;

    betMetadata.allowedMultipliers = obj.multBetValues
      ? obj.multBetValues.map(mult => ConstrainedMultiplier.createFromBackend(mult))
      : undefined;

    betMetadata.potentialPrizeValues = obj.potentialPrizesValues
      ? obj.potentialPrizesValues.map(prize =>
          ConstrainedPotentialPrize.createFromBackend(prize),
        )
      : undefined;

    BetMetadata.setRuleTypeScope(betMetadata);

    return betMetadata;
  }
}
