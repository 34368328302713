import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnInit,
} from '@angular/core';
import {Subject} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';

import {ScreenSize} from '../responsive/model';
import {ResponsiveService} from '../responsive/responsive.service';
import {Destroyable} from '../util/destroyable';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-full-height',
  templateUrl: './full-height.component.html',
  styleUrls: ['./full-height.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullHeightComponent implements OnInit {
  @HostBinding('style.height')
  height: string;

  @Destroyable()
  private destroySubject = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private responsiveService: ResponsiveService,
    @Inject('window') private window: Window,
  ) {}

  ngOnInit() {
    this.responsiveService.resize
      .pipe(startWith(<ScreenSize>null))
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        // remove safe are on safari
        const marginBottom = +this.window
          .getComputedStyle(document.body)
          .getPropertyValue('margin-bottom')
          .slice(0, -2);
        const marginTop = +this.window
          .getComputedStyle(document.body)
          .getPropertyValue('margin-top')
          .slice(0, -2);
        this.height = this.window.innerHeight - marginBottom - marginTop + 'px';
        this.cdr.markForCheck();
      });
  }
}
