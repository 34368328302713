import {GroupHistoryEntry} from './group-history-entry';

export class TicketGroupHistoryEntry extends GroupHistoryEntry {
  gameId: string;

  static createFromBackend(obj: any): TicketGroupHistoryEntry {
    let g = new TicketGroupHistoryEntry();

    GroupHistoryEntry.fillFromBackend(g, obj);
    g.gameId = obj.boletoInfo.juego;

    return g;
  }
}
