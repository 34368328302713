<div class="tl-currency-input__wrap">
  <input
    type="text"
    class="form-control tl-currency-input__input"
    pattern="/[\d.,]*/"
    [class.tl-currency-input__input--empty]="model == undefined"
    [(ngModel)]="displayModel"
    [disabled]="disabled"
    (keydown)="restrictChars($event)"
    (keyup)="restrictChars($event)"
    [placeholder]="placeholder"
    (blur)="onBlur($event)" />
</div>
