import {first, Observable, of, switchMap, zip} from 'rxjs';
import {State} from './state';
import {StatesDao} from './states.dao';
import {StatesService} from './states.service';

export abstract class SelectorStateOperations {
  constructor(
    protected statesDao: StatesDao,
    protected statesService: StatesService,
  ) {}

  /**
   * State selected
   */
  abstract getState(): Observable<State>;

  abstract setState(state: State): Observable<void>;

  abstract setState(state: State): Observable<void>;

  abstract notifyState(
    state: State,
    email: string,
  ): Observable<{status: string; message: string}>;

  /**
   * All states
   */
  getStates(): Observable<Array<State>> {
    return this.statesService.getData();
  }

  getStateByGps(location: GeolocationPosition): Observable<State> {
    return zip([
      this.statesService.getData(),
      this.statesDao.getStateCodeByLocation({geolocation: location}),
    ]).pipe(
      first(),
      switchMap(([states, code]: [Array<State>, string]) => {
        return of(states.find((state: State) => state.code === code));
      }),
    );
  }
}
