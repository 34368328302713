import {AsyncPipe} from '@angular/common';
import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';

import {TranslationsService} from './translations.service';

/**
 * Pipe to easy translate strings in angular templates
 *
 * NOTE: This pipe should be impure due to use AsyncPipe and this one is impure
 * as well.
 */
@Pipe({name: 'i18n', pure: false})
export class TranslablePipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  private currentKey: string;

  private currentObs: Observable<string>;

  constructor(
    cdr: ChangeDetectorRef,
    private translationService: TranslationsService,
  ) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(key: string): string | null {
    // Optimization for impure pipes, observables is reused to minimize updates.
    if (this.currentKey !== key) {
      this.currentObs = this.translationService.getTranslation(key);
      this.currentKey = key;
    }
    return this.asyncPipe.transform(this.currentObs);
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
