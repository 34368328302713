import {Directive, ElementRef, OnDestroy, OnInit, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';

import {ScrollToControlDirective} from './scroll-to-control.directive';

@Directive({
  selector:
    '[formControl][tlScrollTargetControl], [formControlName][tlScrollTargetControl]',
})
export class ScrollTargetControlDirective implements OnInit, OnDestroy {
  constructor(
    private element: ElementRef,
    private ngControl: NgControl,
    @Optional() private scrollTo: ScrollToControlDirective,
  ) {}

  ngOnInit(): void {
    if (!this.scrollTo) {
      return;
    }

    this.scrollTo.registerControl(this.ngControl, this.element);
  }

  ngOnDestroy(): void {
    if (!this.scrollTo) {
      return;
    }

    this.scrollTo.removeControl(this.ngControl);
  }
}
