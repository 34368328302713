import {SerializableDisableWarning} from 'common';

import {RequiredMultiplesBetRuleTypeMetadata} from './required-multiples-bet-rule-type-metadata';
import {RequiredTrueBetRuleTypeMetadata} from './required-true-bet-rule-type-metadata';

/**
 * Used for choosing rows from a source type which must have multiple selections
 * according to that source type own rules.
 *
 * Used to select which bets to reduce on reducidas.
 */
export class PickedMultiplesBetRuleTypeMetadata extends RequiredTrueBetRuleTypeMetadata {
  // Filled in game metadata
  @SerializableDisableWarning()
  multiplesSourceType: RequiredMultiplesBetRuleTypeMetadata;

  static createFromBackend(obj: any) {
    let picksBRTMD = new PickedMultiplesBetRuleTypeMetadata();

    picksBRTMD.required = obj.needed;
    picksBRTMD.requiredTrue = obj.neededTrue;
    picksBRTMD.optional = !!obj.optional;

    return picksBRTMD;
  }

  // Serializable inherited from parent.
}
