import {Inject, Pipe, PipeTransform} from '@angular/core';

import {formatDate} from '../date-fns-wrapper';
import {ENVIRONMENT} from '../../environment/environment-token';

@Pipe({name: 'timestampToShortDateTime'})
export class TimestampToShortDateTimePipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT) private environment: Record<string, any>) {}

  transform(value: number): any {
    return formatDate(value, this.environment.locale.dateFormats.dateShort);
  }
}
