<div ngbDropdown class="tl-dropdown">
  <button
    type="button"
    class="btn"
    ngbDropdownToggle
    [disabled]="disabled || !countries">
    <span *ngIf="model">
      <img
        src="/assets/img/blank.gif"
        class="flag flag-{{ model.code }}"
        alt="{{ model.name }}" />
      &nbsp; +{{ model.prefix }}
    </span>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <label>
      <input
        type="text"
        [placeholder]="'global.search' | i18n"
        class="form-control-sm form-control"
        [formControl]="searchForm" />
    </label>
    <div class="countries">
      <tl-scrollable>
        <div
          *ngFor="
            let country of countries | filterArray: (searchTerm | async):testCountry;
            let i = index
          "
          [title]="('global.select' | i18n) + country.name"
          class="country-item"
          (click)="select(country)">
          <img
            src="/assets/img/blank.gif"
            class="flag flag-{{ country.code }}"
            alt="{{ country.name }}" />
          (+{{ country.prefix }}) {{ country.name }}
        </div>
      </tl-scrollable>
    </div>
  </div>
</div>
