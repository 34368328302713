import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
  CameraInUseModalComponent,
  LocalStorage,
  ModalDialogComponent,
  ModalHelperService,
  NavigationInstant,
  ResponsiveService,
  RouterNavigationCheckerService,
} from 'common';
import {getYear, isFuture, isPast} from 'date-fns';
import {
  filter,
  from,
  mapTo,
  Observable,
  of,
  ReplaySubject,
  throwError,
  zip,
} from 'rxjs';
import {catchError, first, map, pairwise, switchMap, tap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {Endpoint} from '../backend/endpoint/endpoint';
import {EndpointService} from '../backend/endpoint/endpoint.service';
import {TaskContext} from '../common/scheduler/task-context';
import {TaskManager} from '../common/scheduler/task-manager';
import {ErrorService} from '../error/error.service';
import {SessionService} from '../user/auth/session.service';
import {User} from '../user/data/user';
import {UserDao} from '../user/data/user.dao';
import {UserService} from '../user/data/user.service';

import {CardData} from './data/card-data';
import {DniType} from './data/dni-type';
import {KycMethodId} from './data/kyc-method-id';
import {KycStatus} from './data/kyc-status';
import {KycKoDialogComponent} from './dialogs/kyc-ko-dialog/kyc-ko-dialog.component';
import {KycOkDialogComponent} from './dialogs/kyc-ok-dialog/kyc-ok-dialog.component';
import {UploadingImagesDialogComponent} from './dialogs/uploading-images-dialog/uploading-images-dialog.component';
import {KycBackendError} from './kyc-backend-error';
import {KycFlowLauncherService} from './kyc-flow-launcher.service';
import {VerificationStep} from './kyc-verification-step';
import {KycCardFormGroup} from './model/kyc-card-form-group';
import {KycSelfieFormGroup} from './model/kyc-selfie-form-group';
import {KycService} from './model/kyc.service';
import {StepConfiguration} from './step-configuration';
import {KycMethod} from './data/kyc-method';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {KycModalService} from './dialogs/kyc-modal.service';
import {getKycMethodIdFromUserDocumentType} from '../user/data/user-document-types';

@Injectable({providedIn: 'root'})
export class KycViewService {
  backendError: KycBackendError;

  kycMethods: Array<KycMethod> = [];

  kycMethodIndex = environment.kyc?.methods?.default;

  kycModeOn: boolean;

  stepsConfiguration = StepConfiguration.DEFAULT;

  dniType = DniType.NATIONAL;

  showChristmasTexts = false;

  kycLaunched = new ReplaySubject<boolean>(1);

  private readonly dniTypeKycId = new Map([
    [DniType.NATIONAL, KycMethodId.NATIONAL],
    [DniType.COMMUNITY, KycMethodId.COMMUNITY],
    [DniType.NON_COMMUNITY, KycMethodId.NON_COMMUNITY],
    [DniType.PASSPORT, KycMethodId.PASSPORT],
    [DniType.CARD_ID, KycMethodId.CARD_ID],
    [DniType.CARD_DL_ID, KycMethodId.CARD_DL_ID],
    [DniType.CITIZEN_CARD, KycMethodId.CITIZEN_CARD],
    [DniType.IDENTITY_CARD, KycMethodId.IDENTITY_CARD],
    [DniType.RESIDENCE_CARD, KycMethodId.RESIDENCE_CARD],
    [
      DniType.FOREIGNER_IDENTIFICATION_CARD,
      KycMethodId.FOREIGNER_IDENTIFICATION_CARD,
    ],
  ]);

  private routeMap = environment.locale.routes;

  constructor(
    private endpointService: EndpointService,
    private errorService: ErrorService,
    private kycFlowLauncherService: KycFlowLauncherService,
    private kycService: KycService,
    private kycModalService: KycModalService,
    private localStorage: LocalStorage,
    private modalHelperService: ModalHelperService,
    private navigationChecker: RouterNavigationCheckerService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private sessionService: SessionService,
    private taskManager: TaskManager,
    private userDao: UserDao,
    private userService: UserService,
  ) {
    this.kycLaunched.next(
      !!localStorage.getItem(environment.localStorageKeys.kycLaunched),
    );

    this.sessionService.userLogoutEvent.subscribe(() => {
      this.removeKycLaunched();
      this.localStorage.removeItem(environment.localStorageKeys.kycPending);
    });

    this.endpointService.getData().subscribe((endpoint: Endpoint) => {
      this.kycMethods = endpoint.kycMethods;
    });

    this.calculateShowChristmasTexts();

    this.userService
      .getData()
      .pipe(
        pairwise(),
        filter(([oldUser, newUser]) => {
          if (!oldUser || !newUser) {
            return false;
          }

          return oldUser.kyc?.status !== newUser.kyc?.status;
        }),
      )
      .subscribe(([_, u]) => {
        if (u.kyc?.status === KycStatus.OK || u.kyc?.status === KycStatus.EXPIRED) {
          this.modalHelperService.openNoActionModal(KycOkDialogComponent);
        } else if (u.kyc?.status === KycStatus.DENIED) {
          this.modalHelperService
            .openOkModal(KycKoDialogComponent, {
              modalOptions: {centered: true},
              componentParams: {data: u.kyc},
            })
            .pipe(
              switchMap(() => {
                this.backendError = KycBackendError.KYC_REQUIRED;
                return this.initKyc(undefined, null);
              }),
            )
            .subscribe();
        }
      });
  }

  resetKycMethod(): Observable<void> {
    const defaultId = environment.kyc?.methods?.default;
    if (defaultId) {
      this.setDefaultKycMethod(defaultId);
    }

    const defaultBasedOnIdentityDocument =
      environment.kyc?.methods.defaultBasedOnIdentityDocument;
    if (defaultBasedOnIdentityDocument) {
      return this.userService.getData().pipe(
        first(),
        tap((user: User) =>
          this.setDefaultKycMethod(
            getKycMethodIdFromUserDocumentType(user.documentType),
          ),
        ),
        tap(user => getKycMethodIdFromUserDocumentType(user.documentType)),
        mapTo(void 0),
      );
    }
    return of(void 0);
  }

  initKyc(
    stepConfiguration = StepConfiguration.DEFAULT,
    fromBackend?: KycBackendError,
    kycModeOn = true,
  ): Observable<void> {
    this.kycModeOn = kycModeOn;
    this.backendError = fromBackend;

    this.stepsConfiguration = stepConfiguration;

    let sourceObs = of(void 0);

    if (fromBackend) {
      sourceObs = sourceObs.pipe(
        first(),
        switchMap(user => {
          const stepOption =
            this.stepsConfiguration === StepConfiguration.DEFAULT
              ? StepConfiguration.DEFAULT
              : 'OTHER';
          let kycModal: Observable<void> = of(void 0);
          switch (fromBackend) {
            case KycBackendError.KYC_REQUIRED:
              kycModal = this.kycModalService.openModalKycRequired(stepOption);
              break;
            case KycBackendError.KYC_EXPIRED:
              kycModal = this.kycModalService.openModalKycExpired();
              break;
            case KycBackendError.KYC_IN_PROGRESS:
              const option =
                stepOption === StepConfiguration.DEFAULT && this.showChristmasTexts
                  ? 'CHRISTMAS'
                  : stepOption;
              kycModal = this.kycModalService.openModalKycPending(option);
              break;
          }
          return kycModal.pipe(map(() => user));
        }),
      );

      if (!this.localStorage.getItem(environment.localStorageKeys.kycLaunched)) {
        this.localStorage.setItem(environment.localStorageKeys.kycLaunched, 'true');
        this.kycLaunched.next(true);
      }
    }

    return sourceObs.pipe(switchMap(() => this.launchVerification()));
  }

  launchVerification(lastStep?: VerificationStep): Observable<void> {
    this.localStorage.setItem(environment.localStorageKeys.kycLaunched, 'true');
    this.kycLaunched.next(true);

    return this.userService.getData().pipe(
      first(),
      switchMap((user: User) => {
        if (!user.phoneVerified) {
          this.taskManager.scheduleNewTask(
            TaskContext.PHONE_VERIFIED,
            'launchVerification',
            () => this.launchVerification(VerificationStep.PHONE),
          );

          return from(this.goToPhoneVerification(this.stepsConfiguration, false));
        }

        if (!user.kyc?.submitted) {
          this.resetKycMethod().pipe(first()).subscribe();

          if (
            lastStep !== VerificationStep.REQUIRED_DATA &&
            (environment.id !== 'us' ||
              this.stepsConfiguration === StepConfiguration.PASSPORT)
          ) {
            this.taskManager.scheduleNewTask(
              TaskContext.REQUIRED_DATA,
              'launchVerification',
              () => this.launchVerification(VerificationStep.REQUIRED_DATA),
            );

            return from(this.goToRequiredData(this.stepsConfiguration, false));
          }

          this.router.navigate([
            this.responsiveService.isDesktop()
              ? `/${this.routeMap.register}/${this.routeMap.desktop.user.kyc}`
              : `/m/${this.routeMap.mobile.kyc}`,
          ]);
        } else {
          if (user.kyc?.status === KycStatus.EXPIRED) {
            this.taskManager.scheduleNewTask(
              TaskContext.REQUIRED_DATA,
              'launchVerification',
              () => this.launchVerification(VerificationStep.REQUIRED_DATA),
            );

            return from(
              this.goToRequiredData(this.stepsConfiguration, false, false),
            );
          }
        }

        return of(void 0);
      }),
    );
  }

  launchVerificationFlow(): void {
    this.endpointService
      .getData()
      .pipe(first())
      .subscribe(e => {
        const method = e.kycMethods[this.kycMethodIndex];
        this.kycFlowLauncherService
          .launch(method)
          .pipe(
            switchMap((data: {forms: any; user?: User}) =>
              this.checkAndSaveUser(data),
            ),
            switchMap((data: {forms: any; user?: User}) =>
              this.sendKyc(data.forms, data.user?.kyc.barcodeUsed),
            ),
          )
          .subscribe({
            error: () => {
              this.launchVerificationFlow();
            },
          });
      });
  }

  sendKyc(
    forms: Array<KycSelfieFormGroup | KycCardFormGroup>,
    barcodeUsed: boolean,
  ): Observable<void> {
    let lastNavigation: NavigationInstant;
    this.modalHelperService.openNoActionModal(UploadingImagesDialogComponent, {
      modalOptions: {centered: true, backdrop: 'static'},
    });

    return this.kycService.sendKyc(forms, this.kycMethodIndex).pipe(
      map(() => this.modalHelperService.closeLastModal()),
      tap(() => this.showUploadedDialog(barcodeUsed)),
      switchMap(() => {
        lastNavigation = this.navigationChecker.lastNavigationInstant();
        return this.taskManager.executeInContext(TaskContext.REQUIRED_KYC);
      }),
      map(() => {
        if (!this.navigationChecker.hasNavigatedSince(lastNavigation)) {
          this.router.navigate(['/']);
        }
      }),
      catchError(err => {
        this.modalHelperService.closeLastModal();
        this.errorService.processErrorGlobalContext(
          err,
          'Ha ocurrido un error al realizar la solicitud.',
        );
        return throwError(() => err);
      }),
    );
  }

  checkCardCode(data: any): Observable<CardData> {
    return this.kycService.checkCardCode(data);
  }

  goToPhoneVerification(
    stepConfiguration = StepConfiguration.DEFAULT,
    resetKyc = true,
  ): Promise<void> {
    if (resetKyc) {
      this.kycModeOn = false;
      this.backendError = undefined;
    }
    this.stepsConfiguration = stepConfiguration;
    return this.router.navigate([this.getPhoneUrl()]).then();
  }

  goToRequiredData(
    stepConfiguration = this.stepsConfiguration,
    resetKyc = true,
    forcePassport = true,
  ): Promise<void> {
    if (resetKyc) {
      this.kycModeOn = false;
      this.backendError = undefined;
    }
    this.stepsConfiguration = stepConfiguration;
    return this.router
      .navigate([this.getRequiredDataUrl()], {
        state: {
          forcePassport: forcePassport,
        },
      })
      .then();
  }

  showKycBanner(): Observable<boolean> {
    return zip(this.userService.getData(), this.kycLaunched).pipe(
      map(
        ([user, kycLaunched]: [User, boolean]) =>
          user?.kyc?.status && !user.kyc?.submitted && kycLaunched,
      ),
      mapTo(false), // TODO - remove this after KYC "Fase I"
    );
  }

  setDniType(dniType: DniType): void {
    this.dniType = dniType;

    if (!dniType) {
      const defaultId = environment.kyc.methods.default;
      const keysFind = [...this.dniTypeKycId.entries()].find(
        ({1: value}) => value === defaultId,
      );
      this.dniType = keysFind ? keysFind[0] : undefined;
    }

    const index = this.kycMethods.findIndex(
      m => m.id === this.dniTypeKycId.get(this.dniType),
    );

    this.kycMethodIndex = index === -1 ? 0 : index;
  }

  onCameraError(errorCode): void {
    // NotAllowedError:
    //   También NotFoundError
    //   The user denies permission, or matching media is not available
    // AbortError:
    //   Firefox webcam was already in use.
    // NotReadableError:
    //    Webcam access is allowed but not possible.
    //    Camera is used by some other app or website in the background
    // TrackStartError:
    //    Is a non-spec Chrome-specific version of NotReadableError.
    //    Mirar adaptaciones entre navegadores https://github.com/webrtc/adapter
    let modal;
    if (
      errorCode === 'NotReadableError' ||
      errorCode === 'TrackStartError' ||
      errorCode === 'AbortError'
    ) {
      modal = this.modalHelperService.openOkModal(CameraInUseModalComponent);
    } else if (errorCode === 'NotAllowedError' || errorCode === 'NotFoundError') {
      modal = this.modalHelperService.openOkCancelModal(ModalDialogComponent, {
        componentParams: {
          type: 'ok_only',
          title: {
            // eslint-disable-next-line max-len
            key: 'kyc.steps.verifyIdentity.common.cameraPermissionRequired.titleNoCameraPermissionAfterRetryAndDeny',
          },
          message: {
            // eslint-disable-next-line max-len
            key: 'kyc.steps.verifyIdentity.common.cameraPermissionRequired.noCameraPermissionAfterRetryAndDeny',
          },
        },
      });
    } else {
      modal = this.modalHelperService.openOkModal(ModalDialogComponent, {
        componentParams: {
          type: 'ok_only',
          title: {key: 'check.scanner.camError'},
        },
      });
    }

    modal.subscribe(() => {
      const routes = environment.locale.routes;
      if (this.responsiveService.isDesktop()) {
        this.router.navigate([`/${routes.desktop.profile}`]);
      } else {
        this.router.navigate([
          `/m/${routes.mobile.user}/${routes.mobile.tuloteroSlides.profile}`,
        ]);
      }
    });
  }

  public removeKycLaunched(): void {
    this.localStorage.removeItem(environment.localStorageKeys.kycLaunched);
    this.kycLaunched.next(false);
  }

  private getPhoneUrl(): string {
    return this.responsiveService.isDesktop()
      ? `/${this.routeMap.register}/${this.routeMap.desktop.user.phone}`
      : `/m/${this.routeMap.mobile.userPhone}`;
  }

  private getRequiredDataUrl(): string {
    return this.responsiveService.isDesktop()
      ? `/${this.routeMap.register}/${this.routeMap.desktop.user.data}`
      : `/m/${this.routeMap.mobile.userData}`;
  }

  private calculateShowChristmasTexts(): void {
    if (
      environment.kyc?.dateShowChristmasText?.start &&
      environment.kyc?.dateShowChristmasText?.end
    ) {
      const year = getYear(Date.now());

      const startDate = new Date(
        year,
        11,
        environment.kyc.dateShowChristmasText.start,
      );

      const endDate = new Date(year, 0, environment.kyc.dateShowChristmasText.end);

      this.showChristmasTexts = isPast(startDate) || isFuture(endDate);
    } else {
      this.showChristmasTexts = false;
    }
  }

  private checkAndSaveUser(data: {
    forms: any;
    user?: User;
  }): Observable<{forms: any; user?: User}> {
    return data.user
      ? this.userDao.updateProfile(data.user).pipe(map(() => data))
      : of(data);
  }

  private showUploadedDialog(barcodeUsed: boolean): void {
    let componentParams;
    let modalOptions: NgbModalOptions;
    const defaultParams = {
      mode: this.responsiveService.isDesktop() ? 'normal' : 'fullscreen',
      type: 'ok_only',
      headerImage: '/assets/img/kyc/search-files.png',
      closeOnPopstate: true,
    };

    if (barcodeUsed) {
      componentParams = {
        ...defaultParams,
        title: {key: 'kyc.steps.verifyIdentity.selfie.confirmationBarcode.title'},
        message: {
          key: 'kyc.steps.verifyIdentity.selfie.confirmationBarcode.subtitle',
        },
        accept: {
          key: 'kyc.steps.verifyIdentity.selfie.confirmationBarcode.playNow',
        },
      };
    } else {
      componentParams = {
        ...defaultParams,
        title: {key: 'kyc.steps.verifyIdentity.selfie.confirmationInProgress.title'},
        message: {
          key: !this.showChristmasTexts
            ? 'kyc.steps.verifyIdentity.selfie.confirmationInProgress.subtitle'
            : 'kyc.steps.verifyIdentity.selfie.confirmationInProgress.withdraw.subtitleChristmas',
        },
        footNote: {
          key: !this.showChristmasTexts
            ? 'kyc.steps.verifyIdentity.selfie.confirmationInProgress.note'
            : 'kyc.steps.verifyIdentity.selfie.confirmationInProgress.noteChristmas',
        },
        accept: {key: 'kyc.steps.verifyIdentity.selfie.confirmationInProgress.ok'},
      };
    }

    if (this.responsiveService.isDesktop()) {
      modalOptions = {
        centered: true,
        size: 'lg',
        modalDialogClass:
          'tl-kyc-uploaded-dialog tl-kyc-uploaded-dialog__modal-dialog',
      };
    } else {
      modalOptions = {
        windowClass: 'fullscreen',
        modalDialogClass:
          'tl-kyc-uploaded-dialog tl-kyc-uploaded-dialog__modal-dialog' +
          'init-animation-bottom',
      };

      componentParams = {
        ...componentParams,
        classAnimationIn: 'animation-bottom-in',
        classAnimationOut: 'animation-bottom-out',
      };
    }

    this.modalHelperService.openNoActionModal(ModalDialogComponent, {
      componentParams: componentParams,
      modalOptions: modalOptions,
    });
  }

  private setDefaultKycMethod(defaultId: any): void {
    const keysFind = [...this.dniTypeKycId.entries()].find(
      ({1: value}) => value === defaultId,
    );
    this.dniType = defaultId && keysFind ? keysFind[0] : undefined;
    const index = this.kycMethods.findIndex(m => m.id === defaultId);
    this.kycMethodIndex = index === -1 ? 0 : index;
  }
}
