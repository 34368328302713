import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NgbDropdown, NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, map} from 'rxjs/operators';

import {Country} from '../../../countries/country';
import {CountryService} from '../../../countries/country.service';
import {ENVIRONMENT} from '../../../environment/environment-token';
import {ngModelProvider} from '../../../model/ng-model-config';
import {AbstractNgModel} from '../../abstract-ngmodel';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-input-phone-country',
  templateUrl: './input-phone-country.component.html',
  styleUrls: ['./input-phone-country.component.scss'],
  providers: [ngModelProvider(InputPhoneCountryComponent), NgbDropdownConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPhoneCountryComponent
  extends AbstractNgModel<Country>
  implements OnInit
{
  @Input('value')
  model: Country;

  @Input('disabled')
  disabled = false;

  @Output()
  changeSelection = new EventEmitter<Country>();

  @ViewChild(NgbDropdown, {static: true})
  dropdown: NgbDropdown;

  countries: Array<Country>;

  searchForm: FormControl;

  searchTerm: Observable<string>;

  constructor(
    private cdr: ChangeDetectorRef,
    private config: NgbDropdownConfig,
    private countryService: CountryService,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {
    super();
    this.config.autoClose = 'outside';
  }

  ngOnInit() {
    this.searchForm = new FormControl('');
    this.searchTerm = this.searchForm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term && term.replace('+', '')),
    );

    this.countryService
      .getData()
      .pipe(first())
      .subscribe((countries: Array<Country>) => {
        this.countries = countries;
      });
  }

  writeValue(obj: any) {
    if (
      obj &&
      obj.hasOwnProperty('code') &&
      obj.hasOwnProperty('prefix') &&
      obj.hasOwnProperty('name')
    ) {
      this.model = obj;
    } else {
      // Delay rewrite value to force emit change event
      setTimeout(() => {
        this.changeDefaultValue();
        this.cdr.markForCheck();
      });
    }
  }

  select(country: Country) {
    this.dropdown.close();
    this.model = country;
    this.notify();
  }

  testCountry(country: Country, pattern: RegExp): boolean {
    return (
      !!country.name?.match(pattern) ||
      !!country.prefix?.match(pattern) ||
      !!country.code?.match(pattern)
    );
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private changeDefaultValue(): void {
    const country = this.countries?.find(
      (currentCountry: Country) =>
        currentCountry.code === this.environment.locale.user.defaultPhoneCode,
    );
    this.model = country;
    this.notify();
  }

  private notify(): void {
    this.modelChange(this.model);

    if (this.changeSelection.observed) {
      this.changeSelection.emit(this.model);
    }
  }
}
