import {
  Component,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import {ResponsiveService} from '../../responsive/responsive.service';
import {TranslatableText} from '../../i18n/translatable-text';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({
  selector: 'tl-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormRowComponent {
  @Input()
  name: string | TranslatableText | TemplateRef<any>;

  @Input()
  info: string | TranslatableText | TemplateRef<any>;

  @Input()
  infoCallback: (...args: any[]) => any;

  @Input()
  isInfoOpen = false;

  @Input()
  infoPlacement:
    | Array<'top' | 'bottom' | 'left' | 'right' | 'auto'>
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'auto' = ['top', 'auto'];

  @Input()
  icon: 'info' | 'question' = 'question';

  @Input()
  hideLabel = false;

  @Input()
  largeLabel = false;

  @HostBinding('class.tl-form-row')
  readonly hostClass = true;

  @Input()
  alignContent: 'start' | 'end' | 'center' = this.responsiveService.isDesktop()
    ? 'start'
    : 'end';

  @Input()
  showInfo = true;

  @Input()
  showContent = true;

  constructor(protected responsiveService: ResponsiveService) {}

  // Cast typing to avoid errors in template
  get nameTemplate(): TemplateRef<any> {
    return this.name ? (this.name as TemplateRef<any>) : null;
  }

  get nameText(): string | TranslatableText {
    return this.name as string | TranslatableText;
  }

  get infoTemplate(): TemplateRef<any> {
    return this.info ? (this.info as TemplateRef<any>) : null;
  }

  get infoText(): string | TranslatableText {
    return this.info as string | TranslatableText;
  }

  openInfo() {
    if (this.infoCallback) {
      this.infoCallback();
    }
    if (this.info) {
      this.isInfoOpen = !this.isInfoOpen;
    }
  }
}
