import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PaginationData} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractDao} from '../../../common/model/abstract.dao';

import {News} from './news';

@Injectable({providedIn: 'root'})
export class NewsDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  getUserNews(index = 0): Observable<PaginationData<News>> {
    return this.doGetNews(this.baseUrl + '/users/news?firstResult=' + index);
  }

  getNews(index = 0): Observable<PaginationData<News>> {
    return this.doGetNews(this.baseUrl + '/newsflash?firstResult=' + index);
  }

  visitUserNews(id: number): Observable<News> {
    return this.http.get<News>(this.baseUrl + '/users/news/' + id);
  }

  visitNews(id: number): Observable<News> {
    return this.http.get<News>(this.baseUrl + '/guestnews/' + id);
  }

  private doGetNews(url: string): Observable<PaginationData<News>> {
    return this.http.get(url).pipe(
      map((obj: any) => {
        let news = obj.news
          ? obj.news.map(article => News.createFromBackend(article))
          : [];

        return new PaginationData<News>(news, obj.total);
      }),
    );
  }
}
