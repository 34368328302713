import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {TriggerStyleModule} from '../../../styles-triggers/trigger-style.module';

import {InputCreditCardComponent} from './input-credit-card.component';
import {InputExpiryDateComponent} from './input-expiry-date.component';

@NgModule({
  imports: [CommonModule, TriggerStyleModule],
  exports: [InputExpiryDateComponent, InputCreditCardComponent],
  declarations: [InputExpiryDateComponent, InputCreditCardComponent],
})
export class InputCreditCardModule {}
