export enum LotteryService {
  DIGITAL = 'digital',
  SHIPMENT = 'shipment',
  COLLECTION = 'collection',
  RESERVES = 'reserves',
  COMPANIES = 'companies',
  SCANNER = 'scanner',
}

export function isShippingService(service: LotteryService): boolean {
  return (
    service === LotteryService.SHIPMENT || service === LotteryService.COLLECTION
  );
}
