import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style,
} from '@angular/animations';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {fromEvent} from 'rxjs';
import {first} from 'rxjs/operators';

import {ResponsiveService} from '../responsive/responsive.service';
import {isNumeric} from '../util/core/number';

import {SlideComponent} from './slide.component';

/**
 * Slide Component
 *
 * Contains several slides that can be swiped or dragged to cycle between them.
 *
 * Has optional markers at the bottom to show the total and current slide.
 */
// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-slides',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlidesComponent implements AfterViewInit, AfterContentInit, OnInit {
  /**
   * Minimum speed to recognize the drag as a swipe gesture, in px/ms;
   */
  static readonly SWIPE_THRESHOLD = 0.3;

  /**
   * Percent width from wich the dragged slide will snap to the next/previous.
   */
  static readonly SNAP_THRESHOLD = 0.6;

  /**
   * Base duration for the snapping animation, in seconds.
   */
  static readonly BASE_DURATION = 0.05;

  /**
   * If true will show dots at the bottom highlighted with the current slide.
   */
  @Input()
  @HostBinding('class.with-markers')
  markers = false;

  /**
   * Index of the slide to show when the component loads.
   */
  @Input()
  initialSlide = 0;

  /**
   * Show this amount of the next/previous slide on the sides of the current one
   *
   * Note: if this is nonzero slide width must be 100%-bleedSlidePercent*2
   */
  @Input()
  bleedSlidePercent = 0;

  /**
   * Emmits the slide index when the shown slide changes.
   */
  @Output()
  slideChange = new EventEmitter<number>();

  @ContentChildren(SlideComponent, {read: ElementRef})
  slides: QueryList<ElementRef>;

  totalSlides: number;

  /**
   * 0-indexed index of the currently shown slide
   */
  currentSlide = 0;

  @ViewChild('rail', {static: true})
  protected rail: ElementRef;

  private player: AnimationPlayer;

  private currentWidth: number;

  @HostBinding('class.no-markers')
  get noMarkers() {
    return this.totalSlides < 1;
  }

  constructor(
    private animationBuilder: AnimationBuilder,
    private changeDetector: ChangeDetectorRef,
    protected element: ElementRef,
    protected zone: NgZone,
    protected renderer: Renderer2,
    private responsiveService: ResponsiveService,
  ) {}

  ngOnInit() {
    this.currentSlide = this.initialSlide;

    this.responsiveService.resize.subscribe(() => {
      this.currentWidth = this.element.nativeElement.offsetWidth;

      this.setOffset();
      this.changeDetector.markForCheck();
    });
  }

  ngAfterViewInit() {
    this.currentWidth = this.element.nativeElement.offsetWidth;
    this.setOffset();
  }

  ngAfterContentInit() {
    this.setupSlides();

    this.slides.changes.subscribe(() => {
      this.currentSlide = 0;
      this.setupSlides();
    });
  }

  /**
   * Advances to the next slide if possible.
   *
   * @param duration duration of the sliding animation, in seconds
   */
  @HostListener('tlswipeleft')
  goNext(duration = 0.2) {
    if (this.currentSlide < this.totalSlides - 1) {
      this.goSlide(this.currentSlide + 1, true, duration);
    }
  }

  /**
   * Advances to the previous slide if possible.
   *
   * @param duration duration of the sliding animation, in seconds
   */
  @HostListener('tlswiperight')
  goPrevious(duration = 0.2) {
    if (this.currentSlide > 0) {
      this.goSlide(this.currentSlide - 1, true, duration);
    }
  }

  /**
   * Changes to the specified slide.
   *
   * @param index index of the slide to show
   * @param sendEvent wether or not should an event be fired after
   * the change
   * @param duration duration of the sliding animation, in seconds
   */
  goSlide(index: number, sendEvent = false, duration = 0.2) {
    this.currentSlide = Math.max(0, Math.min(this.totalSlides - 1, index));
    this.startSnapAnimationManual(duration);

    if (sendEvent) {
      this.notify();
    }
  }

  protected setOffset() {
    const pos =
      this.currentSlide *
        -(((100 - this.bleedSlidePercent * 2) / 100) * this.currentWidth) +
      (this.bleedSlidePercent / 100) * this.currentWidth;

    this.renderer.setStyle(
      this.rail.nativeElement,
      'transform',
      `translateZ(0) translate(${pos}px, 0)`,
    );
  }

  private setupSlides() {
    setTimeout(() => {
      this.totalSlides = this.slides.length;
      this.currentWidth = this.element.nativeElement.offsetWidth;
      this.changeDetector.markForCheck();

      this.setOffset();
    });
  }

  private currentTransform() {
    return `translateZ(0) translate(
            ${
              this.currentSlide * -(100 - this.bleedSlidePercent * 2) +
              +this.bleedSlidePercent
            }%, 0)`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // @ts-ignore we want to keep this if we need to use it
  private startSnapAnimation(duration: number) {
    duration = isNumeric(duration) ? duration : 0.2;

    const currentTransform = this.currentTransform();
    const factory = this.animationBuilder.build([
      style({transform: '*'}),
      animate(`${duration}s ease-out`, style({transform: currentTransform})),
    ]);

    let player = factory.create(this.rail.nativeElement);
    player.onDone(() => {
      player.destroy();

      this.renderer.setStyle(this.rail.nativeElement, 'transform', currentTransform);
    });
    this.player = player;
    this.player.play();

    if (this.markers) {
      this.changeDetector.markForCheck();
    }
  }

  private startSnapAnimationManual(duration: number) {
    duration = isNumeric(duration) ? duration : 0.2;

    fromEvent(this.rail.nativeElement, 'transitionend')
      .pipe(first())
      .subscribe(() => {
        this.renderer.setStyle(
          this.rail.nativeElement,
          'transition-duration',
          'unset',
        );
        this.renderer.setStyle(
          this.rail.nativeElement,
          'transition-timing-function',
          'unset',
        );
      });

    this.renderer.setStyle(
      this.rail.nativeElement,
      'transition-duration',
      duration + 's',
    );
    this.renderer.setStyle(
      this.rail.nativeElement,
      'transition-timing-function',
      'ease-out',
    );
    this.renderer.setStyle(
      this.rail.nativeElement,
      'transform',
      this.currentTransform(),
    );

    if (this.markers) {
      this.changeDetector.markForCheck();
    }
  }

  private notify() {
    this.zone.run(() => {
      if (this.slideChange.observed) {
        this.slideChange.emit(this.currentSlide);
      }
    });
  }
}
