import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AbstractNgModel} from '../../forms/abstract-ngmodel';
import {ngModelProvider} from '../../model/ng-model-config';
import {SelectableWeekday} from './day-week-selector.types';
import {ENVIRONMENT} from '../../environment/environment-token';

@Component({
  selector: 'tl-day-week-selector',
  templateUrl: './day-week-selector.component.html',
  styleUrls: ['./day-week-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ngModelProvider(DayWeekSelectorComponent)],
})
export class DayWeekSelectorComponent
  extends AbstractNgModel<SelectableWeekday[]>
  implements OnInit
{
  @Input('value')
  model: SelectableWeekday[];

  @Input()
  days: SelectableWeekday[] = [];

  @Input()
  multiple = false;

  @Input()
  required = false;

  @Output()
  preventedDeselect = new EventEmitter<void>();

  touched = false;

  disabled = false;

  control = new FormControl();

  @HostBinding('class.limit-xs') get isLimitXs() {
    return this.maxBreakpoint === 'xs';
  }

  @HostBinding('class.limit-sm') get isLimitSm() {
    return this.maxBreakpoint === 'sm';
  }

  @HostBinding('class.limit-lg') get isLimitLg() {
    return this.maxBreakpoint === 'lg';
  }

  private maxBreakpoint: 'xs' | 'sm' | 'lg' =
    this.environment.features.subscribe.maxDaysSelectorBreakpoint;

  constructor(@Inject(ENVIRONMENT) private environment: Record<string, any>) {
    super();
  }

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      this.model = value;

      this.markTouched();
      this.modelChange(value);
    });
  }

  writeValue(value: SelectableWeekday[]) {
    this.control.setValue(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markTouched(): void {
    this.touched = true;
    this.modelTouch();
  }
}
