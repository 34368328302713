import {NgModule} from '@angular/core';
import {ASYNC_LOCAL_STORAGE_TOKEN, AsyncLocalStorageModule} from 'common';
import {environment} from '~environments/environment';

import {TuLoteroLocalStorageService} from './tu-lotero-local-storage.service';

@NgModule({
  imports: [AsyncLocalStorageModule],
  providers: [
    TuLoteroLocalStorageService,
    {provide: ASYNC_LOCAL_STORAGE_TOKEN, useValue: environment.localStorage.token},
  ],
})
export class TuLoteroLocalStorageModule {}
