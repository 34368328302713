import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {ModalHelperService} from '../modal/modal-helper.service';
import {VideoModalComponent} from './video-modal/video-modal.component';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tl-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent {
  @HostBinding('class.tl-video')
  readonly hostClass = true;

  @Input()
  duration: string;

  @Input()
  thumbnail: string;

  @Input()
  url: string;

  @Input()
  title: string;
  modalRef: NgbModalRef;

  get backgroundThumbnail(): string {
    return `linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.6) 90%),
      url('${this.thumbnail}')`;
  }

  constructor(private modalHelperService: ModalHelperService) {}

  play(): void {
    this.modalRef = this.modalHelperService.openModal(VideoModalComponent, {
      componentParams: {
        url: this.url,
      },
      modalOptions: {
        windowClass: 'fullscreen',
      },
    });
  }

  stop() {
    this.modalRef?.close();
  }
}
