<div class="wrapper" *ngIf="!error">
  <div *ngIf="!loading && showHelp" class="help">
    <div class="backdrop top">
      <p>{{ title }}</p>
    </div>
    <div class="barcode-row">
      <div class="backdrop left"></div>
      <div class="barcode"></div>
      <div class="backdrop right"></div>
    </div>
    <div class="backdrop bottom"></div>
  </div>

  <div *ngIf="loading" class="loader">
    <i class="icon-spin6-icon tl-icon-spin"></i>
  </div>

  <div>
    <video #videoBarcodeDecode autoplay="false"></video>
  </div>
</div>
