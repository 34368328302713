import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {fromEvent, merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {MenuNode} from '../menu-node';

import {MenuItemEvent} from './menu-item-event';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-dropdown-menu-item',
  templateUrl: './dropdown-menu-item.component.html',
  styleUrls: ['./dropdown-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuItemComponent implements OnChanges, OnDestroy {
  @Input()
  itemTemplate: TemplateRef<any>;

  /**
   * If used as header shows the downwards arrow.
   */
  @Input()
  @HostBinding('class.menu-header')
  asHeader = false;

  @Output()
  hoverChange = new Subject<MenuItemEvent>();

  @HostBinding('class.divide-bottom')
  divideBottom = false;

  @HostBinding('class.divide-top')
  divideTop = false;

  @HostBinding('class.selected')
  selected = false;

  @HostBinding('class.disabled')
  disabled = false;

  node: MenuNode;

  private nodeChange = new Subject<void>();

  private destroySubject = new Subject<void>();

  @Input('node')
  set nodeSetter(node: MenuNode) {
    this.node = node;
    this.divideBottom = node.divideBottom;
    this.divideTop = node.divideTop;

    this.selected = node.selected && node.selected();
    this.disabled = node.disabled && node.disabled();
  }

  constructor(public elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.nodeChange.next();

    if (
      !this.asHeader &&
      !this.disabled &&
      this.node &&
      this.node.children &&
      !!this.node.children.length
    ) {
      const changesOrDestroy = merge(this.nodeChange, this.destroySubject);
      fromEvent(this.elementRef.nativeElement, 'mouseenter')
        .pipe(takeUntil(changesOrDestroy))
        .subscribe(() => this.hoverChange.next({hover: true, component: this}));

      fromEvent(this.elementRef.nativeElement, 'mouseleave')
        .pipe(takeUntil(changesOrDestroy))
        .subscribe(() => this.hoverChange.next({hover: false, component: this}));
    }
  }

  ngOnDestroy(): void {
    this.hoverChange.complete();
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
