import {Inject, Injectable} from '@angular/core';
import {NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EMPTY, from, merge, Observable, of} from 'rxjs';
import {catchError, ignoreElements} from 'rxjs/operators';

import {CustomNgbModal} from './custom-ngb-modal';
import {ModalHelperOptions} from './modal-helper-options';
import {MODAL_HELPER_OPTIONS_CONFIG} from './modal-helper-options-config';
import {MessageHookStorageService} from '../message-hooks/message-hook-storage.service';

@Injectable({providedIn: 'root'})
export class ModalHelperService {
  lastModal: NgbModalRef;

  constructor(
    @Inject(MODAL_HELPER_OPTIONS_CONFIG)
    protected modalHelperConfig: NgbModalOptions,
    protected ngbModal: CustomNgbModal,
    private messageHookStorageService: MessageHookStorageService,
  ) {}

  /**
   * Always emits through next regardless of the action done in the modal
   * If the modal is dismissed or closed it also emits
   */
  public openSingleActionModal(
    component: any,
    options?: ModalHelperOptions,
  ): Observable<any> {
    return this.openOkCancelModal(component, options).pipe(catchError(() => of({})));
  }

  /**
   * Never emits regardless of the action done in the modal
   * If the modal is dismissed or closed it does not emit either
   */
  public openNoActionModal(
    component: any,
    options?: ModalHelperOptions,
  ): Observable<never> {
    return this.openSingleActionModal(component, options).pipe(ignoreElements());
  }

  /**
   * Only emits when clicking 'Ok' action
   */
  public openOkModal(component: any, options?: ModalHelperOptions): Observable<any> {
    return this.openOkCancelModal(component, options).pipe(catchError(() => EMPTY));
  }

  /**
   * Keeps original NgbModal behaviour, it emits in 'Ok' action and throws error
   * otherwise
   */
  public openOkCancelModal(
    component: any,
    options?: ModalHelperOptions,
  ): Observable<any> {
    if (this.checkIfUserWantIgnoreThisModal(options)) {
      return of(void 0);
    }
    return from(this.openModal(component, options).result);
  }

  /**
   * Facade for opening a modal
   */
  public openModal(component: any, options?: ModalHelperOptions): NgbModalRef {
    this.lastModal = this.ngbModal.open(component, this.getModalOptions(options));

    merge(this.lastModal.closed, this.lastModal.dismissed).subscribe(
      () => (this.lastModal = null),
    );

    // Pass params to component
    if (options && options.componentParams) {
      Object.keys(options.componentParams).forEach(
        key =>
          (this.lastModal.componentInstance[key] = options.componentParams[key]),
      );
    }

    return this.lastModal;
  }

  setLastModalInput(key: string, value: any): void {
    if (this.lastModal?.componentInstance) {
      this.lastModal.componentInstance[key] = value;
    }
  }

  closeLastModal(): void {
    this.lastModal?.close();
  }

  private getModalOptions(options?: ModalHelperOptions): NgbModalOptions {
    let modalOptions =
      options && options.modalOptions ? options.modalOptions : {centered: true};
    return Object.assign({}, this.modalHelperConfig, modalOptions);
  }

  /**
   * Check if the user wants to ignore this modal in local storage
   */
  private checkIfUserWantIgnoreThisModal(options: ModalHelperOptions): boolean {
    if (
      options &&
      options.componentParams &&
      options.componentParams['avoidModalKey']
    ) {
      return this.messageHookStorageService.exists(
        options.componentParams['avoidModalKey'],
      );
    } else {
      return false;
    }
  }
}
