import {Serializable, SerializableDisableWarning} from 'common';

class SerieInternal {
  @SerializableDisableWarning()
  fractions: Array<number>;

  constructor(public number: number, fractions: Array<number>) {
    this.fractions = fractions;
  }

  getFractions(): string {
    let s = '';
    this.fractions.forEach(p => (s += p.toString() + ' '));
    return s;
  }
}

export class Serie extends Serializable(SerieInternal) {}
