import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NgbDropdown, NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, takeUntil} from 'rxjs/operators';

import {Country} from '../../countries/country';
import {CountryService} from '../../countries/country.service';
import {ngModelProvider} from '../../model/ng-model-config';
import {Destroyable} from '../../util/destroyable';
import {AbstractNgModel} from '../abstract-ngmodel';
import {TranslatableText} from '../../i18n/translatable-text';

@Component({
  selector: 'tl-input-country',
  templateUrl: './input-country.component.html',
  styleUrls: ['./input-country.component.scss'],
  providers: [ngModelProvider(InputCountryComponent), NgbDropdownConfig],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCountryComponent
  extends AbstractNgModel<Country>
  implements OnInit
{
  @HostBinding('class.tl-input-country')
  readonly hostClass = true;

  @Input('value')
  model: Country;

  @Input('disabled')
  disabled = false;

  @Input()
  emptySelection: string | TranslatableText = '';

  @Output()
  changed = new EventEmitter<Country>();

  @ViewChild(NgbDropdown, {static: true})
  dropdown: NgbDropdown;

  countries: Array<Country>;

  searchForm: FormControl;

  searchTerm: Observable<string>;

  @Destroyable()
  private destroy = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private config: NgbDropdownConfig,
    private countryService: CountryService,
  ) {
    super();
    this.config.autoClose = 'outside';
  }

  ngOnInit(): void {
    this.searchForm = new FormControl('');
    this.searchTerm = this.searchForm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );

    this.countryService
      .getData()
      .pipe(takeUntil(this.destroy), first())
      .subscribe(countries => {
        this.countries = countries;
      });
  }

  override writeValue(obj: any) {
    if (
      obj &&
      obj.hasOwnProperty('code') &&
      obj.hasOwnProperty('prefix') &&
      obj.hasOwnProperty('name')
    ) {
      this.model = obj;
    } else {
      // Delay rewrite value to force emit change event
      setTimeout(() => {
        this.model = this.findCountryByCode(obj);
        this.cdr.markForCheck();
      });
    }
  }

  select(country: Country) {
    this.dropdown.close();
    this.model = country;
    this.searchForm.setValue('');
    this.notify();
  }

  testCountry(country: Country, pattern: RegExp): boolean {
    return (
      !!country.name?.match(pattern) ||
      !!country.prefix?.match(pattern) ||
      !!country.code?.match(pattern)
    );
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private findCountryByCode(code: string): Country {
    return this.countries.find((country: Country) => country.code === code);
  }

  private notify(): void {
    this.modelChange(this.model);

    if (this.changed.observed) {
      this.changed.emit(this.model);
    }
  }
}
