<tl-select
  *ngFor="let f of forms; let i = index; let firstRow = first; let lastRow = last"
  [formControl]="f"
  [multiple]="multiple"
  [alwaysOneSelected]="alwaysOneSelected"
  [disableSelect]="disableSelect"
  (selectItem)="selectItem.emit()"
  (preventedDeselect)="preventedDeselect.emit($event)"
  [max]="max"
  class="tl-set-table__row"
  [ngClass]="{
    'tl-set-table__row--first': firstRow,
    'tl-set-table__row--last': lastRow,
    'tl-set-table__row--odd': i % 2 !== 0
  }">
  <tl-select-item
    #choiceItem
    *ngFor="
      let v of valueSet;
      let j = index;
      let firstCol = first;
      let lastCol = last
    "
    class="tl-set-table__col"
    [disabled]="v?.disabled"
    [value]="v.value || v"
    [ngClass]="{
      'tl-set-table__col--first': firstCol,
      'tl-set-table__col--last': lastCol
    }">
    <tl-set-table-item
      *ngIf="!itemTemplate; else customItem"
      [label]="v.label || v"
      [selected]="choiceItem.selected"
      [disabled]="choiceItem.disabled"
      class="tl-set-table__item"
      [ngClass]="{
        'tl-set-table__item--last-column': lastCol,
        'tl-set-table__item--last-row': lastRow
      }">
    </tl-set-table-item>

    <ng-template
      #customItem
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{
        $implicit: choiceItem.selected,
        value: v,
        rowIndex: i,
        columnIndex: j,
        first: firstRow,
        last: lastRow
      }">
    </ng-template>
  </tl-select-item>
</tl-select>
