import {
  animate,
  animation,
  AnimationReferenceMetadata,
  group,
  query,
  sequence,
  style,
  useAnimation,
} from '@angular/animations';

const paginationSlide: AnimationReferenceMetadata = animation([
  sequence([
    group([
      query(
        ':leave',
        [
          style({
            transform: 'translate3d({{leaveStart}}, 0, 0)',
            position: 'absolute',
          }),
          animate(
            '{{timing}}',
            style({transform: 'translate3d({{leaveEnd}}, 0, 0)'}),
          ),
        ],
        {optional: true},
      ),
      query(
        ':enter',
        [
          style({
            transform: 'translate3d({{enterStart}}, 0, 0)',
            position: 'absolute',
          }),
          animate(
            '{{timing}}',
            style({transform: 'translate3d({{enterEnd}}, 0, 0)'}),
          ),
        ],
        {optional: true},
      ),
    ]),
  ]),
]);

export function toLeft(): AnimationReferenceMetadata {
  return useAnimation(paginationSlide, {
    params: {
      enterStart: '100%',
      enterEnd: '0%',
      leaveStart: '0%',
      leaveEnd: '-100%',
      timing: '200ms ease-in-out',
    },
  });
}

export function toRight(): AnimationReferenceMetadata {
  return useAnimation(paginationSlide, {
    params: {
      enterStart: '-100%',
      enterEnd: '0%',
      leaveStart: '0%',
      leaveEnd: '100%',
      timing: '200ms ease-in-out',
    },
  });
}
