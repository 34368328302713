import {Directive, Input} from '@angular/core';

import {SwitchComponent} from './switch.component';

@Directive({
  selector:
    'tl-switch[tlStringBoolean][formControl],' +
    ' tl-switch[tlStringBoolean][formControlName], tl-switch[tlStringBoolean][ngModel]',
})
export class SwitchStringBooleanDirective {
  @Input('true')
  private trueValue: string;
  @Input('false')
  private falseValue: string;

  constructor(private sw: SwitchComponent) {
    const writeValueOriginal = this.sw.writeValue.bind(this.sw);
    this.sw.writeValue = value => writeValueOriginal(this.parser(value));

    const registerOriginal = this.sw.registerOnChange.bind(this.sw);
    this.sw.registerOnChange = fn => registerOriginal(v => fn(this.formatter(v)));
  }

  parser(value) {
    return value === this.trueValue;
  }

  formatter(value) {
    return value ? this.trueValue : this.falseValue;
  }
}
