import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {DefaultRequestInterceptor} from './default-request.interceptor';
import {TimestampHeaderInterceptor} from './timestamp-header-interceptor';
import {LanguageHeaderInterceptor} from './language-header-interceptor';

@NgModule({})
export class AppHttpModule {
  static withInterceptors(): ModuleWithProviders<AppHttpModule> {
    return {
      ngModule: AppHttpModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DefaultRequestInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TimestampHeaderInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LanguageHeaderInterceptor,
          multi: true,
        },
        // {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi:
        // true},
      ],
    };
  }
}
