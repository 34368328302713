import {Injectable, Injector} from '@angular/core';
import {
  AlertsService,
  LocalStorage,
  Logger,
  MailService,
  ResponsiveService,
  Shareable,
  ShareAPIService,
  SmsUriService,
  TelegramService,
  TranslatableText,
  TranslationsService,
  TwitterService,
  WhatsappService,
} from 'common';
import {Observable, of, zip} from 'rxjs';
import {catchError, switchMap, take, tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {ErrorService} from '../../error/error.service';

import {ShareChannel} from './share-channel';
import {SHARE_SERVICE_RESOLVER} from './share-service-resolver';

@Injectable({providedIn: 'root'})
export class ShareService {
  static readonly STORAGE_COUNTER_SHARE_KEY =
    environment.localStorageKeys.counterShares;

  private injector: Injector;

  private isShareSupported = false;

  constructor(
    private alertsService: AlertsService,
    private errorService: ErrorService,
    private localStorage: LocalStorage,
    parentInjector: Injector,
    private translationsService: TranslationsService,
  ) {
    this.isShareSupported = ShareAPIService.isAvailable();

    this.injector = Injector.create({
      providers: [
        {provide: MailService, useClass: MailService, deps: []},
        {provide: TwitterService, useClass: TwitterService, deps: ['window']},
        {
          provide: WhatsappService,
          useClass: WhatsappService,
          deps: [ResponsiveService, 'window'],
        },
        {provide: TelegramService, useClass: TelegramService, deps: ['window']},
        {provide: ShareAPIService, useClass: ShareAPIService, deps: [Logger]},
        {provide: SmsUriService, useClass: SmsUriService, deps: []},
      ],
      parent: parentInjector,
    });
  }

  hasShareApi(): boolean {
    return this.isShareSupported;
  }

  share(
    title: string | TranslatableText,
    text: string | TranslatableText,
    via: ShareChannel,
    files?: Array<File>,
  ): Observable<any> {
    let shareService = this.injector.get<Shareable>(SHARE_SERVICE_RESOLVER[via]);

    return zip([
      (title as TranslatableText)?.key
        ? this.translationsService.getCompiledTranslation(
            (title as TranslatableText).key,
            (title as TranslatableText).keyData,
          )
        : of(title as string),
      (text as TranslatableText)?.key
        ? this.translationsService.getCompiledTranslation(
            (text as TranslatableText).key,
            (text as TranslatableText).keyData,
          )
        : of(text as string),
    ]).pipe(
      take(1),
      switchMap(([titleMsg, textMsg]) => {
        return shareService.share(titleMsg, textMsg, files).pipe(
          tap(() => {
            if (shareService.notify) {
              this.alertsService.notifySuccess({
                key: 'games.clubs.shareComponent.success',
              });
            }
          }),
          catchError(e =>
            of(e).pipe(
              tap(ex =>
                this.errorService.processErrorGlobalContext(ex, {
                  key: 'games.clubs.shareComponent.error',
                }),
              ),
            ),
          ),
        );
      }),
    );
  }

  getShareCounter(phone: string): number {
    const counterSharesDirty: string = this.localStorage.getItem(
      ShareService.STORAGE_COUNTER_SHARE_KEY,
    );
    const counterShares = counterSharesDirty
      ? (JSON.parse(counterSharesDirty) as {[key: string]: number})
      : {};
    return counterShares[phone];
  }

  incrementShareCount(phone: string): void {
    const counterSharesDirty: string = this.localStorage.getItem(
      ShareService.STORAGE_COUNTER_SHARE_KEY,
    );
    const counterShares = counterSharesDirty
      ? (JSON.parse(counterSharesDirty) as {[key: string]: number})
      : {};
    counterShares[phone] = (counterShares[phone] ? counterShares[phone] : 0) + 1;

    this.localStorage.setItem(
      ShareService.STORAGE_COUNTER_SHARE_KEY,
      JSON.stringify(counterShares),
    );
  }
}
