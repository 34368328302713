import {Comparator} from 'common';

import {GenericTicket} from '../../games/tickets/data/generic-ticket';

export class ShipmentTicketComparator implements Comparator<GenericTicket> {
  constructor(private selectedTickets: Map<number, GenericTicket>) {
    this.compare = this.compare.bind(this);
  }

  /**
   * Order criteria:
   *  - Shipped tickets after non-shipped ones
   *  - Already selected before not selected tickets
   *  - When all of the above are the same order by id
   */
  compare(ticket1: GenericTicket, ticket2: GenericTicket) {
    let value1 = ticket1.shipmentInfo.hasShipment() ? 0 : 10;
    let value2 = ticket2.shipmentInfo.hasShipment() ? 0 : 10;

    value1 += this.selectedTickets.has(ticket1.id) ? 1 : 0;
    value2 += this.selectedTickets.has(ticket2.id) ? 1 : 0;

    if (value1 === value2) {
      return ticket1.id - ticket2.id;
    } else {
      return value2 - value1;
    }
  }
}
