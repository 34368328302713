import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {Router} from '@angular/router';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  display: boolean;

  @Input('src')
  link: string;

  @Input()
  headerTemplate: TemplateRef<any>;

  @Output()
  toggle = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (typeof this.display !== 'boolean') {
      this.display = true;
    }
  }

  onClick() {
    if (this.link) {
      this.router.navigate([this.link]);
    } else {
      this.toggleMenu();
    }
  }

  private toggleMenu() {
    this.display = !this.display;
    this.toggle.emit(this.display);
  }
}
