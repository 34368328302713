import {Injectable, Injector, Type} from '@angular/core';

import {SessionService} from '../../../../user/auth/session.service';
import {GameMetadata} from '../../../game-metadata/data/game-metadata';
import {LotteryDao} from '../../../lottery/data/lottery.dao';
import {AbstractLotterySearch} from '../search/abstract-lottery-search';
import {LotenalLotterySearchService} from '../search/lotenal-lottery-search.service';
import {SelaeLotterySearchService} from '../search/selae-lottery-search.service';

import {DynamicLotteryBetFormService} from './dynamic-lottery-bet-form.service';
import {GenericLotterySearchService} from '../search/generic-lottery-search.service';
import {SearchLotteryType} from '../../../game-metadata/data/search-lottery-type';

@Injectable({providedIn: 'root'})
export class DynamicTicketSearchService {
  private injector: Injector;

  constructor(parentInjector: Injector) {
    this.injector = Injector.create({
      providers: [
        {
          provide: SelaeLotterySearchService,
          useClass: SelaeLotterySearchService,
          deps: [LotteryDao, SessionService],
        },
        {
          provide: LotenalLotterySearchService,
          useClass: LotenalLotterySearchService,
          deps: [DynamicLotteryBetFormService, LotteryDao, SessionService],
        },
        {
          provide: GenericLotterySearchService,
          useClass: GenericLotterySearchService,
          deps: [DynamicLotteryBetFormService, LotteryDao, SessionService],
        },
      ],
      parent: parentInjector,
    });
  }

  get(type: Type<any>): AbstractLotterySearch {
    return this.injector.get(type);
  }

  getInstance(gameMetadata: GameMetadata): AbstractLotterySearch {
    switch (gameMetadata.searchLotteryType) {
      case SearchLotteryType.GENERIC:
        const searchService = this.injector.get<AbstractLotterySearch>(
          GenericLotterySearchService,
        );
        searchService.setGameMetadata(gameMetadata);
        return searchService;
      case SearchLotteryType.SELAE:
        const selaeSearchService = this.injector.get<AbstractLotterySearch>(
          SelaeLotterySearchService,
        );
        selaeSearchService.setGameMetadata(gameMetadata);
        return selaeSearchService;
      case SearchLotteryType.LOTENAL:
        const lotenalSearchService = this.injector.get<AbstractLotterySearch>(
          LotenalLotterySearchService,
        );
        lotenalSearchService.setGameMetadata(gameMetadata);
        return lotenalSearchService;
    }
  }
}
