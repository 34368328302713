import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {isToday} from 'date-fns';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

import {SelectableRaffle} from '../../selectable-raffles/data/selectable-raffle';
import {UpcomingGamesService} from '../../upcoming/data/upcoming-games.service';

@Injectable({providedIn: 'root'})
export class RafflesPlayService extends AbstractObservableDataService<
  Array<SelectableRaffle>
> {
  constructor(private upcomingGamesService: UpcomingGamesService) {
    super();
  }

  createForGame(
    id: string,
    excludeClosed = true,
  ): Observable<Array<SelectableRaffle>> {
    return this.upcomingGamesService.getGamesByGameId(id).pipe(
      map(games =>
        games.filter(game => {
          return excludeClosed
            ? game.isAvailable() || game.isBookingMode()
            : game.isAfterOpenning();
        }),
      ),
      map(games => games.map(game => SelectableRaffle.createFromUpcomingGame(game))),
      tap(dates => this._data.next(dates)),
    );
  }

  isFirstRaffleToday(): Observable<boolean> {
    return this._data.pipe(
      filter(list => !!list.length),
      map(list => list[0]),
      map(r => isToday(r.closeDate)),
    );
  }

  hasCompanyLottery(raffleId: number): Observable<boolean> {
    return this.upcomingGamesService
      .getGameByRaffleId(raffleId)
      .pipe(map(game => game && game.hasCompanyClubs));
  }

  isSpecialChristmasLottery(raffleId: number): Observable<boolean> {
    return this.upcomingGamesService
      .getGameByRaffleId(raffleId)
      .pipe(
        map(game => game && (game.uiMetadata?.navidad || game.uiMetadata?.ninyo)),
      );
  }

  getById(raffleId: number): Observable<SelectableRaffle> {
    return this._data.pipe(
      map(raffles => raffles.find(raffle => raffle.id === raffleId)),
    );
  }
}
