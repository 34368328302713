import {environment} from '~environments/environment';

import {MoneyActivity} from './money-activity';

export function mergeMoneyActivities(
  dest: Array<MoneyActivity>,
  source: Array<MoneyActivity>,
): Array<MoneyActivity> {
  source.reverse().forEach((activity: MoneyActivity) => {
    let index = dest.findIndex(m => m.id === activity.id);
    if (index < 0) {
      dest.unshift(activity);
    } else {
      dest[index] = activity;
    }
  });

  return [...dest];
}

export function reduceMoneyActivities(
  moneyActivities: Array<MoneyActivity>,
): Array<MoneyActivity> {
  const maxMoneyActivities = environment.allInfo.reduce.maxMoneyActivies;

  if (moneyActivities && moneyActivities.length > maxMoneyActivities) {
    moneyActivities.splice(maxMoneyActivities, moneyActivities.length);
  }

  return [...moneyActivities];
}
