import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {StepFlowSection} from '../step-flow';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-step-flow',
  templateUrl: './step-flow.component.html',
  styleUrls: ['./step-flow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepFlowComponent {
  @Input()
  step: StepFlowSection;
}
