import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CustomHttpUrlEncondingCodec} from 'common';
import {Observable} from 'rxjs';

import {AbstractDao} from '../../common/model/abstract.dao';

import {LoginResponse} from './login-response';
import {RegisterBasic} from './register-basic';
import {User} from './user';
import {UserCredentials} from './usercredentials';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserDao extends AbstractDao {
  constructor(protected http: HttpClient) {
    super();
  }

  register(data: RegisterBasic, disableGlobalMessages: boolean): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/registration?disableGlobalMessages=${disableGlobalMessages}`,
      data,
    );
  }

  resetPassword(email: string): Observable<any> {
    return this.http.get<void>(
      this.baseUrl + '/beginForgotPassword/?mail=' + encodeURIComponent(email),
    );
  }

  login(credentials: UserCredentials): Observable<LoginResponse> {
    let body = new HttpParams({
      encoder: new CustomHttpUrlEncondingCodec(),
    })
      .append('username', credentials.username)
      .append('password', credentials.password)
      .append('instalacionId', credentials.installationId.toString());
    return this.http.post<LoginResponse>(this.baseUrl + '/userLogin', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }

  profile(): Observable<User> {
    return this.http.get<User>(this.baseUrl + '/users/me');
  }

  updateProfile(u: User, disableValidateMandatoryFields = false): Observable<void> {
    return this.http.put<void>(
      this.baseUrl + '/users/me',
      JSON.stringify({
        ...u.toBackend(),
        shouldValidateMandatoryFields: disableValidateMandatoryFields
          ? false
          : undefined,
      }),
    );
  }

  deleteProfile(): Observable<void> {
    return this.http.delete<void>(this.baseUrl + '/users/me');
  }

  updateExtraField(name: string, value: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/users/extras/add`, {
      name: name,
      value: value,
    });
  }

  replaceExtraFields(extras: {[keys: string]: string}): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/users/extras/replace`, {
      extras: extras,
    });
  }

  verifyEmail(): Observable<void> {
    return this.http.get<void>(this.baseUrl + '/users/verificarMail');
  }

  updateEmail(email: string): Observable<void> {
    return this.http.put<void>(
      this.baseUrl + '/users/mail',
      JSON.stringify({mail: email}),
    );
  }

  initVerifyPhone(number: string): Observable<void> {
    let body = new HttpParams().append('numero', number);

    return this.http.post<void>(
      this.baseUrl + '/users/inicioVerificarNumero',
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      },
    );
  }

  verifyPhone(
    code: string,
    number: string,
    disableGlobalMessages: boolean,
  ): Observable<void> {
    let body = new HttpParams().append('numero', number).append('token', code);

    return this.http.post<void>(
      `${this.baseUrl}/users/verifyPhoneNumber?disableGlobalMessages=${disableGlobalMessages}`,
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      },
    );
  }

  /**
   * Check if a user email is already registered
   *
   * @param email user email
   * @returns boolean true -> is registered else false
   */
  isRegistered(email: string): Observable<boolean> {
    return this.http
      .get<{status: string; result: boolean}>(
        `${this.baseUrl}/shouldShowPrivacyModalOnRegister/${email}`,
      )
      .pipe(map(data => data.result === false));
  }
}
