import {Observable, of} from 'rxjs';

import {GoogleUser} from './google-auth.service';

export class GoogleAuthServiceMockE2e {
  // noinspection JSMethodCanBeStatic, JSUnusedLocalSymbols
  login(scopes?: Array<string>): Observable<GoogleUser> {
    return of(<GoogleUser>{});
  }

  // noinspection JSMethodCanBeStatic
  logout(): Observable<boolean> {
    return of(true);
  }

  // noinspection JSMethodCanBeStatic
  loadAsync() {
    return of(true);
  }

  getCurrentUser(): Observable<GoogleUser | null> {
    return of(<GoogleUser>{});
  }

  // noinspection JSMethodCanBeStatic
  getLoginStatus(): Observable<boolean> {
    return of(true);
  }

  initialize() {
    return of(null);
  }
}
