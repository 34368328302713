import {LotteryTicket} from './lottery-ticket';

export class LotteryTicketPaginationData {
  searchableTickets: number;

  ticketStock: number;

  tickets: Array<LotteryTicket>;

  static createFromBackend(obj: any): LotteryTicketPaginationData {
    const data = new LotteryTicketPaginationData();

    data.searchableTickets = obj.totalDecimosEnSorteo || obj.totalNumbers || 0;
    data.ticketStock = obj.totalStockEnSorteo;

    if (obj.numbers) {
      data.tickets = obj.numbers.map(number =>
        LotteryTicket.createFromBackend(number),
      );
    } else {
      data.tickets = [];
    }

    return data;
  }
}
