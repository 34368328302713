import {Component, EventEmitter, Input, Output} from '@angular/core';

import {AbstractNgModel} from '../../../forms/abstract-ngmodel';
import {ngModelProvider} from '../../../model/ng-model-config';
import {
  isEditingKey,
  isNavigationKey,
  isNumericKey,
} from '../../../util/keyboard/key-utils';
import {applyExpiryDateMask} from '../credit-card-model';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-expiry-date',
  templateUrl: './input-expiry-date.component.html',
  styleUrls: ['./input-expiry-date.component.scss'],
  providers: [ngModelProvider(InputExpiryDateComponent)],
})
export class InputExpiryDateComponent extends AbstractNgModel<string> {
  @Input('value')
  model: string;

  @Input()
  placeholder: string;

  @Input()
  ngTabindex: number;

  @Output()
  changeDate = new EventEmitter<string>();

  onInput(event: any) {
    applyExpiryDateMask(event.target);

    this.model = event.target.value;
    this.notify();
  }

  // noinspection JSMethodCanBeStatic
  inputKeyDown(event: any) {
    if (event.key == null) {
      return false;
    }

    if (
      !isNumericKey(event.key) &&
      !isEditingKey(event.key) &&
      !isNavigationKey(event.key)
    ) {
      return false;
    }

    if (isNumericKey(event.key)) {
      if (event.target.value.length > 4) {
        return false;
      }
    }
  }

  writeValue(value: string) {
    this.model = value;
    this.modelChange(this.model);
  }

  private notify() {
    this.modelChange(this.model);
    this.changeDate.emit(this.model);
  }
}
