<div #rail class="slides">
  <ng-content></ng-content>
</div>
<div *ngIf="markers" class="markers">
  <div
    *ngFor="let item of totalSlides | arrayFromNumber"
    class="ball"
    [class.selected]="item === currentSlide">
    &nbsp;
  </div>
</div>
