import {Serializable} from 'common';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';

/**
 * Array od digits between min and max with order
 */
class DigitsGameTypeMetadataInternal extends AbstractGameTypeMetadata {
  min: number;

  max: number;

  pattern: string;
}

export class DigitsGameTypeMetadata extends Serializable(
  DigitsGameTypeMetadataInternal,
) {
  static createFromBackend(obj: any) {
    let digitsGMDT = new DigitsGameTypeMetadata();
    AbstractGameTypeMetadata.fillFromBackend(digitsGMDT, obj);
    digitsGMDT.min = obj.minValue;
    digitsGMDT.max = obj.maxValue;
    digitsGMDT.pattern = obj.resultPattern;

    return digitsGMDT;
  }
}
