import {FormGroup} from '@angular/forms';
import {isNumeric} from 'common';
import {Subject} from 'rxjs';

export class CombinationForm extends FormGroup {
  index: number;

  betTypeChange = new Subject<string>();

  id: number;

  setIndex(idx: number): void {
    if (isNumeric(idx)) {
      this.index = idx;
    }
  }
}
