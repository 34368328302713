import {deepFreeze} from 'common';

export const NO_TL_CONTACTS_FIXTURE_E2E = [
  {
    type: 'TELEFONO_EXTERNO',
    phone: '34666839099',
    name: 'Pepe',
    shortName: 'Pepe',
    initials: 'P',
  },
  {
    type: 'TELEFONO_EXTERNO',
    phone: '34666839098',
    name: 'Pepito',
    shortName: 'Pepito',
    initials: 'P',
  },
];

deepFreeze(NO_TL_CONTACTS_FIXTURE_E2E);
