import {ModalDialogComponent} from 'common';

export interface KycDialogData {
  type: ModalDialogComponent['type'];
  title: ModalDialogComponent['title'];
  message?: ModalDialogComponent['message'];
  accept?: ModalDialogComponent['accept'];
  image?: ModalDialogComponent['image'];
  disableContentPadding?: boolean;
  closeOnPopstate?: boolean;
}

export const KYC_DIALOG_DATA: {[key: string]: {[key: string]: KycDialogData}} = {
  PENDING: {
    DEFAULT: {
      closeOnPopstate: true,
      disableContentPadding: true,
      type: 'ok_only',
      image: '/assets/img/kyc/kyc-pending.png',
      title: {key: 'kyc.kycFromBackendDialog.pending.title'},
      message: {key: 'kyc.kycFromBackendDialog.pending.withdraw.text'},
      accept: {key: 'kyc.kycFromBackendDialog.pending.buttonText'},
    },
    PLAY: {
      closeOnPopstate: true,
      disableContentPadding: true,
      type: 'ok_only',
      image: '/assets/img/kyc/kyc-pending.png',
      title: {key: 'kyc.play.inProgress.title'},
      message: {key: 'kyc.play.inProgress.text'},
      accept: {key: 'kyc.kycFromBackendDialog.pending.buttonText'},
    },
    PLAY_CHRISTMAS: {
      closeOnPopstate: true,
      disableContentPadding: true,
      type: 'ok_only',
      image: '/assets/img/kyc/kyc-pending.png',
      title: {key: 'kyc.play.inProgress.title'},
      message: {key: 'kyc.kycFromBackendDialog.pending.withdraw.textChristmas'},
      accept: {key: 'kyc.kycFromBackendDialog.pending.buttonText'},
    },
    UNLOAD: {
      closeOnPopstate: true,
      disableContentPadding: true,
      type: 'ok_only',
      image: '/assets/img/kyc/kyc-pending.png',
      title: {key: 'kyc.withdraw.inProgress.title'},
      message: {key: 'kyc.withdraw.inProgress.text'},
      accept: {key: 'kyc.kycFromBackendDialog.pending.buttonText'},
    },
    UNLOAD_CHRISTMAS: {
      closeOnPopstate: true,
      disableContentPadding: true,
      type: 'ok_only',
      image: '/assets/img/kyc/kyc-pending.png',
      title: {key: 'kyc.withdraw.inProgress.title'},
      message: {key: 'kyc.kycFromBackendDialog.pending.withdraw.textChristmas'},
      accept: {key: 'kyc.kycFromBackendDialog.pending.buttonText'},
    },
    CHRISTMAS: {
      closeOnPopstate: true,
      disableContentPadding: true,
      type: 'ok_only',
      image: '/assets/img/kyc/kyc-pending.png',
      title: {key: 'kyc.kycFromBackendDialog.pending.title'},
      message: {key: 'kyc.kycFromBackendDialog.pending.withdraw.textChristmas'},
      accept: {key: 'kyc.kycFromBackendDialog.pending.buttonText'},
    },
    OTHER: {
      closeOnPopstate: true,
      disableContentPadding: true,
      type: 'ok_only',
      image: '/assets/img/kyc/selfie-validate.png',
      title: {key: 'kyc.kycFromBackendDialog.pending.title'},
      message: {key: 'kyc.kycFromBackendDialog.pending.text'},
      accept: {key: 'kyc.kycFromBackendDialog.pending.buttonText'},
    },
  },
  EXPIRED: {
    DEFAULT: {
      type: 'ok',
      closeOnPopstate: true,
      disableContentPadding: true,
      image: '/assets/img/kyc/documentation_invalid.png',
      title: {key: 'middlePrize.dniExpiredDialog.title'},
      message: {key: 'middlePrize.dniExpiredDialog.subtitle'},
      accept: {key: 'middlePrize.dniExpiredDialog.okButton'},
    },
  },
  REQUIRED: {
    DEFAULT: {
      type: 'ok',
      image: '/assets/img/kyc/selfie-validate.png',
      title: {key: 'kyc.kycFromBackendDialog.withdraw.title'},
      message: {key: 'kyc.kycFromBackendDialog.withdraw.text'},
      closeOnPopstate: true,
      disableContentPadding: true,
      accept: {key: 'kyc.kycFromBackendDialog.buttonText'},
    },
    PLAY: {
      type: 'ok',
      image: '/assets/img/kyc/selfie-validate.png',
      title: {key: 'kyc.play.kycNeeded.title'},
      closeOnPopstate: true,
      disableContentPadding: true,
      accept: {key: 'kyc.kycNone.accept'},
    },
    UNLOAD: {
      type: 'ok',
      image: '/assets/img/kyc/selfie-validate.png',
      title: {key: 'kyc.withdraw.kycNeeded.title'},
      message: {key: 'kyc.modalKycNeeded.accept'},
      closeOnPopstate: true,
      disableContentPadding: true,
      accept: {key: 'kyc.kycFromBackendDialog.buttonText'},
    },
    OTHER: {
      type: 'ok',
      image: '/assets/img/kyc/selfie-validate.png',
      title: {key: 'kyc.kycFromBackendDialog.title'},
      message: {key: 'kyc.kycFromBackendDialog.text'},
      closeOnPopstate: true,
      disableContentPadding: true,
      accept: {key: 'kyc.kycFromBackendDialog.buttonText'},
    },
  },
};
