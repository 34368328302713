import {Inject, Injectable, NgZone, OnDestroy} from '@angular/core';
import {differenceInSeconds} from 'date-fns';
import {fromEvent} from 'rxjs';
import {finalize, first, switchMap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {PrivateInfoService} from './private-info.service';

@Injectable({providedIn: 'root'})
export class PrivateInfoAutoSyncService implements OnDestroy {
  private updateInterval: any;

  private syncing = false;

  constructor(
    private privateInfoService: PrivateInfoService,
    private zone: NgZone,
    @Inject('window') private window: Window,
  ) {}

  hasEnabledTimer() {
    return !!this.updateInterval;
  }

  enableTimer() {
    if (!this.hasEnabledTimer()) {
      this.updateInterval = setInterval(() => {
        if (this.shouldSync()) {
          this.sync();
        }
      }, environment.allInfo.intervalToUpdateInfo * 1000);
    }
  }

  clearTimer() {
    if (this.hasEnabledTimer()) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }

  shouldSync() {
    return (
      differenceInSeconds(Date.now(), this.privateInfoService.lastAllInfoCall) >
      environment.allInfo.intervalToUpdateInfo
    );
  }

  sync() {
    if (this.syncing) {
      return;
    }

    this.syncing = true;
    this.clearTimer();
    this.zone.run(() => {
      if (!this.window.navigator.onLine) {
        fromEvent(this.window, 'online')
          .pipe(
            first(),
            switchMap(() => this.privateInfoService.update()),
            finalize(() => {
              this.syncing = false;
              this.enableTimer();
            }),
          )
          .subscribe();
      } else {
        this.privateInfoService
          .update()
          .pipe(finalize(() => (this.syncing = false)))
          .subscribe();
        this.enableTimer();
      }
    });
  }

  ngOnDestroy(): void {
    this.clearTimer();
  }
}
