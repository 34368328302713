import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import {Subject} from 'rxjs';

import {ngModelProvider} from '../../model/ng-model-config';
import {AbstractNgModel} from '../abstract-ngmodel';

import {SelectItemComponent} from './select-item.component';
import {SelectService} from './select.service';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [ngModelProvider(SelectComponent), SelectService],
})
export class SelectComponent
  extends AbstractNgModel<any>
  implements OnInit, AfterContentInit, OnChanges
{
  @Input('value')
  model: any;

  @Input()
  multiple = false;

  /**
   * Only for multiple = true
   */
  @Input()
  max: number;

  /**
   * Keeps always one selected if you init at first one value. If you don't do
   * it, no value will be initialized at first
   */
  @Input()
  alwaysOneSelected = false;

  /**
   * When true several children can have the same value and will be selected and
   * deselected as if they were the same option.
   *
   * Note: same valued children are expected to be contiguous.
   */
  @Input()
  setSelection = false;

  @Output()
  changeSelection = new EventEmitter<any>();

  @Output()
  preventedDeselect = new EventEmitter<void>();

  @ContentChildren(SelectItemComponent, {descendants: true})
  children: QueryList<SelectItemComponent>;

  @Input()
  avoidReset = false;

  @Input()
  initialSelection: any;

  @Input()
  disableSelect = false;

  @Output()
  selectItem: EventEmitter<void> = new EventEmitter<void>();

  private modelChecker = new Subject<any>();

  private disabled = false;

  constructor(private cdr: ChangeDetectorRef, private selectService: SelectService) {
    super();
  }

  ngOnInit(): void {
    this.selectService.registerParent(this);

    if (this.multiple && (this.initialSelection || !this.model)) {
      this.model = this.initialSelection ?? [];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty('multiple') &&
      changes.multiple.currentValue &&
      !this.avoidReset
    ) {
      this.model = [];
    }

    if (changes.hasOwnProperty('model')) {
      this.modelChecker.next(this.model);
    }
  }

  ngAfterContentInit(): void {
    this.modelChecker.subscribe(() => this.doCheck());
  }

  writeValue(obj: any): void {
    if (this.multiple && !Array.isArray(obj)) {
      this.model = [];
    } else {
      this.model = obj;
    }
    this.cdr.markForCheck();
    this.modelChecker.next(this.model);
  }

  doCheck() {
    if (this.multiple) {
      this.children.forEach(child => {
        child.selected = this.model.some(value => value === child.value);
        child.disabled = this.disabled || child.disabled;
      });
    } else {
      if (typeof this.model !== 'undefined') {
        this.children.forEach(child => {
          child.selected = this.model === child.value;
          child.disabled = this.disabled || child.disabled;
        });
      }
    }
  }

  onSelect(child: SelectItemComponent) {
    if (this.multiple) {
      let idx = this.model.indexOf(child.value);
      if (idx >= 0) {
        if (!(this.alwaysOneSelected && this.model.length === 1)) {
          // clone model for detect changes on array
          this.model = this.model.clone();
          this.model.splice(idx, 1);

          this.setSimilarSelected(child, false);

          child.selected = false;
          this.notify();
        } else if (this.alwaysOneSelected) {
          this.preventedDeselect.emit();
        }
      } else {
        if (
          !this.disableSelect &&
          (!Number.isFinite(this.max) || this.model.length < this.max) // didn't reach max
        ) {
          // clone model for detect changes on array
          this.model = this.model.clone();
          this.model.push(child.value);
          child.selected = true;
          this.setSimilarSelected(child, true);
          this.notify();
        }
      }
    } else {
      if (this.model === child.value) {
        if (!this.alwaysOneSelected) {
          this.model = undefined;
          child.selected = false;
          this.setSimilarSelected(child, false);
          this.notify();
        } else {
          this.preventedDeselect.emit();
        }
      } else if (!this.disableSelect) {
        this.model = child.value;
        this.unselectOthers(child);
        child.selected = true;
        this.setSimilarSelected(child, true);
        this.notify();
      }
    }
    this.selectItem.emit();
  }

  setDisabledState(disabled: boolean) {
    this.writeValue(null);
    this.disabled = disabled;
    if (this.children) {
      this.children.forEach(item => (item.disabled = disabled));
    }
  }

  private setSimilarSelected(child: SelectItemComponent, selected: boolean): void {
    if (!this.setSelection) {
      return;
    }

    let lastChildChanged = false;

    for (let item of this.children) {
      if (item.value === child.value) {
        item.selected = selected;
        lastChildChanged = true;
      } else if (lastChildChanged) {
        break;
      }
    }
  }

  private notify() {
    this.modelChange(this.model);
    this.changeSelection.emit(this.model);
  }

  private unselectOthers(child: SelectItemComponent) {
    this.children.forEach(c => {
      if (c !== child) {
        c.selected = false;
      }
    });
  }
}
