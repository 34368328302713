import {
  animate,
  animateChild,
  animation,
  AnimationReferenceMetadata,
  group,
  query,
  sequence,
  style,
  useAnimation,
} from '@angular/animations';

const routerSlideAnimation: AnimationReferenceMetadata = animation([
  sequence([
    query(':leave', animateChild(), {optional: true}),
    query(
      ':enter, :leave',
      [
        style({
          transform: 'translate3d({{leaveStart}}, 0, 0)',
          position: 'absolute',
          'will-change': 'transform',
          width: '100%',
        }),
      ],
      {optional: true},
    ),
    query(':enter', [style({transform: 'translate3d({{enterStart}}, 0, 0)'})], {
      optional: true,
    }),
    group([
      query(
        ':leave',
        [
          animate(
            '{{timing}}',
            style({transform: 'translate3d({{leaveEnd}}, 0, 0)'}),
          ),
        ],
        {optional: true},
      ),
      query(
        ':enter',
        [
          animate(
            '{{timing}}',
            style({transform: 'translate3d({{enterEnd}}, 0, 0)'}),
          ),
        ],
        {optional: true},
      ),
    ]),
    query(':enter', animateChild(), {optional: true}),
  ]),
]);

export function forwardAnimation(): AnimationReferenceMetadata {
  return useAnimation(routerSlideAnimation, {
    params: {
      enterStart: '100%',
      enterEnd: '0%',
      leaveStart: '0%',
      leaveEnd: '-100%',
      timing: '250ms ease-in-out',
    },
  });
}

export function backwardAnimation(): AnimationReferenceMetadata {
  return useAnimation(routerSlideAnimation, {
    params: {
      enterStart: '-100%',
      enterEnd: '0%',
      leaveStart: '0%',
      leaveEnd: '100%',
      timing: '250ms ease-in-out',
    },
  });
}
