import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {NavigationExtras, QueryParamsHandling, Router} from '@angular/router';
import {ResponsiveService} from 'common';

import {PLATFORM_ROUTES_MAPPING} from './platform-routes-mapping';
import {PlatformUriMapper} from './platform-uri-mapper';

/**
 * Map routes among platforms
 */
@Injectable({providedIn: 'root'})
export class PlatformRoutesService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platformUriMapper: PlatformUriMapper,
    private responsiveService: ResponsiveService,
    private router: Router,
  ) {}

  /**
   * Ensure that url is for the current platform
   *
   * @return true if the url is for current platform
   */
  assertRoutePlatform() {
    // Service worker redirection route aims to all platforms
    if (
      this.document.location.pathname === '/sw' ||
      this.document.location.pathname.startsWith('/sw?') ||
      this.document.location.pathname.startsWith('/sw/?')
    ) {
      return true;
    }

    const desktopRoute = !(
      this.document.location.pathname === '/m' ||
      this.document.location.pathname.startsWith('/m(') ||
      this.document.location.pathname.startsWith('/m/')
    );
    const isDesktop = this.responsiveService.isDesktop();
    return (isDesktop && desktopRoute) || (!isDesktop && !desktopRoute);
  }

  /**
   * Navigates to the appropiate route in the current platform
   *
   * @return Router promise
   */
  navigateAdyacentRoute(queryParamsHandling?: QueryParamsHandling | null) {
    this.navigateAdyacentTo(this.document.location.pathname, queryParamsHandling);
  }

  /**
   * Looks for the adyacent route for the give route and navigates to it.
   * If no match is found navigates to home.
   *
   */
  navigateAdyacentTo(
    route: string,
    queryParamsHandling?: QueryParamsHandling | null,
  ) {
    const destination =
      PLATFORM_ROUTES_MAPPING.get(route) || this.platformUriMapper.map(route);

    if (destination) {
      const params: NavigationExtras = {queryParamsHandling: queryParamsHandling};
      // If it's first time router navigates, it won't preserve url query
      // params, even if you use queryParamsHanding, so we get url params and
      // pass in to navigate function.
      if (!this.router.navigated && this.document.location.search) {
        const search = new URLSearchParams(this.document.location.search);
        const objSearch = {};

        search.forEach((value, key) => (objSearch[key] = value));
        params.queryParams = objSearch;
      }

      return this.router.navigate([destination], params);
    } else {
      return this.router.navigate(
        [this.responsiveService.isDesktop() ? '/' : '/m'],
        {queryParamsHandling: queryParamsHandling},
      );
    }
  }

  urlPrefix(): string {
    return this.responsiveService.isDesktop() ? '/' : '/m/';
  }
}
