import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SwitchModule} from '../forms/switch/switch.module';
import {SanitizerModule} from '../sanitizer/sanitizer.module';
import {TooltipModule} from '../tooltip/tooltip.module';

import {ExtraSwitchRowComponent} from './extra-switch-row.component';

@NgModule({
  imports: [CommonModule, SanitizerModule, SwitchModule, TooltipModule],
  exports: [ExtraSwitchRowComponent],
  declarations: [ExtraSwitchRowComponent],
})
export class ExtraSwitchRowModule {}
