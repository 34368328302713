import {Injectable} from '@angular/core';
import {ResponsiveService, UriMap, UriMapResolver, UriUtils} from 'common';
import {Observable, map, take} from 'rxjs';
import {UserService} from './data/user.service';
import {KycViewService} from '../kyc/kyc-view.service';

@Injectable()
export class UserUriMappingResolver implements UriMapResolver {
  constructor(
    private userService: UserService,
    private kycViewService: KycViewService,
    private responsiveService: ResponsiveService,
  ) {}

  resolve(uriMap: UriMap, url: string): Observable<any> {
    return this.userService.getData().pipe(
      take(1),
      map(user => {
        // if no phone verified go step 1
        if (!user.phoneVerified) {
          this.kycViewService.goToPhoneVerification();
          return null;
        }
        return UriUtils.getDestinationUrl(
          uriMap,
          this.responsiveService.getPlatform(),
        );
      }),
    );
  }
}
