import {SerializableDisableWarning} from 'common';

import {DigitsBetRuleTypeMetadata} from './digits-bet-rule-type-metadata';

/**
 * Rule applied to games which require a pattern like Tris game.
 */
export class RestrictedDigitsBetRuleTypeMetadata extends DigitsBetRuleTypeMetadata {
  /**
   * Each item indicates the amount of equal digits in that group, being
   * the length the number of different groups. Digits can't repeat between groups.
   * Selection order doesn't matter.
   *
   * @example `[1,2,2]` 1 unique digit, 2 equal and 2 equal - ABBCC
   */
  @SerializableDisableWarning()
  digitGroups: Array<Array<number>>;

  constructor() {
    super();
  }

  static createFromBackend(obj: any) {
    let restrictedDRTMD = new RestrictedDigitsBetRuleTypeMetadata();

    restrictedDRTMD.required = obj.needed;
    restrictedDRTMD.optional = !!obj.optional;
    restrictedDRTMD.pattern = obj.pattern;
    restrictedDRTMD.digitGroups = (obj.digitGroupsValues || [obj.digitGroups]).map(
      g => g.clone().sort(),
    );

    return restrictedDRTMD;
  }

  // Serializable inherited from parent.
}
