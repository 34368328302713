import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../../common/model/abstract.dao';

@Injectable({providedIn: 'root'})
export class UnfinishedPlayDao extends AbstractDao {
  constructor(protected http: HttpClient) {
    super();
  }

  removeUnfinishedPlay(id: number): Observable<any> {
    return this.http.put(this.baseUrl + '/users/unfinish/finished/' + id, null);
  }
}
