import {Observable} from 'rxjs';

import {AsyncLocalStorageConnection} from './async-local-storage-connection';

export class IndexedDbLocalStorageConnection implements AsyncLocalStorageConnection {
  constructor(private database: IDBDatabase) {}

  clear(): Observable<void> {
    const req = indexedDB.deleteDatabase(this.database.name);
    return new Observable(subscriber => {
      req.onsuccess = () => {
        subscriber.next(void 0);
        this.database = null;
        subscriber.complete();
      };
      req.onerror = error => {
        subscriber.error(error);
      };
    });
  }

  getAll(): Observable<Array<{key: string; value: string}>> {
    if (!this.database) {
      throw new Error('Connection closed or not open');
    }
    const objectStore = this.database
      .transaction([this.database.name], 'readonly')
      .objectStore(this.database.name);
    const req = objectStore.getAll();

    return new Observable(subscriber => {
      req.onsuccess = () => {
        subscriber.next(req.result ? Array.from(req.result.values()) : []);
        subscriber.complete();
      };
      req.onerror = error => {
        subscriber.error(error);
      };
    });
  }

  getItem(key: string): Observable<string | null> {
    if (!this.database) {
      throw new Error('Connection closed or not open');
    }
    const objectStore = this.database
      .transaction([this.database.name], 'readonly')
      .objectStore(this.database.name);
    const req = objectStore.get(key);

    return new Observable(subscriber => {
      req.onsuccess = () => {
        subscriber.next(req.result ? req.result.value : null);
        subscriber.complete();
      };
      req.onerror = error => {
        subscriber.error(error);
      };
    });
  }

  removeItem(key: string): Observable<void> {
    if (!this.database) {
      throw new Error('Connection closed or not open');
    }
    const objectStore = this.database
      .transaction([this.database.name], 'readwrite')
      .objectStore(this.database.name);
    const req = objectStore.delete(key);

    return new Observable(subscriber => {
      req.onsuccess = () => {
        subscriber.next(void 0);
        subscriber.complete();
      };
      req.onerror = error => {
        subscriber.error(error);
      };
    });
  }

  setItem(key: string, data: string): Observable<void> {
    if (!this.database) {
      throw new Error('Connection closed or not open');
    }
    const objectStore = this.database
      .transaction([this.database.name], 'readwrite')
      .objectStore(this.database.name);
    const req = objectStore.put({key: key, value: data});

    return new Observable(subscriber => {
      req.onsuccess = () => {
        subscriber.next(void 0);
        subscriber.complete();
      };
      req.onerror = error => {
        subscriber.error(error);
      };
      (<any>req).onblocked = error => {
        subscriber.error(error);
      };
    });
  }

  close(): void {
    this.database = null;
  }
}
