import {Injectable} from '@angular/core';
import {DeviceOs, DeviceService, isUuid, LocalStorage, uuid} from 'common';
import {differenceInDays} from 'date-fns';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {DeviceDao} from './device.dao';

@Injectable({providedIn: 'root'})
export class TuloteroDeviceService {
  private deviceCache: Observable<string>;

  private fingerprintCache: Observable<string>;

  constructor(
    private deviceDao: DeviceDao,
    private deviceService: DeviceService,
    private localStorage: LocalStorage,
  ) {}

  getBrowser(): string {
    return this.deviceService.getBrowser();
  }

  getBrowserVersion(): string {
    return this.deviceService.getBrowserVersion();
  }

  getOS(): DeviceOs {
    return this.deviceService.getOS();
  }

  getOSVersion() {
    return this.deviceService.getOSVersion();
  }

  getPixelRatio(): number {
    return this.deviceService.getPixelRatio();
  }

  getFingerprint(): Observable<string> {
    if (!this.fingerprintCache) {
      let fps = this.localStorage.getItem(environment.localStorageKeys.fingerprint);
      if (fps) {
        const [fp, time] = fps.split('::');
        this.fingerprintCache = of(fp);
        // We have to send fingerprint eventually, so when we load fingerprint
        // from local storage, which is faster, we have to send fingerprint to
        // FP PRO service.
        if (!time || differenceInDays(new Date(), new Date(+time)) >= 7) {
          const currentTime = new Date().getTime().toString();
          this.deviceService.getBrowserFingerprint().subscribe(
            f =>
              this.localStorage.setItem(
                environment.localStorageKeys.fingerprint,
                f + '::' + currentTime,
              ),
            () => {},
          );
        }
      } else {
        const time = new Date().getTime().toString();
        this.fingerprintCache = this.deviceService.getBrowserFingerprint().pipe(
          tap(f =>
            this.localStorage.setItem(
              environment.localStorageKeys.fingerprint,
              f + '::' + time,
            ),
          ),
          // If fingerprint doesn't work, we will use deviceId
          catchError(() => this.getDeviceId()),
          shareReplay(1),
        );
      }
    }

    return this.fingerprintCache;
  }

  getDeviceId(): Observable<string> {
    if (!this.deviceCache) {
      let id = this.localStorage.getItem(environment.localStorageKeys.deviceId);
      if (id) {
        if (isUuid(id)) {
          // Correct device
          this.deviceCache = of(id);
        } else {
          // Temporal case, if we have a fingerprint on device, we need to
          // unregister and generate a proper uuid.
          this.deviceCache = this.unregister()
            // No matter if fails.
            .pipe(catchError(() => of(true)))
            .pipe(
              map(() => uuid()),
              tap(device =>
                this.localStorage.setItem(
                  environment.localStorageKeys.deviceId,
                  device,
                ),
              ),
              shareReplay(1),
            );
        }
      } else {
        // No device registered. Generate new one.
        this.deviceCache = of(uuid()).pipe(
          tap(device =>
            this.localStorage.setItem(environment.localStorageKeys.deviceId, device),
          ),
          shareReplay(1),
        );
      }
    }
    return this.deviceCache;
  }

  unregister(): Observable<string> {
    let deviceId = this.localStorage.getItem(environment.localStorageKeys.deviceId);

    if (deviceId) {
      return this.deviceDao.unregister(deviceId);
    } else {
      return of(null);
    }
  }

  isSupportedDevice() {
    let currentBrowser = environment.browserSupport.find(
      browser => this.deviceService.getBrowser() === browser.name,
    );
    if (currentBrowser && this.deviceService.getBrowserVersion() != null) {
      let majorVersion = parseInt(
        this.deviceService.getBrowserVersion().match('[0-9]+')[0],
        10,
      );
      return majorVersion >= currentBrowser.version;
    } else {
      return false;
    }
  }
}
