import {Injectable, NgZone} from '@angular/core';
import {
  AbstractObservableDataService,
  GoogleContact,
  GoogleContactsService,
  LocalStorage,
  Logger,
  merge,
  MergeStrategy,
} from 'common';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {ContactsDao} from './contacts.dao';
import {TlContact} from './tl-contact';

@Injectable()
export class ContactsService extends AbstractObservableDataService<
  Array<TlContact>
> {
  /**
   * Same value of subject, setData cannot be async
   */
  private syncContacts: Array<TlContact> = [];

  constructor(
    private contactsDao: ContactsDao,
    private googleContactsService: GoogleContactsService,
    private localStorage: LocalStorage,
    private logger: Logger,
    private ngZone: NgZone,
  ) {
    super();
    this.loadFromStorage();
  }

  /**
   * Filter only registered contacts
   */
  getTuloteroContacts(): Observable<Array<TlContact>> {
    return this.getData().pipe(map(list => list.filter(c => c.isTulotero())));
  }

  /**
   * Filter only unregistered contacts
   */
  getUnregisteredContacts(): Observable<Array<TlContact>> {
    return this.getData().pipe(map(list => list.filter(c => !c.isTulotero())));
  }

  setData(data: Array<TlContact>) {
    merge(
      this.syncContacts,
      data,
      (c1, c2) => c1.id === c2.id,
      MergeStrategy.REPLACE,
    );
    this.syncContacts.sort((f1, f2) => f1.compareTo(f2));
    this.localStorage.setItem(
      environment.localStorageKeys.contacts,
      JSON.stringify(this.syncContacts),
    );
    this.setContacts(this.syncContacts);
  }

  loadFromGoogle(): Observable<Array<TlContact>> {
    return new Observable(observer => {
      this.googleContactsService.getContacts().subscribe({
        next: contacts => {
          // this call must in ngZone for dialog 406 interceptor dialog opened
          this.ngZone.run(() => {
            this.uploadContacts(contacts)
              .pipe(
                take(1),
                tap(newContacts => this.setContacts(newContacts)),
              )
              .subscribe({
                next: tlContacts => {
                  observer.next(tlContacts);
                  observer.complete();
                },
                error: err => {
                  observer.error(err);
                  observer.complete();
                },
              });
          });
        },
        error: err => {
          observer.error(err);
          observer.complete();
        },
      });
    });
  }

  hasPermission(): Observable<boolean> {
    return this.googleContactsService.hasPermission();
  }

  clearData() {
    this.setContacts([]);
    this.localStorage.removeItem(environment.localStorageKeys.contacts);
  }

  private uploadContacts(googleContacts: Array<GoogleContact>) {
    const device = this.localStorage.getItem(environment.localStorageKeys.deviceId);
    return this.contactsDao
      .upload(googleContacts, device)
      .pipe(map(contacts => contacts.sort((f1, f2) => f1.compareTo(f2))));
  }

  /**
   * Keeps both lists sync
   */
  private setContacts(contacts: Array<TlContact>) {
    this.syncContacts = contacts;
    super.setData(contacts);
  }

  private loadFromStorage(): void {
    let data = this.localStorage.getItem(environment.localStorageKeys.contacts);
    let contacts: Array<TlContact>;
    try {
      contacts = data
        ? (JSON.parse(data) as Array<TlContact>).map(c => TlContact.fromJSON(c))
        : [];
    } catch (e) {
      // We sometimes fail loading contacts, we put a log point here to catch the bug
      this.logger.error('Error parsing local storage contacts', e, {
        datastring: data,
      });
      contacts = [];
    }

    this.setContacts(contacts);
  }
}
