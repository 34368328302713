import {Serializable, SerializableProperty} from 'common';
import {isPast, isToday} from 'date-fns';

import {ClubType} from './club-type';
import {ClubExtraNumber} from './club-extra-number';

class ClubInternal {
  id: number;

  groupId: number;

  betPrice: number;

  entryPrice: number;

  entryTotal: number;

  entryMin: number;

  remainingEntries: number;

  closingDate: number;

  gameId: string;

  clubName: string;

  shortDescription: string;

  longDescription: string;

  raffleId: number;

  avatarURL: string;

  subscribable: boolean;

  jackpot: number;

  type: ClubType;

  privateClub: boolean;

  /**
   * The lottery number played in the club if its a company club
   */
  extra: string;

  @SerializableProperty(ClubExtraNumber)
  extraNumbers: Array<ClubExtraNumber>;

  inviteCode: string;

  isAdmin: boolean;

  shareMessage: string;

  shareMessageSMS: string;

  /**
   * When true this clubs code cant be redeemed to get access to it, a custom
   * individual code is required for each user.
   */
  personalCodeRequired: boolean;

  availableEntries: number;

  maxPersonEntries: number;

  isClosed() {
    return isPast(this.closingDate);
  }

  closesToday() {
    return isToday(this.closingDate);
  }

  isSoldOut() {
    return this.remainingEntries <= 0;
  }

  isPersonEntriesLimited(): boolean {
    return this.maxPersonEntries !== undefined;
  }

  getDonatePrice() {
    return this.entryPrice - this.betPrice;
  }

  isClub() {
    return this.type === ClubType.CLUB;
  }

  isRocket() {
    return this.type === ClubType.ROCKET;
  }

  isCompany() {
    return this.type === ClubType.PRIVATE_COMPANIES;
  }

  isDonation() {
    return this.isCompany() && this.entryPrice - this.betPrice > 0;
  }
}

export class Club extends Serializable(ClubInternal) {
  public static createFromBackend(obj: any) {
    let c = new Club();

    c.id = obj.id;
    c.groupId = obj.penyaGroupId;
    c.betPrice = obj.aportacionParticipacion;
    c.entryPrice = obj.precioParticipacion;
    c.entryTotal = obj.numeroParticipaciones;
    c.entryMin = obj.numMinParticipaciones;
    c.remainingEntries = obj.participacionesRestantes;
    c.availableEntries =
      obj.maxParticipacionesPerson ?? obj.participacionesRestantes;
    c.maxPersonEntries = obj.maxParticipacionesPerson;
    c.closingDate = obj.fechaCierre;
    c.gameId = obj.juego;
    c.clubName = obj.nombre;
    c.shortDescription = obj.descCorta;
    c.longDescription = obj.descLarga;
    c.raffleId = obj.sorteoId;
    c.avatarURL = obj.pictureUrl;
    c.subscribable = obj.abonable;
    c.jackpot = obj.bote;
    c.privateClub = !!obj.privateId;
    c.extra = obj.extra;
    c.inviteCode = obj.privateCode;
    c.shareMessage = obj.messageShareCode;
    c.shareMessageSMS = obj.messageShareCodeSMS;
    c.personalCodeRequired = !!obj.codeNeedForAccess;

    c.type =
      (obj.type === 'LOTERIA_EMPRESAS' || obj.type === 'LOTERIA_EMPRESAS_USERS') &&
      obj.privateCodePublic
        ? ClubType.PRIVATE_COMPANIES
        : obj.type === 'SOCIAL'
        ? ClubType.ROCKET
        : ClubType.CLUB;

    c.isAdmin = obj.roles && obj.roles.includes('ADMIN');

    if (obj.hasOwnProperty('extraNumbers')) {
      c.extraNumbers = obj.extraNumbers.map(extraNumber =>
        ClubExtraNumber.createFromBackend(extraNumber),
      );
    }

    return c;
  }
}
