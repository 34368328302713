import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  exitFullScreen,
  GoogleMapComponent,
  isFullScreen,
  requestFullScreen,
} from 'common';

@Component({
  selector: 'tl-google-map-controls',
  templateUrl: './google-map-controls.component.html',
  styleUrls: ['./google-map-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GoogleMapControlsComponent implements OnInit {
  isFullscreen = false;

  @Input()
  showFullscreen = true;

  @Input()
  showRequestLocation = true;

  @Output()
  requestLocation = new EventEmitter<void>();

  @Output()
  closeMap = new EventEmitter<void>();

  @ViewChild('close', {static: true})
  closeControl: ElementRef;

  @ViewChild('controls', {static: true})
  controls: ElementRef;

  @HostBinding('class.tl-google-map-controls')
  readonly hostClass = true;

  private map: google.maps.Map;

  constructor(
    private googleMap: GoogleMapComponent,
    @Inject('window') private window: Window,
  ) {}

  ngOnInit(): void {
    this.googleMap.init.subscribe(() => {
      this.map = this.googleMap.getMap();
      this.initMapControls();
    });
  }

  zoomIn(): void {
    this.googleMap.zoomIn();
  }

  zoomOut(): void {
    this.googleMap.zoomOut();
  }

  onClose(): void {
    if (isFullScreen(document)) {
      exitFullScreen(document);
    } else {
      this.closeMap.emit();
    }
  }

  onFullscreen(): void {
    requestFullScreen(<HTMLElement>this.map.getDiv());
  }

  @HostListener('document:fullscreenchange')
  onFullscreenchange() {
    this.isFullscreen = isFullScreen(document);
  }

  private initMapControls(): void {
    // Close
    const closeDiv = document.createElement('div');
    closeDiv.appendChild(this.closeControl.nativeElement);
    this.map.controls[(<any>this.window).google.maps.ControlPosition.RIGHT_TOP].push(
      closeDiv,
    );

    // Controls
    const controlsDiv = document.createElement('div');
    controlsDiv.appendChild(this.controls.nativeElement);
    this.map.controls[
      (<any>this.window).google.maps.ControlPosition.RIGHT_BOTTOM
    ].push(controlsDiv);
  }
}
