import {Serializable} from '../../serializable/serializable';

import {AbstractMessageHook} from './abstract-message-hook';

class AsapMessageHookInternal extends AbstractMessageHook {
  id: number;
}

export class AsapMessageHook extends Serializable(AsapMessageHookInternal) {
  static createFromBackend(obj: any) {
    let m = new AsapMessageHook();
    m.id = obj.id;
    m.title = obj.title;
    m.content = obj.texto;
    m.image = obj.iconImageUrl;
    m.url = obj.url;
    m.image = obj.iconImageUrl;
    m.okButtonText = obj.okButtonText;

    return m;
  }
}
