import {Serializable} from 'common';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';

class AlphanumericGameTypeMetadataInternal extends AbstractGameTypeMetadata {
  // TODO · UNSURE · doesn't exist yet, possible regexp to valuecheck the string if
  // the client inputs it
  allowedValues: string;
}

export class AlphanumericGameTypeMetadata extends Serializable(
  AlphanumericGameTypeMetadataInternal,
) {
  static createFromBackend(obj: any) {
    let alphanumericGMDT = new AlphanumericGameTypeMetadata();
    AbstractGameTypeMetadata.fillFromBackend(alphanumericGMDT, obj);
    alphanumericGMDT.allowedValues = obj.allowedValues;

    return alphanumericGMDT;
  }
}
