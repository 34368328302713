import {Injectable} from '@angular/core';
import {
  AbstractFlowLauncher,
  Logger,
  PageOpener,
  PreviousRouteService,
  ResponsiveService,
} from 'common';
import {Observable} from 'rxjs';

import {User} from '../user/data/user';

import {KycMethod} from './data/kyc-method';
import {KycMethodId} from './data/kyc-method-id';
import {KycCardFormGroup} from './model/kyc-card-form-group';
import {KycSelfieFormGroup} from './model/kyc-selfie-form-group';

@Injectable({providedIn: 'root'})
export class KycFlowLauncherService extends AbstractFlowLauncher {
  forms: Array<KycSelfieFormGroup | KycCardFormGroup>;

  user: User;

  constructor(
    protected pageOpener: PageOpener,
    protected previousRouteService: PreviousRouteService,
    private responsiveService: ResponsiveService,
    private logger: Logger,
  ) {
    super(pageOpener, previousRouteService);
  }

  /**
   * Logic flow controller
   */
  launch(
    method: KycMethod,
  ): Observable<{forms: Array<KycSelfieFormGroup | KycCardFormGroup>; user: User}> {
    this.forms = [];
    this.steps = [];
    this.user = undefined;
    this.currentStep = 0;

    this.steps.push({
      url: this.responsiveService.isDesktop()
        ? '/kyc/documento'
        : '/m/kyc/documento',
      titleKey: 'kyc.card.pageTitle',
      postStepHook: this.saveForm.bind(this),
      nextStepHook: () => this.forms,
    });

    if (method.id === KycMethodId.CARD_DL_ID) {
      this.steps.push({
        url: this.responsiveService.isDesktop() ? '/kyc/barcode' : '/m/kyc/barcode',
        titleKey: 'kyc.card.pageTitle',
        postStepHook: () => {
          const user: User = this.steps[this.currentStep].returnParams;
          this.user = user;
        },
      });
    }

    this.steps.push({
      url: this.responsiveService.isDesktop() ? '/kyc/selfie' : '/m/kyc/selfie',
      titleKey: 'kyc.card.steps.verifyIdentity.selfie.pageTitle',
      inStepHook: () => ({
        instructionsKey: 'kyc.steps.verifyIdentity.selfie.request.title',
      }),
      postStepHook: this.saveForm.bind(this),
      backStepHook: () => this.forms.pop(),
      nextStepHook: () => ({
        forms: this.forms,
        user: this.user,
      }),
    });

    this.logger.debug('WEB-1850 - KycFlowLauncher steps', {
      hasInstructionsKey:
        this.steps[this.steps.length - 1].inStepHook()?.instructionsKey,
      steps: this.steps,
    });

    return this.runFlow();
  }

  /**
   * Stores form result
   */
  private saveForm(): void {
    this.forms.push(this.steps[this.currentStep].returnParams);
  }
}
