import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {RoundIconButtonComponent} from './round-icon-button.component';

@NgModule({
  imports: [CommonModule],
  exports: [RoundIconButtonComponent],
  declarations: [RoundIconButtonComponent],
})
export class RoundIconButtonModule {}
