import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../../common/model/abstract.dao';

@Injectable({providedIn: 'root'})
export class BoothDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  setFavoriteBooth(boothId: string): Observable<string> {
    let body = new HttpParams().append('administracion', boothId);

    return this.http.post(this.baseUrl + '/users/administracion', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      responseType: 'text',
    });
  }
}
