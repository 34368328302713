import {DecimalPipe} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'distance'})
export class DistancePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) protected localeId: string) {}

  transform(value: any, ...args: Array<any>): any {
    let displayFormat = '1.0-0';
    let convertedDistance: number;
    let units: string;

    if (value > 1000) {
      convertedDistance = value / 1000;
      units = 'km';

      if (value < 100000) {
        displayFormat = '1.1-1';
      }
    } else {
      convertedDistance = value;
      units = 'm';
    }

    return (
      new DecimalPipe(this.localeId).transform(convertedDistance, displayFormat) +
      ' ' +
      units
    );
  }
}
