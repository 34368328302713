import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';

import {environment} from '~environments/environment';

import {ResultsDao} from './results.dao';
import {ResultsDaoAdapter} from './results.dao.adapter';

export function resultsDaoFactory(http: HttpClient) {
  switch (environment.locale.locale) {
    case 'es-ES':
      return new ResultsDaoAdapter(http);
    default:
      return new ResultsDao(http);
  }
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    // TODO · ADAPTER · remove
    {provide: ResultsDao, useFactory: resultsDaoFactory, deps: [HttpClient]},
  ],
})
export class ResultsModelModule {}
