import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';

import {environment} from '~environments/environment';

import {MatchesDao} from './matches.dao';
import {MatchesDaoAdapter} from './matches.dao.adapter';
import {TranslationsService} from 'common';

function matchesDaoFactory(
  http: HttpClient,
  translationsService: TranslationsService,
): MatchesDao {
  if (environment.locale.locale === 'es-ES') {
    return new MatchesDaoAdapter(http, translationsService);
  } else {
    return new MatchesDao(http);
  }
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  // TODO · ADAPTER · remove
  providers: [
    {
      provide: MatchesDao,
      useFactory: matchesDaoFactory,
      deps: [HttpClient, TranslationsService],
    },
  ],
})
export class MatchesModelModule {}
