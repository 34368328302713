import {Serializable} from 'common';

import {KycStatus} from '../../kyc/data/kyc-status';

class UserKycInternal {
  status: KycStatus;

  comment: string;

  barcodeUsed: boolean;

  get submitted(): boolean {
    return [KycStatus.PENDING, KycStatus.OK, KycStatus.EXPIRED].includes(
      this.status,
    );
  }
}

export class UserKyc extends Serializable(UserKycInternal) {
  static createFromBackend(obj: any, barcodeUsed: boolean): UserKyc {
    let userKyc = new UserKyc();

    userKyc.status = obj.status;

    userKyc.comment = obj.comment;

    userKyc.barcodeUsed = barcodeUsed;

    return userKyc;
  }
}
