import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ScrollRailModule} from '../scroll-rail/scroll-rail.module';

import {VirtualScrollComponent} from './virtual-scroll.component';
import {ResponsiveUtilsModule} from '../../responsive/responsive-utils.module';

@NgModule({
  imports: [CommonModule, ResponsiveUtilsModule, ScrollingModule, ScrollRailModule],
  exports: [VirtualScrollComponent, ScrollingModule],
  declarations: [VirtualScrollComponent],
})
export class VirtualScrollModule {}
