import {Serializable} from 'common';

class UpcomingGameUiMetadataInternal {
  navidad: boolean;

  ninyo: boolean;
}

export class UpcomingGameUiMetadata extends Serializable(
  UpcomingGameUiMetadataInternal,
) {
  public static createFromBackend(obj: any): any {
    const uiMD = new UpcomingGameUiMetadata();
    uiMD.navidad = !!obj.navidad;
    uiMD.ninyo = !!obj.ninyo;
    return uiMD;
  }
}
