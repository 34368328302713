import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {first} from 'rxjs/operators';

import {calculateElementWidth} from '../../util/browser/dom-utils';
import {roundDecimals} from '../../util/core/number';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({
  selector: 'tl-pageable-layout',
  templateUrl: './pageable-layout.component.html',
  styleUrls: ['./pageable-layout.component.scss'],
  exportAs: 'tlPageable',
  encapsulation: ViewEncapsulation.None,
})
export class PageableLayoutComponent implements AfterViewInit, OnChanges, DoCheck {
  @Input()
  autoBalance: 'center' | 'justify' | 'none' = 'none';

  @Input()
  dynamicLoading = false;

  @Input()
  initialScrollTo = 0;

  @Input()
  minimumGap = 1;

  page = 0;

  lastPage = true;

  @HostBinding('class.tl-pageable-layout')
  readonly hostClass = true;

  private readonly element: Element;

  private offset = 0;

  private gap = 0;

  private lastWidth = 0;

  private childrenWidth = 0;

  private childMutationObserver: MutationObserver;

  get hasMoreThanOnePage(): boolean {
    return !(this.page === 0 && this.lastPage);
  }

  get isFirstPage(): boolean {
    return this.page === 0;
  }

  get margin(): string {
    return '-' + this.offset.toString() + 'px';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    element: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone,
  ) {
    this.element = element.nativeElement;
  }

  ngAfterViewInit(): void {
    this.setMargins();

    this.childMutationObserver = new MutationObserver(() => {
      if (!this.dynamicLoading) {
        this.reset();
      } else {
        this.setMargins();
      }
    });

    this.childMutationObserver.observe(
      this.element.querySelector('.tl-pageable-layout__pageable'),
      {childList: true},
    );

    this.checkIfInitialScrollIsNeeded();
  }

  ngDoCheck(): void {
    if (this.lastWidth !== this.element.clientWidth) {
      this.reset();
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      simpleChanges.hasOwnProperty('autoBalance') ||
      simpleChanges.hasOwnProperty('minimumGap')
    ) {
      this.reset();
    }
  }

  next(): void {
    const displacement = this.calculatePageAdvance();

    if (this.offset + displacement < this.childrenWidth) {
      setTimeout(() => {
        // Better transition performance
        this.offset = Math.min(
          this.offset + displacement,
          this.childrenWidth - this.element.clientWidth,
        );
        this.lastPage = this.offset >= this.childrenWidth - this.element.clientWidth;
        this.cdr.markForCheck();
      });
      this.page++;
    }
  }

  previous(): void {
    const displacement = this.calculatePageAdvance();

    if (this.offset >= 0) {
      setTimeout(() => {
        this.offset = Math.max(0, this.offset - displacement);
        this.cdr.markForCheck();
      }); // Better transition performance
      this.page--;
      this.lastPage = false;
    }
  }

  @HostListener('window:resize')
  reset(): void {
    this.offset = 0;
    this.page = 0;
    this.setMargins();
    this.checkIfInitialScrollIsNeeded();
  }

  private setMargins(): void {
    this.calculateAndSetMargins();
  }

  private calculateAndSetMargins(): void {
    this.lastWidth = this.element.clientWidth;
    const children = this.element.querySelectorAll(
      '.tl-pageable-layout__pageable > *',
    );

    if (children.length < 1) {
      return;
    }

    if (this.autoBalance === 'none') {
      this.zone.onMicrotaskEmpty.pipe(first()).subscribe(() => {
        this.calculateChildrenWidth();
        this.checkLastpage();
      });
      this.gap = this.minimumGap;
      Array.from(
        this.element.querySelector('.tl-pageable-layout__pageable').children,
      ).forEach((child, index, array) => {
        if (!(index === array.length - 1 && this.autoBalance === 'justify')) {
          this.renderer.setStyle(child, 'margin-right', this.gap / 2 + 'px');
        }

        if (!(index === 0 && this.autoBalance === 'justify')) {
          this.renderer.setStyle(child, 'margin-left', this.gap / 2 + 'px');
        }
      });
      return;
    }

    const width = this.element.clientWidth;
    const childWidth = calculateElementWidth(children[0]);

    const space = this.autoBalance === 'justify' ? width + this.minimumGap : width;
    const fitElements = Math.min(
      children.length,
      Math.floor(space / (childWidth + this.minimumGap)),
    );

    if (fitElements < 1) {
      this.gap = 0;
    } else if (fitElements === 1) {
      this.gap = roundDecimals(width - childWidth);
    } else if (fitElements === children.length && this.autoBalance === 'justify') {
      this.gap = this.minimumGap;
    } else if (this.autoBalance === 'justify') {
      this.gap = roundDecimals(
        (width - fitElements * childWidth) / (fitElements - 1),
      );
    } else {
      this.gap = roundDecimals(width / fitElements) - childWidth;
    }

    if (
      this.element.querySelector('.tl-pageable-layout__pageable').children.length ===
        1 &&
      this.autoBalance === 'center'
    ) {
      this.renderer.setStyle(this.element, 'text-align', 'center');
    } else {
      Array.from(
        this.element.querySelector('.tl-pageable-layout__pageable').children,
      ).forEach((child, index, array) => {
        if (!(index === array.length - 1 && this.autoBalance === 'justify')) {
          this.renderer.setStyle(child, 'margin-right', this.gap / 2 + 'px');
        }

        if (!(index === 0 && this.autoBalance === 'justify')) {
          this.renderer.setStyle(child, 'margin-left', this.gap / 2 + 'px');
        }
      });
    }

    this.calculateChildrenWidth();

    this.zone.onMicrotaskEmpty.pipe(first()).subscribe(() => this.checkLastpage());
  }

  private checkLastpage(): void {
    this.lastPage =
      roundDecimals(this.childrenWidth) <= roundDecimals(this.element.clientWidth);
    this.cdr.markForCheck();
  }

  private calculateChildrenWidth(): void {
    this.childrenWidth = Array.from(
      this.element.querySelector('.tl-pageable-layout__pageable').children,
    )
      .map(element => calculateElementWidth(element, true))
      .reduce((sum, child) => sum + child);
  }

  private calculatePageAdvance(): number {
    return this.autoBalance === 'justify'
      ? this.element.clientWidth + this.gap
      : this.element.clientWidth;
  }

  private calculateBoxWidth(): number {
    const boxes = Array.from(
      this.element.querySelector('.tl-pageable-layout__pageable').children,
    );

    return calculateElementWidth(boxes[0], true);
  }

  private checkIfInitialScrollIsNeeded(): void {
    if (this.initialScrollTo > 2) {
      const multiplier = this.initialScrollTo - 2;
      setTimeout(() => {
        this.offset =
          this.calculateBoxWidth() * multiplier - this.calculateBoxWidth() / 2;
        if (multiplier === 1) {
          this.offset = (this.calculateBoxWidth() / 2) * multiplier;
        }
        this.lastPage = this.offset >= this.childrenWidth - this.element.clientWidth;
        this.page++;
        this.cdr.markForCheck();
      });
    }
  }
}
