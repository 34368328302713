import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {tap} from 'rxjs/operators';

import {UnfinishedPlay} from './unfinished-play';
import {UnfinishedPlayDao} from './unfinished-play.dao';

@Injectable({providedIn: 'root'})
export class UnfinishedPlayService {
  hasUnfinishedPlay = new ReplaySubject<boolean>(1);

  unfinishedPlay: UnfinishedPlay;

  constructor(private unfinishedPlayDao: UnfinishedPlayDao) {}

  setUnfinishedPlays(data: Array<UnfinishedPlay>): void {
    this.unfinishedPlay = data[0];
    this.hasUnfinishedPlay.next(true);
  }

  removePlay(): Observable<any> {
    return this.unfinishedPlayDao
      .removeUnfinishedPlay(this.unfinishedPlay.id)
      .pipe(tap(() => this.clear()));
  }

  clear(): void {
    this.unfinishedPlay = null;
    this.hasUnfinishedPlay.next(false);
  }
}
