import {Directive, ElementRef, HostListener, Input} from '@angular/core';

/**
 * Allow filter and input by a given pattern
 *
 * @link https://jsfiddle.net/emkey08/zgvtjc51
 */
@Directive({
  selector: 'input[type=text][tlInputPattern]',
})
export class InputPatternDirective {
  private element: HTMLInputElement;
  private pattern: RegExp;
  private oldValue: string;
  private oldSelectionStart: number;
  private oldSelectionEnd: number;

  @Input('tlInputPattern')
  set patternSet(pattern: string) {
    this.element.value = '';
    this.oldValue = null;
    this.oldSelectionStart = null;
    this.oldSelectionEnd = null;
    this.pattern = pattern ? new RegExp(pattern) : null;
  }

  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('keyup', ['$event'])
  @HostListener('mousedown', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('select', ['$event'])
  @HostListener('contextMenu', ['$event'])
  @HostListener('drop', ['$event'])
  validate(event: KeyboardEvent) {
    if (this.pattern.test(this.element.value)) {
      this.oldValue = this.element.value;
      this.oldSelectionStart = this.element.selectionStart;
      this.oldSelectionEnd = this.element.selectionEnd;
    } else if (this.oldValue) {
      this.element.value = this.oldValue;
      if (this.oldSelectionStart !== null && this.oldSelectionEnd !== null) {
        this.element.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      }
    } else {
      this.element.value = '';
    }
  }
}
