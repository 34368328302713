import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CounterComponent} from './counter.component';
import {FormsModule} from '@angular/forms';
import {CurrencyModule} from '../../money/currency.module';

@NgModule({
  imports: [CommonModule, FormsModule, CurrencyModule],
  exports: [CounterComponent],
  declarations: [CounterComponent],
})
export class CounterModule {}
