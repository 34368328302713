<div ngbDropdown class="wrapper">
  <button
    type="button"
    class="btn toggle-btn form-control"
    ngbDropdownToggle
    [disabled]="disabled || !countries">
    <span *ngIf="model; else placeholder">
      <img
        src="/assets/img/blank.gif"
        class="flag flag-{{ model.code }}"
        alt="{{ model.name }}" />
      &nbsp; {{ model.name }}
    </span>
    <ng-template #placeholder>
      <ng-container>
        <tl-translatable-text [text]="emptySelection"></tl-translatable-text>
      </ng-container>
    </ng-template>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <label>
      <input
        type="text"
        [placeholder]="'global.search' | i18n"
        class="form-control-sm form-control"
        [formControl]="searchForm" />
    </label>
    <div class="countries">
      <tl-scrollable>
        <div
          *ngFor="
            let country of countries | filterArray: (searchTerm | async):testCountry;
            let i = index
          "
          [title]="('global.select' | i18n) + country.name"
          class="country-item"
          (click)="select(country)">
          <img
            src="/assets/img/blank.gif"
            class="flag flag-{{ country.code }}"
            alt="{{ country.name }}" />
          {{ country.name }}
        </div>
      </tl-scrollable>
    </div>
  </div>
</div>
