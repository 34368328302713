import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';

import {LotteryTicket} from '../../../lottery/data/lottery-ticket';
import {LotteryTicketSelection} from '../../../lottery/lottery-ticket-selection';

import {LotteryFormService} from './lottery-form.service';
import {TooManyTicketsError} from './too-many-tickets-error';
import {PanelFormService} from './panel-form.service';
import {distinctUntilChanged, ReplaySubject} from 'rxjs';
import {NumberCombinationType} from '../../../combination/data/number-combination-type';
import {AbstractLotteryBetFormService} from './abstract-lottery-bet-form.service';
import {deepClone} from 'common';
import {CombinationTypeValue} from '../../../combination/data/combination-type-declaration';

@Injectable()
export class FractionLotteryBetFormService extends AbstractLotteryBetFormService {
  ticketFractions = new ReplaySubject<{[keys: string]: Array<string>}>(1);
  availableFractions: Map<string, Array<string>> = new Map<string, Array<string>>();

  initialize(
    panelFormService: PanelFormService,
    betForm: FormGroup,
    raffleId: number,
  ): void {
    super.initialize(panelFormService, betForm, raffleId);
    this.combinations.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(combinations => {
        let fractions = {};
        combinations.forEach(ticket => {
          // for avoid
          const ticketCloned = deepClone(ticket);
          const ticketNumber = ticketCloned.value.numero[0];
          if (
            fractions[ticketNumber] &&
            !fractions[ticketNumber].includes(ticketCloned.value.fraccion[0])
          ) {
            fractions[ticketNumber].push(...ticketCloned.value.fraccion);
          } else {
            fractions[ticketNumber] = ticketCloned.value.fraccion;
          }
        });
        this.ticketFractions.next(fractions);
      });
  }

  ticketChange(selection: LotteryTicketSelection, searchAdminId?: string): void {
    const index = this.findFormIndexForTicket(this.combinations, selection.hash);
    if (index >= 0) {
      if (selection.amount > 0) {
        this.editTicketAt(
          index,
          selection.amount,
          selection.fraction,
          selection.hash,
          searchAdminId,
        );
      } else {
        this.removeTicketAt(index);
      }
    } else {
      this.addTicket(
        selection.ticket,
        1,
        selection.fraction,
        selection.hash,
        searchAdminId,
      );
    }
  }

  addTicket(
    ticket: LotteryTicket,
    amount: number,
    fraction: string,
    hash: string,
    searchAdminId?: string,
  ): void {
    const max = (<LotteryFormService>this.panelFormService).getMaximumNumbers();

    if (this.combinations.length >= max) {
      throw new TooManyTicketsError(max, this.combinations.length);
    }

    const combinationGroup = (<LotteryFormService>(
      this.panelFormService
    )).createCombinationFromTicket(ticket, fraction);
    combinationGroup.get('amount').setValue(amount);
    combinationGroup.get('hash').setValue(hash);
    if (searchAdminId) {
      combinationGroup.addControl('searchAdminId', new FormControl(searchAdminId));
    }

    this.combinations.push(combinationGroup);
    this.selectedTickets.set(hash, ticket);
    const ticketNumber = (
      ticket._value.find((c: NumberCombinationType) => c.typeId === 'numero')
        .value[0] as unknown as {value: string}
    ).value;
    if (ticket._value.find((c: CombinationTypeValue) => c.typeId === 'fraccion')) {
      const ticketFractions: Array<string> = ticket._value
        .find((c: CombinationTypeValue) => c.typeId === 'fraccion')
        .value.map(v => v['value']);
      this.availableFractions.set(ticketNumber, ticketFractions);
    }
    this.selectedTicketsChange.next(this.getSelectedTickets());
  }

  editTicketAt(
    index: number,
    amount: number,
    fraction: string,
    hash: string,
    searchAdminId?: string,
  ): void {
    const groupForm = this.combinations.at(index);
    groupForm.get('amount').setValue(amount);
    groupForm.get('hash').setValue(hash);
    groupForm.get('value.fraction').setValue([fraction]);
    if (groupForm.get('searchAdminId')) {
      groupForm.get('searchAdminId').setValue(searchAdminId);
    }
  }

  editTicketFractionsAt(ticketNumber: string, amount: number): void {
    const combinationsForSameTicket = deepClone(
      this.combinations.controls.filter(
        c => c.value.value.numero[0] === ticketNumber,
      ),
    );
    const lastGroupForm = deepClone(combinationsForSameTicket).pop();
    const totalFractions = combinationsForSameTicket.length;
    const referenceTicket = this.selectedTickets.get(lastGroupForm.value.hash);
    if (totalFractions < amount) {
      // add a new fraction available
      // get available fractions
      const ticketFractions: Array<string> =
        this.availableFractions.get(ticketNumber);
      const usedFractions: Array<string> = combinationsForSameTicket.map(
        c => c.value.value.fraccion[0],
      );
      const availableFractions = ticketFractions.filter(
        f => !usedFractions.includes(f),
      );
      if (availableFractions.length > 0) {
        this.addTicket(
          referenceTicket,
          1,
          availableFractions[0],
          LotteryTicket.getHash(referenceTicket, availableFractions[0]),
        );
      }
    } else {
      // remove last fraction added
      this.combinations.removeAt(
        this.combinations.controls.findIndex(c => c === lastGroupForm),
      );
    }
  }

  removeTicketBy(hash: string, number: string): void {
    this.removeTicketFromCombinations(this.combinations, number);

    this.selectedTickets.delete(hash);
    this.selectedTicketsChange.next(this.getSelectedTickets());
  }

  removeTicketWithNumber(number: string): void {
    this.selectedTickets.forEach((ticket, hash) => {
      const ticketNumber = (
        ticket.value.find(
          c => c.typeId === 'numero' && c.type === 'NUMBER',
        ) as NumberCombinationType
      ).value[0].value;
      if (ticketNumber === number) {
        this.removeTicketBy(hash, number);
      }
    });
  }

  getSelectedTicketsByNumber(): Array<string> {
    const map = new Map<string, LotteryTicket>();
    this.selectedTickets.forEach(ticket => {
      const ticketNumber = (
        ticket.value.find(
          c => c.typeId === 'numero' && c.type === 'NUMBER',
        ) as NumberCombinationType
      ).value[0].value;
      if (!map.has(ticketNumber)) {
        map.set(ticketNumber, ticket);
      }
    });
    return Array.from(this.selectedTickets.values())
      .map(ticket => {
        return (
          ticket.value.find(
            c => c.typeId === 'numero' && c.type === 'NUMBER',
          ) as NumberCombinationType
        ).value[0].value;
      })
      .filterUnique(ticketNumber => ticketNumber);
  }

  protected removeTicketAt(index: number): void {
    const ticket = this.combinations.at(index);
    // Check if the ticket has more fractions, if not, remove the ticket from the selected tickets
    // else, keep the ticket in the selected tickets
    const hasMoreFractions =
      this.combinations.controls.filter(c => c.value.hash === ticket.value.hash)
        .length > 1;
    if (!hasMoreFractions) {
      this.selectedTickets.delete(ticket.value.hash);
      this.selectedTicketsChange.next(this.getSelectedTickets());
    }
    this.combinations.removeAt(index);
  }

  protected removeTicketFromCombinations(
    formArray: FormArray,
    number: string,
  ): void {
    formArray.controls.forEach(c => {
      if (c.value.value.numero[0] === number) {
        formArray.removeAt(formArray.controls.indexOf(c));
        this.removeTicketFromCombinations(formArray, number);
      }
    });
  }
}
