import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

import {GenericTicket} from '../../games/tickets/data/generic-ticket';

export class TicketsByBoothFormGroup extends FormGroup {
  constructor(boothId: string) {
    super({
      boothId: new FormControl(boothId, Validators.required),
      tickets: new FormArray(
        [],
        Validators.compose([Validators.required, Validators.minLength(1)]),
      ),
      amount: new FormControl(0),
    });
  }

  static createFormTicket(ticket: GenericTicket) {
    let form = new TicketsByBoothFormGroup(ticket.bet.boothId);
    form.addTicket(ticket);

    return form;
  }

  addTicket(ticket: GenericTicket) {
    if (this.value.boothId !== ticket.bet.boothId) {
      // prevent adding tickets from different booths
      return;
    }

    (<FormArray>this.controls['tickets']).push(new FormControl(ticket.id));
    this.controls['amount'].setValue(this.value.amount + ticket.bet.betsNumber);
  }

  removeTicket(ticket: GenericTicket) {
    const index = (<FormArray>this.controls['tickets']).controls.findIndex(
      control => control.value === ticket.id,
    );

    if (index >= 0) {
      (<FormArray>this.controls['tickets']).removeAt(index);
      this.controls['amount'].setValue(this.value.amount - ticket.bet.betsNumber);
    }
  }
}
