import {Location, PlatformLocation, PopStateEvent} from '@angular/common';
import {Injectable} from '@angular/core';
import {NavigationCancel, NavigationEnd, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

export type PopDirection = 'forward' | 'back';

export interface OnPopstate {
  onPopstate(
    direction: PopDirection,
  ): boolean | Observable<boolean> | Promise<boolean>;
}

@Injectable({providedIn: 'root'})
export class PopstateService {
  /**
   * Stores the last popstate event direction until a navigation is finished or
   * cancelled. Null if the current navigation isn't triggered by a popstate
   * event.
   */
  lastPopState: PopDirection | null;

  popStateDirection = new Subject<PopDirection>();

  private historyId = 1;

  private lastRoute: string;

  constructor(
    private platformLocation: PlatformLocation,
    private location: Location,
    private router: Router,
  ) {
    this.patchPlatformLocation();
    this.watchNavigations();
  }

  private patchPlatformLocation(): void {
    // Patch angular methods to include extra info in history states
    let oldPush: any = this.platformLocation.pushState.bind(this.platformLocation);
    this.platformLocation.pushState = (
      state: any,
      title: string,
      url: string,
    ): void => {
      state['historyId'] = ++this.historyId;

      return oldPush(state, title, url);
    };
    this.platformLocation.pushState.bind(this.platformLocation);

    let oldReplace = this.platformLocation.replaceState.bind(this.platformLocation);
    this.platformLocation.replaceState = (
      state: any,
      title: string,
      url: string,
    ): void => {
      state['historyId'] = this.historyId;

      return oldReplace(state, title, url);
    };
    this.platformLocation.replaceState.bind(this.platformLocation);
    // ------------- end patch -----------------

    // detect popstate direction
    this.location.subscribe((e: PopStateEvent) => {
      if (this.location.isCurrentPathEqualTo(this.lastRoute)) {
        // popping to same route doesn't trigger a navigation
        return;
      }

      if (this.historyId < e?.state?.historyId || 0) {
        this.lastPopState = 'forward';
      } else {
        this.lastPopState = 'back';
      }

      this.historyId = e?.state?.historyId || 0;

      this.popStateDirection.next(this.lastPopState);
    });
  }

  private watchNavigations(): void {
    // clear popstate direction to avoid false positives on next ones
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd || e instanceof NavigationCancel))
      .subscribe(() => (this.lastPopState = null));

    // keep track of last successful navigation
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => (this.lastRoute = this.location.path(false)));
  }
}
