import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, first} from 'rxjs/operators';

import {BlurService} from './blur.service';

@Injectable()
export class FocusGuard implements CanActivateChild {
  constructor(private centerFocusService: BlurService, private router: Router) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.router.events
      .pipe(
        filter(ev => ev instanceof NavigationEnd),
        first(),
      )
      .subscribe((ev: NavigationEnd) =>
        this.centerFocusService.setBlur(ev.url === state.url),
      );
    return true;
  }
}
