import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';

import {ResponsiveService} from '../responsive/responsive.service';

import {Press} from './press';
import {PressUp} from './press-up';

/**
 * Service to be used as an EventManagerPlugin.
 * Handles press and pressup events.
 */
@Injectable({
  providedIn: 'root',
})
export class PressEventsService {
  constructor(
    @Inject(DOCUMENT) protected doc: Document,
    protected responsiveService: ResponsiveService,
    @Inject('window') private window: Window,
  ) {}

  supports(eventName: string): boolean {
    return eventName === 'tlpress' || eventName === 'tlpressup';
  }

  addEventListener(
    element: HTMLElement,
    eventName: string,
    originalHandler: (...args: any[]) => any,
  ): (...args: any[]) => any {
    const pressEvent =
      eventName === 'tlpress'
        ? new Press(this.responsiveService, element, originalHandler, this.doc)
        : new PressUp(this.responsiveService, element, originalHandler, this.doc);
    return pressEvent.onDestroy;
  }

  addGlobalEventListener(
    element: string,
    eventName: string,
    handler: (...args: any[]) => any,
  ): (...args: any[]) => any {
    let target: EventTarget;
    switch (element) {
      case 'document':
        target = this.doc;
        break;
      case 'window':
        target = this.window;
        break;
      case 'body':
        target = this.doc.body;
        break;
    }
    if (!target) {
      throw new Error(`Unsupported event target ${target} for event ${eventName}`);
    }
    return this.addEventListener(target as HTMLElement, eventName, handler);
  }
}
