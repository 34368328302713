import {Injectable} from '@angular/core';
import {UpcomingGame} from './upcoming.game';
import {Observable, first, from, map, of, reduce, switchMap} from 'rxjs';
import {GameMetadataService} from '../../game-metadata/data/game-metadata.service';
import {GameMetadata} from '../../game-metadata/data/game-metadata';
import {UpcomingGamesGroupsProcessDataService} from './upcoming-games-groups-process-data.service';

class FilterTuple {
  game: UpcomingGame;
  metadata: GameMetadata;
}

@Injectable()
export class UpcomingGamesGroupsProcessPlayableService
  implements UpcomingGamesGroupsProcessDataService
{
  constructor(private gameMetadataService: GameMetadataService) {}

  processList(list: Array<UpcomingGame>): Observable<Array<UpcomingGame>> {
    return of(list).pipe(
      switchMap((games: Array<UpcomingGame>) =>
        this.generateTupleGameMetadata(games),
      ),
      map((dataList: Array<FilterTuple>) =>
        dataList.filter((data: FilterTuple) => !!data.metadata.playableOnGroups),
      ),
      map((dataList: Array<FilterTuple>) =>
        dataList.map((data: FilterTuple) => data.game),
      ),
    );
  }

  private generateTupleGameMetadata(
    upcomingGames: Array<UpcomingGame>,
  ): Observable<Array<FilterTuple>> {
    return from(upcomingGames).pipe(
      switchMap(game =>
        this.gameMetadataService.getGameMetadata(game.gameId, game.gameVersion).pipe(
          first(),
          map(metadata => <any>{game: game, metadata: metadata}),
        ),
      ),
      reduce((all, data) => all.concat([data]), []),
    );
  }
}
