import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Inject,
} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {Subject, combineLatest, debounceTime, takeUntil} from 'rxjs';
import {NgValidator} from '../validation/ng.validator';
import {Destroyable} from '../../util/destroyable';
import {Country} from '../../countries/country';
import {ENVIRONMENT} from '../../environment/environment-token';

interface InputPhoneValueChangeType {
  country: Country;
  local: string;
}

@Component({
  selector: 'tl-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPhoneComponent implements OnInit, OnChanges {
  @Input()
  controlNameCountry!: string;

  @Input()
  controlNameLocal!: string;

  @Input()
  enableValidators = true;

  @Input()
  acceptLandlineFormat = false;

  @Input()
  id = Math.random().toString();

  @Input()
  placeholder = '';

  @Input()
  disabled = false;

  @Input()
  readonly = false;

  @Input()
  ngTabindex: number;

  @Input()
  submitted: boolean;

  @Output()
  changed = new EventEmitter<InputPhoneValueChangeType>();

  @Destroyable()
  private destroy = new Subject<void>();

  get form(): FormGroup {
    return this.fg.form;
  }

  get controlCountry(): FormControl {
    return this.form.get(this.controlNameCountry) as FormControl;
  }

  get selectedCountry(): Country {
    return this.controlCountry.value as Country;
  }

  get controlLocal(): FormControl {
    return this.form.get(this.controlNameLocal) as FormControl;
  }

  constructor(
    private fg: FormGroupDirective,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {}

  ngOnInit(): void {
    // notify input changes
    combineLatest([this.controlCountry.valueChanges, this.controlLocal.valueChanges])
      .pipe(debounceTime(50), takeUntil(this.destroy))
      .subscribe(values =>
        this.changed.emit({
          country: values[0],
          local: values[1],
        }),
      );

    // set states
    this.setDisabled();
    this.setValidators();

    // cross-validate
    this.controlCountry.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.controlLocal.updateValueAndValidity());
  }

  ngOnChanges(changes: SimpleChanges): void {
    // disabled state
    if (changes.hasOwnProperty('disabled')) {
      this.setDisabled();
    }

    // format validators
    if (changes.hasOwnProperty('enableValidators')) {
      this.setValidators();
    }
  }

  private setDisabled(): void {
    if (this.disabled || this.controlCountry.disabled) {
      this.controlCountry.disable();
    } else {
      this.controlCountry.enable();
    }
    if (this.disabled || this.controlLocal.disabled) {
      this.controlLocal.disable();
    } else {
      this.controlLocal.enable();
    }
  }

  private setValidators(): void {
    const formatValidator = NgValidator.phoneFormat(
      this.controlCountry,
      this.environment?.phone?.skipValidation,
      this.acceptLandlineFormat,
    );

    if (this.enableValidators) {
      this.controlLocal.addValidators(formatValidator);
    } else {
      this.controlLocal.removeValidators(formatValidator);
    }
  }
}
