import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    terms: [
      {
        originUrl: environment.internalUrls.terms,
        destinationPlatformUrl: {
          desktop: `/${routeMap.legal}`,
          mobile: `/m/${routeMap.legal}`,
        },
      },
    ],
  },
];

@NgModule({
  providers: [
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class TermsUriMappingModule {}
