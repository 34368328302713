<tl-modal-dialog type="buttonless" class="tl-code-dialog__modal">
  <div class="tl-code-dialog__wrapper">
    <div *tlIfDesktop class="tl-code-dialog__img-wrap">
      <img
        class="tl-code-dialog__image--desktop"
        src="assets/img/seguridad/verificar-codigo.svg" />
    </div>

    <div class="tl-code-dialog__content">
      <tl-scrollable class="tl-code-dialog__scroll">
        <img
          *tlIfMobile
          class="tl-code-dialog__image--mobile"
          src="assets/img/seguridad/verificar-codigo.svg" />

        <p class="dark-default tl-code-dialog__title">
          {{ 'userProfile.verifyPhone.dialogIdentity' | i18n }}
        </p>
        <p class="tl-code-dialog__description">
          {{
            'userProfile.verifyPhone.dialogDescription'
              | i18nData: {phone: phone | phoneFormat}
          }}
        </p>

        <div class="form-group tl-code-dialog__form">
          <input
            class="form-control tl-code-dialog__input"
            [class.input-xs]="!isMobile"
            type="tel"
            tlTriggerOnKeyboardOpen
            [formControl]="form"
            id="code-input" />
          <div
            class="tl-code-dialog__error-area"
            [tlFormMessages]="form"
            [submitted]="submitted">
            <div tlFormMessage="required">
              {{ 'userProfile.verifyPhone.errorSmsCodeEmpty' | i18n }}
            </div>
            <div *ngIf="wrongCode | async as errorMsg">
              {{ errorMsg }}
            </div>
          </div>
        </div>

        <div class="tl-code-dialog__buttons">
          <button
            class="btn tl-code-dialog__button tl-code-dialog__button--accept"
            [ngClass]="{
              'btn-mobile-round': isMobile,
              'btn-mobile-accept': isMobile,
              'btn-type-4': !isMobile,
              accept: !isMobile
            }"
            (click)="confirm()">
            {{ 'userProfile.verifyPhone.verifyCode' | i18n }}
          </button>

          <button
            class="btn tl-code-dialog__button tl-code-dialog__button--resend"
            [ngClass]="{
              'btn-mobile-round': isMobile,
              'btn-mobile-cancel': isMobile,
              'btn-type-3': !isMobile,
              cancel: !isMobile,
              disabled: resendDisabled
            }"
            (click)="resendSMS()">
            <tl-translatable-text [text]="resendText"></tl-translatable-text>
          </button>
        </div>
      </tl-scrollable>
    </div>
  </div>
</tl-modal-dialog>
