import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {UriMapper} from '../uri/uri-mapper';
import {catchError, filter, first} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {of} from 'rxjs';

@Pipe({
  name: 'translatableUrl',
})
export class TranslatableUrlPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(protected cdr: ChangeDetectorRef, private uriMapper: UriMapper) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(value: string): string {
    const regexHasLink = /href\s*=\s*["'](.*?)["']/;
    if (regexHasLink.test(value)) {
      const regexUrl = /href\s*=\s*["'](.*?)["']/gi;
      const matches: Array<RegExpMatchArray> = Array.from(value.matchAll(regexUrl));
      if (matches) {
        // iterate urls
        for (const match of matches) {
          const url: string = match[1];
          if (this.uriMapper.isInternalRoute(url)) {
            const internalUrl$ = this.uriMapper.map(url).pipe(
              first(),
              filter(uri => !!uri),
              catchError(() => of(void 0)),
            );
            const internalUrl = this.asyncPipe.transform(internalUrl$);
            if (internalUrl) {
              // Add tag with original url for avoid reload page on click
              // (TranslatableTextComponent)
              const replaceUrl = `${internalUrl}" data-href="${url}`;
              value = value.replace(url, replaceUrl);
            }
          }
        }
      }
    }
    return value;
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }
}
