import {NgModule} from '@angular/core';

import {AppModule} from './index';
import {RootComponent} from './root.component';

@NgModule({
  imports: [AppModule],
  declarations: [RootComponent],
  bootstrap: [RootComponent],
})
export class RootModule {}
