import {NgModule} from '@angular/core';
import {TicketsCanceledViewService} from './tickets-canceled-view.service';
import {provideClass} from 'common';
import {environment} from '~environments/environment';
import {NoopTicketsCanceledViewService} from './noop-tickets-canceled-view.service';

@NgModule({
  providers: [
    TicketsCanceledViewService,
    provideClass(
      TicketsCanceledViewService,
      environment.features.reserveMode
        ? TicketsCanceledViewService
        : NoopTicketsCanceledViewService,
    ),
  ],
})
export class TicketsCanceledModule {}
