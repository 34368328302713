import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PlatformRouterLinkModule} from '../responsive/platform-link/platform-router-link.module';

import {CardComponent} from './card.component';

@NgModule({
  imports: [CommonModule, PlatformRouterLinkModule],
  exports: [CardComponent],
  declarations: [CardComponent],
})
export class CardModule {}
