export class GridCell {
  constructor(
    public id: string,
    public index: number,
    public x: number,
    public y: number,
    public width: number,
    public height: number,
  ) {}

  setXBound(x: number): GridCell {
    this.width = x - this.x + 1;
    return this;
  }

  setYBound(y: number): GridCell {
    this.height = y - this.y + 1;
    return this;
  }

  toStyle(
    rows: number,
    columns: number,
  ): {top: string; left: string; width: string; height: string} {
    return {
      top: `${(100 * this.y) / rows}%`,
      left: `${(100 * this.x) / columns}%`,
      width: `${(100 * this.width) / columns}%`,
      height: `${(100 * this.height) / rows}%`,
    };
  }
}
