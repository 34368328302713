import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../../../../common/model/abstract.dao';

@Injectable({providedIn: 'root'})
export class LotenalSearchDao extends AbstractDao {
  constructor(protected http: HttpClient) {
    super();
  }

  freeUserTickets(raffleId: number, ids: Array<number>): Observable<unknown> {
    return this.requestFree(
      this.baseUrl + '/users/lottery/free/search',
      raffleId,
      ids,
    );
  }

  freePublicTickets(raffleId: number, ids: Array<number>): Observable<unknown> {
    return this.requestFree(this.baseUrl + '/lottery/free/search', raffleId, ids);
  }

  private requestFree(
    url: string,
    raffleId: number,
    ids: Array<number>,
  ): Observable<unknown> {
    return this.http.post(url, {sorteoId: raffleId, transactionsIds: ids});
  }
}
