import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {InfoModule} from '../../info/info.module';
import {SanitizerModule} from '../../sanitizer/sanitizer.module';

import {FormRowComponent} from './form-row.component';
import {UtilModule} from '../../util/util-module';
import {TranslatableTextModule} from '../../i18n/translatable-text/translatable-text.module';
import {ResponsiveUtilsModule} from '../../responsive/responsive-utils.module';

@NgModule({
  imports: [
    CommonModule,
    InfoModule,
    ResponsiveUtilsModule,
    SanitizerModule,
    UtilModule,
    TranslatableTextModule,
  ],
  exports: [FormRowComponent],
  declarations: [FormRowComponent],
})
export class FormRowModule {}
