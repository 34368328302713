import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  CurrencyModule,
  I18nModule,
  ModalDialogModule,
  SanitizerModule,
} from 'common';

import {LimitReachedModalComponent} from './limit-reached-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ModalDialogModule,
    I18nModule,
    CurrencyModule,
    SanitizerModule,
  ],
  exports: [LimitReachedModalComponent],
  declarations: [LimitReachedModalComponent],
})
export class LimitReachedModalModule {}
