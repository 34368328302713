import {InjectionToken} from '@angular/core';

export const TOOLTIP_CONFIG = new InjectionToken<TooltipConfig>(
  'TooltipServiceConfig',
  {
    providedIn: 'root',
    factory: () => <TooltipConfig>{tooltipClass: 'tl-tooltip'},
  },
);

export interface TooltipConfig {
  tooltipClass: string;
}
