import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';

/**
 * Navigate to parent route if the route is like:
 *
 * - /foo/bar
 * - /foo/bar?params=1
 *
 * Won't work with:
 * - /foo/bar/param1 if you suppose the parent is /foo
 * - /foo/bar(secondary:outlet)
 */
@Injectable()
export class ParentNavigationGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.router.navigated) {
      const parent = decodeURI(state.url.replace(/(\/[\w]*)(?=\?.*$|$)/g, ''));
      this.router.navigate([parent]);
      return false;
    }

    return true;
  }
}
