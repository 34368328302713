import {Inject, Injectable} from '@angular/core';
import {Logger} from 'common';
import {first, switchMap} from 'rxjs/operators';

import {SessionService} from '../../../../user/auth/session.service';
import {LotteryTicket} from '../../../lottery/data/lottery-ticket';
import {LotteryBetFormService} from '../form/lottery-bet-form.service';

import {LotenalSearchDao} from './lotenal-search.dao';

/**
 * Keeps track of the searched tickets with their associated search
 * transactionId.
 *
 * Allows to reserve the transactionId of tickets that are going to be bought
 * and liberate the ids of tickets that are not.
 */
@Injectable()
export class LotenalBetFormService extends LotteryBetFormService {
  // transactionId -> boolean
  private searchIds: Map<number, boolean>;

  constructor(
    private logger: Logger,
    private lotenalSearchDao: LotenalSearchDao,
    private sessionService: SessionService,
    @Inject('window') private window: Window,
  ) {
    super();
    this.searchIds = new Map<number, boolean>();

    this.window.addEventListener('beforeunload', () => this.clearSelectedTickets());
  }

  registerSearchId(ticket: LotteryTicket): void {
    this.searchIds.set(ticket.transactionId, true);
  }

  removeTicket(hash: string): void {
    const ticket = this.selectedTickets.get(hash);
    if (ticket) {
      this.markDelete(ticket);
    }

    super.removeTicket(hash);
  }

  removeAllTickets(): void {
    super.removeAllTickets();

    this.clearSelectedTickets();
  }

  free(): void {
    if (this.searchIds.size <= 0) {
      return;
    }

    const selectedIds = Array.from(this.selectedTickets.values()).map(
      t => t.transactionId,
    );
    let idsToFree = Array.from(this.searchIds)
      .filter(id => !selectedIds.includes(id[0]))
      .map(id => id[0]);

    if (idsToFree.length <= 0) {
      return;
    }

    this.sessionService
      .isLoggedIn()
      .pipe(
        first(),
        switchMap(isLoggedIn => {
          if (isLoggedIn) {
            return this.lotenalSearchDao.freeUserTickets(this.raffleId, idsToFree);
          } else {
            return this.lotenalSearchDao.freePublicTickets(this.raffleId, idsToFree);
          }
        }),
      )
      .subscribe({
        error: e =>
          this.logger.error(
            'Ha ocurrido un error al liberar las busquedas.',
            null,
            e,
          ),
      });

    this.searchIds.clear();
  }

  clearSelectedTickets(): void {
    this.selectedTickets.forEach(ticket => this.markDelete(ticket));
    super.clearSelectedTickets();
    this.free();
  }

  saveState(): void {
    this.savedTickets.clear();

    this.selectedTickets.forEach(ticket => {
      this.savedTickets.set(ticket.hash, ticket);
      this.searchIds.delete(ticket.transactionId);
    });
    this.selectedTickets.clear();
    this.selectedTicketsChange.next(this.getSelectedTickets());
  }

  private markDelete(ticket: LotteryTicket): void {
    this.registerSearchId(ticket);
  }
}
