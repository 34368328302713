import {Country, Serializable, SerializableProperty, SerializableType} from 'common';

import {MessageBanner} from '../../banner/data/message-banner';
import {LotteryBooth} from '../../booth/data/lottery-booth';
import {GameMetadata} from '../../games/game-metadata/data/game-metadata';
import {GenericResult} from '../../games/results/data/generic-result';
import {Sentence} from '../../games/sentences/sentence';
import {UpcomingGame} from '../../games/upcoming/data/upcoming.game';
import {Endpoint} from '../endpoint/endpoint';
import {State} from '../../states/data/state';
import {MessageBannerByState} from '../../banner/data/message-banner-by-state';

class PublicInfoInternal {
  @SerializableProperty(GenericResult)
  results: Array<GenericResult>;

  @SerializableProperty(UpcomingGame)
  upcomingGames: Array<UpcomingGame>;

  @SerializableProperty(Sentence)
  sentences: Array<Sentence>;

  @SerializableProperty(GameMetadata)
  gameMetadata: Array<GameMetadata>;

  @SerializableProperty(LotteryBooth)
  lotteryBooths: Array<LotteryBooth>;

  @SerializableProperty(Endpoint, SerializableType.OBJECT)
  endpointInfo: Endpoint;

  @SerializableProperty(State)
  states: Array<State>;

  @SerializableProperty(MessageBanner, SerializableType.OBJECT)
  messageBanner: MessageBanner;

  @SerializableProperty(MessageBannerByState, SerializableType.COLLECTION)
  messagesBannerByState: Array<MessageBannerByState>;

  @SerializableProperty(Country)
  countries: Array<Country>;
}

export class PublicInfo extends Serializable(PublicInfoInternal) {
  static createFromBackend(obj: any): any {
    let publicInfo = new PublicInfo();

    // type news means don't show the message
    if (obj.mensajeBanner) {
      // If exist bannerList, we must use bannerList
      if (
        obj.mensajeBanner.type !== 'news' &&
        obj.mensajeBanner.texto &&
        !obj.bannerList
      ) {
        publicInfo.messageBanner = MessageBanner.createFromBackend(
          obj.mensajeBanner,
        );
      }
    }

    if (obj.hasOwnProperty('bannerList')) {
      publicInfo.messagesBannerByState = [];
      publicInfo.messagesBannerByState = obj.bannerList.map(
        MessageBannerByState.createFromBackend,
      );
    }

    if (obj.hasOwnProperty('resultados')) {
      publicInfo.results = obj.resultados.map(result =>
        GenericResult.createFromBackend(result),
      );
    }

    if (obj.hasOwnProperty('proximosSorteos')) {
      publicInfo.upcomingGames = obj.proximosSorteos.map(upcoming =>
        UpcomingGame.createFromBackend(upcoming),
      );
    }

    if (obj.hasOwnProperty('frases')) {
      publicInfo.sentences = [];
      for (let frase of obj.frases) {
        publicInfo.sentences.push(Sentence.createFromBackend(frase));
      }
    }

    if (obj.hasOwnProperty('administraciones')) {
      publicInfo.lotteryBooths = [];
      for (let adm of obj.administraciones) {
        publicInfo.lotteryBooths.push(LotteryBooth.createFromBackend(adm));
      }
    }

    if (
      obj.hasOwnProperty('genericDescriptors') &&
      obj.genericDescriptors &&
      obj.genericDescriptors.length > 0
    ) {
      publicInfo.gameMetadata = obj.genericDescriptors.map(desc =>
        GameMetadata.createFromBackend(desc),
      );
    }

    if (obj.hasOwnProperty('endpoint')) {
      publicInfo.endpointInfo = Endpoint.createFromBackend(obj.endpoint);

      publicInfo.states = obj.endpoint.states.map(state =>
        State.createFromBackend(state, obj.endpoint),
      );
    }

    if (obj.hasOwnProperty('allCountries')) {
      publicInfo.countries = obj.allCountries.map(country =>
        Country.createFromBackend(country),
      );
    }

    return publicInfo;
  }
}
