import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class NgGroupValidator {
  static passwords(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const passwordField = control.get('password');
      const repeatField = control.get('repeatedPassword');
      if (
        !!passwordField.value &&
        !!repeatField.value &&
        passwordField.value !== repeatField.value
      ) {
        return {
          password: {
            equals: true,
          },
        };
      } else {
        return null;
      }
    };
  }
}
