import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

const uriMaps: UriConfig = [
  {
    home: [
      {
        originUrl: environment.internalUrls.home,
        destinationPlatformUrl: {
          desktop: '/',
          mobile: `/m/${environment.locale.routes.mobile.main.games}`,
        },
      },
    ],
  },
];

@NgModule({
  providers: [
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class AppUriMappingModule {}
