import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {ResponsiveService} from '../../responsive/responsive.service';
import {encodeURLParameters} from '../../util/url';
import {Shareable} from '../shareable';

@Injectable()
export class WhatsappService implements Shareable {
  notify = false;

  constructor(
    private responsiveService: ResponsiveService,
    @Inject('window') private window: Window,
  ) {}

  share(title: string, text: string): Observable<any> {
    const baseUrl = this.responsiveService.isDesktop() ? 'web' : 'api';
    const textShare = `*${title}*%0a${encodeURLParameters(text)}`;
    this.window.open(
      `https://${baseUrl}.whatsapp.com/send?text=${textShare}`,
      '_blank',
    );
    return of(true);
  }
}
