export enum BarcodeFormat {
  /** Aztec 2D barcode format. */
  AZTEC = 'aztec',
  /** CODABAR 1D format. */
  CODABAR = 'codabar',
  /** Code 39 1D format. */
  CODE_39 = 'code_39',
  /** Code 93 1D format. */
  CODE_93 = 'code_93',
  /** Code 128 1D format. */
  CODE_128 = 'code_128',
  /** Data Matrix 2D barcode format. */
  DATA_MATRIX = 'data_matrix',
  /** EAN-8 1D format. */
  EAN_8 = 'ean_8',
  /** EAN-13 1D format. */
  EAN_13 = 'ean_13',
  /** ITF (Interleaved Two of Five) 1D format. */
  ITF = 'itf',
  /** MaxiCode 2D barcode format. */
  MAXICODE = 'maxicode',
  /** PDF417 format. */
  PDF_417 = 'pdf417',
  /** QR Code 2D barcode format. */
  QR_CODE = 'qr_code',
  /** RSS 14 */
  RSS_14 = 'rss_14',
  /** RSS EXPANDED */
  RSS_EXPANDED = 'rss_expanded',
  /** UPC-A 1D format. */
  UPC_A = 'upc_a',
  /** UPC-E 1D format. */
  UPC_E = 'upc_e',
  /** UPC/EAN extension format. Not a stand-alone format. */
  UPC_EAN_EXTENSION = 'upc_ean_extension',
  /** Unknow **/
  UNKNOWN = 'unknown',
}
