import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {AuthorizationInterceptor} from './authorization.interceptor';
import {UnauthorizationInterceptor} from './unauthorization.interceptor';

@NgModule({})
export class UserModelModule {
  static withInterceptors(): ModuleWithProviders<UserModelModule> {
    return {
      ngModule: UserModelModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthorizationInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: UnauthorizationInterceptor,
          multi: true,
        },
      ],
    };
  }
}
