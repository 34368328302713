<div
  class="tl-video__wrapper"
  [ngStyle]="{background: backgroundThumbnail}"
  (click)="play()">
  <ng-container>
    <span class="tl-video__wrapper__duration">
      {{ duration }}
    </span>
    <div class="tl-video__wrapper__play-button">
      <img src="/assets/img/video/play.svg" />
    </div>
    <span class="tl-video__wrapper__title">{{ title }}</span>
  </ng-container>
</div>
