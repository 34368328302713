import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {IsActiveMatchOptions, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {Destroyable} from '../util/destroyable';

@Directive({
  selector: '[tlMatchUrlRouterLinkActive]',
})
export class MatchUrlRouterLinkActiveDirective implements OnInit, OnChanges {
  @Input('tlMatchUrlRouterLinkActiveClass')
  activeClass: string;

  @Input('tlMatchUrlRouterLinkActive')
  activeUri: string;

  /** Determines if check a subpath of activeUri
   * example: 'ticket/25847' --> 'ticket/25847/edit' = TRUE
   */
  @Input('tlMatchUrlRouterLinkActiveSubPath')
  subPath = false;

  @Destroyable()
  private destroy = new Subject();

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private element: ElementRef,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(takeUntil(this.destroy))
      .subscribe((event: NavigationEnd) => {
        this.updateElementClass();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty('activeClass') &&
      changes.activeClass.hasOwnProperty('previousValue')
    ) {
      const previousValue = changes.activeClass.previousValue;
      this.renderer.removeClass(this.element.nativeElement, previousValue);
    }
    if (
      (changes.hasOwnProperty('activeClass') ||
        changes.hasOwnProperty('activeUri')) &&
      this.activeClass &&
      this.activeUri
    ) {
      this.updateElementClass();
    }
  }

  private updateElementClass(): void {
    this.renderer.removeClass(this.element.nativeElement, this.activeClass);

    const mathOptions: IsActiveMatchOptions = {
      paths: this.subPath ? 'subset' : 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
    };

    if (this.router.isActive(this.activeUri, mathOptions)) {
      this.renderer.addClass(this.element.nativeElement, this.activeClass);
    }
  }
}
