import {ElementRef} from '@angular/core';

export interface ScrollEvent {
  scrollTop: number;

  scrollPercent: number;

  element: ElementRef;

  scrollDirection: ScrollDirection;
}

export enum ScrollDirection {
  DOWN,
  UP,
}
