<div
  *ngFor="let control of form.controls; let i = index; let last = last"
  class="tl-boolean-table__cell-wrap"
  [class.tl-boolean-table__cell-wrap--last]="last"
  [style.background-color]="highlight">
  <div
    class="selectable-cell tl-boolean-table__boolean-cell"
    [class.selected]="control | controlValue | async"
    [class.disabled]="(control | controlStatus | async) === 'DISABLED'"
    [style.border-color]="
      (control | controlValue | async) ? selectedBorderColor : borderColor
    "
    [style.background-color]="(control | controlValue | async) ? selectedColor : ''"
    (click)="onCellToggle(control)">
    <img
      *ngIf="cellImage; else labelled"
      class="tl-boolean-table__image"
      [src]="cellImage" />
    <ng-template #labelled>{{ cellLabel }}</ng-template>
  </div>
</div>
