import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SelectableModeComponent} from './selectable-mode.component';

@NgModule({
  imports: [CommonModule],
  exports: [SelectableModeComponent],
  declarations: [SelectableModeComponent],
})
export class SelectableModeModule {}
