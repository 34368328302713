import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {AbstractDao} from '../common/model/abstract.dao';

declare const dataLayer: DataLayer;

export interface DataLayer {
  push(data: any);
}

@Injectable({providedIn: 'root'})
export class MeasuringDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Returns true if and only if the server returns ok.
   */
  sendBackendEvent(name: string): Observable<boolean> {
    return this.http.get(this.baseUrl + '/users/events/shouldSend/' + name).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  sendTagManagerEvent(name: string, options = {}) {
    if (environment.production && typeof dataLayer === 'object') {
      dataLayer.push(Object.assign({event: name}, options));
    }
  }
}
