import {trigger} from '@angular/animations';
import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

import {slideFromTop} from '../../animation/angular/slide-from-top';
import {TranslatableText} from '../../i18n/translatable-text';
import {AlertType} from '../alert-type';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [trigger('SlideFromTop', slideFromTop('-100%'))],
})
export class AlertComponent {
  @Input()
  message: string | TranslatableText;

  @Input()
  @HostBinding('class')
  type: AlertType;

  @HostBinding('@SlideFromTop')
  animation: string;

  @Output()
  changeEvent = new EventEmitter<any>();

  cancel() {
    this.changeEvent.emit();
  }
}
