import {Observable, of} from 'rxjs';
import {delay, map as mapOperator, retry} from 'rxjs/operators';

import {CustomMarkerArgs, CustomMarkerType} from './custom-marker-type';

// eslint-disable-next-line @typescript-eslint/naming-convention
export let CustomMarker;

export function initCustomMarker() {
  class CustomMarkerCls extends google.maps.OverlayView implements CustomMarkerType {
    public marker: HTMLDivElement;

    constructor(
      private latLng: google.maps.LatLng,
      public map: google.maps.Map,
      public args: CustomMarkerArgs,
      private onClick: (id: string) => void,
      private google: any,
      private document: Document,
      private className?: string,
    ) {
      super();
      this.setMap(map);
    }

    onAdd() {
      let self = this;
      this.marker = this.document.createElement('div');
      this.marker.className = this.className || 'custom-marker';
      this.marker.innerHTML = this.args.innerHTML;

      let selectArea = this.document.createElement('div');
      selectArea.className = 'select-area';

      if (typeof this.args.marker_id !== 'undefined') {
        this.marker.dataset.marker_id = this.args.marker_id;
      }

      selectArea.addEventListener('click', () => {
        if (!self.marker.classList.contains('selected')) {
          self.onClick(self.args.marker_id);
        }
        this.google.maps.event.trigger(self, 'click');
      });

      this.marker.appendChild(selectArea);
      this.getPanes().overlayMouseTarget.appendChild(this.marker);
      this.google.maps.OverlayView.preventMapHitsFrom(selectArea);
    }

    draw(): void {
      let point = this.getProjection().fromLatLngToDivPixel(this.latLng);
      if (point) {
        this.marker.style.left = point.x + 'px';
        this.marker.style.top = point.y + 'px';
      }
    }

    remove(): void {
      if (this.marker) {
        this.marker.parentNode.removeChild(this.marker);
        this.marker = null;
      }
      this.setMap(null);
    }

    getPosition(): google.maps.LatLng {
      return this.latLng;
    }

    setPosition(position: google.maps.LatLng | GeolocationPosition): void {
      if (position instanceof google.maps.LatLng) {
        this.latLng = position;
      } else {
        this.latLng = new google.maps.LatLng(
          position.coords.longitude,
          position.coords.latitude,
        );
      }
    }

    /**
     * This is done in async mode since Google Maps is who adds the markers to
     * the map and we have no way to know when this action is finished since
     * there is no possible callback.
     *
     * There are 10 attempts and 50 milliseconds between then, so this won't be
     * executed if there are more than .5 seconds of delay from Google Maps
     * adding the markers.
     *
     * @param tries number of attempts waiting for Google to add the
     *   markers
     * @param methodName
     */
    getWhenReady(
      methodName: string,
      tries = 10,
    ): Observable<CustomMarkerCls | CustomMarkerType> {
      let tryCount = 0;
      return of(null).pipe(
        delay(50),
        mapOperator(() => {
          if (!this.marker) {
            throw new Error(
              `Marker was not ready after ${++tryCount * 50}ms in ${methodName}'`,
            );
          } else {
            return this;
          }
        }),
        retry(tries),
      );
    }
  }

  CustomMarker = CustomMarkerCls;
}
