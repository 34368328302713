import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SanitizerModule} from '../../sanitizer/sanitizer.module';
import {UtilModule} from '../../util/util-module';

import {BannerComponent} from './banner.component';

@NgModule({
  imports: [CommonModule, UtilModule, SanitizerModule],
  exports: [BannerComponent],
  declarations: [BannerComponent],
})
export class BannerModule {}
