import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '~environments/environment';

import {SessionInterceptorService} from './session-interceptor.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private sessionInterceptor: SessionInterceptorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this.sessionInterceptor.isLoggedIn() &&
      this.isDomainAllowed(req.url) &&
      // Allow manually change authorization from bussiness logic
      !req.headers.has('Authorization')
    ) {
      return next.handle(
        req.clone({
          headers: req.headers.set(
            'Authorization',
            'Basic ' + this.sessionInterceptor.token,
          ),
        }),
      );
    }
    return next.handle(req);
  }

  private isDomainAllowed(url: string) {
    return (
      url.indexOf(environment.endpoint) >= 0 ||
      (Array.isArray(environment.enableAuthDomains) &&
        environment.enableAuthDomains.find(domain => url.includes(domain)))
    );
  }
}
