import {Injectable} from '@angular/core';
import {State} from './data/state';
import {first, iif, map, Observable, of, switchMap} from 'rxjs';
import {SessionService} from '../user/auth/session.service';
import {UserDao} from '../user/data/user.dao';
import {PrivateInfoService} from '../backend/private-info/private-info.service';
import {StateAppDataService} from './data/state-app-data.service';
import {Logger, TranslationsService} from 'common';
import {StatesDao} from './data/states.dao';
import {StatesService} from './data/states.service';
import {SelectorStateOperations} from './data/selector-state-operations';

@Injectable()
export class StateAppService extends SelectorStateOperations {
  constructor(
    private stateAppDataService: StateAppDataService,
    private sessionService: SessionService,
    private translationsService: TranslationsService,
    private userDao: UserDao,
    private privateInfoService: PrivateInfoService,
    private logger: Logger,
    protected statesDao: StatesDao,
    protected statesService: StatesService,
  ) {
    super(statesDao, statesService);
  }

  getState(): Observable<State> {
    return this.stateAppDataService.getData();
  }

  setState(state: State): Observable<void> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap((logged: boolean) =>
        iif(
          () => logged,
          this.updateUserState(state),
          this.updateAnonymousState(state),
        ),
      ),
    );
  }

  getSubscribedStates(): Observable<Array<string>> {
    return this.statesDao.getNotify();
  }

  getAvailableStates(): Observable<Array<State>> {
    return this.getStates().pipe(
      map(states => states.filter(state => state.available)),
    );
  }

  getAvailableStatesText(): Observable<string> {
    return this.getAvailableStates().pipe(
      map(availableStates => availableStates.map(state => state.code)),
      switchMap(availableStateCodes => {
        if (availableStateCodes.length > 1) {
          const lastCode = availableStateCodes.pop(); // Remove the last element
          return this.translationsService
            .getTranslation('global.and')
            .pipe(
              map(
                andTranslation =>
                  `${availableStateCodes.join(', ')} ${andTranslation} ${lastCode}`,
              ),
            );
        } else {
          return of(availableStateCodes.join(''));
        }
      }),
    );
  }

  notifyState(
    state: State,
    email: string,
  ): Observable<{status: string; message: string}> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap((logged: boolean) =>
        iif(
          () => !logged,
          this.anonymousSubscribeState(state, email),
          this.subscribeState(state),
        ),
      ),
    );
  }

  private subscribeState(
    state: State,
  ): Observable<{status: string; message: string}> {
    return this.statesDao.notify(state);
  }

  private anonymousSubscribeState(
    state: State,
    email: string,
  ): Observable<{status: string; message: string}> {
    return this.statesDao.notifyAnonymous(state, email);
  }

  private updateUserState(state: State): Observable<void> {
    return iif(
      () => !!state,
      this.userDao.updateExtraField('userStateCode', state.code).pipe(
        switchMap(() => this.privateInfoService.update()),
        switchMap(() => of(null)),
      ),
      of(null),
    );
  }

  private updateAnonymousState(state: State): Observable<void> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap((logged: boolean) =>
        iif(
          () => !logged,
          of(null).pipe(
            switchMap(() => {
              this.stateAppDataService.setData(state);
              return of(null);
            }),
          ),
          of(null).pipe(
            switchMap(() => {
              this.logger.error('Change anonymous App State when is logged', null, {
                state: state,
              });
              return of(null);
            }),
          ),
        ),
      ),
    );
  }
}
