export function mod(number, n) {
  return ((number % n) + n) % n;
}

export function padding(number, width) {
  width -= number.toString().length;
  if (width > 0) {
    return (
      new Array(width + (/\./.test(number.toString()) ? 2 : 1)).join('0') +
      number.toString()
    );
  }
  return number.toString();
}

export function isNumeric(number: any) {
  return (
    (typeof number === 'number' || typeof number === 'string') &&
    !isNaN(<any>number - parseFloat(<any>number))
  );
}

/**
 * Random number from min to max both inclusive.
 */
export function randomIn(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns an array of n numbers in he given range without duplicates.
 * Both min and max are inclusive.
 */
export function nRandomsInRange(n: number, min: number, max: number): Array<number> {
  if (n === 1) {
    return [randomIn(min, max)];
  }

  const seed = shuffleArray(range(min, max));

  return seed.slice(0, n);
}

export function nRandomsInRangeMoreEntrophy(
  n: number,
  min: number,
  max: number,
): Array<number> {
  if (n === 1) {
    return [randomIn(min, max)];
  }

  const seed = shuffleArray(range(min, max));
  const array = new Array<number>(n).fill(undefined);
  array.forEach((element, index) => {
    let randomIndex = randomIn(0, seed.length - 1);
    while (array.includes(randomIndex)) {
      randomIndex = randomIn(0, seed.length - 1);
    }
    array[index] = randomIndex;
  });
  return array.map(index => seed[index]);
}

/**
 * Returns an range of integers from start to end both inclusive.
 */
export function range(start: number, end: number): Array<number> {
  return arrayOfLength(end - start + 1).map((_, index) => index + start);
}

export function roundDecimals(n: number, decimals = 1) {
  let order = Math.pow(10, decimals);
  return Math.round(n * order) / order;
}

export function floorDecimals(n: number, decimals = 1) {
  const order = Math.pow(10, decimals);
  return Math.floor(n * order) / order;
}

/**
 * Returns the max divisor of a given number, excluding the number itself.
 */
export function maxDivisor(number: number) {
  let divisor = Math.floor(number / 2);
  while (mod(number, divisor) !== 0) {
    divisor--;
  }
  return divisor;
}

/**
 * Private functions that it cannot be imported to avoid circular deps
 */

function arrayOfLength(length: number, initialValue: any = 0) {
  let newArray = Array(length);

  if (typeof initialValue === 'function') {
    newArray = newArray.fill(null).map(() => initialValue());
  } else {
    newArray.fill(initialValue);
  }
  return newArray;
}

function shuffleArray<T>(array: Array<T>): Array<T> {
  for (let i = array.length - 1; i > 1; i--) {
    const rnd = randomIn(0, i);
    let tmp = array[rnd];
    array[rnd] = array[i];
    array[i] = tmp;
  }
  return array;
}
