import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {filter, first, map, mergeMap} from 'rxjs/operators';

import {TranslationsService} from '../i18n/translations.service';
import {Logger} from '../logger/logger';
import {ActivatedRouteService} from '../router/activated-route.service';
import {deepClone} from '../util/clone/clone';

@Injectable({providedIn: 'root'})
export class MetaRouterService {
  addedMetas = new Set<string>();

  constructor(
    private activatedRouteService: ActivatedRouteService,
    private logger: Logger,
    private title: Title,
    private translationsService: TranslationsService,
    private meta: Meta,
  ) {
    this.activatedRouteService.route
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        map(data => deepClone(data)),
      )
      .subscribe((data: any) => {
        if (data.hasOwnProperty('meta') && typeof data.meta === 'object') {
          if (data.meta.hasOwnProperty('title')) {
            switch (typeof data.meta.title) {
              case 'string':
                this.title.setTitle(data.meta.title);
                break;

              case 'object':
                if (data.meta.title.translationKey) {
                  if (!data.meta.title.translationData) {
                    this.translationsService
                      .getTranslation(data.meta.title.translationKey)
                      .pipe(first())
                      .subscribe(text => this.title.setTitle(text));
                  } else {
                    this.translationsService
                      .getCompiledTranslation(
                        data.meta.title.translationKey,
                        data.meta.title.translationData,
                      )
                      .pipe(first())
                      .subscribe(text => this.title.setTitle(text));
                  }
                } else {
                  this.translationsService
                    .getTranslation('global.appName')
                    .pipe(first())
                    .subscribe(text => this.title.setTitle(text));
                  this.logger.warn(
                    `MetaRouterService: title is 'object' but has no` +
                      `translation key: ${data.meta.title}`,
                  );
                }

                break;

              default:
                this.logger.warn(
                  `MetaRouterService: title is not 'object' or 'string':` +
                    JSON.stringify(data.meta.title),
                );
                this.translationsService
                  .getTranslation('global.appName')
                  .pipe(first())
                  .subscribe(text => this.title.setTitle(text));
            }

            delete data.meta['title'];
          }

          this.setMetas(data.meta);
        }
      });
  }

  private setMetas(metas: any): void {
    const newKeys = new Set<string>(Object.keys(metas));
    const metasDelete = Array.from(this.addedMetas.keys()).filter(
      key => !newKeys.has(key),
    );
    metasDelete.forEach(v => this.meta.removeTag('name="' + v + '"'));

    this.addedMetas = newKeys;
    this.addedMetas.forEach(meta =>
      this.meta.updateTag({name: meta, content: metas[meta]}),
    );
  }
}
