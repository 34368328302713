import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Group} from './group';

@Injectable({providedIn: 'root'})
export class GroupsService extends AbstractObservableDataService<Array<Group>> {
  constructor() {
    super();
    this.setData([]);
  }

  getNumUnreadGroups(): Observable<number> {
    return this.getData().pipe(
      map(groups =>
        groups.reduce(
          (total, group) => total + (group.numUnreadMessages ? 1 : 0),
          0,
        ),
      ),
    );
  }
}
