import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tl-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TabsComponent {
  @Input()
  selectedTabIndex: number;

  @Input()
  tabs: Array<string>;

  @Output()
  tabChange = new EventEmitter<number>();

  @HostBinding('class.tl-tabs')
  readonly hostClass = true;
}
