// import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PaginationData} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {adaptTicket} from '../patch/info.patch';
import {GenericTicket} from '../tickets/data/generic-ticket';

import {GamesDao} from './games.dao';

// TODO · ADAPTER · remove
@Injectable()
export class GamesDaoAdapter extends GamesDao {
  getTicket(ticketId: number): Observable<GenericTicket> {
    return this.http.get(this.baseUrl + '/users/boletos/' + ticketId).pipe(
      map(res => adaptTicket(res)),
      map(res => GenericTicket.createFromBackend(res)),
    );
  }

  moreTickets(
    index: number,
    filter?: Array<string>,
  ): Observable<PaginationData<GenericTicket>> {
    let filterString =
      filter && filter.length > 0
        ? '/' +
          filter
            .reduce((a, f) => a.concat(f) + '+', '')
            .replace(/\+$/g, '')
            .toLowerCase()
        : '';

    return this.http
      .get(
        this.baseUrl + '/users/boletos/me' + filterString + '?firstResult=' + index,
      )
      .pipe(
        map((obj: any) => {
          let tickets: Array<GenericTicket> = obj.boletos
            .map(ticket => adaptTicket(ticket))
            .map(ticket => GenericTicket.createFromBackend(ticket));

          return new PaginationData<GenericTicket>(tickets, obj.total);
        }),
      );
  }
}
