import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {differenceInYears, isDate, isValid as isValidDate} from 'date-fns';
import {Observable, of, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {isNumeric} from '../../util/core/number';
import {Country} from '../../countries/country';
import {ValidatableCode} from './validatable-code';
import {ValidationResult} from './validation-result';
import {CountryCode, NumberType, parsePhoneNumber} from 'libphonenumber-js/max';

export class NgValidator {
  static passwordExpressions = {
    invalidChar: () =>
      new RegExp(/[^a-zA-ZñÑ\d!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]/, 'g'),
    digit: () => new RegExp(/\d+/, 'g'),
    upperCase: () => new RegExp(/[A-ZÑ]+/, 'g'),
    lowerCase: () => new RegExp(/[a-zñ]+/, 'g'),
    symbol: () => new RegExp(/[!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]+/, 'g'),
  };

  static composeOr(
    validators: (ValidatorFn | null | undefined)[],
  ): ValidatorFn | null {
    return (c: AbstractControl) =>
      validators.reduce((resultTotal, validator) => {
        const result = validator(c);
        return result === null
          ? null
          : {
              ...result,
              ...resultTotal,
            };
      }, null);
  }

  static min(min: number): ValidatorFn {
    return (c: AbstractControl) => (c.value < min ? {min: true} : null);
  }

  static dni(c: AbstractControl): ValidationResult {
    let validChars = 'TRWAGMYFPDXBNJZSQVHLCKE';
    let nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
    let nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;

    if (c.value === null || c.value === undefined || c.value === '') {
      return null;
    }

    let str = c.value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      return {format: true};
    }

    let nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');

    let letter = str.substr(-1);
    let charIndex = parseInt(nie.substr(0, 8), 10) % 23;

    if (validChars.charAt(charIndex) === letter) {
      return null;
    }

    return {format: true};
  }

  static email(control: AbstractControl): ValidationResult {
    let EMAIL_REGEXP = new RegExp(
      '^' + // Inicio
        // Minimo 1 cosa
        "[a-z0-9!#$%&'*+/=?^_`{|}~.-]+" +
        // Separador
        '@' +
        // Minimo 1 cosa (permitiendo - pero sin que empiece o acabe)
        // '[a-z0-9]([a-z0-9-]*[a-z0-9])?' +
        '[a-z0-9]+' +
        // Varios .algo (permitiendo - pero sin que empiece o acabe)
        '(\\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*' +
        // Final
        '$',
      'i',
    );

    if (!EMAIL_REGEXP.test(control.value)) {
      return {format: true};
    }
    return null;
  }

  static emailOrPhone(control: AbstractControl): ValidationResult {
    if (!control.value) {
      return null;
    }

    const isMail = NgValidator.email(control) === null;

    if (isMail) {
      return null;
    }

    if (new RegExp(/^\d{9,}$/).test(control.value)) {
      return null;
    } else {
      return {emailOrPhone: true};
    }
  }

  static password(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const invalids = control.value.match(
        NgValidator.passwordExpressions.invalidChar(),
      );
      if (invalids) {
        return {
          password: {
            invalidChar: invalids
              .filter(
                (character, index) => invalids.indexOf(character) === index, // Remove duplicates
              )
              .map((character: string) => (character === ' ' ? 'ESPACE' : character))
              .join(''),
          },
        };
      }

      if (!NgValidator.passwordExpressions.digit().test(control.value)) {
        return {password: {numberNeeded: control.value}};
      }

      if (!NgValidator.passwordExpressions.upperCase().test(control.value)) {
        return {password: {uppercaseNeeded: control.value}};
      }

      if (!NgValidator.passwordExpressions.lowerCase().test(control.value)) {
        return {password: {lowercaseNeeded: control.value}};
      }

      if (!NgValidator.passwordExpressions.symbol().test(control.value)) {
        return {password: {symbolNeeded: control.value}};
      }

      return null;
    };
  }

  static passwordCheckAll(allowEmpty?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors = {};
      if (allowEmpty && !control.value) {
        return null;
      }

      if (!NgValidator.passwordExpressions.digit().test(control.value)) {
        errors['numberNeeded'] = true;
      }

      if (!NgValidator.passwordExpressions.upperCase().test(control.value)) {
        errors['uppercaseNeeded'] = true;
      }

      if (!NgValidator.passwordExpressions.lowerCase().test(control.value)) {
        errors['lowercaseNeeded'] = true;
      }

      if (!NgValidator.passwordExpressions.symbol().test(control.value)) {
        errors['symbolNeeded'] = true;
      }

      if (control.value?.length < 8) {
        errors['length'] = true;
      }

      return Object.keys(errors).length ? {password: errors} : null;
    };
  }

  static passwordFieldContent(
    fieldPath: string,
    fieldContentPaths: Array<string>,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(fieldPath);

      if (!password?.value) {
        return null;
      }

      return fieldContentPaths.reduce(
        (valid: ValidationErrors | null, fieldContentPath: string) => {
          const fieldContent = control.get(fieldContentPath);

          if (!!fieldContent.value) {
            const fieldRegexp = new RegExp(fieldContent.value, 'i');

            if (fieldRegexp.test(password.value)) {
              const fieldName = fieldPath.split('.').reverse()[0]; // Get last
              const fieldContentName = fieldContentPath.split('.').reverse()[0]; // Get last

              return {
                [fieldName]: {
                  password: {
                    [`${fieldContentName}Detected`]: password.value,
                  },
                },
              };
            }
          }
          return valid;
        },
        null,
      );
    };
  }

  static passwordContent(
    fieldPath: string,
    fieldContents: {[fieldName: string]: string},
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(fieldPath);

      if (!password?.value) {
        return null;
      }

      return Object.keys(fieldContents).reduce(
        (valid: ValidationErrors | null, fieldContentName: string) => {
          const value = fieldContents[fieldContentName];
          if (!!value) {
            const fieldRegexp = new RegExp(value, 'i');

            if (fieldRegexp.test(password.value)) {
              const fieldName = fieldPath.split('.').reverse()[0]; // Get last
              return {
                [fieldName]: {
                  password: {
                    [`${fieldContentName}Detected`]: password.value,
                  },
                },
              };
            }
          }
          return valid;
        },
        null,
      );
    };
  }

  static requiredConditionalField(
    path: string,
    conditionalPath: string,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const field = control.get(path);
      const validation = Validators.required(field);

      const conditionalField = control.get(conditionalPath);

      if (!!conditionalField.value && validation) {
        const name = path.split('.').reverse()[0]; // Get last
        return {
          [name]: validation,
        };
      }
      return null;
    };
  }

  static alphanumeric(control: AbstractControl): ValidationResult {
    let ALPHANUMERIC_PATTERN = new RegExp(/^[a-z0-9ñÑ]*$/, 'i');

    if (!ALPHANUMERIC_PATTERN.test(control.value)) {
      return {format: true};
    }

    return null;
  }

  static alphabeticWithSpaces(control: AbstractControl): ValidationResult {
    let ALPHABETIC_PATTERN = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s]*$/);

    if (!ALPHABETIC_PATTERN.test(control.value)) {
      return {format: true};
    }

    return null;
  }

  static nameAndSurname(control: AbstractControl): ValidationResult {
    let ALPHABETIC_PATTERN = new RegExp(/^[a-zA-Z\-\u00C0-\u00FF-\u0027\s]*$/);

    if (!ALPHABETIC_PATTERN.test(control.value)) {
      return {format: true};
    }

    return null;
  }

  static surname(control: AbstractControl): ValidationResult {
    let ALPHABETIC_PATTERN = new RegExp(/^[a-zA-Z\-\u00C0-\u00FF\s]*$/);

    if (!ALPHABETIC_PATTERN.test(control.value)) {
      return {format: true};
    }

    return null;
  }

  static age(age: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (isDate(control.value)) {
        if (!isValidDate(control.value)) {
          return {invalid: 'invalid'};
        }

        if (differenceInYears(Date.now(), control.value) >= age) {
          return null;
        } else {
          return {min: 18};
        }
      }

      return {invalid: 'invalid'};
    };
  }

  static creditCard(control: AbstractControl): ValidationErrors | null {
    let nCheck = 0;
    let nDigit = 0;
    let bEven = false;

    if (!control.value) {
      return {creditCard: {actual: control.value}};
    }

    let value = control.value.toString().replace(/[-\s]/g, '');

    if (value.length < 13) {
      return {creditCard: {actual: control.value}};
    }

    // The Luhn Algorithm
    for (let n = value.length - 1; n >= 0; n--) {
      let cDigit = value.charAt(n);
      nDigit = Number.parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) {
          nDigit -= 9;
        }
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0 ? null : {creditCard: {actual: control.value}};
  }

  static expiryDate(control: AbstractControl): ValidationErrors | null {
    if (
      control.value === null ||
      control.value === undefined ||
      control.value === ''
    ) {
      return null;
    }

    let chunks = control.value.toString().split('/');

    if (chunks.length !== 2) {
      return {expiryDate: {actual: control.value}};
    }

    let currentYear = Number.parseInt(
      new Date().getFullYear().toString().substr(2),
      10,
    );
    let month = Number.parseInt(chunks[0], 10);
    let year = Number.parseInt(chunks[1], 10);

    return isNumeric(month) &&
      month > 0 &&
      month <= 12 &&
      isNumeric(year) &&
      year >= currentYear
      ? null
      : {expiryDate: {actual: control.value}};
  }

  static atLeastOneTrue(control: AbstractControl): ValidationErrors | null {
    if (!(control instanceof FormArray)) {
      return null;
    }

    if (control.value.length === 0) {
      return {atLeastOneTrue: {minlength: 1, actualLength: 0}};
    }

    return control.value.some(value => value) ? null : {atLeastOneTrue: false};
  }

  static validCode(dao: ValidatableCode, code?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      timer(1200).pipe(
        switchMap(() => {
          let obsReturn: Observable<ValidationErrors | null>;
          if (!control.value) {
            obsReturn = of({invalid: 'invalid'});
          } else if (control.value.toString().toLowerCase() === code) {
            obsReturn = of(null);
          } else {
            obsReturn = dao
              .validCode(control.value)
              .pipe(map(isValid => (isValid ? null : {invalid: 'invalid'})));
          }
          return obsReturn;
        }),
      );
  }

  static phoneFormat(
    countryControl: AbstractControl,
    skipNumbers: Array<string> = [],
    acceptLandline: boolean = true,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!!skipNumbers && skipNumbers.indexOf(value) > -1) {
        return null;
      }

      if (!value?.length) {
        return null;
      }

      const error = {phoneFormat: true};

      // get country code
      const country = countryControl.value as Country;
      const countryCode = country?.code?.toUpperCase() as CountryCode;

      if (!countryCode) {
        return error;
      }

      // get accepted types
      // Bizum test 700000000 PERSONAL_NUMBER
      const acceptedTypes: NumberType[] = acceptLandline
        ? ['MOBILE', 'FIXED_LINE', 'FIXED_LINE_OR_MOBILE']
        : ['MOBILE', 'FIXED_LINE_OR_MOBILE'];

      // validate
      try {
        const phoneNumber = parsePhoneNumber(value, countryCode);
        const isValidNumber =
          phoneNumber.isValid() && acceptedTypes.includes(phoneNumber.getType());

        return isValidNumber ? null : error;
      } catch {
        return error;
      }
    };
  }

  static postalCodeValidator(pattern: string): ValidatorFn | null {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (Validators.required(control) !== null || !control.value) {
        return null;
      }

      const alphanumeric = control.value;

      const regex = new RegExp(
        `^${pattern.replace(/[A0]/g, match =>
          match === 'A' ? '[A-Za-z]' : '\\d',
        )}$`,
      );

      if (!regex.test(alphanumeric)) {
        return {invalidPostalCode: true};
      }

      return null;
    };
  }

  static nifRequired(c: AbstractControl): ValidationResult {
    if (
      c.value === null ||
      typeof c.value !== 'object' ||
      !c.value.hasOwnProperty('type') ||
      !c.value.hasOwnProperty('value') ||
      (c.value.hasOwnProperty('type') &&
        c.value.hasOwnProperty('value') &&
        (!c.value.type || !c.value.value))
    ) {
      return {required: true};
    }
    return null;
  }

  static nifMinLength(
    c: AbstractControl,
    minLengthCondition: (value: any) => boolean,
  ): ValidationResult {
    if (
      c.value !== null &&
      c.value.hasOwnProperty('type') &&
      c.value.hasOwnProperty('value') &&
      c.value.type &&
      c.value.value
    ) {
      if (minLengthCondition(c.value)) {
        return {minlength: true};
      }
    }
    return null;
  }

  static nifMinLengthPT(c: AbstractControl): ValidationResult {
    return NgValidator.nifMaxLength(
      c,
      value =>
        (value.type === 'NATIONAL' && value.value.length < 9) ||
        (value.type === 'FOREIGN' && value.value.length < 5),
    );
  }
  static nifMaxLength(
    c: AbstractControl,
    maxLengthCondition: (value: any) => boolean,
  ): ValidationResult {
    if (
      c.value !== null &&
      c.value.hasOwnProperty('type') &&
      c.value.hasOwnProperty('value') &&
      c.value.type &&
      c.value.value
    ) {
      if (maxLengthCondition(c.value)) {
        return {maxlength: true};
      }
    }
    return null;
  }

  static nifMaxLengthPT(c: AbstractControl): ValidationResult {
    return NgValidator.nifMaxLength(
      c,
      value =>
        (value.type === 'NATIONAL' && value.value.length > 9) ||
        (value.type === 'FOREIGN' && value.value.length > 20),
    );
  }
}
