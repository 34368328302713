import {Inject, Injectable, Injector} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';

import {Logger} from '../logger/logger';
import {ResponsiveService} from '../responsive/responsive.service';

import {UriError} from './uri-error';
import {UriMapResolver} from './uri-map-resolver';
import {URI_MAP_CONFIG, URI_MAP_PREFIX} from './uri-mapper.token';
import {UriConfig, UriMap} from './uri-types';
import {UriUtils} from './uri-utils';

@Injectable({providedIn: 'root'})
export class UriMapper {
  private config: {[key: string]: Array<UriMap>};

  constructor(
    private logger: Logger,
    private responsiveService: ResponsiveService,
    private injector: Injector,
    @Inject(URI_MAP_PREFIX) private prefix: string,
    @Inject(URI_MAP_CONFIG) private uriConfig: Array<UriConfig>,
  ) {
    this.config = this.uriConfig
      .flat()
      .reduce((flat, item) => Object.assign(item, flat), {});
  }

  isInternalRoute(url: string): boolean {
    return url.startsWith(this.prefix + '://');
  }

  map(url: string): Observable<string> {
    if (!url) {
      return throwError(() => new UriError('No uri provided', ''));
    }

    if (!url.startsWith(this.prefix + ':')) {
      this.logger.warn(`Malformed ${this.prefix} URI: ${url}`);
      return throwError(
        () => new UriError(`Malformed ${this.prefix} URI: ${url}`, url),
      );
    }

    const segments = url.substring(11).split('/');

    const uriMapFound = this.config[segments[0]]?.find(
      uriMap => !!UriUtils.urlMatches(uriMap.pattern, uriMap.originUrl, url),
    );

    if (uriMapFound) {
      if (uriMapFound.resolver) {
        const resolver = this.injector.get<UriMapResolver>(uriMapFound.resolver);
        return resolver.resolve(uriMapFound, url);
      } else {
        return of(
          UriUtils.getDestinationUrl(
            uriMapFound,
            this.responsiveService.getPlatform(),
          ),
        );
      }
    } else {
      this.logger.warn(`Unkown ${this.prefix} URI: ${url}`);
      return throwError(
        () => new UriError(`Unkown ${this.prefix} URI: ${url}`, url),
      );
    }
  }
}
