import {Inject, Injectable} from '@angular/core';

import {CONSOLE} from './config-tokens';
import {Logger} from './logger';

@Injectable()
export class ConsoleLoggerService implements Logger {
  constructor(@Inject(CONSOLE) private console: any) {}

  info(message: string, context?: Record<string, unknown>) {
    this.console.info(message, context ? context : '');
  }

  warn(message: string, context?: Record<string, unknown>) {
    this.console.warn(message, context ? context : '');
  }

  error(message: string, stacktrace?: string, context?: Record<string, unknown>) {
    if (!context) {
      context = {};
    }
    context['stacktrace'] = stacktrace;
    this.console.error(message, context ? context : '');
  }

  debug(message: string, context?: Record<string, unknown>) {
    this.console.log(message, context ? context : '');
  }
}
