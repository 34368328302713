/**
 * Avoid implementing ngOnDestroy hook to unsubscribe all component
 * subscriptions
 *
 * @example @Destroyable() destroy = new Subject();
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Destroyable() {
  return (target: any, propertyKey: string) => {
    const originalOnDestroy = target.constructor.prototype.ngOnDestroy;

    target.constructor.prototype.ngOnDestroy = function () {
      if (
        typeof this[propertyKey].next === 'function' &&
        typeof this[propertyKey].complete === 'function'
      ) {
        this[propertyKey].next();
        this[propertyKey].complete();
      }

      if (typeof originalOnDestroy === 'function') {
        originalOnDestroy.apply(this, arguments);
      }
    };
  };
}
