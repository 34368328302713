import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ClickOutsideDirective} from './click-outside.directive';
import {ToggleableElementDirective} from './toggleable-element.directive';

@NgModule({
  imports: [CommonModule],
  exports: [ClickOutsideDirective, ToggleableElementDirective],
  declarations: [ClickOutsideDirective, ToggleableElementDirective],
})
export class ClickEventsModule {}
