import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tl-info-basic',
  templateUrl: './info.component.html',
  styleUrls: ['./info-button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  @Input()
  icon: 'info' | 'question' = 'question';

  @Output()
  openInfo = new EventEmitter<any>();

  @HostBinding('class.tl-info')
  readonly hostClass = true;

  @HostListener('click')
  onClick() {
    this.openInfo.emit();
  }
}
