import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {UtilModule} from '../util/util-module';

import {SlideComponent} from './slide.component';
import {SlidesComponent} from './slides.component';

@NgModule({
  imports: [CommonModule, UtilModule],
  exports: [SlideComponent, SlidesComponent],
  declarations: [SlideComponent, SlidesComponent],
})
export class SlidesModule {}
