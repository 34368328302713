<span class="tl-number-unit__digit tl-number-unit__digit--unit">
  {{ unit && prefixUnit ? unit : '' }}{{ number }}
</span>

<span *ngIf="!integer" class="tl-number-unit__digit tl-number-unit__digit--decimal">
  {{ decimalSeparator }}{{ decimal }} {{ unit && !prefixUnit ? unit : '' }}
</span>

<span *ngIf="description" class="tl-number-unit__description">
  {{ description }}
</span>
