import {Inject, Pipe, PipeTransform} from '@angular/core';
import {ENVIRONMENT} from '../../environment/environment-token';
import {CountryCode, parsePhoneNumber} from 'libphonenumber-js/max';
import {fixPhoneNumber} from './phone';

@Pipe({name: 'phoneFormat'})
export class PhoneFormatPipe implements PipeTransform {
  private defaultCountryCode: CountryCode;

  constructor(@Inject(ENVIRONMENT) protected environment: Record<string, any>) {
    const localeCountryCode = environment.locale.user.defaultPhoneCode;
    this.defaultCountryCode = localeCountryCode.toUpperCase();
  }

  transform(
    value: string,
    defaultCountryCode: string = this.defaultCountryCode,
  ): string {
    if (!value?.length) {
      return value;
    }

    try {
      const code = defaultCountryCode.toUpperCase() as CountryCode;
      const valueFixed = fixPhoneNumber(value, code);

      const phoneNumber = parsePhoneNumber(valueFixed, code);

      if (phoneNumber.isValid()) {
        if (phoneNumber.country === code) {
          return phoneNumber.formatNational();
        } else {
          return phoneNumber.formatInternational();
        }
      } else {
        return value;
      }
    } catch {
      return value;
    }
  }
}
