import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  getDate,
  getDaysInMonth,
  getMonth,
  getYear,
  isDate,
  isExists,
} from 'date-fns';

import {AbstractNgModel} from '../../forms/abstract-ngmodel';
import {ngModelProvider} from '../../model/ng-model-config';
import {isNumeric, range} from '../../util/core/number';
import {ENVIRONMENT} from '../../environment/environment-token';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-datefield',
  templateUrl: './datefield.component.html',
  styleUrls: ['./datefield.component.scss'],
  providers: [ngModelProvider(DateFieldComponent)],
})
export class DateFieldComponent
  extends AbstractNgModel<Date>
  implements OnInit, OnChanges
{
  @Input('value')
  model: Date;

  @Input()
  startDate: Date;

  @Input()
  endDate: Date;

  day: string;

  month: string;

  year: string;

  displayDays: Array<number>;

  displayMonths: Array<number>;

  displayYears: Array<number>;

  formatClass = 'DD-MM-YYYY';

  @Input()
  showDays = true;

  @Input()
  showMonths = true;

  @Input()
  showYears = true;

  @Input()
  blurOnChanges = false;

  @ViewChildren('select') selects: QueryList<ElementRef>;

  constructor(@Inject(ENVIRONMENT) private environment: Record<string, any>) {
    super();
    this.formatClass = this.environment.locale.dateFormats.format
      .replaceAll('/', '-')
      .toUpperCase();
  }

  ngOnInit() {
    if (isDate(this.model)) {
      this.splitModel();
    } else {
      this.updatePicker();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('model')) {
      this.splitModel();
    }
  }

  writeValue(obj: any) {
    if (isDate(obj)) {
      this.model = obj;
      this.splitModel();
    }
  }

  onChange() {
    // Month isNumeric because it can be Zero
    if (this.day && isNumeric(this.month) && this.year) {
      this.model = isExists(+this.year, +this.month, +this.day)
        ? new Date(+this.year, +this.month, +this.day)
        : null;
      this.modelChange(this.model);
      this.updatePicker();
      if (this.blurOnChanges) {
        this.selects.forEach((select: ElementRef) => select.nativeElement.blur());
      }
    }
  }

  updatePicker(): void {
    let endYear = getYear(Date.now());
    let startYear = endYear - 100;

    let startMonth = 1;
    let endMonth = 12;

    let startDay = 1;
    let endDay = getDaysInMonth(
      new Date(+this.year || endYear, isNumeric(+this.month) ? +this.month : 0),
    );

    if (this.startDate) {
      startYear = getYear(this.startDate);
      startMonth =
        +this.year === getYear(this.startDate)
          ? getMonth(this.startDate) + 1
          : startMonth;
      startDay =
        +startMonth === getMonth(this.startDate) + 1 &&
        +startYear === getYear(this.startDate)
          ? getDate(this.startDate)
          : startDay;
    }

    if (this.endDate) {
      endYear = getYear(this.endDate);
      endMonth =
        +this.year === getYear(this.endDate) ? getMonth(this.endDate) + 1 : endMonth;
      endDay =
        +this.month === getMonth(this.endDate) &&
        +this.year === getYear(this.endDate)
          ? getDate(this.endDate)
          : endDay;
    }

    this.displayDays = range(startDay, endDay);
    this.displayMonths = range(startMonth, endMonth);
    this.displayYears = range(startYear, endYear).reverse();
  }

  private splitModel() {
    this.year = getYear(this.model).toString();
    this.month = getMonth(this.model).toString();
    this.day = getDate(this.model).toString();

    this.updatePicker();
  }
}
