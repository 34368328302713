import {Comparable} from 'common';
import {isFuture, isPast} from 'date-fns';

import {UpcomingGame} from '../../upcoming/data/upcoming.game';
import {environment} from '~environments/environment';

export class SelectableRaffle implements Comparable<SelectableRaffle> {
  constructor(
    public id: any,
    public alias: string,
    public time: number,
    public openDate: number,
    public closeDate: number,
    public price: number,
    public version: number,
    public promos: Array<string> = [],
    public disabled = false,
  ) {}

  static createFromUpcomingGame(game: UpcomingGame) {
    return new SelectableRaffle(
      game.raffleId,
      game.alias || game.name,
      game.raffleDate,
      game.openDate,
      game.closeDate,
      game.price,
      game.gameVersion,
      game.promos,
    );
  }

  isCelebrated(): boolean {
    return isPast(this.time);
  }

  isClosed(): boolean {
    return isPast(this.closeDate);
  }

  isAfterOpenning(): boolean {
    return !!this.openDate ? isPast(this.openDate) : true;
  }

  isAvailable(): boolean {
    return !this.isClosed() && this.isAfterOpenning();
  }

  compareTo(o: SelectableRaffle): number {
    return this.time - o.time;
  }

  isBookingMode(): boolean {
    if (!environment.features.reserveMode) return false;
    return !!this.openDate ? isFuture(this.openDate) : false;
  }
}
