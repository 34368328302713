import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SanitizerModule} from '../../sanitizer/sanitizer.module';
import {I18nModule} from '../i18n.module';

import {TranslatableTextComponent} from './translatable-text.component';

@NgModule({
  imports: [CommonModule, I18nModule, SanitizerModule],
  exports: [TranslatableTextComponent],
  declarations: [TranslatableTextComponent],
})
export class TranslatableTextModule {}
