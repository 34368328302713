import {Component, Input} from '@angular/core';

import {SessionService} from '../../../user/auth/session.service';
import {LotteryBoothItemBaseComponent} from '../../lottery-booth-item-base.component';
import {ResponsiveService} from 'common';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-booth-favourite-item',
  templateUrl: './booth-favourite-item.component.html',
  styleUrls: ['./booth-favourite-item.component.scss'],
})
export class BoothFavouriteItemComponent extends LotteryBoothItemBaseComponent {
  @Input()
  isLoading = false;

  constructor(sessionService: SessionService, responsiveService: ResponsiveService) {
    super(sessionService, responsiveService);
  }
}
