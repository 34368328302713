import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {I18nModule, SanitizerModule} from 'common';

import {CreateCompanyClubHelpContentComponent} from './create-company-club-help-content.component';

@NgModule({
  imports: [CommonModule, I18nModule, SanitizerModule],
  exports: [CreateCompanyClubHelpContentComponent],
  declarations: [CreateCompanyClubHelpContentComponent],
})
export class CreateCompanyClubHelpContentModule {}
