import {isNumeric, padding} from 'common';

import {AbstractGameTypeMetadata} from '../../game-metadata/data/abstract-game-type-metadata';
import {AlphanumericGameTypeMetadata} from '../../game-metadata/data/alphanumeric-game-type-metadata';
import {BooleanGameTypeMetadata} from '../../game-metadata/data/boolean-game-type-metadata';
import {DigitsGameTypeMetadata} from '../../game-metadata/data/digits-game-type-metadata';
import {NumbersGameTypeMetadata} from '../../game-metadata/data/numbers-game-type-metadata';
import {SelectionGameTypeMetadata} from '../../game-metadata/data/selection-game-type-metadata';

import {CombinationValue} from './combination-value';

export function getFormattedValue(
  value: CombinationValue | Array<CombinationValue>,
  gameTypeMetadata: AbstractGameTypeMetadata,
): string {
  if (gameTypeMetadata.uiMetadata?.rawNumber) {
    return Array.isArray(value) ? value.toString() : value.value;
  }

  if (gameTypeMetadata instanceof AlphanumericGameTypeMetadata) {
    const chunks = (<CombinationValue>value).value.split(' ');
    return chunks.length > 2
      ? `${chunks[0]}, ${chunks[1]}...`
      : (<CombinationValue>value).value;
  } else if (gameTypeMetadata instanceof BooleanGameTypeMetadata) {
    // When the game has 3 states, we return undefined as false.
    return ((<CombinationValue>value).value ?? false).toString();
  } else if (
    gameTypeMetadata instanceof DigitsGameTypeMetadata ||
    gameTypeMetadata instanceof NumbersGameTypeMetadata
  ) {
    const number = Number.parseInt((<CombinationValue>value).value, 10);

    return !isNumeric(number)
      ? (<CombinationValue>value).value
      : padding(number, ('' + gameTypeMetadata.max).length);
  } else if (gameTypeMetadata instanceof SelectionGameTypeMetadata) {
    const choices = (<Array<CombinationValue>>value)
      .map((v: CombinationValue) => v.value)
      .reduce((str, v) => str + v, '');

    return choices.length === 0 ? '-' : choices;
  }
}
