import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first, mapTo, switchMap} from 'rxjs/operators';

import {EndpointService} from '../../backend/endpoint/endpoint.service';
import {PrivateInfoService} from '../../backend/private-info/private-info.service';
import {AbstractDao} from '../../common/model/abstract.dao';
import {CardData} from '../data/card-data';
import {KycCardFormGroup} from './kyc-card-form-group';
import {KycSelfieFormGroup} from './kyc-selfie-form-group';

import {KycDao} from './kyc.dao';

@Injectable({providedIn: 'root'})
export class KycService extends AbstractDao {
  constructor(
    private endpointService: EndpointService,
    private kycDao: KycDao,
    private privateInfoService: PrivateInfoService,
  ) {
    super();
  }

  sendKyc(
    data: Array<KycSelfieFormGroup | KycCardFormGroup>,
    kycMethodIndex: number,
  ): Observable<void> {
    return this.endpointService.getData().pipe(
      first(),
      switchMap(endpoint =>
        this.kycDao.sendKyc(
          endpoint.kycMethods[kycMethodIndex].method,
          endpoint.kycMethods[kycMethodIndex].id,
          data,
        ),
      ),
      switchMap(() => this.privateInfoService.update()),
      mapTo(void 0),
    );
  }

  checkCardCode(data: any): Observable<CardData> {
    return this.kycDao.checkCardCode(data);
  }
}
