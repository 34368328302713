import {Directive, Host, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {FormMessagesDirective} from './form-messages.directive';

@Directive({selector: '[tlFormMessage]'})
export class FormMessageDirective implements OnDestroy, OnInit {
  @HostBinding('hidden')
  valid = true;

  @Input('tlFormMessage')
  message: string;

  private subscription: Subscription;

  constructor(@Host() private messages: FormMessagesDirective) {
    this.subscription = messages.update.subscribe(this.validate.bind(this));
  }

  ngOnInit() {
    this.validate();
  }

  validate() {
    this.valid = !this.messages.has(this.message);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
