<div class="d-none" [class.d-block]="showSuccess">
  <p class="input-password-validations__success">
    <i class="icon-check-icon"></i>
    {{ 'authentication.userRegister.validations.allOk' | i18n }}
  </p>
</div>

<div [class.d-none]="showSuccess">
  <p class="input-password-validations__title">
    {{ 'authentication.userRegister.validations.title' | i18n }}
  </p>

  <ul
    class="input-password-validations__messages"
    [tlFormMessages]="control"
    [tlFormMessagesForm]="form"
    [tlFormMessagesFieldName]="messagesFieldName"
    [submitted]="true">
    <li
      *ngFor="let message of messages | keyvalue: sortNull"
      class="input-password-validations__message"
      [tlFormMessageState]="message.key"
      [tlFormMessageStateForceChanged]="submitted">
      <i class="icon-circle-icon"></i>
      <i class="icon-check-icon"></i>
      <i class="icon-pedido-anulado-icon"></i>
      {{ message.value | i18n }}
    </li>
  </ul>
</div>
