import {Serializable} from 'common';

import {GamePrizeType} from './prize-type.enum';

class GamePrizeInternal {
  image: string;

  title: string;

  type: GamePrizeType;

  typeIcon: string;

  typeDescription: string;

  category: number;

  amount: number;

  amountString: string;

  description: string;
}

export class GamePrize extends Serializable(GamePrizeInternal) {
  static createFromBackend(obj: any) {
    let gamePrize = new GamePrize();

    gamePrize.title = obj.title;
    gamePrize.typeDescription = obj.rangeDescription;
    gamePrize.image = obj.urlImagePrize;
    gamePrize.amount = obj.prizeAmount;
    gamePrize.amountString = obj.prizeAmountString;
    gamePrize.category = obj.prizeOrder;
    gamePrize.description = obj.description || '';

    switch (obj.type) {
      case 'CHEQUE':
        gamePrize.type = GamePrizeType.CHEQUE;
        gamePrize.typeIcon = 'assets/img/premio/cheque.svg';
        break;
      case 'SALDO':
        gamePrize.type = GamePrizeType.SALDO;
        gamePrize.typeIcon = 'assets/img/premio/cartera.svg';
        break;
      case 'ITEM':
        gamePrize.type = GamePrizeType.ITEM;
        gamePrize.typeIcon = 'assets/img/premio/copa.svg';
        break;
    }

    return gamePrize;
  }
}
