import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';

import {ngModelProvider} from '../../model/ng-model-config';
import {AbstractNgModel} from '../abstract-ngmodel';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-form-image-uploader',
  templateUrl: './form-image-uploader.component.html',
  styleUrls: ['./form-image-uploader.component.scss'],
  providers: [ngModelProvider(FormImageUploaderComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormImageUploaderComponent extends AbstractNgModel<{image: string}> {
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  writeValue(obj: any): void {
    this.model = obj;
    this.cdr.markForCheck();
  }
}
