import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {ResponsiveService} from '../responsive/responsive.service';

import {UriMapResolver} from './uri-map-resolver';
import {UriMap} from './uri-types';
import {UriUtils} from './uri-utils';
import {Logger} from '../logger/logger';

@Injectable()
export class PropagateParamsUriMapResolver implements UriMapResolver {
  constructor(
    protected responsiveService: ResponsiveService,
    protected logger: Logger,
  ) {}

  resolve(uriMap: UriMap, url: string): Observable<string> {
    const paramNames = UriUtils.getUrlParams(uriMap.originUrl);
    const paramValues = <RegExpExecArray>(
      UriUtils.urlMatches(uriMap.pattern, uriMap.originUrl, url)
    );
    this.logger.error(
      'WEB-988 resolve url with isDesktopCache: ' +
        this.responsiveService.isDesktopCache,
      undefined,
      {uri: uriMap, url: url},
    );
    const destinationUrl = UriUtils.getDestinationUrl(
      uriMap,
      this.responsiveService.getPlatform(),
    );
    this.logger.error(
      'WEB-988 resolve url with replaceParams: ' +
        this.replaceParams(paramNames, paramValues, destinationUrl),
    );
    return of(this.replaceParams(paramNames, paramValues, destinationUrl));
  }

  protected replaceParams(
    paramNames: RegExpMatchArray,
    paramValues: RegExpExecArray,
    destinationUrl: string,
  ): string {
    paramValues
      .slice(1)
      .forEach(
        (match, index) =>
          (destinationUrl = destinationUrl.replace(paramNames[index], match)),
      );

    return destinationUrl;
  }
}
