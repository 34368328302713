import {NgModule} from '@angular/core';
import {EVENT_MANAGER_PLUGINS} from '@angular/platform-browser';
import {PressEventsService, SwipePlugin} from 'common';

@NgModule({
  providers: [
    {
      provide: EVENT_MANAGER_PLUGINS,
      useExisting: PressEventsService,
      multi: true,
    },
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: SwipePlugin,
      multi: true,
    },
  ],
})
export class EventManagerProvidersModule {}
