import {DOCUMENT} from '@angular/common';
import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {animationFrameScheduler} from 'rxjs';
import {auditTime, startWith} from 'rxjs/operators';

import {DeviceService} from '../../device/device.service';
import {ResponsiveService} from '../../responsive/responsive.service';
import {ScrollDirection, ScrollEvent} from '../scroll-event';
import {ScrollIntoElement} from '../scroll-into-element';
import {ScrollableBaseComponent} from '../scrollable-base.component';
import {TooltipService} from '../../tooltip/tooltip.service';

@Component({
  selector: 'tl-scrollable',
  templateUrl: './scrollable.component.html',
  styleUrls: ['./scrollable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ScrollableComponent
  extends ScrollableBaseComponent
  implements
    AfterContentInit,
    AfterContentChecked,
    OnDestroy,
    OnInit,
    ScrollIntoElement
{
  @HostBinding('class.tl-scrollable')
  readonly hostClass = true;

  private scrollEventListener: () => void;

  /**
   * Safe margin to be taken into account when scrolling to avoid element
   * being very close to top/bottom edge
   */
  private readonly ELEMENT_MARGIN = 10;

  constructor(
    protected deviceService: DeviceService,
    @Inject(DOCUMENT) protected document: Document,
    protected elementRef: ElementRef,
    protected ngZone: NgZone,
    protected renderer: Renderer2,
    protected responsiveService: ResponsiveService,
    @Inject('window') private window: Window,
    protected tooltipService: TooltipService,
  ) {
    super(deviceService, document, elementRef, ngZone, renderer, responsiveService);
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(
      () =>
        (this.scrollEventListener = this.renderer.listen(
          this.elementRef.nativeElement,
          'scroll',
          () => {
            this.scrollChangeOutsideAngular.emit(this.buildScrollEvent());
            this.tooltipService.closeAll();
          },
        )),
    );
  }

  ngAfterContentChecked() {
    if (this.shouldAttachRail()) {
      this.updateRail();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (typeof this.scrollEventListener === 'function') {
      this.scrollEventListener();
    }
  }

  attachEvents(): void {
    super.attachEvents();

    this.listeners.push(
      this.scrollChangeOutsideAngular
        .pipe(startWith({}), auditTime(0, animationFrameScheduler))
        .subscribe(() => this.updateRail()),
    );

    this.listeners.push(
      this.rail.scrollTopUpdater
        .pipe(auditTime(0, animationFrameScheduler))
        .subscribe(scrollTop => this.onRailDrag(scrollTop)),
    );
  }

  hasScroll(): boolean {
    return (
      this.elementRef.nativeElement.clientHeight <
      this.elementRef.nativeElement.scrollHeight
    );
  }

  railDragStart() {
    // Avoid iframe take mouse events and breaks the rail drag.
    this.getIframeChildren().forEach(
      (el: HTMLIFrameElement) => (el.style['pointer-events'] = 'none'),
    );
  }

  railDragEnd() {
    // Avoid iframe take mouse events and breaks the rail drag.
    this.getIframeChildren().forEach(
      (el: HTMLIFrameElement) => (el.style['pointer-events'] = 'default'),
    );
  }

  setScrollPosition(scrollTop: number): void {
    if (this.elementRef.nativeElement.scrollTop === scrollTop) {
      return;
    }

    const style = this.window.getComputedStyle(this.elementRef.nativeElement);
    const paddingTop = parseFloat(style.paddingTop);

    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'scrollTop',
      scrollTop - paddingTop,
    );

    this.ngZone.runOutsideAngular(() => {
      this.updateRail();
      this.scrollChangeOutsideAngular.emit(this.buildScrollEvent());
    });
  }

  /**
   * Scrolls to the given position.
   *
   * If the position is below the view (pos > 0), the scroll element height is
   * subtracted, since the positioned element is supposed to be at the very
   * bottom. This behaviour is avoided with forceTop = true
   *
   * @param pos, number of pixels to be scrolled
   * @param forceTop, flag to force the scroll to top
   */
  setScrollPositionFromViewport(pos: number, forceTop = false) {
    let domRect = this.elementRef.nativeElement.getBoundingClientRect();
    let scrollTop = +this.elementRef.nativeElement.scrollTop + pos - domRect.top;

    if (pos > 0 && !forceTop) {
      scrollTop -= domRect.height;
    }

    this.setScrollPosition(scrollTop);
  }

  /**
   * Scrolls the necessary amount of pixels to view the element.
   * ELEMENT_MARGIN pixels are added in order to not have the element closed to
   * the top/bottom
   *
   * It also adds an offset if passed by param:
   *   - if the element is above the view, the offset is added at the top
   *   - if the element is below the view, the offset is added at the bottom
   *
   * @param element to be placed into view
   * @param offset, number of extra margin pixels
   */
  scrollElementIntoView(element: Element, offset = 0) {
    const domRect = element.getBoundingClientRect();
    const domRectScrollable = this.elementRef.nativeElement.getBoundingClientRect();
    const offsetTop = domRect.top + domRect.height + offset;

    if (offsetTop > domRectScrollable.height + domRectScrollable.top) {
      this.setScrollPositionFromViewport(offsetTop + this.ELEMENT_MARGIN);
    }

    const offsetBottom = domRect.top - offset;
    if (offsetBottom < 0) {
      this.setScrollPositionFromViewport(offsetBottom - this.ELEMENT_MARGIN);
    }
  }

  scrollElementToTop(element: Element): void {
    const pos = element.getBoundingClientRect().top - this.ELEMENT_MARGIN;
    this.setScrollPositionFromViewport(pos, true);
  }

  scrollToBottom(): void {
    this.setScrollPosition(this.elementRef.nativeElement.scrollHeight);
  }

  getScrollTop(): number {
    return this.elementRef.nativeElement.scrollTop;
  }

  getScrollPercent(scrollDirection = ScrollDirection.DOWN): number {
    const scrollableHeight =
      this.elementRef.nativeElement.scrollHeight -
      this.elementRef.nativeElement.clientHeight;

    const amountScrolled =
      scrollDirection === ScrollDirection.UP
        ? scrollableHeight - this.getScrollTop()
        : this.getScrollTop();

    return (amountScrolled / scrollableHeight) * 100;
  }

  protected onRailDrag(scrollTop: number): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'scrollTop', scrollTop);
  }

  protected updateRail(): void {
    if (!this.rail) {
      return;
    }

    this.rail.setConstraints(
      this.elementRef.nativeElement.clientHeight,
      this.elementRef.nativeElement.scrollHeight,
    );
    this.rail.setScrollTop(this.elementRef.nativeElement.scrollTop);
  }

  private getIframeChildren() {
    return Array.from(this.elementRef.nativeElement.getElementsByTagName('iframe'));
  }

  private buildScrollEvent(): ScrollEvent {
    let scrollTop = this.getScrollTop();
    let scrollDirection =
      scrollTop < this.lastScrollTop ? ScrollDirection.UP : ScrollDirection.DOWN;
    this.lastScrollTop = scrollTop;
    return {
      scrollTop: scrollTop,
      scrollPercent: this.getScrollPercent(scrollDirection),
      element: this.elementRef,
      scrollDirection: scrollDirection,
    };
  }
}
