import {Serializable} from 'common';

class TransferOptionsInternal {
  displayName: string;

  logoUrl: string;

  virtualAccountEndpoint: string;

  createVirtualAccountEndpoint: string;

  htmlBasicInfo: Array<string>;

  receiver: string;

  minAmount: string;

  concept: string;

  bankName: string;
}

export class TransferOptions extends Serializable(TransferOptionsInternal) {
  public static createFromBackend(obj: any): any {
    let value = new TransferOptions();

    value.displayName = obj.displayName;
    value.logoUrl = obj.logoUrl;
    value.htmlBasicInfo = obj.htmlBasicInfo;
    value.minAmount = obj.nativeInfo.minAmount;
    value.receiver = obj.nativeInfo.receiverName;
    value.concept = obj.nativeInfo.concept;
    value.bankName = obj.nativeInfo.bankName;

    // obj.virtualAccountEndpoint = {
    //   urlPath: '/rest/users/paymentsexternal/account/spei';
    //   method: 'GET';
    // };
    value.virtualAccountEndpoint = obj.virtualAccountEndpoint.urlPath;

    // obj.createVirtualAccountEndpoint: {
    //   urlPath: '/rest/users/paymentsexternal/account/spei';
    //   method: 'POST';
    // };
    value.createVirtualAccountEndpoint = obj.createVirtualAccountEndPoint.urlPath;

    return value;
  }
}
