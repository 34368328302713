import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SlidableModalComponent} from './slidable-modal.component';

@NgModule({
  imports: [RouterModule],
  exports: [SlidableModalComponent],
  declarations: [SlidableModalComponent],
})
export class SlidableModalModule {}
