import {
  Mergeable,
  Serializable,
  SerializableProperty,
  SerializableType,
} from 'common';

import {GroupHistoryEntry} from './group-history-entry';
import {GroupInfoMessage} from './group-info-message';
import {GroupMember} from './group-member';
import {GroupBalanceMode, GroupPrizeMode} from './groups.constants';
import {MoneyActivityGroup} from './money-activity-group';
import {TicketGroup} from './ticket-group';
import {TicketGroupHistoryEntry} from './ticket-group-history-entry';
import {UserGroupHistoryEntry} from './user-group-history-entry';
import {UserGroupStatus} from './user-group-status';
import {GroupMemberMoneyPrizeBox} from './group-member-money-prize-box';
import {mergeMoneyActivitiesGroup, reduceMoneyActivitiesGroup} from './operations';

export function groupHistoryEntryFactory(entry: any): typeof GroupHistoryEntry {
  if (entry.hasOwnProperty('boletoInfo')) {
    return TicketGroupHistoryEntry;
  } else if (entry.hasOwnProperty('memberRequest')) {
    return UserGroupHistoryEntry;
  } else {
    return GroupHistoryEntry;
  }
}

function groupHistoryEntryResolver(entry: any): typeof GroupHistoryEntry {
  if (entry.hasOwnProperty('gameId')) {
    return TicketGroupHistoryEntry;
  } else if (entry.hasOwnProperty('user')) {
    return UserGroupHistoryEntry;
  } else {
    return GroupHistoryEntry;
  }
}

class GroupInternal {
  amountToJoin: number;
  balance: number;
  balanceBlocked: number;
  balanceMode: GroupBalanceMode;
  boothId: string;
  code: string;
  codePublic: boolean;
  creationDate: number;
  description: string;
  id: number;
  image: string;
  lastUpdate: number;
  membersPlaying: number;
  name: string;
  numActiveTickets: number;
  numMembers: number;
  picture: string;
  prizeMode: GroupPrizeMode;
  prizeMoneyBox: GroupPrizeMode;
  totalHistory: number;
  provisionalPrizes: number;
  totalMoneyActivities: number;
  totalPlayedInActiveTickets: number;
  totalTickets: number;
  withdrawableBalance: number;

  @SerializableProperty(groupHistoryEntryResolver, SerializableType.COLLECTION, true)
  history: Array<GroupHistoryEntry>;

  @SerializableProperty(GroupMember)
  members: Array<GroupMember>;

  @SerializableProperty(MoneyActivityGroup)
  moneyActivities: Array<MoneyActivityGroup>;

  @SerializableProperty(TicketGroup)
  tickets: Array<TicketGroup>;

  @SerializableProperty(UserGroupStatus, SerializableType.OBJECT)
  userGroupStatus: UserGroupStatus;

  @SerializableProperty(GroupMember, SerializableType.OBJECT)
  firstMember: GroupMember;

  @SerializableProperty(GroupInfoMessage, SerializableType.OBJECT)
  lastMessage: GroupInfoMessage;

  @SerializableProperty(GroupMemberMoneyPrizeBox, SerializableType.COLLECTION)
  membersMoneyPrizeBox: Array<GroupMemberMoneyPrizeBox>;

  get numUnreadMessages(): number {
    return this.history.filter(
      entry => entry.id > this.userGroupStatus.lastHistoryReadId,
    ).length;
  }

  merge(obj: Group): void {
    for (let property in obj) {
      if (
        obj.hasOwnProperty(property) &&
        (obj[property] || obj[property] === 0) &&
        ((Array.isArray(obj[property]) && obj[property].length) ||
          !Array.isArray(obj[property])) &&
        property !== 'tickets' &&
        property !== 'moneyActivities'
      ) {
        this[property] = obj[property];
      }
    }

    if (obj.moneyActivities) {
      this.moneyActivities = mergeMoneyActivitiesGroup(
        this.moneyActivities,
        obj.moneyActivities,
      );
    }
  }

  reduce(): void {
    this.moneyActivities = reduceMoneyActivitiesGroup(this.moneyActivities);
  }
}

export class Group extends Serializable(GroupInternal) implements Mergeable {
  static createFromBackend(obj: any): Group {
    let g = new Group();

    g.amountToJoin = obj.balanceStatus?.creditRequiredToJoin;
    g.balance = obj.balanceStatus?.balance;
    g.balanceBlocked = obj.balanceStatus?.balanceBlocked;
    g.balanceMode = obj.balanceStatus?.balanceMode;
    g.boothId = obj.admin;
    g.code = obj.code;
    g.codePublic = obj.codePublic;
    g.creationDate = obj.creationDate;
    g.description = obj.mainDesc;
    g.id = obj.id;
    g.image = obj.pictureUrl;
    g.lastUpdate = obj.lastUpdateTimestamp;
    g.membersPlaying = obj.numMembersParticipating;
    g.name = obj.name;
    g.numActiveTickets = obj.balanceStatus.activeBoletos?.length;
    g.numMembers = obj.numMembers;
    g.picture = obj.pictureUrl;
    g.prizeMode =
      obj.prizeMode === GroupPrizeMode.KEEP_MANAGED
        ? GroupPrizeMode.KEEP
        : obj.prizeMode;
    g.prizeMoneyBox = obj.prizeMode === GroupPrizeMode.SHARE ? null : obj.prizeMode;
    g.provisionalPrizes = obj.balanceStatus?.prizesProvisional;
    g.totalMoneyActivities = obj.lastHistoryBalanceEntries?.totalEntries;
    g.totalPlayedInActiveTickets = obj.balanceStatus.totalPlayedInActiveBoletos;
    g.totalTickets = obj.boletosInfo?.total;
    g.withdrawableBalance = obj.balanceStatus?.withdrawableAmount;

    g.history = [];
    if (obj.lastHistoryEntries) {
      g.lastMessage = GroupInfoMessage.createFromBackend(
        obj.lastHistoryEntries.entries[0],
      );

      g.history = obj.lastHistoryEntries.entries.map(e =>
        groupHistoryEntryFactory(e).createFromBackend(e),
      );
      g.totalHistory =
        obj.lastHistoryEntries.entries[0]?.id ?? obj.lastHistoryEntries.totalEntries;
    }

    g.members = [];
    if (obj.members) {
      g.members = obj.members.map(m => GroupMember.createFromBackend(m));
    }

    g.moneyActivities = [];
    if (obj.lastHistoryBalanceEntries) {
      g.moneyActivities = obj.lastHistoryBalanceEntries.entries.map(e =>
        MoneyActivityGroup.createFromBackend(e),
      );
    }

    g.tickets = [];
    if (obj.balanceStatus?.groupBoletos) {
      g.tickets = obj.balanceStatus.groupBoletos.map(t =>
        TicketGroup.createFromBackend(t),
      );
    }

    if (obj.profileInfo) {
      g.userGroupStatus = UserGroupStatus.createFromBackend(obj.profileInfo);
    }

    g.membersMoneyPrizeBox = [];
    if (g.members) {
      g.membersMoneyPrizeBox = g.members.map((member: GroupMember) =>
        GroupMemberMoneyPrizeBox.createFromBackend(
          obj?.groupPrizeInfos?.find(
            (prizeInfo: any) => prizeInfo.clienteId === member.id,
          ),
          member,
        ),
      );
    }

    if (obj.firstMember) {
      g.firstMember = GroupMember.createFromBackend(obj.firstMember);
    }

    return g;
  }
}
