import {
  Serializable,
  SerializableDisableWarning,
  SerializableProperty,
  SerializableType,
} from 'common';

import {BetGroupUIMetadata} from './bet-group-ui-metadata';
import {BetSection} from './bet-section';
import {environment} from '~environments/environment';
import {GameId} from '../../data/game-id';
import {GameMetadata} from './game-metadata';
import {TicketItemSelector} from './ticket-item-selector';

class UIMetadataIntenal {
  color: string;

  layout: string;

  /**
   * The ordering of the number/choice table for mobile
   */
  layoutFlow: string;

  /**
   * The ordering of the number/choice table for desktop
   */
  desktopLayoutFlow: string;

  logoSVG: string;

  ticketLogo: string;

  logoResult: string;

  /**
   * To label panels as A,B,C... instead of 1,2,3...
   */
  alphabeticIndexing: boolean;

  roundedLogo: boolean;

  @SerializableDisableWarning()
  grid: Array<Array<string>>;

  @SerializableDisableWarning()
  gridImages: Array<string>;

  @SerializableProperty(BetGroupUIMetadata, SerializableType.MAP)
  groupUIs: Map<string, BetGroupUIMetadata>;

  showcaseImage: string;

  unitLabel: string;

  fractionLabel: string;

  /**
   * The type for the fraction label. This is used to show plural or singular correctly
   */
  fractionLabelType: 'tickets' | 'fraction';

  closedPlayRafflesStrategy: 'next' | 'reset';

  /**
   * Defines the sections in the modal to select the betType
   */
  @SerializableProperty(BetSection, SerializableType.COLLECTION)
  betSections: Array<BetSection>;

  /**
   * Whether the board index (i.e. '1.' or 'A.') is displayed inside the board
   * alongside the numbers or at the top. When at the top the index is centered,
   * bold and preceded by the label for the play.
   */
  boardIndexOutside: boolean;

  /**
   * Whether to show the manual amount input in the lottery manual page
   */
  showLotteryManualAmount: boolean;

  /**
   * Whether the wheel number selector should be smaller on mobile
   */
  mobileWheelSmallFit: boolean;

  /**
   * Whether to show the booths in the lottery manual page
   */
  showBooths: boolean;

  /**
   * The size class for the tickets on manual search (Desktop)
   */
  ticketsSearchSizeClass: string;

  /**
   * The selector to use for the ticket item
   *
   * - COUNTER: Select amount with a counter
   * - NUMBER: Select fractions with a selector
   */
  ticketItemSelector: TicketItemSelector;

  /**
   * Indicates if it is a lottery game that uses fractions.
   */
  isFractionLottery: boolean;

  /**
   * The URL for the rules of the game
   */
  rulesUrl: string;
}

export class UIMetadata extends Serializable(UIMetadataIntenal) {
  static createFromBackend(obj: any, gameMetadata: GameMetadata) {
    let uiMD = new UIMetadata();

    uiMD.roundedLogo = environment.games?.logos?.rounded;

    uiMD.color = obj.color;
    uiMD.logoSVG = obj.logoNoBorde || obj.logoNoBordeSVG;
    uiMD.ticketLogo = obj.boletoMiniImgUrl || obj.boletoMiniImgSVGUrl;
    uiMD.logoResult = obj.logoResultados || obj.logoResultadosSVG;
    uiMD.layoutFlow = obj.layout || 'columns';
    uiMD.desktopLayoutFlow = obj.desktopLayout || uiMD.layoutFlow;
    uiMD.alphabeticIndexing = obj.alphabeticIndexing;
    uiMD.showcaseImage = obj.playImage;
    uiMD.unitLabel = obj.unitText;
    uiMD.fractionLabel = obj.fractionText;
    uiMD.fractionLabelType = environment.games.lottery.fractionLabelType;

    uiMD.grid = obj.template;
    uiMD.gridImages = obj.templateImages;

    uiMD.boardIndexOutside = !!obj.boardIndexOutside;

    uiMD.closedPlayRafflesStrategy = obj.closedPlayRafflesStrategy || 'next';

    uiMD.rulesUrl = obj.rules;

    if (obj.betSections) {
      uiMD.betSections = obj.betSections.map(section =>
        BetSection.createFromBackend(section),
      );
    }

    uiMD.groupUIs = new Map();

    if (obj.groups) {
      obj.groups.forEach(groupUI =>
        uiMD.groupUIs.set(groupUI.id, BetGroupUIMetadata.createFromBackend(groupUI)),
      );
    }

    uiMD.showLotteryManualAmount =
      gameMetadata.metadataType === 'lottery' &&
      environment.games.lottery.showLotteryManualAmount &&
      gameMetadata.id !== GameId.CRUZ_ROJA;

    uiMD.mobileWheelSmallFit =
      gameMetadata.metadataType === 'lottery' &&
      environment.games.lottery.mobileWheelSmallFit;

    uiMD.showBooths =
      gameMetadata.metadataType === 'lottery' &&
      environment.games.lottery.showBooths;

    uiMD.ticketsSearchSizeClass =
      gameMetadata.metadataType === 'lottery'
        ? environment.games.lottery.ticketsSearchSizeClass
        : null;

    uiMD.ticketItemSelector =
      gameMetadata.metadataType === 'lottery'
        ? gameMetadata.id === GameId.LOTARIA_POPULAR
          ? TicketItemSelector.NUMBER
          : TicketItemSelector.COUNTER
        : null;

    uiMD.isFractionLottery =
      gameMetadata.metadataType === 'lottery' &&
      gameMetadata.id === GameId.LOTARIA_POPULAR;

    return uiMD;
  }
}
