import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {encodeURLParameters} from '../../util/url';
import {Shareable} from '../shareable';

@Injectable()
export class TwitterService implements Shareable {
  notify = false;

  constructor(@Inject('window') private window: Window) {}

  share(title: string, text: string): Observable<any> {
    this.window.open(
      'https://twitter.com/intent/tweet?text=' +
        title +
        ' ' +
        encodeURLParameters(text),
      '_blank',
    );
    return of(true);
  }
}
