export const DEFAULT_TICKET_FILTERS = {
  all: {
    args: {
      id: 'all',
      gameIds: null,
      translationKey: 'tickets.filters.all',
      assetType: null,
      asset: null,
      renderLabel: true,
    },
    section: 0,
  },
  active: {
    args: {
      id: 'active',
      gameIds: null,
      translationKey: 'tickets.filters.active',
      assetType: null,
      asset: null,
      renderLabel: true,
    },
    section: 0,
  },
  prized: {
    args: {
      id: 'prized',
      gameIds: ['prized'],
      translationKey: 'tickets.filters.prized',
      assetType: 'image',
      assetTranslated: true,
      asset: 'assets/img/boletos/prize-icon.svg',
      assetMobile: 'assets/img/boletos/prize-icon.svg',
      renderLabel: true,
    },
    section: 1,
  },
  '@games': {section: 2},
  group: {
    args: {
      id: 'group',
      gameIds: ['group'],
      translationKey: 'tickets.filters.inGroups',
      assetType: 'icon',
      asset: 'icon-grupo-icon',
      renderLabel: true,
    },
    section: 3,
  },
  individual: {
    args: {
      id: 'individual',
      gameIds: ['individual'],
      translationKey: 'tickets.filters.individual',
      assetType: 'icon',
      asset: 'icon-individual-icon',
      renderLabel: true,
    },
    section: 3,
  },
  subscribed: {
    args: {
      id: 'subscribed',
      gameIds: ['subscribed'],
      translationKey: 'tickets.filters.subscribed',
      assetType: 'icon',
      asset: 'icon-favorito-abono-icon',
      renderLabel: true,
    },
    section: 3,
  },
  stored: {
    args: {
      id: 'stored',
      gameIds: ['stored'],
      label: 'Almacenados',
      translationKey: 'tickets.filters.almanac',
      assetType: 'icon',
      asset: 'icon-administraciones-icon',
      renderLabel: true,
    },
    section: 3,
  },
  archived: {
    args: {
      id: 'archived',
      gameIds: ['archived'],
      label: 'Archivados',
      translationKey: 'tickets.filters.archived',
      assetType: 'icon',
      asset: 'icon-archivar-icon',
      renderLabel: true,
      enableGroupedTickets: false,
    },
    section: 3,
  },
};

export const DEFAULT_RESULT_FILTERS = {
  all: {
    args: {
      id: 'all',
      gameIds: null,
      translationKey: 'tickets.filters.all',
      assetType: null,
      asset: null,
      renderLabel: true,
    },
    section: 0,
  },
  '@games': {section: 1},
};

export const DEFAULT_GAME_FILTERS = {
  all: {
    args: {
      id: 'all',
      gameIds: null,
      translationKey: 'tickets.filters.all',
      assetType: null,
      asset: null,
      renderLabel: true,
    },
    section: 0,
  },
  '@games': {section: 0},
};

export const DEFAULT_RAFFLE_TICKET_FILTERS = {
  all: {
    args: {
      id: 'all',
      gameIds: null,
      translationKey: 'tickets.filters.all',
      assetType: null,
      asset: null,
      renderLabel: true,
    },
    section: 0,
  },
  prized: {
    args: {
      id: 'prized',
      gameIds: ['prized'],
      translationKey: 'tickets.filters.prized',
      assetType: 'image',
      assetTranslated: true,
      asset: 'assets/img/boletos/prize-icon.svg',
      assetMobile: 'assets/img/boletos/prize-icon.svg',
      renderLabel: true,
    },
    section: 1,
  },
  group: {
    args: {
      id: 'group',
      gameIds: ['group'],
      translationKey: 'tickets.filters.inGroups',
      assetType: 'icon',
      asset: 'icon-grupo-icon',
      renderLabel: true,
    },
    section: 3,
  },
  individual: {
    args: {
      id: 'individual',
      gameIds: ['individual'],
      translationKey: 'tickets.filters.individual',
      assetType: 'icon',
      asset: 'icon-individual-icon',
      renderLabel: true,
    },
    section: 3,
  },
  stored: {
    args: {
      id: 'stored',
      gameIds: ['stored'],
      label: 'Almacenados',
      translationKey: 'tickets.filters.almanac',
      assetType: 'icon',
      asset: 'icon-administraciones-icon',
      renderLabel: true,
      enableGroupedTickets: false,
    },
    section: 3,
  },
};

export const STATIC_RAFFLE_TICKET_FILTERS = [
  'stored',
  'prized',
  'prizedWithProvisionals',
  'all',
  'group',
  'individual',
];
