import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {CurrencyModule} from '../currency.module';

import {CurrencyInputComponent} from './currency-input.component';

@NgModule({
  imports: [CommonModule, FormsModule, CurrencyModule],
  exports: [CurrencyInputComponent],
  declarations: [CurrencyInputComponent],
})
export class CurrencyInputModule {}
