import {environment} from '~environments/environment';

import {GenericTicket} from './generic-ticket';

export function mergeTickets(
  dest: Array<GenericTicket>,
  source: Array<GenericTicket>,
): Array<GenericTicket> {
  dest.sort((a: GenericTicket, b: GenericTicket) => b.compareTo(a));
  const minTickets = environment.allInfo.reduce.minTickets;

  source.forEach((ticket: GenericTicket) => {
    const lastTicket =
      dest.length > 0 // No tickets case
        ? dest[dest.length - 1]
        : null;

    const index = dest.findIndex(t => t.id === ticket.id);

    if (
      index < 0 &&
      (dest.length < minTickets || ticket.compareTo(lastTicket) > 0)
    ) {
      const insertIndex = dest.findIndex(t => ticket.compareTo(t) > 0);

      if (insertIndex >= 0) {
        dest.splice(insertIndex, 0, ticket);
      } else {
        dest.push(ticket);
      }
    } else if (index >= 0) {
      dest[index] = ticket;
    }
  });

  return [...dest];
}

export function reduceTickets(tickets: Array<GenericTicket>): Array<GenericTicket> {
  const maxTickets = environment.allInfo.reduce.maxTickets;
  if (tickets && tickets.length > maxTickets) {
    tickets.splice(maxTickets, tickets.length);
  }

  return [...tickets];
}
