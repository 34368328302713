import {Serializable} from 'common';

class PendingPrizeAppInfoInternal {
  totalPaidExternal: number;

  totalPaidInApp: number;

  // Premios mayores, los que gestiona TL
  totalPending: number;

  // Premios medianos, gestiona el mediador
  totalPendingByIntegrator: number;

  totalPendingString: string;

  subtitle: string;

  showAnimation: boolean | null;

  // Indicates that all money bets are divided among those with winning tickets.
  // Example: Gopher 5, North 5
  parimutuel: boolean;
}

export class PendingPrizeAppInfo extends Serializable(PendingPrizeAppInfoInternal) {
  static createFromBackend(obj: any): PendingPrizeAppInfo {
    let pendingPriceAppInfo = new PendingPrizeAppInfo();
    pendingPriceAppInfo.totalPaidExternal = obj.totalPaidExternal;
    pendingPriceAppInfo.totalPaidInApp = obj.totalPaidInApp;
    pendingPriceAppInfo.totalPending = obj.totalPending;
    pendingPriceAppInfo.totalPendingByIntegrator = obj.totalPendingByIntegrator;
    pendingPriceAppInfo.totalPendingString = obj.totalPendingString;
    pendingPriceAppInfo.subtitle = obj.subtitle;
    pendingPriceAppInfo.showAnimation = null;
    pendingPriceAppInfo.parimutuel = obj.parimutuel;
    return pendingPriceAppInfo;
  }
}
