import {format, formatDistance, formatRelative, Locale, parse} from 'date-fns';
import {enUS, es, pt} from 'date-fns/locale';

const LOCALE_ID_MAP = {
  'en-US': enUS,
  'es-ES': es,
  'pt-PT': pt,
  'es-MX': es,
};

let locale: Locale;

export function setDateLocale(local: Locale): void {
  locale = local;
}

export function setDateLocaleByLocaleId(localeId: string): void {
  locale = LOCALE_ID_MAP[localeId];
}

export function formatDate(
  date: Date | number,
  formatStr: string,
  options: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  } = {},
): string {
  return format(
    date,
    formatStr,
    locale ? Object.assign({locale: locale}, options) : options,
  );
}

export function formatDateDistance(
  date: Date | number,
  baseDate: Date | number,
  options: {includeSeconds?: boolean; addSuffix?: boolean; locale?: Locale} = {},
): string {
  return formatDistance(
    date,
    baseDate,
    locale ? Object.assign({locale: locale}, options) : options,
  );
}

export function formatRelativeDate(
  date: Date | number,
  baseDate: Date | number,
  options: {locale?: Locale; weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6} = {},
): string {
  return formatRelative(
    date,
    baseDate,
    locale ? Object.assign({locale: locale}, options) : options,
  );
}

export function parseDate(
  dateStr: string,
  formatStr: string,
  referenceDate: Date | number,
  options: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  } = {},
): Date {
  return parse(
    dateStr,
    formatStr,
    referenceDate,
    locale ? (Object.assign({locale: locale}, options) as any) : options,
  );
}
