import {
  FacebookService,
  MailService,
  ShareAPIService,
  TelegramService,
  TwitterService,
  WhatsappService,
  SmsUriService,
} from 'common';

export const SHARE_SERVICE_RESOLVER = {
  whatsapp: WhatsappService,
  facebook: FacebookService,
  twitter: TwitterService,
  mail: MailService,
  api: ShareAPIService,
  telegram: TelegramService,
  sms: SmsUriService,
};
