import {Platform} from '../responsive/platform';

import {UriMap} from './uri-types';

export class UriUtils {
  static getDestinationUrl(uriMap: UriMap, platform: Platform): string {
    return platform === Platform.MOBILE
      ? uriMap.destinationPlatformUrl.mobile
      : uriMap.destinationPlatformUrl.desktop;
  }

  static urlMatches(
    pattern: RegExp,
    originUrl: string,
    url: string,
  ): RegExpMatchArray | boolean {
    return pattern ? url.match(pattern) : originUrl === url;
  }

  static getUrlParams(uri: string): RegExpMatchArray {
    return uri.match(/:[a-zA-Z0-9-]+/g);
  }
}
