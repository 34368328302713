import {LotteryBooth} from './lottery-booth';

export class GeolocatedLotteryBooth extends LotteryBooth {
  distance: number;

  constructor(lotteryBooth: LotteryBooth, distance: number) {
    super();
    Object.assign(this, lotteryBooth);
    this.distance = distance;
  }
}
