import {Injectable, OnDestroy} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

import {LotteryTicket} from '../../../lottery/data/lottery-ticket';
import {LotteryTicketSelection} from '../../../lottery/lottery-ticket-selection';

import {LotteryFormService} from './lottery-form.service';
import {PanelFormService} from './panel-form.service';

@Injectable()
export abstract class AbstractLotteryBetFormService implements OnDestroy {
  ticketAmounts = new ReplaySubject<{[keys: string]: number}>(1);

  selectedTicketsChange = new ReplaySubject<Array<LotteryTicket>>(1);

  raffleId: number;

  protected panelFormService: PanelFormService;

  protected combinations: FormArray;

  protected selectedTickets: Map<string, LotteryTicket>;

  protected savedTickets: Map<string, LotteryTicket>;

  constructor() {
    this.selectedTickets = new Map<string, LotteryTicket>();
  }

  abstract ticketChange(
    selection: LotteryTicketSelection,
    searchAdminId?: string,
  ): void;

  initialize(
    panelFormService: PanelFormService,
    betForm: FormGroup,
    raffleId: number,
  ): void {
    this.panelFormService = panelFormService;
    this.combinations = <FormArray>betForm.get('combinations');
    this.raffleId = raffleId;

    this.selectedTickets = new Map<string, LotteryTicket>();
    this.selectedTicketsChange.next(this.getSelectedTickets());
    this.savedTickets = new Map<string, LotteryTicket>();

    this.combinations.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(combinations => {
        let amounts = {};
        combinations.forEach(ticket => {
          amounts[ticket.hash] = ticket.amount;
        });

        this.ticketAmounts.next(amounts);
      });
  }

  ngOnDestroy(): void {
    this.ticketAmounts.complete();
    this.selectedTicketsChange.complete();
  }

  updateRaffleId(raffleId: number): void {
    this.raffleId = raffleId;
  }

  removeTicket(hash: string): void {
    const index = this.findFormIndexForTicket(this.combinations, hash);

    this.selectedTickets.delete(hash);
    this.selectedTicketsChange.next(this.getSelectedTickets());
    this.combinations.removeAt(index);
  }

  removeAllTickets(): void {
    if (this.combinations) {
      while (this.combinations.controls.length > 0) {
        this.removeTicket(this.combinations.controls[0].value.hash);
      }
    }
  }

  getSelectedTickets(): Array<LotteryTicket> {
    return Array.from(this.selectedTickets.values());
  }

  clearSelectedTickets(): void {
    this.selectedTickets.clear();
    this.selectedTicketsChange.next(this.getSelectedTickets());
  }

  saveState(): void {
    this.savedTickets.clear();

    this.selectedTickets.forEach(ticket =>
      this.savedTickets.set(ticket.hash, ticket),
    );

    this.selectedTickets.clear();
    this.selectedTicketsChange.next(this.getSelectedTickets());
  }

  restoreState(raffleId: number): void {
    if (this.raffleId === raffleId) {
      this.selectedTickets.clear();
      this.savedTickets.forEach(ticket =>
        this.selectedTickets.set(ticket.hash, ticket),
      );
      this.selectedTicketsChange.next(this.getSelectedTickets());
    }
  }

  /**
   * Given a form and a list of LotteryTickets, finds the lotteryticket
   * associated with that form.
   */
  findTicketFromForm(
    ticketForm: FormGroup,
    tickets: Array<LotteryTicket>,
  ): LotteryTicket {
    return tickets.find(ticket => ticketForm.value.hash === ticket.hash);
  }

  getTicketReservations(): Observable<Array<LotteryTicket>> {
    return this.selectedTicketsChange.pipe(
      map((tickets: Array<LotteryTicket>) =>
        tickets.filter((ticket: LotteryTicket) => ticket.reservable),
      ),
    );
  }

  getTotalAmount(): number {
    if (!this.combinations) {
      return 0;
    } else {
      return this.combinations.controls.reduce(
        (total, control) => control.value.amount + total,
        0,
      );
    }
  }

  getMaxTickets(): number {
    return this.panelFormService
      ? (<LotteryFormService>this.panelFormService).getMaximumNumbers()
      : -1;
  }

  protected removeTicketAt(index: number): void {
    const ticket = this.combinations.at(index);

    this.selectedTickets.delete(ticket.value.hash);
    this.selectedTicketsChange.next(this.getSelectedTickets());
    this.combinations.removeAt(index);
  }

  protected findFormIndexForTicket(formArray: FormArray, hash: string): number {
    return formArray.controls.findIndex(c => c.value.hash === hash);
  }
}
