import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, iif, Observable, of, zip} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {Logger} from '../logger/logger';

import {GoogleAuthService, GoogleUser} from './google-auth.service';
import {GoogleContact} from './google-contact';

@Injectable({providedIn: 'root'})
export class GoogleContactsService {
  private readonly BASE = 'https://people.googleapis.com/v1/';
  private readonly SCOPE = 'https://www.googleapis.com/auth/contacts.readonly';
  private readonly URL = 'people/me/connections';

  private args: HttpParams;

  constructor(
    private googleAuth: GoogleAuthService,
    private http: HttpClient,
    protected logger: Logger,
  ) {
    this.args = new HttpParams();
    this.args = this.args.set('pageSize', '2000');
    this.args = this.args.set('personFields', 'names,phoneNumbers');
  }

  /**
   * Get contacts if logged, else complete the observable.
   */
  getContactsIfLogged(): Observable<Array<GoogleContact>> {
    return this.googleAuth.getLoginStatus().pipe(
      switchMap(isLoggedIn => {
        if (isLoggedIn) {
          return this.googleAuth.getCurrentUser().pipe(
            switchMap(googleUser => {
              if (googleUser && googleUser.scope.split(' ').includes(this.SCOPE)) {
                return this.getUserContacts(of(googleUser));
              } else {
                return EMPTY;
              }
            }),
          );
        } else {
          return EMPTY;
        }
      }),
    );
  }

  /**
   * Get contacts if logged, else force login.
   */
  getContacts(): Observable<Array<GoogleContact>> {
    return zip([this.googleAuth.getLoginStatus(), this.hasPermission()]).pipe(
      take(1),
      switchMap(([logged, contactPermissions]) => {
        if (!logged) {
          return this.googleAuth.login([this.SCOPE]);
        } else if (!contactPermissions) {
          return this.googleAuth.grant(this.SCOPE);
        }
        return this.googleAuth.getCurrentUser();
      }),
      switchMap(currentUser => {
        return this.getUserContacts(of(currentUser));
      }),
    );
  }

  hasPermission(): Observable<boolean> {
    return this.googleAuth
      .getLoginStatus()
      .pipe(
        switchMap(isLoggedIn =>
          iif(
            () => isLoggedIn,
            this.googleAuth
              .getCurrentUser()
              .pipe(
                map((googleUser: GoogleUser) =>
                  googleUser.scope.includes(this.SCOPE),
                ),
              ),
            of(false),
          ),
        ),
      );
  }

  private getUserContacts(userObs: Observable<GoogleUser>) {
    return userObs.pipe(
      switchMap((googleUser: GoogleUser) => {
        let params = this.args.set('access_token', googleUser.access_token);
        return this.http.get(this.BASE + this.URL, {params: params});
      }),
      map(
        (
          data: any, // Entry does not exist in case of no contacts
        ) => (data.connections ? this.parseContacts(data.connections) : []),
      ),
    );
  }

  private parseContacts(connections: Array<any>): Array<GoogleContact> {
    return connections
      .filter(
        connection =>
          connection.names &&
          connection.names.length > 0 &&
          connection.names[0].displayName &&
          connection.phoneNumbers &&
          connection.phoneNumbers.length,
      )
      .map(
        connection =>
          new GoogleContact(
            connection.names[0].displayName,
            connection.phoneNumbers.map(phoneNumber => phoneNumber.canonicalForm),
          ),
      );
  }
}
