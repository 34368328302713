import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {zip} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {ResponsiveService} from '../responsive/responsive.service';

import {ActivatedRouteService} from './activated-route.service';
import {TagRoute} from './tag-route';

/**
 * Service to keep track of the last navigated route.
 *
 * When the application first loads the last route is always /
 *
 * A route with the tag no-history won't be added to the history so the user
 * can't back to it. (Useful for prompts)
 */
@Injectable({providedIn: 'root'})
export class PreviousRouteService {
  private currentRoute: TagRoute;

  private history: Array<TagRoute>;

  private noHistory = false;

  private shouldClean = false;

  constructor(
    private activatedRouteService: ActivatedRouteService,
    private responsiveService: ResponsiveService,
    private router: Router,
  ) {
    this.responsiveService.breakpointChange.subscribe(() => this.markForClean());

    this.history = [];

    zip(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
      this.activatedRouteService.route,
    )
      .pipe(
        map((data: any) => ({
          url: data[0].url,
          tag: data[1].snapshot.data.tag || 'noop',
        })),
      )
      .subscribe(res => {
        if (
          !this.noHistory &&
          !(this.currentRoute && this.currentRoute.tag === res.tag)
        ) {
          if (
            this.history.length > 0 &&
            this.history[this.history.length - 1].tag === res.tag
          ) {
            // detect back
            this.history.pop();
          } else if (
            this.history.length > 0 &&
            this.history[this.history.length - 1].tag === this.currentRoute.tag
          ) {
            this.history[this.history.length - 1] = this.currentRoute;
          } else if (this.currentRoute && this.currentRoute.tag !== 'no-history') {
            this.history.push(this.currentRoute);
          }
        }

        this.currentRoute = new TagRoute(res.tag, res.url);

        if (this.shouldClean) {
          this.clean();
        }

        this.noHistory = false;
      });
  }

  /**
   * Navigates to the last route.
   *
   * If default is specified it will navigate to that route when the last route
   * is empty (i.e. /) this is useful to route to a known place when the app
   * loads in an arbitrary route.
   */
  back(defaultRoute: string | Array<string> = '/') {
    let tagRoute = this.history[this.history.length - 1];

    if (this.history.length === 0 || tagRoute.route === '/') {
      this.noHistory = true;
      return this.router.navigate(
        typeof defaultRoute === 'string' ? [defaultRoute] : defaultRoute,
      );
    } else {
      return this.router.navigateByUrl(tagRoute.route);
    }
  }

  markNextNavigationToNoHistory() {
    this.noHistory = true;
  }

  /**
   * Marks the service to clean the history in the next navigation.
   */
  markForClean() {
    this.shouldClean = true;
  }

  /**
   * Clears the history.
   */
  clean() {
    this.history = [new TagRoute('void', '/')];
    this.shouldClean = false;
  }

  /**
   * Returns the current route history.
   */
  getCurrentHistory(): Array<string> {
    return this.history.map(tagRoute => tagRoute.route);
  }
}
