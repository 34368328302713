import {Serializable} from 'common';

export interface ResultSummaryLine {
  value: any;
}

class SummaryStringLineInternal implements ResultSummaryLine {
  value: string;
}

class SummaryAmountLineInternal implements ResultSummaryLine {
  value: number;

  symbol: string;
}

export class SummaryStringLine extends Serializable(SummaryStringLineInternal) {
  static createFromBackend(obj: any) {
    let summarySL = new SummaryStringLine();
    summarySL.value = obj.value || '' + obj.amount;

    return summarySL;
  }
}
export class SummaryAmountLine extends Serializable(SummaryAmountLineInternal) {
  static createFromBackend(obj: any) {
    let summaryAL = new SummaryAmountLine();
    summaryAL.value = obj.amount;
    summaryAL.symbol = obj.currencySymbol;

    return summaryAL;
  }
}
