import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {TranslatableText} from '../../i18n/translatable-text';
import {AlertType} from '../alert-type';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({
  selector: 'tl-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertModalComponent {
  @Input()
  message: string | TranslatableText;

  @Input()
  type: AlertType;

  @Output()
  changeEvent = new EventEmitter<any>();

  @HostBinding('class.tl-alert-modal')
  readonly hostClass = true;

  constructor() {
    this.cancel = this.cancel.bind(this);
  }

  cancel(): void {
    this.changeEvent.emit();
  }
}
