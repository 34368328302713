import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {LotteryBooth} from './data/lottery-booth';

@Injectable({providedIn: 'root'})
export class BoothSelectedService {
  selectedBooth = new BehaviorSubject<LotteryBooth>(null);
  loadingBooth = new BehaviorSubject<LotteryBooth>(null);
}
