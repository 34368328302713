import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  Subject,
  catchError,
  filter,
  first,
  map,
  of,
  switchMapTo,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '~environments/environment';

import {
  ModalDialogType,
  TranslatableText,
  Destroyable,
  NgValidator,
  ModalDialogComponent,
  ModalDialogLayout,
} from 'common';

import {SessionService} from '../../user/auth/session.service';
import {State} from '../data/state';
import {StateAppService} from '../state-app.service';

@Component({
  selector: 'tl-state-subscribe-dialog',
  templateUrl: './state-subscribe-dialog.component.html',
  styleUrls: ['./state-subscribe-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateSubscribeDialogComponent implements OnInit {
  @HostBinding('class.tl-state-subscribe-dialog')
  readonly hostClass = true;

  state: State;
  geoLocation: GeolocationPosition;

  form: FormGroup;

  email: string;

  error: string;

  isAnonymous = false;

  isSubscribeSuccessful = false;

  loading = false;

  submitted = false;

  /** to init dialog when all info are completed */
  showDialog = false;

  type: ModalDialogType = 'ok_cancel';

  title: string | TranslatableText;

  message: ModalDialogComponent['message'];

  accept: ModalDialogComponent['accept'] = {
    key: 'availableStatesDialog.notifyButton',
  };

  cancel: ModalDialogComponent['cancel'] = {
    key: 'availableStatesDialog.gotItButton',
  };

  public get modalDialogLayout() {
    return ModalDialogLayout;
  }

  readonly routeMap = environment.locale.routes;

  @Destroyable()
  private destroy = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private stateAppService: StateAppService,
  ) {}

  ngOnInit(): void {
    //set default title
    if (!this.title) {
      this.stateAppService
        .getAvailableStatesText()
        .subscribe(availableStatesText => {
          this.title = {
            key: 'availableStatesDialog.titleActivate',
            keyData: {availableStates: availableStatesText},
          };
        });
    }

    // set default msg
    if (!this.message) {
      this.message = {
        key: 'availableStatesDialog.textActivate',
        keyData: {state: this.state?.name},
      };
    }

    // check loagin
    this.sessionService
      .isLoggedIn()
      .pipe(takeUntil(this.destroy))
      .subscribe((logged: boolean) => {
        this.isAnonymous = !logged;
        if (this.isAnonymous) {
          this.buildForm();
        }
        this.cdr.markForCheck();
      });

    // get state from service if not set external
    if (!this.state && this.geoLocation) {
      this.loading = true;
      this.stateAppService
        .getStateByGps(this.geoLocation)
        .pipe(
          takeUntil(this.destroy),
          catchError(err => {
            if (err?.error?.code === 'StateNotFound') {
              this.loading = false;
              this.changeTextsToSuccessful(false);
              this.openDialog();
              return of(null);
            }
            return throwError(() => err);
          }),
          filter(state => !!state),
          tap((state: State) => (this.state = state)),
          switchMapTo(this.stateAppService.getSubscribedStates().pipe(first())),
          map((statesNotify: string[]) => {
            return !!statesNotify.find(code => code === this.state.code);
          }),
        )
        .subscribe(alreadyNotify => {
          this.loading = false;
          if (alreadyNotify) {
            this.changeTextsToSuccessful(false);
          }
          this.openDialog();
        });
    } else {
      this.openDialog();
    }

    // To use this in clickAccept
    this.clickAccept = this.clickAccept.bind(this);
  }

  clickAccept(): boolean {
    this.submitted = true;

    if (this.isSubscribeSuccessful) {
      return true;
    } else {
      if (!this.isAnonymous || this.form.valid) {
        this.sendSubscription();
      }
      return false;
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, NgValidator.email]],
    });
  }

  private sendSubscription(): void {
    this.loading = true;
    const subscribeObserver = this.stateAppService.notifyState(
      this.state,
      this.email ? this.email : this.form.value.email,
    );

    subscribeObserver.subscribe({
      next: (response: {status: string; message: string}) => {
        this.loading = false;
        if (response.status === 'OK') {
          this.changeTextsToSuccessful();
        } else {
          this.showError(response.message);
        }
        this.cdr.markForCheck();
      },
      error: errorResponse => {
        this.loading = false;
        this.showError(errorResponse.error.message);
      },
    });
  }

  /**
   * @param fromNotifySucces if its true changes buttons and labels
   * for preserve messages from back when user already subscriber
   */
  private changeTextsToSuccessful(fromNotifySucces = true): void {
    this.isSubscribeSuccessful = true;
    this.type = 'ok';
    this.accept = {
      key: 'availableStatesDialog.gotItButton',
    };

    if (fromNotifySucces) {
      this.accept = {key: 'availableStatesDialog.greatButton'};
      this.title = {
        key: 'availableStatesDialog.titleNoAvailable',
        keyData: {state: this.state.name},
      };
      this.message = {key: 'availableStatesDialog.textNoAvailable'};
    }
  }

  private showError(message: string): void {
    this.error = message;
  }

  private openDialog() {
    this.showDialog = true;
    this.cdr.markForCheck();
  }
}
