import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {UserKyc} from '../../../user/data/user-kyc';

@Component({
  selector: 'tl-kyc-ko-dialog',
  templateUrl: './kyc-ko-dialog.component.html',
  styleUrls: ['./kyc-ko-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KycKoDialogComponent {
  @HostBinding('class.tl-kyc-ko-dialog')
  readonly hostClass = true;

  @Input()
  data: UserKyc;
}
