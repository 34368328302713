import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({selector: 'img[tlImgDefault]'})
export class ImageDefaultDirective {
  @Input('tlImgDefault')
  src: string;

  private image: HTMLImageElement;

  constructor(private element: ElementRef, private renderer: Renderer2) {
    this.image = this.element.nativeElement;
  }

  @HostListener('error')
  onError() {
    // Avoid infinite loop of errors if default image fails.
    if (this.image.src.includes(this.src)) {
      throw new Error(
        `Default image ${this.image.src} is failing, was ${this.src} `,
      );
    }
    this.renderer.setAttribute(this.image, 'src', this.src);
  }
}
