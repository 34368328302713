/**
 * Since Typescript breaks the prototype chain when extending Error, all
 * custom errors should inherit from here to have it fixed.
 */
export class CustomError extends Error {
  constructor(message: string) {
    super(message);

    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      (this as any).__proto__ = actualProto;
    }

    this.name = 'CustomError';
  }
}
