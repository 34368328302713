import {InjectionToken} from '@angular/core';
import {UAParser} from 'ua-parser-js';

const uaParserSingleton = new UAParser().getResult();

export const UA_PARSER = new InjectionToken<any>('UAParser', {
  providedIn: 'root',
  factory: getUaParser,
});

export function getUaParser() {
  return uaParserSingleton;
}

export function getBrowser(): string {
  return uaParserSingleton.browser.name;
}

export function getBrowserVersion(): string {
  return uaParserSingleton.browser.version;
}

export function getOS() {
  return uaParserSingleton.os.name;
}

export function getOSVersion() {
  return uaParserSingleton.os.version;
}

export function getBrowserInfoString() {
  return (
    `${getBrowser()} (${getBrowserVersion()})` +
    ` running over ${getOS()} (${getOSVersion()})`
  );
}
