import {Injectable} from '@angular/core';
import {
  BarcodeFormat as ZxingBarcodeFormat,
  Exception,
  Result,
  DecodeHintType,
} from '@zxing/library';
import {Observable, Subject, from} from 'rxjs';
import {first, map, switchMap, takeUntil} from 'rxjs/operators';

import {AbstractBarcodeReader} from './abstract-barcode-reader';
import {BarcodeFormat} from './barcode-format';
import {BarcodeResult} from './barcode-result';
import {BrowserMultiReaderFix} from './browser-multi-reader-fix';
import {CameraService} from '../camera/camera.service';
import {CameraType} from '../camera/camera-type';
import {ResponsiveService} from '../responsive/responsive.service';
import {Logger} from '../logger/logger';
import {Destroyable} from '../util/destroyable';

@Injectable({providedIn: 'root'})
export class ZxingBarcodeService extends AbstractBarcodeReader {
  private barcode = new Subject<BarcodeResult>();

  private reader: BrowserMultiReaderFix;

  @Destroyable()
  private destroySubject = new Subject<void>();

  constructor(
    private cameraService: CameraService,
    private logger: Logger,
    responsiveService: ResponsiveService,
  ) {
    super(responsiveService);

    this.onDecode = this.onDecode.bind(this);
  }

  load(element: HTMLVideoElement, formats?: Array<string>): Observable<boolean> {
    return this.initialize(element, formats);
  }

  continue(): void {}

  read(): Observable<BarcodeResult> {
    return this.barcode;
  }

  destroy(): void {
    this.barcode.complete();
    this.barcode = new Subject<BarcodeResult>();

    if (this.reader) {
      this.reader.reset();
    }

    super.destroy();
  }

  private initialize(element: HTMLVideoElement, formats?: Array<string>) {
    let hints;
    if (!!formats) {
      hints = new Map();
      hints.set(
        DecodeHintType.POSSIBLE_FORMATS,
        formats.map((format: BarcodeFormat) =>
          this.nativeFormatToZxingFormat(format),
        ),
      );
    }

    this.reader = new BrowserMultiReaderFix(hints);

    return this.cameraService
      .getCameraSelector(CameraType.BACK, this.getBestCamera.bind(this))
      .pipe(
        first(),
        switchMap(config =>
          window.navigator.mediaDevices.getUserMedia({
            video: config,
            audio: false,
          }),
        ),
        takeUntil(this.destroySubject),
        switchMap((stream: MediaStream) => {
          this.configCamera(stream, this.logger);
          return from(this.reader.decodeFromStream(stream, element, this.onDecode));
        }),
        map(() => true),
      );
  }

  private onDecode(result: Result, err?: Exception) {
    if (!!result) {
      this.barcode.next({
        content: result.getText(),
        format: this.zxingFormatToNativeFormat(result.getBarcodeFormat()),
      });
    } else if (
      !!err &&
      err.name !== 'NotFoundException' &&
      err.message !== 'No MultiFormat Readers were able to detect the code.'
    ) {
      // When compile for pre err.name is not exist
      this.barcode.error(err);
    }
  }

  private zxingFormatToNativeFormat(format: ZxingBarcodeFormat): BarcodeFormat {
    switch (format) {
      case ZxingBarcodeFormat.AZTEC:
        return BarcodeFormat.AZTEC;
      case ZxingBarcodeFormat.CODABAR:
        return BarcodeFormat.CODABAR;
      case ZxingBarcodeFormat.CODE_39:
        return BarcodeFormat.CODE_39;
      case ZxingBarcodeFormat.CODE_93:
        return BarcodeFormat.CODE_93;
      case ZxingBarcodeFormat.CODE_128:
        return BarcodeFormat.CODE_128;
      case ZxingBarcodeFormat.DATA_MATRIX:
        return BarcodeFormat.DATA_MATRIX;
      case ZxingBarcodeFormat.EAN_8:
        return BarcodeFormat.EAN_8;
      case ZxingBarcodeFormat.EAN_13:
        return BarcodeFormat.EAN_13;
      case ZxingBarcodeFormat.ITF:
        return BarcodeFormat.ITF;
      case ZxingBarcodeFormat.MAXICODE:
        return BarcodeFormat.MAXICODE;
      case ZxingBarcodeFormat.PDF_417:
        return BarcodeFormat.PDF_417;
      case ZxingBarcodeFormat.QR_CODE:
        return BarcodeFormat.QR_CODE;
      case ZxingBarcodeFormat.RSS_14:
        return BarcodeFormat.RSS_14;
      case ZxingBarcodeFormat.RSS_EXPANDED:
        return BarcodeFormat.RSS_EXPANDED;
      case ZxingBarcodeFormat.UPC_A:
        return BarcodeFormat.UPC_A;
      case ZxingBarcodeFormat.UPC_E:
        return BarcodeFormat.UPC_E;
      case ZxingBarcodeFormat.UPC_EAN_EXTENSION:
        return BarcodeFormat.UPC_EAN_EXTENSION;
      default:
        return BarcodeFormat.UNKNOWN;
    }
  }

  private nativeFormatToZxingFormat(format: BarcodeFormat): ZxingBarcodeFormat {
    switch (format) {
      case BarcodeFormat.AZTEC:
        return ZxingBarcodeFormat.AZTEC;
      case BarcodeFormat.CODABAR:
        return ZxingBarcodeFormat.CODABAR;
      case BarcodeFormat.CODE_39:
        return ZxingBarcodeFormat.CODE_39;
      case BarcodeFormat.CODE_93:
        return ZxingBarcodeFormat.CODE_93;
      case BarcodeFormat.CODE_128:
        return ZxingBarcodeFormat.CODE_128;
      case BarcodeFormat.DATA_MATRIX:
        return ZxingBarcodeFormat.DATA_MATRIX;
      case BarcodeFormat.EAN_8:
        return ZxingBarcodeFormat.EAN_8;
      case BarcodeFormat.EAN_13:
        return ZxingBarcodeFormat.EAN_13;
      case BarcodeFormat.ITF:
        return ZxingBarcodeFormat.ITF;
      case BarcodeFormat.MAXICODE:
        return ZxingBarcodeFormat.MAXICODE;
      case BarcodeFormat.PDF_417:
        return ZxingBarcodeFormat.PDF_417;
      case BarcodeFormat.QR_CODE:
        return ZxingBarcodeFormat.QR_CODE;
      case BarcodeFormat.RSS_14:
        return ZxingBarcodeFormat.RSS_14;
      case BarcodeFormat.RSS_EXPANDED:
        return ZxingBarcodeFormat.RSS_EXPANDED;
      case BarcodeFormat.UPC_A:
        return ZxingBarcodeFormat.UPC_A;
      case BarcodeFormat.UPC_E:
        return ZxingBarcodeFormat.UPC_E;
      case BarcodeFormat.UPC_EAN_EXTENSION:
        return ZxingBarcodeFormat.UPC_EAN_EXTENSION;
    }
  }
}
