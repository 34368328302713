import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  I18nModule,
  ModalDialogModule,
  ResponsiveUtilsModule,
  ScrollableModule,
} from 'common';

import {KycRequiredPrizeDialogComponent} from './kyc-required-prize-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ModalDialogModule,
    ResponsiveUtilsModule,
    ScrollableModule,
  ],
  exports: [KycRequiredPrizeDialogComponent],
  declarations: [KycRequiredPrizeDialogComponent],
})
export class KycRequiredPrizeDialogModule {}
