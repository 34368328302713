<tl-lottery-booth-item
  [lotteryBooth]="lotteryBooth"
  tlToggleCloseOthers
  [enableIconFav]="false"
  [enableToggle]="enableToogle">
  <div *ngIf="lotteryBooth.enabled; else soon" class="booth-item-fav">
    <div
      *ngIf="!isLoading; else loader"
      class="fav-icon icon-favorito-abono-icon"
      [class.selected]="isFavorite"
      (click)="setFavorite($event)"></div>
    <div class="fav-icon-text--mobile">
      {{ 'administrationOffice.status.favorite' | i18n }}
    </div>
    <div class="fav-icon-text--desktop">
      {{
        (isFavorite
          ? 'administrationOffice.status.favorite'
          : 'administrationOffice.status.unfavoriteDesktop'
        ) | i18n
      }}
    </div>
  </div>
  <ng-template #soon>
    <div class="booth-item-coming-soon">
      {{ 'administrationOffice.status.future' | i18n }}
    </div>
  </ng-template>
  <ng-template #loader>
    <tl-loading class="booth-item-fav__loading"></tl-loading>
  </ng-template>
</tl-lottery-booth-item>
