import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';

import {MaintenanceInterceptor} from './maintenance.interceptor';

@NgModule({
  imports: [],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true},
  ],
})
export class MaintenanceModule {}
