import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {AbstractDao} from '../model/abstract.dao';

@Injectable({providedIn: 'root'})
export class AppleDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  login(token: string, deviceId: string, instalationId?: number, userName?: string) {
    let body = new HttpParams()
      .append('token', token)
      .append('grantedPermissions', 'full_name, email')
      .append('deviceType', 'WEB')
      .append('registerDeviceId', deviceId)
      .append('instalationId', instalationId.toString());
    if (userName) {
      body = body.append('userName', userName);
    }
    return this.http.post(this.baseUrl + '/apple/login', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
}
