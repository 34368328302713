import {Injectable} from '@angular/core';
import {LocalStorage} from 'common';
import {Observable, of} from 'rxjs';

/**
 *  Convert TuLoteroLocalStorageService to use LocalStorage.
 *  This allows the use of LocalStorage depending on the environment.
 */
@Injectable()
export class LocalStorageService {
  constructor(private localStorage: LocalStorage) {}

  public clear(): Observable<void> {
    this.localStorage.clear();
    return of(void 0);
  }

  getItem(key: string): Observable<string | null> {
    return of(this.localStorage.getItem(key));
  }

  removeItem(key: string): Observable<void> {
    this.localStorage.removeItem(key);
    return of(void 0);
  }

  setItem(key: string, data: string): Observable<void> {
    this.localStorage.setItem(key, data);
    return of(void 0);
  }
}
