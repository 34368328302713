export function calculateElementWidth(element, includeMargin = false) {
  const style = element.currentStyle || window.getComputedStyle(element);
  const width = element.offsetWidth;
  const margin = includeMargin
    ? parseFloat(style.marginLeft) + parseFloat(style.marginRight)
    : 0;

  return width + margin;
}

export function calculateElementHeight(element, includeMargin = false) {
  const style = element.currentStyle || window.getComputedStyle(element);
  const height = element.offsetHeight;
  const margin = includeMargin
    ? parseFloat(style.marginTop) + parseFloat(style.marginBottom)
    : 0;

  return height + margin;
}

export function calculateScrollPercentage(element, body: HTMLElement): number {
  let h = element;
  let b = body;
  let st = 'scrollTop';
  let sh = 'scrollHeight';
  return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
}

export function isMouseInsideElement(
  mouse: MouseEvent,
  element: HTMLElement,
): boolean {
  const oc = element.getBoundingClientRect();

  return (
    mouse.clientX > oc.left &&
    mouse.clientX < oc.right &&
    mouse.clientY > oc.top &&
    mouse.clientY < oc.bottom
  );
}

export function calculateElementCoordinates(el) {
  let lx;
  let ly;

  for (
    lx = 0, ly = 0;
    el != null;
    lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent
  ) {}
  return {x: lx, y: ly};
}

/**
 * Returns the relative position from the child to the parent from their
 * absolute screen position.
 */
export function relativizePosition(
  child: DOMRect,
  relativeTo: DOMRect,
): {top: number; left: number} {
  return {
    top: child.top - relativeTo.top,
    left: child.left - relativeTo.left,
  };
}

/**
 * jQuery like:
 * https://stackoverflow.com/questions/1145850/how-to-get-height-of-entire-document-with-javascript
 */
export function getDocHeight(doc: Document): number {
  return Math.max(
    doc.body.scrollHeight,
    doc.documentElement.scrollHeight,
    doc.body.offsetHeight,
    doc.documentElement.offsetHeight,
    doc.documentElement.clientHeight,
  );
}
