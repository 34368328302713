import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

import {Queue} from '../../util/collections/queue';

@Injectable({providedIn: 'root'})
export class SlidableModalAnimationService {
  private queue = new Queue<ReplaySubject<void>>();

  private animating = false;

  /**
   * Register elements in the queue.
   * If not animating, starts the process.
   **/
  waitForAnimationTurn(): Observable<void> {
    const animateItem = new ReplaySubject<void>(1);

    this.queue.push(animateItem);

    if (!this.animating) {
      this.animating = true;
      animateItem.next();
    }

    return animateItem.asObservable();
  }

  /**
   * Removes the element which just finished the animation, and fires the next
   *one in the queue. The process is finished when no elements are left in the
   *queue.
   **/
  animationFinished(): void {
    this.queue.pop();

    if (this.queue.isEmpty()) {
      this.animating = false;
    } else {
      this.queue.store[0].next();
    }
  }
}
