import {InjectionToken} from '@angular/core';

export const GOOGLE_AUTH_STORE_KEY = new InjectionToken<string>(
  'GoogleAuthStoreKey',
);
export const GOOGLE_LOGIN_STORE_KEY = new InjectionToken<string>(
  'GoogleLoginStoreKey',
);
export const GOOGLE_MAPS_API_KEY = new InjectionToken<string>('GoogleMapsApiKey');
export const GOOGLE_MAPS_DEFAULT_ZOOM_IN = new InjectionToken<string>(
  'GoogleMapsDefaultZoomIn',
);
export const GOOGLE_CLIENT_ID = new InjectionToken<string>('GoogleClientId');
