import {NgModule} from '@angular/core';

import {IframeAutoHeightDirective} from './iframe-auto-height.directive';
import {IframeEventListenerDirective} from './iframe-event-listener.directive';
import {IframeSwipeDirective} from './iframe-swipe.directive';
import {SrcdocPolyFillDirective} from './srcdoc-polyfill.directive';

@NgModule({
  exports: [
    IframeAutoHeightDirective,
    IframeEventListenerDirective,
    IframeSwipeDirective,
    SrcdocPolyFillDirective,
  ],
  declarations: [
    IframeAutoHeightDirective,
    IframeEventListenerDirective,
    IframeSwipeDirective,
    SrcdocPolyFillDirective,
  ],
})
export class IframeModule {}
