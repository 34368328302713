import {FormGroup, Validators} from '@angular/forms';
import {ExtendedFormArray} from 'common';

import {BackendTransformable} from '../../../backend/util/backend-transformable';
import {TlContact} from '../../../contacts/data/tl-contact';
import {Group} from '../../groups/data/group';

import {ShareItemContactFormGroup} from './share-item-contact-form-group';
import {ShareItemFormGroup} from './share-item-form-group';
import {ShareItemGroupFormGroup} from './share-item-group-form-group';

export abstract class ShareFormGroup
  extends FormGroup
  implements BackendTransformable
{
  protected constructor(controls: Array<ShareItemFormGroup>) {
    super({
      shares: new ExtendedFormArray(controls ? controls : [], Validators.min(1)),
    });
  }

  abstract addShare(share: TlContact | Group): void;

  abstract toBackend(price?: number, asPercent?: boolean): Record<string, unknown>;

  getShares(): ExtendedFormArray<ShareItemFormGroup> {
    return <ExtendedFormArray<ShareItemFormGroup>>this.get('shares');
  }

  hasShare(share: TlContact | Group): boolean {
    return !!this.getShares().controls.find(
      (c: ShareItemFormGroup) => c.getShare() === share,
    );
  }

  /**
   * Returns removed item
   */
  removeShare(share: TlContact | Group): ShareItemFormGroup {
    const array = <ExtendedFormArray<ShareItemFormGroup>>this.controls['shares'];
    const item = <ShareItemFormGroup>(
      array.controls.find((c: FormGroup) => c.controls['share'].value === share)
    );
    array.remove(item);
    return item;
  }

  reset(value?: any, options?: {onlySelf?: boolean; emitEvent?: boolean}): void {
    const array = <ExtendedFormArray<ShareItemFormGroup>>this.getShares();
    array.clear();

    if (value && Array.isArray(value['shares'])) {
      value['shares'].forEach(share =>
        array.push(
          share.share instanceof TlContact
            ? new ShareItemContactFormGroup(
                share.share,
                share.percent,
                share.blocked,
              )
            : new ShareItemGroupFormGroup(share.share),
        ),
      );
    }
  }
}
