import {Injectable} from '@angular/core';
import {CookiesService} from 'common';

@Injectable({providedIn: 'root'})
export class CampaignService {
  constructor(private cookies: CookiesService) {}

  hasCampaign() {
    return this.cookies.check('TLcampaign');
  }

  getCampaignParams() {
    let campaign = '';
    let source = '';
    if (this.cookies.check('TLcampaign')) {
      let params: URLSearchParams;
      params = new URLSearchParams(atob(this.cookies.get('TLcampaign')));

      if (params.has('cid')) {
        if (params.get('cid') === 'onelink') {
          source = params.get('pid');
          campaign = params.get('c');
        } else if (params.get('cid') === 'fb') {
          // Facebook
          source = params.get('utm_source');
          campaign = params.get('utm_campaign');
        } else {
          source = params.get('cid');
        }
      }
    }
    return {campaign: campaign, source: source};
  }
}
