/**
 * Represents a message that can be throw in an application context
 */
import {Serializable} from '../../serializable/serializable';

import {AbstractMessageHook} from './abstract-message-hook';

class MessageHookInternal extends AbstractMessageHook {}

export class MessageHook extends Serializable(MessageHookInternal) {
  static createFromBackend(obj: any) {
    let m = new MessageHook();

    m.title = obj.title;
    m.content = obj.content;
    m.image = obj.imageSVG;

    return m;
  }
}
