import {Component, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {ResponsiveService} from 'common';
import {first} from 'rxjs/operators';

import {TuloteroDeviceService} from '../common/device/tulotero-device.service';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-browser-support',
  templateUrl: './browser-support.component.html',
  styleUrls: ['./browser-support.component.scss'],
})
export class BrowserSupportComponent {
  showBrowserAlert = false;

  constructor(
    private deviceService: TuloteroDeviceService,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
    private router: Router,
  ) {
    this.showBrowserAlert = !this.deviceService.isSupportedDevice();
    // When user click first time on the document we add a router event to
    // cancel the alert in the next navigation event.
    this.renderer.listen(
      'document',
      'click',
      () => !!this.router.events.pipe(first()).subscribe(() => this.cancel()),
    );
  }

  get unsupportedBrowserMessage() {
    return this.responsiveService.isMobile()
      ? `Estás usando un navegador con características limitadas. Para una
           mejor experiencia utiliza Chrome.`
      : `Tu navegador está obsoleto, por lo que es posible que algunas de las
            características de esta web no funcionen correctamente.`;
  }

  cancel() {
    this.showBrowserAlert = false;
  }
}
