import {Serializable} from 'common';

class CombinationValueInternal {
  value: string;

  highlight: boolean;
}

export class CombinationValue extends Serializable(CombinationValueInternal) {
  static createFromBackend(obj: any) {
    let selectionValue = new CombinationValue();

    selectionValue.value = obj.value;
    selectionValue.highlight = obj.highlighted;

    return selectionValue;
  }
}
