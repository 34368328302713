import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SwitchStringBooleanDirective} from './switch-string-boolean.directive';
import {SwitchComponent} from './switch.component';

@NgModule({
  imports: [CommonModule],
  exports: [SwitchComponent, SwitchStringBooleanDirective],
  declarations: [SwitchComponent, SwitchStringBooleanDirective],
})
export class SwitchModule {}
