import {Serializable} from 'common';

class ShipmentAddressInternal {
  name: string;
  address: string;
  city: string;
  zipCode: string;
  state: string;
  phone: string;

  constructor() {}
}

export class ShipmentAddress extends Serializable(ShipmentAddressInternal) {
  public static createFromBackend(obj: any): ShipmentAddress {
    if (!!obj) {
      let shipmentAddress = new ShipmentAddress();
      shipmentAddress.name = obj.nombre;
      shipmentAddress.address = obj.direccion;
      shipmentAddress.city = obj.poblacion;
      shipmentAddress.zipCode = obj.codPostal;
      shipmentAddress.state = obj.provincia;
      shipmentAddress.phone = obj.telefono;
      return shipmentAddress;
    }
    return null;
  }
}
