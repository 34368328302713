/**
 * Represent any error it has been detected and parsed
 */
export interface ParsedError {
  message: string;
  stacktrace: string;
  context: any;
}

/**
 * Parses any application error.
 */
export function parseError(error: any): ParsedError {
  let message: string;
  let stacktrace: string;
  let context: any;

  if (error && typeof error === 'object') {
    if (error.hasOwnProperty('name') || error.hasOwnProperty('message')) {
      message = error.name + ': ' + error.message;
    } else {
      message = 'Error desconocido';
    }
    stacktrace = error.hasOwnProperty('stack') ? error.stack : '';
    context = error;
  } else {
    message = 'Error desconocido';
    stacktrace = '';
    context = error;
  }

  return {message, stacktrace, context};
}

/**
 * Detects when error is produced by CORS.
 *
 * @example
 * {
 *    "headers": {
 *        "normalizedNames": [],
 *        "lazyUpdate": null,
 *        "headers": []
 *    },
 *    "status": 0,
 *    "statusText": "Unknown Error",
 *    "url": "assets/data/force-update.json?cache-bust=0.3147917551261812",
 *    "ok": false,
 *    "name": "HttpErrorResponse",
 *    "message": "Http failure response for
 *     assets/data/force-update.json?cache-bust=0
 *    .3147917551261812: 0 Unknown Error",
 *    "error": {
 *        "isTrusted": true
 *    }
 * }
 */
export function isCORSError(error: any): boolean {
  return (
    typeof error === 'object' &&
    ((error.status === 0 && error.statusText === 'Unknown Error') ||
      (error.isTrusted === true && Object.keys(error).length === 1))
  );
}
