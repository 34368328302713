import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PaginationData} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractDao} from '../../../common/model/abstract.dao';

import {GenericTicket} from './generic-ticket';
import {Filter} from '../../game-filter/data/filter';

@Injectable({providedIn: 'root'})
export class TicketsDao extends AbstractDao {
  constructor(protected http: HttpClient) {
    super();
  }

  moreTickets(
    index: number,
    filter?: Array<string>,
    groupId?: number,
  ): Observable<PaginationData<GenericTicket>> {
    let filterString =
      filter && filter.length > 0
        ? '/' +
          filter
            .reduce((a, f) => a.concat(f) + '+', '')
            .replace(/\+$/g, '')
            .toLowerCase()
        : '';

    let params = new HttpParams().append('firstResult', index.toString());

    if (groupId) {
      params = params.append('groupId', groupId.toString());
    }

    return this.http
      .get(this.baseUrl + '/users/boletos/me' + filterString, {params: params})
      .pipe(
        map((obj: any) => {
          let tickets: Array<GenericTicket>;
          tickets = [];
          for (let ticket of obj.boletos) {
            tickets.push(GenericTicket.createFromBackend(ticket));
          }

          return new PaginationData<GenericTicket>(tickets, obj.total);
        }),
      );
  }

  /**
   * Returns the filters that can be used by the user to filter his tickets
   */
  getUserFilters(): Observable<Array<Filter>> {
    return this.http
      .get(this.baseUrl + '/users/boletos/me/filters')
      .pipe(
        map((userFilters: any) =>
          userFilters.filters.map(filter => Filter.createFromBackend(filter)),
        ),
      );
  }

  getTicket(ticketId: number): Observable<GenericTicket> {
    return this.http
      .get(this.baseUrl + '/users/boletos/' + ticketId)
      .pipe(map(res => GenericTicket.createFromBackend(res)));
  }
}
