import {environment} from '~environments/environment';

export enum TaxIdentificationType {
  NATIONAL = 'NATIONAL',
  FOREIGN = 'FOREIGN',
}

export enum TaxIdentificationTypeBackendPT {
  NATIONAL = 'PORTUGUESE',
  FOREIGN = 'FOREIGN',
}

/**cifType → Forzarlo a TaxIdentificationTypeBackendPT.NATIONAL en caso
 * de portugal si no cumple ningún criterio, ya que si luego en el KYC
 * es necesario cambiarlo lo corregirá el usuario. */
export function getBackendTaxIdentificationType(
  taxIdentificationType: TaxIdentificationType,
): TaxIdentificationTypeBackendPT {
  if (environment.id === 'pt') {
    switch (taxIdentificationType) {
      case TaxIdentificationType.FOREIGN:
        return TaxIdentificationTypeBackendPT.FOREIGN;
      case TaxIdentificationType.NATIONAL:
      default:
        return TaxIdentificationTypeBackendPT.NATIONAL;
    }
  }
  return null;
}

export function taxIdentificationFromBackend(
  taxIdentificationType: TaxIdentificationTypeBackendPT,
): TaxIdentificationType {
  if (environment.id === 'pt') {
    switch (taxIdentificationType) {
      case TaxIdentificationTypeBackendPT.NATIONAL:
        return TaxIdentificationType.NATIONAL;
      case TaxIdentificationTypeBackendPT.FOREIGN:
        return TaxIdentificationType.FOREIGN;
      default:
        return null;
    }
  }
  return null;
}
