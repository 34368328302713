import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {NumberUnitComponent} from './number-unit.component';

@NgModule({
  imports: [CommonModule],
  exports: [NumberUnitComponent],
  declarations: [NumberUnitComponent],
})
export class NumberUnitModule {}
