/**
 * Load a script asynchronously
 *
 * @param id html tag id
 * @param url script url
 * @param  async async mode for tag script
 * @return return true if not loaded, and false if it'd
 *     already been loaded
 */
import {Observable} from 'rxjs';

export function loadScript(
  id: string,
  url: string,
  async = true,
): Observable<boolean> {
  return new Observable(o => {
    let fjs = document.getElementsByTagName('script')[0];

    if (document.getElementById(id)) {
      o.next(false);
      o.complete();
      return;
    }
    let js = document.createElement('script');
    js.id = id;
    js.async = async;
    js.src = url;
    js.onload = () => {
      o.next(true);
      o.complete();
    };
    js.onerror = e => {
      fjs.parentNode.removeChild(js);
      o.error(e);
    };
    fjs.parentNode.insertBefore(js, fjs);
  });
}
