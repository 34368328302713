<div [class]="['tl-datefield__wrapper', 'tl-datefield__wrapper--' + formatClass]">
  <select
    #select
    *ngIf="showDays"
    class="form-control inline tl-datefield__day"
    [ngClass]="{'tl-datefield__empty-value': !day}"
    [(ngModel)]="day"
    (change)="onChange()">
    <option value="undefined" disabled selected hidden>
      {{ 'games.time.day' | i18n }}
    </option>
    <option *ngFor="let dayRow of displayDays" [value]="dayRow">{{ dayRow }}</option>
  </select>

  <select
    #select
    *ngIf="showMonths"
    [ngClass]="{'tl-datefield__empty-value': !month}"
    class="form-control inline tl-datefield__month"
    [(ngModel)]="month"
    (change)="onChange()">
    <option value="undefined" disabled selected hidden>
      {{ 'games.time.month' | i18n }}
    </option>
    <option *ngFor="let monthRow of displayMonths" [value]="monthRow - 1">
      {{ monthRow }}
    </option>
  </select>

  <select
    #select
    *ngIf="showYears"
    class="form-control inline year tl-datefield__year"
    [ngClass]="{'tl-datefield__empty-value': !year}"
    [(ngModel)]="year"
    (change)="onChange()">
    <option value="undefined" disabled selected hidden>
      {{ 'games.time.year' | i18n }}
    </option>
    <option *ngFor="let yearRow of displayYears" [value]="yearRow">
      {{ yearRow }}
    </option>
  </select>
</div>
