import {Component, EventEmitter, Input, Output} from '@angular/core';
import {first} from 'rxjs/operators';

import {SessionService} from '../user/auth/session.service';

import {LotteryBooth} from './data/lottery-booth';
import {ResponsiveService} from 'common';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({template: 'no-ui'})
export abstract class LotteryBoothItemBaseComponent {
  @Input()
  lotteryBooth: LotteryBooth;

  @Input()
  isFavorite = false;

  @Output()
  clickFav = new EventEmitter<LotteryBooth>();

  isLoggedIn: boolean;

  @Input()
  enableToogle = true;

  constructor(sessionService: SessionService, responsiveService: ResponsiveService) {
    sessionService
      .isLoggedIn()
      .pipe(first())
      .subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn));

    this.enableToogle = !responsiveService.isDesktop();
  }

  setFavorite(event: Event) {
    this.clickFav.emit(this.lotteryBooth);

    if (event) {
      event.stopPropagation();
    }
  }
}
