import {query, transition, trigger, useAnimation} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import {slideInVertical, slideOutVertical} from '../../animation/angular/slide';

@Component({
  selector: 'tl-semi-modal',
  templateUrl: './semi-modal.component.html',
  styleUrls: ['./semi-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('contentSlide', [
      transition(
        ':enter',
        query('.tl-semi-modal__content', [
          useAnimation(slideInVertical, {
            params: {start: '100%', timing: '.3s .2s ease-in'},
          }),
        ]),
      ),
      transition(
        ':leave',
        query('.tl-semi-modal__content', [
          useAnimation(slideOutVertical, {
            params: {end: '100%', timing: '.3s .2s ease-out'},
          }),
        ]),
      ),
    ]),
  ],
})
export class SemiModalComponent {
  @Input()
  type: 'plain' | 'button' | 'buttons' = 'plain';

  @Input()
  acceptKey = 'global.acceptButton';

  @Input()
  cancelKey = 'global.cancelButton';

  @Input()
  buttonText: string;

  @Input()
  loading = false;

  @Input()
  title: string | TemplateRef<any>;

  @Input()
  autoClose = true;

  @Input()
  @HostBinding('@contentSlide')
  animation = true;

  @Output()
  accept = new EventEmitter<void>();

  @Output()
  closeModal = new EventEmitter<void>();

  @Output()
  headerClick = new EventEmitter<void>();

  @Output()
  animationDone = new EventEmitter<void>();

  @HostBinding('class.tl-semi-modal')
  readonly hostClass = true;

  constructor(private elementRef: ElementRef) {}

  @HostListener('@contentSlide.done')
  onAnimationDone(): void {
    this.animationDone.emit();
  }

  @HostListener('click', ['$event'])
  onClick(clickEvent: MouseEvent): void {
    if (this.autoClose && clickEvent.target === this.elementRef.nativeElement) {
      this.onClose();
      clickEvent.stopPropagation();
    }
  }

  @HostListener('tlswipedown')
  onClose(): void {
    this.closeModal.emit();
  }

  onHeaderClick(): void {
    this.headerClick.emit();
  }
}
