export enum DniType {
  NATIONAL,
  COMMUNITY,
  NON_COMMUNITY,
  PASSPORT,
  CARD_ID,
  CARD_DL_ID,
  CITIZEN_CARD,
  IDENTITY_CARD,
  RESIDENCE_CARD,
  FOREIGNER_IDENTIFICATION_CARD,
}
