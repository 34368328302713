import {Inject, Injectable} from '@angular/core';
import {NavigationCancel, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

import {ROUTER_HISTORY_LIMIT} from './config-tokens';
import {RouterHistoryEntry} from './router-history-entry';

@Injectable({providedIn: 'root'})
export class RouterHistoryService {
  private storage: Array<RouterHistoryEntry>;

  constructor(
    @Inject(ROUTER_HISTORY_LIMIT) private limit: number,
    private router: Router,
  ) {
    this.initialize();
  }

  /**
   * Dumps the current history state into an array.
   */
  dump(): Array<RouterHistoryEntry> {
    return this.storage.slice(0);
  }

  private initialize(): void {
    this.storage = [];

    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationEnd || event instanceof NavigationCancel,
        ),
      )
      .subscribe(navEvent => {
        if (navEvent instanceof NavigationEnd) {
          this.storage.unshift({
            requestedUrl: navEvent.url,
            finalUrl: navEvent.urlAfterRedirects,
            status: 'success',
          });
        }

        if (navEvent instanceof NavigationCancel) {
          this.storage.unshift({
            requestedUrl: navEvent.url,
            finalUrl: this.router.url,
            status: 'aborted: ' + navEvent.reason,
          });
        }

        if (this.storage.length > this.limit) {
          this.storage.pop();
        }
      });
  }
}
