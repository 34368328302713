import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BalloonComponent} from './balloon/balloon.component';
import {HappyBirthdayComponent} from './happy-birthday/happy-birthday.component';

@NgModule({
  declarations: [BalloonComponent, HappyBirthdayComponent],
  imports: [CommonModule],
  exports: [HappyBirthdayComponent],
})
export class HappyBirthdayModule {}
