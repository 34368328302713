import {DOCUMENT} from '@angular/common';
import {
  Directive,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

import {PopoverExtensionService} from './popover-extension.service';

@Directive({selector: '[ngbPopover][tlCloseOutside]'})
export class CloseClickOutsideDirective implements OnInit, OnDestroy {
  @Input('tlCloseOutside')
  popover: NgbPopover;

  constructor(
    private closeOthersService: PopoverExtensionService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.closeOthersService.add(this.popover);
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: MouseEvent) {
    // filter clicks on popover window
    if (this.popover.isOpen()) {
      let pop = this.document.querySelector('ngb-popover-window');

      if (!pop || !pop.contains(event.target as Element)) {
        this.closeOthersService.closeAll();
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.closeOthersService.remove(this.popover);
  }
}
