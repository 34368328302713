import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {environment} from '~environments/environment';

import {LotteryDao} from './lottery.dao';
import {LotteryDaoAdapter} from './lottery.dao.adapter';

export function lotteryDaoFactory(http: HttpClient) {
  if (environment.locale.locale === 'es-ES') {
    return new LotteryDaoAdapter(http);
  } else {
    return new LotteryDao(http);
  }
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    // TODO · ADAPTER · remove
    {provide: LotteryDao, useFactory: lotteryDaoFactory, deps: [HttpClient]},
  ],
})
export class LotteryModelModule {}
