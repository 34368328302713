import {Injectable} from '@angular/core';

import {AbstractObservableDataService} from '../../model/abstract-observable-data.service';

import {AsapMessageHook} from './message-hook-asap';

@Injectable({providedIn: 'root'})
export class MessageHookAsapService extends AbstractObservableDataService<
  Array<AsapMessageHook>
> {}
