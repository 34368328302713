import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {LocaleService} from '../../../i18n/locale.service';
import {MARKET_IMAGE_FILES, Market} from '../market';
import {ENVIRONMENT} from '../../../environment/environment-token';
import {TranslateAssetPipe} from '../../../i18n/translable-asset.pipe';

@Component({
  selector: 'tl-market-logo',
  templateUrl: './market-logo.component.html',
  styleUrls: ['./market-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketLogoComponent {
  @Input()
  market: Market;
  @Input()
  link: string;

  private translateAsset: (assetPath: string) => string;
  private readonly downloadUsingPlayStore: boolean;

  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    private localeService: LocaleService,
  ) {
    this.downloadUsingPlayStore =
      this.environment.app.android.downloadUsingPlayStore;
    const locale = this.localeService.getLocale();
    this.translateAsset = filePath =>
      new TranslateAssetPipe(locale).transform(filePath);
  }

  get image(): string {
    if (this.market === Market.APPLE) {
      return this.translateAsset(MARKET_IMAGE_FILES['APPLE']);
    }

    if (this.market === Market.GOOGLE) {
      return !this.downloadUsingPlayStore
        ? this.translateAsset(MARKET_IMAGE_FILES['APK'])
        : this.translateAsset(MARKET_IMAGE_FILES['GOOGLE']);
    }

    if (this.market === Market.SAMSUNG) {
      return this.translateAsset(MARKET_IMAGE_FILES['APK']);
    }
  }

  get imageKey(): string {
    switch (this.market) {
      case Market.APPLE:
        return 'pwa.conversion.downloadForIos';
      case Market.GOOGLE:
        return 'pwa.conversion.downloadForAndroid';
      case Market.SAMSUNG:
        return 'pwa.conversion.downloadForAndroid';
    }
  }
}
