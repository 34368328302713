import {SubscriptionNotExecuted} from './subscription-not-executed';
import {Serializable, SerializableProperty, SerializableType} from 'common';

class SusbcriptionsNotExecutedInternal {
  clientId: number;

  @SerializableProperty(SubscriptionNotExecuted, SerializableType.COLLECTION)
  subscriptionsNotExecuted: Array<SubscriptionNotExecuted>;
}

export class SubscriptionsNotExecuted extends Serializable(
  SusbcriptionsNotExecutedInternal,
) {
  static createFromBackend(obj: any): SubscriptionsNotExecuted {
    let susbcriptionNotExecuted: SubscriptionsNotExecuted =
      new SubscriptionsNotExecuted();
    susbcriptionNotExecuted.clientId = obj.clienteId;
    if (obj.abonosNotExecutedCache) {
      susbcriptionNotExecuted.subscriptionsNotExecuted =
        obj.abonosNotExecutedCache.map(SubscriptionNotExecuted.createFromBackend);
    }
    return susbcriptionNotExecuted;
  }
}
