import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[tlClickOutside]',
})
export class ClickOutsideDirective {
  @Output('tlClickOutside')
  emitter = new EventEmitter<void>();

  private selfClick = false;

  @HostListener('document:click')
  clickOutside(): void {
    if (!this.selfClick) {
      this.emitter.emit();
    }
    this.selfClick = false;
  }

  @HostListener('click')
  captureClick(): void {
    this.selfClick = true;
  }
}
