import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  Renderer2,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {first} from 'rxjs/operators';

@Directive({selector: 'iframe[srcdoc][tlSrcdocPolyfill]'})
export class SrcdocPolyFillDirective implements OnChanges {
  @Input()
  srcdoc: any; // SafeHtml

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  load = new EventEmitter<any>();

  iframe: any;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private zone: NgZone,
  ) {
    this.iframe = this.element.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const html = this.sanitizer.sanitize(SecurityContext.HTML, this.srcdoc);

    if ('srcdoc' in this.iframe) {
      this.renderer.setProperty(this.iframe, 'srcdoc', html);
    } else if (html && html.search('<form') >= 0) {
      this.renderer.setProperty(
        this.iframe,
        'src',
        "javascript: '" + html.replaceAll("'", "\\'") + "'",
      );
    } else {
      let frameDocument =
        this.element.nativeElement.contentDocument ||
        this.element.nativeElement.contentWindow.document;
      frameDocument.body.innerHTML = html;
    }

    this.zone.onMicrotaskEmpty.pipe(first()).subscribe(() => {
      this.load.emit();
    });
  }
}
