import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  catchHttpError,
  PaginationData,
  PaginationDirection,
  TranslationsService,
  ValidatableCode,
} from 'common';
import {Observable, of, throwError} from 'rxjs';
import {first, map, switchMap} from 'rxjs/operators';

import {AbstractDao} from '../../../common/model/abstract.dao';
import {TuLoteroServerError} from '../../../error/tulotero-server-error';

import {Group, groupHistoryEntryFactory} from './group';
import {GroupHistoryEntry} from './group-history-entry';
import {GroupHistoryEntryType} from './group-history-entry-type.enum';
import {MoneyActivityGroup} from './money-activity-group';

@Injectable({providedIn: 'root'})
export class GroupDao extends AbstractDao implements ValidatableCode {
  constructor(
    private http: HttpClient,
    private translationsService: TranslationsService,
  ) {
    super();
  }

  get(id: number, lastUpdate?: number): Observable<Group> {
    let params = new HttpParams();
    if (lastUpdate) {
      params = params.append('lastUpdate', lastUpdate.toString());
    }
    return this.http
      .get(`${this.baseUrl}/users/groups/${id}`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<any>) => (res.status !== 204 ? res.body : res)),
        map(response =>
          response instanceof HttpResponse
            ? new Group()
            : Group.createFromBackend(response),
        ),
      );
  }

  join(code: string): Observable<void> {
    return this.http.post<void>(
      this.baseUrl + '/users/groups/join',
      JSON.stringify({code: code}),
    );
  }

  create(data: Record<string, unknown>): Observable<number> {
    return this.http
      .post(this.baseUrl + '/users/groups', JSON.stringify(data))
      .pipe(map((group: any) => group.id));
  }

  update(groupId: number, data: Record<string, unknown>): Observable<Group> {
    return this.http
      .put(`${this.baseUrl}/users/groups/${groupId}`, JSON.stringify(data))
      .pipe(map(group => Group.createFromBackend(group)));
  }

  updatePreferences(
    groupId: number,
    data: Record<string, unknown>,
  ): Observable<Group> {
    return this.http
      .put(
        `${this.baseUrl}/users/groups/${groupId}/members/me/profile`,
        JSON.stringify(data),
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  leave(groupId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/users/groups/${groupId}/members/me`,
    );
  }

  inviteMembers(groupId: number, members: {[key: string]: any}): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/users/groups/${groupId}/members`,
      JSON.stringify(members),
    );
  }

  requestMoney(
    amount: number,
    groupId: number,
    userIds: Array<number>,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/users/groups/${groupId}/balance/request`,
      JSON.stringify({amount: amount, userIds: userIds}),
    );
  }

  distributeMoney(
    amount: number,
    groupId: number,
    userIds: Array<number>,
  ): Observable<Group> {
    return this.http
      .post(
        `${this.baseUrl}/users/groups/${groupId}/balance/withdraw`,
        JSON.stringify({amount: amount, userIds: userIds}),
        {headers: new HttpHeaders().append('Content-Type', 'application/json')},
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  distributeMoneyParticipants(amount: number, groupId: number): Observable<Group> {
    return this.http
      .post(
        `${this.baseUrl}/users/groups/${groupId}/balance/withdrawParticipants`,
        JSON.stringify({amount: amount}),
        {headers: new HttpHeaders().append('Content-Type', 'application/json')},
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  sendMessage(message: string, groupId: number): Observable<Group> {
    return this.http
      .post<void>(`${this.baseUrl}/users/groups/${groupId}/message`, message)
      .pipe(map(group => Group.createFromBackend(group)));
  }

  transferMoney(
    amount: number,
    groupId: number,
    userId?: number,
  ): Observable<Group> {
    return this.http
      .post(
        `${this.baseUrl}/users/groups/${groupId}/balance/transfer`,
        JSON.stringify({amount: amount, userId: userId}),
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  transferPendingMoney(groupId: number, userIds: Array<number>): Observable<Group> {
    return this.http
      .post(
        `${this.baseUrl}/users/groups/${groupId}/balance/transferPending`,
        JSON.stringify({userIds: userIds}),
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  transferAndRequestMoney(amount: number, groupId: number): Observable<Group> {
    return this.http
      .post(
        `${this.baseUrl}/users/groups/${groupId}/balance/transferCycle`,
        JSON.stringify({amount: amount}),
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  uploadPicture(groupId: number, picture: Blob): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl}/users/groups/${groupId}/photo`,
      picture,
    );
  }

  validCode(code: string): Observable<boolean> {
    return this.getGroupByCodeInternal(code).pipe(
      map(() => false),
      catchHttpError(404, () => of(true)),
    );
  }

  getGroupByCode(code: string): Observable<Group> {
    return this.translationsService
      .getCompiledTranslation('games.clubs.clubDoesNotExist', {
        code: code.toUpperCase(),
      })
      .pipe(
        first(),
        switchMap((message: string) =>
          this.getGroupByCodeInternal(code).pipe(
            map(group => Group.createFromBackend(group)),
            catchHttpError(404, () =>
              throwError(
                () => new TuLoteroServerError(404, 'ERROR', message, false),
              ),
            ),
          ),
        ),
      );
  }

  moneyActivities(
    groupId: number,
    index: number,
    type?: Array<string>,
  ): Observable<PaginationData<MoneyActivityGroup>> {
    let url = `${this.baseUrl}/users/groups/${groupId}/history/balance`;

    // Filters:
    // - numResults: numero de actividades a devolver (Para paginar)
    // - idGreaterThan: Envia solo actividades desde ese id
    // - idLessThan: Envía solo actividades hasta ese id
    // - types: typos de actividades a devolver (Separados por comas)
    let params = new HttpParams();
    if (!!index) {
      params = params.append('idLessThan', index.toString());
    }
    if (!!type) {
      params = params.append('types', type.join(','));
    }

    return this.http.get(url, {params: params}).pipe(
      map((obj: any) => {
        let activities = new Array<MoneyActivityGroup>();
        for (let entry of obj.entries) {
          activities.push(MoneyActivityGroup.createFromBackend(entry));
        }

        return new PaginationData<MoneyActivityGroup>(activities, obj.total);
      }),
    );
  }

  getHistory(
    groupId: number,
    startId: number,
    direction: PaginationDirection,
    count = 10,
  ): Observable<PaginationData<GroupHistoryEntry>> {
    let params = new HttpParams()
      .append('numResults', count.toString())
      .append(
        direction === PaginationDirection.DOWN ? 'idGreaterThan' : 'idLessThan',
        startId.toString(),
      );

    if (direction === PaginationDirection.DOWN) {
      params = params.append('idLessThan', (startId + count + 1).toString());
    }

    return this.http
      .get(`${this.baseUrl}/users/groups/${groupId}/history`, {params: params})
      .pipe(
        map((obj: any) => {
          const entries = obj?.entries.map(e =>
            groupHistoryEntryFactory(e).createFromBackend(e),
          );
          // don't trust this totalEntries
          return new PaginationData<GroupHistoryEntry>(entries, obj.totalEntries);
        }),
      );
  }

  getMemberHistory(
    groupId: number,
    memberId: number,
    startId?: number,
    count = 10,
    entryTypes?: Array<GroupHistoryEntryType>,
  ): Observable<PaginationData<GroupHistoryEntry>> {
    let params = new HttpParams().append('numResults', count.toString());

    if (startId > 0) {
      params = params.append('idLessThan', startId.toString());
    }

    if (entryTypes?.length > 0) {
      params = params.append('types', entryTypes.join(','));
    }

    return this.http
      .get(`${this.baseUrl}/users/groups/${groupId}/members/${memberId}/history`, {
        params: params,
      })
      .pipe(
        map((obj: any) => {
          const entries = obj?.entries.map(e =>
            groupHistoryEntryFactory(e).createFromBackend(e),
          );
          return new PaginationData<GroupHistoryEntry>(entries, obj.totalEntries);
        }),
      );
  }

  kickOut(groupId: number, userId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/users/groups/${groupId}/members/${userId}`,
    );
  }

  distributeMoneyBox(
    amount: number,
    groupId: number,
    userIds: Array<number>,
  ): Observable<Group> {
    return this.http
      .post(
        `${this.baseUrl}/users/groups/${groupId}/balanceBlocked/withdraw`,
        JSON.stringify({amount: amount, userIds: userIds}),
        {headers: new HttpHeaders().append('Content-Type', 'application/json')},
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  distributeAllMoneyBox(amount: number, groupId: number): Observable<Group> {
    return this.http
      .post(
        `${this.baseUrl}/users/groups/${groupId}/balanceBlocked/withdrawAll`,
        JSON.stringify({amount: amount}),
        {headers: new HttpHeaders().append('Content-Type', 'application/json')},
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  transferMoneyBox(amount: number, groupId: number): Observable<Group> {
    return this.http
      .post(
        `${this.baseUrl}/users/groups/${groupId}/balanceBlocked/transfer`,
        JSON.stringify({amount: amount}),
        {headers: new HttpHeaders().append('Content-Type', 'application/json')},
      )
      .pipe(map(group => Group.createFromBackend(group)));
  }

  private getGroupByCodeInternal(code: string): Observable<unknown> {
    return this.http.get(`${this.baseUrl}/groups/${encodeURIComponent(code)}`);
  }
}
