<ng-container *ngFor="let step of steps" [ngSwitch]="step.type">
  <tl-step-flow *ngSwitchCase="'step'" [step]="step"></tl-step-flow>
  <div
    *ngSwitchCase="'link'"
    class="separator"
    [class.stretch]="step.link === 3"
    [style.margin-top]="step.marginTop">
    <i [class]="getLinkClass(step.link)" [style.color]="step.color"></i>
  </div>
  <tl-step-ball *ngSwitchCase="'ball'" class="ball" [step]="step"> </tl-step-ball>
</ng-container>
