import {isNumeric} from 'common';

import {SelectionBetRuleTypeMetadata} from './selection-bet-rule-type-metadata';

/**
 * Defines a minimum amount of doubles and/or triples that must be selected and
 * a number of bets from those selections. This multiplier may be lower than the
 * actual number of combinations that could be generated from them.
 *
 * Used for reducidas.
 */
export class RequiredMultiplesBetRuleTypeMetadata extends SelectionBetRuleTypeMetadata {
  minDoubles: number;

  minTriples: number;

  /**
   * The fixed multiplier from the mandatory multiples.
   */
  baseMultiplier: number;

  static createFromBackend(obj: any): RequiredMultiplesBetRuleTypeMetadata {
    let fixedSBRMD = new RequiredMultiplesBetRuleTypeMetadata();

    fixedSBRMD.required = obj.needed;
    fixedSBRMD.optional = !!obj.optional;
    fixedSBRMD.maxDoubles = isNumeric(obj.maxPairs) ? obj.maxPairs : obj.needed;
    fixedSBRMD.maxTriples = isNumeric(obj.maxTriples) ? obj.maxTriples : obj.needed;
    fixedSBRMD.minMultiplier = obj.multBetMin || 1;
    fixedSBRMD.maxMultiplier = obj.multBetMax;

    fixedSBRMD.minDoubles = obj.minPairs || 0;
    fixedSBRMD.minTriples = obj.minTriples || 0;
    fixedSBRMD.baseMultiplier = obj.baseMultiplier;

    return fixedSBRMD;
  }

  // Serializable inherited from parent.
}
