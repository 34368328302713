import {Comparable, Serializable} from 'common';

import {Share} from '../../games/share/data/share';

class TlContactInternal implements Comparable<TlContact> {
  id: number;

  name: string;

  shortName: string;

  date: number;

  image: string;

  initials: string;

  phone: string;

  type: string;

  public isTulotero() {
    return this.type === 'TELEFONO';
  }

  public compareTo(o: TlContact) {
    return this.name.localeCompare(o.name);
  }
}

export class TlContact extends Serializable(TlContactInternal) {
  static createFromBackend(obj: any) {
    let f = new TlContact();

    f.id = obj.clienteRelacionId;
    f.name = obj.nombre;
    f.date = obj.fecha;
    f.image = obj.pictureUrl;
    f.initials = obj.iniciales;
    f.shortName = obj.nombreWithoutMails;
    f.phone = obj.telefono;
    f.type = obj.tipo; // Could be TELEFONO | TELEFONO_EXTERNO
    return f;
  }

  static createFromShare(share: Share) {
    const contact = new TlContact();

    contact.id = share.userId;
    contact.name = share.name;
    contact.shortName =
      share.name.indexOf('(') >= 0 ? share.name.split('(')[0].trim() : share.name;
    contact.image = share.image;
    contact.initials = share.initials;

    return contact;
  }
}
