<div class="icon" *ngIf="step.icon">
  <i [ngClass]="step.icon"></i>
</div>
<div
  class="value"
  [innerHTML]="step.value | htmlSafe"
  [style.color]="step.color"
  [style.borderColor]="step.borderColor"
  [style.backgroundColor]="step.backColor"></div>
<div
  *ngIf="step.label || step.labelKey || step.translatableText"
  class="label"
  [class.allow-overflow]="step.allowLabelOverflow">
  <tl-translatable-text
    *ngIf="step.translatableText; else stepLabel"
    [text]="step.translatableText">
  </tl-translatable-text>
  <ng-template #stepLabel>{{
    step.labelKey ? (step.labelKey | i18n) : step.label
  }}</ng-template>
</div>
