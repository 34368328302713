/* eslint-disable no-restricted-imports */

import '../projects/common/src/util/core/array';
import '../projects/common/src/util/core/error';
import '../projects/common/src/util/core/map';
import '../projects/common/src/util/core/set';
import '../projects/common/src/util/core/string';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {setDateLocale} from 'common';
import {environment} from '~environments/environment';

import {RootModule} from './app/root.module';

if (environment.angularProduction) {
  enableProdMode();
}

// date-fns default locale
setDateLocale(environment.locale.dateLocale);

platformBrowserDynamic()
  .bootstrapModule(RootModule)
  .catch(err => console.error(err));
