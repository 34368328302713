import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {UtilModule} from '../../util/util-module';

import {WheelSelectorComponent} from './wheel-selector.component';

@NgModule({
  imports: [CommonModule, UtilModule],
  exports: [WheelSelectorComponent],
  declarations: [WheelSelectorComponent],
})
export class WheelSelectorModule {}
