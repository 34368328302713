import {NgModule} from '@angular/core';

import {LoadingDirective} from './loading.directive';
import {LoadingComponent} from './view/loading.component';

@NgModule({
  imports: [],
  exports: [LoadingComponent, LoadingDirective],
  declarations: [LoadingComponent, LoadingDirective],
})
export class LoadingModule {}
