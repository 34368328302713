import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {VerificationInterceptor} from '../verification/verification-interceptor';
import {VerificationDialogModule} from './verification-dialog/verification-dialog.module';

@NgModule({
  declarations: [],
})
export class VerificationModule {
  static withInterceptors(): ModuleWithProviders<VerificationModule> {
    return {
      ngModule: VerificationDialogModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: VerificationInterceptor,
          multi: true,
        },
      ],
    };
  }
}
