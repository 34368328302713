import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';

import {RouterParams} from '../router/router-params';

import {PageOpenerInteface, PageResolverInterface} from './page-controller';

/**
 * This class controls a page flow, specially at mobile platform when we
 * need to open a page, do stuff and return the control of the previous page
 * with new data. This class only handle one page at the same time, but it can
 * be used to open several page one-by-one.
 *
 * @example this.opener.open('page1', 'page1')
 * .switchMap(page1data => this.opener.open('page2', 'page2', page2data)
 * .subscribe(finalData => console.log(finalData));
 */
@Injectable()
export class PageControllerService
  implements PageOpenerInteface, PageResolverInterface
{
  titleKey: string;

  data: any;

  private resolver: Subject<any>;

  constructor(private router: Router) {}

  open(url: string | RouterParams, titleKey: string, data?: any): Observable<any> {
    this.titleKey = titleKey;
    this.data = data;
    if (typeof url === 'string') {
      this.router.navigate([url]);
    } else {
      this.router.navigate(url.command, url.extras);
    }
    this.resolver = new Subject();
    return this.resolver.asObservable();
  }

  resolve(data: any): void {
    const currentResolver = this.resolver;
    currentResolver.next(data);
    currentResolver.complete();
  }

  reject(data: any): void {
    const currentResolver = this.resolver;
    currentResolver.error(data);
  }
}
