import {Inject, Injectable, Injector} from '@angular/core';
import {Logger} from '../logger/logger';

import {AbstractBarcodeReader} from './abstract-barcode-reader';
import {NativeBarcodeService} from './native-barcode-reader.service';
import {ZxingBarcodeService} from './zxing-barcode-reader.service';
import {CameraService} from '../camera/camera.service';
import {ResponsiveService} from '../responsive/responsive.service';

@Injectable({providedIn: 'root'})
export class BarcodeReaderFactory {
  private injector: Injector;

  constructor(parentInjector: Injector, @Inject('window') private window: Window) {
    this.injector = Injector.create({
      providers: [
        {
          provide: NativeBarcodeService,
          useClass: NativeBarcodeService,
          deps: [CameraService, Logger, ResponsiveService, 'window'],
        },
        {
          provide: ZxingBarcodeService,
          useClass: ZxingBarcodeService,
          deps: [CameraService, Logger, ResponsiveService],
        },
      ],
      parent: parentInjector,
    });
  }

  getInstance(): AbstractBarcodeReader {
    return this.browserHasBarcodeDetector()
      ? this.injector.get(NativeBarcodeService)
      : this.injector.get(ZxingBarcodeService);
  }

  private browserHasBarcodeDetector(): boolean {
    return 'BarcodeDetector' in this.window;
  }
}
