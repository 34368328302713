import {NgModule} from '@angular/core';
import {
  GOOGLE_AUTH_STORE_KEY,
  GOOGLE_CLIENT_ID,
  GOOGLE_LOGIN_STORE_KEY,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_DEFAULT_ZOOM_IN,
} from 'common';

import {environment} from '../../../environments/environment';

@NgModule({
  providers: [
    {
      provide: GOOGLE_AUTH_STORE_KEY,
      useValue: environment.localStorageKeys.googleToken,
    },
    {
      provide: GOOGLE_LOGIN_STORE_KEY,
      useValue: environment.localStorageKeys.googleLogin,
    },
    {provide: GOOGLE_MAPS_API_KEY, useValue: environment.google.maps.apikey},
    {
      provide: GOOGLE_MAPS_DEFAULT_ZOOM_IN,
      useValue: environment.google.maps.defaultZoomIn,
    },
    {provide: GOOGLE_CLIENT_ID, useValue: environment.google.clientId},
  ],
})
export class GoogleProvidersModule {}
