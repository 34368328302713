import {ActionButton} from './action-button';

export class WhatsappActionButton extends ActionButton {
  static createFromBackend(obj: any) {
    let whatsappActionButton = new WhatsappActionButton();

    Object.assign(whatsappActionButton, ActionButton.createFromBackend(obj));

    whatsappActionButton.urlDesktop = whatsappActionButton.urlDesktop.replace(
      'https://api',
      'https://web',
    );

    return whatsappActionButton;
  }
}
