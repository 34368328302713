import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';
import {LoadMoneyType} from './data/load-money-type';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    cargar: [
      {
        originUrl: environment.internalUrls.load,
        destinationPlatformUrl: {
          desktop: `/${routeMap.desktop.balance}/${routeMap.desktop.money.card}`,
          mobile:
            `/m/${routeMap.mobile.load}?` +
            `${routeMap.money.screenParam}=${LoadMoneyType.CARD}`,
        },
      },
    ],
  },
  {
    transferir: [
      {
        originUrl: environment.internalUrls.transfer,
        destinationPlatformUrl: {
          desktop: `/${routeMap.money.balance}/${routeMap.money.load}/${routeMap.money.transfer}`,
          mobile:
            `/m/${routeMap.mobile.load}?` +
            `${routeMap.money.screenParam}=${LoadMoneyType.TRANSFER}`,
        },
      },
    ],
  },
];

@NgModule({
  providers: [
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class MoneyUriMappingModule {}
