import {Injectable} from '@angular/core';
import {filter, iif, map, of, switchMap, zip} from 'rxjs';
import {User} from '../../user/data/user';
import {State} from './state';
import {SessionService} from '../../user/auth/session.service';
import {UserService} from '../../user/data/user.service';
import {StatesService} from './states.service';
import {StatesDao} from './states.dao';
import {StateAppDataService} from './state-app-data.service';

import {environment} from '~environments/environment';

@Injectable()
export class UpdateStateAppListenerService {
  constructor(
    private statesService: StatesService,
    private stateAppDataService: StateAppDataService,
    private sessionService: SessionService,
    private userService: UserService,
    private statesDao: StatesDao,
  ) {}

  /**
   * Initialize listener
   */
  init(): void {
    zip([
      this.statesService.getData(),
      this.sessionService.isLoggedIn().pipe(
        switchMap((logged: boolean) =>
          logged
            ? this.userService.getData().pipe(
                filter((user: User) => !!user),
                map((user: User) =>
                  user?.accountStateCode
                    ? user.accountStateCode
                    : environment.geolocation.defaultAnonimousStateCode,
                ),
              )
            : this.statesDao.getStateCodeByIp(),
        ),
      ),
    ])
      .pipe(
        switchMap(([states, stateCode]: [Array<State>, string]) =>
          iif(
            () => !!stateCode,
            of(states.find((state: State) => state.code === stateCode)),
            of(null),
          ),
        ),
      )
      .subscribe((state: State | null) => this.stateAppDataService.setData(state));
  }
}
