import {Serializable} from 'common';

/**
 * An individual multiplier with a label
 */
class ConstrainedMultiplierInternal {
  label: string;

  value: number;

  enabled: boolean;

  default: boolean;
}

export class ConstrainedMultiplier extends Serializable(
  ConstrainedMultiplierInternal,
) {
  static createFromBackend(obj: any) {
    const mult = new ConstrainedMultiplier();
    mult.label = obj.label;
    mult.value = obj.value;
    // by default set enabled. For compatibility with old games where don't exist this property
    mult.enabled = obj.hasOwnProperty('enabled') ? obj.enabled : true;
    mult.default = obj.hasOwnProperty('default') ? obj.default : null;

    return mult;
  }
}
