import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoComponent} from './video.component';
import {VideoModalComponent} from './video-modal/video-modal.component';
import {LoadingModule} from '../loading/loading.module';

@NgModule({
  declarations: [VideoComponent, VideoModalComponent],
  imports: [CommonModule, LoadingModule],
  exports: [VideoComponent],
})
export class VideoModule {}
