import {DOCUMENT} from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  Input,
  NgZone,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {NgbTooltip, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {filter, first, takeUntil} from 'rxjs/operators';

import {Destroyable} from '../util/destroyable';

import {TOOLTIP_CONFIG, TooltipConfig} from './tooltip-config';
import {ALLTOOLTIPID, TooltipService} from './tooltip.service';
import {TranslatableText} from '../i18n/translatable-text';
import {TranslationsService} from '../i18n/translations.service';

@Directive({selector: '[tlTooltip]', exportAs: 'tlTooltip'})
export class TooltipDirective extends NgbTooltip implements OnInit {
  @Input()
  id: string;

  @Input()
  autoShow = false;

  @Input()
  set tlTooltip(value: string | TemplateRef<any> | TranslatableText) {
    if (value) {
      if (this.isTranslatableText(value)) {
        const translationKey = value.key.toString();
        this.translationService
          .getTranslation(translationKey)
          .pipe(first())
          .subscribe((text: string) => {
            this.ngbTooltip = text;
          });
      } else {
        this.ngbTooltip = value as string | TemplateRef<any>;
      }
    }
  }

  @Destroyable()
  private destroy = new Subject();

  constructor(
    appRef: ApplicationRef,
    cdr: ChangeDetectorRef,
    componentFactoryResolver: ComponentFactoryResolver,
    config: NgbTooltipConfig,
    @Inject(DOCUMENT) document: Document,
    element: ElementRef,
    injector: Injector,
    renderer: Renderer2,
    private tooltipService: TooltipService,
    @Inject(TOOLTIP_CONFIG) tooltipConfig: TooltipConfig,
    viewContainerRef: ViewContainerRef,
    zone: NgZone,
    private translationService: TranslationsService,
  ) {
    super(
      element,
      renderer,
      injector,
      componentFactoryResolver,
      viewContainerRef,
      config,
      zone,
      document,
      cdr,
      appRef,
    );

    // Default config, can be overrided with input params.
    this.tooltipClass = tooltipConfig.tooltipClass;
    this.container = 'body';
    this.triggers = 'manual';
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (!this.id) throw new Error('Tooltip id is required');
    if (this.autoShow) {
      this.open();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.autoShow) {
      this.close();
    }
  }

  ngOnInit(): void {
    if (!this.id) {
      throw new Error('Tooltip id is required');
    }
    super.ngOnInit();
    this.tooltipService.listener
      .pipe(
        filter(id => id === this.id),
        takeUntil(this.destroy),
      )
      .subscribe(() => this.open());

    this.tooltipService.closeListener
      .pipe(
        filter(id => id === this.id || id === ALLTOOLTIPID),
        takeUntil(this.destroy),
      )
      .subscribe(() => this.close());
  }

  isTranslatableText(value: any): value is TranslatableText {
    return (
      typeof value !== 'string' &&
      value !== null &&
      typeof value === 'object' &&
      'key' in value
    );
  }
}
