import {Serializable} from 'common';

class SubscribableJackpotIncrementInternal {
  from: number;

  to: number;

  increment: number;
}

export class SubscribableJackpotIncrement extends Serializable(
  SubscribableJackpotIncrementInternal,
) {
  static createFromBackend(obj: any): SubscribableJackpotIncrement {
    let sji: SubscribableJackpotIncrement = new SubscribableJackpotIncrement();
    sji.from = obj?.from;
    sji.to = obj?.to;
    sji.increment = obj?.increment;
    return sji;
  }
}
