import {Serializable, SerializableDisableWarning} from 'common';

export class UnfinishedPlayInternal {
  id: number;

  @SerializableDisableWarning()
  savedPlay: any;

  gameId: string;

  raffleId: number;

  type: 'club' | 'play';
}

export class UnfinishedPlay extends Serializable(UnfinishedPlayInternal) {
  static createFromBackend(obj: any): UnfinishedPlay {
    let up = new UnfinishedPlay();

    up.id = obj.id;
    up.gameId = obj.sorteo.juego;
    up.raffleId = obj.sorteo.id;
    up.parsePayload(obj.play);

    return up;
  }

  private parsePayload(data: string): void {
    const json = JSON.parse(data) as any;
    this.savedPlay = json;

    if (
      json.hasOwnProperty('participaciones') ||
      json.hasOwnProperty('numParticipaciones')
    ) {
      this.type = 'club';

      if (json.hasOwnProperty('numParticipaciones')) {
        this.savedPlay = {participaciones: [json]};
      }
    } else {
      this.type = 'play';
    }
  }
}
