import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {TranslatableText} from '../translatable-text';
import {Router} from '@angular/router';
import {UriMapper} from '../../uri/uri-mapper';

@Component({
  selector: 'tl-translatable-text',
  templateUrl: './translatable-text.component.html',
  styleUrls: ['./translatable-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslatableTextComponent implements AfterViewInit {
  @Input()
  text: string | TranslatableText = '';

  constructor(
    private router: Router,
    private ref: ElementRef,
    private uriMapper: UriMapper,
  ) {}

  ngAfterViewInit(): void {
    let anchors = [...this.ref.nativeElement.querySelectorAll('a')];
    anchors = anchors
      .filter(a => a.hasAttribute('data-href'))
      .filter(a => this.uriMapper.isInternalRoute(a.getAttribute('data-href')));
    anchors.forEach(a =>
      a.addEventListener('click', e => {
        e.preventDefault();
        const url = new URL(a.href);
        this.router.navigateByUrl(url.pathname);
      }),
    );
  }
}
