import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';

import {Endpoint} from './endpoint';

@Injectable({providedIn: 'root'})
export class EndpointService extends AbstractObservableDataService<Endpoint> {
  constructor() {
    super();
  }
}
