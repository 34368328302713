import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router';
import {Logger, ResponsiveService} from 'common';
import {Observable} from 'rxjs';

import {PlatformRoutesService} from './platform-routes.service';

@Injectable()
export class PlatformGuard implements CanActivate, CanActivateChild {
  constructor(
    private logger: Logger,
    private platformRoutesService: PlatformRoutesService,
    private responsiveService: ResponsiveService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const canGo = this.responsiveService.isDesktop() !== state.url.startsWith('/m/');

    if (!canGo) {
      // When clicking a link to tulotero.xx from outside (landing/fb/twitter...) it
      // opens the pwa and redirects to the home on load (/m/juegos) if the back
      // physical button is pressed, it tries to route to '/' (or /?pid=whatever) so
      // don't log it as it's not relevant.
      if (state.url !== '/' && !state.url.startsWith('/?')) {
        this.logger.warn(
          `Tried to route to ${state.url} when isDesktop ` +
            `is ${this.responsiveService.isDesktop()}`,
        );
      }

      this.platformRoutesService.navigateAdyacentTo(state.url);
    }

    return canGo;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
