import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {State} from './state';

@Injectable({
  providedIn: 'root',
})
export class StateAppDataService extends AbstractObservableDataService<State> {
  constructor() {
    super();
  }
}
