import {Serializable} from 'common';

import {GroupRole} from './group-role.enum';

export class GroupMemberInternal {
  id: number;
  name: string;
  shortName: string;
  picture: string;
  initials: string;
  role: GroupRole;
  registered: boolean;
  creditRequired: boolean;
  totalCredit: number;

  get isAdmin(): boolean {
    return this.role === GroupRole.ADMIN;
  }

  get isActive(): boolean {
    return !this.creditRequired;
  }
}

export class GroupMember extends Serializable(GroupMemberInternal) {
  static createFromBackend(obj: any): GroupMember {
    let g = new GroupMember();

    g.id = obj.clientId;
    g.shortName = obj.nameShort;
    g.name = obj.name;
    g.picture = obj.pictureUrl;
    g.initials = obj.initials;
    g.role = obj.role;
    g.registered = obj.userRegistered;
    g.creditRequired = obj.creditRequired;
    g.totalCredit = obj.amountCredit;

    return g;
  }
}
