import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tl-select-booth-confirmation',
  templateUrl: './select-booth-confirmation.component.html',
  styleUrls: ['./select-booth-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectBoothConfirmationComponent {
  @HostBinding('class.tl-select-booth-confirmation')
  readonly hostClass = true;
}
