import {Injectable} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Logger} from '../../logger/logger';
import {Shareable} from '../shareable';
import {ShareFilesNotAllowedException} from './share-files-not-allowed-exception';
import {ShareApiNotAvailableException} from './share-api-not-available-exception';

@Injectable()
export class ShareAPIService implements Shareable {
  notify = false;

  constructor(private logger: Logger) {}

  static isAvailable(files?: Array<File>): boolean {
    try {
      const payload = {url: '', title: '', text: ''};

      if (files && files.length) {
        payload['files'] = files;
      }

      return (
        !!(<any>navigator).canShare &&
        (<any>navigator).canShare(payload) &&
        (<any>navigator).share !== undefined
      );
    } catch {
      return false;
    }
  }

  share(title: string, text: string, files: Array<File>): Observable<any> {
    return new Observable(observer => {
      let payload = {
        title: title || '',
        text: text || '',
      };
      if (!ShareAPIService.isAvailable()) {
        this.logger.error("Tried to share via ShareAPI when it's not supported");
        observer.error(new ShareApiNotAvailableException());
      }
      if (!!files) {
        if (ShareAPIService.isAvailable(files)) {
          payload['files'] = files;
        } else {
          this.logger.error(
            "Tried to share files via ShareAPI when it's not supported",
          );
          observer.error(new ShareFilesNotAllowedException());
        }
      }
      observer.next(
        from((navigator as any).share(payload)).pipe(
          catchError(e => {
            if (typeof e === 'object' && e.name === 'AbortError') {
              return of(true);
            } else {
              throwError(() => e);
            }
          }),
        ),
      );
    });
  }
}
