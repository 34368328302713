import {NgModule} from '@angular/core';
import {PropagateParamsUriMapResolver, URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    groups: [
      {
        originUrl: environment.internalUrls.group,
        destinationPlatformUrl: {
          desktop: `/`,
          mobile: `/m/${routeMap.groups.base}/:groupId`,
        },
        pattern: /tulotero:\/\/groups\/([0-9]+)$/,
        resolver: PropagateParamsUriMapResolver,
      },
      {
        originUrl: environment.internalUrls.groupChat,
        destinationPlatformUrl: {
          desktop: `/`,
          mobile: `/m/${routeMap.groups.base}/:groupId/${routeMap.groups.chat}`,
        },
        pattern: /tulotero:\/\/groups\/([0-9]+)\/chat/,
        resolver: PropagateParamsUriMapResolver,
      },
    ],
  },
];

@NgModule({
  providers: [
    PropagateParamsUriMapResolver,
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class GroupUriMappingModule {}
