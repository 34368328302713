import {Clonable, deepClone, Serializable} from 'common';

class TlBankAccountInternal implements Clonable {
  name: string;

  account: string;

  standardAccount: string;

  clone(): any {
    return deepClone(this);
  }

  toBackend() {
    return {
      description: this.name,
      account: this.account,
      standardAccount: this.standardAccount,
    };
  }
}

export class TlBankAccount extends Serializable(TlBankAccountInternal) {
  public static createFromBackend(obj: any): any {
    let j = new TlBankAccount();

    j.name = obj.description;
    j.account = obj.account;
    j.standardAccount = obj.standardAccount;

    return j;
  }
}
