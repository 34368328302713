export class UriError {
  message: string;

  uri: string;

  name: string;

  constructor(message: string, uri: string) {
    this.name = 'UriError';
    this.message = message;
    this.uri = uri;
  }
}
