import {
  MessageContext,
  MessageHook,
  Serializable,
  SerializableProperty,
  SerializableType,
} from 'common';

import {Filter} from '../../games/game-filter/data/filter';
import {KycMethod} from '../../kyc/data/kyc-method';

import {ActionButtons} from './action-buttons';
import {ClubInfo} from './club-info';
import {CreditCardOptions} from './credit-card-options';
import {DepositAccount} from './deposit-account';
import {PaymentMethod} from './payment-method';
import {TlBankAccount} from './tl-bank-account';
import {GroupUsersInfo} from './group-users-info';
import {GeolocationContext} from './geolocationContext';
import {environment} from '~environments/environment';
import {TicketInfoUrl} from './ticket-info';
import {TransferOptions} from './transfer-options';

class EndpointInternal {
  @SerializableProperty(ActionButtons, SerializableType.OBJECT)
  actionButtons: ActionButtons;

  allowBooths: boolean;

  allowClubs: boolean;

  allowGroups: boolean;

  @SerializableProperty(GroupUsersInfo, SerializableType.OBJECT)
  groupUsersInfo: GroupUsersInfo;

  @SerializableProperty(ClubInfo, SerializableType.OBJECT)
  clubInfo: ClubInfo;

  contactMail: string;

  contactPhone: string;

  contactCompaniesEmail: string;

  @SerializableProperty(CreditCardOptions, SerializableType.OBJECT)
  creditCardOptions: CreditCardOptions;

  @SerializableProperty(TransferOptions, SerializableType.OBJECT)
  transferOptions: TransferOptions;

  @SerializableProperty(DepositAccount)
  depositAccounts: Array<DepositAccount>;

  emptyMovementsImage: string;

  facebookHandler: string;

  fixedGameFilters: Array<string>;

  fixedResultFilters: Array<string>;

  fixedTicketFilters: Array<string>;

  @SerializableProperty(Filter)
  gameFilters: Array<Filter>;

  instagramHandler: string;

  @SerializableProperty(KycMethod)
  kycMethods: Array<KycMethod>;

  legalAddress: string;

  legalName: string;

  locale: string;

  @SerializableProperty(MessageHook, SerializableType.MAP)
  messageHooks: Map<MessageContext, MessageHook>;

  // It will redirect the user to the right app
  // Link to the app shared by web, android and iOS
  oneLink: string;

  @SerializableProperty(Filter)
  resultFilters: Array<Filter>;

  scannerImage: string;

  securePaymentImage: string;

  sponsorReward: number;

  termsOfServiceUrl: string;

  responsibleGameUrl: string;

  responsibleGame18Url: string;

  lotteryScamsUrl?: string;

  @SerializableProperty(Filter)
  ticketFilters: Array<Filter>;

  @SerializableProperty(TlBankAccount)
  tlAccounts: Array<TlBankAccount>;

  twitterHandler: string;

  rulesBaseUrl: string;

  geolocation: Array<string>;

  suffixesForName: Array<string>;

  paymentMethods: Array<PaymentMethod>;

  selfExclusionUrl: string;

  termsLimitLoadUrl: string;

  ticketInfoUrl: TicketInfoUrl;

  authorizedAgentImage: string;

  tiktokHandler: string;

  hasDepositButtons(): boolean {
    return (
      this.actionButtons &&
      Array.isArray(this.actionButtons.depositButtons) &&
      !!this.actionButtons.depositButtons.length &&
      Array.isArray(this.depositAccounts) &&
      !!this.depositAccounts.length
    );
  }

  isGeolocationRequiredFor(context: GeolocationContext): boolean {
    return this.geolocation.includes(context);
  }

  hasKyc(): boolean {
    return this.kycMethods?.length > 0;
  }
}

export class Endpoint extends Serializable(EndpointInternal) {
  static createFromBackend(obj: any) {
    let endpoint = new Endpoint();

    endpoint.locale = obj.serverLocale.replace('_', '-');
    endpoint.allowClubs = obj.penyas;
    endpoint.allowGroups = obj.groups;
    endpoint.groupUsersInfo = GroupUsersInfo.createFromBackend(obj.groupUsersInfo);
    endpoint.allowBooths = obj.administrations;
    endpoint.sponsorReward = obj.sponsorAmount;
    endpoint.termsOfServiceUrl = obj.terms;
    endpoint.responsibleGameUrl = obj.responsibleGame;
    endpoint.termsLimitLoadUrl = obj.termsLimitLoad;
    endpoint.responsibleGame18Url = obj.responsibleGame18;
    endpoint.lotteryScamsUrl = obj.lotteryScamsUrl;
    endpoint.twitterHandler = obj.twitter;
    endpoint.facebookHandler = obj.facebook;
    endpoint.instagramHandler = obj.instagram;
    endpoint.tiktokHandler = obj.tiktok;
    endpoint.contactPhone = obj.contactPhone;
    endpoint.contactCompaniesEmail = obj.contactCompaniesEmail;
    endpoint.contactMail = obj.contactMail;
    endpoint.legalAddress = obj.legalAddress;
    endpoint.legalName = obj.legalName;
    endpoint.oneLink = obj.oneLink;
    endpoint.scannerImage = obj.imgScanner;
    endpoint.geolocation = obj.geolocation || [];
    if (
      environment.geolocation.enableAppState &&
      !environment.geolocation.reduceLocationAddPayment &&
      !endpoint.geolocation.includes(GeolocationContext.ADD_PAYMENT)
    ) {
      endpoint.geolocation.push(GeolocationContext.ADD_PAYMENT);
    }
    endpoint.suffixesForName = obj.suffixesForName;
    endpoint.selfExclusionUrl = obj.selfExclusionUrlInfo;
    endpoint.authorizedAgentImage = obj.authorizedAgentImage;

    endpoint.fixedTicketFilters = [];
    Object.getOwnPropertyNames(obj.tickets)
      .filter(name => name !== 'filters')
      .forEach(name => {
        if (obj.tickets[name]) {
          endpoint.fixedTicketFilters.push(name);
        }
      });

    endpoint.rulesBaseUrl = obj.rulesGamesBaseAndroid;
    endpoint.ticketInfoUrl = obj.ticketInfoUrl;

    endpoint.fixedResultFilters = [];
    Object.getOwnPropertyNames(obj.results)
      .filter(name => name !== 'filters')
      .forEach(name => {
        if (obj.results[name]) {
          endpoint.fixedResultFilters.push(name);
        }
      });

    endpoint.ticketFilters = obj.tickets.filters.map(filter =>
      Filter.createFromBackend(filter),
    );

    endpoint.resultFilters = obj.results.filters.map(filter =>
      Filter.createFromBackend(filter),
    );

    endpoint.tlAccounts = [];
    if (obj.credit && obj.credit.bankAccounts) {
      for (let account of obj.credit.bankAccounts) {
        endpoint.tlAccounts.push(TlBankAccount.createFromBackend(account));
      }
    }

    if (obj.credit && obj.credit.creditCard) {
      endpoint.creditCardOptions = CreditCardOptions.createFromBackend(
        obj.credit.creditCard,
      );
    }

    if (obj.credit && obj.credit.transfer) {
      endpoint.transferOptions = TransferOptions.createFromBackend(
        obj.credit.transfer,
      );
    }

    if (obj.credit) {
      endpoint.securePaymentImage = obj.credit.securePayImageUrl;
      endpoint.emptyMovementsImage = obj.credit.emptyMovementsImageUrl;
      endpoint.messageHooks = new Map();

      if (obj.credit.warningMessageCredit) {
        endpoint.messageHooks.set(
          'tpv',
          MessageHook.createFromBackend(obj.credit.warningMessageCredit),
        );
      }

      if (obj.credit.warningMessageWithdraw) {
        endpoint.messageHooks.set(
          'withdraw',
          MessageHook.createFromBackend(obj.credit.warningMessageWithdraw),
        );
      }
    }

    if (obj.credit.depositAccounts && obj.credit.depositAccounts.length) {
      endpoint.depositAccounts = obj.credit.depositAccounts.map(recipient =>
        DepositAccount.createFromBackend(recipient),
      );
    }

    endpoint.actionButtons = obj.actionButtons
      ? ActionButtons.createFromBackend(obj.actionButtons)
      : undefined;

    endpoint.fixedGameFilters = [];
    if (!!obj.integrators) {
      Object.getOwnPropertyNames(obj.integrators)
        .filter(name => name !== 'filters')
        .forEach(name => {
          if (obj.integrators[name]) {
            endpoint.fixedGameFilters.push(name);
          }
        });

      if (obj.integrators.filters) {
        endpoint.gameFilters = obj.integrators.filters.map(filter =>
          Filter.createFromBackend(filter),
        );
      }
    }

    endpoint.clubInfo = obj.penyasInfo
      ? ClubInfo.createFromBackend(obj.penyasInfo)
      : undefined;

    endpoint.kycMethods = [];
    if (obj.kycMethods && obj.kycMethods.length) {
      endpoint.kycMethods = obj.kycMethods.map(kycMethod =>
        KycMethod.createFromBackend(kycMethod),
      );
    }

    if (obj.paymentMethods) {
      endpoint.paymentMethods = obj.paymentMethods.map(paymentMethod =>
        PaymentMethod.createFromBackend(paymentMethod),
      );
    }

    // TODO remove environment.id === 'pt' when backend remove phone in USA STAGE
    if (obj.credit && obj.credit.phone && environment.id === 'pt') {
      // MB Way | Bizum
      if (!endpoint.paymentMethods) {
        endpoint.paymentMethods = [];
      }
      endpoint.paymentMethods.push(PaymentMethod.createFromBackend(obj, obj.credit));
    }
    return endpoint;
  }
}
