import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractObservableDataService, CustomError, switchMapArray} from 'common';
import {Observable, from, of} from 'rxjs';
import {first, map, reduce, switchMap} from 'rxjs/operators';

import {GameMetadata} from '../../game-metadata/data/game-metadata';
import {GameMetadataService} from '../../game-metadata/data/game-metadata.service';

import {UpcomingGame} from './upcoming.game';
import {UpcomingGamesProcessDataService} from './upcoming-games-process-data.service';
import {UpcomingGamesGroupsProcessDataService} from './upcoming-games-groups-process-data.service';

export class GameNotFoundError extends CustomError {
  data: Record<string, any>;

  constructor(message: string, data: Record<string, any>) {
    super(message);
    this.name = 'GameNotFoundError';
    this.data = data;
  }
}

@Injectable({providedIn: 'root'})
export class UpcomingGamesService extends AbstractObservableDataService<
  Array<UpcomingGame>
> {
  constructor(
    private gameMetadataService: GameMetadataService,
    @Inject(UpcomingGamesProcessDataService)
    @Optional()
    private processDataServices: Array<UpcomingGamesProcessDataService>,
    @Inject(UpcomingGamesGroupsProcessDataService)
    @Optional()
    private groupsProcessDataServices: Array<UpcomingGamesGroupsProcessDataService>,
  ) {
    super();
  }

  getData({process = true}: {process?: boolean} = {}): Observable<
    Array<UpcomingGame>
  > {
    const processData = process ? this.processDataServices : undefined;
    return super
      .getData()
      .pipe(switchMap(data => switchMapArray(data, processData, 'processList')));
  }

  getGamesByGameId(gameId: string): Observable<Array<UpcomingGame>> {
    return this.getData({process: false}).pipe(
      map(games => games.filter(game => game.gameId === gameId)),
    );
  }

  getGameByRaffleId(raffleId: number): Observable<UpcomingGame> {
    return this.getData({process: false}).pipe(
      map(games => {
        const found = games.find(game => game.raffleId === raffleId);
        if (!found) {
          throw new GameNotFoundError(`Game with raffleId: ${raffleId} not found.`, {
            available: games.map(g => [g.raffleId, g.gameId]),
          });
        } else {
          return found;
        }
      }),
    );
  }

  getGamesByJackpotRanking(): Observable<Array<UpcomingGame>> {
    return this.getData().pipe(
      map(games =>
        games
          .filter(game => game.isAvailable() && !!game.jackpot)
          .sort((g1, g2) => g2.jackpot - g1.jackpot),
      ),
    );
  }

  /**
   * Check if two raffles has same metadata version
   *
   * Check if two raffles has same metadata version.
   * Will return true if any of the games is not found.
   *
   * @param raffleId1 raffle id 1
   * @param raffleId2 raffle id 2
   * @return Observable<boolean>
   */
  hasSameMetadataVersion(raffleId1: number, raffleId2: number): Observable<boolean> {
    return this.getGameByRaffleId(raffleId1).pipe(
      first(),
      switchMap(r1 =>
        this.getGameByRaffleId(raffleId2).pipe(
          first(),
          map(r2 => [r1, r2]),
        ),
      ),
      map(([r1, r2]) => !r1 || !r2 || r1.gameVersion === r2.gameVersion),
    );
  }

  /**
   * Check if two raffles has different metadata version
   *
   * @param raffleId1 raffle id 1
   * @param raffleId2 raffle id 2
   * @return Observable<boolean>
   */
  hasDifferentMetadataVersion(
    raffleId1: number,
    raffleId2: number,
  ): Observable<boolean> {
    return this.hasSameMetadataVersion(raffleId1, raffleId2).pipe(
      map(result => !result),
    );
  }

  getGroupsDistinctGames(): Observable<Array<UpcomingGame>> {
    return this.getDistinctGames(this.groupsProcessDataServices);
  }

  getDistinctGames(
    process?: Array<UpcomingGamesProcessDataService>,
  ): Observable<Array<UpcomingGame>> {
    return this.getData().pipe(
      switchMap(data =>
        !process ? of(data) : switchMapArray(data, process, 'processList'),
      ),
      switchMap((games: Array<UpcomingGame>) =>
        this.generateTupleGameMetadata(games),
      ),
      map((dataList: Array<{game: UpcomingGame; metadata: GameMetadata}>) => {
        let gamesToShow: Array<UpcomingGame> = [];
        let visited: Map<string, UpcomingGame> = new Map<string, UpcomingGame>();

        dataList.forEach(data => {
          if (data.metadata.showOnlyOneDraw) {
            const visitedGame = visited.get(data.game.gameId);
            const skipGame = this.checkSkip(data.game, dataList);
            if (!data.game.isBookingMode() && !skipGame) {
              if (!visited.has(data.game.gameId)) {
                visited.set(data.game.gameId, data.game);
                gamesToShow.push(data.game);
              } else if (
                visitedGame.isRaffleCelebrated() &&
                data.game.isAfterOpenning()
              ) {
                // replace celebrated raffle with an open one if same game
                visited.set(data.game.gameId, data.game);
                gamesToShow.splice(gamesToShow.indexOf(visitedGame), 1);
                gamesToShow.push(data.game);
              } else if (
                !visitedGame.isRaffleCelebrated() &&
                visitedGame.isClosed()
              ) {
                // add next to close
                visited.set(data.game.gameId, data.game);
                gamesToShow.push(data.game);
              }
            }
          } else {
            if (!data.game.isRaffleCelebrated() || data.game.highlightedImage) {
              // if the game is already celebrated but highlighted add it too
              // (for 'check' in christmas lottery)
              gamesToShow.push(data.game);
            }
          }
        });

        return gamesToShow;
      }),
    );
  }

  private generateTupleGameMetadata(
    upcomingGames: Array<UpcomingGame>,
  ): Observable<Array<{game: UpcomingGame; metadata: GameMetadata}>> {
    return from(upcomingGames).pipe(
      switchMap(game =>
        this.gameMetadataService.getGameMetadata(game.gameId, game.gameVersion).pipe(
          first(),
          map(metadata => <any>{game: game, metadata: metadata}),
        ),
      ),
      reduce((all, data) => all.concat([data]), []),
    );
  }

  /** Check if skip add a upcomming game in list
   *  case 1: game is closed and exist other game openen
   */
  private checkSkip(
    game: UpcomingGame,
    list: Array<{game: UpcomingGame; metadata: GameMetadata}>,
  ): boolean {
    // case 1:
    const firstGameOpened = list
      .map(g => g.game)
      .filter(
        currentGame =>
          currentGame.gameId === game.gameId && // same type
          currentGame.isAfterOpenning() && // is open
          !this.isSpecialGame(currentGame) && // not a special
          currentGame !== game, //not be self
      )
      .sort((a, b) => a.raffleDate - b.raffleDate)[0];

    // skip when next game is aviable
    return !!game.isClosed() && !this.isSpecialGame(game) && !!firstGameOpened;
  }

  /** Determines if a game is a special in same gametype */
  private isSpecialGame(upcomingGame: UpcomingGame): boolean {
    return upcomingGame.uiMetadata?.navidad || upcomingGame.uiMetadata?.ninyo;
  }
}
