import {Injectable} from '@angular/core';
import {map, Observable, of, switchMap} from 'rxjs';
import {NotificationsProcessDataService} from './notifications-process-data.service';
import {Preference} from './preference';
import {State} from '../../states/data/state';
import {StateNotificationsService} from '../../states/data/state-notifications.service';

@Injectable()
export class NotificationsProcessStatesService
  implements NotificationsProcessDataService
{
  constructor(private stateNotificationsDataService: StateNotificationsService) {}

  processList(list: Array<Preference>): Observable<Array<Preference>> {
    return of(list).pipe(
      switchMap((dataList: Array<Preference>) =>
        this.filterNotificationsByState(dataList),
      ),
    );
  }

  private filterNotificationsByState(
    dataList: Array<Preference>,
  ): Observable<Array<Preference>> {
    return this.stateNotificationsDataService.getState().pipe(
      map(
        (state: State): Array<Preference> =>
          !!state
            ? dataList.filter(preference => {
                if (preference.categoryTree && preference.categoryTree.groupKey) {
                  // * represent multi-state games,
                  return ['*', state.code].includes(
                    preference.categoryTree.groupKey,
                  );
                }
                return true;
              })
            : dataList,
      ),
    );
  }
}
