import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {getYear, isToday, setYear} from 'date-fns';

import {LocaleService} from '../../i18n/locale.service';
import {MessageHookStorageService} from '../../message-hooks/message-hook-storage.service';

@Component({
  selector: 'tl-happy-birthday',
  templateUrl: './happy-birthday.component.html',
  styleUrls: ['./happy-birthday.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HappyBirthdayComponent {
  @Input()
  birthDate: number;

  constructor(
    private localeService: LocaleService,
    private messageHookStorageService: MessageHookStorageService,
  ) {}

  get showAnimation(): boolean {
    const currentYear = getYear(new Date());
    const isBirthday = isToday(setYear(this.birthDate, currentYear));
    if (
      isBirthday &&
      !this.messageHookStorageService.exists(
        `BIRTHDAY_ANIMATION_SHOWN_${currentYear}`,
      )
    ) {
      this.messageHookStorageService.save(`BIRTHDAY_ANIMATION_SHOWN_${currentYear}`);
      return true;
    }
    return false;
  }

  get birthdayBalloon(): string {
    const locale = this.localeService.getLocale().split('-')[0];
    return `assets/img/birthday/balloon-happy-birthday-${locale}.svg`;
  }
}
