import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {I18nModule} from '../../i18n/i18n.module';
import {ScrollableModule} from '../../scrolling/scrollable/scrollable.module';

import {PageComponent} from './page.component';
import {ResponsiveUtilsModule} from '../../responsive/responsive-utils.module';
import {InfoModule} from '../../info/info.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ResponsiveUtilsModule,
    ScrollableModule,
    InfoModule,
  ],
  exports: [PageComponent],
  declarations: [PageComponent],
})
export class PageModule {}
