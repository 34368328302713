import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {TriggerStyleService} from './trigger-style.service';

/**
 * Somewhere else there should another component emitting events, so this can
 * listen to them.
 */
@Directive({selector: '[tlTriggerStyle]'})
export class TriggerStyleDirective implements OnChanges, OnDestroy {
  @Input('tlTriggerStyle')
  eventName: string;

  @Input('tlTriggerStyleClass')
  styleClass: string;

  private destroySubject = new Subject<void>();

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private triggerStyleService: TriggerStyleService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.styleClass) {
      throw new Error(
        'No class specified for TriggerStyleDirective on' +
          this.element.nativeElement.name,
      );
    }

    if (changes.hasOwnProperty('eventName') && this.eventName) {
      this.destroySubject.next();

      this.triggerStyleService
        .listenEvent(this.eventName)
        .pipe(takeUntil(this.destroySubject))
        .subscribe(state => {
          if (state) {
            this.setStyle();
          } else {
            this.unsetStyle();
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  setStyle() {
    this.renderer.addClass(this.element.nativeElement, this.styleClass);
  }

  unsetStyle() {
    this.renderer.removeClass(this.element.nativeElement, this.styleClass);
  }
}
