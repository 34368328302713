<div class="tl-input-phone-local">
  <input
    class="form-control"
    type="tel"
    autocomplete="off"
    [tlTriggerOnKeyboardOpen]="65"
    [id]="id"
    [disabled]="disabled"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [tabindex]="ngTabindex"
    [ngModel]="inputValue"
    (ngModelChange)="onInput($event)"
    (blur)="onBlur($event)" />
</div>
