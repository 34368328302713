import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {equalPrimitiveArray, isNumeric} from 'common';
import {from} from 'rxjs';
import {groupBy, map, mergeMap, toArray} from 'rxjs/operators';

import {AbstractGameTypeMetadata} from '../../../game-metadata/data/abstract-game-type-metadata';
import {AlphanumericGameTypeMetadata} from '../../../game-metadata/data/alphanumeric-game-type-metadata';
import {BetMetadata} from '../../../game-metadata/data/bet-metadata';
import {BetRuleTypeMetadata} from '../../../game-metadata/data/bet-rule-type-metadata';
import {DigitsBetRuleTypeMetadata} from '../../../game-metadata/data/digits-bet-rule-type-metadata';
import {DigitsGameTypeMetadata} from '../../../game-metadata/data/digits-game-type-metadata';
import {NumbersGameTypeMetadata} from '../../../game-metadata/data/numbers-game-type-metadata';
import {PickedMultiplesBetRuleTypeMetadata} from '../../../game-metadata/data/picked-multiples-bet-rule-type-metadata';
import {RequiredBetRuleTypeMetadata} from '../../../game-metadata/data/required-bet-rule-type-metadata';
import {RequiredTrueBetRuleTypeMetadata} from '../../../game-metadata/data/required-true-bet-rule-type-metadata';
import {RestrictedDigitsBetRuleTypeMetadata} from '../../../game-metadata/data/restricted-digits-bet-rule-type-metadata';
import {SelectionBetRuleTypeMetadata} from '../../../game-metadata/data/selection-bet-rule-type-metadata';
import {SelectionGameTypeMetadata} from '../../../game-metadata/data/selection-game-type-metadata';
import {generateSelectionsArray, multiplierFromMultiples} from '../utils/bet-utils';

import {CombinationForm} from './combination-form';

function isEmpty(value: any) {
  return value == null || value.length === 0;
}

export class TypeValidators {
  static requiredAll(control: AbstractControl): ValidationErrors | null {
    const normalizedValue = Array.isArray(control.value)
      ? control.value
      : [control.value];

    let failValue = control.value;

    return isEmpty(normalizedValue) ||
      normalizedValue.some(v => {
        failValue = v;
        return isEmpty(v);
      })
      ? {required: {actual: failValue}}
      : null;
  }

  static minLengthNotEmpty(min: number, optional = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (optional && isEmpty(control.value)) {
        return null;
      }

      // const normalized = Array.isArray(control.value) ? control.value :
      // [control.value];

      const notEmpties = Array.isArray(control.value)
        ? control.value.reduce((length, next) => length + (isEmpty(next) ? 0 : 1), 0)
        : 0;
      // optional allows all nulls
      if (notEmpties === 0 && optional) {
        return null;
      }
      return notEmpties < min
        ? {minLengthNotEmpty: {requiredLength: min, actualLength: notEmpties}}
        : null;
    };
  }

  static requiredTrue(required: number, optional = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (optional && !control.value.some(v => v === true)) {
        return null;
      }

      const trues = Array.isArray(control.value)
        ? control.value.reduce((length, next) => length + (next === true ? 1 : 0), 0)
        : 0;

      return trues !== required
        ? {requiredTrue: {requiredLength: required, actualLength: trues}}
        : null;
    };
  }

  static elementsInSet(set: Array<any>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmpty(control.value)) {
        return null;
      }

      let normalizedValue = Array.isArray(control.value)
        ? control.value
        : [control.value];

      let values = [].concat.apply([], normalizedValue);

      let failValue = null;
      return values.some(v => {
        failValue = v;
        return !isEmpty(v) && set.indexOf(v) < 0;
      })
        ? {elementsInSet: {allowedSet: set, actual: failValue}}
        : null;
    };
  }

  static elementsInRange(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmpty(control.value)) {
        return null;
      }

      let normalizedValue = Array.isArray(control.value)
        ? control.value
        : [control.value];

      let failValue;
      // noinspection JSUnusedAssignment
      return normalizedValue.some(v => {
        failValue = v;
        return !isNumeric(v) || v < min || v > max;
      })
        ? {elementsInRange: {requiredRange: [min, max], actual: failValue}}
        : null;
    };
  }

  /**
   * Checks that the proper selections are made in the picks field for the given
   * playable field from the combinationForm level.
   */
  static fixedPicksForRule(
    ruleType: PickedMultiplesBetRuleTypeMetadata,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceValue = control.get(ruleType.multiplesSourceType.type.id).value;
      const picksValue = control.get(ruleType.type.id).value;

      const sourceRule = ruleType.multiplesSourceType;
      const requiredPicks = sourceRule.minDoubles + sourceRule.minTriples;

      let pickedDoubles = 0;
      let pickedTriples = 0;
      let totalPicks = 0;

      picksValue.forEach((row, index) => {
        if (row) {
          totalPicks++;

          if (Array.isArray(sourceValue[index])) {
            switch (sourceValue[index].length) {
              case 2:
                pickedDoubles++;
                break;
              case 3:
                pickedTriples++;
                break;
            }
          }
        }
      });

      if (totalPicks !== sourceRule.minDoubles + sourceRule.minTriples) {
        return {fixedPicks: {required: requiredPicks, actual: totalPicks}};
      }

      let errorDoubles =
        pickedDoubles === sourceRule.minDoubles
          ? null
          : {
              requiredDoubles: sourceRule.minDoubles,
              actualDoubles: pickedDoubles,
            };

      let errorTriples =
        pickedTriples === sourceRule.minTriples
          ? null
          : {
              requiredTriples: sourceRule.minTriples,
              actualTriples: pickedTriples,
            };

      if (errorDoubles || errorTriples) {
        return {
          fixedPicks: Object.assign({}, errorDoubles || {}, errorTriples || {}),
        };
      }

      return null;
    };
  }

  static restrictedDigitsForRule(
    ruleType: RestrictedDigitsBetRuleTypeMetadata,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const minLengthErrors = TypeValidators.minLengthNotEmpty(
        ruleType.required,
        false,
      )(control);
      if (minLengthErrors) {
        return minLengthErrors;
      }

      const digits = control.value.clone();

      let foundGroups: Array<number> = null;
      from(digits)
        .pipe(
          groupBy(digit => digit),
          mergeMap(group =>
            group.pipe(
              toArray(),
              map(a => a.length),
            ),
          ),
          toArray(),
        )
        .subscribe(groups => (foundGroups = groups.sort()));

      if (
        ruleType.digitGroups.some(group => equalPrimitiveArray(group, foundGroups))
      ) {
        return null;
      } else {
        return {
          restrictedDigits: {
            groups: {expected: ruleType.digitGroups, actual: foundGroups},
          },
        };
      }
    };
  }

  static allowedMultiplier(
    min: number,
    max: number,
    maxDoubles: number,
    maxTriples: number,
    minDoubles?: number,
    minTriples?: number,
    baseMultiplier?: number,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmpty(control.value) || !control.value.some(value => !isEmpty(value))) {
        return null;
      }

      let multiplier = multiplierFromMultiples(
        generateSelectionsArray(control.value),
        [0, minDoubles, minTriples],
        baseMultiplier,
      );

      return TypeValidators.allowedMultiplierFromValues(
        control.value,
        multiplier,
        min,
        max,
        maxDoubles,
        maxTriples,
        minDoubles,
        minTriples,
      );
    };
  }

  static allowedGlobalBetMultiplier(betMetadata: BetMetadata): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmpty(control.value)) {
        return null;
      }

      if (
        control.get('combinations').invalid ||
        control.get('extraFields').invalid
      ) {
        return null;
      }
      // assume this validator will only be applied for bets with one panel
      let values = [];
      let multiplier = 1;

      const combinationControls = (<FormGroup>control.get('combinations.0'))
        .controls;
      const extraFieldControls = (<FormGroup>control.get('extraFields')).controls;
      Object.keys(combinationControls)
        .filter(key => betMetadata.groupedTypes.has(key))
        // Remove global types that are already in the combination
        .filter(key => !Object.keys(extraFieldControls).includes(key))
        .map(key => [key, combinationControls[key]])
        .concat(
          Object.keys(extraFieldControls)
            .filter(key => betMetadata.groupedTypes.has(key))
            .map(key => [key, extraFieldControls[key]]),
        )
        .filter(
          ([_, fieldControl]: [string, AbstractControl]) =>
            Array.isArray(fieldControl.value) &&
            !TypeValidators.minLengthNotEmpty(fieldControl.value.length)(
              fieldControl,
            ),
        )
        .forEach(([fieldId, fieldControl]: [string, AbstractControl]) => {
          values.push(...fieldControl.value);

          let ruleTypeMD: BetRuleTypeMetadata;

          betMetadata.rules.some(rule => {
            ruleTypeMD = rule.getRuleTypeWithId(fieldId);
            return !!ruleTypeMD;
          });

          if (ruleTypeMD) {
            multiplier *= multiplierFromMultiples(
              generateSelectionsArray(fieldControl.value),
              [0, ruleTypeMD['minDoubles'], ruleTypeMD['minTriples']],
              ruleTypeMD['baseMultiplier'],
            );
          }
        });

      return TypeValidators.allowedMultiplierFromValues(
        values,
        multiplier,
        betMetadata.minMultiplier,
        betMetadata.maxMultiplier,
        betMetadata.maxDoubles,
        betMetadata.maxTriples,
      );
    };
  }

  static requiredMainCombination(fieldIds: Array<string>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmpty(control.value)) {
        return null;
      }

      if (control.get('extraFields').invalid) {
        return null;
      }

      const extraFieldControls = (<FormGroup>control.get('extraFields')).controls;
      const forcedFilled = // see if any forced field is valid and filled
        fieldIds.filter(
          id =>
            extraFieldControls[id] && extraFieldControls[id].value.some(v => !!v),
        );

      if (forcedFilled.length) {
        const firstCombination = (<FormArray>(
          control.get('combinations')
        )).controls.find(combination => (<CombinationForm>combination).index === 0);

        if (firstCombination && firstCombination.valid) {
          return null;
        } else {
          return {requiredMainCombination: {selected: forcedFilled}};
        }
      } else {
        return null;
      }
    };
  }

  static createValidatorsForRule(ruleMD: BetRuleTypeMetadata) {
    if (ruleMD instanceof RestrictedDigitsBetRuleTypeMetadata) {
      return [TypeValidators.restrictedDigitsForRule(ruleMD)];
    } else if (ruleMD instanceof DigitsBetRuleTypeMetadata) {
      return [TypeValidators.minLengthNotEmpty(ruleMD.required, ruleMD.optional)];
    } else if (ruleMD instanceof RequiredTrueBetRuleTypeMetadata) {
      return [TypeValidators.requiredTrue(ruleMD.requiredTrue, ruleMD.optional)];
    } else if (ruleMD instanceof SelectionBetRuleTypeMetadata) {
      return [
        TypeValidators.minLengthNotEmpty(ruleMD.required, ruleMD.optional),
        TypeValidators.allowedMultiplier(
          ruleMD.minMultiplier,
          ruleMD.maxMultiplier,
          ruleMD.maxDoubles,
          ruleMD.maxTriples,
          ruleMD['minDoubles'] || 0,
          ruleMD['minTriples'] || 0,
          ruleMD['baseMultiplier'] || 1,
        ),
      ];
    } else if (ruleMD instanceof RequiredBetRuleTypeMetadata) {
      if (ruleMD.required > 1) {
        return [TypeValidators.minLengthNotEmpty(ruleMD.required, ruleMD.optional)];
      } else {
        return !ruleMD.optional ? [TypeValidators.requiredAll] : [];
      }
    } else {
      return !ruleMD.optional ? [TypeValidators.requiredAll] : [];
    }
  }

  static createValidatorsForType(gameMetadata: AbstractGameTypeMetadata) {
    if (
      gameMetadata.playType === 'BOOLEAN' ||
      gameMetadata.playType === 'BOOLEAN_ARRAY'
    ) {
      return [Validators.required];
    } else if (gameMetadata instanceof SelectionGameTypeMetadata) {
      return [
        TypeValidators.elementsInSet(
          gameMetadata.choices.map(choice => choice.value),
        ),
      ];
    } else if (gameMetadata instanceof NumbersGameTypeMetadata) {
      return [TypeValidators.elementsInRange(gameMetadata.min, gameMetadata.max)];
    } else if (gameMetadata instanceof DigitsGameTypeMetadata) {
      return [TypeValidators.elementsInRange(gameMetadata.min, gameMetadata.max)];
    } else if (gameMetadata instanceof AlphanumericGameTypeMetadata) {
      // TODO · UNKOWN FEATURE · add validator if type ends up with allowedvalues
      return [];
    } else if (!gameMetadata.optional) {
      return [];
    } else {
      throw new Error(
        'Unknown or unsupported GameTypeMetadata: ' + JSON.stringify(gameMetadata),
      );
    }
  }

  private static allowedMultiplierFromValues(
    values: Array<any>,
    multiplier: number,
    min: number,
    max: number,
    maxDoubles: number,
    maxTriples: number,
    minDoubles?: number,
    minTriples?: number,
  ): ValidationErrors | null {
    if (!Array.isArray(values)) {
      return {allowedMultiplier: {actual: values}};
    }

    const selections = generateSelectionsArray(values);

    // removed invalidation of multiples bigger than triples for now
    // if (!selections.length || selections.length > 3) {
    //   return {allowedMultiplier: {multiplesLength: selections.length}};
    // }

    let errorContent = [];

    if (selections[1] > maxDoubles) {
      errorContent.push({maxDoubles: maxDoubles, actualDoubles: selections[1]});
    }

    if (isNumeric(minDoubles) && (selections[1] || 0) < minDoubles) {
      errorContent.push({minDoubles: minDoubles, actualDoubles: selections[1]});
    }

    if (selections[2] > maxTriples) {
      errorContent.push({maxTriples: maxTriples, actualTriples: selections[2]});
    }

    if (isNumeric(minTriples) && (selections[2] || 0) < minTriples) {
      errorContent.push({minTriples: minTriples, actualTriples: selections[2]});
    }

    if (errorContent.length > 0) {
      return {
        allowedMultiplier: errorContent.reduce(
          (result, partial) => Object.assign(result, partial),
          {},
        ),
      };
    }

    if (multiplier < min || (isNumeric(max) && multiplier > max)) {
      return {
        allowedMultiplier: {
          minMultiplier: min,
          maxMultiplier: max,
          actualMultiplier: multiplier,
        },
      };
    }

    return null;
  }
}
