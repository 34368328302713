<div class="tl-video-modal__backdrop" (click)="close()">
  <div class="tl-video-modal__container">
    <video
      (click)="$event.stopPropagation(); this.checkFullScreen()"
      (pause)="iosPlayer && checkClose()"
      (ended)="close()"
      class="tl-video-modal__video"
      [src]="url"
      #videoElement
      controls
      autoplay
      playsinline
      (canplay)="loaded = true"
      [hidden]="!loaded"></video>
    <i
      class="tl-video-modal__close icon-plus-circled-icon"
      [class.ios-close-mode]="iosPlayer"
      (click)="close()"
      [hidden]="!loaded"></i>
    <tl-loading *ngIf="!loaded"></tl-loading>
  </div>
</div>
