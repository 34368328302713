import {Injectable} from '@angular/core';
import {filter, first, from, map, Observable, of, switchMap, toArray} from 'rxjs';
import {State} from '../../../../states/data/state';
import {GameMetadata} from '../../../game-metadata/data/game-metadata';
import {GameMetadataService} from '../../../game-metadata/data/game-metadata.service';
import {Filter} from '../../../game-filter/data/filter';
import {GenericResult} from '../../data/generic-result';
import {ResultsListViewProcessDataService} from './results-list-view-process-data.service';
import {StateAppDataService} from '../../../../states/data/state-app-data.service';

@Injectable()
export class ResultsListViewProcessStatesService
  implements ResultsListViewProcessDataService
{
  constructor(
    private gameMetadataService: GameMetadataService,
    private stateAppDataService: StateAppDataService,
  ) {}

  processList(
    list: Array<GenericResult>,
    options?: {
      filter: Filter;
    },
  ): Observable<Array<GenericResult>> {
    // Solo hay que filtrar por estado cuando hay estado y el filtro es el de
    // todos o no hay al tener resultados sin filtrar del allinfo
    return !options?.filter || options?.filter.id === 'all'
      ? of(list).pipe(
          switchMap((results: Array<GenericResult>) =>
            this.filterResultsByState(results),
          ),
        )
      : of(list);
  }

  private filterResultsByState(
    results: Array<GenericResult>,
  ): Observable<Array<GenericResult>> {
    return this.stateAppDataService.getData().pipe(
      switchMap((state: State) =>
        !!state
          ? from(results).pipe(
              switchMap((result: GenericResult) =>
                this.gameMetadataService.getGameMetadata(result.gameId).pipe(
                  first(),
                  map((metadata: GameMetadata) => ({
                    result: result,
                    metadata: metadata,
                  })),
                ),
              ),
              filter(
                ({
                  result,
                  metadata,
                }: {
                  result: GenericResult;
                  metadata: GameMetadata;
                }) =>
                  !!metadata.visibleOnStates.find(
                    (code: string) => code === state.code,
                  ),
              ),
              map(
                ({
                  result,
                  metadata,
                }: {
                  result: GenericResult;
                  metadata: GameMetadata;
                }) => result,
              ),
              toArray(),
            )
          : of(results),
      ),
    );
  }
}
