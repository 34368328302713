import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';

import {LotteryTicket} from '../../../lottery/data/lottery-ticket';
import {LotteryTicketSelection} from '../../../lottery/lottery-ticket-selection';

import {LotteryFormService} from './lottery-form.service';
import {PanelFormService} from './panel-form.service';
import {TooManyTicketsError} from './too-many-tickets-error';
import {AbstractLotteryBetFormService} from './abstract-lottery-bet-form.service';

@Injectable()
export class LotteryBetFormService extends AbstractLotteryBetFormService {
  initialize(
    panelFormService: PanelFormService,
    betForm: FormGroup,
    raffleId: number,
  ): void {
    this.panelFormService = panelFormService;
    this.combinations = <FormArray>betForm.get('combinations');
    this.raffleId = raffleId;

    this.selectedTickets = new Map<string, LotteryTicket>();
    this.selectedTicketsChange.next(this.getSelectedTickets());
    this.savedTickets = new Map<string, LotteryTicket>();

    this.combinations.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(combinations => {
        let amounts = {};
        combinations.forEach(ticket => {
          amounts[ticket.hash] = ticket.amount;
        });

        this.ticketAmounts.next(amounts);
      });
  }

  ticketChange(selection: LotteryTicketSelection, searchAdminId?: string): void {
    const index = this.findFormIndexForTicket(
      this.combinations,
      selection.ticket.hash,
    );

    if (index >= 0) {
      if (selection.amount > 0) {
        this.editTicketAt(index, selection.amount, searchAdminId);
      } else {
        this.removeTicketAt(index);
      }
    } else {
      this.addTicket(selection.ticket, selection.amount, searchAdminId);
    }
  }

  addTicket(ticket: LotteryTicket, amount: number, searchAdminId?: string): void {
    const max = (<LotteryFormService>this.panelFormService).getMaximumNumbers();

    if (this.combinations.length >= max) {
      throw new TooManyTicketsError(max, this.combinations.length);
    }

    const combinationGroup = (<LotteryFormService>(
      this.panelFormService
    )).createCombinationFromTicket(ticket);
    combinationGroup.get('amount').setValue(amount);

    if (searchAdminId) {
      combinationGroup.addControl('searchAdminId', new FormControl(searchAdminId));
    }

    this.combinations.push(combinationGroup);

    this.selectedTickets.set(ticket.hash, ticket);
    this.selectedTicketsChange.next(this.getSelectedTickets());
  }

  editTicketAt(index: number, amount: number, searchAdminId?: string): void {
    const groupForm = this.combinations.at(index);
    groupForm.get('amount').setValue(amount);
    if (groupForm.get('searchAdminId')) {
      groupForm.get('searchAdminId').setValue(searchAdminId);
    }
  }

  protected removeTicketAt(index: number): void {
    const ticket = this.combinations.at(index);

    this.selectedTickets.delete(ticket.value.hash);
    this.selectedTicketsChange.next(this.getSelectedTickets());
    this.combinations.removeAt(index);
  }

  protected findFormIndexForTicket(formArray: FormArray, hash: string): number {
    return formArray.controls.findIndex(c => c.value.hash === hash);
  }
}
