import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {UtilModule} from '../../util/util-module';
import {SelectTlModule} from '../select/select.module';

import {SetTableItemComponent} from './set-table-item.component';
import {SetTableComponent} from './set-table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectTlModule,
    UtilModule,
  ],
  exports: [SetTableComponent, SetTableItemComponent],
  declarations: [SetTableComponent, SetTableItemComponent],
})
export class SetTableModule {}
