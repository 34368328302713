import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {EMPTY, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, takeUntil} from 'rxjs/operators';

import {Logger} from '../logger/logger';
import {Destroyable} from '../util/destroyable';

import {AbstractBarcodeReader} from './abstract-barcode-reader';
import {BarcodeReaderFactory} from './barcode-reader-factory';
import {BarcodeResult} from './barcode-result';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-barcode-reader',
  templateUrl: './barcode-reader.component.html',
  styleUrls: ['./barcode-reader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeReaderComponent implements AfterViewInit, OnDestroy {
  @Output()
  detect = new EventEmitter<BarcodeResult>();

  @Output()
  loadDevice = new EventEmitter<void>();

  @Output()
  loadError = new EventEmitter<void>();

  @Input()
  title: string;

  loading = true;

  @Input()
  showHelp = true;

  @ViewChild('videoBarcodeDecode', {read: ElementRef})
  videoBarcodeDecode: ElementRef;

  error: boolean;

  private barcode: AbstractBarcodeReader;

  @Destroyable()
  private destroySubject = new Subject<void>();

  constructor(
    private barcodeReaderFactory: BarcodeReaderFactory,
    private logger: Logger,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this.barcode.destroy();
  }

  onFeedLoad(success: boolean, loading: boolean): void {
    this.loading = loading;
    this.cdr.markForCheck();

    if (success) {
      this.error = false;
      this.loadDevice.next();
    } else {
      this.error = true;
    }
  }

  ngAfterViewInit() {
    this.barcode = this.barcodeReaderFactory.getInstance();

    this.barcode
      .load(this.videoBarcodeDecode.nativeElement)
      .pipe(
        takeUntil(this.destroySubject),
        catchError(err => {
          this.catchError(err);
          return EMPTY;
        }),
      )
      .subscribe((load: boolean) => {
        this.onFeedLoad(load, false);

        this.barcode
          .read()
          .pipe(distinctUntilChanged(), takeUntil(this.destroySubject))
          .subscribe({
            next: (code: BarcodeResult) => this.detect.next(code),
            error: err => this.catchError(err),
          });
      });
  }

  private catchError(err) {
    this.onFeedLoad(false, false);

    if (!(err instanceof DOMException)) {
      this.logger.error('Error initializing camera feed.', err && err.stack, err);
    }

    this.loadError.emit();
  }
}
