<tl-modal-dialog
  *ngIf="showDialog"
  [type]="type"
  image="assets/img/geolocalizacion/map-states.png"
  [title]="title"
  [accept]="accept"
  [cancel]="cancel"
  [message]="message"
  [loading]="loading"
  [acceptCallBack]="clickAccept"
  [footer]="contactUsFooter"
  [smallText]="true"
  [imageWidth]="96"
  [closeOnNavigation]="true"
  [layout]="modalDialogLayout.VERTICAL"
  class="tl-state-subscribe-dialog__dialog">
  <div *ngIf="!isSubscribeSuccessful" class="tl-state-subscribe-dialog__content">
    <div
      *ngIf="isAnonymous && form"
      class="tl-state-subscribe-dialog__email"
      [formGroup]="form">
      <input
        class="form-control"
        type="email"
        [placeholder]="
          'userProfile.userData.formUserData.requiredData.inputEmail.placeholderDesktop'
            | i18n
        "
        formControlName="email" />
      <div [tlFormMessages]="form.get('email')" [submitted]="submitted">
        <div class="danger" tlFormMessage="required">
          {{ 'suggestions.inputEmail.validation.emailRequired' | i18n }}
        </div>
        <div *ngIf="!!form.value.email" class="danger" tlFormMessage="format">
          {{ 'suggestions.inputEmail.validation.emailNotValid' | i18n }}
        </div>
      </div>
    </div>
    <div *ngIf="error" class="tl-state-subscribe-dialog__error">
      {{ error }}
    </div>
  </div>
  <div class="tl-state-subscribe-dialog__footer--desktop">
    <ng-container *ngTemplateOutlet="contactUsFooter"></ng-container>
  </div>
</tl-modal-dialog>

<ng-template #contactUsFooter>
  <p class="tl-state-subscribe-dialog__footer">
    <tl-translatable-text
      [text]="{
        key: 'payments.anyQuestionsContact'
      }"></tl-translatable-text>
  </p>
</ng-template>
