import {Serializable} from 'common';

class DepositAccountInternal {
  label: string;

  logo: string;

  color: string;

  value: string;

  extraLabel: string;

  extraType: 'image' | 'text';

  extraValue: string;
}

export class DepositAccount extends Serializable(DepositAccountInternal) {
  static createFromBackend(obj: any) {
    let depositRecipient = new DepositAccount();

    depositRecipient.label = obj.description;
    depositRecipient.logo = obj.imageSvg;
    depositRecipient.color = obj.color;
    depositRecipient.value = obj.account;
    depositRecipient.extraLabel = obj.infoExtra
      ? obj.infoExtra.description
      : undefined;
    depositRecipient.extraType = obj.infoExtra
      ? (obj.infoExtra.type || '').toLowerCase()
      : undefined;
    depositRecipient.extraValue = obj.infoExtra ? obj.infoExtra.value : undefined;

    return depositRecipient;
  }
}
