import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToastComponent} from './toast.component';
import {ToastService} from './toast.service';
import {TranslatableTextModule} from '../i18n/translatable-text/translatable-text.module';
import {Overlay} from '@angular/cdk/overlay';

@NgModule({
  declarations: [ToastComponent],
  imports: [CommonModule, TranslatableTextModule],
  exports: [ToastComponent],
  providers: [ToastService, Overlay],
})
export class ToastModule {}
