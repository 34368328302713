import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-slidable-text',
  templateUrl: './slidable-text.component.html',
  styleUrls: ['./slidable-text.component.scss'],
})
export class SlidableTextComponent implements AfterViewInit {
  @Input()
  duration = 6;

  readonly mirrorGapPx = 32;

  @ViewChild('slider', {static: true})
  private slider: ElementRef;

  private mirrorGenerated: HTMLElement;

  constructor(private element: ElementRef, private renderer: Renderer2) {
    this.observeNode();
  }

  ngAfterViewInit() {
    this.setMargin();
  }

  @HostListener('resize')
  /* eslint-disable @typescript-eslint/no-unused-vars */
  resize() {
    this.setMargin();
  }

  public updateMirror() {
    this.generateMirror();
  }

  private observeNode() {
    let nativeObserver = new MutationObserver(() => this.setMargin());
    nativeObserver.observe(this.element.nativeElement, {childList: true});
  }

  private setMargin() {
    const overflow = this.calculateOverflow();
    if (overflow > 0) {
      // this.style('margin-left', '-' + overflow + 'px');
      this.style('animation', `slide ${this.duration}s infinite`);
      this.style('animation-timing-function', `linear`);
      this.style('animation-delay', `1s`);
      if (!this.mirrorGenerated) {
        this.generateMirror();
      }
    } else {
      this.style('margin-left', '0');
      this.style('animation', 'initial');
    }
  }

  private calculateOverflow() {
    return (
      this.slider.nativeElement.offsetWidth - this.element.nativeElement.offsetWidth
    );
  }

  private style(prop: string, value: string) {
    this.renderer.setStyle(this.slider.nativeElement, prop, value);
  }

  private generateMirror() {
    const shadowEl: HTMLElement = this.renderer.createElement('DIV');
    shadowEl.style.display = 'inline-block';
    shadowEl.style.marginLeft = `${this.mirrorGapPx}px`;
    shadowEl.innerHTML = this.slider.nativeElement.innerHTML;
    if (this.mirrorGenerated) {
      this.renderer.removeChild(this.slider.nativeElement, this.mirrorGenerated);
    }
    this.renderer.appendChild(this.slider.nativeElement, shadowEl);
    this.mirrorGenerated = shadowEl;
  }
}
