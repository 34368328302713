import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../../i18n/i18n.module';
import {UtilModule} from '../../util/util-module';

import {SemiModalComponent} from './semi-modal.component';

@NgModule({
  imports: [CommonModule, I18nModule, UtilModule, I18nModule],
  exports: [SemiModalComponent],
  declarations: [SemiModalComponent],
})
export class SemiModalModule {}
