import {NgModule} from '@angular/core';

import {PushPromptModule} from '../content/push-prompt.module';

import {PushPromptDesktopComponent} from './push-prompt-desktop.component';
import {I18nModule} from 'common';

@NgModule({
  imports: [I18nModule, PushPromptModule],
  exports: [PushPromptDesktopComponent],
  declarations: [PushPromptDesktopComponent],
})
export class PushPromptDesktopModule {}
