import {Serializable} from 'common';

class GroupUsersInfoInternal {
  assignableNewAdmin: boolean;

  groupMembersMaxExceededMessage: string;

  maxAdminUsers: number;

  maxUsers: number;
}

export class GroupUsersInfo extends Serializable(GroupUsersInfoInternal) {
  static createFromBackend(obj: any) {
    let groupUsersInfo = new GroupUsersInfo();

    groupUsersInfo.assignableNewAdmin = obj.assignableNewAdmin;
    groupUsersInfo.groupMembersMaxExceededMessage =
      obj.groupMembersMaxExceededMessage;
    groupUsersInfo.maxAdminUsers = obj.maxAdminUsers;
    groupUsersInfo.maxUsers = obj.maxUsers;

    return groupUsersInfo;
  }
}
