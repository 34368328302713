import {Injectable} from '@angular/core';
import {distinctUntilChanged, map, Observable, of, switchMap} from 'rxjs';
import {BannerProcessDataService} from './banner-process-data.service';
import {MessageBanner} from './message-banner';
import {MessageBannerByState} from './message-banner-by-state';
import {State} from '../../states/data/state';
import {StateAppDataService} from '../../states/data/state-app-data.service';

@Injectable()
export class BannerProcessStatesService implements BannerProcessDataService {
  constructor(private stateAppDataService: StateAppDataService) {}

  processList(
    list: Array<MessageBanner | MessageBannerByState>,
  ): Observable<Array<MessageBanner | MessageBannerByState>> {
    return of(list).pipe(
      switchMap((banners: Array<MessageBanner | MessageBannerByState>) =>
        this.filterBannersByState(banners),
      ),
    );
  }

  private filterBannersByState(
    banners: Array<MessageBanner | MessageBannerByState>,
  ): Observable<Array<MessageBanner | MessageBannerByState>> {
    return this.stateAppDataService.getData().pipe(
      distinctUntilChanged(),
      map((state: State) => this.filterBy(banners, state)),
    );
  }

  private filterBy(
    banners: Array<MessageBanner | MessageBannerByState>,
    state: State,
  ): Array<MessageBanner | MessageBannerByState> {
    const findBannersAllStates: Array<MessageBanner | MessageBannerByState> =
      banners.filter(banner => {
        if (banner instanceof MessageBannerByState) {
          return banner.region.includes('ALL');
        }
        return true;
      });
    if (state) {
      const findBannersUserState: Array<MessageBanner | MessageBannerByState> =
        banners.filter(banner => {
          if (banner instanceof MessageBannerByState) {
            return banner.region.includes(state.code);
          }
          return true;
        });
      return !!findBannersAllStates && findBannersAllStates.length
        ? findBannersAllStates
        : findBannersUserState;
    }
    return findBannersAllStates;
  }
}
