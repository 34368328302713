import {padding} from '../util/core/number';

/**
 * Formats the seconds to time format
 *
 * @param seconds number
 * @returns
 */
export function formatMinute(seconds: number) {
  if (seconds > 3600) {
    return `${padding(Math.floor(seconds / 3600), 2)}:${padding(
      Math.floor((seconds / 60) % 60),
      2,
    )}:${padding(seconds % 60, 2)}`;
  }
  return `${padding(Math.floor(seconds / 60), 2)}:${padding(seconds % 60, 2)}`;
}
