import {Injectable} from '@angular/core';

import {MessageHookAsapService} from './data/message-hook-asap.service';
import {ModalMessageHookService} from './modal/modal-message-hook.service';
import {concatMap, from, switchMap} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MessageHookAsapDisplayerService {
  constructor(
    private messageHookAsapService: MessageHookAsapService,
    private modalMessageHookService: ModalMessageHookService,
  ) {}

  init(): void {
    this.messageHookAsapService
      .getData()
      .pipe(
        switchMap(data => {
          return from(data).pipe(
            concatMap(message =>
              this.modalMessageHookService.displayCustomMessage(
                'asap_' + message.id,
                {
                  data: {message: message},
                  avoidCloseAll: true,
                },
              ),
            ),
          );
        }),
      )
      .subscribe();
  }
}
