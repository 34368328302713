import {Serializable, SerializableDisableWarning} from 'common';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';

/**
 * Generic rule type metadata
 */
class BetRuleTypeMetadataInternal {
  optional: boolean;

  @SerializableDisableWarning()
  type: AbstractGameTypeMetadata;
}

export class BetRuleTypeMetadata extends Serializable(BetRuleTypeMetadataInternal) {
  static createFromBackend(obj: any) {
    let ruleTypeMD = new BetRuleTypeMetadata();

    ruleTypeMD.optional = !!obj.optional;

    return ruleTypeMD;
  }
}
