import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../../i18n/i18n.module';

import {MarketLogoComponent} from './market-logo/market-logo.component';

@NgModule({
  declarations: [MarketLogoComponent],
  imports: [CommonModule, I18nModule],
  exports: [MarketLogoComponent],
})
export class MarketModule {}
