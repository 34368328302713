import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'emailLink',
  pure: true,
})
export class EmailLinkPipe implements PipeTransform {
  transform(email: string): unknown {
    if (!email) {
      return email;
    }

    return 'mailto:' + email;
  }
}
