import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {first, switchMap, tap} from 'rxjs/operators';
import {SessionService} from '../../../../user/auth/session.service';
import {CombinationTypeValue} from '../../../combination/data/combination-type-declaration';
import {CombinationValue} from '../../../combination/data/combination-value';
import {LotteryTicketPaginationData} from '../../../lottery/data/lottery-ticket-pagination-data';
import {LotteryDao} from '../../../lottery/data/lottery.dao';

import {AbstractLotterySearch} from './abstract-lottery-search';
import {LotterySearchParams} from './lottery-search-params';
import {LotterySearchType} from './lottery-search-type';

@Injectable()
export class SelaeLotterySearchService extends AbstractLotterySearch {
  totalTickets = 0;

  searchTypes: Array<CombinationTypeValue>;

  companySearch = false;

  includeReservations = false;

  isFiltered = new ReplaySubject<boolean>(1);

  constructor(
    private lotteryDao: LotteryDao,
    private sessionService: SessionService,
  ) {
    super();
    this._data.subscribe(list => (this.paginationIndex = !list ? 0 : list.length));
  }

  loadData(): Observable<LotteryTicketPaginationData> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap(isLoggedIn => {
        if (this.getGameMetadata().upgrade) {
          if (isLoggedIn) {
            return this.lotteryDao.getUserLotteryTickets(
              this.getRaffleId(),
              this.getGameMetadata(),
              this.paginationIndex,
              this.searchTypes,
              this.minNumber,
              this.location,
              this.companySearch,
              this.includeReservations,
            );
          } else {
            return this.lotteryDao.getLotteryTickets(
              this.getRaffleId(),
              this.getGameMetadata(),
              this.paginationIndex,
              this.searchTypes,
              this.minNumber,
              this.location,
              this.companySearch,
              this.includeReservations,
            );
          }
        } else {
          if (isLoggedIn) {
            return this.lotteryDao.searchLotteryTicketsAsUser(
              this.getRaffleId(),
              this.paginationIndex,
              this.getGameMetadata(),
              this.searchTypes,
              this.minNumber,
            );
          } else {
            return this.lotteryDao.searchLotteryTickets(
              this.getRaffleId(),
              this.paginationIndex,
              this.getGameMetadata(),
              this.searchTypes,
              this.minNumber,
            );
          }
        }
      }),
      tap((data: LotteryTicketPaginationData) => {
        this.paginationEnd =
          this.paginationIndex + data.tickets.length >= data.searchableTickets ||
          data.tickets.length === 0;
        this.numberTickets = data.searchableTickets;
        this.totalTickets = data.ticketStock;
      }),
    );
  }

  setSearchParams(params: LotterySearchParams): void {
    this.searchTypes = params?.get(LotterySearchType.VALUE_TYPES) ?? null;
    this.minNumber = params?.get(LotterySearchType.MIN_NUMBER) ?? null;
    this.location = params?.get(LotterySearchType.LOCATION) ?? null;
    this.companySearch = params?.get(LotterySearchType.COMPANY) ?? false;
    this.includeReservations = params?.get(LotterySearchType.RESERVATIONS) ?? false;

    this.updateIsFiltered();
  }

  updateIsFiltered(): void {
    let filtered = false;
    if (this.searchTypes) {
      this.searchTypes.forEach((combinationType: CombinationTypeValue) => {
        combinationType.value.forEach(combinationValue => {
          if (combinationValue instanceof CombinationValue) {
            filtered = filtered || combinationValue.value !== '';
          } else if (combinationValue instanceof Array) {
            combinationValue.forEach((subCombinationValue: CombinationValue) => {
              filtered = filtered || subCombinationValue.value !== '';
            });
          }
        });
      });
    }
    filtered =
      filtered || this.minNumber > 1 || Boolean(this.location) || this.companySearch;

    this.isFiltered.next(filtered);
  }

  getStock(boothId: string): Observable<{stock: number; boothId: string}> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap(isLoggedIn =>
        isLoggedIn
          ? this.lotteryDao.getStockUser(this.raffle, boothId)
          : this.lotteryDao.getStock(this.raffle, boothId),
      ),
    );
  }

  getStockExcludeBooth(
    excludeBooth: string,
    minStock: number,
  ): Observable<{stock: number; boothId: string}> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap(isLoggedIn =>
        isLoggedIn
          ? this.lotteryDao.getStockUserExcludeBooth(
              this.raffle,
              excludeBooth,
              minStock,
            )
          : this.lotteryDao.getStockExcludeBooth(
              this.raffle,
              excludeBooth,
              minStock,
            ),
      ),
    );
  }
}
