import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {Preference} from './preference';

@Injectable({
  providedIn: 'root',
})
export class PreferenceDataService extends AbstractObservableDataService<
  Array<Preference>
> {
  constructor() {
    super();
  }
}
