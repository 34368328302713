import {NgModule} from '@angular/core';

import {FormMessageDirective} from './form-message.directive';
import {FormMessagesDirective} from './form-messages.directive';
import {FormMessageStateDirective} from './form-message-state.directive';

@NgModule({
  exports: [FormMessageDirective, FormMessagesDirective, FormMessageStateDirective],
  declarations: [
    FormMessageDirective,
    FormMessagesDirective,
    FormMessageStateDirective,
  ],
})
export class FormMessagesModule {}
