import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';
import {UserUriMappingResolver} from './user-uri-mapping.resolver';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    datos: [
      {
        originUrl: environment.internalUrls.data,
        destinationPlatformUrl: {
          desktop: `/${routeMap.desktop.profile}`,
          mobile: `/m/${routeMap.mobile.user}/${routeMap.mobile.tuloteroSlides.profile}`,
        },
      },
    ],
    completarDatos: [
      {
        originUrl: environment.internalUrls.completeData,
        destinationPlatformUrl: {
          desktop: `/${routeMap.register}/${routeMap.desktop.user.data}`,
          mobile: `/m/${routeMap.mobile.userData}`,
        },
        resolver: UserUriMappingResolver,
      },
    ],
  },
];

@NgModule({
  providers: [
    UserUriMappingResolver,
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class UserUriMappingModule {}
