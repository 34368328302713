import {Serializable, SerializableProperty, SerializableType} from 'common';

import {CombinationType} from './combination-type';
import {CombinationValue} from './combination-value';

class SelectionCombinationTypeInternal
  implements CombinationType<Array<CombinationValue>>
{
  typeId: string;

  type: string;

  @SerializableProperty(CombinationValue, SerializableType.COLLECTION_OF_COLLECTION)
  value: Array<Array<CombinationValue>>;
}

export class SelectionCombinationType extends Serializable(
  SelectionCombinationTypeInternal,
) {
  static createFromBackend(obj: any) {
    let selectionRT = new SelectionCombinationType();

    selectionRT.typeId = obj.typeId;
    selectionRT.type = obj.type;

    selectionRT.value = obj.value.map(value =>
      value.selections.map(selection =>
        CombinationValue.createFromBackend(selection),
      ),
    );

    return selectionRT;
  }
}
