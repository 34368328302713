export function compile(sentence: string, data: {[key: string]: any}): string {
  let compiled = sentence;

  for (let id in data) {
    if (data.hasOwnProperty(id)) {
      compiled = compiled.replace(
        new RegExp('{(' + id + ')}', 'g'),
        !data[id] && data[id] !== 0 ? '' : data[id].toString().replace('$', '$$$$'),
      );
    }
  }

  return compiled;
}
