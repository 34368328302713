import {Inject, Injectable} from '@angular/core';
import {from, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, first, map, switchMap, takeUntil} from 'rxjs/operators';

import {Logger} from '../logger/logger';

import {AbstractBarcodeReader} from './abstract-barcode-reader';
import {BarcodeResult} from './barcode-result';
import {CameraService} from '../camera/camera.service';
import {CameraType} from '../camera/camera-type';
import {ResponsiveService} from '../responsive/responsive.service';
import {BarcodeFormat} from './barcode-format';

@Injectable({providedIn: 'root'})
export class NativeBarcodeService extends AbstractBarcodeReader {
  private destroySubject = new Subject<void>();

  private barcode = new Subject<BarcodeResult>();

  private barcodeDetector: any;

  private videoElement: HTMLVideoElement;

  constructor(
    private cameraService: CameraService,
    private logger: Logger,
    responsiveService: ResponsiveService,
    @Inject('window') private window: Window,
  ) {
    super(responsiveService);
  }

  load(element: HTMLVideoElement, formats?: Array<string>): Observable<boolean> {
    return this.initialize(element, formats);
  }

  continue(): void {
    this.detectBrowserBarcodeDetector(this.videoElement);
  }

  read(): Observable<BarcodeResult> {
    return this.barcode;
  }

  destroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
    this.destroySubject = new Subject<void>();

    this.barcode.complete();
    this.barcode = new Subject<BarcodeResult>();

    if (this.videoElement) {
      this.videoElement.pause();
    }

    super.destroy();
  }

  private initialize(
    element: HTMLVideoElement,
    formats?: Array<string>,
  ): Observable<boolean> {
    this.videoElement = element;

    // facingMode:
    // - back camera: environment
    // - front camera: user
    return this.cameraService
      .getCameraSelector(CameraType.BACK, this.getBestCamera.bind(this))
      .pipe(
        first(),
        switchMap(cameraConfig =>
          this.window.navigator.mediaDevices.getUserMedia({
            video: cameraConfig,
            audio: false,
          }),
        ),
        takeUntil(this.destroySubject),
        map((stream: MediaStream) => {
          this.configCamera(stream, this.logger);

          element.srcObject = stream;
          element.autoplay = false;

          element.play().then(() => {
            let config;
            try {
              config = !!formats
                ? {
                    formats: formats,
                  }
                : undefined;
              this.barcodeDetector = new this.window['BarcodeDetector'](config);
            } catch (e) {
              this.logger.debug(e, {
                window: this.window,
                constructor: this.window['BarcodeDetector'],
                barcodeDetector: this.barcodeDetector,
              });
            }
            if (this.barcodeDetector) {
              this.detectBrowserBarcodeDetector(element);
            } else {
              this.logger.error(
                `Native BarcodeDetector not implemented on this browser. Config: ${JSON.stringify(
                  config,
                )}`,
              );
            }
          });
        }),
        map(() => true),
      );
  }

  private detectBrowserBarcodeDetector(element: HTMLVideoElement) {
    from(this.barcodeDetector.detect(element))
      .pipe(
        first(),
        catchError(error => {
          if (error.name === 'InvalidStateError') {
            // this.logger.error(
            //   'An InvalidStateError was thrown:',
            //   error,
            //   element.readyState,
            // );
            return of(undefined); // retry when is undefined
          }

          return throwError(() => error);
        }),
      )
      .subscribe(barcodes => {
        if (barcodes && barcodes[0]) {
          this.onDecode(barcodes[0]);
        } else {
          this.detectBrowserBarcodeDetector(element);
        }
      });
  }

  private onDecode(result: {
    boundingBox: DOMRectReadOnly;
    cornerPoints: any;
    format: BarcodeFormat;
    rawValue: string;
  }) {
    if (result) {
      this.barcode.next({
        content: result.rawValue,
        format: result.format,
      });
    }
  }
}
