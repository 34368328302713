import {Serializable} from 'common';

class UserGroupHistoryInternal {
  id: number;
  name: string;
  initials: string;
  picture: string;
}

export class UserGroupHistory extends Serializable(UserGroupHistoryInternal) {
  static createFromBackend(obj: any): UserGroupHistory {
    let g = new UserGroupHistory();
    UserGroupHistory.fillFromBackend(g, obj);
    return g;
  }

  protected static fillFromBackend(u: UserGroupHistory, obj: any) {
    u.id = obj.clientId;
    u.initials = obj.initials;
    u.name = obj.name;
    u.picture = obj.pictureUrl;
  }
}
