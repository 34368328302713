import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

const uriMaps: UriConfig = [
  {
    config: [
      {
        originUrl: environment.internalUrls.settings,
        destinationPlatformUrl: {
          desktop: `/${environment.locale.routes.notifications}`,
          mobile: `/m/${environment.locale.routes.notifications}`,
        },
      },
    ],
  },
];

@NgModule({
  providers: [
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class PreferencesUriMappingModule {}
