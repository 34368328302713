<tl-set-table
  class="day-week-selector__table"
  [form]="control"
  [valueSet]="days"
  [multiple]="multiple"
  [alwaysOneSelected]="required"
  [itemTemplate]="item"
  (preventedDeselect)="preventedDeselect.emit()">
</tl-set-table>

<ng-template #item let-selected let-value="value">
  <tl-set-table-item
    class="day-week-selector__item"
    [selected]="selected"
    [disabled]="value.disabled">
    <span class="day-week-selector__item-label day-week-selector__item-label--xs">
      {{ value.text.xs.key | i18n }}
    </span>
    <span class="day-week-selector__item-label day-week-selector__item-label--sm">
      {{ value.text.sm.key | i18n }}
    </span>
    <span class="day-week-selector__item-label day-week-selector__item-label--lg">
      {{ value.text.lg.key | i18n }}
    </span>
  </tl-set-table-item>
</ng-template>
