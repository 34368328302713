import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {I18nModule, ModalDialogModule} from 'common';

import {VerifyPhoneDialogComponent} from './verify-phone-dialog.component';

@NgModule({
  imports: [CommonModule, I18nModule, ModalDialogModule, NgbModalModule],
  exports: [VerifyPhoneDialogComponent],
  declarations: [VerifyPhoneDialogComponent],
})
export class VerifyPhoneDialogModule {}
