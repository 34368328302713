import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ResponsiveService} from 'common';
import {combineLatest, iif, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {TuloteroDeviceService} from './tulotero-device.service';

@Injectable()
export class DeviceInterceptor implements HttpInterceptor {
  constructor(
    private tuloteroDeviceService: TuloteroDeviceService,
    private responsiveService: ResponsiveService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Do not send device id to unauthorized requests. This is due to generate
    // fingerprint is slow and speed-up loading time.
    return iif(
      // 1. We need to exclude unregister cause we need unregister devices old
      // devices.
      // 2. Installation request should include device info headers.
      () =>
        (req.url.indexOf('/rest/users/') >= 0 &&
          req.url.indexOf('/rest/users/unregister') < 0) ||
        req.url.indexOf('/rest/installation') >= 0 ||
        req.url.indexOf('/rest/userLogin') >= 0 ||
        req.url.indexOf('/rest/facebook/login') >= 0 ||
        req.url.indexOf('/rest/google/login') >= 0,
      combineLatest([
        this.tuloteroDeviceService.getDeviceId(),
        this.tuloteroDeviceService.getFingerprint(),
      ]),
      of([undefined, undefined]),
    ).pipe(
      map(([device, fp]) => this.buildDeviceRequest(req, device, fp)),
      switchMap(request => next.handle(request)),
    );
  }

  private buildDeviceRequest(
    req: HttpRequest<any>,
    device?: string,
    fingerprint?: string,
  ): HttpRequest<any> {
    let deviceReq = req;
    if (req.url.indexOf(environment.endpoint) >= 0) {
      let browser = this.tuloteroDeviceService.getBrowser();
      let browserVersion = this.tuloteroDeviceService.getBrowserVersion();
      let os = this.tuloteroDeviceService.getOS();
      let osVersion = `${os}(${this.tuloteroDeviceService.getOSVersion()})`;
      let deviceDesc = `${browser}(${browserVersion})`;

      let headers = req.headers;
      if (device) {
        headers = headers.set('androidRegistrationId', device);
        headers = headers.set('deviceId', fingerprint);
      }

      deviceReq = req.clone({
        headers: headers
          .set('deviceType', 'WEB')
          .set('deviceDesc', deviceDesc)
          .set('osVersion', osVersion)
          .set('versionCode', environment.appVersion)
          .set('mode', this.responsiveService.isMobile() ? 'mobile' : 'desktop'),
      });
    }
    return deviceReq;
  }
}
