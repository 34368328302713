import {isKey, isKeyInKeys, Keys} from './keycodes';

export function isNavigationKey(key: string): boolean {
  return isKeyInKeys(key, [
    Keys.ARROW_DOWN,
    Keys.ARROW_LEFT,
    Keys.ARROW_RIGHT,
    Keys.ARROW_UP,
    Keys.END,
    Keys.HOME,
    Keys.PAGE_DOWN,
    Keys.PAGE_UP,
    Keys.TAB,
  ]);
}

export function isEditingKey(key: string): boolean {
  return isKeyInKeys(key, [
    Keys.BACKSPACE,
    Keys.CLEAR,
    Keys.CONTROL,
    Keys.COPY,
    Keys.CURSOR_SELECTOR,
    Keys.CUT,
    Keys.DELETE,
    Keys.ERASE_EOF,
    Keys.EXTEND_SELECTION,
    Keys.INSERT,
    Keys.PASTE,
  ]);
}

export function isDeleteKey(key: string): boolean {
  return isKeyInKeys(key, [
    Keys.BACKSPACE,
    Keys.CLEAR,
    Keys.DELETE,
    Keys.ERASE_EOF,
    Keys.CUT,
  ]);
}

export function isNumericKey(key: string): boolean {
  return !!key.match(/\d/);
}

export function isAlphanumericKey(key: string): boolean {
  return !!key.match(/[a-zA-Z0-9]/);
}

export function isShortCutCopyOrPaste(event: KeyboardEvent) {
  return (
    (event.ctrlKey || event.metaKey) &&
    ['x', 'c', 'v'].findIndex(k => k === event.key.toLowerCase()) >= 0
  );
}

export function isModifierKey(key: string): boolean {
  return isKeyInKeys(key, [Keys.CONTROL, Keys.ALT, Keys.SHIFT, Keys.META]);
}

export function isCommaKey(key: string): boolean {
  return !!key.match(/,/);
}

export function isDotKey(key: string): boolean {
  return !!key.match(/./);
}

export function isBackWardsKey(event: KeyboardEvent): boolean {
  return (
    isKey(event.key, Keys.ARROW_LEFT) ||
    (isKey(event.key, Keys.BACKSPACE) && !event.shiftKey)
  );
}
