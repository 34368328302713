import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';

import {PendingWithdrawals} from './pending-withdrawals';

@Injectable({
  providedIn: 'root',
})
export class PendingWithdrawalsService extends AbstractObservableDataService<
  Array<PendingWithdrawals>
> {
  constructor() {
    super();
  }
}
