import {Inject, Injectable, NgZone} from '@angular/core';
import {fromEvent, Observable, Observer, of, ReplaySubject} from 'rxjs';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';

import {CookiesService} from '../../cookies/cookies.service';
import {loadScript} from '../../util/script';
import {Shareable} from '../shareable';

import {
  AuthResponseInfo,
  FacebookConfiguration,
  FacebookConfigurationLoader,
} from './model';

declare const FB: any;

@Injectable({providedIn: 'root'})
export class FacebookService implements Shareable {
  notify = false;

  info: FacebookConfiguration;

  state: string;

  popup: Window;

  private sdkLoaded = false;

  private scriptLoaderObserver = new ReplaySubject<any>(1);

  constructor(
    private cookieService: CookiesService,
    private configuration: FacebookConfigurationLoader,
    private zone: NgZone,
    @Inject('window') private window: Window,
  ) {}

  login(rerequest = false) {
    return this.loadAsync().pipe(
      switchMap(
        () =>
          new Observable((o: Observer<AuthResponseInfo>) => {
            let options = {scope: this.info.permissions, return_scopes: true};
            if (rerequest) {
              options['auth_type'] = 'rerequest';
            }
            FB.login(response => this.loginCallback(response, o), options);
          }),
      ),
      switchMap((authInfoResponse: AuthResponseInfo) =>
        this.getUserEmail().pipe(map(email => ({...authInfoResponse, email}))),
      ),
    );
  }

  getLoginStatus(): Observable<AuthResponseInfo> {
    return this.loadAsync().pipe(
      switchMap(
        () =>
          new Observable((o: Observer<AuthResponseInfo>) => {
            FB.getLoginStatus(response => this.loginCallback(response, o), true);
          }),
      ),
    );
  }

  getUserEmail(): Observable<string> {
    return this.loadAsync().pipe(
      switchMap(
        () =>
          new Observable((o: Observer<string>) => {
            FB.api('/me', {fields: 'email'}, response => {
              this.userEmailCallback(response, o);
            });
          }),
      ),
    );
  }

  openExternalLogin(rerequest: boolean) {
    return this.loadAsync().pipe(
      tap(() => this.deleteFacebookCookie()),
      switchMap(() => {
        this.state = Math.random().toString(36).substring(2, 15);
        let loginUrl = new URL('https://www.facebook.com/v3.1/dialog/oauth?');
        loginUrl.searchParams.append('client_id', this.info.appId);
        loginUrl.searchParams.append('response_type', 'token granted_scopes');
        loginUrl.searchParams.append('redirect_uri', this.info.redirect);
        loginUrl.searchParams.append('state', this.state);
        loginUrl.searchParams.append('scope', this.info.permissions);

        if (rerequest) {
          loginUrl.searchParams.append('auth_type', 'rerequest');
        }

        this.popup = this.window.open(loginUrl.toString());

        return (
          fromEvent(this.window, 'message')
            // Filter facebook postMessages, only accept from our domain
            .pipe(
              filter((ev: any) => ev.origin === this.window.location.origin),
              // It seems previous filter is not enough, some request does
              // not hash. fix: TypeError: Cannot read property
              // 'startsWith' of undefined
              filter(
                (ev: any) =>
                  ev?.origin === this.window.location.origin &&
                  typeof ev?.data?.fragment === 'string' &&
                  ev.data.fragment.includes('access_token') &&
                  ev.data.fragment.includes('data_access_expiration_time'),
              ),
            )
        );
      }),
      first(),
      switchMap((event: any) => {
        // Fix facebook url pattern -> url/?#param1=value
        let fragment = event.data.fragment;
        if (fragment.startsWith('#')) {
          fragment = fragment.slice(1, fragment.length);
        }

        let params = new URLSearchParams(fragment);

        if (this.popup) {
          this.popup.close();
          this.popup = null;
        }

        if (params.has('error_code')) {
          throw new Error(
            'FB login error: ' +
              params.get('error_code') +
              ' : ' +
              params.get('error_message'),
          );
        } else if (params.has('access_token')) {
          this.deleteFacebookCookie();
          return of(<AuthResponseInfo>{
            accessToken: params.get('access_token'),
            expiresIn: parseInt(params.get('expires_in'), 10),
            grantedScopes: params.get('granted_scopes'),
            signedRequest: '',
            userID: '',
          });
        } else {
          throw new Error('FB login error: parameters not recognized');
        }
      }),
    );
  }

  logout() {
    return this.loadAsync().pipe(
      switchMap(
        () =>
          new Observable((o: Observer<any>) => {
            FB.logout(() => {
              this.zone.run(() => {
                this.deleteFacebookCookie();
                o.next(true);
                o.complete();
              });
            });
          }),
      ),
    );
  }

  share(title: string, text: string) {
    return this.loadAsync().pipe(
      switchMap(() => {
        let match = text && text.match(/(https?:[^\s]+)/);
        let url = text && match ? match[0] : '';

        return new Observable((o: Observer<any>) => {
          FB.ui(
            {method: 'feed', link: url, caption: title, description: text},
            () => {
              this.zone.run(() => {
                o.next(true);
                o.complete();
              });
            },
          );
        });
      }),
    );
  }

  loadAsync(): Observable<any> {
    if (!this.sdkLoaded) {
      this.sdkLoaded = true;
      return this.configuration.load().pipe(
        switchMap((info: FacebookConfiguration) => {
          this.info = info;
          (<any>window).fbAsyncInit = () => {
            this.zone.run(() => {
              FB.init({
                appId: info.appId,
                status: false,
                xfbml: false,
                version: 'v20.0',
              });
              this.sdkLoaded = true;
              this.scriptLoaderObserver.next(FB);
            });
          };
          loadScript('facebook-jssdk', '//connect.facebook.net/en_US/sdk.js')
            .pipe(filter(loadedNow => !loadedNow))
            .subscribe(() =>
              this.zone.run(() => this.scriptLoaderObserver.next(FB)),
            );

          return this.scriptLoaderObserver.asObservable();
        }),
      );
    }

    return this.scriptLoaderObserver.asObservable();
  }

  private deleteFacebookCookie() {
    if (this.cookieService.check('fblo_' + this.info.appId)) {
      this.cookieService.delete(
        'fblo_' + this.info.appId,
        '/',
        '.' + this.window.location.hostname,
      );
    }
  }

  private loginCallback(response: any, observer: Observer<AuthResponseInfo>) {
    if (response.authResponse) {
      this.zone.run(() => {
        observer.next(<AuthResponseInfo>response.authResponse);
        observer.complete();
      });
    } else {
      this.zone.run(() => {
        observer.error(new Error('El usuario no ha autorizado los permisos.'));
      });
    }
  }

  private userEmailCallback(response: any, observer: Observer<string>) {
    if (response.email) {
      this.zone.run(() => {
        observer.next(<string>response.email);
        observer.complete();
      });
    } else {
      this.zone.run(() => {
        observer.error(new Error('El usuario no ha autorizado los permisos.'));
      });
    }
  }
}
