import {Injectable} from '@angular/core';
import {differenceInDays} from 'date-fns';
import {Subscription} from 'rxjs';
import {filter, first, switchMap} from 'rxjs/operators';

import {PreferencesService} from '../preferences/data/preferences.service';

import {NotificationsService} from './notifications.service';

@Injectable({providedIn: 'root'})
export class NotificationsSubscriptionManagerService {
  /**
   * Save the timestamp of last time the subscription is sended to the server,
   * specially when users doesn't close the app on the phone.
   */
  private lastSubscriptionSent: Date;

  private subscription: Subscription;

  constructor(
    private notificationsService: NotificationsService,
    private preferencesService: PreferencesService,
  ) {}

  trySendSubscription() {
    if (this.notificationsService.isPushSupported() && this.shouldSend()) {
      this.notificationsService
        .hasPermissions()
        .pipe(filter(has => !!has))
        .subscribe(() => {
          if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
          }
          this.subscription = this.preferencesService
            .hasPushEnabled()
            .pipe(
              filter(value => !!value),
              switchMap(() =>
                this.notificationsService.tryNotificationSubscription(),
              ),
              first(),
            )
            .subscribe({
              next: () => (this.lastSubscriptionSent = new Date()),
              error: () => {},
            });
        });
    }
  }

  private shouldSend() {
    return this.lastSubscriptionSent
      ? differenceInDays(Date.now(), this.lastSubscriptionSent) >= 1
      : true;
  }
}
