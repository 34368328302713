import {Serializable} from 'common';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';

class BooleanGameTypeMetadataInternal extends AbstractGameTypeMetadata {
  /**
   * The value to display on the graphical item. If not set the type label
   * is displayed by default.
   */
  displayLabel: string;

  /**
   * Image to display on the graphical item. It takes precedence over the
   * label(s).
   */
  displayImage: string;
}

export class BooleanGameTypeMetadata extends Serializable(
  BooleanGameTypeMetadataInternal,
) {
  static createFromBackend(obj: any) {
    let booleanGTMD = new BooleanGameTypeMetadata();
    AbstractGameTypeMetadata.fillFromBackend(booleanGTMD, obj);
    booleanGTMD.displayLabel = obj.displayLabel;
    booleanGTMD.displayImage = obj.displayImage;

    return booleanGTMD;
  }
}
