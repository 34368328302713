import {Serializable, SerializableProperty, SerializableType} from 'common';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';
import {AdditionalBetPicksBetRuleTypeMetadata} from './additional-bet-picks-bet-rule-type-metadata';
import {BetRuleTypeMetadata} from './bet-rule-type-metadata';
import {DigitsBetRuleTypeMetadata} from './digits-bet-rule-type-metadata';
import {PickedMultiplesBetRuleTypeMetadata} from './picked-multiples-bet-rule-type-metadata';
import {RequiredBetRuleTypeMetadata} from './required-bet-rule-type-metadata';
import {RequiredMultiplesBetRuleTypeMetadata} from './required-multiples-bet-rule-type-metadata';
import {RestrictedDigitsBetRuleTypeMetadata} from './restricted-digits-bet-rule-type-metadata';
import {SelectionBetRuleTypeMetadata} from './selection-bet-rule-type-metadata';

function betRuleTypeMetadataFactory(type: any) {
  if (
    type.hasOwnProperty('digitGroups') ||
    type.hasOwnProperty('digitGroupsValues')
  ) {
    return RestrictedDigitsBetRuleTypeMetadata;
  } else if (type.hasOwnProperty('pattern')) {
    return DigitsBetRuleTypeMetadata;
  } else if (type.hasOwnProperty('baseMultiplier')) {
    return RequiredMultiplesBetRuleTypeMetadata;
  } else if (type.hasOwnProperty('maxPairs') || type.hasOwnProperty('maxDoubles')) {
    return SelectionBetRuleTypeMetadata;
  } else if (type.hasOwnProperty('multiplesSourceType')) {
    return PickedMultiplesBetRuleTypeMetadata;
  } else if (type.hasOwnProperty('betsSourceType')) {
    return AdditionalBetPicksBetRuleTypeMetadata;
  } else if (type.hasOwnProperty('needed') || type.hasOwnProperty('required')) {
    return RequiredBetRuleTypeMetadata;
  } else {
    return BetRuleTypeMetadata;
  }
}

class BetRuleMetadataInternal {
  // Minimum number of panels/combinations that a user must cover for a specific type
  minPanels: number;

  // Represents the maximum number of plays that can be made for this specific type
  // Example: Tris -> Straight 5 -> 10 panels -> 10 bets
  // Example: Tris -> Pack 7 -> 1 panel -> 1 bet
  // (Which are then multiplied by 7 -> multBet: 7)
  // Example: Gran Especial / Zodiaco Especial -> 20 panels ->
  // 20 tickets that can be picked independently of whether they are the same combination or not
  maxPanels: number;

  @SerializableProperty(
    type => betRuleTypeMetadataFactory(type),
    SerializableType.COLLECTION,
    true,
  )
  ruleTypes: Array<BetRuleTypeMetadata>;

  scope = 'global';

  getRuleTypeWithId(id: string): BetRuleTypeMetadata {
    return this.ruleTypes.find(rt => rt.type.id === id);
  }

  getRuleTypesOf(type: string) {
    return this.ruleTypes.filter(rt => rt.type.playType === type);
  }

  getAllTypes(): Array<AbstractGameTypeMetadata> {
    return this.ruleTypes.map(ruleType => ruleType.type);
  }

  hasRuleTypesOf(type: string) {
    return this.ruleTypes.some(rt => rt.type.playType === type);
  }
}

export class BetRuleMetadata extends Serializable(BetRuleMetadataInternal) {
  static createFromBackend(obj: any) {
    let ruleMD = new BetRuleMetadata();

    ruleMD.minPanels = obj.numMinColumns;
    ruleMD.maxPanels = obj.numMaxColumns;

    ruleMD.ruleTypes = obj.typeIds
      ? obj.typeIds.map(type =>
          betRuleTypeMetadataFactory(type).createFromBackend(type),
        )
      : undefined;

    return ruleMD;
  }
}
