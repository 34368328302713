import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GoogleContact} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractDao} from '../../common/model/abstract.dao';

import {TlContact} from './tl-contact';

@Injectable({providedIn: 'root'})
export class ContactsDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  upload(
    contacts: Array<GoogleContact>,
    deviceId: string,
  ): Observable<Array<TlContact>> {
    const data = contacts.reduce(
      (array: Array<any>, contact) =>
        array.concat(
          contact.phones.map(phone => ({nombre: contact.name, telefono: phone})),
        ),
      [],
    );

    return this.http
      .post(this.baseUrl + '/users/agenda', {contactos: data, deviceId: deviceId})
      .pipe(
        map((r: any) =>
          r.relations.concat(r.agenda).map(re => TlContact.createFromBackend(re)),
        ),
      );
  }
}
