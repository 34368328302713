import {Serializable} from 'common';

class TicketCanceledInternal {
  id: number;
  raffleId: number;
  gameId: string;
  raffleDate: number;
}

export class TicketCanceled extends Serializable(TicketCanceledInternal) {
  static createFromBackend(obj: any): TicketCanceled {
    let ticketCanceled: TicketCanceled = new TicketCanceled();
    ticketCanceled.id = obj.boletoId;
    ticketCanceled.raffleId = obj.sorteoId;
    ticketCanceled.gameId = obj.juego;
    ticketCanceled.raffleDate = obj.sorteoDate;
    return ticketCanceled;
  }
}
