import {Serializable, SerializableProperty} from 'common';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';
import {Choice} from './choice';

class SelectionGameTypeMetadataInternal extends AbstractGameTypeMetadata {
  @SerializableProperty(Choice)
  choices: Array<Choice>;
}

export class SelectionGameTypeMetadata extends Serializable(
  SelectionGameTypeMetadataInternal,
) {
  static createFromBackend(obj: any) {
    let selectionGMDT = new SelectionGameTypeMetadata();
    AbstractGameTypeMetadata.fillFromBackend(selectionGMDT, obj);
    selectionGMDT.choices = obj.allowedValues
      ? obj.allowedValues.map(value => Choice.createFromBackend(value))
      : undefined;

    return selectionGMDT;
  }
}
