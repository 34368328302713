import {Injectable} from '@angular/core';
import {AbstractObservableDataService, isNumeric, Logger} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {GameMetadata} from './game-metadata';
import {GameMetadataNotFoundError} from './game-metadata-not-found-error';

@Injectable({providedIn: 'root'})
export class GameMetadataService extends AbstractObservableDataService<
  Map<string, Array<GameMetadata>>
> {
  constructor(private logger: Logger) {
    super();
  }

  getGameMetadata(gameId: string, version?: number): Observable<GameMetadata> {
    return this._data.pipe(
      map(d => d.get(gameId.toUpperCase())),
      map(list => {
        if (!list) {
          throw new GameMetadataNotFoundError(gameId);
        }

        if (isNumeric(version)) {
          const metadata = list.find(
            gameMetadata => gameMetadata.version === version,
          );
          if (metadata) {
            return metadata;
          } else {
            if (version > list[0].version) {
              this.logger.warn(
                `Metadata version not found. game: ${gameId} version: ${version}`,
              );
            }
            return list[0];
          }
        } else {
          return list[0];
        }
      }),
    );
  }

  setMetadata(data: Array<GameMetadata>) {
    const mapO = new Map<string, Array<GameMetadata>>();

    data.forEach(meta => {
      const current = mapO.get(meta.id);

      if (!current) {
        mapO.set(meta.id.toUpperCase(), [meta]);
      } else {
        if (meta.version > current[0].version) {
          current.unshift(meta);
        } else {
          current.push(meta);
        }
      }
    });

    this.setData(mapO);
  }
}
