import {Inject, Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MediaService {
  // TODO create permissions api service

  constructor(@Inject('window') private window: Window) {}

  isMediaDevicesSupported(): boolean {
    return (
      !!this.window.navigator &&
      !!this.window.navigator.mediaDevices &&
      !!this.window.navigator.mediaDevices.enumerateDevices
    );
  }

  /**
   * Checks wheter or not the user has any media devices, if kind is specified
   * searches only for those devices.
   *
   * Note: Does not need permissions to be granted.
   */
  hasMediaDevices(kind?: MediaDeviceKind): Observable<boolean> {
    if (!this.isMediaDevicesSupported()) {
      return of(false);
    }

    return this.listMediaDevices(kind).pipe(map(list => list.length > 0));
  }

  /**
   * Return if the requested kind of devices has the permission granted or not.
   */
  isMediaPermissionGranted(kind: MediaDeviceKind): Observable<boolean> {
    // if we dont have permissions device labels are empty
    return this.listMediaDevices(kind).pipe(
      map(list => list.some(device => !!device.label)),
    );
  }

  private listMediaDevices(
    kind: MediaDeviceKind,
  ): Observable<Array<MediaDeviceInfo>> {
    return from(this.window.navigator.mediaDevices.enumerateDevices()).pipe(
      map(list => (kind ? list.filter(device => device.kind === kind) : list)),
    );
  }
}
