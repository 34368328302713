import {Serializable} from 'common';

class WithdrawalsInternal {
  id: number;

  clientMail: string;

  createdDate: number;

  lastModifiedDate: number;

  amount: number;

  bankAccount: string;

  status: 'FAILED' | 'SUSPENDED';
}

export class Withdrawals extends Serializable(WithdrawalsInternal) {
  static createFromBackend(obj: any) {
    const withdrawals = new Withdrawals();
    withdrawals.id = obj.id;
    withdrawals.clientMail = obj.clientMail;
    withdrawals.createdDate = obj.createdDate;
    withdrawals.lastModifiedDate = obj.lastModifiedDate;
    withdrawals.amount = obj.amount;
    withdrawals.bankAccount = obj.bankAccount;
    withdrawals.status = obj.status;

    return withdrawals;
  }
}
