<ng-container *ngIf="text">
  <ng-container *ngIf="text | isTranslatableText; else noTranslatable">
    <span
      *ngIf="!text.keyData; else translatableWithData"
      [innerHTML]="text.key | i18n | translatableUrl | htmlSafe">
    </span>
    <ng-template #translatableWithData>
      <span
        [innerHTML]="
          text.key | i18nData: text.keyData | translatableUrl | htmlSafe
        "></span>
    </ng-template>
  </ng-container>
  <ng-template #noTranslatable>
    <span [innerHTML]="text | translatableUrl | htmlSafe"></span>
  </ng-template>
</ng-container>
