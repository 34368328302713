import {NgModule} from '@angular/core';

import {HtmlSafePipe} from './html-safe.pipe';
import {StyleSafePipe} from './style-safe.pipe';
import {UrlSafePipe} from './url-safe.pipe';

@NgModule({
  exports: [HtmlSafePipe, StyleSafePipe, UrlSafePipe],
  declarations: [HtmlSafePipe, StyleSafePipe, UrlSafePipe],
})
export class SanitizerModule {}
