import {Serializable} from 'common';

/**
 * Used to display a banner to select this group of bet types
 */
class BetGroupUIMetadataInternal {
  id: string;

  logo: string;

  text: string;

  tooltip: string;
}

export class BetGroupUIMetadata extends Serializable(BetGroupUIMetadataInternal) {
  static createFromBackend(obj: any): BetGroupUIMetadata {
    let groupUI = new BetGroupUIMetadata();

    groupUI.id = obj.id;
    groupUI.logo = obj.logo;
    groupUI.text = obj.text;
    groupUI.tooltip = obj.tooltip;

    return groupUI;
  }
}
