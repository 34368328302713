<div (click)="leftIconClick.emit()">
  <ng-template
    *ngIf="leftIconTemplate; else defaultLeftIcon"
    [ngTemplateOutlet]="leftIconTemplate">
  </ng-template>
  <ng-template #defaultLeftIcon>
    <div class="tl-banner__left-icon">&times;</div>
  </ng-template>
</div>

<span class="tl-banner__text">
  <ng-template *ngIf="text | isTemplate; else plainText" [ngTemplateOutlet]="text">
  </ng-template>
  <ng-template #plainText>
    <span [innerHTML]="text | htmlSafe"></span>
  </ng-template>
</span>

<ng-container *ngIf="button">
  <ng-template
    *ngIf="button | isTemplate; else defaultButton"
    [ngTemplateOutlet]="button">
  </ng-template>
  <ng-template #defaultButton>
    <button class="btn btn-type-1 tl-banner__button" (click)="buttonClick.emit()">
      {{ button }}
    </button>
  </ng-template>
</ng-container>
