import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tl-apple-button',
  templateUrl: './apple-button.component.html',
  styleUrls: ['./apple-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppleButtonComponent {
  @Input()
  value: string;

  @Input()
  @HostBinding('class.disabled')
  loading = false;

  @HostBinding('class.tl-apple-button')
  readonly hostClass = true;
}
