import {Injectable} from '@angular/core';
import {
  formatDate,
  ModalDialogComponent,
  ModalHelperService,
  TranslatableText,
} from 'common';
import {Observable} from 'rxjs';
import {first, map, tap} from 'rxjs/operators';

import {ErrorService} from '../../error/error.service';
import {User} from '../../user/data/user';
import {UserService} from '../../user/data/user.service';

import {ExclusionDao, SpendLimits} from './exclusion.dao';
import {Question} from './question';
import {environment} from '~environments/environment';
import {ResponseDateRetryQuestionnarie} from './response-date-retry-questionnarie';

@Injectable({
  providedIn: 'root',
})
export class ExclusionService {
  public showMenu = false;

  constructor(
    private errorService: ErrorService,
    private exclusionDao: ExclusionDao,
    private userService: UserService,
    private modalHelperService: ModalHelperService,
  ) {}

  setShowMenu(show: boolean): void {
    this.showMenu = show;
  }

  getLimits(): Observable<SpendLimits> {
    return this.exclusionDao.getLimits();
  }

  saveLimits(payload: any): Observable<any> {
    return this.exclusionDao.saveLimits(payload);
  }

  requestToken(selfExclusion): Observable<any> {
    return this.exclusionDao.requestToken(selfExclusion);
  }

  verifyExclusion(token: string): Observable<any> {
    return this.exclusionDao.verifyExclusion(token).pipe(
      tap({
        error: err => {
          this.errorService.processTuLoteroErrorGlobalContext(err);
        },
      }),
    );
  }

  /**
   * Open info dialog if the user is excluded
   *
   * @return true if is excluded (if modal is shown)
   */
  showExcludedModal(): Observable<boolean> {
    return this.userService.getData().pipe(
      first(),
      map((user: User) => {
        if (user?.exclusion) {
          const indefinitely = !user.exclusion.automaticReactivation;
          const translationText: TranslatableText = indefinitely
            ? {
                key: 'selfExclusion.dialogExclusion.titleDialogExclusionIndefinitelyAction',
              }
            : {
                key: 'selfExclusion.dialogExclusion.titleDialogExclusionDateAction',
                keyData: {
                  date: formatDate(
                    user.exclusion.reactivationDate,
                    environment.locale.dateFormats.shortDayMonthYear,
                  ),
                },
              };
          this.modalHelperService.openOkModal(ModalDialogComponent, {
            componentParams: {
              type: 'ok',
              image: 'assets/img/exclusion/stop.png',
              title: translationText,
              accept: {key: 'selfExclusion.dialogExclusion.okButton'},
            },
          });
          return true;
        } else {
          return false;
        }
      }),
    );
  }

  getQuestionnarie(): Observable<Array<Question>> {
    return this.exclusionDao.getLimitsMaxRemoveQuestionnaire();
  }

  saveLimitsMaxRemoveQuestionnaire(questions: Array<Question>): Observable<any> {
    return this.exclusionDao.saveLimitsMaxRemoveQuestionnaire(questions);
  }

  getLimitsMaxDateRetryQuestionnaire(): Observable<ResponseDateRetryQuestionnarie> {
    return this.exclusionDao.getLimitsMaxDateRetryQuestionnaire();
  }
}
