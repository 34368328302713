import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {Logger} from 'common';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {environment} from '~environments/environment';

@Injectable({providedIn: 'root'})
export class PwaForceUpdateService {
  private onUpdate = new BehaviorSubject(false);

  private onlineStatus: boolean;

  constructor(
    private http: HttpClient,
    private logger: Logger,
    private swUpdate: SwUpdate,
    @Inject('window') private window: Window,
  ) {}

  /**
   * Flag to know whether app is updating.
   *
   * true if updating
   */
  get updating() {
    return this.onUpdate.asObservable();
  }

  /**
   * Check for updates
   */
  hasUpdate(): Observable<boolean> {
    this.onlineStatus = this.window.navigator.onLine;

    if (this.swUpdate.isEnabled) {
      return this.http
        .get(
          'assets/data/force-update.json?ngsw-bypass=1&cache-bust=' + Math.random(),
        )
        .pipe(
          map((o: any) => o.version),
          map(version => version > environment.appVersion),
          catchError(e => {
            this.logger.warn('No se ha podido obtener el fichero force update', {
              error: e,
              onlineStatusBefore: this.onlineStatus,
              onlineStatusCurrent: this.window.navigator.onLine,
            });
            return of(false);
          }),
        );
    } else {
      return of(false);
    }
  }

  update() {
    this.onUpdate.next(true);
    this.swUpdate.checkForUpdate();
    this.swUpdate.available.pipe(first()).subscribe(() => {
      this.onUpdate.next(false);
      this.window.location.reload();
    });
  }
}
