import {Team} from './team';

export class Match {
  id: number;

  local: Team;

  visitor: Team;

  date: number;

  resultValues: Array<string>;

  typeId: string;

  static createFromBackend(obj: any) {
    let pMatch = new Match();

    pMatch.id = obj.id;
    pMatch.local = Team.createFromBackend(obj.local);
    pMatch.visitor = Team.createFromBackend(obj.visitor);
    pMatch.date = obj.date;
    pMatch.typeId = obj.typeId;
    pMatch.resultValues = obj.resultInfo && obj.resultInfo.values;

    return pMatch;
  }

  public static fromJSON(json: any): Match {
    if (typeof json === 'string') {
      return JSON.parse(json, Match.reviver) as Match;
    } else if (json !== undefined && json !== null) {
      let pMatch = Object.create(Match.prototype);
      return Object.assign(pMatch, json, {});
    } else {
      return json;
    }
  }

  public static reviver(key: string, value: any): any {
    return key === '' ? Match.fromJSON(value) : value;
  }

  toJSON() {
    return Object.assign({}, this);
  }
}

export interface MatchesData {
  matches: Array<Match>;
  extraInfo?: string[];
}

export interface MatchesByType {
  matchGroups: Map<string, Array<Match>>;
  extraInfo?: string[];
}
