export enum KycMethodId {
  NATIONAL = 'dni',
  COMMUNITY = 'dnipaiscert',
  NON_COMMUNITY = 'tie',
  PASSPORT = 'pasaporte',
  CARD_ID = 'dnipais',
  CARD_DL_ID = 'dlidcard',
  CITIZEN_CARD = 'cartaocidadao',
  IDENTITY_CARD = 'bilheteidentidade',
  RESIDENCE_CARD = 'cartaoresidente',
  FOREIGNER_IDENTIFICATION_CARD = 'cartaoestrangeira',
}
