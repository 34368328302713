import {Serializable} from 'common';

export class NoDeleteAllInfoInternal {
  min: number;
  max: number;
}

export class NoDeleteAllInfo extends Serializable(NoDeleteAllInfoInternal) {
  static createFromBackend(obj: any): NoDeleteAllInfo {
    const noDeleteAllInfo: NoDeleteAllInfo = new NoDeleteAllInfo();
    noDeleteAllInfo.min = obj.min;
    noDeleteAllInfo.max = obj.max;
    return noDeleteAllInfo;
  }
}
