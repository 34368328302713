import {NgModule} from '@angular/core';
import {BankAccountFormatter, ClabeFormatter, IbanFormatter} from 'common';
import {environment} from '~environments/environment';

@NgModule({
  providers: [
    {
      provide: BankAccountFormatter,
      useFactory: () =>
        environment.locale.bankAccountType === 'IBAN'
          ? new IbanFormatter(environment.locale.bankAccountFieldLengths)
          : new ClabeFormatter(),
    },
  ],
})
export class BankAccountProvidersModule {}
