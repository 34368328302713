import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../../common/model/abstract.dao';

@Injectable({providedIn: 'root'})
export class TicketShippingDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  requestShipment(data: Record<string, unknown>): Observable<{message: string}> {
    return this.http.post<{message: string}>(
      this.baseUrl + '/users/enviocasa/v2/',
      data,
    );
  }

  requestCollection(data: Record<string, unknown>): Observable<{message: string}> {
    return this.http.post<{message: string}>(
      this.baseUrl + '/users/recogida/',
      data,
    );
  }

  getShipmentPriceUser(boothId: string, state: string): Observable<{price: number}> {
    return this.http.get<{price: number}>(
      `${this.baseUrl}/users/envioCasa/${boothId}?provinciaEnvio=${state}`,
    );
  }

  getShipmentPrice(boothId: string, state: string): Observable<{price: number}> {
    return this.http.get<{price: number}>(
      `${this.baseUrl}/envioCasa/${boothId}?provinciaEnvio=${state}`,
    );
  }

  zipCodeMatchesState(
    zipCode: string,
    state: string,
  ): Observable<{correct: boolean}> {
    return this.http.get<{correct: boolean}>(
      `${this.baseUrl}/delivery/checkCp?cp=${zipCode}&province=${state}`,
    );
  }
}
