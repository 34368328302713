import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';

import {ResponsiveService} from '../../responsive/responsive.service';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-scroll-rail',
  template: '',
  styleUrls: ['./scroll-rail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollRailComponent implements OnInit, OnDestroy {
  @Output()
  scrollTopUpdater = new EventEmitter<number>();

  @Output()
  dragStart = new EventEmitter<void>();

  @Output()
  dragEnd = new EventEmitter<void>();

  private isDragging = false;

  private viewportHeight = 1;

  private scrollHeight = 1;

  private thumbHeight = 0;

  private mouseDownListener: () => void;

  private dragListeners: Array<() => void>;

  private responsiveSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private element: ElementRef,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
  ) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.responsiveSubscription = this.responsiveService.breakpointChange
        .pipe(
          map(() => this.responsiveService.isDesktop()),
          startWith(this.responsiveService.isDesktop()),
          distinctUntilChanged(),
        )
        .subscribe(isDesktop => {
          if (isDesktop) {
            this.attachMouseDownListener();
          } else {
            this.detachMouseDownListener();
          }
        });
    });
  }

  ngOnDestroy() {
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }

    this.detachMouseDownListener();

    this.detachDragListeners();
  }

  setHeight(height: string): void {
    this.renderer.setStyle(this.element.nativeElement, 'height', height);
  }

  setScrollTop(scrollTop): void {
    if (!this.isDragging) {
      this.renderer.setStyle(
        this.element.nativeElement,
        'top',
        `${this.ratioTimesBase(
          scrollTop,
          this.scrollHeight,
          this.viewportHeight,
        )}px`,
      );
    }
  }

  setConstraints(viewportHeight: number, scrollHeight: number): void {
    this.viewportHeight = viewportHeight;
    this.scrollHeight = scrollHeight;

    this.thumbHeight = this.ratioTimesBase(
      this.viewportHeight,
      this.scrollHeight,
      this.viewportHeight,
    );

    this.setHeight(`${this.thumbHeight}px`);
  }

  setVisibility(visible: boolean): void {
    if (visible) {
      this.renderer.addClass(this.element.nativeElement, 'visible');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'visible');
    }
  }

  getElementRef(): ElementRef {
    return this.element;
  }

  private attachMouseDownListener(): void {
    this.mouseDownListener = this.renderer.listen(
      this.element.nativeElement,
      'mousedown',
      (event: MouseEvent) => this.onMouseDown(event),
    );
  }

  private detachMouseDownListener(): void {
    if (typeof this.mouseDownListener === 'function') {
      this.mouseDownListener();
    }
  }

  private onMouseDown(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.dragStart.emit();

    this.dragListeners = [];

    this.isDragging = true;
    this.renderer.addClass(this.element.nativeElement, 'force-hover');

    // attach listeners
    this.dragListeners.push(
      this.renderer.listen(this.document, 'mousemove', (mousemove: MouseEvent) =>
        this.handleDrag(mousemove),
      ),
    );

    // detach listeners
    this.dragListeners.push(
      this.renderer.listen(this.document, 'mouseup', () => {
        this.renderer.removeClass(this.element.nativeElement, 'force-hover');
        this.dragEnd.emit();
        this.isDragging = false;

        this.detachDragListeners();
      }),
    );
  }

  private detachDragListeners(): void {
    if (this.dragListeners && this.dragListeners.length) {
      this.dragListeners.forEach(listener => listener());

      this.dragListeners = [];
    }
  }

  private handleDrag(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    const newTop =
      Number.parseInt(this.element.nativeElement.style.top, 10) + event.movementY;
    const correctedTop = Math.max(
      0,
      Math.min(newTop, this.viewportHeight - this.thumbHeight),
    );

    this.renderer.setStyle(this.element.nativeElement, 'top', `${correctedTop}px`);

    this.scrollTopUpdater.emit(
      (correctedTop / this.viewportHeight) * this.scrollHeight,
    );
  }

  /**
   * Calculates a ratio from the first 2 arguments and returns the amount
   * of that ratio multiplied by the third argument.
   */
  private ratioTimesBase(
    ratioPart: number,
    ratioTotal: number,
    base: number,
  ): number {
    return (ratioPart / ratioTotal) * base;
  }
}
