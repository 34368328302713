import {BackendTransformable} from '../../backend/util/backend-transformable';

export class Question implements BackendTransformable {
  index: number;

  text: string;

  answer: boolean;

  static createFromBackend(obj: any): Question {
    const question = new Question();
    question.index = obj.questionNum;
    question.text = obj.question;
    return question;
  }

  toBackend() {
    return {
      questionNum: this.index,
      answer: this.answer,
    };
  }
}
