import {Injector} from '@angular/core';
import {DeviceClientHintService} from './device-client-hints.service';
import {FingerprintStrategy} from './fingerprint/model';
import {UA_PARSER} from './ua-parser/ua-parser';
import {DeviceUAParserService} from './device-ua-parser.service';

export const deviceServiceFactory = (
  fingerprint: FingerprintStrategy,
  injector: Injector,
) => {
  // eslint-disable-next-line deprecation/deprecation
  const window = injector.get('window');
  const uaParser = injector.get(UA_PARSER);
  const isSupportedUserAgentClientHints = !!window.navigator['userAgentData'];

  if (isSupportedUserAgentClientHints) {
    return new DeviceClientHintService(fingerprint, uaParser, window);
  } else {
    return new DeviceUAParserService(fingerprint, uaParser, window);
  }
};
