import {randomIn} from './number';

export function arrayOfLength(length: number, initialValue: any = 0) {
  let newArray = Array(length);

  if (typeof initialValue === 'function') {
    newArray = newArray.fill(null).map(() => initialValue());
  } else {
    newArray.fill(initialValue);
  }
  return newArray;
}

/**
 * Shuffles the array contents using the Fisher-Yates shuffle implementation
 * https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
 */
export function shuffleArray<T>(array: Array<T>): Array<T> {
  for (let i = array.length - 1; i > 1; i--) {
    const rnd = randomIn(0, i);
    let tmp = array[rnd];
    array[rnd] = array[i];
    array[i] = tmp;
  }
  return array;
}

export function ensureArray<T>(value: T | Array<T>): Array<T> {
  return Array.isArray(value) ? value : [value];
}

export function equalPrimitiveArray(
  a: Array<number | string | boolean>,
  b: Array<number | string | boolean>,
): boolean {
  return (
    Array.isArray(a) && Array.isArray(b) && a.every((n, index) => n === b[index])
  );
}
