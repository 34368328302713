import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

const uriMaps: UriConfig = [
  {
    adminasociada: [
      {
        originUrl: environment.internalUrls.boothAssigned,
        destinationPlatformUrl: {
          desktop: `/${environment.locale.routes.booths}`,
          mobile: `/m/${environment.locale.routes.booths}`,
        },
      },
    ],
  },
];

@NgModule({
  providers: [
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class BoothsUriMappingModule {}
