import {Serializable, SerializableProperty, SerializableType} from 'common';

import {ActionButton} from './action-button';
import {WhatsappActionButton} from './whatsapp-action-button';
import {SmsActionButton} from './sms-action-button';

function actionButtonsFactory(action: any) {
  if (action.type === 'whatsapp') {
    return WhatsappActionButton;
  }

  if (action.type === 'sms') {
    return SmsActionButton;
  }

  return ActionButton;
}

class ActionButtonsInternal {
  // All properties should be initialized, even if you initialize with =
  // undefined. See CAUTION.
  @SerializableProperty(actionButtonsFactory, SerializableType.COLLECTION, true)
  depositButtons: Array<ActionButton> = [];

  @SerializableProperty(actionButtonsFactory, SerializableType.COLLECTION, true)
  verifyPhoneButtons: Array<ActionButton> = [];
}

export class ActionButtons extends Serializable(ActionButtonsInternal) {
  static createFromBackend(obj: any) {
    let actionButtons = new ActionButtons();

    Object.keys(obj).forEach(key => {
      // CAUTION: This works because all properties are initialized, but
      // actionButtons.hasOwnProperty will false. This is a typescript es5
      // compilation issue / problem.
      if (obj.hasOwnProperty(key) && actionButtons.hasOwnProperty(key)) {
        actionButtons[key] = obj[key]
          .filter(
            action =>
              action.platforms.includes('WEB_DESKTOP') ||
              action.platforms.includes('MOBILE_WEB'),
          )
          .map(action => {
            if (action.types[0] === 'WHATSAPP') {
              return WhatsappActionButton.createFromBackend(action);
            }

            if (action.types[0] === 'SMS') {
              return SmsActionButton.createFromBackend(action);
            }

            return ActionButton.createFromBackend(action);
          });
      }
    });

    return actionButtons;
  }
}
