<div class="drop-menu-toggle" (click)="toggleMain()">
  <tl-dropdown-menu-item
    [itemTemplate]="headerTemplate"
    [node]="selectedNode | async"
    [asHeader]="true">
  </tl-dropdown-menu-item>
</div>

<div
  *ngFor="let parentNode of displayNodes | async; let level = index"
  #parentContainer
  class="drop-menu-container hidden"
  [id]="'mc-' + parentNode.id"
  [style.z-index]="1000 + level">
  <tl-scrollable>
    <tl-dropdown-menu-item
      *ngFor="let node of parentNode.children"
      #nodeItem
      [id]="'mn-' + node.id"
      [itemTemplate]="nodeTemplate"
      [node]="node"
      (click)="nodeClick(nodeItem)">
    </tl-dropdown-menu-item>
  </tl-scrollable>
</div>
