import {NgModule} from '@angular/core';
import {provideClass} from 'common';
import {environment} from '~environments/environment';
import {SubscriptionsNotExcludedViewService} from './subscriptions-not-excluded-view.service';
import {NoopSubscriptionNotExecutedViewService} from './noop-subscription-not-executed-view.service';

@NgModule({
  providers: [
    SubscriptionsNotExcludedViewService,
    provideClass(
      SubscriptionsNotExcludedViewService,
      environment.features.reserveMode
        ? SubscriptionsNotExcludedViewService
        : NoopSubscriptionNotExecutedViewService,
    ),
  ],
})
export class SubscriptionsNotExecutedModule {}
