import {Injectable} from '@angular/core';
import {
  asapScheduler,
  BehaviorSubject,
  combineLatest,
  defer,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  catchError,
  first,
  map,
  observeOn,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {CoordinatePoint} from '../geolocation/coordinate-point';
import {
  InaccurateLocationError,
  RejectedGeolocationError,
} from '../geolocation/geolocation-errors';
import {GeolocationViewService} from '../geolocation/geolocation-view.service';

import {LotteryBooth} from './data/lottery-booth';
import {LotteryBoothService} from './data/lottery-booth.service';
import {AlertsService, Destroyable, Logger} from 'common';
import {GeolocatedLotteryBooth} from './data/geolocated-lottery-booth';

@Injectable()
export class LotteryBoothsGeolocationViewService {
  lotteryBooths: Observable<Array<LotteryBooth>>;

  lastPosition: GeolocationPosition;
  currentPosition = new ReplaySubject<GeolocationPosition>(1);

  forcedLocation: {longitude: number; latitude: number};

  /**
   * Indicates if geolocation is enabled or disabled.
   */
  private geolocationState = new BehaviorSubject(false);

  /**
   * Maximum accuracy error allowed to consider a reading valid.
   */
  private readonly MAXIMUM_ALLOWED_ERROR = environment.geolocation.maxAccuracy;

  @Destroyable()
  private destroy = new Subject();

  get geolocationEnabled() {
    return this.geolocationState.asObservable();
  }

  constructor(
    private geolocationViewService: GeolocationViewService,
    private lotteryBoothService: LotteryBoothService,
    private alertService: AlertsService,
    private logger: Logger,
  ) {
    const geolocationEnabled = defer(() =>
      this.forcedLocation
        ? of({coords: this.forcedLocation} as GeolocationPosition)
        : this.geolocationState.getValue()
        ? this.currentPosition.asObservable().pipe(first())
        : this.geolocationViewService.requestLocationToOrderBooths(),
    ).pipe(
      tap(currentPosition => this.currentPosition.next(currentPosition)),
      switchMap(position => this.lotteryBoothService.geolocateBooths(position)),
      catchError(() => {
        this.geolocationState.next(false);
        return this.lotteryBoothService.getData();
      }),
    );

    const geolocationDisabled = this.lotteryBoothService.getData();

    // Emits when booths change or geolocation state changes.
    this.lotteryBooths = combineLatest([
      this.lotteryBoothService.getData(),
      this.geolocationState,
    ] as [Observable<Array<LotteryBooth>>, Observable<boolean>]).pipe(
      map(([_, state]) => state),
      switchMap(state => (state ? geolocationEnabled : geolocationDisabled)),
      observeOn(asapScheduler),
      takeUntil(this.destroy),
      shareReplay(1),
    );
  }

  /**
   * Request permission if needed and load geolocated lottery booths.
   */
  enableGeolocation(allowInaccurate = false): Observable<GeolocationPosition> {
    // We need to call requestGeolocation() here cause if fails, this function
    // have to return an error.
    return this.enableGeolocationWithoutBooths(allowInaccurate).pipe(
      switchMap(position =>
        this.lotteryBooths.pipe(
          map(() => position),
          first(),
        ),
      ),
    );
  }

  enableGeolocationWithoutBooths(
    allowInaccurate = false,
  ): Observable<GeolocationPosition> {
    // We need to call requestGeolocation() here cause if fails, this function
    // have to return an error.

    return this.geolocationViewService.requestLocationToOrderBooths().pipe(
      tap(position => {
        if (
          !allowInaccurate &&
          position.coords.accuracy > this.MAXIMUM_ALLOWED_ERROR
        ) {
          throw new InaccurateLocationError(
            'Location error: Low positional accuracy: ' + position.coords.accuracy,
            position,
          );
        }

        this.lastPosition = position;
        this.currentPosition.next(position);
        this.geolocationState.next(true);
      }),
      catchError((geolocationError: RejectedGeolocationError) => {
        if (geolocationError instanceof InaccurateLocationError) {
          throw geolocationError;
        }

        this.logger.warn(
          'Error to request location to order booths',
          geolocationError.message,
        );
        this.alertService.notifyError({key: 'geolocation.geolocationError'});
        throw new RejectedGeolocationError();
      }),
    );
  }

  setGeolocation(coords: CoordinatePoint): void {
    this.forcedLocation = coords;
    this.geolocationState.next(true);
  }

  /**
   * Load non geolocated lottery booths.
   */
  disableGeolocation(): void {
    this.geolocationState.next(false);
    this.lastPosition = null;
  }

  /**
   * Returns geolocated booth if we have a location, otherwise we return it without
   * location (distance between user and booth)
   *(Used when fail geolocation user but geolocation is enabled)
   *
   * @param boothId
   */
  getGeolocatedBoothById(
    boothId: string,
  ): Observable<GeolocatedLotteryBooth | LotteryBooth> {
    return this.currentPosition.pipe(
      switchMap(position =>
        this.lotteryBoothService.geolocateBooth(position, boothId),
      ),
      catchError(() => {
        this.geolocationState.next(false);
        return this.lotteryBoothService
          .getData()
          .pipe(map(booths => booths.find(b => b.id === boothId)));
      }),
    );
  }

  getBoothWithoutGeolocation(boothId: string): Observable<LotteryBooth> {
    return this.lotteryBooths.pipe(
      map(booths => booths.find(b => b.id === boothId)),
    );
  }
}
