import {DniType} from '../../kyc/data/dni-type';
import {KycMethodId} from '../../kyc/data/kyc-method-id';

export enum UserDocumentType {
  CITIZEN_CARD = 'citizenCard',
  IDENTITY_CARD = 'identityCard',
  RESIDENCE_CARD = 'residentCard',
  PASSPORT = 'passport',
  FOREIGNER_IDENTIFICATION_CARD = 'foreignIdentificationCard',
}

export enum UserDocumentTypeBackend {
  CITIZEN_CARD = 'CARTAO_CIDADAO',
  IDENTITY_CARD = 'BILHETE_IDENTIDADE',
  RESIDENCE_CARD = 'CARTAO_RESIDENTE',
  PASSPORT = 'PASSAPORTE',
  FOREIGNER_IDENTIFICATION_CARD = 'CARTAO_IDENTIFICACAO_ESTRANGERIA',
}

export function getBackendUserDocumentType(
  documentType: UserDocumentType,
): UserDocumentTypeBackend {
  switch (documentType) {
    case UserDocumentType.CITIZEN_CARD:
      return UserDocumentTypeBackend.CITIZEN_CARD;
    case UserDocumentType.IDENTITY_CARD:
      return UserDocumentTypeBackend.IDENTITY_CARD;
    case UserDocumentType.RESIDENCE_CARD:
      return UserDocumentTypeBackend.RESIDENCE_CARD;
    case UserDocumentType.PASSPORT:
      return UserDocumentTypeBackend.PASSPORT;
    case UserDocumentType.FOREIGNER_IDENTIFICATION_CARD:
      return UserDocumentTypeBackend.FOREIGNER_IDENTIFICATION_CARD;
    default:
      return null;
  }
}

export function userDocumentTypeFromBackend(
  documentType: UserDocumentTypeBackend,
): UserDocumentType {
  switch (documentType) {
    case UserDocumentTypeBackend.CITIZEN_CARD:
      return UserDocumentType.CITIZEN_CARD;
    case UserDocumentTypeBackend.IDENTITY_CARD:
      return UserDocumentType.IDENTITY_CARD;
    case UserDocumentTypeBackend.RESIDENCE_CARD:
      return UserDocumentType.RESIDENCE_CARD;
    case UserDocumentTypeBackend.PASSPORT:
      return UserDocumentType.PASSPORT;
    case UserDocumentTypeBackend.FOREIGNER_IDENTIFICATION_CARD:
      return UserDocumentType.FOREIGNER_IDENTIFICATION_CARD;
    default:
      return null;
  }
}

export function getUserDocumentTypeFromDniType(dniType: DniType): UserDocumentType {
  switch (dniType) {
    case DniType.CITIZEN_CARD:
      return UserDocumentType.CITIZEN_CARD;
    case DniType.IDENTITY_CARD:
      return UserDocumentType.IDENTITY_CARD;
    case DniType.RESIDENCE_CARD:
      return UserDocumentType.RESIDENCE_CARD;
    case DniType.PASSPORT:
      return UserDocumentType.PASSPORT;
    case DniType.FOREIGNER_IDENTIFICATION_CARD:
      return UserDocumentType.FOREIGNER_IDENTIFICATION_CARD;
    default:
      return null;
  }
}

export function getKycMethodIdFromUserDocumentType(
  documentType: UserDocumentType,
): KycMethodId {
  switch (documentType) {
    case UserDocumentType.CITIZEN_CARD:
      return KycMethodId.CITIZEN_CARD;
    case UserDocumentType.IDENTITY_CARD:
      return KycMethodId.IDENTITY_CARD;
    case UserDocumentType.RESIDENCE_CARD:
      return KycMethodId.RESIDENCE_CARD;
    case UserDocumentType.PASSPORT:
      return KycMethodId.PASSPORT;
    case UserDocumentType.FOREIGNER_IDENTIFICATION_CARD:
      return KycMethodId.FOREIGNER_IDENTIFICATION_CARD;
    default:
      return null;
  }
}
