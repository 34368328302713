import {Injectable} from '@angular/core';
import {
  AbstractObservableDataService,
  PaginationDirection,
  TwoWayPageableService,
} from 'common';
import {EMPTY, Observable, of, zip} from 'rxjs';
import {expand, first, last, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {Group} from './group';
import {GroupHistoryEntry} from './group-history-entry';
import {GroupDao} from './group.dao';
import {GroupService} from './group.service';

@Injectable({providedIn: 'root'})
export class GroupHistoryService
  extends AbstractObservableDataService<Array<GroupHistoryEntry>>
  implements TwoWayPageableService<Array<GroupHistoryEntry>>
{
  paginationIndex: number;
  paginationUpIndex: number;
  paginationEnd: boolean;
  paginationUpEnd: boolean;

  private readonly PAGE_SIZE = 20;

  constructor(private groupDao: GroupDao, private groupService: GroupService) {
    super();
  }

  reset(): Observable<Array<GroupHistoryEntry>> {
    this.paginationIndex = 0;
    this.paginationEnd = false;
    this.paginationUpEnd = false;

    super.setData([]);
    return this.groupService.getData().pipe(
      first(),
      map(group => (this.paginationUpIndex = group.history[0].id)),
      switchMap(() => this.loadMore(PaginationDirection.UP)),
    );
  }

  /**
   * Set the data form a kown full list of messages and infer pagination from them
   */
  setInitialData(data: Array<GroupHistoryEntry>, group: Group): void {
    this.paginationIndex = data.length ? data[data.length - 1].id : 0;
    this.setPaginationUp(group.totalHistory);

    this.paginationUpIndex = data.length ? data[0].id : 0;
    this.setPaginationDown(group.totalHistory);

    this.setData(data);
  }

  getLastReadIndex(): Observable<number> {
    return zip(this.getData(), this.groupService.getData()).pipe(
      first(),
      map(([data, group]) => data.findIndex(value => this.isLastRead(value, group))),
    );
  }

  getDataReady(): Observable<void> {
    return zip(this.getData(), this.groupService.getData()).pipe(
      first(),
      switchMap(([_, group]) => {
        if (this.isLastReadEntryFetched(group)) {
          return of(void 0);
        } else {
          return this.loadNotReadEntries(group);
        }
      }),
    );
  }

  loadMore(
    direction: PaginationDirection,
    reset = false,
  ): Observable<Array<GroupHistoryEntry>> {
    return this.groupService.getData().pipe(
      first(),
      switchMap(group =>
        this.loadChat(direction, group).pipe(
          expand(newHistory => {
            const paginationEnd =
              direction === PaginationDirection.UP
                ? this.paginationUpEnd
                : this.paginationEnd;

            if (newHistory.length >= this.PAGE_SIZE / 2 || paginationEnd) {
              return EMPTY;
            } else {
              return this.loadChat(direction, group).pipe(
                map(lines =>
                  direction === PaginationDirection.UP
                    ? newHistory.concat(lines)
                    : lines.concat(newHistory),
                ),
              );
            }
          }),
          last(),
          switchMap(newHistory => {
            if (reset) {
              return of(newHistory.reverse());
            } else {
              return this._data.pipe(
                first(),
                map(current =>
                  direction === PaginationDirection.UP
                    ? newHistory.reverse().concat(current)
                    : current.concat(newHistory.reverse()),
                ),
              );
            }
          }),
          tap(list => this.setData(list)),
        ),
      ),
    );
  }

  private loadChat(
    direction: PaginationDirection,
    group: Group,
  ): Observable<Array<GroupHistoryEntry>> {
    const index =
      direction === PaginationDirection.UP
        ? this.paginationUpIndex
        : this.paginationIndex;

    return this.groupDao.getHistory(group.id, index, direction, this.PAGE_SIZE).pipe(
      map(history => {
        if (direction === PaginationDirection.UP) {
          this.setPaginationUp(group.totalHistory, -this.PAGE_SIZE);
        } else {
          this.setPaginationDown(group.totalHistory, this.PAGE_SIZE);
        }

        return history.data;
      }),
    );
  }

  private loadNotReadEntries(group: Group): Observable<void> {
    const upLimit = Math.max(group.totalHistory - environment.chatLimit, 0);

    if (
      group.totalHistory -
        group.userGroupStatus.lastHistoryReadId -
        this.PAGE_SIZE / 2 >
      environment.chatLimit
    ) {
      // forward read id to be within limits
      this.paginationUpIndex = this.paginationIndex = upLimit;
      this.paginationUpEnd = true;
    } else {
      this.paginationUpIndex = this.paginationIndex =
        group.userGroupStatus.lastHistoryReadId - this.PAGE_SIZE / 2;
      this.paginationUpEnd = false;
    }

    return this.getData().pipe(
      first(),
      switchMap(() => this.loadMore(PaginationDirection.DOWN, true)),
      switchMap(list => {
        if (list.length < this.PAGE_SIZE / 2 && this.paginationEnd) {
          return this.loadMore(PaginationDirection.UP);
        } else {
          return of(list);
        }
      }),
      map(() => void 0),
    );
  }

  private setPaginationUp(newest: number, move = 0): void {
    const oldest = Math.max(newest - environment.chatLimit, 0);
    this.paginationUpIndex = Math.max(this.paginationUpIndex + move, oldest);
    this.paginationUpEnd = this.paginationUpIndex === oldest;
  }

  private setPaginationDown(newest: number, move = 0): void {
    this.paginationIndex = Math.min(this.paginationIndex + move, newest);
    this.paginationEnd = this.paginationIndex === newest;
  }

  private isLastReadEntryFetched(group: Group): boolean {
    return (
      this.paginationIndex >= group.userGroupStatus.lastHistoryReadId &&
      this.paginationUpIndex <= group.userGroupStatus.lastHistoryReadId
    );
  }

  private isLastRead(entry: GroupHistoryEntry, group: Group): boolean {
    return entry.id === group.userGroupStatus.lastHistoryReadId;
  }
}
