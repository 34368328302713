export class Keys {
  static readonly ARROW_LEFT = ['ArrowLeft', 'Left'];
  static readonly ARROW_DOWN = ['ArrowDown', 'Down'];
  static readonly ARROW_RIGHT = ['ArrowRight', 'Right'];
  static readonly ARROW_UP = ['ArrowUp', 'Up'];
  static readonly BACKSPACE = ['Backspace'];
  static readonly CLEAR = ['Clear'];
  static readonly CONTROL = ['Control'];
  static readonly COPY = ['Copy'];
  static readonly CURSOR_SELECTOR = ['CrSel'];
  static readonly CUT = ['Cut'];
  static readonly DELETE = ['Delete'];
  static readonly END = ['End'];
  static readonly ENTER = ['Enter'];
  static readonly ERASE_EOF = ['EraseEof'];
  static readonly ESCAPE = ['Escape', 'Esc'];
  static readonly EXTEND_SELECTION = ['ExSel'];
  static readonly HOME = ['Home'];
  static readonly INSERT = ['Insert'];
  static readonly PAGE_DOWN = ['PageDown'];
  static readonly PAGE_UP = ['PageUp'];
  static readonly PASTE = ['Paste'];
  static readonly SHIFT = ['Shift'];
  static readonly SPACE = [' ', 'SpaceBar'];
  static readonly TAB = ['Tab'];
  static readonly ALT = ['Alt'];
  static readonly ALTGR = ['AltGraph'];
  static readonly META = ['Meta'];
}

export function isKey(value: string, key: Array<string>) {
  return key.indexOf(value) >= 0;
}

export function isKeyInKeys(value: string, keys: Array<Array<string>>) {
  return keys.some(key => isKey(value, key));
}
