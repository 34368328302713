import {Injectable} from '@angular/core';
import {
  LocaleService,
  ModalDialogComponent,
  ModalDialogLayout,
  ModalHelperService,
  ResponsiveService,
  TranslationsService,
} from 'common';
import {TicketsCanceledService} from '../data/tickets-canceled.service';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import {TicketCanceled} from '../data/ticket-canceled';
import {iif, of, zip} from 'rxjs';
import {ITicketsCanceledViewService} from './i-tickets-canceled-view-service';
import {MeasuringDao} from '../../../marketing/measuring.dao';
import {GamesDao} from '../../data/games.dao';
import {TicketsService} from '../data/tickets.service';
import {GenericTicket} from '../data/generic-ticket';
import {TicketNotFoundError} from '../ticket-not-found-error';
import {DatePipe} from '@angular/common';
import {environment} from '~environments/environment';
import {TlRouterService} from '../../../common/router/tl-router.service';
import {PrivateInfoService} from '../../../backend/private-info/private-info.service';

@Injectable()
export class TicketsCanceledViewService implements ITicketsCanceledViewService {
  constructor(
    private localeService: LocaleService,
    private gamesDao: GamesDao,
    private ticketsService: TicketsService,
    private measuringDao: MeasuringDao,
    private modalHelperService: ModalHelperService,
    private privateInfoService: PrivateInfoService,
    private routerService: TlRouterService,
    private responsiveService: ResponsiveService,
    private ticketCanceledService: TicketsCanceledService,
    private translationsService: TranslationsService,
  ) {}

  checkTicketsCanceled(): void {
    this.ticketCanceledService
      .getData()
      .pipe(
        distinctUntilChanged(),
        filter((ticketsCanceled: Array<TicketCanceled>) => !!ticketsCanceled),
        switchMap((ticketsCanceled: Array<TicketCanceled>) =>
          zip(
            ticketsCanceled.map((ticketCanceled: TicketCanceled) =>
              this.ticketsService.getTicket(ticketCanceled.id).pipe(
                first(),
                switchMap((ticket: GenericTicket) =>
                  iif(
                    () => !!ticket,
                    of(ticket),
                    this.gamesDao.getTicket(ticketCanceled.id).pipe(
                      first(),
                      catchError(() => {
                        throw new TicketNotFoundError(ticketCanceled.id);
                      }),
                    ),
                  ),
                ),
                switchMap((ticket: GenericTicket) =>
                  this.translationsService
                    .getCompiledTranslation(
                      'tickets.warningTicketNotValid.subTitle',
                      {
                        game: ticket.name,
                        date: new DatePipe(this.localeService.getLocale()).transform(
                          ticketCanceled.raffleDate,
                          environment.locale.dateFormats.format,
                        ),
                      },
                    )
                    .pipe(
                      first(),
                      map(
                        (
                          subtitle: string,
                        ): {subtitle: string; ticketCanceled: TicketCanceled} => ({
                          subtitle,
                          ticketCanceled,
                        }),
                      ),
                    ),
                ),
              ),
            ),
          ),
        ),
        map((data: Array<{subtitle: string; ticketCanceled: TicketCanceled}>) => {
          const subtitle: string = data
            .map(
              (item: {subtitle: string; ticketCanceled: TicketCanceled}) =>
                item.subtitle,
            )
            .join('<br>');
          const ticketsCanceled: Array<TicketCanceled> = data.map(
            (item: {subtitle: string; ticketCanceled: TicketCanceled}) =>
              item.ticketCanceled,
          );
          return {subtitle, ticketsCanceled};
        }),
      )
      .subscribe(
        (data: {subtitle: string; ticketsCanceled: Array<TicketCanceled>}): void => {
          // launch Modal
          const hasMultipleTickets = data.ticketsCanceled.length > 1;
          let modalComponentParams;
          if (hasMultipleTickets) {
            modalComponentParams = {
              componentParams: {
                type: 'ok',
                layout: ModalDialogLayout.VERTICAL,
                image: 'assets/img/boletos/ticket-not-validated.svg',
                title: {key: 'tickets.warningTicketNotValid.title.other'},
                subtitle: data.subtitle,
                message: {key: 'tickets.warningTicketNotValid.messageNotRepeat'},
                accept: {key: 'global.understood'},
              },
            };
          } else {
            modalComponentParams = {
              componentParams: {
                type: 'ok_cancel',
                layout: ModalDialogLayout.VERTICAL,
                image: 'assets/img/boletos/ticket-not-validated.svg',
                title: {key: 'tickets.warningTicketNotValid.title.one'},
                subtitle: data.subtitle,
                message: {key: 'tickets.warningTicketNotValid.message'},
                cancel: {key: 'global.understood'},
                accept: {key: 'tickets.warningTicketNotValid.retryBet'},
              },
            };
          }
          this.modalHelperService
            .openOkModal(ModalDialogComponent, modalComponentParams)
            .subscribe(() => {
              if (!hasMultipleTickets) {
                const routes = environment.locale.routes;
                const route = this.responsiveService.isMobile()
                  ? [
                      routes.mobile.main.tickets,
                      routes.mobile.main.ticket,
                      data.ticketsCanceled[0].id,
                      routes.tickets.repeat,
                    ]
                  : [
                      routes.mobile.main.ticket,
                      data.ticketsCanceled[0].id,
                      routes.tickets.repeat,
                    ];
                this.routerService.navigate(route);
              }
              const ticketsIds = data.ticketsCanceled
                .map((t: TicketCanceled) => t.id)
                .join('_');
              this.privateInfoService.update().pipe(delay(400), first()).subscribe();
              this.measuringDao
                .sendBackendEvent(`TICKET_NOT_VALIDATED_${ticketsIds}`)
                .subscribe();
            });
        },
      );
  }
}
