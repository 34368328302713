export class Choice {
  value: string;

  label: string;

  description: string;

  img: string;

  header: string;

  subHeader: string;

  static createFromBackend(obj: any) {
    let choice = new Choice();

    choice.label = obj.label;
    choice.value = obj.value;
    choice.description = obj.desc;
    choice.img = obj.imgUrlSVG || obj.imgUrl;
    choice.header = obj.header;
    choice.subHeader = obj.subHeader;

    return choice;
  }

  static fromJSON(json: any): Choice {
    if (typeof json === 'string') {
      return JSON.parse(json, Choice.reviver) as Choice;
    } else if (json !== undefined && json !== null) {
      let tmp = Object.create(Choice.prototype);
      return Object.assign(tmp, json);
    } else {
      return json;
    }
  }

  static reviver(key: string, value: any): any {
    return key === '' ? Choice.fromJSON(value) : value;
  }

  toJSON() {
    return Object.assign({}, this);
  }
}
