import {LotteryBooth} from '../../booth/data/lottery-booth';

import {BoothShipmentStatus} from './booth-shipment-status';

export class ShipmentBooth extends LotteryBooth {
  status = new BoothShipmentStatus();

  constructor(lotteryBooth: LotteryBooth) {
    super();
    Object.assign(this, lotteryBooth);
  }
}
