import {NgModule} from '@angular/core';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

import {ArrayFromNumberPipe} from './array-from-number.pipe';
import {ArrayFromRangePipe} from './array-from-range.pipe';
import {ArrayLimitPipe} from './array-limit.pipe';
import {CastArrayPipe} from './cast-array.pipe';
import {ClipboardCopyDirective} from './clipboard-copy.directive';
import {DistancePipe} from './distance.pipe';
import {FilterArrayPipe} from './filter-array.pipe';
import {IsTemplatePipe} from './is-template.pipe';
import {KeyValuePipe} from './key-value.pipe';
import {KeysPipe} from './keys.pipe';
import {LimitMoreItemsPipe} from './limit-more-items.pipe';
import {MapValuesPipe} from './map-value.pipe';
import {OrdinalSuffixPipe} from './ordinal-suffix.pipe';
import {SortPipe} from './sort.pipe';

@NgModule({
  imports: [NgbTooltipModule],
  exports: [
    ArrayFromNumberPipe,
    ArrayFromRangePipe,
    ArrayLimitPipe,
    LimitMoreItemsPipe,
    CastArrayPipe,
    ClipboardCopyDirective,
    DistancePipe,
    FilterArrayPipe,
    IsTemplatePipe,
    KeysPipe,
    KeyValuePipe,
    MapValuesPipe,
    OrdinalSuffixPipe,
    SortPipe,
  ],
  declarations: [
    ArrayFromNumberPipe,
    ArrayFromRangePipe,
    ArrayLimitPipe,
    LimitMoreItemsPipe,
    CastArrayPipe,
    ClipboardCopyDirective,
    DistancePipe,
    FilterArrayPipe,
    IsTemplatePipe,
    KeysPipe,
    KeyValuePipe,
    MapValuesPipe,
    OrdinalSuffixPipe,
    SortPipe,
  ],
})
export class UtilModule {}
