import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PlatformMetadataPipe} from './platform-metadata.pipe';
import {VirtualKeyboardSupportedDirective} from './virtual-keyboard-supported.directive';
import {IfMobileDirective} from './if-platform/if-mobile.directive';
import {IfDesktopDirective} from './if-platform/if-desktop.directive';

@NgModule({
  imports: [CommonModule],
  exports: [
    IfDesktopDirective,
    IfMobileDirective,
    PlatformMetadataPipe,
    VirtualKeyboardSupportedDirective,
  ],
  declarations: [
    IfDesktopDirective,
    IfMobileDirective,
    PlatformMetadataPipe,
    VirtualKeyboardSupportedDirective,
  ],
})
export class ResponsiveUtilsModule {}
