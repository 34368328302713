import {Injectable, OnDestroy} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {arrayOfLength, isNumeric, Logger, RouterHistoryService} from 'common';
import {Observable, of, Subscription} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import {BetMetadata} from '../../../game-metadata/data/bet-metadata';
import {BetRuleTypeMetadata} from '../../../game-metadata/data/bet-rule-type-metadata';
import {PickedMultiplesBetRuleTypeMetadata} from '../../../game-metadata/data/picked-multiples-bet-rule-type-metadata';
import {SelectionGameTypeMetadata} from '../../../game-metadata/data/selection-game-type-metadata';
import {generateSelectionsArray} from '../utils/bet-utils';

import {CombinationForm} from './combination-form';
import {FormService} from './form.service';
import {TypeValidators} from './type-validators';
import {SelectionBetRuleTypeMetadata} from '../../../game-metadata/data/selection-bet-rule-type-metadata';
import {RequiredMultiplesBetRuleTypeMetadata} from '../../../game-metadata/data/required-multiples-bet-rule-type-metadata';

@Injectable()
export class NumbersFormService extends FormService implements OnDestroy {
  constructor(
    protected fb: FormBuilder,
    protected logger: Logger,
    protected historyService: RouterHistoryService,
  ) {
    super(fb);
  }

  restore(defaults: any): void {
    this.form.get('random').reset(defaults.random);

    super.restore(defaults);

    // trigger bettype hooks to set validators
    this.form.get('bet.currentBetType').updateValueAndValidity();
    this.form.get('bet.autoFields').reset(defaults.bet.autoFields);

    this.clearCombinations();
    defaults.bet.combinations.forEach((combination, index) => {
      let combinationForm = this.createCombinationFormGroup(index);
      combinationForm.reset(combination);

      if (this.form.get('random').value) {
        (this.form.get('bet.combinations') as FormArray).push(combinationForm);
      }
    });

    this.form.get('bet.extraFields').reset(defaults.bet.extraFields);
  }

  createCombinationFormGroup(index?: number): CombinationForm {
    const betMetadata = this.currentBetMetadata();

    const combinationForm = new CombinationForm({});
    combinationForm.setIndex(index);

    betMetadata
      .getLocalRule()
      .ruleTypes.filter(
        ruleType => !this.excludedTypes.some(id => id === ruleType.type.id),
      )
      .forEach(ruleType => this.createFieldForMetadata(ruleType, combinationForm));

    betMetadata
      .getLocalRule()
      .ruleTypes.forEach(ruleType =>
        this.combinationSubscriptions.push(
          ...this.setupTypeDependencies(ruleType.type, combinationForm),
          ...this.setupRuleTypeDependencies(ruleType, combinationForm),
        ),
      );

    betMetadata
      .getLocalRule()
      .ruleTypes.filter(ruleType => ruleType.type.global)
      .forEach(ruleType => {
        this.combinationSubscriptions.push(
          this.form
            .get(`bet.extraFields.${ruleType.type.id}`)
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
              if (
                value === null ||
                value === undefined ||
                (Array.isArray(value) && value.filter(x => !!x).length === 0)
              ) {
                combinationForm.get(ruleType.type.id).reset();
              } else {
                combinationForm.get(ruleType.type.id).setValue(value);
              }
            }),
        );
      });

    this.setCombinationValidators(combinationForm, betMetadata);

    if (!this.form.get('random').value) {
      this.keepValidCombinationsOnChanges(combinationForm);

      combinationForm.updateValueAndValidity();
    }
    return combinationForm;
  }

  setCombinationValidators(
    combinationForm: FormGroup,
    betMetadata: BetMetadata,
  ): void {
    const combinationValidators = betMetadata
      .getLocalRule()
      .ruleTypes.filter(rule => rule instanceof PickedMultiplesBetRuleTypeMetadata)
      .map((rule: PickedMultiplesBetRuleTypeMetadata) =>
        TypeValidators.fixedPicksForRule(rule),
      );

    if (combinationValidators.length) {
      combinationForm.setValidators(Validators.compose(combinationValidators));
    }
  }

  generateRandomCombination(combinationForm: FormGroup): FormGroup {
    const betMetadata = this.currentBetMetadata();

    betMetadata
      .getLocalRule()
      .ruleTypes // don't alter boolean types
      .filter(ruleType => ruleType.type.playType !== 'BOOLEAN')
      .filter(ruleType => !ruleType.type.global)
      .forEach(ruleType => {
        const field = combinationForm.get(ruleType.type.id);

        if (!field) {
          // excluded types won't exist
          return;
        }

        if (ruleType instanceof PickedMultiplesBetRuleTypeMetadata) {
          const sourceField = combinationForm.get(
            ruleType.multiplesSourceType.type.id,
          );
          field.reset(sourceField.value.map(value => value.length > 1 || false));
        } else {
          field.reset(
            this.generateRandomValue(ruleType, betMetadata.hasVariableMultiplier()),
          );
        }
      });

    return combinationForm;
  }

  generateRandomForcedLocalGlobals(): void {
    const forcedLocalTypes = this.gameMetadata.getForcedLocalTypes();

    const betMetadata = this.currentBetMetadata();

    betMetadata.getGlobalRules().forEach(rule =>
      rule.ruleTypes
        .filter(ruleType => !this.excludedTypes.some(id => id === ruleType.type.id))
        .filter(
          ruleType =>
            forcedLocalTypes.includes(ruleType.type) || ruleType.type.global,
        )
        .forEach(ruleType => {
          this.form
            .get(`bet.extraFields.${ruleType.type.id}`)
            .setValue(
              this.generateRandomValue(
                ruleType,
                betMetadata.hasVariableMultiplier(),
              ),
            );
        }),
    );
  }

  generateRandomGlobals(): void {
    const betMetadata = this.currentBetMetadata();

    betMetadata
      .getGlobalRuleTypes()
      .filter(ruleType => !this.excludedTypes.some(id => id === ruleType.type.id))
      // .filter(ruleType => ruleType.type.playType !== 'BOOLEAN')
      .forEach(ruleType => {
        const autoControl = this.form.get(`bet.autoFields.${ruleType.type.id}`);
        const extraControl = this.form.get(`bet.extraFields.${ruleType.type.id}`);

        if (!extraControl) {
          return;
        }

        if (autoControl) {
          // case for optionals
          if (this.gameMetadata.types.get(ruleType.type.id).playType === 'BOOLEAN') {
            extraControl.setValue(autoControl.value);
            return;
          }
        }

        if (!autoControl || autoControl.value) {
          extraControl.setValue(
            this.generateRandomValue(ruleType, betMetadata.hasVariableMultiplier()),
          );
        }
      });
    if (this.form.get(`bet.extraFields.reintegro`)) {
      const value = this.form.get(`bet.extraFields.reintegro`).value;
      if (!value?.toString) {
        this.logger.warn('WEB-2419 Invalid value for reintegro', {
          formData: this.form,
        });
      }
    }
  }

  generateRandomBet(): void {
    const betsNumber = this.form.get('betsNumber').value;
    const combinationsArray = this.form.get('bet.combinations') as FormArray;

    this.clearCombinations();
    this.form.get('bet.extraFields').reset();

    arrayOfLength(betsNumber).forEach((_, index) => {
      const combination = this.createCombinationFormGroup(index);

      this.generateRandomCombination(combination);

      Object.keys((this.form.get('bet.autoFields') as FormGroup).controls).forEach(
        field => {
          if (combination.controls.hasOwnProperty(field)) {
            if (this.gameMetadata.types.get(field).playType === 'BOOLEAN') {
              combination
                .get(field)
                .setValue(this.form.get(`bet.autoFields.${field}`).value);
            } else if (!this.form.get(`bet.autoFields.${field}`).value) {
              combination.get(field).reset();
            }
          }
        },
      );

      combinationsArray.push(combination);
    });

    this.generateRandomGlobals();
  }

  clearCombination(combinationForm: FormGroup): void {
    combinationForm.reset();

    for (let id in combinationForm.controls) {
      if (combinationForm.controls.hasOwnProperty(id)) {
        const fieldType = this.gameMetadata.types.get(id);

        if (fieldType && fieldType.playType === 'BOOLEAN') {
          combinationForm.controls[id].setValue(fieldType.defaultValue);
        }
      }
    }
  }

  clearGlobals(): void {
    let globals = <FormGroup>this.form.get('bet.extraFields');
    globals.reset();

    for (let id in globals.controls) {
      if (globals.controls.hasOwnProperty(id)) {
        const fieldType = this.gameMetadata.types.get(id);

        if (fieldType.playType === 'BOOLEAN') {
          globals.controls[id].setValue(fieldType.defaultValue);
        }
      }
    }
  }

  clearForcedLocalGlobals(): void {
    let globals = <FormGroup>this.form.get('bet.extraFields');

    this.gameMetadata
      .getForcedLocalTypes()
      .filter(type => !!globals.controls[type.id])
      .forEach(type =>
        globals.controls[type.id].reset(
          type.playType === 'BOOLEAN' ? type.defaultValue : undefined,
        ),
      );
  }

  toBackend(): Record<string, any> {
    const betMetadata = this.currentBetMetadata();
    const extraFields = this.getBetExtrafields();
    return {
      almanaque: this.form.get('external').value,
      confirmacion: false,
      abonarse: this.form.get('subscribe').value,
      abonoDays: !this.form.get('subscriptionType').value
        ? this.buildSubscribableDatesField()
        : null,
      abonoMinJackpotAmount: this.form.get('subscriptionType').value
        ? this.buildSubscribableJackpotField()
        : undefined,
      aleatorio: this.form.get('random').value,
      groupIdToSpoof: this.form.get('groupId').value ?? undefined,
      juego: this.gameMetadata.id,
      quickPlay: this.form.get('quickplay').value,
      sorteoIds: Array.isArray(this.form.get('raffles').value)
        ? this.form.get('raffles').value
        : [this.form.get('raffles').value],
      numApuestas: (this.form.get('bet.combinations') as FormArray).length,
      totalApuesta: this.totalPrice,
      comparticion: this.buildShareField(),
      extraInfo: this.extraInfoToBackend(),
      combinacionJugada: {
        juego: this.gameMetadata.id,
        aleatoria: this.form.get('random').value,
        bets: [
          ...(this.form.get('bet.combinations') as FormArray).controls,
          ...extraFields,
        ]
          .filter((group: FormGroup) => Object.keys(group.controls).length > 0)
          .sort((c1, c2) => c1['index'] - c2['index'])
          .map((combination: CombinationForm) => {
            let returnData = {
              amountBet: 1,
              betId: betMetadata.id,
              types: Object.keys((combination as FormGroup).controls)
                .filter(field => {
                  const control = (combination as FormGroup).controls[field];
                  const typeMetadata = this.gameMetadata.types.get(field);

                  if (typeMetadata.playType === 'BOOLEAN_ARRAY') {
                    if (typeMetadata.optional) {
                      const rule = betMetadata.getRuleTypeForType(field);
                      return !(
                        Array.isArray(control.value) &&
                        TypeValidators.requiredTrue(rule['requiredTrue'])(control)
                      );
                    } else {
                      return control.valid;
                    }
                  } else {
                    return !(
                      Array.isArray(control.value) &&
                      TypeValidators.minLengthNotEmpty(control.value.length)(control)
                    );
                  }
                })
                .map(field => {
                  const control = (combination as FormGroup).controls[field];
                  const typeMetadata = this.gameMetadata.types.get(field);

                  let values = Array.isArray(control.value)
                    ? control.value
                    : [control.value];

                  if (typeMetadata.type === 'SELECTION') {
                    values = values.map(value => ({
                      selections: (Array.isArray(value) ? value : [value]).map(
                        v => ({value: v}),
                      ),
                    }));
                  }

                  // WEB-2419 CAPTURE IN TOBACKEND
                  if (
                    typeMetadata.id === 'reintegro' &&
                    !(values && values[0]?.toString)
                  ) {
                    this.logger.warn(
                      "WEB-2419 invalid value 'reintegro' in tobackend",
                      {
                        typeMetaData: typeMetadata,
                        value: values,
                        control: control,
                        combination: combination.value,
                        random: JSON.stringify(this.form.get('random').value),
                      },
                    );
                  }

                  return {
                    type: typeMetadata.playType,
                    typeId: typeMetadata.id,
                    value: values,
                  };
                }),
            };

            if (this.gameMetadata.includeBoardId) {
              returnData['boardId'] = this.boardIndexToId(combination.index);
            }

            return returnData;
          }),
      },
    };
  }

  getMinimumBets(): number {
    const betMetadata = this.currentBetMetadata();

    if (betMetadata) {
      return betMetadata.rules[0].minPanels * betMetadata.minMultiplier;
    } else {
      return 1;
    }
  }

  getMaximumBets(): Observable<number> {
    const betMetadata = this.currentBetMetadata();

    if (betMetadata) {
      // due how betsnumber in progol is a different thing in random and manual
      // modes this has to be only the panels for this kind of games
      // incidentally it would work for quiniela too because quiniela also has
      // only 1 panel, however you can't random a multiple quiniela
      // In fact random betsnumber selector was never meant to be used with
      // multiple modes, so this might break when it does
      // this can be done because this is only used for the counter
      if (betMetadata.multiplier === 0) {
        return of(betMetadata.rules[0].maxPanels);
      } else {
        return of(betMetadata.rules[0].maxPanels * betMetadata.maxMultiplier);
      }
    } else {
      return of(1);
    }
  }

  getBetType(): string {
    return this.form.get('bet.currentBetType').value;
  }

  setBetType(betId: string) {
    this.form.get('bet.currentBetType').setValue(betId);
  }

  stateRandom(): void {
    this.clearCombinations();
    this.form
      .get('betsNumber')
      .valueChanges.pipe(takeUntil(this.stateChange))
      .subscribe(() => this.generateRandomBet());

    this.form
      .get('bet.currentBetType')
      .setValue(this.gameMetadata.getFirstBet(this.form.get('betGroup').value).id);
    this.form
      .get('betsNumber')
      .setValue(
        this.gameMetadata.getFirstBet(this.form.get('betGroup').value).getLocalRule()
          .minPanels,
      );
  }

  resetExtrafields(): void {
    this.setExtrafields(this.currentBetMetadata());
  }

  protected getBetExtrafields() {
    let extraFields = [];
    // skip extrafields if it only has global types
    if (
      Object.keys((this.form.get('bet.extraFields') as FormGroup).controls).every(
        key => !this.gameMetadata.getTypeMetadata(key).global,
      )
    ) {
      extraFields = [this.form.get('bet.extraFields')];
    }
    return extraFields;
  }

  protected currentBetMetadata(): BetMetadata {
    return (
      this.form &&
      this.getBetMetadata(
        this.form.get('bet.currentBetType').value,
        this.form.get('betGroup').value,
      )
    );
  }

  /**
   * Autoselect a triple if any remaining when selecting the match as reduced
   * and there are no selections on the main table.
   */
  protected setupRuleTypeDependencies(
    ruleType: BetRuleTypeMetadata,
    context: FormGroup,
  ): Array<Subscription> {
    let subscriptions = [];

    if (ruleType instanceof PickedMultiplesBetRuleTypeMetadata) {
      const picksControl = context.get(ruleType.type.id);
      const sourceRule = ruleType.multiplesSourceType;
      const sourceControl = context.get(sourceRule.type.id);

      subscriptions.push(
        picksControl.valueChanges
          .pipe(
            distinctUntilChanged(),
            map(() => picksControl.value),
            startWith(arrayOfLength(ruleType.required, false)),
            pairwise(),
            // build an array with true for the fields that changed from false
            // to true [f, t, t, f] + [t, t ,f, f] => [t, f, f, f]
            map(pair => pair[0].map((old, index) => !old && pair[1][index])),
          )
          .subscribe({
            next: value => {
              value.forEach((controlValue, index) => {
                if (
                  controlValue === true && // index is selected
                  Array.isArray(sourceControl.value) && // selections are empty
                  (sourceControl.value[index] == null ||
                    sourceControl.value[index].length === 0) &&
                  (picksControl.errors === null || // last pick
                    (picksControl.errors.requiredTrue && // remaining picks
                      picksControl.errors.requiredTrue.requiredLength >
                        picksControl.errors.requiredTrue.actualLength))
                ) {
                  const selections = generateSelectionsArray(sourceControl.value);
                  if (
                    sourceRule.minTriples > (selections[2] || 0) &&
                    sourceRule.minDoubles - (selections[1] || 0) <= 0
                  ) {
                    // only triples left
                    sourceControl
                      .get('' + index)
                      .setValue(
                        (<SelectionGameTypeMetadata>sourceRule.type).choices.map(
                          choice => choice.value,
                        ),
                      );
                  }
                }
              });
            },
            error: error => this.logger.error('Wrong value in dependency:', error),
          }),
      );
    }

    return subscriptions;
  }

  protected setCalculatePriceSubscription(): void {
    this.mainFormSubscriptions.push(
      this.form.get('raffles').statusChanges.subscribe(() => this.calculatePrice()),
    );
    this.mainFormSubscriptions.push(
      this.form.get('promo').statusChanges.subscribe(() => this.calculatePrice()),
    );
  }

  protected createBetForm(): void {
    if (this.betFormSubscriptions) {
      this.betFormSubscriptions.forEach(sub => sub.unsubscribe());
      this.betFormSubscriptions = [];
    }

    if (!this.gameMetadata.bets.has(this.form.get('betGroup').value)) {
      this.logger.error('Betgroup error: bet group not found', new Error().stack, {
        gameMetadata: this.gameMetadata,
        form: this.form.value,
        history: this.historyService.dump(),
      });
    }

    const betGroup = this.form.get('betGroup').value;
    const firstBet = this.gameMetadata.getFirstBet(betGroup, 'Numbers Form 531');
    this.form.setControl(
      'bet',
      this.fb.group({
        currentBetType: [firstBet.id, Validators.required],
        autoFields: this.fb.group({}),
        combinations: this.fb.array([], Validators.required),
        extraFields: this.fb.group({}),
      }),
    );

    this.betFormSubscriptions.push(
      this.form
        .get('bet.currentBetType')
        .valueChanges.pipe(
          distinctUntilChanged(),
          map(() => this.currentBetMetadata()),
        )
        .subscribe((betMetadata: BetMetadata) => {
          if (this.rafflesFormSubscriptions) {
            this.rafflesFormSubscriptions.forEach(sub => sub.unsubscribe());
            this.rafflesFormSubscriptions = [];
          }

          this.setAutofields(betMetadata);
          this.setExtrafields(betMetadata);

          const minBets =
            betMetadata.minMultiplier * betMetadata.getLocalRule().minPanels;
          this.addBetsNumberValidation(Validators.min(minBets));
          this.setBetValidators(betMetadata);

          this.rebuildCombinations.next();
        }),
    );

    this.form.get('bet.currentBetType').updateValueAndValidity();

    const firstBetLocalRule = this.gameMetadata.getFirstBet(
      betGroup,
      'Numbers Form 543',
    );
    this.form
      .get('betsNumber')
      .setValue(
        firstBetLocalRule.getLocalRule().minPanels * firstBetLocalRule.minMultiplier,
      );

    this.betFormSubscriptions.push(
      this.form
        .get('bet.autoFields')
        .valueChanges.pipe(filter(() => this.form.get('random').value))
        .subscribe(() => this.generateRandomBet()),
    );

    this.betFormSubscriptions.push(
      this.form.get('bet').statusChanges.subscribe(() => this.calculatePrice()),
    );
  }

  /**
   * Creates a field for each global type from the current bet that can be
   * chosen in automatic mode.
   */
  protected setAutofields(betMetadata: BetMetadata): void {
    let autoFields = {};

    const autoFieldTypes = betMetadata
      .getAllTypes()
      .filter(
        type => type.playable && (type.optional || type.playType === 'BOOLEAN'),
      )
      .filter(type => !this.excludedTypes.some(id => id === type.id));

    autoFieldTypes.forEach(
      type => (autoFields[type.id] = [type.defaultValue, Validators.required]),
    );

    (<FormGroup>this.form.get('bet')).setControl(
      'autoFields',
      this.fb.group(autoFields),
    );

    // set dependencies after all autofields are in place
    autoFieldTypes.forEach(type => {
      this.rafflesFormSubscriptions.push(
        ...this.setupTypeDependencies(
          type,
          this.form.get('bet.autoFields') as FormGroup,
        ),
      );
    });
  }

  /**
   * Creates a field for each global type in the current bet.
   */
  protected setExtrafields(betMetadata: BetMetadata): void {
    const extraFieldsForm = this.fb.group({});
    (<FormGroup>this.form.get('bet')).setControl('extraFields', extraFieldsForm);
    betMetadata
      .getGlobalRuleTypes()
      .filter(ruleType => !this.excludedTypes.some(id => id === ruleType.type.id))
      .filter(ruleType => !extraFieldsForm.controls.hasOwnProperty(ruleType.type.id))
      .forEach(ruleType => this.createFieldForMetadata(ruleType, extraFieldsForm));
  }

  protected calculatePrice(): void {
    let price = 0;
    this.multiplier = 0;

    if (!this.form.get('bet').valid) {
      this.price.next(0);

      if (!this.form.get('random').value) {
        this.form.get('betsNumber').setValue(this.multiplier);
      }
      return;
    }

    const betMetadata = this.currentBetMetadata();

    const combinationGroups = (this.form.get('bet.combinations') as FormArray)
      .controls;

    let combinationPrices = [];

    // price from combinations
    combinationGroups.forEach((combinationGroup: FormGroup) => {
      const combinationControls = combinationGroup.controls;

      const fieldPrices = [];
      const fieldMultipliers = [];

      Object.keys(combinationControls)
        .filter(fieldId =>
          this.shouldCalculatePriceForControl(combinationControls[fieldId]),
        )
        .forEach(fieldId => {
          this.checkGameTypeIntegrity(fieldId);

          const fieldPrice = this.getFieldPrice(fieldId);

          if (fieldPrice === 0) {
            return;
          }

          fieldPrices.push(fieldPrice);
          // assume every panel has the same bettype
          fieldMultipliers.push(betMetadata.multiplier);
        });

      const combinationPrice = fieldPrices.reduce(
        (total, fPrice, index) => fPrice * fieldMultipliers[index] + total,
        0,
      );

      combinationPrices.push(combinationPrice);
      // assume every combination has same bettype
      this.multiplier += betMetadata.multiplier;
    });

    // If has the attribute fixedPrice in betMetadata
    // the combination total price must be the fixedPrice
    price = betMetadata.fixedPrice
      ? betMetadata.fixedPrice
      : combinationPrices.reduce((total, cPrice) => total + cPrice, 0);

    const extraControls = (this.form.get('bet.extraFields') as FormGroup).controls;

    Object.keys(extraControls).forEach(field => {
      if (
        extraControls[field].valid &&
        extraControls[field].value &&
        !this.gameMetadata.types.get(field).global
      ) {
        // globals are placed on extrafields for selecting but are computed in the
        // combination
        const gameType = this.gameMetadata.types.get(field);
        price += gameType.price || 0;
      }
    });

    const selectedRaffles = this.form.get('raffles').value;

    price *= Array.isArray(selectedRaffles) ? selectedRaffles.length : 1;

    if (!this.form.get('random').value) {
      this.form.get('betsNumber').setValue(this.multiplier);

      // when manual wait until 'betsNumber' is validated to know if the form is
      // is valid so we can emit the price or 0 if the form is still invalid
      this.form.statusChanges
        .pipe(first())
        .pipe(map(status => (status === 'VALID' ? price : 0)))
        .subscribe(nextValue =>
          this.price.next(
            this.form.get('promo').value === 'prereserveshared' ? 0 : nextValue,
          ),
        );
    }

    this.price.next(this.form.get('promo').value === 'prereserveshared' ? 0 : price);
  }

  protected stateManual(): void {
    this.clearCombinations();
    this.form
      .get('bet.currentBetType')
      .valueChanges.pipe(takeUntil(this.stateChange))
      .subscribe(() => {
        this.clearCombinations();
        Object.keys((<FormGroup>this.form.get('bet.extraFields')).controls).forEach(
          typeId => {
            let type = this.gameMetadata.types.get(typeId);

            if (!type) {
              this.logger.info(`No game type ${typeId} found in play manual`, {
                types: this.gameMetadata.getTypeKeys(),
              });
            }

            if (type.playType === 'BOOLEAN') {
              this.form
                .get(`bet.extraFields.${typeId}`)
                .setValue(type.defaultValue || false);
            } else {
              this.form.get(`bet.extraFields.${typeId}`).reset();
            }
          },
        );
      });

    // Select default bet for manual
    let betId: string;
    if (this.gameMetadata.uiMetadata.layout === 'matches_3_columns') {
      // PROGOL free mode
      betId = this.gameMetadata.getFreeModeBet().id;
    } else {
      // Remaining games
      betId = this.gameMetadata.getFirstBet(this.form.get('betGroup').value).id;
    }

    this.form.get('bet.currentBetType').setValue(betId);
  }

  protected setBetValidators(betMetadata: BetMetadata): void {
    let validators = [];
    const forcedLocals = betMetadata.getForcedLocalTypes();

    if (forcedLocals.length) {
      validators.push(
        TypeValidators.requiredMainCombination(
          forcedLocals.map(rule => rule.type.id),
        ),
      );
    }

    if (
      betMetadata.isMultiplierGlobal() &&
      betMetadata.getLocalRule().maxPanels === 1 &&
      betMetadata
        .getAllRuleTypes()
        .some(
          (rule: BetRuleTypeMetadata) =>
            rule instanceof SelectionBetRuleTypeMetadata ||
            rule instanceof RequiredMultiplesBetRuleTypeMetadata,
        )
    ) {
      validators.push(TypeValidators.allowedGlobalBetMultiplier(betMetadata));
    }

    this.form.get('bet').setValidators(validators);
  }

  protected checkGameTypeIntegrity(fieldId: string): void {
    const gameType = this.gameMetadata.types.get(fieldId);
    if (!gameType) {
      this.logger.info('gameType no existe, info en el context detallada', {
        field: fieldId,
        formValue: this.form.value,
        gameMetadata: this.gameMetadata.toJSON(),
      });
    }
  }

  protected getFieldPrice(fieldId: string): number {
    const price = this.gameMetadata.types.get(fieldId).price;
    // main type has price undefined, all other types have price >=0
    return isNumeric(price) ? price : this.gameMetadata.price;
  }
}
