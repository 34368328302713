import {Injectable} from '@angular/core';
import {escapeRegExp} from 'common';
import {combineLatest, merge, Observable, Subject} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mapTo,
  skip,
  startWith,
  take,
} from 'rxjs/operators';

import {BoothsFavoriteViewService} from './booths-favorite-view.service';
import {LotteryBooth} from './data/lottery-booth';
import {LotteryBoothsGeolocationViewService} from './lottery-booths-geolocation-view.service';

@Injectable()
export class BoothsSearchViewService {
  booths: Observable<Array<LotteryBooth>>;

  searchSubject = new Subject<string>();

  searchBounds = new Subject<google.maps.LatLngBounds>();

  constructor(
    private lotteryBoothService: LotteryBoothsGeolocationViewService,
    private boothsFavoriteViewService: BoothsFavoriteViewService,
  ) {
    this.booths = combineLatest([
      this.lotteryBoothService.lotteryBooths,
      merge(this.searchSubject, this.searchBounds).pipe(startWith('')),
    ]).pipe(map(([list, query]) => this.filterBooths(list, query)));
  }

  /** Return a observable with these conditions
   *  - first time place fav booth in start of array
   *  - if booth changes remove first and not sorting again
   *  - subscribe filter input and filter data
   *  - subscribe to main data change
   *
   *  for the list fav component
   */
  public getFavoriteList(): Observable<Array<LotteryBooth>> {
    const sortFirstBoothFav$ = this.boothsFavoriteViewService.favoriteBooth.pipe(
      skip(1),
      mapTo(false),
      startWith(true),
    );

    const filterChange$ = merge(this.searchSubject, this.searchBounds).pipe(
      startWith(''),
      distinctUntilChanged(),
    );

    return combineLatest([
      this.lotteryBoothService.lotteryBooths,
      filterChange$,
      this.boothsFavoriteViewService.favoriteBooth.pipe(take(1)),
      sortFirstBoothFav$,
    ]).pipe(
      map(([data, query, boothFavoriteId, sortFirstBoothFav]) => {
        const dataFiltered = this.filterBooths(data, query);

        if (boothFavoriteId && sortFirstBoothFav) {
          const index = dataFiltered.findIndex(
            booth => booth.id === boothFavoriteId,
          );
          const sortedList = [...dataFiltered];

          if (index >= 0) {
            const booth = sortedList.splice(index, 1);
            sortedList.unshift(booth[0]);

            return sortedList;
          }
        }

        return dataFiltered;
      }),
    );
  }

  private filterBooths(
    booths: Array<LotteryBooth>,
    query: string | google.maps.LatLngBounds,
  ): Array<LotteryBooth> {
    if (!query) {
      return booths;
    }

    if (typeof query === 'string' && query.length > 0) {
      const pattern = new RegExp(escapeRegExp(query), 'i');
      return booths.filter(
        b =>
          pattern.test(b.name) ||
          pattern.test(b.id) ||
          pattern.test(b.city) ||
          pattern.test(b.address) ||
          pattern.test(b.zipCode) ||
          pattern.test(b.state),
      );
    }

    if (query instanceof google.maps.LatLngBounds && !query.isEmpty()) {
      return booths.filter(b =>
        query.contains(new google.maps.LatLng(+b.latitude, +b.longitude)),
      );
    }

    return booths;
  }
}
