import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import {LocaleService} from '../../i18n/locale.service';
import {BigPrize, BigPrizeType} from '../big-prize';

@Component({
  selector: 'tl-big-prize',
  templateUrl: './big-prize.component.html',
  styleUrls: ['./big-prize.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BigPrizeComponent implements OnChanges {
  @Input()
  bigPrize: BigPrize | null;

  @Output()
  closeAnimation: EventEmitter<void> = new EventEmitter<void>();

  legendLabel: string;

  btnLabel: string;

  get trophyImage(): string {
    const locale = this.localeService.getLocale().split('-')[0];
    return `assets/img/big-prize/trophy-${locale}.svg`;
  }

  constructor(private localeService: LocaleService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('bigPrize')) {
      // set legendLabel
      if (this.bigPrize?.type === BigPrizeType.PT_MEDIUM) {
        this.legendLabel = 'middlePrize.infoContact';
      } else if (this.bigPrize?.parimutuel) {
        this.legendLabel = 'bigPrize.infoContactParimutuel';
      } else {
        this.legendLabel = 'bigPrize.infoContact';
      }

      // set btnLabel
      this.btnLabel =
        this.bigPrize?.type === BigPrizeType.PT_MEDIUM
          ? 'middlePrize.accept'
          : 'bigPrize.accept';
    }
  }

  onClose() {
    this.bigPrize = null;
    this.closeAnimation.next();
  }
}
