import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CounterModule} from '../counter.module';

import {Counter4Component} from './counter4.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [CommonModule, CounterModule, FormsModule],
  exports: [Counter4Component],
  declarations: [Counter4Component],
})
export class Counter4Module {}
