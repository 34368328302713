import {
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import {LoadingComponent} from './view/loading.component';

@Directive({selector: '[tlLoading]'})
export class LoadingDirective {
  private loaderFactory: ComponentFactory<LoadingComponent>;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.loaderFactory =
      this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
  }

  @Input('tlLoading')
  set state(loading: boolean) {
    this.vcRef.clear();

    if (loading) {
      this.vcRef.createComponent(this.loaderFactory);
    } else {
      this.vcRef.createEmbeddedView(this.templateRef);
    }
  }
}
