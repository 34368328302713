import {isNumeric} from '../core/number';

import {Comparator} from './comparator';

export class DistanceComparator implements Comparator<any> {
  /**
   * Define the field with the distance in the objects to be compared and the
   * order.
   *
   * @param fieldName the name of the field containing the distance
   * @param ascending order of comparison
   */
  constructor(private fieldName: string, private ascending = true) {
    this.compare = this.compare.bind(this);
  }

  compare(itemA: any, itemB: any): number {
    let factor;
    if (!isNumeric(itemA[this.fieldName])) {
      return 1;
    } else if (!isNumeric(itemB[this.fieldName])) {
      return -1;
    } else {
      factor = itemA[this.fieldName] - itemB[this.fieldName];
    }

    return this.ascending ? factor : factor * -1;
  }
}
