import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';

import {I18nModule} from '../i18n/i18n.module';
import {TranslatableTextModule} from '../i18n/translatable-text/translatable-text.module';
import {ModalDialogModule} from '../modal/modal-dialog/modal-dialog.module';
import {SanitizerModule} from '../sanitizer/sanitizer.module';

import {AlertModalComponent} from './alert-modal/alert-modal.component';
import {AlertComponent} from './alert-slidable/alert.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ModalDialogModule,
    NgbModalModule,
    SanitizerModule,
    TranslatableTextModule,
  ],
  exports: [AlertComponent, AlertModalComponent],
  declarations: [AlertComponent, AlertModalComponent],
})
export class AlertModule {}
