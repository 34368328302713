import {Injectable} from '@angular/core';
import {LocalStorage} from 'common';
import {environment} from '~environments/environment';
import {TuLoteroLocalStorageMigrationService} from '../common/local-storage/tu-lotero-local-storage-migration.service';
import {concat, Observable} from 'rxjs';

@Injectable()
export class VersionMismatchMigrationDbService {
  constructor(
    private localStorage: LocalStorage,
    private tuLoteroLocalStorageMigrationService: TuLoteroLocalStorageMigrationService,
  ) {}

  runHooks(version: number): Observable<void> {
    if (1092 <= version && version <= 1097) {
      this.localStorage.removeItem(environment.localStorageKeys.kycLaunched);
      this.localStorage.removeItem(environment.localStorageKeys.counterShares);
      this.localStorage.removeItem(environment.localStorageKeys.boothsLastDate);
    }

    if (environment.enableLocalStorageMigration) {
      // Migrate allInfo to IndexedDB
      return concat(
        ...this.tuLoteroLocalStorageMigrationService.migrateToIndexedDB([
          environment.localStorageKeys.allInfo,
        ]),
      );
    } else {
      // Migrate allInfo to IndexedDB
      return concat(
        ...this.tuLoteroLocalStorageMigrationService.migrateToLocalStorage([
          environment.localStorageKeys.allInfo,
        ]),
      );
    }
  }
}
