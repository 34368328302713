import {FormControl, Validators} from '@angular/forms';
import {isNumeric} from 'common';

import {TlContact} from '../../../contacts/data/tl-contact';

import {ShareItemFormGroup} from './share-item-form-group';

export class ShareItemContactFormGroup extends ShareItemFormGroup {
  constructor(contact: TlContact, percent?: number, blocked?: boolean) {
    super(contact, {
      percent: new FormControl(
        isNumeric(percent) ? percent : 0,
        Validators.required,
      ),
      blocked: new FormControl(!!blocked),
      maximum: new FormControl(null),
    });
  }

  reset(value?: any, options?: {onlySelf?: boolean; emitEvent?: boolean}): void {
    super.reset({percent: 0, blocked: false, maximum: null}, value, options);
  }

  isBlocked() {
    return this.controls['blocked'].value;
  }

  setMaximum(max: number) {
    this.controls['maximum'].setValue(max);
  }

  getPercent(): number {
    return this.controls['percent'].value;
  }

  getContact(): TlContact {
    return <TlContact>super.getShare();
  }

  toBackend(price?: number): any {
    const data = this.commonToBackend();

    data['precio'] = isNumeric(price)
      ? (price * this.controls['percent'].value) / 100
      : 0;

    return data;
  }

  toBackendPercent(): any {
    const data = this.commonToBackend();
    data['porcentajeBase100'] = this.controls['percent'].value;

    return data;
  }

  private commonToBackend(): any {
    const contact = this.controls['share'].value;
    const data = {idusuario: contact.id, nombre: contact.name};

    if (contact.phone) {
      data['telefono'] = contact.phone;
    }

    return data;
  }
}
