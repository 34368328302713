import {Inject, Injectable} from '@angular/core';
import {SwPush} from '@angular/service-worker';
import {Logger} from 'common';
import {from, Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, tap, timeout} from 'rxjs/operators';

import {NotificationsDao} from './notifications.dao';
import {PushSubscriptionError} from './push-subscription-error';

@Injectable({providedIn: 'root'})
export class NotificationsService {
  // eslint-disable-next-line  max-len
  readonly VAPID_PUBLIC_KEY =
    'BIyOieMJCk13c7BphUolZH3VZQI3zE4eTwyzCzMA5mvwPrMtUAWV3IBriGhtWil_GFNXCR_kwAlB_hNxY0hWNHQ';

  constructor(
    private logger: Logger,
    private notificationsDao: NotificationsDao,
    private swPush: SwPush,
    @Inject('window') private window: Window,
  ) {}

  tryNotificationSubscription() {
    return this.tryNotificationSubscriptionNative().pipe(
      timeout(15000),
      tap({
        error: error =>
          this.logger.warn("Can't subscribe: " + error.toString(), error),
      }),
      switchMap(sub => this.notificationsDao.registerPushSubscriber(sub)),
    );
  }

  trySubscribeIfGranted() {
    this.hasPermissions()
      .pipe(
        filter(p => !!p),
        switchMap(() => this.tryNotificationSubscription()),
      )
      .subscribe({
        next: () => {},
        error: () => {},
      });
  }

  /**
   * Check permisssions
   *
   * @return Observable<NotificationPermission | null> Null if browser doesn't
   * support notifications.
   */
  permissionStatus(): Observable<PushPermissionState | null> {
    if (this.isPushSupported()) {
      return from(this.window.navigator.serviceWorker.ready).pipe(
        switchMap(registration =>
          registration.pushManager.permissionState({userVisibleOnly: true}),
        ),
        catchError(() => of(null)),
      );
    } else {
      return of(null);
    }
  }

  hasPermissions() {
    return this.permissionStatus().pipe(map(status => status === 'granted'));
  }

  unsubscribe(clearBrowserSubscription = false): Observable<any> {
    const subs: Observable<any> = clearBrowserSubscription
      ? from(this.swPush.unsubscribe())
      : of(true);
    return subs.pipe(switchMap(() => this.notificationsDao.unsubscribe()));
  }

  isPushSupported(): boolean {
    return (
      this.swPush.isEnabled &&
      // Fix TypeError: e.window.navigator.serviceWorker is undefined on
      // Firefox 70.0 Windows 7 https://trello.com/c/vVz2Q2fL
      this.window.navigator.serviceWorker &&
      // PushManager can be undefined in some mobile browser
      // https://github.com/angular/angular/issues/27889
      'PushManager' in this.window &&
      typeof ServiceWorkerRegistration === 'function' &&
      'pushManager' in ServiceWorkerRegistration.prototype
    );
  }

  private tryNotificationSubscriptionNative(): Observable<PushSubscription> {
    return new Observable(observer => {
      this.window.navigator.serviceWorker.ready.then(
        registration => {
          if (
            !registration.pushManager ||
            !registration.pushManager?.getSubscription()
          ) {
            observer.error(
              new PushSubscriptionError(
                'Push native: Error pushManager not defined',
              ),
            );
          }
          registration.pushManager.getSubscription().then(
            subscription => {
              if (subscription) {
                observer.next(subscription);
                observer.complete();
              } else {
                registration.pushManager.subscribe(this.generatePushOptions()).then(
                  newSubscription => {
                    if (newSubscription) {
                      observer.next(newSubscription);
                      observer.complete();
                    } else {
                      observer.error(
                        new PushSubscriptionError(
                          'Push native: subscribe returns null subscription',
                        ),
                      );
                    }
                  },
                  e =>
                    observer.error(
                      new PushSubscriptionError('Push native: Error subscribe', e),
                    ),
                );
              }
            },
            e =>
              observer.error(
                new PushSubscriptionError('Push native: Error getSubcription', e),
              ),
          );
        },
        e =>
          observer.error(
            new PushSubscriptionError('Push native: Service worker not ready', e),
          ),
      );
    });
  }

  private generatePushOptions() {
    const pushOptions: PushSubscriptionOptionsInit = {userVisibleOnly: true};
    let key = atob(this.VAPID_PUBLIC_KEY.replace(/_/g, '/').replace(/-/g, '+'));
    let applicationServerKey = new Uint8Array(new ArrayBuffer(key.length));
    for (let i = 0; i < key.length; i++) {
      applicationServerKey[i] = key.charCodeAt(i);
    }
    pushOptions.applicationServerKey = applicationServerKey;

    return pushOptions;
  }
}
