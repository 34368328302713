import {NgModule} from '@angular/core';

import {PlatformRouterLinkDirective} from './platform-router-link';
import {PlatformRouterLinkActiveDirective} from './platform-router-link-active';
import {PlatformRouterLinkWithHrefDirective} from './platform-router-link-href';

@NgModule({
  exports: [
    PlatformRouterLinkActiveDirective,
    PlatformRouterLinkDirective,
    PlatformRouterLinkWithHrefDirective,
  ],
  declarations: [
    PlatformRouterLinkActiveDirective,
    PlatformRouterLinkDirective,
    PlatformRouterLinkWithHrefDirective,
  ],
})
export class PlatformRouterLinkModule {}
