import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
} from '@angular/core';

import {ngModelProvider} from '../../../model/ng-model-config';
import {ResponsiveService} from '../../../responsive/responsive.service';
import {CounterComponent} from '../counter.component';

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-counter-4',
  templateUrl: '../counter.component.html',
  styleUrls: ['./counter4.component.scss'],
  providers: [ngModelProvider(Counter4Component)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Counter4Component extends CounterComponent {
  constructor(
    protected cdr: ChangeDetectorRef,
    protected responsiveService: ResponsiveService,
    @Inject(LOCALE_ID) protected locale: string,
  ) {
    super(cdr, responsiveService, locale);
  }
}
