import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TicketsDao} from './tickets.dao';
import {Filter} from '../../game-filter/data/filter';

@Injectable({providedIn: 'root'})
export class TicketGameUserFiltersService {
  constructor(private ticketsDao: TicketsDao) {}

  getFilters(): Observable<Array<Filter>> {
    return this.ticketsDao.getUserFilters();
  }
}
