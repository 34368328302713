import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, catchError, throwError} from 'rxjs';
import {AlertsService} from 'common';

import {SessionService} from './session.service';
import {TuloteroFraudError} from '../../error/tulotero-errors/tulotero-fraud-error';

@Injectable()
export class FraudErrorInterceptor implements HttpInterceptor {
  constructor(
    private alertsService: AlertsService,
    private sessionService: SessionService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.error && e.error.status === 'FRAUD_DETECTED') {
          this.sessionService.logoutNoUnregister();
          this.alertsService.notifyError(e.error.message);

          return throwError(() => new TuloteroFraudError(e.error.message));
        }

        return throwError(() => e);
      }),
    );
  }
}
