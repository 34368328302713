import {ResponsiveService} from '../responsive/responsive.service';

import {PRESS_TRESHOLD, PressEvent} from './press-event';

export class PressUp extends PressEvent {
  private longPress = false;

  constructor(
    protected responsiveService: ResponsiveService,
    protected element: HTMLElement,
    protected originalHandler: (...args: any[]) => any,
    protected doc: Document,
  ) {
    super(responsiveService, element, originalHandler, doc);
  }

  /**
   * Marks pressup event as ready to fire original handler after before
   * {@link PRESS_TRESHOLD} millis.
   * Mousedown/touchstart for desktop/mobile respectively, will fire this
   * handler.
   */
  protected startHandler(): void {
    this.timer = setTimeout(() => {
      this.longPress = true;
    }, PRESS_TRESHOLD);
  }

  /**
   * Fires the pressup original handler.
   * If this handler is fired before {@link PRESS_TRESHOLD} millis from start
   * handler, the pressup event is cancelled.
   *
   * @param event dom event which ends pressup. This will be mouseup/touchend
   *     for
   * desktop/mobile respectively.
   */
  protected endHandler(event: Event): void {
    clearTimeout(this.timer);
    if (this.longPress) {
      this.originalHandler(event);
      this.longPress = false;
    }
  }
}
