import {TranslatableText} from '../i18n/translatable-text';

import {AlertOptions} from './alert-options';
import {AlertType} from './alert-type';

export class Alert {
  constructor(
    public message: string | TranslatableText,
    public type: AlertType,
    public options?: AlertOptions,
  ) {}
}
