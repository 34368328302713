import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class SoundPlayService {
  constructor() {}

  play(name: string) {
    const audio = new Audio(`assets/sound/${name}.mp3`);
    audio.preload = 'auto';
    return audio.play();
  }
}
