import {Serializable} from 'common';
import {isFuture} from 'date-fns';

import {ShipmentStatus} from './shipment-status.enum';

class ShipmentInfoInternal {
  id: number;

  // company name for deliveries, null for collection
  company: string | null;

  securityCode: string;

  trackingCode: string;

  status: ShipmentStatus;

  shipmentClosingDate: number;

  collectionClosingDate: number;

  requestDate: number;

  shippingDate: number;

  isDeliverable() {
    return this.canCollect() || this.canShip();
  }

  canCollect(): boolean {
    return !!this.collectionClosingDate && isFuture(this.collectionClosingDate);
  }

  canShip(): boolean {
    return !!this.shipmentClosingDate && isFuture(this.shipmentClosingDate);
  }

  hasShipment() {
    return !!this.id;
  }

  isDeliveredRequested() {
    return (
      this.hasShipment() &&
      (this.status === ShipmentStatus.DELIVERED ||
        this.status === ShipmentStatus.PENDING_SHIPMENT)
    );
  }

  isCollectionRequested() {
    return (
      this.hasShipment() &&
      (this.status === ShipmentStatus.COLLECTED ||
        this.status === ShipmentStatus.PENDING_COLLECTION)
    );
  }
}

export class ShipmentInfo extends Serializable(ShipmentInfoInternal) {
  public static createFromBackend(obj: any): ShipmentInfo {
    let info = new ShipmentInfo();

    info.shipmentClosingDate = obj.fechaCierreEnvioCasa;
    info.collectionClosingDate = obj.fechaCierreRecogida;
    info.id = obj.apuesta.envioCasaId;

    if (!obj.apuesta.envioCasa) {
      return info;
    }

    info.requestDate = obj.apuesta.envioCasa.fechaSolicitud;
    info.shippingDate = obj.apuesta.envioCasa.fechaEnvio;

    if (obj.apuesta.envioCasa.empresaEnvio === 'Recogida') {
      info.company = null;
    } else {
      info.company = obj.apuesta.envioCasa.empresaEnvio || null;
    }

    if (
      obj.apuesta.envioCasa.empresaEnvio &&
      obj.apuesta.envioCasa.empresaEnvio === 'Recogida'
    ) {
      info.securityCode = obj.apuesta.envioCasa.empresaEnvioId;
    } else {
      info.trackingCode = obj.apuesta.envioCasa.empresaEnvioId;
    }

    // Valores de backend de estado:
    // - nombre:"REQUESTED", descripcion: "Solicitado"
    // - nombre:"PENDIENTE", descripcion: "Validado"
    // - nombre:"ENVIADA", descripcion: "Enviado"
    // - nombre:"RECOGIDA", descripcion: "Espera de recogida"
    // - nombre:"TRANSITO", descripcion: "Tránsito"
    // - nombre:"REPARTO", descripcion: "Reparto"
    // - nombre:"ERROR_ENVIADA", descripcion: "Incidencia durante envío"
    // - nombre:"ERROR_RECOGIDA", descripcion: "Incidencia al recoger"
    // - nombre:"ENTREGADA", descripcion: "Entregado"
    // - nombre:"DEVUELTO", descripcion: "Devuelto"
    // - nombre:"ANULADO", descripcion: "Anulado"
    // - nombre:"DUPLICADO_ANULADO", descripcion: "Anulado por duplicado"
    if (!!obj.apuesta.envioCasa.estado) {
      switch (obj.apuesta.envioCasa.estado) {
        case 'RECOGIDA':
          info.status = ShipmentStatus.IN_TRANSIT;
          break;
        case 'ENVIADA':
          info.status = ShipmentStatus.DELIVERED;
          break;
        case 'ENTREGADA':
          info.status = ShipmentStatus.COLLECTED;
          break;
        case 'REQUESTED':
          info.status =
            obj.apuesta.envioCasa?.empresaEnvio === 'Recogida'
              ? ShipmentStatus.PENDING_COLLECTION
              : ShipmentStatus.PENDING_SHIPMENT;
          break;
      }
    } else {
      info.status =
        obj.apuesta.envioCasa?.empresaEnvio === 'Recogida'
          ? ShipmentStatus.PENDING_COLLECTION
          : ShipmentStatus.PENDING_SHIPMENT;
    }

    return info;
  }
}
