import {Serializable, SerializableProperty, SerializableType} from 'common';

import {CombinationData} from '../../combination/data/combination-data';
import {CombinationTypeValue} from '../../combination/data/combination-type-declaration';
import {COMBINATION_TYPE_RESOLVER} from '../../combination/data/combination-type-resolver';
import {getFormattedValue} from '../../combination/data/combination-value.util';
import {GameMetadata} from '../../game-metadata/data/game-metadata';

import {Serie} from './serie';

class GenericBetInternal implements CombinationData {
  id: string;

  gameId: string;

  boothId: string;

  combinationDisplay: string;

  external: boolean;

  creationDate: number;

  validationDate: number;

  /**
   * It's the price of a simple bet.
   *
   * @example Example with Primitiva:
   * Ticket: 1€ (Even if you buy 2 bets).
   * Club:   1€ (If you buy a club participation, the price usually 8€, but
   *            this is only 1 bet).
   */
  price: number;

  /**
   * It's the price of the bet.
   *
   * @example Example with Primitiva:
   * Ticket: 2€ (For 2 bets)
   * Club:   8€ (The whole bets price)
   */
  totalPrice: number;

  betsNumber: number;

  random: boolean;

  @SerializableProperty(
    combination => COMBINATION_TYPE_RESOLVER[combination.type],
    SerializableType.COLLECTION,
    true,
  )
  combinationTypes: Array<CombinationTypeValue>;

  betType: string;

  betGroup: string;

  @SerializableProperty(Serie)
  series: Array<Serie>;

  /**
   * Promos info
   *
   * @example {prereserveshared: true}
   */
  promos: {[key: string]: boolean};

  joker2: string;
}

export class GenericBet extends Serializable(GenericBetInternal) {
  static createFromBackend(obj: any) {
    let bet = new GenericBet();
    bet.id = obj.id;
    bet.gameId = obj.juego;
    bet.boothId = obj.integrator;
    bet.combinationDisplay = obj.combinacionFormatted;
    bet.external = obj.almanaque;
    bet.creationDate = obj.fechaCreacion;
    bet.validationDate = obj.fechaValidacion;
    bet.price = obj.precio;
    bet.totalPrice = obj.total;
    bet.random = obj.aleatoria;
    bet.betsNumber = obj.cantidad;

    if (obj.asignacion === 'PRERESERVE') {
      bet.promos = {prereserveshared: true};
    }

    bet.betType = obj.betInfo?.bets[0].betId ?? undefined;
    bet.joker2 = obj.joker2;
    // TODO backend doesnt support this
    bet.betGroup = obj.betInfo?.bets[0].betGroup ?? 'default';
    // ---

    if (obj.betInfo) {
      let addedTypes = []; // logic to add only one panel and every global
      // field after
      bet.combinationTypes = [];

      obj.betInfo.bets.forEach(b =>
        b.types
          .filter(
            type => type.type !== 'BOOLEAN' && !addedTypes.includes(type.typeId),
          )
          .forEach(type => {
            addedTypes.push(type.typeId);
            bet.combinationTypes.push(
              COMBINATION_TYPE_RESOLVER[type.type].createFromBackend(type),
            );
          }),
      );
    }

    if (
      typeof obj.serieFraccion === 'string' &&
      obj.serieFraccion !== '' &&
      obj.serieFraccion !== '0(0)'
    ) {
      bet.series = [];
      let stringSeries = obj.serieFraccion.match(/(\d+)(\(([\d+,?]+)\))?/g);
      stringSeries.forEach(stringSerie => {
        let serie = stringSerie.match(/\d+/)[0];
        const matchedFractions = stringSerie.match(/\(([(\d+),?]+)\)/g);
        let fractions = null;
        if (matchedFractions) {
          fractions = matchedFractions[0].replace(/[()]/g, '').split(',');
        }
        bet.series.push(new Serie(Number.parseInt(serie, 10), fractions));
      });
    }

    return bet;
  }

  getPlayableCombination(gameMetadata: GameMetadata): string {
    let text = '';
    this.combinationTypes.forEach((combinationType, index) => {
      const typeMetadata = gameMetadata.getTypeMetadata(combinationType.typeId);

      if (
        !typeMetadata.playable ||
        typeMetadata.playType === 'BOOLEAN' ||
        typeMetadata.playType === 'BOOLEAN_ARRAY'
      ) {
        return;
      }

      if (index !== 0) {
        text += `${typeMetadata.label.substr(0, 1)}: `;
      }

      combinationType.value.forEach(
        value => (text += getFormattedValue(value, typeMetadata) + ','),
      );

      text = text.slice(0, -1) + ' ';
    });

    return text.trim();
  }
}
