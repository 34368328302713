import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {Logger} from 'common';

import {environment} from '../../../../environments/environment';

import {PlayDao} from './play.dao';
import {PlayDaoAdapter} from './play.dao.adapter';

export function playDaoFactory(http: HttpClient, logger: Logger) {
  if (environment.locale.locale === 'es-ES') {
    return new PlayDaoAdapter(http, logger);
  } else {
    return new PlayDao(http, logger);
  }
}
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    // TODO · ADAPTER · remove
    {provide: PlayDao, useFactory: playDaoFactory, deps: [HttpClient, Logger]},
  ],
})
export class PlayModelModule {}
