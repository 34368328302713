import {parseError} from 'common';

export class PushSubscriptionError {
  constructor(public message: string, public error?: Error) {}

  toString() {
    const error = parseError(this.error);

    return this.message + ': ' + error.message;
  }
}
