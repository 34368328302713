import {NgModule} from '@angular/core';
import {environment} from '~environments/environment';

import {GeolocationService} from './geolocation.service';
import {GeolocationServiceFake} from './geolocation.service.fake';
import {GeolocationUsService} from './geolocation-us.service';

@NgModule({
  providers: [
    {
      provide: GeolocationService,
      useClass: GeolocationProvidersModule.getGeolocationService(),
    },
  ],
})
export class GeolocationProvidersModule {
  private static getGeolocationService() {
    if (environment.skipLocation) {
      return GeolocationServiceFake;
    } else if (environment.id === 'us') {
      return GeolocationUsService;
    } else {
      return GeolocationService;
    }
  }
}
