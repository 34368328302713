import {NgModule} from '@angular/core';
import {HTTP_DEFAULT_HEADERS, HTTP_ENDPOINT} from 'common';

import {environment} from '../../../environments/environment';

@NgModule({
  providers: [
    {provide: HTTP_ENDPOINT, useValue: environment.endpoint},
    {provide: HTTP_DEFAULT_HEADERS, useValue: environment.defaultHeaders},
  ],
})
export class HttpProvidersModule {}
