import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {State} from './state';

@Injectable({
  providedIn: 'root',
})
export class StatesService extends AbstractObservableDataService<Array<State>> {
  constructor() {
    super();
  }
}
