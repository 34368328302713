import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'tl-camera-in-use-modal',
  templateUrl: './camera-in-use-modal.component.html',
  styleUrls: ['./camera-in-use-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraInUseModalComponent {}
