<div
  [placement]="placement"
  [ngbTooltip]="template || descriptionTemplate"
  (mouseover)="onOpen()"
  (mouseleave)="onClose()"
  triggers="manual"
  container="body">
  <div
    *ngIf="icon === 'question'; else info"
    class="tl-info__ring square-responsive">
    <div class="tl-info__character square-responsive-content">?</div>
  </div>

  <ng-template #descriptionTemplate>
    <div [innerHtml]="description"></div>
  </ng-template>

  <ng-template #info>
    <i class="tl-info__icon icon-info2-icon"></i>
  </ng-template>
</div>
