import {Inject, Pipe, PipeTransform} from '@angular/core';

import {ENVIRONMENT} from '../../environment/environment-token';
import {formatDate} from '../date-fns-wrapper';

@Pipe({name: 'timestampToDate'})
export class TimestampToDatePipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT) private environment: Record<string, any>) {}

  transform(value: number): any {
    return formatDate(value, this.environment.locale.dateFormats.date);
  }
}
