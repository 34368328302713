export abstract class LocalStorage {
  abstract get length(): number;

  static isAvailable(): boolean {
    const storage = window['localStorage'];
    // Check if storage is available.
    if (!storage) {
      return false;
    }
    // Check if the storage can actually be accessed.
    try {
      const now = Date.now();
      const testItemKey = `storage-test-entry-${now}`;
      const testItemValue = `storage-test-value-${now}`;
      storage.setItem(testItemKey, testItemValue);
      const retrievedItemValue = storage.getItem(testItemKey);
      storage.removeItem(testItemKey);
      return retrievedItemValue === testItemValue;
    } catch (error) {
      return false;
    }
  }

  abstract clear(): void;

  abstract getItem(key: string): string | null;

  /**
   * The key() method of the Storage interface, when passed a number n,
   * returns the name of the nth key in a given Storage object. The order of
   * keys is user-agent defined, so you should not rely on it.
   */
  abstract key(index: number): string | null;

  abstract removeItem(key: string): void;

  abstract setItem(key: string, data: string): void;
}
