import {
  Clonable,
  deepClone,
  Serializable,
  SerializableProperty,
  SerializableType,
} from 'common';
import {environment} from '~environments/environment';

/**
 * @deprecated
 */
export enum PreferenceCategory {
  NOTIFICATIONS = 'notifications',
  MAILS = 'mails',
  SMS = 'sms',
  OTHER = 'none',
}

export enum PreferenceType {
  PUSH = 'PUSH',
  MAIL = 'MAIL',
  SMS = 'TEXT',
}

class PreferenceParentCategoryTreeInternal implements Clonable {
  title: string;
  subtitle: string;
  isRootCategory: boolean;

  clone(): any {
    return deepClone(this);
  }

  toBackend() {
    return {
      title: this.title,
      subtitle: this.subtitle,
      isRootCategory: this.isRootCategory,
    };
  }
}

export class PreferenceParentCategoryTree extends Serializable(
  PreferenceParentCategoryTreeInternal,
) {
  public static createFromBackend(obj: any): any {
    const ppct = new PreferenceParentCategoryTree();
    ppct.title = obj.title;
    ppct.subtitle = obj.subtitle;
    ppct.isRootCategory = obj.isRootCategory;
    return ppct;
  }
}

class PreferenceCategoryTreeInternal implements Clonable {
  title: string;
  subtitle: string;
  isRootCategory: boolean;
  groupKey: string;

  @SerializableProperty(PreferenceParentCategoryTree, SerializableType.OBJECT)
  parentCategory: PreferenceParentCategoryTree;

  clone(): any {
    return deepClone(this);
  }

  toBackend() {
    return {
      title: this.title,
      groupKey: this.groupKey,
      subtitle: this.subtitle,
      isRootCategory: this.isRootCategory,
      parentCategory: this.parentCategory.toBackend(),
    };
  }
}

export class PreferenceCategoryTree extends Serializable(
  PreferenceCategoryTreeInternal,
) {
  public static createFromBackend(obj: any): any {
    const pct = new PreferenceCategoryTree();
    pct.title = obj.title;
    pct.subtitle = obj.subtitle;
    pct.isRootCategory = obj.isRootCategory;
    pct.groupKey = obj.groupKey;
    if (obj.hasOwnProperty('parentCategory') && obj.parentCategory) {
      pct.parentCategory = PreferenceParentCategoryTree.createFromBackend(
        obj.parentCategory,
      );
    }
    return pct;
  }
}

class PreferenceInternal implements Clonable {
  title: string;

  categorizedTitle: string;

  subtitle: string;

  /**
   * @deprecated The field should not be used
   */
  // eslint-disable-next-line deprecation/deprecation
  category: PreferenceCategory;

  key: string;

  value: boolean;

  @SerializableProperty(PreferenceCategoryTree, SerializableType.OBJECT)
  categoryTree: PreferenceCategoryTree;

  type: PreferenceType;

  /**
   * @deprecated The field should not be used
   */
  protected categoryString: string;

  clone(): any {
    return deepClone(this);
  }

  setCategory(category: string): void {
    const cat = category.toLowerCase();
    if (
      [
        // eslint-disable-next-line deprecation/deprecation
        PreferenceCategory.NOTIFICATIONS,
        // eslint-disable-next-line deprecation/deprecation
        PreferenceCategory.MAILS,
        // eslint-disable-next-line deprecation/deprecation
        PreferenceCategory.SMS,
        // eslint-disable-next-line deprecation/deprecation
      ].includes(<PreferenceCategory>cat)
    ) {
      // eslint-disable-next-line deprecation/deprecation
      this.category = <PreferenceCategory>cat;
    } else {
      // eslint-disable-next-line deprecation/deprecation
      this.category = PreferenceCategory.OTHER;
    }
  }

  toBackend() {
    return {
      key: this.key,
      value: this.value ? 'true' : 'false',
    };
  }
}

export class Preference extends Serializable(PreferenceInternal) {
  public static createFromBackend(obj: any) {
    let p = new Preference();

    p.title = obj.title;
    p.categorizedTitle = obj.categorizedTitle;
    p.subtitle = obj.subtitle;
    p.setCategory(
      obj.category.toLowerCase() === environment.preferences.notificationCategory
        ? // eslint-disable-next-line deprecation/deprecation
          PreferenceCategory.NOTIFICATIONS
        : obj.category,
    );
    // eslint-disable-next-line deprecation/deprecation
    p.categoryString = obj.category;
    if (obj.hasOwnProperty('categoryTree') && obj.categoryTree) {
      p.categoryTree = PreferenceCategoryTree.createFromBackend(obj.categoryTree);
    }
    p.key = obj.key;
    p.value = obj.value === 'true';
    p.type = obj.preferenceType;

    return p;
  }
}

export interface NotificationPreference {
  title: string;
  subtitle: string;
  key: string;
  value: boolean;
  type: PreferenceType;
}

export interface NotificationPreferenceSubcategory {
  title: string;
  subtitle: string;
  preferences: Array<NotificationPreference>;
  state: string;
  hasPushNotifications: boolean;
  allPreferenceArePush: boolean;
}

export interface NotificationPreferenceCategory {
  title: string;
  subtitle: string;
  subcategories?: Map<string, NotificationPreferenceSubcategory>;
  preferences?: Map<number, NotificationPreference>;
  hasPushNotifications: boolean;
  allPreferenceArePush: boolean;
}
