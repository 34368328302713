<svg
  width="149"
  height="205"
  viewBox="0 0 149 205"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M83.3873 160.6C80.8969 162.853 77.5236 168.052 83.9535 170.82C90.3834 173.588 85.2093 180.269 81.8185 183.264C80.0289 184.949 78.181 188.981 85.1061 191.624C92.0312 194.267 88.3825 199.782 85.6925 202.209"
    stroke="#555555"
    stroke-width="1.73451"
    stroke-linecap="round" />
  <path
    class="tl-balloon__fill"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M128.17 86.7258C130.385 79.1234 131.092 70.9388 129.988 62.5727C125.758 30.52 96.538 7.93974 64.7228 12.1382C32.9075 16.3367 10.5451 45.7241 14.7749 77.7767C15.1194 80.3874 15.6298 82.9353 16.2938 85.411C16.299 85.4307 16.2857 85.4506 16.2655 85.4531V85.4531C16.2448 85.4558 16.2316 85.4769 16.2376 85.4968C29.3445 129.028 58.7148 146.844 73.5987 150.703C74.5861 150.959 75.1905 151.969 74.903 152.948L72.8758 159.851C72.4611 161.263 73.9261 162.613 75.365 162.304C80.0304 161.303 85.4784 161.626 89.6215 162.245C91.1885 162.478 92.4253 160.689 91.5554 159.364L86.2306 151.258C85.7089 150.464 85.9291 149.396 86.7083 148.852C112.771 130.669 124.041 105.164 128.17 86.7258Z"
    [attr.fill]="color" />
  <g opacity="0.4">
    <path
      d="M79.0506 49.2477L49.8832 50.8636C48.9565 50.9149 48.1652 51.5504 47.9151 52.4442L45.8761 59.7325C45.4758 61.1632 46.6006 62.5636 48.084 62.4814L53.3305 62.1908C54.8313 62.1076 55.9594 63.5397 55.5269 64.9793L50.2104 82.6786C49.778 84.1181 50.906 85.5503 52.4068 85.4671L59.3445 85.0828C60.2561 85.0323 61.0384 84.4161 61.301 83.5417L67.5202 62.8373C67.7829 61.9629 68.5651 61.3468 69.4767 61.2962L77.3674 60.8591C78.3058 60.8071 79.1038 60.1565 79.3438 59.2479L81.2668 51.9661C81.6428 50.5425 80.5208 49.1662 79.0506 49.2477Z"
      fill="white" />
    <path
      d="M95.1621 48.3551L88.7469 48.7105C87.8416 48.7606 87.0631 49.3688 86.7954 50.235L77.235 81.1651C76.789 82.6082 77.9182 84.0538 79.4264 83.9702L97.5456 82.9664C98.4383 82.9169 99.209 82.3246 99.4866 81.4748L101.874 74.1673C102.347 72.7175 101.215 71.2449 99.6927 71.3293L94.3781 71.6237C92.8838 71.7065 91.757 70.2863 92.1773 68.85L97.3629 51.1288C97.7832 49.6925 96.6564 48.2723 95.1621 48.3551Z"
      fill="white" />
  </g>
  <path
    d="M85.4297 139.577C105.553 124.704 114.842 106.299 118.021 90.3725C119.086 85.0356 113.635 84.5386 111.716 89.6312C104.918 107.673 94.8869 121.617 82.2373 132.87C77.6557 136.946 80.4983 143.222 85.4297 139.577Z"
    fill="#161109"
    fill-opacity="0.08" />
  <ellipse
    cx="45.1199"
    cy="33.814"
    rx="7.48491"
    ry="3.80161"
    transform="rotate(-41.6335 45.1199 33.814)"
    fill="white"
    fill-opacity="0.3" />
  <ellipse
    cx="36.8642"
    cy="41.8174"
    rx="2.29194"
    ry="1.7328"
    transform="rotate(-41.6335 36.8642 41.8174)"
    fill="white"
    fill-opacity="0.3" />
</svg>
