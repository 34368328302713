import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tl-uploading-images-dialog',
  templateUrl: './uploading-images-dialog.component.html',
  styleUrls: ['./uploading-images-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UploadingImagesDialogComponent {
  @HostBinding('class.tl-uploading-images-dialog')
  hostClass = true;
}
