import {Inject, Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {AlertsService, ENVIRONMENT} from 'common';
import {Router} from '@angular/router';

@Injectable()
export class GroupNotMemberInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private alertsService: AlertsService,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error && error.error && error.error.status === 'GROUP_NOT_MEMBER') {
          // Backend returns this error in the status field
          // when the user does not belong to the group
          this.alertsService.notifyError({key: 'groups.errorUserNotMember'});
          this.router.navigate([
            '/m',
            this.environment.locale.routes.mobile.main.groups,
          ]);
          return new Observable<HttpEvent<any>>();
        }
        throw error;
      }),
    );
  }
}
