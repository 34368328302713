import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../../i18n/i18n.module';

import {StepBallComponent} from './step-ball.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  declarations: [StepBallComponent],
  exports: [StepBallComponent],
})
export class StepBallModule {}
