import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {Router} from '@angular/router';
import {
  LocaleService,
  ModalHelperService,
  MoneyPipe,
  ResponsiveService,
} from 'common';

import {environment} from '../../../environments/environment';

@Component({
  selector: 'tl-limit-reached-modal',
  templateUrl: './limit-reached-modal.component.html',
  styleUrls: ['./limit-reached-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LimitReachedModalComponent implements OnInit {
  @Input()
  error: any;

  @Input()
  context: 'load' | 'play' | 'group' | 'shipment';

  titleKey: string;

  titleKeyData: any;

  hintKey: string;

  hintKeyData: any;

  image: string;

  spentKey: string;

  spentKeyData: any;

  get currencySymbol() {
    return this.localeService.getCurrencySymbol();
  }

  get isDesktop(): boolean {
    return this.responsiveService.isDesktop();
  }

  @HostBinding('class.tl-limit-reached-modal')
  readonly hostClass = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private localeService: LocaleService,
    private modalHelperService: ModalHelperService,
    private responsiveService: ResponsiveService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.context === 'load') {
      this.generateLimitLoadData();
    } else if (this.context === 'play') {
      const limitType: string = this.error?.exceededLimit?.limitType;
      if (limitType === 'spending.weekly') {
        this.generateLimitPlayData();
      } else if (
        ['balanceload.daily', 'balanceload.weekly', 'balanceload.monthly'].indexOf(
          limitType,
        ) > -1
      ) {
        this.generateLimitLoadPlayData();
      }
    } else if (this.context === 'group') {
      this.generateLimitGroupLoadData();
    } else if (this.context === 'shipment') {
      this.generateLimitShipmentData();
    }

    this.cdr.markForCheck();
  }

  goLimitsPage() {
    this.router.navigate([
      this.isDesktop ? '/' : '/m',
      environment.locale.routes.selfExclusion.selfExclusion,
    ]);

    this.modalHelperService.closeLastModal();
  }

  private generateLimitLoadData(): void {
    this.image = 'assets/img/exclusion/load-limit.png';

    const limitType: string = this.error.exceededLimit.limitType;
    const limitAmount = this.error.exceededLimit.total;
    const spentAmount = this.error.exceededLimit.used;
    const remainingAmount = limitAmount - spentAmount;

    this.titleKeyData = {
      amountWithCurrency: this.formatMoney(limitAmount),
    };
    this.spentKeyData = {
      amountWithCurrency: this.formatMoney(spentAmount),
    };
    this.hintKeyData = {
      remainder: this.formatMoney(remainingAmount),
    };

    if (limitType === 'balanceload.daily') {
      if (spentAmount < limitAmount) {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitDailyOvercome';
        if (spentAmount > 0) {
          this.spentKey = 'selfExclusion.dialogLimit.contentDialogLimitDaily';
          this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitWithRemainder';
        } else {
          this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimit';
          this.hintKeyData = undefined;
        }
      } else {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitDailyReached';
        this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitDailyReached';
      }
    } else if (limitType === 'balanceload.weekly') {
      if (spentAmount < limitAmount) {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitWeeklyOvercome';
        if (spentAmount > 0) {
          this.spentKey = 'selfExclusion.dialogLimit.contentDialogLimitWeekly';
          this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitWithRemainder';
        } else {
          this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimit';
          this.hintKeyData = undefined;
        }
      } else {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitWeeklyReached';
        this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitWeeklyReached';
      }
    } else if (limitType === 'balanceload.monthly') {
      if (spentAmount < limitAmount) {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitMonthlyOvercome';
        if (spentAmount > 0) {
          this.spentKey = 'selfExclusion.dialogLimit.contentDialogLimitMonthly';
          this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitWithRemainder';
        } else {
          this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimit';
          this.hintKeyData = undefined;
        }
      } else {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitMonthlyReached';
        this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitMonthlyReached';
      }
    } else {
      if (spentAmount < limitAmount) {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitDefaultOvercome';
        this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimit';
        this.hintKeyData = undefined;
      } else {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitDefaultReached';
        this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitDefaultReached';
      }
    }
  }

  private formatMoney(number: number | string): string {
    const locale = this.localeService.getLocale();
    const moneyPipe = new MoneyPipe(locale);
    return moneyPipe.transform(number, null, null, '1.0-2', locale);
  }

  private generateLimitLoadPlayData(): void {
    this.image = 'assets/img/exclusion/load-limit.png';

    const limitType: string = this.error.exceededLimit.limitType;
    const limitAmount = this.error.exceededLimit.total;

    if (limitType === 'balanceload.daily') {
      this.titleKey = 'selfExclusion.dialogLimitAutoLoad.titleDaily';
    } else if (limitType === 'balanceload.weekly') {
      this.titleKey = 'selfExclusion.dialogLimitAutoLoad.titleDaily';
    } else if (limitType === 'balanceload.monthly') {
      this.titleKey = 'selfExclusion.dialogLimitAutoLoad.titleDaily';
    }
    this.titleKeyData = {
      amountWithCurrency: `${limitAmount} ${this.currencySymbol}`,
    };

    this.spentKey = undefined;
    this.spentKeyData = undefined;

    this.hintKey = 'selfExclusion.dialogLimitAutoLoad.content';
    this.hintKeyData = undefined;
  }

  private generateLimitPlayData(): void {
    this.image = 'assets/img/exclusion/spend-limit.png';

    const limitAmount = this.error.exceededLimit?.total;
    const spentAmount = this.error.exceededLimit?.used;
    const remainingAmount = limitAmount - spentAmount;

    if (spentAmount < limitAmount) {
      if (spentAmount > 0) {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitWeeklyOnePlay';
        this.titleKeyData = {
          amountWithCurrency: `${limitAmount} ${this.currencySymbol}`,
        };
        this.spentKey = 'selfExclusion.dialogLimit.subtitleDialogLimitWeeklyPlay';
        this.spentKeyData = {
          amountWithCurrency: `${spentAmount} ${this.currencySymbol}`,
        };
        this.hintKey =
          'selfExclusion.dialogLimit.contentDialogLimitWeeklyOnePlayWithRemainder';
        this.hintKeyData = {
          amountWithCurrency: `${remainingAmount} ${this.currencySymbol}`,
        };
      } else {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitWeeklyOnePlay';
        this.titleKeyData = {
          amountWithCurrency: `${limitAmount}${this.currencySymbol}`,
        };
        this.spentKey = undefined;
        this.spentKeyData = undefined;
        this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitWeeklyOnePlay';
        this.hintKeyData = undefined;
      }
    } else {
      // this.spentAmount >= this.limitAmount
      this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitWeeklyPlay';
      this.titleKeyData = {
        amountWithCurrency: `${limitAmount}${this.currencySymbol}`,
      };
      this.spentKey = undefined;
      this.spentKeyData = undefined;
      this.hintKey = 'selfExclusion.dialogLimit.contentDialogLimitWeeklyPlay';
      this.hintKeyData = undefined;
    }

    this.cdr.markForCheck();
  }

  private generateLimitShipmentData(): void {
    this.image = 'assets/img/exclusion/spend-limit.png';

    const limitAmount = this.error.exceededLimit?.total;
    const spentAmount = this.error.exceededLimit?.used;

    if (spentAmount < limitAmount) {
      if (spentAmount > 0) {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitWeeklyOperation';
        this.titleKeyData = {
          amountWithCurrency: `${limitAmount} ${this.currencySymbol}`,
        };
        this.spentKey = 'selfExclusion.dialogLimit.subtitleDialogLimitWeeklyPlay';
        this.spentKeyData = {
          amountWithCurrency: `${spentAmount} ${this.currencySymbol}`,
        };
        this.hintKey = 'selfExclusion.dialogLimit.titleDialogLimitOperationSubtitle';
      } else {
        this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitWeeklyOperation';
        this.titleKeyData = {
          amountWithCurrency: `${limitAmount}${this.currencySymbol}`,
        };
        this.spentKey = undefined;
        this.spentKeyData = undefined;
        this.hintKey = 'selfExclusion.dialogLimit.titleDialogLimitOperationSubtitle';
        this.hintKeyData = undefined;
      }
    } else {
      this.titleKey = 'selfExclusion.dialogLimit.titleDialogLimitWeeklyPlay';
      this.titleKeyData = {
        amountWithCurrency: `${limitAmount}${this.currencySymbol}`,
      };

      this.spentKey = undefined;
      this.spentKeyData = undefined;
      this.hintKey = 'selfExclusion.dialogLimit.titleDialogLimitOperationSubtitle';
      this.hintKeyData = undefined;
    }

    this.cdr.markForCheck();
  }

  private generateLimitGroupLoadData(): void {
    this.image = 'assets/img/exclusion/spend-limit.png';

    const limitAmount = this.error.exceededLimit?.total;
    const spentAmount = this.error.exceededLimit?.used;
    const remainingAmount = limitAmount - spentAmount;

    if (spentAmount < limitAmount) {
      if (spentAmount > 0) {
        this.titleKey = 'selfExclusion.dialogWeeklyLimitLoadGroup.title';
        this.titleKeyData = {
          amountWithCurrency: `${limitAmount} ${this.currencySymbol}`,
        };
        this.spentKey = 'selfExclusion.dialogWeeklyLimitLoadGroup.subtitle';
        this.spentKeyData = {
          amountWithCurrency: `${spentAmount} ${this.currencySymbol}`,
        };
        this.hintKey =
          'selfExclusion.dialogWeeklyLimitLoadGroup.contentWithRemainder';
        this.hintKeyData = {
          amountWithCurrency: `${remainingAmount} ${this.currencySymbol}`,
        };
      } else {
        this.titleKey = 'selfExclusion.dialogWeeklyLimitLoadGroup.title';
        this.titleKeyData = {
          amountWithCurrency: `${limitAmount}${this.currencySymbol}`,
        };
        this.spentKey = undefined;
        this.spentKeyData = undefined;
        this.hintKey = 'selfExclusion.dialogWeeklyLimitLoadGroup.contentOnePlay';
        this.hintKeyData = undefined;
      }
    } else {
      // this.spentAmount >= this.limitAmount
      this.titleKey = 'selfExclusion.dialogWeeklyLimitLoadGroup.titleReached';
      this.titleKeyData = {
        amountWithCurrency: `${limitAmount}${this.currencySymbol}`,
      };
      this.spentKey = undefined;
      this.spentKeyData = undefined;
      this.hintKey = 'selfExclusion.dialogWeeklyLimitLoadGroup.contentReached';
      this.hintKeyData = undefined;
    }

    this.cdr.markForCheck();
  }
}
