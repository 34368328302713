import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {
  FakeFingerprintStrategy,
  FINGERPRINT_PRO_ENDPOINT,
  FINGERPRINT_PRO_TOKEN,
  FingerprintPROStrategy,
  FingerprintStrategy,
  Logger,
} from 'common';
import {environment} from '~environments/environment';

import {DeviceInterceptor} from './device.interceptor';

function fpFactory(token, endpoint, logger) {
  return environment.production
    ? new FingerprintPROStrategy(token, endpoint, logger)
    : new FakeFingerprintStrategy();
}

@NgModule({
  providers: [
    {provide: FINGERPRINT_PRO_TOKEN, useValue: environment.fingerprint.token},
    {provide: FINGERPRINT_PRO_ENDPOINT, useValue: environment.fingerprint.endpoint},
    {
      provide: FingerprintStrategy,
      useFactory: fpFactory,
      deps: [FINGERPRINT_PRO_TOKEN, FINGERPRINT_PRO_ENDPOINT, Logger],
    },
  ],
})
export class TuloteroDeviceModule {
  static withInterceptors(): ModuleWithProviders<TuloteroDeviceModule> {
    return {
      ngModule: TuloteroDeviceModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DeviceInterceptor,
          multi: true,
        },
      ],
    };
  }
}
