import {DOCUMENT} from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import {ResponsiveService} from '../responsive/responsive.service';
import {WindowResizeService} from '../responsive/window-resize.service';
import {ScrollableComponent} from '../scrolling/scrollable/scrollable.component';

import {KeyboardTriggerFilterService} from './keyboard-trigger-filter.service';

@Directive({selector: '[tlTriggerOnKeyboardOpen]'})
export class TriggerOnKeyboardOpenDirective implements OnInit, OnDestroy {
  /**
   * Offset to add to the vertical scroll amount.
   */
  @Input('tlTriggerOnKeyboardOpen')
  offset: number;

  private destroySubject = new Subject<void>();

  private isFocused: boolean;

  private focus = new Subject<void>();

  private focusListener: () => void;

  private blurListener: () => void;

  private resizeListener: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private element: ElementRef,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
    @Optional() private closestScrollable: ScrollableComponent,
    private triggerFilter: KeyboardTriggerFilterService,
    private windowResizeService: WindowResizeService,
    private zone: NgZone,
  ) {}

  ngOnInit() {
    this.initOffset();
    this.responsiveService.breakpointChange
      .pipe(
        map(() => this.responsiveService.isMobile()),
        startWith(this.responsiveService.isMobile()),
        distinctUntilChanged(),
        takeUntil(this.destroySubject),
      )
      .subscribe(isMobile => {
        if (isMobile) {
          this.attachListeners();
        } else {
          this.detachListeners();
        }
      });

    this.focus.pipe(debounceTime(10)).subscribe(() => this.scrollIntoView());
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();

    this.focus.complete();

    this.detachListeners();

    if (this.responsiveService.isMobile()) {
      this.scrollBodyTo(0);
    }
  }

  private initOffset() {
    if (!this.offset) {
      this.offset = 0;
    }
    if (isNaN(this.offset)) {
      throw new Error(
        'Input "offset" value is not valid, it must be a valid number.',
      );
    }
  }

  private triggerFocus() {
    this.isFocused = true;

    if (this.responsiveService.isMobile()) {
      this.triggerFilter.triggerFocusEvent(this.isFocused);
    }
  }

  private triggerBlur() {
    this.isFocused = false;

    if (this.responsiveService.isMobile()) {
      this.triggerFilter.triggerFocusEvent(this.isFocused);
    }
  }

  private attachListeners(): void {
    // prevent autoscroll to whole body
    this.resizeListener = this.windowResizeService.resize
      .pipe(
        map(event => event.target),
        filter(eventWindow => !!eventWindow),
        map(
          eventWindow =>
            eventWindow.innerHeight < eventWindow.screen.availHeight * 0.7,
        ),
        distinctUntilChanged(),
        takeUntil(this.destroySubject),
      )
      .subscribe(isSmall => {
        // heuristic to detect keyboard openning and fix scroll
        if (this.isFocused && isSmall) {
          // we need to wait until the browser resizing event is totally
          // finished
          this.zone.onStable.subscribe(() => this.scrollBodyTo(0));

          this.triggerFilter.triggerHeuristicEvent(true);
          // delay the scrollto event to give time to the scrollable to
          // resize itself
          setTimeout(() => this.focus.next(), 100);
        }

        // heuristic to detect keyboard closing
        if (!isSmall) {
          this.triggerFilter.triggerHeuristicEvent(false);
        }
      });

    this.focusListener = this.renderer.listen(
      this.element.nativeElement,
      'focus',
      focusEvent => {
        this.triggerFocus();
        this.focus.next(focusEvent);
      },
    );

    this.blurListener = this.renderer.listen(
      this.element.nativeElement,
      'blur',
      () => this.triggerBlur(),
    );
  }

  private detachListeners(): void {
    if (typeof this.focusListener === 'function') {
      this.focusListener();
    }

    if (typeof this.blurListener === 'function') {
      this.blurListener();
    }

    if (this.resizeListener) {
      this.resizeListener.unsubscribe();
    }
  }

  private scrollIntoView() {
    if (!this.closestScrollable) {
      return;
    }
    this.closestScrollable.scrollElementIntoView(
      this.element.nativeElement,
      this.offset,
    );
  }

  // noinspection JSMethodCanBeStatic
  private scrollBodyTo(y: number) {
    if (typeof this.document.body.scrollTo === 'function') {
      this.document.body.scrollTo(0, y);
    } else {
      this.document.body.scrollTop = y;
    }
  }
}
