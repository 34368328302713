import {Component} from '@angular/core';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-sonar-loading',
  templateUrl: './sonar-loading.component.html',
  styleUrls: ['./sonar-loading.component.scss'],
})
export class SonarLoadingComponent {}
