import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../i18n/i18n.module';

import {BarcodeReaderComponent} from './barcode-reader.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  exports: [BarcodeReaderComponent],
  declarations: [BarcodeReaderComponent],
})
export class BarcodeReaderModule {}
