import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import {ResponsiveService} from '../responsive/responsive.service';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-extra-switch-row',
  templateUrl: './extra-switch-row.component.html',
  styleUrls: ['./extra-switch-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtraSwitchRowComponent {
  @Input()
  logo: string;

  @Input()
  rowText: string;

  @Input()
  infoTooltipText: string;

  @Input()
  infoTooltipClass: string;

  @Input()
  infoTooltipId: string;

  @Input()
  switchTooltipText: string;

  @Input()
  switchTooltipClass: string;

  @Input()
  switchTooltipId: string;

  @Input()
  enabled: boolean;

  @Input()
  switchClass: string;

  @Output()
  info = new EventEmitter<void>();

  @Output()
  toggleSwitch = new EventEmitter<boolean>();

  get isDesktop(): boolean {
    return this.responsiveService.isDesktop();
  }

  constructor(private responsiveService: ResponsiveService) {}
}
