import {Injectable} from '@angular/core';
import {FacebookConfiguration, FacebookConfigurationLoader} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {FacebookDao} from './data/facebook.dao';

@Injectable()
export class BackendFacebookConfiguration extends FacebookConfigurationLoader {
  constructor(private facebookDao: FacebookDao) {
    super();
  }

  load(): Observable<FacebookConfiguration> {
    return this.facebookDao
      .appInfo()
      .pipe(
        map(info =>
          Object.assign({redirect: environment.social.facebookRedirect}, info),
        ),
      );
  }
}
