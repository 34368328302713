import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../common/model/abstract.dao';

@Injectable({providedIn: 'root'})
export class NotificationsDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  registerPushSubscriber(sub: PushSubscription): Observable<void> {
    let s = sub.toJSON();

    delete s.expirationTime;
    return this.http.post<void>(this.baseUrl + '/users/android/subscription', s);
  }

  unsubscribe(): Observable<void> {
    return this.http.delete<void>(
      this.baseUrl + '/tuloteroweb/rest/users/android/subscription',
    );
  }
}
