import {NgModule} from '@angular/core';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';

import {AutocloseDirective} from './autoclose.directive';
import {CloseClickOutsideDirective} from './close-click-outside.directive';
import {CloseOthersDirective} from './close-others-directive';

@NgModule({
  imports: [NgbPopoverModule],
  exports: [
    AutocloseDirective,
    CloseClickOutsideDirective,
    CloseOthersDirective,
    NgbPopoverModule,
  ],
  declarations: [
    AutocloseDirective,
    CloseClickOutsideDirective,
    CloseOthersDirective,
  ],
})
export class PopoverModule {}
