import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractDao} from '../../model/abstract.dao';

import {FacebookAppInfo} from './facebook';

@Injectable({providedIn: 'root'})
export class FacebookDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  appInfo(): Observable<FacebookAppInfo> {
    return <Observable<FacebookAppInfo>>(
      this.http.get(this.baseUrl + '/facebook/login/info')
    );
  }

  login(token: string, permissions: string, instalationId?: number) {
    let body = new HttpParams()
      .append('token', token)
      .append('grantedPermissions', permissions)
      .append('deviceType', 'WEB')
      .append('instalacionId', instalationId.toString());
    return this.http.post(this.baseUrl + '/facebook/login', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
}
