import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Filter} from '../../game-filter/data/filter';
import {TicketGameUserFiltersService} from './ticket-game-user-filters.service';

@Injectable()
export class NoopTicketGameUserFiltersService extends TicketGameUserFiltersService {
  getFilters(): Observable<Array<Filter>> {
    return of(void 0);
  }
}
