import {Inject, Pipe, PipeTransform} from '@angular/core';
import {ENVIRONMENT} from '../../environment/environment-token';
import {AsYouType, CountryCode} from 'libphonenumber-js/max';

@Pipe({name: 'phoneUnformat'})
export class PhoneUnformatPipe implements PipeTransform {
  private defaultCountryCode: CountryCode;

  constructor(@Inject(ENVIRONMENT) protected environment: Record<string, any>) {
    this.defaultCountryCode = environment.locale.user.defaultPhoneCode;
  }

  transform(value: string, countryCode: string = this.defaultCountryCode): string {
    if (!value?.length) {
      return value;
    }

    try {
      const formatter = this.getFormatter(this.sanitizeCountryCode(countryCode));
      formatter.input(value);
      return formatter.getNumber()?.nationalNumber;
    } catch {
      return value;
    }
  }

  private getFormatter(countryCode: CountryCode): AsYouType {
    return new AsYouType(countryCode);
  }

  private sanitizeCountryCode(code: string): CountryCode {
    return code?.toUpperCase() as CountryCode;
  }
}
