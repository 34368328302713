import {Serializable} from 'common';

import {KycMethodId} from './kyc-method-id';
import {KycType} from './kyc-type';

class KycMethodInternal {
  bothSides: false;

  id: KycMethodId;

  name: string;

  type: KycType;

  get method(): string {
    return KycMethods[this.type];
  }
}

export class KycMethod extends Serializable(KycMethodInternal) {
  public static createFromBackend(obj: any): KycMethod {
    let k = new KycMethod();

    k.bothSides = obj.extra?.bothSides;
    k.id = obj.id;
    k.name = obj.name;
    k.type = obj.type;

    return k;
  }
}

enum KycMethods {
  CARD_AND_SELFIE = 'cardselfie',
  CARD_FRONT_AND_SELFIE = 'cardfrontselfie',
  CARD = 'card',
  NIF_CODE = 'nifcode',
}
