import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {UtilModule} from '../../util/util-module';
import {SelectTlModule} from '../select/select.module';

import {NumbersTableComponent} from './numbers-table.component';

@NgModule({
  imports: [CommonModule, FormsModule, SelectTlModule, UtilModule],
  exports: [NumbersTableComponent],
  declarations: [NumbersTableComponent],
})
export class NumbersTableModule {}
