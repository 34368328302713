/**
 * Treshold limit to consider the event as a press event, in millis.
 */
import {ResponsiveService} from '../responsive/responsive.service';

export const PRESS_TRESHOLD = 250;

/**
 * Adds and removes start and end events needed for press events.
 *
 * Mousedown and mouseup are used for desktop.
 * Touchstart and touchend are used for mobile.
 *
 * Note: mouseup is always added at document level in order to consider a valid
 * press event when the user moves out of the initial element while pressing.
 */
export abstract class PressEvent {
  protected timer: any;
  private readonly startEvent: string;
  private readonly endEvents: Array<string>;

  constructor(
    protected responsiveService: ResponsiveService,
    protected element: HTMLElement,
    protected originalHandler: (...args: any[]) => any,
    protected doc: Document,
  ) {
    this.startHandler = this.startHandler.bind(this);
    this.endHandler = this.endHandler.bind(this);
    this.onDestroy = this.onDestroy.bind(this);

    if (responsiveService.isDesktop()) {
      this.startEvent = 'mousedown';
      this.endEvents = ['mouseup'];
      this.endEvents.forEach(event => doc.addEventListener(event, this.endHandler));
    } else {
      this.startEvent = 'touchstart';
      this.endEvents = ['touchend', 'touchcancel'];
      this.endEvents.forEach(event =>
        element.addEventListener(event, this.endHandler),
      );
    }
    element.addEventListener(this.startEvent, this.startHandler);
  }

  protected abstract startHandler(event: Event): void;

  protected abstract endHandler(event: Event): void;

  onDestroy() {
    this.element.removeEventListener(this.startEvent, this.startHandler);

    if (this.responsiveService.isDesktop()) {
      this.endEvents.forEach(event =>
        this.doc.removeEventListener(event, this.endHandler),
      );
    } else {
      this.endEvents.forEach(event =>
        this.element.removeEventListener(event, this.endHandler),
      );
    }
  }
}
