export function deepClone<T>(obj: T): T {
  let depth = -1;
  let arr = [];
  return clone(obj, arr, depth);
}

function clone(obj, arr, depth) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  let length = Object.keys(obj).length;
  let result = Object.create(Object.getPrototypeOf(obj));
  if (result instanceof Array) {
    result.length = length;
  }

  depth++;
  arr[depth] = [];
  arr[depth][length] = [];

  for (let x = depth; x >= 0; x--) {
    if (arr[x][length]) {
      for (let index = 0; index < arr[x][length].length; index++) {
        if (obj === arr[x][length][index]) {
          return obj;
        }
      }
    }
  }

  arr[depth][length].push(obj);
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      result[prop] = clone(obj[prop], arr, depth);
    }
  }

  return result;
}
