export const INTERNAL_ROUTES = {
  boothAssigned: 'tulotero://adminasociada',
  completeData: 'tulotero://completarDatos',
  data: 'tulotero://datos',
  group: 'tulotero://groups/:groupId',
  groupChat: 'tulotero://groups/:groupId/chat',
  home: 'tulotero://home',
  load: 'tulotero://cargar',
  news: 'tulotero://news/?id=:newsId',
  playGame: 'tulotero://jugar/?juego=:gameId',
  playRaffle: 'tulotero://jugar/?sorteo_id=:sorteoId',
  raffleClubId: 'tulotero://sorteos/:sorteoId/penyas/:tab?penya=:penyaId',
  raffleClubs: 'tulotero://sorteos/:sorteoId/penyas',
  raffleClubsTab: 'tulotero://sorteos/:sorteoId/penyas/:tab',
  raffleResult: 'tulotero://sorteos/:sorteoId/result',
  requestMediumPrize:
    'tulotero://request-medium-prize' +
    '/boletoId::ticketId' +
    '/totalPending::totalPending' +
    '/totalPendingByIntegrator::totalPendingByIntegrator',
  settings: 'tulotero://config',
  sponsor: 'tulotero://sponsor',
  sponsorAchieved: 'tulotero://sponsor/achieved',
  sponsorContacts: 'tulotero://sponsor/contacts',
  sponsorFriends: 'tulotero://sponsor/friends',
  sponsorRanking: 'tulotero://sponsor/ranking',
  suggestions: 'tulotero://sugerencias',
  terms: 'tulotero://terms',
  ticket: 'tulotero://boletos/?boleto_id=:boletoId',
  transfer: 'tulotero://transferir',
};
