export {};

declare global {
  interface Map<K, V> {
    toJSON(): any;
  }
}

Map.prototype.toJSON = function () {
  return Array.from(this.entries());
};
