import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalStorage} from 'common';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {AbstractDao} from '../common/model/abstract.dao';

import {PrivateInfo} from './private-info/private-info';
import {PublicInfo} from './public-info/public.info';

@Injectable({providedIn: 'root'})
export class BackendDao extends AbstractDao {
  cachedBooths = null;

  constructor(protected http: HttpClient, protected localStorage: LocalStorage) {
    super();
  }

  allInfoPublic(): Observable<PublicInfo> {
    return this.http.get(environment.endpointCommon).pipe(
      switchMap((res: any) =>
        this.getBooths().pipe(
          map(booths => {
            res.administraciones = booths;
            return res;
          }),
        ),
      ),
      map(response => PublicInfo.createFromBackend(response)),
    );
  }

  allInfo(lastUpdate?: number): Observable<PrivateInfo> {
    let url = '/users/allinfo/v2';

    if (lastUpdate) {
      url += '?lastUpdate=' + lastUpdate;
    }

    return this.http.get(this.baseUrl + url, {observe: 'response'}).pipe(
      switchMap((res: HttpResponse<any>) => {
        if (res.status !== 204) {
          return this.getBooths().pipe(
            map(booths => {
              res.body.administraciones = booths;
              return res.body;
            }),
          );
        } else {
          return of(res);
        }
      }),
      // TODO remove
      map(response =>
        response.genericDescriptors ? this.mock(response) : response,
      ),
      // -------
      map(response =>
        response instanceof HttpResponse
          ? null
          : PrivateInfo.createFromBackend(response),
      ),
    );
  }

  getBooths(): Observable<Array<any>> {
    if (
      this.cachedBooths &&
      Date.now().valueOf() -
        +this.localStorage.getItem(environment.localStorageKeys.boothsLastDate) <
        environment.allInfo.boothCacheDuration
    ) {
      return of(this.cachedBooths);
    }

    return this.http.get(this.baseUrl + '/admins').pipe(
      map(booths => {
        const list = booths as Array<any>;
        this.cachedBooths = list;
        this.localStorage.setItem(
          environment.localStorageKeys.boothsLastDate,
          Date.now().toString(),
        );

        return list;
      }),
    );
  }

  // TODO REMOVE
  protected mock(response): any {
    response.genericDescriptors.forEach(desc => {
      desc.uiInfo.boardIndexOutside = true;

      if (desc.juego === 'PROGOL_PLAY') {
        desc.types[0].allowedValues.forEach(choice => (choice.header = undefined));
        desc.types[1].allowedValues.forEach(choice => (choice.header = undefined));
      }

      if (desc.juego === 'PROTOUCH_PLAY') {
        desc.types[1].allowedValues.forEach(choice => (choice.header = undefined));
      }
      //
      //   if (desc.juego === 'MELATE_RETRO') {
      //     desc.types[0].uiInfo = {
      //       highlightColor: '#f5f5f5',
      //       length: 8,
      //     };
      //   }
      //
      //   if (desc.juego === 'CHISPAZO') {
      //     desc.types[0].uiInfo = {
      //       highlightColor: '#f5f5f5',
      //       length: 7,
      //     };
      //   }
      //
      //   if (desc.juego === 'TRIS') {
      //     desc.types[0].uiInfo = {
      //       highlightColor: '#f5f5f5',
      //       length: 10,
      //     };
      //   }
      //
      //   // if (desc.juego === 'POWERBALL' || desc.juego === 'TEXAS_TWO_STEP'
      //   //     || desc.juego === 'MEGAMILLIONS' ) {
      //   //   desc.types[0].uiInfo.fullscreen = true;
      //   //   desc.types[0].uiInfo.highlightColor = '#f5f5f5';
      //   //
      //   //   desc.types[1].uiInfo.fullscreen = true;
      //   //   desc.types[1].uiInfo.modal = true;
      //   //   desc.types[1].uiInfo.highlightColor = '#f5f5f5';
      //   //
      //   //   // desc.bets[0].types[0].numMaxColumns = 1;
      //   // }
    });

    return response;
  }
}
