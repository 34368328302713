import {Directive, ElementRef, Optional} from '@angular/core';
import {FormGroupName, NgControl} from '@angular/forms';

import {ResponsiveHeightComponent} from '../../responsive/responsive-height/responsive-height.component';
import {ScrollIntoElement} from '../../scrolling/scroll-into-element';
import {ScrollableComponent} from '../../scrolling/scrollable/scrollable.component';

@Directive({selector: '[tlScrollToControl]'})
export class ScrollToControlDirective {
  private nestedControls: Array<{ngControl: NgControl; element: ElementRef}> = [];

  private scrollIntoContainer: ScrollIntoElement;

  constructor(
    @Optional() private closestScrollable: ScrollableComponent,
    @Optional() private responsiveHeightComponent: ResponsiveHeightComponent,
  ) {
    this.scrollIntoContainer =
      this.closestScrollable || this.responsiveHeightComponent || null;
  }

  registerControl(ngControl: NgControl, element: ElementRef): void {
    this.nestedControls.push({ngControl, element});
  }

  removeControl(ngControl: NgControl): void {
    this.nestedControls.splice(
      this.nestedControls.findIndex(nested => nested.ngControl === ngControl),
      1,
    );
  }

  scrollToInvalidControl(order: 'asc' | 'desc') {
    if (!this.scrollIntoContainer) {
      return;
    }

    let controls = this.nestedControls.clone();

    if (order === 'desc') {
      controls.reverse();
    }

    let invalidIndex = controls.findIndex(
      nested => nested.ngControl.control.invalid,
    );

    if (invalidIndex < 0) {
      // if no match, the first child control of the first invalid formGroup is
      // fetched
      invalidIndex = controls.findIndex(
        nested =>
          nested.ngControl.control['_parent'] instanceof FormGroupName &&
          nested.ngControl.control.parent.invalid,
      );
    }

    if (invalidIndex < 0) {
      return;
    }

    this.scrollTo(
      order === 'desc' ? controls.length - 1 - invalidIndex : invalidIndex,
    );
  }

  private scrollTo(invalidIndex: number): void {
    const nativeElement = this.nestedControls[invalidIndex].element.nativeElement;
    this.scrollIntoContainer.scrollElementIntoView(nativeElement);
    nativeElement.focus();
  }
}
