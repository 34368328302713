import {Inject, Injectable} from '@angular/core';
import {ResponsiveService, UriUtils} from 'common';

import {Slugger} from '../../games/util/slugger';

import {PlatformUriConfig, PlatformUriMap} from './platform-mapper-types';
import {PLATFORM_MAP_CONFIG} from './platform-mapper.token';

@Injectable({providedIn: 'root'})
export class PlatformUriMapper {
  private config: Array<PlatformUriMap>;

  constructor(
    private responsiveService: ResponsiveService,
    @Inject(PLATFORM_MAP_CONFIG) private uriConfig: PlatformUriConfig,
  ) {
    this.config = this.uriConfig.flat();
  }

  map(url: string): string {
    if (!url) {
      return null;
    }

    const normalizedUrl = decodeURI(url);

    let match;
    let mapping;
    const uriMapFound = this.config?.find(platformMap => {
      mapping = this.getMapping(platformMap, this.responsiveService.isDesktop());

      match = UriUtils.urlMatches(mapping.pattern, mapping.url, normalizedUrl);
      return !!match;
    });

    if (uriMapFound) {
      const paramNames = UriUtils.getUrlParams(mapping.url);
      return this.insertParams(paramNames, match, mapping.url);
    } else {
      return null;
    }
  }

  private getMapping(
    platformMap: PlatformUriMap,
    forDesktop: boolean,
  ): {url: string; pattern: RegExp} {
    return {
      url: forDesktop ? platformMap.desktopUrl : platformMap.mobileUrl,
      pattern: forDesktop ? platformMap.mobilePattern : platformMap.desktopPattern,
    };
  }

  private insertParams(
    names: RegExpMatchArray,
    values: RegExpExecArray,
    url: string,
  ): string {
    let destinationUrl = url;

    values
      .slice(1)
      .forEach(
        (token, index) =>
          (destinationUrl = destinationUrl.replace(
            names[index],
            Slugger.toUrl(token),
          )),
      );

    return destinationUrl;
  }
}
