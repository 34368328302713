import {Serializable, SerializableProperty} from 'common';

import {CombinationType} from './combination-type';
import {CombinationValue} from './combination-value';

/**
 * A empty value, for example the million on pt.
 * On Thursdays, there's a million, and on Tuesdays, there isn't.
 * On Tuesdays, we shouldn't show anything.
 */
class EmptyTypeInternal implements CombinationType<CombinationValue> {
  typeId: string;

  type: string;

  @SerializableProperty(CombinationValue)
  value: Array<CombinationValue>;
}

export class EmptyCombinationType extends Serializable(EmptyTypeInternal) {
  static createFromBackend(obj: any): EmptyCombinationType {
    let emptyCT: EmptyCombinationType = new EmptyCombinationType();

    emptyCT.typeId = obj.typeId;
    emptyCT.type = obj.type;
    emptyCT.value = [];
    return emptyCT;
  }
}
