import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class SessionInterceptorService {
  token: string;

  logoutPatch: () => void = () => {};

  logout() {
    this.logoutPatch();
  }

  isLoggedIn() {
    return !!this.token;
  }
}
