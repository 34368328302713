import {Observable, throwError} from 'rxjs';

import {BarcodeResult} from './barcode-result';
import {Logger} from '../logger/logger';
import {Camera} from '../camera/camera';
import {CameraType} from '../camera/camera-type';
import {ResponsiveService} from '../responsive/responsive.service';

export abstract class AbstractBarcodeReader {
  private stream: MediaStream;

  constructor(protected responsiveService: ResponsiveService) {}

  abstract load(
    element: HTMLVideoElement,
    formats?: Array<string>,
  ): Observable<boolean>;

  abstract continue(): void;

  abstract read(): Observable<BarcodeResult>;

  destroy(): void {
    this.stopMediaTracks();
  }

  public getBestCamera(
    currentCamera: Camera,
    nextCamera: Camera,
    data: {
      logger: Logger;
      cameraType: CameraType;
      devices: Array<MediaDeviceInfo>;
    },
  ): Camera {
    const nextCapabilities = nextCamera.tracks[0].capabilities;

    // Log when there is capabilities and is desktop
    if (!!nextCapabilities && this.responsiveService.isDesktop()) {
      data.logger.warn(
        'CameraComponent: There is capabilities and facingMode in Desktop',
        {
          devices: data.devices,
          capabilities: nextCapabilities,
        },
      );
    }

    // Log when there is not capabilities
    if (!nextCapabilities) {
      // Firefox in Ubuntu: capabilities === undefined
      // Safari in MacOS: capabilities.facingMode === undefined
      data.logger.warn(
        `CameraComponent: There is not capabilities ${
          this.responsiveService.isDesktop() ? 'in Desktop' : 'in Mobile'
        }`,
        {
          device: nextCamera.device,
          devices: data.devices,
          capabilities: nextCapabilities,
        },
      );
    }

    // Log when there is capabilities and there is not facingMode
    if (
      !!nextCapabilities &&
      (!nextCapabilities.facingMode || nextCapabilities.facingMode.length === 0)
    ) {
      // Chrome in Ubuntu: capabilities.facingMode === []
      data.logger.warn(
        `CameraComponent: There is not facingMode ${
          this.responsiveService.isDesktop() ? 'in Desktop' : 'in Mobile'
        }`,
        {
          device: nextCamera.device,
          devices: data.devices,
          capabilities: nextCapabilities,
        },
      );
    }

    // Request front camera and next camera is not front
    if (
      data.cameraType === CameraType.FRONT &&
      !!nextCapabilities &&
      !!nextCapabilities.facingMode &&
      nextCapabilities.facingMode.length > 0 &&
      nextCapabilities.facingMode[0] === 'environment'
    ) {
      // Return before
      return currentCamera;
    }

    // Request back camera and next camera is not back
    if (
      data.cameraType === CameraType.BACK &&
      !!nextCapabilities &&
      !!nextCapabilities.facingMode &&
      nextCapabilities.facingMode.length > 0 &&
      nextCapabilities.facingMode[0] === 'user'
    ) {
      // Return before
      return currentCamera;
    }

    // Selected if it has torch
    if (!!nextCapabilities && !!nextCapabilities['torch']) {
      return nextCamera;
    }

    if (!!nextCapabilities) {
      // Selected last
      return nextCamera;
      // Selected first
      // return !!currentCamera ? currentCamera : nextCamera;
    }

    // If we can not detect if it is better camera, select current
    // (There is not capabilities)
    // (There is not facinMode)
    return currentCamera;
  }

  protected configCamera(stream: MediaStream, logger?: Logger): void {
    this.stream = stream;

    stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
      const capabilities: MediaTrackCapabilities = track.getCapabilities
        ? track.getCapabilities()
        : undefined;

      if (!capabilities) {
        logger.warn('AbstractBarcodeReader: There is not getCapabilities', {
          stream: stream,
          track: track,
        });
      }

      const config: MediaTrackConstraintSet = {};

      // When use focusMode applyConstraints fails
      // - OverconstrainedError: Mixing ImageCapture and non-ImageCapture
      //   constraints is not currently supported
      // const focusModes: Array<string> = (capabilities as any).focusMode;
      // if (
      //   focusModes &&
      //   (focusModes.includes('auto') || focusModes.includes('continuous'))
      // ) {
      //   config['focusMode'] = focusModes.includes('auto') ? 'auto' : 'continuous';
      // }

      const fullHDWidth = 1920; // FullHD 1920x1080px
      // const fullHDWidth = 1280; // FullHD 1280x720px
      const maxCameraWidth = capabilities?.width?.max;
      config.width =
        maxCameraWidth && maxCameraWidth < fullHDWidth
          ? maxCameraWidth
          : fullHDWidth;

      track
        .applyConstraints(config)
        .then(() => {
          // Log info
          const settings: MediaTrackSettings = track.getSettings();
          if (capabilities && settings.height < (config.height as number)) {
            logger.warn('NativeBarcodeService: Settings worse that requested', {
              constains: config,
              capabilities: capabilities,
              settings: settings,
            });
          }
        })
        .catch(e => {
          // The constraints could not be satisfied by the available devices.
          const settings: MediaTrackSettings = track.getSettings();
          logger.error('NativeBarcodeService: Error applyConstraints', e, {
            constains: config,
            capabilities: capabilities,
            settings: settings,
          });
          throwError(() => e);
        });
    });
  }

  /**
   * Stops all active media tracks.
   * This prevents the webcam from being indicated as active,
   * even if it is no longer used by this component.
   */
  private stopMediaTracks() {
    if (this.stream && this.stream.getTracks) {
      this.stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    }
  }
}
