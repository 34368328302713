import {Injectable} from '@angular/core';

import {Toggleable} from './toggleable';

@Injectable()
export class ToggleCloseOthersService {
  private components: Array<Toggleable> = [];

  register(instance: Toggleable) {
    this.components.push(instance);
  }

  closeOthers(instance: Toggleable) {
    this.components.filter(c => instance !== c).forEach(c => c.close());
  }
}
