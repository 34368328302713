export abstract class Logger {
  abstract info(message: string, context?: any);

  abstract warn(message: string, context?: any);

  abstract error(message: string, stacktrace?: string, context?: any);

  abstract debug(message: string, context?: any);
}

export enum LogLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  GENERIC = 'GENERIC',
}
