import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractDao} from '../../../common/model/abstract.dao';

import {Match} from './match';
import {MatchesData} from './match';

@Injectable({providedIn: 'root'})
export class MatchesDao extends AbstractDao {
  constructor(protected http: HttpClient) {
    super();
  }

  getMatches(raffleId: number, size = 20): Observable<MatchesData> {
    return this.http
      .get(this.baseUrl + '/matches/sorteos/' + raffleId + '?imgSize=' + size)
      .pipe(
        map((res: any) => ({
          matches: res.matches.map(Match.createFromBackend),
          extraInfo: res.extraInfo,
        })),
      );
  }
}
