import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tl-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BannerComponent {
  @HostBinding('class.tl-banner')
  readonly hostClass = true;

  @Input()
  leftIconTemplate: TemplateRef<any>;

  @Input()
  text: string | TemplateRef<any>;

  @Input()
  button: string | TemplateRef<any>;

  @Output()
  buttonClick = new EventEmitter<void>();

  @Output()
  leftIconClick = new EventEmitter<void>();
}
