import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import {StepBallSection} from '../step-flow';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-step-ball',
  templateUrl: './step-ball.component.html',
  styleUrls: ['./step-ball.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepBallComponent implements OnChanges, OnInit {
  @Input()
  step: StepBallSection;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('step') && !changes.step.isFirstChange()) {
      this.setHostClass();
    }
  }

  ngOnInit(): void {
    this.setHostClass();
  }

  setHostClass(): void {
    this.renderer.addClass(this.element.nativeElement, this.step?.state);
  }
}
