import {iif, map, Observable} from 'rxjs';

import {createStaticWorker} from '../../worker/util';

let isEnabled = true;
let workers = new Map<string, WorkerRunner>();

class WorkerRunner {
  worker: Worker;

  jobs = 0;

  finish = false;
}

/**
 * This could be disabled for better testing.
 */
export function enableWorkerMap(state: boolean) {
  isEnabled = state;
}

/**
 * This check if it is possible to create a worker.
 */
function allowCreateWorker(): boolean {
  try {
    const runner: WorkerRunner = new WorkerRunner();
    runner.worker = createStaticWorker(() => {});
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * This use workerMap if it is possible otherwise use map.
 */
export const workerMapIfPossible =
  (cb: (...args: any[]) => any) => (source: Observable<any>) =>
    iif(
      () => allowCreateWorker(),
      workerMap(cb)(source),
      map(value => cb(value))(source),
    );

export const workerMap =
  (cb: (...args: any[]) => any) => (source: Observable<any>) =>
    new Observable(observer => {
      if (!isEnabled) {
        source.subscribe(value => {
          observer.next(cb(value));
          observer.complete();
        });
        return;
      }
      let workerString = cb.toString();
      let runner: WorkerRunner;

      if (workers.has(workerString)) {
        runner = workers.get(workerString);
      } else {
        runner = new WorkerRunner();
        runner.worker = createStaticWorker(cb);

        workers.set(workerString, runner);
      }

      runner.worker.onmessage = e => {
        runner.jobs--;
        observer.next(e.data);
        if (runner.jobs === 0 && runner.finish) {
          runner.worker.terminate();
          workers.delete(workerString);
          observer.complete();
        }
      };
      runner.worker.onerror = err => {
        runner.worker.terminate();
        workers.delete(workerString);
        observer.error(err);
      };

      source.subscribe({
        next: value => {
          runner.jobs++;
          runner.worker.postMessage(value);
        },
        error: e => observer.error(e),
        complete: () => (runner.finish = true),
      });
    });

// export function workerMap(cb: Function): Subject<any> {
//   const subject: Subject<any> = new Subject();
//
//   let workerString = cb.toString();
//   let runner: WorkerRunner;
//
//   if (workers.has(workerString)) {
//     runner = workers.get(workerString);
//   } else {
//     runner = new WorkerRunner();
//     runner.worker = createStaticWorker(cb);
//
//     workers.set(workerString, runner);
//   }
//
//   runner.worker.onmessage = e => {
//     runner.jobs--;
//     subject.next(e.data);
//     if (runner.jobs === 0 && runner.finish) {
//       runner.worker.terminate();
//       workers.delete(workerString);
//       subject.complete();
//     }
//   };
//   runner.worker.onerror = err => {
//     runner.worker.terminate();
//     workers.delete(workerString);
//     subject.error(err);
//   };
//
//   this.subscribe(value => {
//       runner.jobs++;
//       runner.worker.postMessage(value);
//     },
//     e => subject.error(e),
//     () => runner.finish = true);
//
//   return subject;
// }
