/* eslint-disable @typescript-eslint/ban-types */
import {isDevMode} from '@angular/core';

import {Constructor} from '../util/core/types';

type SerializableMetadata = [Function, SerializableType, boolean];

/**
 * Metadata store for @SerialibleProperty
 */
const metadata: Map<object, Map<string, SerializableMetadata>> = new Map();

/**
 * Metadata store for @SerializableDisableWarning
 */
const warningMetadata: Map<object, Map<string, boolean>> = new Map();

/**
 * Supported types
 */
export enum SerializableType {
  OBJECT,
  COLLECTION,
  COLLECTION_OF_COLLECTION,
  MAP,
  SET,
  MAP_OF_MAP,
}

/**
 * Decorator to restore object serialization of related properties.
 *
 * @param type Type of related object
 * @param objectType Indicates how to parse the type
 * @param resolver Function to resolve in case of multiple types
 *
 * @example Example of usage:
 *
 * ```
 * class Foo {
 *     property1: string;
 *
 *     \@SerializableProperty(Bar, SerializableType.COLLECTION)
 *     property2: Array<Bar>;
 * }
 * ```
 *
 * @example Resolver example
 *
 * ```
 * class Foo {
 *     property1: string;
 *
 *     \@SerializableProperty((json) =>
 *     COMBINATION_TYPE_RESOLVER[json.type], SerializableType.COLLECTION, true)
 *     property2: Array<Bar>;
 * }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function SerializableProperty(
  type: Function,
  objectType = SerializableType.COLLECTION,
  resolver?: boolean,
) {
  return (target: any, propertyName: string) => {
    if (!metadata.has(target)) {
      metadata.set(target, new Map());
    }
    metadata.get(target).set(propertyName, [type, objectType, resolver]);
  };
}

/**
 * Annotate properties which are not primitive types and you don't want to
 * serialize.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function SerializableDisableWarning() {
  return (target: any, propertyName: string) => {
    if (!warningMetadata.has(target)) {
      warningMetadata.set(target, new Map());
    }
    warningMetadata.get(target).set(propertyName, true);
  };
}

/**
 * Mixin to create object with serializable behavior
 *
 * @param Base Type we want to extend
 *
 * @example Serializable user creation:
 * `export const User = Serializable(UserInternal);`
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Serializable<T extends Constructor>(Base: T) {
  return class extends Base {
    static fromJSON(json: any) {
      if (!this) {
        throw new Error(`You are breaking the scope on static methods:
        Probably using something like: const fn = Type.fromJSON; ... fn(data).
        Use: const fn = value => Type.fromJSON(value); ... fn(data) instead.`);
      }
      if (typeof json === 'string') {
        return JSON.parse(json, this.reviver) as any;
      } else if (json !== undefined && json !== null) {
        const obj = Object.create(this.prototype);
        return Object.assign(obj, json, serializeNextLevelRelations(this, json));
      } else {
        return json;
      }
    }

    static reviver(): any {
      return (key: string, value: any) =>
        key === '' ? (<any>this).fromJSON(value) : value;
    }

    toJSON(): any {
      return Object.assign({}, this);
    }
  };
}

/**
 * Abstract behavior of the third parameter of this:
 *
 * ```
 * return Object.assign(user, json, {
 *       tlAccounts: json.tlAccounts ?
 *         json.tlAccounts.map(acc => TlBankAccount.fromJSON(acc)) :
 *         json.tlAccounts,
 *     });
 * ```
 *
 * Reads metadata and parses relations.
 *
 * @param type Type of object
 * @param json data to deserialize
 */
function serializeNextLevelRelations(type: Function, json: any) {
  const relations = {};
  const typeMetadata = searchMetadata(metadata, type);

  if (typeMetadata.size) {
    typeMetadata.forEach((propertyMetadata, property) => {
      if (json[property]) {
        const resolver: Function = propertyMetadata[2]
          ? propertyMetadata[0]
          : () => propertyMetadata[0];
        switch (propertyMetadata[1]) {
          case SerializableType.COLLECTION:
            relations[property] = json[property].map(value =>
              resolver(value).fromJSON(value),
            );
            break;
          case SerializableType.COLLECTION_OF_COLLECTION:
            relations[property] = json[property].map(value =>
              value.map(value2 => resolver(value2).fromJSON(value2)),
            );
            break;
          case SerializableType.MAP:
            if (propertyMetadata[0] === Map) {
              // Case for primitive maps.
              // @SerializableProperty(Map, SerializableType.MAP)
              relations[property] = new Map(json[property]);
            } else {
              relations[property] = new Map(
                json[property].map(value => [
                  value[0],
                  resolver(value[1]).fromJSON(value[1]),
                ]),
              );
            }
            break;
          case SerializableType.MAP_OF_MAP:
            if (propertyMetadata[0] === Map) {
              // Case for primitive maps.
              // @SerializableProperty(Map, SerializableType.MAP)
              relations[property] = new Map(
                json[property].map(prop => [prop[0], new Map(prop[1])]),
              );
            } else {
              relations[property] = new Map(
                json[property].map(prop => [
                  prop[0],
                  new Map(
                    prop[1].map(value => [
                      value[0],
                      resolver(value[1]).fromJSON(value[1]),
                    ]),
                  ),
                ]),
              );
            }
            break;
          case SerializableType.SET:
            if (propertyMetadata[0] === Set) {
              // Case for primitive sets.
              // @SerializableProperty(Set, SerializableType.SET)
              relations[property] = new Set(json[property]);
            } else {
              relations[property] = new Set(
                json[property].map(value => resolver(value[1]).fromJSON(value[1])),
              );
            }
            break;
          case SerializableType.OBJECT:
            relations[property] = resolver(json).fromJSON(json[property]);
            break;
          default:
            throw new Error(`Serialization Error: Unsupported SerializableType
           {${propertyMetadata[1]}}`);
        }
      } else {
        relations[property] = json[property];
      }
    });
  } else if (isDevMode()) {
    const currentWarnMetadata = searchMetadata(warningMetadata, type);
    const key = Object.keys(json).find(
      k => json[k] && typeof json[k] === 'object' && !currentWarnMetadata.get(k),
    );
    if (key) {
      throw new Error(
        `Serialization Error: Object {${type.prototype.constructor.name}}
       with key {${key}} should be annotated.`,
      );
    }
  }

  return relations;
}

/**
 * Search metadata in the current object and all parents until reach Object.
 */
function searchMetadata(
  metadataMap: Map<object, any>,
  type: Function,
): Map<string, any> {
  if (type.constructor.name === 'Object') {
    return new Map();
  }
  const currentMertadata = metadataMap.has(type.prototype)
    ? metadataMap.get(type.prototype)
    : new Map();
  return new Map(
    Array.from(searchMetadata(metadataMap, Object.getPrototypeOf(type))).concat(
      Array.from(currentMertadata),
    ),
  );
}
