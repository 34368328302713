import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {StaggerForDirective} from './stagger-for.directive';

@NgModule({
  imports: [CommonModule],
  exports: [CommonModule, StaggerForDirective],
  declarations: [StaggerForDirective],
})
export class StaggerForModule {}
