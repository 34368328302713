import {FormArray} from '@angular/forms';

import {Group} from '../../groups/data/group';

import {ShareFormGroup} from './share-form-group';
import {ShareItemFormGroup} from './share-item-form-group';
import {ShareItemGroupFormGroup} from './share-item-group-form-group';

export class ShareGroupsFormGroup extends ShareFormGroup {
  constructor(shares?: Array<ShareItemGroupFormGroup>) {
    super(shares);
  }

  getSelectedItems(): Array<Group> {
    return this.getShares().controls.map((control: ShareItemGroupFormGroup) =>
      control.getGroup(),
    );
  }

  addShare(group: Group): void {
    const share = new ShareItemGroupFormGroup(group);
    (<FormArray>this.controls['shares']).push(share);
  }

  toBackend(): Record<string, unknown> {
    const shares = (<FormArray>this.controls['shares']).controls.map(
      (control: ShareItemFormGroup) => control.toBackend(),
    );

    return shares.length > 0
      ? {comparticiones: shares, publicMode: false, opened: true}
      : undefined;
  }
}
