import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    sponsor: [
      {
        originUrl: environment.internalUrls.sponsor,
        destinationPlatformUrl: {
          desktop: `/${routeMap.sponsor}`,
          mobile: `/m/${routeMap.mobile.user}
          /${routeMap.mobile.tuloteroSlides.sponsor}`,
        },
      },
      {
        originUrl: environment.internalUrls.sponsorFriends,
        destinationPlatformUrl: {
          desktop: `/${routeMap.sponsor}`,
          mobile: `/m/${routeMap.mobile.sponsorFriends}
          /${routeMap.mobile.sponsorContacts.friends}`,
        },
      },
      {
        originUrl: environment.internalUrls.sponsorRanking,
        destinationPlatformUrl: {
          desktop: `/${routeMap.sponsor}`,
          mobile: `/m/${routeMap.mobile.sponsorFriends}
          /${routeMap.mobile.sponsorContacts.ranking}`,
        },
      },
      {
        originUrl: environment.internalUrls.sponsorAchieved,
        destinationPlatformUrl: {
          desktop: `/${routeMap.sponsor}`,
          mobile: `/m/${routeMap.mobile.sponsorFriends}
          /${routeMap.mobile.sponsorContacts.achieved}`,
        },
      },
      {
        originUrl: environment.internalUrls.sponsorContacts,
        destinationPlatformUrl: {
          desktop: `/${routeMap.sponsor}`,
          mobile: `/m/${routeMap.mobile.sponsorFriends}
          /${routeMap.mobile.sponsorContacts.contacts}`,
        },
      },
    ],
  },
];

@NgModule({
  providers: [
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class SponsorUriMappingModule {}
