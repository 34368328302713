import {environment} from '~environments/environment';
/**
 * Base to extends classes that access to the model
 */
export abstract class AbstractDao {
  protected baseUrl;

  constructor() {
    this.baseUrl = environment.endpoint;
  }
}
