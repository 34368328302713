import {CustomError} from 'common';

export enum PositionErrors {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE,
  TIMEOUT,
}

export enum GeolocationBackendError {
  INVALID_PARAM = 'InvalidParam',
  STATE_NOT_FOUND = 'StateNotFound',
  NOT_ENOUGH_ACCURACY = 'NotEnoughAccuracy',
}

export class InaccurateLocationError extends CustomError {
  position: GeolocationPosition;

  constructor(message: string, position: GeolocationPosition) {
    super(message);
    this.position = position;
    this.name = 'InaccurateLocationError';
  }
}

export class RejectedGeolocationError extends CustomError {
  constructor() {
    super('geolocation.geolocationRejected');
    this.name = 'RejectedGeolocationError';
  }
}

export function processGeolocationError(error: any): Error {
  let err = new Error('geolocation.geolocationError');

  if (typeof error === 'object' && error.code) {
    switch (error.code) {
      case PositionErrors.PERMISSION_DENIED:
        err.message = 'geolocation.deniedPermissions';
        break;
      case PositionErrors.POSITION_UNAVAILABLE:
      case PositionErrors.TIMEOUT:
        err.message = 'geolocation.geolocationTimeout';
        break;
    }

    err.stack = error.stack || err.stack;
  }

  return err;
}
