import {NgModule} from '@angular/core';
import {environment} from '~environments/environment';
import {VersionMismatchService} from './version-mismatch.service';
import {VersionMismatchMigrationDbService} from './version-mismatch-migration-db.service';
import {TuLoteroLocalStorageMigrationService} from '../common/local-storage/tu-lotero-local-storage-migration.service';

@NgModule({
  declarations: [],
  providers: [
    environment.enableLocalStorageMigration
      ? TuLoteroLocalStorageMigrationService
      : [],
    {
      provide: VersionMismatchService,
      useClass: environment.enableLocalStorageMigration
        ? VersionMismatchMigrationDbService
        : VersionMismatchService,
    },
  ],
})
export class VersionMismatchModule {}
