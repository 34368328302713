import {Serializable, SerializableProperty, SerializableType} from 'common';
import {GroupMember} from './group-member';

class GroupMemberMoneyPrizeBoxInternal {
  @SerializableProperty(GroupMember, SerializableType.OBJECT)
  member: GroupMember;

  amount: number;

  get hasPrizes(): boolean {
    return this.amount > 0;
  }
}

export class GroupMemberMoneyPrizeBox extends Serializable(
  GroupMemberMoneyPrizeBoxInternal,
) {
  static createFromBackend(
    groupPrizeInfo: any,
    member: GroupMember,
  ): GroupMemberMoneyPrizeBox {
    let g: GroupMemberMoneyPrizeBox = new GroupMemberMoneyPrizeBox();
    g.member = member;
    g.amount = groupPrizeInfo?.amount ?? 0;
    return g;
  }
}
