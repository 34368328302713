import {CurrencyPipe, getLocaleCurrencySymbol} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'money'})
export class MoneyPipe implements PipeTransform {
  localeCode: string;

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.localeCode = locale;
    if (this.localeCode === 'pt-PT') {
      // WEB-1913 -- Format currency numbers same spain format
      this.localeCode = 'es-ES';
    }
  }

  transform(
    value: any = 0,
    currencyCode?: string,
    display: 'code' | 'symbol' | 'symbol-narrow' | boolean = 'symbol',
    digits?: string,
    locale: string = this.localeCode,
  ): any {
    if (isNaN(value)) return;

    if (!currencyCode) {
      currencyCode = getLocaleCurrencySymbol(locale);
    }
    if (!display) {
      display = 'symbol';
    }
    return new CurrencyPipe(locale).transform(
      value,
      currencyCode,
      display,
      digits,
      locale,
    );
  }
}
