import {Injectable} from '@angular/core';
import {ISubscriptionNotExecutedViewService} from './i-subcriptions-not-executed-view-service';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import {iif, of, zip} from 'rxjs';
import {
  LocaleService,
  ModalDialogComponent,
  ModalDialogLayout,
  ModalHelperService,
  TranslationsService,
} from 'common';
import {MeasuringDao} from '../../../marketing/measuring.dao';
import {SubscriptionsNotExecutedService} from '../data/subscriptions-not-executed.service';
import {SubscriptionsNotExecuted} from '../data/subscriptions-not-executed';
import {SubscriptionNotExecuted} from '../data/subscription-not-executed';
import {TicketsService} from '../data/tickets.service';
import {GenericTicket} from '../data/generic-ticket';
import {TicketNotFoundError} from '../ticket-not-found-error';
import {GamesDao} from '../../data/games.dao';
import {DatePipe} from '@angular/common';
import {environment} from '~environments/environment';
import {PrivateInfoService} from '../../../backend/private-info/private-info.service';

@Injectable()
export class SubscriptionsNotExcludedViewService
  implements ISubscriptionNotExecutedViewService
{
  constructor(
    private localeService: LocaleService,
    private gamesDao: GamesDao,
    private ticketsService: TicketsService,
    private measuringDao: MeasuringDao,
    private modalHelperService: ModalHelperService,
    private subscriptionsNotExecutedService: SubscriptionsNotExecutedService,
    private translationsService: TranslationsService,
    private privateInfoService: PrivateInfoService,
  ) {}

  checkSubcriptionsNotExecuted(): void {
    this.subscriptionsNotExecutedService
      .getData()
      .pipe(
        distinctUntilChanged(),
        filter(
          (subscriptionsNotExecuted: SubscriptionsNotExecuted) =>
            !!subscriptionsNotExecuted,
        ),
        map(
          (subscriptionsNotExecuted: SubscriptionsNotExecuted) =>
            subscriptionsNotExecuted.subscriptionsNotExecuted,
        ),
        filter(
          (subscriptionsNotExecuted: Array<SubscriptionNotExecuted>) =>
            !!subscriptionsNotExecuted,
        ),
        switchMap((subscriptionsNotExecuted: Array<SubscriptionNotExecuted>) =>
          zip(
            ...subscriptionsNotExecuted.map(
              (subscription: SubscriptionNotExecuted) =>
                this.ticketsService.getTicket(subscription.ticketId).pipe(
                  first(),
                  switchMap((ticket: GenericTicket) =>
                    iif(
                      () => !!ticket,
                      of(ticket),
                      this.gamesDao.getTicket(subscription.ticketId).pipe(
                        first(),
                        catchError(() => {
                          throw new TicketNotFoundError(subscription.ticketId);
                        }),
                      ),
                    ),
                  ),
                  switchMap((ticket: GenericTicket) =>
                    this.translationsService
                      .getCompiledTranslation(
                        'tickets.warningSubscriptionNotValid.subTitle',
                        {
                          game: ticket.name,
                          date: new DatePipe(
                            this.localeService.getLocale(),
                          ).transform(
                            subscription.drawDate,
                            environment.locale.dateFormats.format,
                          ),
                        },
                      )
                      .pipe(
                        first(),
                        map(
                          (
                            subtitle: string,
                          ): {
                            subtitle: string;
                            subscription: SubscriptionNotExecuted;
                          } => ({
                            subtitle,
                            subscription,
                          }),
                        ),
                      ),
                  ),
                ),
            ),
          ),
        ),
        map(
          (
            data: Array<{
              subtitle: string;
              subscription: SubscriptionNotExecuted;
            }>,
          ) => {
            const subtitle: string = data
              .map(
                (item: {subtitle: string; subscription: SubscriptionNotExecuted}) =>
                  item.subtitle,
              )
              .join('<br>');
            const subscriptions: Array<SubscriptionNotExecuted> = data.map(
              (item: {subtitle: string; subscription: SubscriptionNotExecuted}) =>
                item.subscription,
            );
            return {subtitle, subscriptions};
          },
        ),
      )
      .subscribe(
        (data: {
          subtitle: string;
          subscriptions: Array<SubscriptionNotExecuted>;
        }): void => {
          // launch Modal
          this.modalHelperService
            .openOkModal(ModalDialogComponent, {
              componentParams: {
                type: 'ok',
                image: 'assets/img/boletos/subscription-not-validated.svg',
                layout: ModalDialogLayout.VERTICAL,
                title:
                  data.subscriptions.length > 1
                    ? {key: 'tickets.warningSubscriptionNotValid.title.other'}
                    : {key: 'tickets.warningSubscriptionNotValid.title.one'},
                subtitle: data.subtitle,
                message:
                  data.subscriptions.length > 1
                    ? {key: 'tickets.warningSubscriptionNotValid.message.other'}
                    : {key: 'tickets.warningSubscriptionNotValid.message.one'},
                accept: {key: 'global.understood'},
              },
            })
            .subscribe(() => {
              this.privateInfoService.update().pipe(delay(400), first()).subscribe();
              const subscriptionsIds: string = data.subscriptions
                .map((s: SubscriptionNotExecuted) => s.ticketId)
                .join('_');
              this.measuringDao
                .sendBackendEvent(`SUBSCRIPTION_NOT_EXECUTED_${subscriptionsIds}`)
                .subscribe();
            });
        },
      );
  }
}
