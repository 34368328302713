import {
  Directive,
  Host,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormMessagesDirective} from './form-messages.directive';

@Directive({selector: '[tlFormMessageState]'})
export class FormMessageStateDirective implements OnDestroy, OnInit, OnChanges {
  @HostBinding('class.form--message-state--valid')
  valid = false;

  @HostBinding('class.form--message-state--changed')
  changed = false;

  @Input('tlFormMessageState')
  message: string;

  @Input('tlFormMessageStateForceChanged')
  forceChanged: boolean;

  private subscription: Subscription;

  constructor(@Host() private messages: FormMessagesDirective) {
    this.subscription = messages.update.subscribe(this.validate.bind(this));
  }

  ngOnInit() {
    this.validate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('forceChanged')) {
      if (!this.changed && this.forceChanged) {
        this.changed = true;
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  validate() {
    const isValid = !this.messages.has(this.message);

    if (!this.changed && this.valid !== isValid) {
      this.changed = true;
    }

    this.valid = isValid;
  }
}
