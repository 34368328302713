import {NgModule} from '@angular/core';

import {ScrollRailModule} from '../scroll-rail/scroll-rail.module';

import {ScrollableComponent} from './scrollable.component';

@NgModule({
  imports: [ScrollRailModule],
  exports: [ScrollableComponent],
  declarations: [ScrollableComponent],
})
export class ScrollableModule {}
