import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../i18n/i18n.module';

import {TabsComponent} from './tabs.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  exports: [TabsComponent],
  declarations: [TabsComponent],
})
export class TabsModule {}
