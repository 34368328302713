import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../i18n/i18n.module';
import {CurrencyModule} from '../money/currency.module';

import {BigPrizeComponent} from './big-prize/big-prize.component';

@NgModule({
  declarations: [BigPrizeComponent],
  imports: [CommonModule, I18nModule, CurrencyModule],
  exports: [BigPrizeComponent],
})
export class BigPrizeModule {}
