import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {BlurService} from './blur.service';

@Injectable()
export class FocusLeaveGuard {
  constructor(private centerFocusService: BlurService) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.centerFocusService.setBlur(false);

    return true;
  }
}
