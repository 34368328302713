import {Component} from '@angular/core';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent {}
