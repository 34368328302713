import {Inject, Injectable, NgZone} from '@angular/core';
import {Observable, ReplaySubject, from} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {LocalStorage} from '../local-storage/local-storage';
import {loadScript} from '../util/script';

import {GOOGLE_CLIENT_ID, GOOGLE_LOGIN_STORE_KEY} from './config-tokens';
import {GoogleAuthService, GoogleUser} from './google-auth.service';
import {GoogleAuthError} from './google-errors';

declare const gapi: any;

@Injectable()
export class GoogleAuthSdkService extends GoogleAuthService {
  private isLoggedIn = false;

  /**
   * Check google api script is lazy loaded
   */
  private initialized = false;

  private scriptLoadObserver = new ReplaySubject<any>(1);

  constructor(
    @Inject(GOOGLE_CLIENT_ID) private googleClient: string,
    @Inject(GOOGLE_LOGIN_STORE_KEY) private googleLoginKey: string,
    private localStorage: LocalStorage,
    private zone: NgZone,
  ) {
    super();
  }

  login(scopes?: Array<string>): Observable<GoogleUser> {
    return new Observable(observer => {
      this.initialize().subscribe(() => {
        const options = new gapi.auth2.SigninOptionsBuilder();
        options.setPrompt('none');
        const login = this.localStorage.getItem(this.googleLoginKey);
        if (!login || !login.toBoolean()) {
          options.setPrompt('select_account');
        }

        if (scopes) {
          scopes.forEach(scope => options.setScope(scope));
        }
        gapi.auth2
          .getAuthInstance()
          .signIn(options)
          .then(
            googleUser => {
              this.zone.run(() => {
                this.localStorage.setItem(this.googleLoginKey, 'true');
                observer.next({
                  access_token: googleUser.getAuthResponse(true).access_token,
                  id_token: googleUser.getAuthResponse(true).id_token,
                  scope: googleUser.getAuthResponse(true).scope,
                  email: googleUser.getBasicProfile().getEmail(),
                });
                observer.complete();
              });
            },
            () =>
              this.zone.run(() =>
                observer.error(
                  new GoogleAuthError('Error de autenticación con Google.'),
                ),
              ),
          );
      });
    });
  }

  logout(): Observable<boolean> {
    return new Observable(observer => {
      this.initialize().subscribe(() => {
        gapi.auth2
          .getAuthInstance()
          .signOut()
          .then(() => {
            this.zone.run(() => {
              this.localStorage.setItem(this.googleLoginKey, 'false');
              observer.next(true);
              observer.complete();
            });
          });
      });
    });
  }

  getCurrentUser(): Observable<GoogleUser> {
    return this.initialize().pipe(
      map(() => gapi.auth2.getAuthInstance().currentUser.get()),
      map(googleUser => ({
        access_token: googleUser.getAuthResponse(true).access_token,
        id_token: googleUser.getAuthResponse(true).id_token,
        scope: googleUser.getAuthResponse(true).scope,
        email: googleUser.getBasicProfile().getEmail(),
      })),
    );
  }

  grant(scope: string): Observable<GoogleUser> {
    return this.initialize().pipe(
      map(() => gapi.auth2.getAuthInstance().currentUser.get()),
      switchMap(
        currentUser =>
          from(
            currentUser.grant({
              scope: scope,
            }),
          ) as Observable<GoogleUser>,
      ),
    );
  }

  getLoginStatus(): Observable<boolean> {
    const googleLogin = this.localStorage.getItem(this.googleLoginKey);
    return this.initialize().pipe(
      map(() => this.isLoggedIn && googleLogin?.toBoolean()),
    );
  }

  private initialize(): Observable<any> {
    // Load whether not initialized only over https
    if (!this.initialized) {
      this.initialized = true;
      loadScript('g-api', 'https://apis.google.com/js/api.js').subscribe({
        next: () =>
          gapi.load(
            'auth2',
            () =>
              gapi.auth2.init({clientId: this.googleClient}).then(
                () =>
                  this.zone.run(() => {
                    gapi.auth2
                      .getAuthInstance()
                      .isSignedIn.listen(logged => (this.isLoggedIn = logged));

                    this.isLoggedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
                    this.scriptLoadObserver.next(true);
                  }),
                error => {
                  // gapi init error
                  this.initialized = false;
                  this.zone.run(() => this.scriptLoadObserver.error(error));
                },
              ),
            error => {
              // gapi load error
              this.initialized = false;
              this.zone.run(() => this.scriptLoadObserver.error(error));
            },
          ),
        error: error => {
          // loadscript error
          this.initialized = false;
          this.zone.run(() => this.scriptLoadObserver.error(error));
        },
      });
    }

    return this.scriptLoadObserver.asObservable();
  }
}
