import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {filter, startWith, Subscription} from 'rxjs';

@Directive({selector: '[tlIsRouteActive]'})
export class IsRouteActiveDirective implements OnDestroy {
  private hasView = false;

  private currentRouterSubscription: Subscription;

  @Input()
  set tlIsRouteActive(url: any) {
    if (this.currentRouterSubscription) {
      this.currentRouterSubscription.unsubscribe();
    }
    let urlTree = this.router.createUrlTree(url, {
      relativeTo: this.activatedRoute,
    });
    this.currentRouterSubscription = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        startWith(null),
      )
      .subscribe((e: RouterEvent) => {
        let condition = this.router.isActive(urlTree, {
          paths: 'exact',
          queryParams: 'exact',
          fragment: 'ignored',
          matrixParams: 'ignored',
        });

        if (condition && !this.hasView) {
          this.hasView = true;
          this.viewContainer.createEmbeddedView(this.template);
        } else if (!condition && this.hasView) {
          this.hasView = false;
          this.viewContainer.clear();
        }
      });
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnDestroy() {
    this.currentRouterSubscription.unsubscribe();
  }
}
