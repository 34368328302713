import {Component, Input} from '@angular/core';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-profile-img',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
})
export class UserProfileImageComponent {
  @Input()
  src: string;

  @Input()
  initials: string;

  @Input()
  lazy = false;

  error = false;
}
