import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../../i18n/i18n.module';

import {GoogleMapComponent} from './google-map.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  exports: [GoogleMapComponent],
  declarations: [GoogleMapComponent],
})
export class GoogleMapModule {}
