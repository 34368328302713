import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HTTP_ENDPOINT} from './config-tokens';

@Injectable()
export class TimestampHeaderInterceptor implements HttpInterceptor {
  constructor(@Inject(HTTP_ENDPOINT) private endpoint: string) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.endpoint)) {
      const headers = req.headers.append('ngTime', Date.now().toString());
      return next.handle(req.clone({headers: headers}));
    }

    return next.handle(req);
  }
}
