import {Injectable} from '@angular/core';
import {AbstractObservableDataService, LocalStorage} from 'common';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {User} from './user';

@Injectable({providedIn: 'root'})
export class UserService extends AbstractObservableDataService<User> {
  constructor(private localStorage: LocalStorage) {
    super();
  }

  isSponsor(): boolean {
    return !!this.localStorage.getItem(environment.localStorageKeys.sponsorNew);
  }

  setAsSponsor(): void {
    this.localStorage.setItem(environment.localStorageKeys.sponsorNew, 'false');
  }

  hasBalanceToBuy(price: number): Observable<boolean> {
    return this.getData().pipe(
      first(),
      map(user => user.balance),
      map(balance => price <= balance),
    );
  }

  hasBalanceToBuyOrAutoCredit(price: number): Observable<boolean> {
    return this.getData().pipe(
      first(),
      map(user => {
        return user.autoCredit ? true : price <= user.balance;
      }),
    );
  }
}
