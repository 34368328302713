import {Serializable, SerializableDisableWarning} from 'common';

class SubscribableDatesInternal {
  @SerializableDisableWarning()
  day: string;

  @SerializableDisableWarning()
  hour: string;
}

export class SubscribableDates extends Serializable(SubscribableDatesInternal) {
  static createFromBackend(obj: any): SubscribableDates {
    let sd = new SubscribableDates();
    sd.day = obj?.day;
    sd.hour = obj?.hour;

    return sd;
  }
}
