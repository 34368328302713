import {Component, EventEmitter, Input, Output} from '@angular/core';

import {ngModelProvider} from '../../model/ng-model-config';
import {AbstractNgModel} from '../abstract-ngmodel';
import {TranslatableText} from '../../i18n/translatable-text';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-choice-row',
  templateUrl: './choice-row.component.html',
  styleUrls: ['./choice-row.component.scss'],
  providers: [ngModelProvider(ChoiceRowComponent)],
})
export class ChoiceRowComponent extends AbstractNgModel<boolean | string> {
  @Input()
  choiceTrueIcon: string;

  @Input()
  choiceTrueText: string | TranslatableText;

  @Input()
  choiceTrueValue: string | boolean = true;

  @Input()
  choiceFalseIcon: string;

  @Input()
  choiceFalseText: string | TranslatableText;

  @Input()
  choiceFalseValue: string | boolean = false;

  @Input()
  nullable = false;

  @Input()
  disableTrueChoice = false;

  @Input()
  disableFalseChoice = false;

  @Input()
  showHelp = true;

  @Output()
  buttonClick = new EventEmitter<boolean | string>();

  helpOpened = false;

  isDisabled = false;

  @Input()
  showSeparator = true;

  writeValue(obj: any): void {
    if (obj == null) {
      this.model = this.nullable ? null : this.choiceFalseValue;
    } else {
      this.model = obj;
    }
  }

  selectValue(value: boolean | string) {
    this.buttonClick.emit(value);
    const isString = typeof value === 'string';
    if (isString) {
      if (
        this.isDisabled ||
        (value === this.choiceTrueValue && this.disableTrueChoice) ||
        (value === this.choiceFalseValue && this.disableFalseChoice)
      ) {
        return;
      }
    } else {
      if (
        this.isDisabled ||
        (value && this.disableTrueChoice) ||
        (!value && this.disableFalseChoice)
      ) {
        return;
      }
    }

    if (this.model !== value) {
      this.model = value;
      this.modelChange(this.model);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
