export interface FailedAuthData {
  attempts: number;
  timestamp: number;
  timeoutTimestamp: number;
}

export enum FailedAuthErrorType {
  ATTEMPT,
  BLOCKED,
}
