import {NgIf, NgIfContext} from '@angular/common';
import {
  Directive,
  Inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import {ENVIRONMENT} from '../environment-token';

@Directive({
  selector: '[tlIfEnvironment]',
})
export class IfEnvironmentDirective extends NgIf {
  @Input()
  set tlIfEnvironment(environmentData: string | {path: string; value: any}) {
    const path =
      typeof environmentData === 'string' ? environmentData : environmentData?.path;

    let value: boolean;
    try {
      value = path.split('.').reduce((o, i) => o[i], this.environment);
      if (value === undefined) {
        throw new Error();
      }
    } catch {
      throw new Error(
        `IfEnvironmentDirective: environment path ${path} is undefined`,
      );
    }
    super.ngIf =
      typeof environmentData === 'string' ? value : environmentData?.value === value;
  }

  @Input()
  set tlIfEnvironmentElse(templateRef: TemplateRef<NgIfContext<any>>) {
    super.ngIfElse = templateRef;
  }

  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    templateRef: TemplateRef<NgIfContext<string>>,
    viewContainer: ViewContainerRef,
  ) {
    super(viewContainer, templateRef);
  }
}
