import {HttpErrorResponse} from '@angular/common/http';
import {Observable, OperatorFunction, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

/**
 * Intercepts http errors matching the given status, applying the given
 * function, and lets through the others. If this fuctions throws an error
 * itself it will emited down the chain.
 */
export function catchHttpError<T, R>(
  status: number,
  process: (error: HttpErrorResponse, source: Observable<T>) => Observable<R>,
): OperatorFunction<T, T | R> {
  return catchError((error: HttpErrorResponse, caught: Observable<T>) => {
    if (error.status === status) {
      try {
        return process(error, caught);
      } catch (e) {
        return throwError(() => e);
      }
    } else {
      return throwError(() => error);
    }
  });
}
