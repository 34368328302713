import {ErrorHandler, Injectable} from '@angular/core';
import {isCORSError, Logger, parseError} from 'common';
import {environment} from '~environments/environment';

@Injectable({providedIn: 'root'})
export class ServerErrorHandler extends ErrorHandler {
  constructor(private logger: Logger) {
    super();
  }

  handleError(error: any) {
    if (environment.production) {
      const err = parseError(error);

      // CORS errors as warnings
      if (isCORSError(error)) {
        this.logger.warn(err.message, err.context);
        return;
      }

      if (
        err.context &&
        (err.context.status === 401 || err.context.status === 504)
      ) {
        this.logger.debug(err.message, err.context);
      } else {
        this.logger.error(err.message, err.stacktrace, err.context);
      }

      if (err.message.includes('Error: Loading chunk')) {
        window.location.reload();
      }
    } else {
      throw error;
    }
  }
}
