import {Serializable, SerializableProperty, SerializableType} from 'common';
import {SubscribableDates} from '../../game-metadata/data/subscribable-date';

const WEEK_DAYS = 7;

class SubscriptionInternal {
  id: number;

  date: number;

  endDate: number;

  originalTicketId: number;

  random: boolean;

  @SerializableProperty(SubscribableDates, SerializableType.COLLECTION)
  subscribableDates: Array<SubscribableDates>;

  minJackpotAmount: number;

  reShare: boolean;

  get isWeekly(): boolean {
    return this.subscribableDates && this.subscribableDates.length === WEEK_DAYS;
  }
}

export class Subscription extends Serializable(SubscriptionInternal) {
  public static createFromBackend(obj: any) {
    let s = new Subscription();

    s.id = obj.id;
    s.date = obj.fechaCreacion;
    s.endDate = obj.fechaFin;
    s.originalTicketId = obj.originalBoletoId;
    s.random = obj.aleatorio;
    s.subscribableDates =
      obj.abonoDays && obj.abonoDays.map(SubscribableDates.createFromBackend);

    s.minJackpotAmount = obj.abonoMinJackpotAmount;
    s.reShare = obj.recompartir;

    return s;
  }
}
