import {AbstractControl, FormArray} from '@angular/forms';

export class ExtendedFormArray<T extends AbstractControl> extends FormArray {
  clear() {
    while (this.length !== 0) {
      this.removeAt(0);
    }
  }

  remove(item: T) {
    const index = this.controls.findIndex((c: T) => c === item);
    if (index >= 0) {
      this.removeAt(index);
    } else {
      throw Error('Item not found');
    }
  }
}
