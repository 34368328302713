import {Injectable} from '@angular/core';
import {AsyncLocalStorageService} from 'common';
import {Observable} from 'rxjs';

@Injectable()
export class TuLoteroLocalStorageService {
  constructor(private asyncLocalStorageService: AsyncLocalStorageService) {}

  public clear(): Observable<void> {
    return this.asyncLocalStorageService.clear();
  }

  getAll(): Observable<Array<{key: string; value: string}>> {
    return this.asyncLocalStorageService.getAll();
  }

  getItem(key: string): Observable<string | null> {
    return this.asyncLocalStorageService.getItem(key);
  }

  removeItem(key: string): Observable<void> {
    return this.asyncLocalStorageService.removeItem(key);
  }

  setItem(key: string, data: string): Observable<void> {
    return this.asyncLocalStorageService.setItem(key, data);
  }
}
