import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ResponsiveService} from '../../responsive/responsive.service';
import {DeviceService} from '../../device/device.service';
import {DeviceOs} from '../../device/deviceOs';

@Component({
  selector: 'tl-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoModalComponent implements AfterViewInit {
  @HostBinding('class.tl-video-modal')
  readonly hostClass = true;

  @Input()
  url: string;

  @ViewChild('videoElement', {static: false})
  videoRef: ElementRef;

  /** determines if the video is playing */
  loaded = false;
  iosPlayer = this.deviceService.getOS() === DeviceOs.IOS;
  /** Determines if user go to fullScreen mode to close on back fullScreen */
  fullScreenFlag = false;

  get video(): HTMLVideoElement {
    return this.videoRef.nativeElement;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private elementRef: ElementRef,
    private responsiveService: ResponsiveService,
    private deviceService: DeviceService,
  ) {}

  @HostListener('fullscreenchange', ['$event'])
  @HostListener('onwebkitfullscreenchange', ['$event'])
  onFullScreenChange(event) {
    const isFullScreen =
      document.fullscreenElement ||
      this.elementRef.nativeElement.webkitCurrentFullScreenElement ||
      this.elementRef.nativeElement.fullscreenElement ||
      (this.video as any).webkitCurrentFullScreenElement ||
      (this.video as any).webkitIsFullScreen;

    if (!isFullScreen) {
      this.close();
    }
  }

  ngAfterViewInit() {
    if (this.responsiveService.isMobile()) {
      // if is a IOS device use native player and enter fullscreen video
      this.toggleFullScreen(this.elementRef.nativeElement);
    }
  }

  close() {
    this.activeModal.close();
  }

  toggleFullScreen(elem: HTMLElement | HTMLVideoElement | any) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* IOS */
      elem.webkitRequestFullscreen();
    } else if (elem.webkitEnterFullscreen) {
      /* Safari */
      elem.webkitEnterFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  checkClose() {
    const inFullScreenIos =
      this.iosPlayer && (this.video as any).webkitDisplayingFullscreen;
    if (this.fullScreenFlag && !inFullScreenIos) {
      this.close();
    }
  }

  checkFullScreen() {
    const inFullScreenIos =
      this.iosPlayer && (this.video as any).webkitDisplayingFullscreen;
    if (inFullScreenIos) {
      this.fullScreenFlag = true;
    }
  }
}
