import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'translateAsset'})
export class TranslateAssetPipe implements PipeTransform {
  locale: string;

  constructor(@Inject(LOCALE_ID) private localeId: string) {
    this.locale = this.localeId.split('-')[0];
  }

  transform(assetPath: string): string {
    return assetPath.replace('.', `-${this.locale}.`);
  }
}
