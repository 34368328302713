import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {iif, Observable, of, timer} from 'rxjs';
import {finalize, map, switchMap} from 'rxjs/operators';

import {TicketShippingDao} from './ticket-shipping.dao';

export class ShippingAddressValidator {
  static isZipCodeValid(ticketShippingDao: TicketShippingDao): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      timer(800).pipe(
        switchMap(() =>
          iif(
            () => !!control.value.zipCode && !!control.value.state,
            ticketShippingDao
              .zipCodeMatchesState(control.value.zipCode, control.value.state)
              .pipe(
                map(data => (data.correct ? null : {stateZipCode: true})),
                finalize(() => control.setErrors(control.errors)),
              ),
            of({stateZipCode: true}).pipe(
              finalize(() => control.setErrors(control.errors)),
            ),
          ),
        ),
      );
  }
}
