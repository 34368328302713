import {ActionButton} from './action-button';

export class SmsActionButton extends ActionButton {
  static createFromBackend(obj: any) {
    let smsActionButton = new SmsActionButton();

    Object.assign(smsActionButton, ActionButton.createFromBackend(obj));

    return smsActionButton;
  }
}
