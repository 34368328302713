import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stringToArray',
})
export class StringToArrayPipe implements PipeTransform {
  transform(value: string): string[] {
    if (typeof value !== 'string') {
      return [];
    }
    return value.split('');
  }
}
