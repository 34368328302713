<div class="wrapper" [class.wrapper-grow]="!cellOptions?.width">
  <div class="grid-crop" [ngStyle]="cropStyle">
    <div class="grid-container" [ngStyle]="containerStyle">
      <div
        *ngFor="let cellPair of places | keyvalue"
        class="grid-cell"
        [ngStyle]="mergeStyles(cellPair.value.toStyle(rows, columns), cellStyle)">
        <ng-template
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{$implicit: cellPair.value}">
        </ng-template>
      </div>
    </div>
  </div>
</div>
