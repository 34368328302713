<div
  class="menu-title"
  (click)="onClick()"
  [ngClass]="{'menu-title-content-hidden': !display}">
  <ng-container *ngIf="headerTemplate; else defaultHeader">
    <ng-template
      [ngTemplateOutlet]="headerTemplate"
      [ngTemplateOutletContext]="{$implicit: display}">
    </ng-template>
  </ng-container>

  <ng-template #defaultHeader>
    <img class="img-fluid menu-icon" [src]="icon" alt="Icono menú" />
    <span class="title">{{ title }}</span>
    <span
      class="icon-angle-down-icon toggle-icon"
      [ngClass]="{'menu-icon-content-hidden': !display}">
    </span>
  </ng-template>
</div>

<div *ngIf="display" class="menu-content">
  <ng-content></ng-content>
</div>
