const BROWSER_PREFIXES = ['ms', 'moz', 'webkit'];

/**
 * Get property value with browser prefix.
 *
 * @param obj object which contains the property.
 * @param name Property name
 * @param callFunction property function should be invoked
 * @example invokePrefixedFunction(document, 'fullScreenElement');
 */
export function invokePrefixedProperty(
  obj: any,
  name: string,
  callFunction = false,
): any {
  if (obj[name]) {
    return callFunction ? obj[name]() : obj[name];
  } else {
    for (const prefix of BROWSER_PREFIXES) {
      const fnName = prefix + name.capitalize();
      if (obj[fnName]) {
        return callFunction ? obj[fnName]() : obj[fnName];
      }
    }
  }

  // Throw error if any function is executed
  throw new Error(`Error invoking ${name} property. No prefix available.`);
}

/**
 * Call function with browser prefix.
 *
 * @param obj object which contains the function.
 * @param name Function name
 *
 * @example invokePrefixedFunction(document, 'requestFullscreen');
 */
export function invokePrefixedFunction(obj: any, name: string): any {
  return invokePrefixedProperty(obj, name, true);
}
