import {MoneyActivity} from '../../../money/data/money-activity';
import {GroupMember} from './group-member';
import {SerializableProperty, SerializableType} from 'common';

export class MoneyActivityGroup extends MoneyActivity {
  @SerializableProperty(GroupMember, SerializableType.OBJECT)
  mainMember: GroupMember;

  public static createFromBackend(obj: any): any {
    let m = new MoneyActivityGroup();

    m.id = obj.id;
    m.date = obj.date;
    m.amount = obj.amount;
    m.type = obj.type;
    m.description = obj.desc;
    m.balance = obj.balance;
    m.symbol = obj.negative ? '-' : '+';
    if (obj.mainMember) {
      m.mainMember = GroupMember.createFromBackend(obj.mainMember);
    }

    return m;
  }
}
