import {Component, EventEmitter, Input, Output} from '@angular/core';

import {AbstractNgModel} from '../../../forms/abstract-ngmodel';
import {ngModelProvider} from '../../../model/ng-model-config';
import {isNumeric} from '../../../util/core/number';
import {
  isEditingKey,
  isNavigationKey,
  isNumericKey,
  isShortCutCopyOrPaste,
} from '../../../util/keyboard/key-utils';
import {applyCreditCardMask, detectCreditCardType} from '../credit-card-model';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-credit-card-input',
  templateUrl: './input-credit-card.component.html',
  styleUrls: ['./input-credit-card.component.scss'],
  providers: [ngModelProvider(InputCreditCardComponent)],
})
export class InputCreditCardComponent extends AbstractNgModel<string> {
  @Input('value')
  model: string;

  @Input()
  placeholder: string;

  @Input()
  id = Math.random().toString();

  @Input()
  ngTabindex: number;

  @Output()
  changeCard = new EventEmitter<string>();

  image: string;

  @Input()
  showCardIcon = true;

  private textLengthBeforeInput: number;

  onInput(event: any) {
    const card = detectCreditCardType(event.target.value);
    this.image = card ? card.image : null;
    const isTextInserted = event.target.value.length > this.textLengthBeforeInput;

    applyCreditCardMask(card, event.target, '-', isTextInserted);

    this.model = event.target.value;
    this.notify();
  }

  inputKeyDown(event: KeyboardEvent) {
    this.textLengthBeforeInput = (<any>event.target).value.length;
    const isLastKeyNumeric =
      typeof event.key === 'string' && isNumericKey(event.key);

    if (
      event.key != null &&
      !isLastKeyNumeric &&
      !isEditingKey(event.key) &&
      !isNavigationKey(event.key) &&
      !isShortCutCopyOrPaste(event)
    ) {
      return false;
    }
  }

  writeValue(value: string) {
    this.model = value;
    this.modelChange(this.model);
  }

  onPaste(event: ClipboardEvent) {
    this.textLengthBeforeInput = (<any>event.target).value.length;
    const pastedText = event.clipboardData
      .getData('text')
      .replace(/\s|-/g, '')
      .trim();

    if (!isNumeric(pastedText)) {
      return false;
    }
  }

  private notify() {
    this.modelChange(this.model);
    this.changeCard.emit(this.model);
  }
}
