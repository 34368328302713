import {NgModule} from '@angular/core';
import {ALERTS_READ_SPEED_FACTOR} from 'common';

import {environment} from '../../../environments/environment';

@NgModule({
  providers: [
    {
      provide: ALERTS_READ_SPEED_FACTOR,
      useValue: environment.alerts.readSpeedFactor,
    },
  ],
})
export class AlertsProvidersModule {}
