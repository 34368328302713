import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import {ngModelProvider} from '../../model/ng-model-config';
import {AbstractNgModel} from '../abstract-ngmodel';

@Component({
  selector: 'tl-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  providers: [ngModelProvider(ToggleButtonComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToggleButtonComponent extends AbstractNgModel<boolean> {
  @HostBinding('class')
  class = 'selectable-box';

  @Input('value')
  @HostBinding('class.highlight')
  model = false;

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  isDisabled = false;

  @HostBinding('class.tl-toggle-button')
  readonly hostClass = true;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  writeValue(obj: any): void {
    this.model = !!obj;
    this.cdr.detectChanges();
  }

  onChange(): void {
    this.model = !this.model;
    this.modelChange(this.model);
    this.toggle.emit(this.model);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }
}
