import {Inject, Injectable} from '@angular/core';

import {ENVIRONMENT} from '../environment/environment-token';
import {LocalStorage} from '../local-storage/local-storage';

@Injectable({providedIn: 'root'})
export class MessageHookStorageService {
  readonly STORAGE_KEY = '';

  readonly SEPARATOR = ':';

  private ids: Array<string> | null;

  constructor(
    private localStorage: LocalStorage,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {
    this.STORAGE_KEY = this.environment.localStorageKeys.popupStates;
    this.load();
  }

  exists(id: string): boolean {
    return this.ids.includes(id);
  }

  find(f: (f: string) => boolean): string {
    return this.ids.find(f);
  }

  remove(id: string): void {
    if (this.exists(id)) {
      this.ids.splice(this.ids.indexOf(id), 1);
      this.persistData();
    }
  }

  save(id: string): void {
    if (!this.exists(id)) {
      this.ids.push(id);
      this.persistData();
    }
  }

  private load() {
    const item = this.localStorage.getItem(this.STORAGE_KEY);
    this.ids = item ? item.split(this.SEPARATOR) : [];
  }

  private persistData() {
    this.localStorage.setItem(this.STORAGE_KEY, this.ids.join(this.SEPARATOR));
  }
}
