import {NgModule} from '@angular/core';

import {PageOpener, PageResolver} from './page-controller';
import {PageControllerService} from './page-controller.service';

@NgModule({
  providers: [
    // Both providers uses PageControllerService as instance, this is a hack to
    // manage several interfaces of the same service.
    {provide: PageResolver, useClass: PageControllerService},
    {provide: PageOpener, useExisting: PageResolver},
  ],
})
export class PageControllerModule {}
