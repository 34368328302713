import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Destroyable, GoogleMapComponent, Logger} from 'common';
import {Subject, combineLatest} from 'rxjs';
import {filter, first, map, skip, switchMap, takeUntil} from 'rxjs/operators';

import {GeolocationViewService} from '../../../geolocation/geolocation-view.service';
import {BoothSelectedService} from '../../booth-selected.service';
import {BoothsSearchViewService} from '../../booths-search-view.service';
import {LotteryBooth} from '../../data/lottery-booth';
import {LotteryBoothsGeolocationViewService} from '../../lottery-booths-geolocation-view.service';
import {LotteryBoothsListComponent} from '../../common/lottery-booths-list/lottery-booths-list.component';

@Component({
  selector: 'tl-booth-selector-modal',
  templateUrl: './booth-selector-modal.component.html',
  styleUrls: ['./booth-selector-modal.component.scss'],
  providers: [BoothsSearchViewService, LotteryBoothsGeolocationViewService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BoothSelectorModalComponent implements OnInit {
  @HostBinding('class.tl-booth-selector-modal')
  readonly hostClass = true;

  boothsDisplayed: Array<LotteryBooth>;

  mapReady = new EventEmitter<void>();

  initMapArgs = <google.maps.MapOptions>{
    center: {lat: 40.4167672, lng: -3.7044203},
    zoom: 10,
    gestureHandling: 'greedy',
    disableDefaultUI: true,
  };

  selectedId: string;

  @ViewChild(LotteryBoothsListComponent)
  boothList: LotteryBoothsListComponent;

  @Destroyable()
  private destroySubject = new Subject<void>();

  @ViewChild('googleMap', {static: true})
  private googleMap: GoogleMapComponent;

  private markersAdded = new EventEmitter<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private logger: Logger,
    public boothGeolocationViewService: LotteryBoothsGeolocationViewService,
    private boothSelectedService: BoothSelectedService,
    private boothsSearchViewService: BoothsSearchViewService,
    public geolocationViewService: GeolocationViewService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    combineLatest([this.boothGeolocationViewService.lotteryBooths, this.mapReady])
      .pipe(takeUntil(this.destroySubject))
      .subscribe(([booths]) => {
        this.boothsDisplayed = booths;
        this.initMarkers();
      });

    this.boothSelectedService.selectedBooth
      .pipe(skip(1), takeUntil(this.destroySubject))
      .subscribe(booth => {
        this.googleMap.selectMarker(booth.id);
        this.selectedId = booth.id;
      });

    combineLatest([this.boothSelectedService.selectedBooth, this.markersAdded])
      .pipe(
        first(),
        filter(([booth]) => !!booth),
        switchMap(([booth]) =>
          this.googleMap.markers[0]
            .getWhenReady('BoothSelectorModalComponent - ngOnInit')
            .pipe(map(() => booth)),
        ),
      )
      .subscribe(booth => {
        this.googleMap.selectMarker(booth.id);
        this.selectedId = booth.id;
        this.cdr.markForCheck();
      });
  }

  initMarkers() {
    this.googleMap.resetMarkers();
    this.boothsDisplayed.forEach(booth => {
      if (booth.latitude) {
        this.googleMap.addBoothMarker(
          new google.maps.LatLng(+booth.latitude, +booth.longitude),
          booth.id,
        );
      }
    });

    if (this.boothGeolocationViewService.lastPosition) {
      this.onGeolocationUpdated(this.boothGeolocationViewService.lastPosition);
    }

    this.markersAdded.emit();
  }

  onMarkerSelected(boothId: string) {
    this.onBoothSelected(this.boothsDisplayed.find(booth => booth.id === boothId));

    // @ts-ignore
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  onBoothSelected(booth) {
    this.boothSelectedService.selectedBooth.next(booth);
  }

  selectBooth(): void {
    this.activeModal.close(this.selectedId);
  }

  filterListBooths(): void {
    this.boothList.clearSearch();
    this.boothsSearchViewService.searchBounds.next(this.googleMap.getBounds());
  }

  protected onGeolocationUpdated(position: GeolocationPosition): void {
    this.googleMap.updateSelfMarker(
      new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
    );

    this.googleMap.selfMarker
      .getWhenReady('BoothSelectorModalComponent - onGeolocationUpdated')
      .subscribe({
        next: () => this.googleMap.focusSelfMarker(),
        error: err => this.logger.error(err.message, err.stack),
      });
  }
}
