import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputPhoneComponent} from './input-phone.component';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TriggerStyleModule} from '../../styles-triggers/trigger-style.module';
import {InputPhoneLocalComponent} from './input-phone-local/input-phone-local.component';
import {I18nModule} from '../../i18n/i18n.module';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {ScrollableModule} from '../../scrolling/scrollable/scrollable.module';
import {UtilModule} from '../../util/util-module';
import {InputPhoneCountryComponent} from './input-phone-country/input-phone-country.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TriggerStyleModule,
    I18nModule,
    NgbDropdownModule,
    ScrollableModule,
    UtilModule,
  ],
  exports: [
    InputPhoneComponent,
    InputPhoneLocalComponent,
    InputPhoneCountryComponent,
  ],
  declarations: [
    InputPhoneComponent,
    InputPhoneLocalComponent,
    InputPhoneCountryComponent,
  ],
})
export class InputPhoneModule {}
