import {CombinationTypeValue} from '../../combination/data/combination-type-declaration';
import {COMBINATION_TYPE_RESOLVER} from '../../combination/data/combination-type-resolver';

export class LotteryTicket {
  hash: string;

  _value: Array<CombinationTypeValue>;

  get value(): Array<CombinationTypeValue> {
    return this._value;
  }

  set value(v: Array<CombinationTypeValue>) {
    this._value = v;
    this.hash = LotteryTicket.getHash(v);
  }

  constructor(
    public v?: Array<CombinationTypeValue>,
    public availableAmount?: number,
    public transactionId?: number,
    public expiration?: number,
    public reservable?: boolean,
  ) {
    if (v) {
      this.value = v;
    }
  }

  static createFromBackend(obj: any) {
    let ticket = new LotteryTicket();

    ticket.value = obj.types
      ? obj.types.map(type =>
          COMBINATION_TYPE_RESOLVER[type.type].createFromBackend(type),
        )
      : undefined;

    ticket.availableAmount = obj.amount;
    ticket.transactionId = obj.transactionId;
    ticket.expiration = obj.expiration;
    ticket.reservable = obj.reservable;

    return ticket;
  }

  /**
   * Generates a string hash with the typeids and values, ordered by typeId
   * i.e.: 'ticket555sign10' for a ticket with number 555 and sign 10
   */
  public static getHash(
    value: Array<CombinationTypeValue> | {[key: string]: any},
    filterFraction?: string,
  ) {
    if (Array.isArray(value)) {
      return value
        .clone() // prevent reordering original array
        .sort((typeA, typeB) => (typeA.typeId > typeB.typeId ? -1 : 1))
        .reduce((res, type) => {
          let normalizedValue = Array.isArray(type.value[0])
            ? type.value[0][0].value
            : (type.value[0] as any).value;
          if (type.typeId === 'fraccion' && filterFraction) {
            normalizedValue = filterFraction;
          }
          return type.typeId + normalizedValue + res;
        }, '');
    } else {
      let res = '';
      Object.keys(value)
        .sort()
        .forEach(key => {
          let normalizedValue = Array.isArray(value[key])
            ? value[key][0]
            : value[key];
          if (key === 'fraccion' && filterFraction) {
            normalizedValue = filterFraction;
          }
          res += key + normalizedValue;
        });

      return res;
    }
  }
}
