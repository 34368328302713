import * as deepmerge from 'deepmerge';

import {ENVIRONMENT_DEV} from './global/dev';
import {ENVIRONMENT_MX} from './local/mx';

export const environment = deepmerge.all<any>([
  {},
  ENVIRONMENT_DEV,
  ENVIRONMENT_MX,
  {
    angularProduction: true,
    endpoint: 'https://web.mx.pre.tulotero.net/tuloteroweb/rest',
    endpointCommon: 'https://static.mx.stage.tulotero.net/allinfo.json',
    serviceWorkerEnabled: true,
    appleRedirectUri: 'https://web.mx.pre.tulotero.net',
    mockContacts: true,
  },
]);
