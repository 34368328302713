import {NgModule, NgZone} from '@angular/core';
import {GoogleContactsService, LocalStorage, Logger} from 'common';
import {environment} from '~environments/environment';

import {ContactsDao} from './contacts.dao';
import {ContactsService} from './contacts.service';
import {ContactsServiceMockE2e} from './contacts.service.mock.e2e';

function contactsServiceFactory(
  contactsDao,
  googleContactsService,
  localStorage,
  logger,
  ngZone,
) {
  return environment.mockContacts
    ? new ContactsServiceMockE2e()
    : new ContactsService(
        contactsDao,
        googleContactsService,
        localStorage,
        logger,
        ngZone,
      );
}

@NgModule({
  providers: [
    {
      provide: ContactsService,
      useFactory: contactsServiceFactory,
      deps: [ContactsDao, GoogleContactsService, LocalStorage, Logger, NgZone],
    },
  ],
})
export class ContactsModelModule {}
