import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import {AbstractNgModel} from '../../forms/abstract-ngmodel';
import {LocaleService} from '../../i18n/locale.service';
import {ngModelProvider} from '../../model/ng-model-config';
import {
  isCommaKey,
  isDeleteKey,
  isDotKey,
  isEditingKey,
  isModifierKey,
  isNavigationKey,
  isNumericKey,
  isShortCutCopyOrPaste,
} from '../../util/keyboard/key-utils';
import {MoneyPipe} from '../money.pipe';
import {parseCurrency} from '../../util/money';

@Component({
  selector: 'tl-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  providers: [ngModelProvider(CurrencyInputComponent)],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyInputComponent
  extends AbstractNgModel<number>
  implements OnChanges
{
  @Input()
  placeholder = '';

  @Input()
  disableZeroValue = false;

  @Input()
  min: number;

  @Input()
  max: number;

  @Output()
  tlBlur = new EventEmitter();

  displayModel = '';

  disabled = false;

  @Input()
  showDecimals = false;

  digitsInfo = '1.0-2';

  backupDisplayModel = '';

  @HostBinding('class.tl-currency-input')
  readonly hostClass = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private localeService: LocaleService,
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('showDecimals')) {
      if (this.showDecimals) {
        this.digitsInfo = '1.2-2';
      } else {
        this.digitsInfo = '1.0-2';
      }
    }
  }

  writeValue(obj: any): void {
    const reachedMinLimit = Number.isFinite(this.min) && obj < this.min;
    const reachedMaxLimit = Number.isFinite(this.max) && obj > this.max;
    if (reachedMinLimit) {
      obj = this.min;
    }
    if (reachedMaxLimit) {
      obj = this.max;
    }
    super.writeValue(this.disableZeroValue && obj === 0 ? undefined : obj);
    this.updateDisplayModel(this.model);
  }

  onBlur(event) {
    const value = event.target.value;
    const locale = this.localeService.getLocale();
    const parsed = parseCurrency(
      value,
      locale,
      this.showDecimals,
      this.backupDisplayModel,
      this.min,
    );
    this.model = isNaN(parsed) ? null : parsed;

    const reachedMinLimit = Number.isFinite(this.min) && this.model < this.min;
    const reachedMaxLimit = Number.isFinite(this.max) && this.model > this.max;
    if (reachedMinLimit) {
      this.model = this.min;
    }
    if (reachedMaxLimit) {
      this.model = this.max;
    }

    if (this.disableZeroValue && this.model === 0) {
      this.model = undefined;
    }

    if (this.modelChange) {
      this.modelChange(this.model);
    }

    this.updateDisplayModel(this.model);
    this.tlBlur.emit(event);
  }

  restrictChars(event: KeyboardEvent): boolean {
    const reachedMinLimit = Number.isFinite(this.min) && this.model < this.min;
    const reachedMaxLimit = Number.isFinite(this.max) && this.model > this.max;

    return (
      isDeleteKey(event.key) ||
      isEditingKey(event.key) ||
      isNavigationKey(event.key) ||
      isModifierKey(event.key) ||
      isShortCutCopyOrPaste(event) ||
      (isNumericKey(event.key) && !reachedMinLimit && !reachedMaxLimit) ||
      this.restrictSeparators(event.key)
    );
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.renderer.setAttribute(this.element.nativeElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.element.nativeElement, 'disabled');
    }
  }

  private updateDisplayModel(model: number): void {
    if (!!model) {
      const locale = this.localeService.getLocale();
      const moneyPipe = new MoneyPipe(locale);
      this.displayModel = moneyPipe.transform(
        this.model,
        null,
        null,
        this.digitsInfo,
        locale,
      );
    } else {
      this.displayModel = '';
    }
    this.cdr.markForCheck();
  }

  private restrictSeparators(key: string): boolean {
    if (this.showDecimals) {
      return isCommaKey(key) || isDotKey(key);
    } else {
      return false;
    }
  }
}
