import {Observable} from 'rxjs';

import {AsyncLocalStorageFactory} from './async-local-storage-factory';
import {IndexedDbLocalStorageConnection} from './indexed-db-local-storage-connection';

export class IndexedDbSessionFactory implements AsyncLocalStorageFactory {
  constructor() {}

  open(database: string): Observable<IndexedDbLocalStorageConnection> {
    return new Observable(observer => {
      const connection = indexedDB.open(database, 1);

      connection.onsuccess = (ev: Event) => {
        observer.next(new IndexedDbLocalStorageConnection((<any>ev.target).result));
      };

      connection.onupgradeneeded = e => {
        const db: IDBDatabase = (<any>e.target).result;
        db.createObjectStore(database, {keyPath: 'key'});
      };

      connection.onerror = err => observer.error(err);
    });
  }

  close(connection: IndexedDbLocalStorageConnection): void {
    connection.close();
  }
}
