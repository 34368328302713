<button
  class="btn tl-counter__handle tl-counter__less"
  type="button"
  [disabled]="disabled"
  (click)="previous(true)"
  (tlpress)="onPressPrev($event)"
  (tlpressup)="onPressUp($event)"
  [class.tl-counter__handle--disabled]="prevDisabled || disabled">
  &#8722;
</button>
<input
  type="text"
  inputmode="numeric"
  class="form-control tl-counter__number"
  [disabled]="disabled"
  [readOnly]="!manual || readOnly"
  (keydown)="keydown($event)"
  (blur)="blur($event)"
  [(ngModel)]="displayModel" />
<button
  class="btn tl-counter__handle tl-counter__plus"
  type="button"
  [disabled]="disabled"
  (click)="next(true)"
  (tlpress)="onPressNext($event)"
  (tlpressup)="onPressUp($event)"
  [class.tl-counter__handle--disabled]="nextDisabled || disabled">
  &#43;
</button>
