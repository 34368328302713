import {AbstractProcessDataService} from 'common';
import {Filter} from '../../../game-filter/data/filter';
import {GenericResult} from '../../data/generic-result';

export abstract class ResultsListViewProcessDataService extends AbstractProcessDataService<
  GenericResult,
  {
    filter: Filter;
  }
> {}
