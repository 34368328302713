import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {Logger} from '../logger/logger';

@Directive({selector: '[tlFileUrl]'})
export class DownloadFileDirective implements OnInit, OnDestroy {
  @Input()
  tlFileUrl: string;

  @Input()
  extension: string;

  @Input()
  fileName: string;

  urlBlob: string;

  @Output()
  downloadingFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  downloadCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected http: HttpClient,
    protected logger: Logger,
  ) {}

  ngOnInit() {
    if (!this.tlFileUrl) {
      throw new Error('Must provide a file url, was:' + this.tlFileUrl);
    }
    this.http.get(this.tlFileUrl, {responseType: 'blob'}).subscribe({
      next: res => {
        this.urlBlob = window.URL.createObjectURL(res);
      },
      error: e => {
        this.logger.error(`Error al cargar ${this.fileName}`, JSON.stringify(e));
      },
    });
  }

  ngOnDestroy() {
    if (this.urlBlob) {
      window.URL.revokeObjectURL(this.urlBlob);
    }
  }

  @HostListener('click')
  download() {
    if (!this.tlFileUrl) {
      return;
    }
    this.downloadingFile.next(true);
    let link = this.document.createElement('a');
    link.setAttribute('href', this.urlBlob);

    let file = this.fileName ? this.fileName : 'download';
    file += this.extension ? '.' + this.extension : '';

    link.setAttribute('download', file);
    link.style.visibility = 'hidden';
    this.document.body.appendChild(link);
    link.click();
    this.document.body.removeChild(link);
    this.downloadingFile.next(false);
    this.downloadCompleted.next(true);
  }
}
