import {Serializable, SerializableProperty} from 'common';

import {CombinationType} from './combination-type';
import {CombinationValue} from './combination-value';

class NumberCombinationTypeInternal implements CombinationType<CombinationValue> {
  typeId: string;

  type: string;

  @SerializableProperty(CombinationValue)
  value: Array<CombinationValue>;
}

export class NumberCombinationType extends Serializable(
  NumberCombinationTypeInternal,
) {
  static createFromBackend(obj: any) {
    let numbersRT = new NumberCombinationType();

    numbersRT.typeId = obj.typeId;
    numbersRT.type = obj.type;

    numbersRT.value = obj.value.map(v => {
      if (obj.type === 'NUMBER_EXTENDED') {
        return CombinationValue.fromJSON({
          value: '' + v.value,
          highlight: v.highlighted,
        });
      } else {
        return CombinationValue.fromJSON({value: '' + v, highlight: false});
      }
    });

    return numbersRT;
  }
}
