import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {NumberSymbol, formatNumber, getLocaleNumberSymbol} from '@angular/common';
import {CurrencyPosition, LocaleService} from '../../i18n/locale.service';

@Component({
  selector: 'tl-number-unit',
  templateUrl: './number-unit.component.html',
  styleUrls: ['./number-unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NumberUnitComponent implements OnChanges, OnInit {
  @Input()
  value: number;

  @Input()
  description: string;

  @Input()
  color: string;

  /**
   * Hides the decimal part of the number.
   */
  @Input()
  integer = false;

  unit: string;

  prefixUnit = false;

  number: string;

  decimal: string;

  decimalSeparator: string;

  @Input()
  showCurrency = true;

  @HostBinding('class.tl-number-unit')
  readonly hostClass = true;

  constructor(
    private elementRef: ElementRef,
    private localeService: LocaleService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (this.color) {
      this.setColor(this.color);
    }
    if (this.showCurrency) {
      this.unit = this.localeService.getCurrencySymbol();
      this.prefixUnit =
        this.localeService.getCurrencySymbolPosition() === CurrencyPosition.START;
    }
    this.decimalSeparator = this.calculateDecimalSeparators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('value')) {
      this.splitNumber(changes['value'].currentValue);
    }
  }

  private setColor(color: string) {
    this.renderer.addClass(
      this.elementRef.nativeElement,
      'tl-number-unit--' + color,
    );
  }

  private splitNumber(value: number) {
    let chunks = (Math.round(value * 1000) / 1000).toString().split('.');
    this.number = this.formatNumber(chunks[0]);
    if (chunks[1]) {
      this.decimal = chunks[1].length === 1 ? chunks[1].concat('0') : chunks[1];
    } else {
      this.decimal = '00';
    }
  }

  private calculateDecimalSeparators(): string {
    let locale = this.localeService.getLocale();
    if (locale === 'pt-PT') {
      // WEB-1913 -- Format currency numbers same spain format
      locale = 'es-ES';
    }
    return getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal);
  }

  private formatNumber(number): string {
    let locale = this.localeService.getLocale();
    if (locale === 'pt-PT') {
      // WEB-1913 -- Format currency numbers same spain format
      locale = 'es-ES';
    }
    return formatNumber(number, locale);
  }
}
