import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

import {I18nModule} from '../i18n/i18n.module';

import {InfoTooltipComponent} from './info-tooltip.component';
import {InfoComponent} from './info.component';

@NgModule({
  imports: [CommonModule, I18nModule, NgbTooltipModule],
  exports: [InfoComponent, InfoTooltipComponent],
  declarations: [InfoComponent, InfoTooltipComponent],
})
export class InfoModule {}
