import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {AbstractControl, FormArray} from '@angular/forms';

@Component({
  selector: 'tl-boolean-table',
  templateUrl: './boolean-table.component.html',
  styleUrls: ['./boolean-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BooleanTableComponent {
  @Input()
  cellLabel: string;

  @Input()
  cellImage: string = null;

  @Input()
  borderColor = '';

  @Input()
  selectedColor = '';

  @Input()
  selectedBorderColor = '';

  @Input()
  @HostBinding('class.tl-boolean-table--highlighted')
  @HostBinding('style.background')
  highlight: string;

  @Input()
  maxSelected: number;

  @Input()
  form: FormArray;

  @HostBinding('class.tl-boolean-table')
  readonly hostClass = true;

  onCellToggle(control: AbstractControl): void {
    if (control.value) {
      // always allow deselecting
      control.setValue(false);
      return;
    }

    const totalTrues = this.form.value.reduce(
      (total: number, item: boolean) => total + (item ? 1 : 0),
      0,
    );

    if (totalTrues === this.maxSelected) {
      return;
    }

    control.setValue(true);
  }
}
