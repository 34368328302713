import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, throwError} from 'rxjs';
import {first, switchMap, tap} from 'rxjs/operators';

import {SessionService} from '../../../../user/auth/session.service';
import {CombinationTypeValue} from '../../../combination/data/combination-type-declaration';
import {CombinationValue} from '../../../combination/data/combination-value';
import {LotteryTicket} from '../../../lottery/data/lottery-ticket';
import {LotteryTicketPaginationData} from '../../../lottery/data/lottery-ticket-pagination-data';
import {LotteryDao} from '../../../lottery/data/lottery.dao';
import {DynamicLotteryBetFormService} from '../di/dynamic-lottery-bet-form.service';

import {AbstractLotterySearch} from './abstract-lottery-search';
import {LotterySearchParams} from './lottery-search-params';
import {LotterySearchType} from './lottery-search-type';
import {GameMetadata} from '../../../game-metadata/data/game-metadata';
import {AbstractLotteryBetFormService} from '../form/abstract-lottery-bet-form.service';

@Injectable()
export class GenericLotterySearchService extends AbstractLotterySearch {
  totalTickets = 0;

  searchTypes: Array<CombinationTypeValue>;

  isFiltered = new ReplaySubject<boolean>(1);

  private betFormService: AbstractLotteryBetFormService;

  get raffleId(): number {
    return this.raffle;
  }

  set raffleId(id: number) {
    this.raffle = id;
    this.betFormService.updateRaffleId(id);
  }

  constructor(
    private dynamicLotteryBetFormService: DynamicLotteryBetFormService,
    private lotteryDao: LotteryDao,
    private sessionService: SessionService,
  ) {
    super();
  }

  setGameMetadata(gameMetadata: GameMetadata) {
    this.gameMetadata = gameMetadata;
    this.betFormService = <AbstractLotteryBetFormService>(
      this.dynamicLotteryBetFormService.getInstance(this.gameMetadata)
    );
  }

  loadData(): Observable<LotteryTicketPaginationData> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap(isLoggedIn => {
        const operation = isLoggedIn
          ? this.lotteryDao.searchLotteryTicketsAsUser
          : this.lotteryDao.searchLotteryTickets;

        return (<Observable<LotteryTicketPaginationData>>(
          operation.call(
            this.lotteryDao,
            this.raffleId,
            this.paginationIndex,
            this.gameMetadata,
            this.searchTypes,
            this.minNumber,
          )
        )).pipe(
          tap((data: LotteryTicketPaginationData) => {
            this.paginationEnd =
              this.paginationIndex + data.tickets.length >= data.searchableTickets ||
              data.tickets.length === 0;
            this.numberTickets = data.searchableTickets;
            this.totalTickets = data.ticketStock;
          }),
        );
      }),
    );
  }

  setSearchParams(params: LotterySearchParams): void {
    this.searchTypes = params ? params.get(LotterySearchType.VALUE_TYPES) : null;
    this.minNumber = params?.get(LotterySearchType.MIN_NUMBER) ?? null;
    this.location = params?.get(LotterySearchType.LOCATION) ?? null;

    this.updateIsFiltered();
  }

  updateIsFiltered(): void {
    let filtered =
      this.searchTypes &&
      this.searchTypes.some((combinationType: CombinationTypeValue) =>
        combinationType.value.some(
          combinationValue =>
            (combinationValue instanceof CombinationValue &&
              combinationValue.value !== '') ||
            (combinationValue instanceof Array &&
              combinationValue.some(
                (subCombinationValue: CombinationValue) =>
                  subCombinationValue.value !== '',
              )),
        ),
      );
    filtered = filtered || this.minNumber > 1 || Boolean(this.location);
    this.isFiltered.next(filtered);
  }

  clear(): void {
    super.clear();
  }

  reset(): Observable<Array<LotteryTicket>> {
    return super.reset();
  }

  getStock(boothId: string): Observable<{stock: number; boothId: string}> {
    return throwError(() => new Error('Not supported'));
  }

  getStockExcludeBooth(
    adminExclude: string,
    amount: number,
  ): Observable<{stock: number; boothId: string}> {
    return throwError(() => new Error('Not supported'));
  }
}
