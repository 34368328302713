import {AlphanumericCombinationType} from './alphanumeric-combination-type';
import {BooleanArrayCombinationType} from './boolean-array-combination-type';
import {DigitsCombinationType} from './digits-combination-type';
import {NumberCombinationType} from './number-combination-type';
import {SelectionCombinationType} from './selection-combination-type';
import {EmptyCombinationType} from './empty-combination-type';

export const COMBINATION_TYPE_RESOLVER = {
  GUESS_DIGIT: DigitsCombinationType,
  GUESS_DIGIT_EXTENDED: DigitsCombinationType,
  NUMBER: NumberCombinationType,
  REPETIBLE_NUMBER: NumberCombinationType,
  NUMBER_EXTENDED: NumberCombinationType,
  SELECTION: SelectionCombinationType,
  ALPHANUMERIC: AlphanumericCombinationType,
  ALPHANUMERIC_EXTENDED: AlphanumericCombinationType,
  MATRIX: DigitsCombinationType,
  BOOLEAN: BooleanArrayCombinationType,
  BOOLEAN_ARRAY: BooleanArrayCombinationType,
  EMPTY: EmptyCombinationType,
};
