import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {TriggerStyleModule} from '../../styles-triggers/trigger-style.module';
import {UtilModule} from '../../util/util-module';

import {BankAccountInitWidthPipe} from './bank-account-init-width.pipe';
import {BankAccountComponent} from './bank-account.component';

@NgModule({
  imports: [CommonModule, FormsModule, TriggerStyleModule, UtilModule],
  exports: [BankAccountComponent, BankAccountInitWidthPipe],
  declarations: [BankAccountComponent, BankAccountInitWidthPipe],
})
export class BankAccountModule {}
