import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {first} from 'rxjs';

import {Logger} from '../logger/logger';
import {CameraType} from './camera-type';
import {Camera} from './camera';
import {CameraService} from './camera.service';
import {ResponsiveService} from '../responsive/responsive.service';

@Component({
  selector: 'tl-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.tl-camera')
  readonly hostClass = true;

  @Output()
  videoInitialized = new EventEmitter();

  @Input()
  type: CameraType = CameraType.BACK;

  @Output()
  cameraError: EventEmitter<'NotAllowedError' | 'Unknown'> = new EventEmitter();

  cameraExists = true;

  @ViewChild('video', {static: false})
  videoRef: ElementRef;

  get video(): HTMLVideoElement {
    return this.videoRef.nativeElement;
  }

  private mediaStream: MediaStream = null;

  private devices: Array<MediaDeviceInfo>;

  constructor(
    private cameraService: CameraService,
    private element: ElementRef,
    private logger: Logger,
    private responsiveService: ResponsiveService,
    @Inject('window') private window: Window,
  ) {}

  ngAfterViewInit(): void {
    this.cameraService
      .getCameraSelector(this.type, this.getBestCamera.bind(this))
      .pipe(first())
      .subscribe({
        next: (config: boolean | MediaTrackConstraintSet) => {
          this.configCamera(config);
        },
        error: error => {
          this.logger.error('CameraComponent: Get camera selector', undefined, {
            error: error,
          });
          this.handleError(error);
        },
      });
  }

  ngOnDestroy(): void {
    this.stopMediaTracks();
  }

  takePicture(): string {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const imageWidth = this.video.videoWidth;
    const imageHeight = this.video.videoHeight;

    let offsetCropX = 0;

    const nativeElement = this.element.nativeElement;
    const aspectRatio = nativeElement.offsetWidth / nativeElement.offsetHeight;

    const widthAfterCrop = imageHeight * aspectRatio;
    offsetCropX = (imageWidth - widthAfterCrop) / 2;

    canvas.width = widthAfterCrop;
    canvas.height = imageHeight;

    context.drawImage(
      this.video,
      offsetCropX,
      0,
      widthAfterCrop,
      imageHeight,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    return canvas.toDataURL('image/jpeg');
  }

  public getBestCamera(
    currentCamera: Camera,
    nextCamera: Camera,
    data: {
      logger: Logger;
      cameraType: CameraType;
      devices: Array<MediaDeviceInfo>;
    },
  ): Camera {
    const nextCapabilities = nextCamera.tracks[0].capabilities;

    // Log when there is capabilities and is desktop
    if (!!nextCapabilities && this.responsiveService.isDesktop()) {
      data.logger.warn(
        'CameraComponent: There is capabilities and facingMode in Desktop',
        {
          devices: this.devices,
          capabilities: nextCapabilities,
        },
      );
    }

    // Log when there is not capabilities
    if (!nextCapabilities) {
      // Firefox in Ubuntu: capabilities === undefined
      // Safari in MacOS: capabilities.facingMode === undefined
      data.logger.warn(
        `CameraComponent: There is not capabilities ${
          this.responsiveService.isDesktop() ? 'in Desktop' : 'in Mobile'
        }`,
        {
          device: nextCamera.device,
          devices: data.devices,
          capabilities: nextCapabilities,
        },
      );
    }

    // Log when there is capabilities and there is not facingMode
    if (
      !!nextCapabilities &&
      (!nextCapabilities.facingMode || nextCapabilities.facingMode.length === 0)
    ) {
      // Chrome in Ubuntu: capabilities.facingMode === []
      data.logger.warn(
        `CameraComponent: There is not facingMode ${
          this.responsiveService.isDesktop() ? 'in Desktop' : 'in Mobile'
        }`,
        {
          device: nextCamera.device,
          devices: data.devices,
          capabilities: nextCapabilities,
        },
      );
    }

    // Request front camera and next camera is not front
    if (
      data.cameraType === CameraType.FRONT &&
      !!nextCapabilities &&
      !!nextCapabilities.facingMode &&
      nextCapabilities.facingMode.length > 0 &&
      nextCapabilities.facingMode[0] === 'environment'
    ) {
      // Return before
      return currentCamera;
    }

    // Request back camera and next camera is not back
    if (
      data.cameraType === CameraType.BACK &&
      !!nextCapabilities &&
      !!nextCapabilities.facingMode &&
      nextCapabilities.facingMode.length > 0 &&
      nextCapabilities.facingMode[0] === 'user'
    ) {
      // Return before
      return currentCamera;
    }

    // Selected if it has torch
    if (!!nextCapabilities && !!nextCapabilities['torch']) {
      return nextCamera;
    }

    if (!!nextCapabilities) {
      // Selected last
      return nextCamera;
      // Selected first
      // return !!currentCamera ? currentCamera : nextCamera;
    }

    // If we can not detect if it is better camera, select current
    // (There is not capabilities)
    // (There is not facinMode)
    return currentCamera;
  }

  private configCamera(videoOptions: boolean | MediaTrackConstraintSet): void {
    let streaming = false;

    // access video stream from webcam
    this.window.navigator.mediaDevices
      .getUserMedia({
        video: videoOptions,
        audio: false,
      })
      // on success, stream it in video tag
      .then(stream => {
        this.mediaStream = stream;
        this.video.srcObject = stream;
        this.video.setAttribute('autoplay', '');
        this.video.setAttribute('muted', '');
        this.video.setAttribute('playsinline', '');
        this.video.play();

        stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
          const capabilities: MediaTrackCapabilities = track.getCapabilities
            ? track.getCapabilities()
            : undefined;
          // const settings: MediaTrackSettings = track.getSettings();
          const config: MediaTrackConstraints = {};

          // const maxWidth = capabilities?.width.max;
          // const maxHeight = capabilities?.height.max;

          // if (!!maxWidth && !!maxHeight) {
          //   // aspect ratio width / height
          //   if(maxHeight * settings.aspectRatio < maxWidth) {
          //     config.height = {ideal: maxHeight};
          //     config.width = {ideal: maxHeight * settings.aspectRatio};
          //     config.aspectRatio = {ideal: settings.aspectRatio};
          //   } else if (maxWidth / settings.aspectRatio < maxHeight) {
          //     config.height = {ideal: maxWidth / settings.aspectRatio};
          //     config.width = {ideal: maxWidth};
          //     config.aspectRatio = {ideal: settings.aspectRatio};
          //   } else {
          //     this.logger.warn(
          //       'CameraComponent: settings.aspectRatio incompatible with max
          //       height or witdh',
          //       {
          //         settings: settings,
          //         capabilities: capabilities,
          //         device: this.device,
          //         devices: this.devices,
          //       }
          //     );
          //   }
          // } else {
          //   config.height = {ideal: 1900};
          // }

          config.height = {
            ideal: !!capabilities?.height?.max ? capabilities.height.max : 1900,
          };
          if (videoOptions === true) {
            // is desktop, force aspect ratio 16/9
            config.aspectRatio = 1.777777778;
          }

          track
            .applyConstraints(config)
            .then(() => {
              // Log info
              const settings: MediaTrackSettings = track.getSettings();
              if (
                capabilities &&
                settings.height < (config.height as ConstrainULongRange).ideal
              ) {
                this.logger.warn('CameraComponent: Settings worse that requested', {
                  videoOptions: videoOptions,
                  config: config,
                  devices: this.devices,
                  capabilities: capabilities,
                  settings: settings,
                });
              }
            })
            .catch(error => {
              // The constraints could not be satisfied by the available devices.
              this.logger.error('CameraComponent: Error applyConstraints', error);
              this.handleError(error);
            });
        });
      })
      .catch(error => {
        this.handleError(error);
        this.logger.error('CameraComponent: Error startup', undefined, {
          error: error,
          videoOptions: videoOptions,
          devices: this.devices,
        });
      });

    this.video.addEventListener(
      'canplay',
      () => {
        if (!streaming) {
          this.videoInitialized.emit();
          streaming = true;
        }
      },
      false,
    );
  }

  /**
   * Stops all active media tracks.
   * This prevents the webcam from being indicated as active,
   * even if it is no longer used by this component.
   */
  private stopMediaTracks() {
    if (this.mediaStream && this.mediaStream.getTracks) {
      this.mediaStream
        .getTracks()
        .forEach((track: MediaStreamTrack) => track.stop());
    }
  }

  private handleError(error): void {
    this.cameraError.next(error.name);
  }
}
