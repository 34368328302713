import {Directive, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

import {PopoverExtensionService} from './popover-extension.service';

@Directive({selector: '[ngbPopover][triggers="manual"][tlCloseOthers]'})
export class CloseOthersDirective implements OnInit, OnDestroy {
  @Input('tlCloseOthers')
  popover: NgbPopover;

  constructor(private popoverExtensionService: PopoverExtensionService) {}

  ngOnInit(): void {
    this.popoverExtensionService.add(this.popover);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.popover.isOpen()) {
      this.popoverExtensionService.closeAll();
    }
  }

  ngOnDestroy(): void {
    this.popoverExtensionService.remove(this.popover);
  }
}
