import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {SubscriptionsNotExecuted} from './subscriptions-not-executed';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line max-len
export class SubscriptionsNotExecutedService extends AbstractObservableDataService<SubscriptionsNotExecuted> {
  constructor() {
    super();
  }
}
