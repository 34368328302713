import {Observable, OperatorFunction, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

/**
 * Intercepts errors matching the given type, applying the given function,
 * and lets through the others.
 * If this fuctions throws an error itself it will emited down the chain.
 */
export function catchErrorOf<T, R>(
  errorType: any,
  process: (error: any, source: Observable<T>) => Observable<R>,
): OperatorFunction<T, T | R> {
  return catchError((error: any, caught: Observable<T>) => {
    if (error instanceof errorType) {
      try {
        return process(error, caught);
      } catch (e) {
        return throwError(() => e);
      }
    } else {
      return throwError(() => error);
    }
  });
}
