import {Injectable} from '@angular/core';
import {
  AbstractObservableDataService,
  isNumeric,
  LocalStorage,
  PageableService,
  PaginationData,
} from 'common';
import {Observable, of, ReplaySubject} from 'rxjs';
import {first, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {SessionService} from '../../../user/auth/session.service';

import {News} from './news';
import {NewsDao} from './news-dao';

@Injectable({providedIn: 'root'})
export class NewsService
  extends AbstractObservableDataService<Array<News>>
  implements PageableService<Array<News>>
{
  paginationIndex = 0;

  paginationEnd = false;

  private hasData = false;

  private freshNews = new ReplaySubject<number>(1);

  constructor(
    private newsDao: NewsDao,
    private localStorage: LocalStorage,
    private sessionService: SessionService,
  ) {
    super();

    this.sessionService.userLogoutEvent.subscribe(() => this.clearFreshNews());
    this.freshNews.next(
      +this.localStorage.getItem(environment.localStorageKeys.freshNews) || 0,
    );
  }

  getFreshNews(): Observable<number> {
    return this.freshNews.asObservable();
  }

  setFreshNews(fresh: number): void {
    if (isNumeric(fresh)) {
      this.freshNews.next(fresh);
      this.localStorage.setItem(
        environment.localStorageKeys.freshNews,
        fresh.toString(),
      );
    }
  }

  clearFreshNews(): void {
    this.freshNews.next(0);
    this.localStorage.setItem(environment.localStorageKeys.freshNews, '');
  }

  /**
   * Gets new news from the server.
   * There's no duplicity check nor ordering when appending the newly received
   * news to the already existing ones.
   */
  loadMore(): Observable<Array<News>> {
    if (this.paginationEnd) {
      return this.getData();
    }

    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap(isLoggedIn => {
        if (isLoggedIn) {
          return this.newsDao.getUserNews(this.paginationIndex);
        } else {
          return this.newsDao.getNews(this.paginationIndex);
        }
      }),
      switchMap((newsData: PaginationData<News>) => {
        const newsList = newsData.data;
        this.paginationIndex = this.paginationIndex + newsList.length;

        if (this.paginationIndex >= newsData.total) {
          this.paginationEnd = true;
        }

        if (this.hasData) {
          return this._data.pipe(
            map(list => list.concat(newsList)),
            first(),
          );
        } else {
          return of(newsList);
        }
      }),
      tap(news => this.setData(news)),
    );
  }

  reset(): Observable<Array<News>> {
    this.paginationEnd = false;
    this.paginationIndex = 0;
    this.hasData = false;
    this.setData([]);

    return this.loadMore();
  }

  setData(data: Array<News>): void {
    super.setData(data);
    this.hasData = !!data && !!data.length;
  }

  /**
   * Mark the news as visited and return the news.
   */
  setVisited(id: number): Observable<News> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap(isLoggedIn => {
        if (isLoggedIn) {
          return this.newsDao.visitUserNews(id);
        } else {
          return this.newsDao.visitNews(id);
        }
      }),
      map(res => News.createFromBackend(res)),
    );
  }
}
