import {NgModule} from '@angular/core';

import {TranslableDataPipe} from './translable-data.pipe';
import {TranslablePipe} from './translable.pipe';
import {TranslateAssetPipe} from './translable-asset.pipe';
import {IsTranslatableTextPipe} from './is-translatable-text.pipe';
import {TranslatableUrlPipe} from './translatable-url.pipe';

@NgModule({
  exports: [
    TranslablePipe,
    TranslableDataPipe,
    TranslateAssetPipe,
    IsTranslatableTextPipe,
    TranslatableUrlPipe,
  ],
  declarations: [
    TranslablePipe,
    TranslableDataPipe,
    TranslateAssetPipe,
    IsTranslatableTextPipe,
    TranslatableUrlPipe,
  ],
})
export class I18nModule {}
