import {Inject, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, switchMap} from 'rxjs';
import {finalize, first, tap} from 'rxjs/operators';

import {ASYNC_LOCAL_STORAGE_TOKEN} from './async-local-storage-token';
import {IndexedDbLocalStorageConnection} from './indexed-db-local-storage-connection';
import {IndexedDbSessionFactory} from './indexed-db-session.factory';

@Injectable()
export class AsyncLocalStorageService implements OnDestroy {
  private connection: BehaviorSubject<IndexedDbLocalStorageConnection> =
    new BehaviorSubject<IndexedDbLocalStorageConnection>(null);

  constructor(@Inject(ASYNC_LOCAL_STORAGE_TOKEN) private token: string) {}

  clear(): Observable<void> {
    return this.getConnection().pipe(switchMap(connection => connection.clear()));
  }

  setItem(key: string, data: string): Observable<void> {
    return this.getConnection().pipe(
      switchMap(connection => connection.setItem(key, data)),
    );
  }

  getItem(key: string): Observable<string | null> {
    return this.getConnection().pipe(
      switchMap(connection => connection.getItem(key)),
    );
  }

  removeItem(key: string): Observable<void> {
    return this.getConnection().pipe(
      switchMap(connection => connection.removeItem(key)),
    );
  }

  getAll(): Observable<Array<{key: string; value: string}>> {
    return this.getConnection().pipe(switchMap(connection => connection.getAll()));
  }

  close(): void {
    if (this.connection) {
      this.connection.pipe(
        first(),
        tap(connection => connection.close()),
        finalize(() => this.connection.next(null)),
      );
    }
  }

  ngOnDestroy(): void {
    this.close();
  }

  private initialize(): Observable<IndexedDbLocalStorageConnection> {
    const indexedDbSessionFactory = new IndexedDbSessionFactory();
    return indexedDbSessionFactory
      .open(this.token)
      .pipe(tap(connection => this.connection.next(connection)));
  }

  private getConnection(): Observable<IndexedDbLocalStorageConnection> {
    return this.connection.pipe(
      first(),
      switchMap(connection => {
        if (!connection) {
          return this.initialize();
        }
        return of(connection);
      }),
    );
  }
}
