import {deepFreeze} from 'common';

export const TLUPLOADED_CONTACTS_FIXTURE_E2E = [
  {
    id: 42095,
    date: 1505922355826,
    type: 'TELEFONO',
    phone: '34659798081',
    name: 'Dani TuLotero',
    shortName: 'Dani TuLotero',
    initials: 'DT',
  },
  {
    id: 41249,
    date: 1447342195002,
    type: 'TELEFONO',
    phone: '34645737618',
    name: 'Marcos Lopez (marcos.lo.pez.miguel@gmail.com)',
    shortName: 'Marcos Lopez',
    initials: 'ML',
  },
  {
    id: 39020,
    date: 1441025736239,
    type: 'TELEFONO',
    phone: '34635471271',
    name: 'Eva TuLotero (ababa@gmai.com)',
    nombreWithoutMails: 'Ayoze TuLotero',
    initials: 'ET',
  },
  {
    id: 39021,
    date: 1441025736239,
    type: 'TELEFONO',
    phone: '34635471234',
    name: 'Eugenio Perez(eugenio@gmai.com)',
    nombreWithoutMails: 'Eugenio TuLotero',
    initials: 'EP',
  },
];

deepFreeze(TLUPLOADED_CONTACTS_FIXTURE_E2E);
