import {Serializable} from 'common';

export class MoneySummaryInternal {
  amountCurrent: number;
  amountInit: number;
  betsSuperior: boolean;
  filledWithData: boolean;
  income: number;
  lastUpdate: number;
  totalBets: number;
  totalPrizes: number;
  totalPrizesGroups: number;
  totalPromotion: number;
  totalReceivedGroups: number;
  totalSentToGroups: number;
  totalShippingCosts: number;
  withDrawals: number;
}

export class MoneySummary extends Serializable(MoneySummaryInternal) {
  public static createFromBackend(obj: any): MoneySummary {
    let moneySummary = new MoneySummary();
    moneySummary.amountCurrent = obj.amountCurrent;
    moneySummary.amountInit = obj.amountInit;
    moneySummary.betsSuperior = obj.betsSuperior;
    moneySummary.filledWithData = obj.filledWithData;
    moneySummary.income = obj.income;
    moneySummary.lastUpdate = obj.lastUpdate;
    moneySummary.totalBets = obj.totalBets;
    moneySummary.totalPrizes = obj.totalPrizes;
    moneySummary.totalPrizesGroups = obj.totalPrizesGroups;
    moneySummary.totalPromotion = obj.totalPromotion;
    moneySummary.totalReceivedGroups = obj.totalReceivedGroups;
    moneySummary.totalSentToGroups = obj.totalSentToGroups;
    moneySummary.totalShippingCosts = obj.totalShippingCosts;
    moneySummary.withDrawals = obj.withDrawals;
    return moneySummary;
  }
}
