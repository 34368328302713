export enum GroupBalanceMode {
  FREE = 'FREE',
  MANAGED = 'MANAGED',
}

export enum GroupPrizeMode {
  SHARE = 'SHARE',
  KEEP = 'KEEP',
  KEEP_MANAGED = 'KEEP_MANAGED',
}
