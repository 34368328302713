import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {isToday} from 'date-fns';

@Component({
  selector: 'tl-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DateComponent {
  date: number;

  isToday = false;

  @HostBinding('class.tl-date')
  readonly hostClass = true;

  @Input()
  set value(date: number) {
    this.date = date;
    this.isToday = isToday(date);
  }
}
