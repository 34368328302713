import {Serializable} from 'common';

class ClubInfoInternal {
  codePrefix: string;
}

export class ClubInfo extends Serializable(ClubInfoInternal) {
  static createFromBackend(obj: any): ClubInfo {
    let info = new ClubInfo();

    info.codePrefix = obj.penyaUserPromoPrefix;

    return info;
  }
}
