import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tl-round-icon-button',
  templateUrl: './round-icon-button.component.html',
  styleUrls: ['./round-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RoundIconButtonComponent {
  @Input()
  text: string;

  @Input()
  icon: string;

  @Input()
  image: string;

  @Input()
  description: string;

  @Input()
  color: string;

  @Output()
  clickAction = new EventEmitter<any>();

  @HostBinding('class.tl-round-icon-button')
  readonly hostClass = true;

  click(event: any) {
    this.clickAction.emit(event);
  }
}
