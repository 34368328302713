import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {finalize} from 'rxjs/operators';

import {GoogleAuthService, GoogleUser} from '../google-auth.service';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleLoginComponent {
  @Input()
  buttonClass: string;

  @Input()
  text: string;

  @Input()
  loading = false;

  @Output()
  login = new EventEmitter<GoogleUser>();

  private submitted = false;

  constructor(private authService: GoogleAuthService) {}

  signIn() {
    if (this.submitted) {
      return;
    }

    this.submitted = true;

    this.authService
      .login(['email', 'profile'])
      .pipe(finalize(() => (this.submitted = false)))
      .subscribe(user => this.login.next(user));
  }
}
