import {NgModule} from '@angular/core';

import {ScrollableModule} from '../scrolling/scrollable/scrollable.module';

import {TriggerOnKeyboardOpenDirective} from './trigger-on-keyboard-open.directive';
import {TriggerStyleScrollDirective} from './trigger-style-scroll.directive';
import {TriggerStyleDirective} from './trigger-style.directive';

@NgModule({
  imports: [ScrollableModule],
  exports: [
    TriggerStyleDirective,
    TriggerStyleScrollDirective,
    TriggerOnKeyboardOpenDirective,
    ScrollableModule,
  ],
  declarations: [
    TriggerStyleDirective,
    TriggerStyleScrollDirective,
    TriggerOnKeyboardOpenDirective,
  ],
})
export class TriggerStyleModule {}
