import {Serializable} from 'common';

export class MoneyActivityInternal {
  id: number;

  date: number;

  amount: number;

  type: string;

  description: string;

  balance: number;

  symbol: '+' | '-';

  constructor() {}

  getNormalizedDescription() {
    if (typeof this.description === 'string') {
      return this.description.replaceAll('_', ' ').capitalize();
    } else {
      return this.description;
    }
  }
}

export class MoneyActivity extends Serializable(MoneyActivityInternal) {
  public static createFromBackend(obj: any): any {
    let m = new MoneyActivity();

    m.id = obj.id;
    m.date = obj.fecha;
    m.amount = obj.cantidad;
    m.type = obj.tipoMovimiento;
    m.description = obj.descripcion;
    m.balance = obj.saldo;
    m.symbol = obj.negative ? '-' : '+';

    return m;
  }
}
