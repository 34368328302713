import {isBackWardsKey, isDeleteKey, isModifierKey} from './key-utils';

export function createPrefixFormatter(prefix: string): (keyboardEvent: any) => void {
  return (keyboardEvent: any) => {
    const input = keyboardEvent.target;

    if (keyboardEvent.type === 'input') {
      const value =
        typeof input.value === 'string' ? input.value.toLowerCase() : input.value;

      if (!value.startsWith(prefix)) {
        keyboardEvent.target.value =
          value.length < prefix.length ? prefix : prefix + value;
      }
    } else if (keyboardEvent.type === 'keydown') {
      if (input.selectionStart < prefix.length && isModifierKey(keyboardEvent.key)) {
        if (isDeleteKey(keyboardEvent.key)) {
          input.setSelectionRange(prefix.length, input.selectionEnd);
          return true;
        } else {
          input.setSelectionRange(prefix.length, prefix.length);
          return false;
        }
      } else if (
        input.selectionStart === prefix.length &&
        isBackWardsKey(keyboardEvent)
      ) {
        return false;
      }
    }
  };
}
