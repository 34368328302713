import {Injectable, Injector} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {ModalDialogComponent, ModalDialogLayout, ModalHelperService} from 'common';
import {map, switchMap, take} from 'rxjs/operators';
import {StateSubscribeDialogComponent} from './state-subscribe-dialog/state-subscribe-dialog.component';
import {UserService} from '../user/data/user.service';
import {GeolocationViewService} from '../geolocation/geolocation-view.service';

@Injectable()
export class StateModalService {
  constructor(
    private userService: UserService,
    private geolocationViewService: GeolocationViewService,
    private modalHelperService: ModalHelperService,
    public injector: Injector,
  ) {}
  displayLocationError(
    title: string,
    message: string,
    description: string,
    isUserNotifyMeStateAvailable?: boolean,
  ): Observable<void> {
    let modalParams = {
      title: title,
      message: [message, description].filter(txt => txt),
      image: 'assets/img/geolocalizacion/map-states.png',
    };
    return this.openGeolocationNotifyModal(
      modalParams,
      isUserNotifyMeStateAvailable,
    );
  }

  private openGeolocationNotifyModal(
    options?,
    isUserNotifyMeStateAvailable?: boolean,
  ) {
    return zip([
      // get email
      this.userService.getData().pipe(
        take(1),
        map(userData => userData.email),
      ),
      // get location
      this.geolocationViewService.requestLocation(),
    ]).pipe(
      switchMap(([email, geolocation]: [string, GeolocationPosition]) => {
        if (isUserNotifyMeStateAvailable === false) {
          // When the user's current state is not available and the user is not
          // subscribed, show the dialog to get notified when it becomes available
          return this.modalHelperService.openModal(StateSubscribeDialogComponent, {
            componentParams: {
              type: 'ok_cancel',
              ...options,
              geoLocation: geolocation,
              email: email,
            },
            modalOptions: {
              modalDialogClass:
                'tl-geolocation-prompt tl-dlg-wrapper-column tl-state-subscribe-modal',
              centered: true,
              injector: this.injector,
            },
          }).closed;
        }

        return this.modalHelperService.openOkModal(ModalDialogComponent, {
          componentParams: {
            ...options,
            type: 'ok',
            accept: {key: 'global.gotIt'},
            footer: {
              key: 'payments.anyQuestionsContact',
            },
            closeOnNavigation: true,
            layout: ModalDialogLayout.VERTICAL,
            smallText: true,
            imageWidth: 96,
          },
        });
      }),
    );
  }
}
