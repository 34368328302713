import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {AbstractNgModel} from '../abstract-ngmodel';
import {ngModelProvider} from '../../model/ng-model-config';
import {Step} from './step';

@Component({
  selector: 'tl-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [ngModelProvider(StepperComponent)],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent
  extends AbstractNgModel<number>
  implements OnInit, OnChanges
{
  @HostBinding('class.tl-stepper')
  readonly hostClass = true;

  @Input() steps: Array<Step>;

  @Input() readOnly = true;

  prevDisabled = true;

  nextDisabled = false;

  disabled = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.steps = this.steps.sort();
    this.model = this.steps[0].value;
    this.updateButtons();
    this.writeValue(this.model);
    this.modelChange(this.model);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('steps')) {
      this.steps = this.steps.sort();
      this.updateButtons();
      this.writeValue(this.model);
      this.modelChange(this.model);
    }
  }

  updateButtons() {
    if (this.model <= this.steps[0].value) {
      this.prevDisabled = true;
      this.prevDisabled = true;
    } else {
      this.prevDisabled = false;
    }
    this.nextDisabled = this.model >= this.steps[this.steps.length - 1].value;
  }

  next(): void {
    if (this.nextDisabled || this.disabled) {
      return;
    }
    const currentStepIndex = this.steps.findIndex(step => step.value === this.model);
    const nextStepIndex = currentStepIndex + 1;
    const nextStep = this.steps[nextStepIndex];
    if (nextStep) {
      this.model = nextStep.value;
      this.updateButtons();
      this.writeValue(this.model);
      this.modelChange(this.model);
    }
  }

  previous(): void {
    if (this.prevDisabled || this.disabled) {
      return;
    }
    const currentStepIndex = this.steps.findIndex(step => step.value === this.model);
    const prevStepIndex = currentStepIndex - 1;
    const prevStep = this.steps[prevStepIndex];
    if (prevStep) {
      this.model = prevStep.value;
      this.updateButtons();
      this.writeValue(this.model);
      this.modelChange(this.model);
    }
  }

  // Método para obtener el texto que se muestra en el campo del stepper
  get valueText() {
    for (const item of this.steps) {
      if (item.value === this.model) {
        return item.label;
      }
    }
    return '';
  }

  writeValue(value: any): void {
    this.model = value;
    this.updateButtons();
    this.modelChange(this.model);
  }
}
