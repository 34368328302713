import {environment} from '~environments/environment';
import {Country} from 'common';

export enum IssuingEntity {
  NATIONAL = 'countryIssuingEntity',
  FOREIGN = 'foreignServiceAndBorders',
}

/**
 * Para un documentType CARTAO_CIDADAO o  BILHETE_IDENTIDADE,
 * la entidad emisora (documentIssuingEntity) ha de ser “República Portuguesa”.
 *
 * Para un documentType CARTAO_RESIDENTE, la entidad emisora (documentIssuingEntity)
 * ha de ser “Serviço de Estrangeiros e Fronteiras”.
 *
 * Para un documentType CARTAO_IDENTIFICACAO_ESTRANGERIA o PASSAPORTE, la entidad emisora
 * (documentIssuingEntity) puede ser cualquier país
 * (se comprueba simplemente si el campo documentIssuingEntity * no está vacío).
 *
 */
export function getBackendIssuingEntity(
  issuingEntity: IssuingEntity | Country | string,
): string {
  if (environment.id === 'pt') {
    if (issuingEntity instanceof Country) {
      return issuingEntity.code;
    }

    switch (issuingEntity) {
      case IssuingEntity.NATIONAL:
        return 'República Portuguesa';
      case IssuingEntity.FOREIGN:
        return 'Serviço de Estrangeiros e Fronteiras';
      default:
        return issuingEntity;
    }
  }
  return null;
}

export function issuingEntityFromBackend(
  issuingEntity: string,
): IssuingEntity | string {
  if (environment.id === 'pt') {
    switch (issuingEntity) {
      case 'República Portuguesa':
        return IssuingEntity.NATIONAL;
      case 'Serviço de Estrangeiros e Fronteiras':
        return IssuingEntity.FOREIGN;
      default:
        return issuingEntity;
    }
  }
  return null;
}
