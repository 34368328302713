import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CounterModule} from '../counter.module';

import {Counter1Component} from './counter1.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [CommonModule, CounterModule, FormsModule],
  exports: [Counter1Component],
  declarations: [Counter1Component],
})
export class Counter1Module {}
