import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {TriggerStyleService} from './trigger-style.service';

/**
 * Filters focus and blur events from different inputs and only emits keyboard
 * style events when appropiate.
 */
@Injectable({providedIn: 'root'})
export class KeyboardTriggerFilterService {
  private debouncer = new Subject<boolean>();

  private somethingFocused = 0;

  constructor(private styleService: TriggerStyleService) {
    this.debouncer
      .pipe(debounceTime(10), distinctUntilChanged())
      .subscribe(isKeyboardOpen =>
        this.styleService.triggerEvent('keyboard', isKeyboardOpen),
      );
  }

  triggerFocusEvent(focused: boolean) {
    if (focused) {
      this.somethingFocused++;
    } else {
      this.somethingFocused = Math.max(0, this.somethingFocused - 1);
    }

    this.debouncer.next(this.somethingFocused > 0);
  }

  triggerHeuristicEvent(isOpen: boolean) {
    this.debouncer.next(isOpen);
  }
}
