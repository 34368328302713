import {HttpClient} from '@angular/common/http';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';

import {PRODUCTION} from '../production/production-token';
import {APP_VERSION} from '../version/app-version-token';

import {CONSOLE, LOGSTASH_ENDPOINT} from './config-tokens';
import {ConsoleLoggerService} from './console-logger.service';
import {Logger} from './logger';
import {LogstashLoggerService} from './logstash-logger.service';

export function loggerFactory(
  appVersion,
  console,
  http,
  injector,
  isProduction,
  endpoint,
  locale,
) {
  return isProduction
    ? new LogstashLoggerService(appVersion, endpoint, http, injector, locale)
    : new ConsoleLoggerService(console);
}

export function consoleFactory() {
  return window.console;
}

@NgModule({
  providers: [
    {provide: CONSOLE, useFactory: consoleFactory},
    {
      provide: Logger,
      useFactory: loggerFactory,
      deps: [
        APP_VERSION,
        CONSOLE,
        HttpClient,
        Injector,
        PRODUCTION,
        LOGSTASH_ENDPOINT,
        LOCALE_ID,
      ],
    },
  ],
})
export class LoggerModule {}
