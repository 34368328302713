import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TriggerStyleService {
  events = new Map<string, Subject<boolean>>();

  triggerEvent(eventName: string, state: boolean) {
    if (this.events.has(eventName)) {
      this.events.get(eventName).next(state);
    }
  }

  listenEvent(eventName: string): Observable<boolean> {
    if (!this.events.has(eventName)) {
      this.events.set(eventName, new Subject<boolean>());
    }

    return this.events.get(eventName);
  }
}
