import {Injectable} from '@angular/core';

import {AbstractFiltersService} from '../abstract-filters.service';
import {DEFAULT_RESULT_FILTERS} from '../default-filter-data';

@Injectable({providedIn: 'root'})
export class ResultFiltersService extends AbstractFiltersService {
  // noinspection JSUnusedGlobalSymbols
  readonly DEFAULT_FILTERS = DEFAULT_RESULT_FILTERS;
}
