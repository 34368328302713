import {Serializable, SerializableProperty, SerializableType} from 'common';

import {CellDirection} from './cell-direction';
import {CellShape} from './cell-shape';
import {IconMetadata} from './icon-metadata';

class UITypeMetadataInternal {
  label: string;

  help: string;

  img: string;

  @SerializableProperty(IconMetadata, SerializableType.OBJECT)
  icon: IconMetadata;

  highlight: string;

  desktopHighlight: string;

  /**
   * Expands the highlight color to the whole background on mobile.
   */
  extendHighlight: boolean;

  cellBorderColor: string;

  cellSelectedColor: string;

  cellSelectedBorderColor: string;

  /**
   * The length of each row/column (based on 'layoutFlow')
   */
  stripeLength: number;

  desktopStripeLength: number;

  /**
   * Shape of the cell outline
   */
  shape: CellShape;

  /**
   * Cell layout direction
   */
  direction: CellDirection;

  desktopDirection: CellDirection;

  rawNumber: boolean;

  /**
   * Shows the type in a bottomsheet on mobile.
   */
  displayModal: boolean;

  /**
   * Translation key for the valid bet bottom sheet title
   */
  modalValidTitle: string;

  /**
   * Forces the control for this type to be rendered inside the first panel.
   */
  displayAsLocal: boolean;
}

export class UITypeMetadata extends Serializable(UITypeMetadataInternal) {
  static createFromBackend(obj: any) {
    let uiMD = new UITypeMetadata();

    uiMD.label = obj.playLabel;
    uiMD.help = obj.playDescHelp;
    uiMD.img = obj.img;
    uiMD.highlight = obj.highlightColor || '#f5f5f5';
    uiMD.desktopHighlight = obj.desktopHighlightColor || uiMD.highlight;
    uiMD.cellBorderColor = obj.cellBorderColor;
    uiMD.cellSelectedColor = obj.cellSelectedColor;
    uiMD.cellSelectedBorderColor = obj.cellSelectedBorderColor;
    uiMD.stripeLength = obj.numbersPerColumn || obj.length || 10;
    uiMD.desktopStripeLength = obj.desktopLength || uiMD.stripeLength;
    uiMD.shape = obj.shape || 'square';
    uiMD.direction = obj.direction || 'columns';
    uiMD.desktopDirection = obj.desktopDirection || uiMD.direction;
    uiMD.rawNumber = obj.rawNumber;
    uiMD.displayAsLocal = obj.displayAsLocal;
    uiMD.displayModal = !!obj.modal;
    uiMD.extendHighlight = obj.fullscreen;
    uiMD.modalValidTitle = obj.validTitle;

    if (obj.icon) {
      uiMD.icon = IconMetadata.createFromBackend(obj.icon);
    } else if (obj.resultIconUrl || obj.resultIconUrlSVG) {
      uiMD.icon = new IconMetadata();
      uiMD.icon.type = 'image';
      uiMD.icon.url = obj.resultIconUrlSVG || obj.resultIconUrl;
    }

    return uiMD;
  }
}
