import {Pipe, PipeTransform} from '@angular/core';

import {escapeRegExp} from './core/string-utils';

@Pipe({name: 'filterArray'})
export class FilterArrayPipe<T> implements PipeTransform {
  transform(
    data: Array<T>,
    search: string,
    test: (value: T, pattern: RegExp) => boolean,
  ): any {
    if (search && search.length > 0) {
      const pattern = new RegExp(escapeRegExp(search), 'i');
      return data.filter(value => test(value, pattern));
    }

    return data;
  }
}
