import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {merge, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import * as deepmerge from 'deepmerge';
import {isBoolean} from '../../util/core/boolean';

@Directive({selector: '[tlFormMessages]'})
export class FormMessagesDirective implements OnInit, OnDestroy, OnChanges {
  @Input('tlFormMessages')
  messages: FormControl;

  @Input('tlFormMessagesForm')
  form: FormGroup;

  @Input('tlFormMessagesFieldName')
  fieldName: string;

  @Input()
  submitted: boolean;

  @Output()
  update: EventEmitter<void> = new EventEmitter<void>();

  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('submitted')) {
      this.update.emit();
    }
  }

  ngOnInit() {
    if (!this.messages) {
      throw new Error('Please provide form control on [tlFormMessages]');
    }

    this.subscription =
      // eslint-disable-next-line import/no-deprecated
      merge(this.messages.valueChanges, this.messages.statusChanges)
        .pipe(debounceTime(2))
        .subscribe(() => {
          this.update.emit();
          this.cdr.markForCheck();
        });
  }

  has(name: string): boolean {
    if (!(isBoolean(this.submitted) ? this.submitted : this.messages.dirty)) {
      return false;
    }

    let formError = this.messages.errors;

    if (!!this.form && !!this.form.errors) {
      const globalFieldError = this.form.errors[this.fieldName];
      formError = deepmerge(formError || {}, globalFieldError || {});
    }

    return formError ? this.getPropertyIfAny(formError, name) : false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getPropertyIfAny(obj: unknown, key: string): any {
    const chunks = key.split('.');

    let value = obj;
    for (let chunk of chunks) {
      if (value.hasOwnProperty(chunk)) {
        value = value[chunk];
      } else {
        return false;
      }
    }

    return value;
  }
}
