import {DOCUMENT} from '@angular/common';
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';

import {DeviceService} from '../device/device.service';
import {ResponsiveService} from '../responsive/responsive.service';

import {ScrollDirection, ScrollEvent} from './scroll-event';
import {ScrollRailComponent} from './scroll-rail/scroll-rail.component';
import {DeviceOs} from '../device/deviceOs';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({template: 'no-ui'})
export abstract class ScrollableBaseComponent
  implements AfterContentInit, OnDestroy
{
  @ViewChild(ScrollRailComponent)
  rail: ScrollRailComponent;

  /**
   * This event emits outside angular to prevent triggering change detection
   */
  @Output()
  scrollChangeOutsideAngular = new EventEmitter<ScrollEvent>();

  protected listeners: Array<Subscription | (() => void)> = [];

  protected lastScrollTop: number;

  private responsiveSubscription: Subscription;

  constructor(
    protected deviceService: DeviceService,
    @Inject(DOCUMENT) protected document: Document,
    protected elementRef: ElementRef,
    protected ngZone: NgZone,
    protected renderer: Renderer2,
    protected responsiveService: ResponsiveService,
  ) {}

  abstract hasScroll(): boolean;

  abstract setScrollPosition(scrollTop: number): void;

  abstract getScrollTop(): number;

  abstract getScrollPercent(scrollDirection: ScrollDirection): number;

  protected abstract onRailDrag(scrollTop: number): void;

  protected abstract updateRail(): void;

  ngAfterContentInit() {
    this.ngZone.runOutsideAngular(() =>
      Promise.resolve().then(() => {
        this.responsiveSubscription = this.responsiveService.breakpointChange
          .pipe(
            map(() => this.responsiveService.isDesktop()),
            startWith(this.responsiveService.isDesktop()),
            distinctUntilChanged(),
          )
          .subscribe(() => {
            if (this.shouldAttachRail()) {
              this.attachEvents();
            } else {
              this.detachEvents();
            }
          });
      }),
    );
  }

  ngOnDestroy() {
    this.detachEvents();

    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
  }

  attachEvents(): void {
    this.listeners.push(
      this.renderer.listen(this.elementRef.nativeElement, 'mouseenter', mouseenter =>
        this.onMouseEnter(mouseenter),
      ),
    );

    this.listeners.push(
      this.renderer.listen(this.elementRef.nativeElement, 'mouseleave', mouseleave =>
        this.onMouseLeave(mouseleave),
      ),
    );
  }

  detachEvents(): void {
    this.listeners.forEach(listener => {
      if (listener instanceof Subscription) {
        listener.unsubscribe();
      } else {
        listener();
      }
    });

    this.listeners = [];
  }

  getElementRef(): ElementRef {
    return this.elementRef;
  }

  getContainerHeight(): number {
    return this.elementRef.nativeElement.offsetHeight;
  }

  protected onMouseEnter(event: MouseEvent): void {
    if (this.hasScroll()) {
      this.rail.setVisibility(true);
    }
  }

  protected onMouseLeave(event: MouseEvent): void {
    if (this.hasScroll()) {
      this.rail.setVisibility(false);
    }
  }

  protected shouldAttachRail(): boolean {
    return (
      this.responsiveService.isDesktop() &&
      this.deviceService.getOS() !== DeviceOs.MACOS
    );
  }
}
