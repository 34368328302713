import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {LocalStorage} from 'common';

import {environment} from '~environments/environment';

import {BackendAdapterDao} from './backend-adapter.dao';
import {BackendDao} from './backend.dao';

// TODO · ADAPTER · remove
function backendDaoFactory(http: HttpClient, localStorage: LocalStorage) {
  if (environment.locale.locale === 'es-ES') {
    return new BackendAdapterDao(http, localStorage);
  } else {
    return new BackendDao(http, localStorage);
  }
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    // TODO · ADAPTER · remove
    {
      provide: BackendDao,
      useFactory: backendDaoFactory,
      deps: [HttpClient, LocalStorage],
    },
  ],
})
export class BackendModule {}
