import {Serializable} from 'common';

import {environment} from '../../../environments/environment';

class LotteryBoothInternal {
  id: string;

  name: string;

  email: string;

  picture: string;

  phone: string;

  address: string;

  city: string;

  state: string;

  zipCode: string;

  number: number;

  latitude: string;

  longitude: string;

  enabled: boolean;

  map: string;

  mapsLink: string;
}

export class LotteryBooth extends Serializable(LotteryBoothInternal) {
  static createFromBackend(obj: any): LotteryBooth {
    let booth = new LotteryBooth();

    booth.id = obj.id;
    booth.name = obj.commercial ? obj.commercial : obj.nombre;
    booth.email = obj.mail;
    booth.picture = obj.urlFoto;
    booth.phone = obj.telefono;
    booth.address = obj.direccion;
    booth.city = obj.poblacion;
    booth.state = obj.provincia;
    booth.zipCode = obj.codigoPostal;
    booth.number = obj.numero;
    booth.latitude = obj.latitud;
    booth.longitude = obj.longitud;
    booth.enabled = obj.enabled;
    booth.map = obj.mapImage;

    if (booth.latitude) {
      booth.mapsLink =
        environment.google.maps.queryLink +
        encodeURIComponent(`${booth.latitude},${booth.longitude}`);
    } else {
      booth.mapsLink =
        environment.google.maps.queryLink +
        encodeURIComponent(
          `${booth.name},${booth.address},${booth.city},${booth.state}`,
        );
    }

    return booth;
  }
}
