import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logger} from 'common';
import {map, Observable} from 'rxjs';

import {AbstractDao} from '../../common/model/abstract.dao';

import {ExclusionTypeEnum} from './exclusion-type.enum';
import {Question} from './question';
import {ResponseDateRetryQuestionnarie} from './response-date-retry-questionnarie';

export interface SpendLimits {
  spendingLimitWeekly: number;
  balanceLoadLimitDaily: number;
  balanceLoadLimitWeekly: number;
  balanceLoadLimitMonthly: number;
  balanceLoadMaxLimitDaily: number;
  balanceLoadMaxLimitWeekly: number;
  balanceLoadMaxLimitMonthly: number;
  balanceLoadMaxLimitDailyByLaw: number;
  balanceLoadMaxLimitWeeklyByLaw: number;
  balanceLoadMaxLimitMonthlyByLaw: number;
  hasDefaultMaxLimits: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ExclusionDao extends AbstractDao {
  constructor(private http: HttpClient, private logger: Logger) {
    super();
  }

  getLimits(): Observable<SpendLimits> {
    return this.http
      .get(this.baseUrl + '/users/limits')
      .pipe(map(res => res as SpendLimits));
  }

  saveLimits(payload: Record<string, any>): Observable<any> {
    return this.http.put(this.baseUrl + '/users/limits', payload);
  }

  requestToken(payload: Record<string, any>): Observable<any> {
    const code: ExclusionTypeEnum = !!payload?.automaticReactivation
      ? ExclusionTypeEnum.INDEFINITELY
      : payload?.durationInMonths === 1
      ? ExclusionTypeEnum.ONE_MONTH
      : payload?.durationInMonths === 3
      ? ExclusionTypeEnum.THREE_MONTHS
      : payload?.durationInMonths === 6
      ? ExclusionTypeEnum.SIX_MONTHS
      : undefined;

    if (!code) {
      this.logger.error('DurationInMonths in exclusion does not recognized', null, {
        payload,
      });
    }
    return this.http.post(
      `${this.baseUrl}/users/selfexclusion/generatetoken?selfexclusiontypecode=${code}`,
      {},
    );
  }

  verifyExclusion(token: string): Observable<any> {
    return this.http.put(this.baseUrl + '/users/selfexclusion/confirm', token);
  }

  getLimitsMaxRemoveQuestionnaire(): Observable<Array<Question>> {
    return this.http.get(`${this.baseUrl}/users/autocontroltest/questions`).pipe(
      map((response: any) => {
        return response.object.map(obj => Question.createFromBackend(obj));
      }),
    );
  }

  saveLimitsMaxRemoveQuestionnaire(questions: Array<Question>): Observable<any> {
    const data = questions.map(value => value.toBackend());
    return this.http.post(`${this.baseUrl}/users/autocontroltest/testResult`, data);
  }

  getLimitsMaxDateRetryQuestionnaire(): Observable<ResponseDateRetryQuestionnarie> {
    return this.http.get<ResponseDateRetryQuestionnarie>(
      `${this.baseUrl}/users/autocontroltest/nextAttemptTimestamp`,
    );
  }
}
