import {Inject, Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'initWidth'})
export class BankAccountInitWidthPipe implements PipeTransform {
  constructor(@Inject('window') private window: Window) {}

  transform(multiplier: any, element: HTMLInputElement): any {
    return (
      parseInt(
        this.window
          .getComputedStyle(element)
          .getPropertyValue('padding-left')
          .replace('px', ''),
        10,
      ) * multiplier
    );
  }
}
