export {};

declare global {
  interface String {
    replaceAll(search, replacement);

    replaceAt(index: number, char): string;

    capitalize(keepUpperCase?: boolean): string;

    splice(start: number, count: number, str: string);

    toBoolean(): boolean;

    matchAll(regexp: RegExp): IterableIterator<RegExpMatchArray>;
  }
}

String.prototype.replaceAt = function (index: number, char): string {
  return this.substr(0, index) + char + this.substr(index + char.length);
};

String.prototype.capitalize = function (keepUpperCase = false) {
  return (
    this.charAt(0).toUpperCase() +
    (keepUpperCase ? this.substring(1) : this.substring(1).toLowerCase())
  );
};

String.prototype.replaceAll = function (search, replacement) {
  let target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

String.prototype.splice = function (start, count, str) {
  return this.slice(0, start) + str + this.slice(start + Math.abs(count));
};

String.prototype.toBoolean = function () {
  return !(this.toString() === 'false' || this.toString() === '');
};

String.prototype.matchAll = function (
  this: string,
  re: RegExp,
): IterableIterator<RegExpMatchArray> {
  re = new RegExp(re, 'g');
  let match;
  let matches: RegExpMatchArray[] = [];

  while ((match = re.exec(this))) matches.push(match);

  return matches[Symbol.iterator]();
};
