import {Directive, HostBinding} from '@angular/core';
import {VirtualKeyboardApiService} from './virtual-keyboard-api.service';

@Directive({
  selector: '[tlVirtualKeyboardSupported]',
})
export class VirtualKeyboardSupportedDirective {
  @HostBinding('class.tl-virtual-keyboard-supported')
  hasSupport = this.keyboardApi.isSupported;

  constructor(private keyboardApi: VirtualKeyboardApiService) {}
}
