import {RequiredBetRuleTypeMetadata} from './required-bet-rule-type-metadata';

/**
 * Defines a rule for selecting requiredTrue true values out of an array of
 * required elements preserving the indexes of each value.
 */
export class RequiredTrueBetRuleTypeMetadata extends RequiredBetRuleTypeMetadata {
  /**
   * Number of picks to be made. `Required` applies to amount of posible picks
   * and `requiredTrue` applies to the amount that must be selected.
   */
  requiredTrue: number;

  static createFromBackend(obj: any) {
    let requiredTrueBRTMD = new RequiredTrueBetRuleTypeMetadata();

    requiredTrueBRTMD.required = obj.needed;
    requiredTrueBRTMD.requiredTrue = obj.neededTrue;
    requiredTrueBRTMD.optional = !!obj.optional;

    return requiredTrueBRTMD;
  }

  // Serializable inherited from parent.
}
