import {Serializable} from 'common';

import {UserExclusionType} from './user-exclusion-type.enum';

class UserExclusionInternal {
  type: UserExclusionType;

  durationInMonths: number;

  reactivationDate: number;

  automaticReactivation: boolean;
}

export class UserExclusion extends Serializable(UserExclusionInternal) {
  static createFromBackend(obj: any): UserExclusion {
    let userExclusion = new UserExclusion();

    userExclusion.type = UserExclusionType.SELF;
    userExclusion.durationInMonths = obj.durationInMonths;
    userExclusion.reactivationDate = obj.reactivationDate;
    userExclusion.automaticReactivation = obj.automaticReactivation;

    return userExclusion;
  }
}
