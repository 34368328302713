import {NgModule} from '@angular/core';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

import {TooltipFormMessageDirective} from './tooltip-form-message.directive';
import {TooltipFormDirective} from './tooltip-form.directive';

@NgModule({
  imports: [NgbTooltipModule],
  exports: [TooltipFormDirective, TooltipFormMessageDirective],
  declarations: [TooltipFormDirective, TooltipFormMessageDirective],
})
export class TooltipFormModule {}
