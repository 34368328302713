import {BidirectionalMap} from 'common';
import {environment} from '~environments/environment';

const routeMap = environment.locale.routes;
export const PLATFORM_ROUTES_MAPPING = new BidirectionalMap<string>([
  [`/${routeMap.booths}`, `/m/${routeMap.booths}`],
  [`/${routeMap.contactUs}`, `/m/${routeMap.contactUs}`],
  [`/${routeMap.notifications}`, `/m/${routeMap.notifications}`],
  [`/${routeMap.legal}`, `/m/${routeMap.legal}`],
  [`/${routeMap.faq}`, `/m/${routeMap.faq}`],
  [`/${routeMap.promos}`, `/m/${routeMap.promos}`],
  [
    `/${routeMap.desktop.balance}/${routeMap.desktop.money.load}`,
    `/m/${routeMap.mobile.load}`,
  ],
  [
    `/${routeMap.desktop.balance}/${routeMap.desktop.money.unload}`,
    `/m/${routeMap.mobile.unload}`,
  ],
  [
    `/${routeMap.desktop.profile}`,
    `/m/${routeMap.mobile.user}/${routeMap.mobile.main.profile}`,
  ],
  [`/${routeMap.register}`, `/m/${routeMap.register}`],
  [
    `/${routeMap.register}/${routeMap.desktop.user.data}`,
    `/m/${routeMap.mobile.user}/${routeMap.mobile.main.profile}`,
  ],
  [
    `/${routeMap.register}/${routeMap.desktop.user.phone}`,
    `/m/${routeMap.mobile.userPhone}`,
  ],
  [
    `/${routeMap.register}/${routeMap.desktop.user.sms}`,
    `/m/${routeMap.mobile.userSms}`,
  ],
  [`/m/${routeMap.mobile.sponsorFriends}`, `/${routeMap.sponsor}`],
  [
    `/${routeMap.sponsor}`,
    `/m/${routeMap.mobile.user}/${routeMap.mobile.tuloteroSlides.sponsor}`,
  ],
  [`/`, `/m/${routeMap.mobile.login}`],
  [`/`, `/m`],
]);
