import {NgModule} from '@angular/core';

import {UploadFileDirective} from './upload-file.directive';

@NgModule({
  imports: [],
  exports: [UploadFileDirective],
  declarations: [UploadFileDirective],
  providers: [],
})
export class UploadFileModule {}
