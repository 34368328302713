import {Injectable} from '@angular/core';

import {StorableContext} from './storable-context';
import {StorableContextEnum} from './storable-context.enum';

@Injectable({providedIn: 'root'})
export class StorableContextService {
  private context = new Map<StorableContextEnum, StorableContext<any>>();

  get(context: StorableContextEnum): StorableContext<any> {
    return this.context.get(context);
  }

  put(context: StorableContextEnum, data: StorableContext<any>): void {
    this.context.set(context, data);
  }

  has(context: StorableContextEnum): boolean {
    return this.context.has(context);
  }

  remove(context: StorableContextEnum): void {
    this.context.delete(context);
  }
}
