import {EMPTY, OperatorFunction, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

/**
 * Catches errors that match the type and completes the observable, lets
 * through any other error.
 */
export function ignoreError<T, R>(errorType: any): OperatorFunction<T, T | R> {
  return catchError((error: any) => {
    if (error instanceof errorType) {
      return EMPTY;
    } else {
      return throwError(() => error);
    }
  });
}
