import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleButtonComponent {
  @Input()
  value: string;

  @Input()
  @HostBinding('class.disabled')
  loading = false;
}
