import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ENVIRONMENT} from '../../environment/environment-token';
import {AppIosMarket} from '../data/appIosMarket';

@Injectable({
  providedIn: 'root',
})
export class MarketIosService {
  readonly marketIosRatingsUrl = `https://itunes.apple.com/es/lookup?id=`;

  constructor(
    protected http: HttpClient,
    @Inject(ENVIRONMENT) environment: Record<string, any>,
  ) {
    this.marketIosRatingsUrl += environment.markets.ios.id;
  }

  getRatings(): Observable<AppIosMarket> {
    return this.http
      .get(this.marketIosRatingsUrl)
      .pipe(map((data: any) => (data?.results ? data.results[0] : undefined)));
  }
}
