import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';

import {SwipeDirection, SwipeManager} from '../custom-events/swipe-manager';

/**
 * Enables swiping for this iframe, can listen one or multiple swipe events.
 *
 * Emits every swipe event on the output with an argument for direction.
 * Events are emmited inside the angular zone to ensure hooks work as expected.
 */
@Directive({selector: 'iframe[tlIframeSwipe]'})
export class IframeSwipeDirective implements OnChanges, OnDestroy {
  @Output()
  iframeSwipe = new EventEmitter<SwipeDirection>();

  swipeManager: Map<string, SwipeManager>;

  private eventNames: Array<SwipeDirection>;

  /**
   * Name of the event or events to listen to.
   * Can be a string if only one event is to be listened or an array for
   * several events.
   */
  @Input('tlIframeSwipe')
  set events(names: Array<SwipeDirection> | SwipeDirection) {
    this.eventNames = Array.isArray(names) ? names : [names];
  }

  constructor(private element: ElementRef, private zone: NgZone) {
    this.swipeManager = new Map<string, SwipeManager>();
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('events')) {
      this.load();
    }
  }

  @HostListener('load')
  private load() {
    let frameWindow =
      this.element.nativeElement.window || this.element.nativeElement.contentWindow;
    let body = frameWindow.document.body;

    this.destroy();

    if (!this.eventNames) {
      return;
    }

    if (body) {
      this.eventNames.forEach(name => {
        if (this.swipeManager.has(name)) {
          this.swipeManager.get(name).destroy();
        }

        this.swipeManager.set(
          name,
          new SwipeManager(
            frameWindow,
            name,
            () => this.emit(name),
            this.zone,
            frameWindow,
          ),
        );
      });
    }
  }

  private emit(name: SwipeDirection): void {
    this.zone.run(() => this.iframeSwipe.emit(name));
  }

  private destroy() {
    if (this.swipeManager) {
      this.swipeManager.forEach(manager => manager.destroy());
      this.swipeManager.clear();
    }
  }
}
