<div class="tl-semi-modal__content">
  <div class="tl-semi-modal__knob" (click)="onHeaderClick()"></div>

  <div
    class="tl-semi-modal__header"
    [class.tl-semi-modal__header--bordered]="title || type === 'buttons'"
    (click)="onHeaderClick()">
    <div
      *ngIf="type === 'buttons'"
      class="tl-semi-modal__button"
      (click)="onClose()">
      {{ cancelKey || 'global.cancelButton' | i18n }}
    </div>

    <ng-template
      *ngIf="title | isTemplate; else basicTitle"
      [ngTemplateOutlet]="title">
    </ng-template>
    <ng-template #basicTitle>
      <div class="tl-semi-modal__title">{{ title }}</div>
    </ng-template>

    <div
      *ngIf="type === 'buttons'"
      class="tl-semi-modal__button"
      (click)="!loading && accept.emit()">
      <span [style.visibility]="loading ? 'hidden' : 'visible'">
        {{ acceptKey || 'global.acceptButton' | i18n }}
      </span>
      <i
        *ngIf="loading"
        class="icon-spin6-icon tl-icon-spin tl-semi-modal__loader"></i>
    </div>
  </div>

  <ng-content></ng-content>

  <div
    *ngIf="type === 'button'"
    class="tl-semi-modal__footer mobile-section-shadow-top">
    <button
      type="button"
      class="btn btn-type-4 tl-semi-modal__button"
      (click)="accept.emit()">
      {{ buttonText }}
    </button>
  </div>
</div>
