import {environment} from '../environments/environment';
import {GameMetadataService} from './games/game-metadata/data/game-metadata.service';
import {ResultsListViewProcessDataService} from './games/results/widget/result-list/results-list-view-process-data.service';
import {ResultsListViewProcessStatesService} from './games/results/widget/result-list/results-list-view-process-states.service';
import {UpcomingGamesGroupsProcessDataService} from './games/upcoming/data/upcoming-games-groups-process-data.service';
import {UpcomingGamesGroupsProcessPlayableService} from './games/upcoming/data/upcoming-games-groups-process-playable.service';
import {UpcomingGamesProcessDataService} from './games/upcoming/data/upcoming-games-process-data.service';
import {UpcomingGamesProcessStatesService} from './games/upcoming/data/upcoming-games-process-states.service';
import {BannerProcessDataService} from './banner/data/banner-process-data.service';
import {BannerProcessStatesService} from './banner/data/banner-process-states.service';
import {UpcomingGamesService} from './games/upcoming/data/upcoming-games.service';
import {ResultFiltersViewProcessDataService} from './games/results/widget/result-filters-view-process-data.service';
import {FilterViewProcessStatesService} from './states/filter-view-process-states.service';
import {UpdateStateAppListenerService} from './states/data/update-state-app-listener.service';
import {StateAppDataService} from './states/data/state-app-data.service';
import {NotificationsProcessDataService} from './preferences/data/notifications-process-data.service';
import {NotificationsProcessStatesService} from './preferences/data/notifications-process-states.service';
import {StateNotificationsService} from './states/data/state-notifications.service';
import {FiltersViewProcessDataService} from './games/game-filter/data/filters-view-process-data.service';
import {GamesMenuWidgetProcessDataService} from './games/menu/games-menu-widget-process-data.service';
import {GamesMenuWidgetProcessStatesService} from './games/menu/games-menu-widget-process-states.service';
import {StateAppService} from './states/state-app.service';
import {TranslationsService} from 'common';
import {TranslationsUsService} from './i18n/translations-us.service';
import {TicketGameUserFiltersDataService} from './games/tickets/data/ticket-game-user-filters-data.service';
import {TicketGameUserFiltersService} from './games/tickets/data/ticket-game-user-filters.service';
import {NoopTicketGameUserFiltersService} from './games/tickets/data/noop-ticket-game-user-filters.service';
import {StateModalService} from './states/state-modal.service';

const PROVIDERS = [];

if (environment.geolocation.enableAppState) {
  PROVIDERS.push([
    UpdateStateAppListenerService,
    {
      provide: UpcomingGamesProcessDataService,
      useClass: UpcomingGamesProcessStatesService,
      deps: [GameMetadataService, StateAppDataService],
      multi: true,
    },
    {
      provide: GamesMenuWidgetProcessDataService,
      useClass: GamesMenuWidgetProcessStatesService,
      deps: [GameMetadataService, StateAppDataService],
      multi: true,
    },
    {
      provide: ResultsListViewProcessDataService,
      useClass: ResultsListViewProcessStatesService,
      deps: [GameMetadataService, StateAppDataService],
      multi: true,
    },
    {
      provide: BannerProcessDataService,
      useClass: BannerProcessStatesService,
      deps: [StateAppDataService],
      multi: true,
    },
    {
      provide: FiltersViewProcessDataService,
      useClass: FilterViewProcessStatesService,
      deps: [GameMetadataService, StateAppDataService, UpcomingGamesService],
      multi: true,
    },
    {
      provide: ResultFiltersViewProcessDataService,
      useClass: FilterViewProcessStatesService,
      deps: [GameMetadataService, StateAppDataService, UpcomingGamesService],
      multi: true,
    },
    StateNotificationsService,
    {
      provide: NotificationsProcessDataService,
      useClass: NotificationsProcessStatesService,
      deps: [StateNotificationsService],
      multi: true,
    },
    StateAppService,
    {
      provide: TranslationsService,
      useClass: TranslationsUsService,
    },
    StateModalService,
  ]);
}

if (environment.features.groups.filterGames) {
  PROVIDERS.push([
    {
      provide: UpcomingGamesGroupsProcessDataService,
      useClass: UpcomingGamesGroupsProcessPlayableService,
      deps: [GameMetadataService],
      multi: true,
    },
  ]);
}

if (environment.features.tickets.filterGames) {
  PROVIDERS.push([TicketGameUserFiltersDataService]);
} else {
  PROVIDERS.push([
    {
      provide: TicketGameUserFiltersService,
      useClass: NoopTicketGameUserFiltersService,
    },
  ]);
}

export function getDynamicProviders() {
  return PROVIDERS;
}
