import {Inject, Injectable} from '@angular/core';
import {AbstractObservableDataService, ENVIRONMENT, LocalStorage} from 'common';
import {Filter} from '../../game-filter/data/filter';

@Injectable()
export class TicketGameUserFiltersDataService extends AbstractObservableDataService<
  Array<Filter>
> {
  constructor(
    private localStorage: LocalStorage,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {
    super();
    const savedFilters = this.localStorage.getItem(
      this.environment.localStorageKeys.ticketsGameFilters,
    );
    if (savedFilters) {
      this.setData(
        (JSON.parse(savedFilters) as Array<Filter>).map(filter =>
          Filter.fromJSON(filter),
        ),
      );
    } else {
      this.setData([]);
    }
  }

  setData(data: Array<Filter>) {
    super.setData(data || []);
    this.localStorage.setItem(
      this.environment.localStorageKeys.ticketsGameFilters,
      JSON.stringify(data || []),
    );
  }
}
