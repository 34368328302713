import {EMPTY, Observable, of} from 'rxjs';
import {catchError, expand, filter, map} from 'rxjs/operators';

import {PreviousRouteService} from '../../router/previous-route.service';
import {PageOpener} from '../page-controller';

import {FlowStep} from './model';

export class AbstractFlowLauncher {
  protected steps: Array<FlowStep> = [];

  protected currentStep = 0;

  constructor(
    protected pageOpener: PageOpener,
    protected previousRoute: PreviousRouteService,
  ) {}

  protected runFlow(initData?: any): Observable<any> {
    let finishFlow = false;
    return of(initData).pipe(
      expand(stepData => {
        if (finishFlow) {
          return EMPTY;
        }

        const step = this.steps[this.currentStep];
        step.startParams = stepData;

        if (step.inStepHook) {
          stepData = step.inStepHook(stepData);
        }

        return this.pageOpener.open(step.url, step.titleKey, stepData).pipe(
          map(data => {
            step.returnParams = data;

            if (step.postStepHook) {
              step.postStepHook();
            }

            if (this.currentStep === this.steps.length - 1) {
              finishFlow = true;
            } else {
              this.currentStep++;
            }

            return step.nextStepHook ? step.nextStepHook(data) : data;
          }),

          catchError(e => {
            // e instanceof Error doesn't work, somethimes is not an
            // error.
            if (
              e &&
              typeof e === 'object' &&
              e.hasOwnProperty('stack') &&
              e.hasOwnProperty('message')
            ) {
              throw e;
            }

            if (step.backStepHook) {
              step.backStepHook(step.startParams);
            }

            if (e?.abortFlow === true) {
              this.currentStep = 0;
            }

            if (this.currentStep === 0) {
              finishFlow = true;
              this.previousRoute.back();
              return EMPTY;
            }

            this.currentStep--;
            return of(this.steps[this.currentStep].startParams);
          }),
        );
      }),

      filter(() => finishFlow),
    );
  }
}
