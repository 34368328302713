import {environment} from '~environments/environment';
import {PropagateParamsUriMapResolver, URI_MAP_CONFIG, UriConfig} from 'common';
import {NgModule} from '@angular/core';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    'request-medium-prize': [
      {
        originUrl:
          // eslint-disable-next-line max-len
          environment.internalUrls.requestMediumPrize,
        // originUrl: 'tulotero://request-medium-prize/:ticketId',
        destinationPlatformUrl: {
          // eslint-disable-next-line max-len
          desktop: `/${routeMap.desktop.money.requestGreatPrize}/:ticketId?${routeMap.desktop.money.requestGreatPrizeBigPrizeParam}=:totalPending&${routeMap.desktop.money.requestGreatPrizeMediumPrizeByIntegratorParam}=:totalPendingByIntegrator`,
          // eslint-disable-next-line max-len
          mobile: `/m/${routeMap.mobile.requestGreatPrize}/:ticketId?${routeMap.mobile.requestGreatPrizeBigPrizeParam}=:totalPending&${routeMap.mobile.requestGreatPrizeMediumPrizeByIntegratorParam}=:totalPendingByIntegrator`,
        },
        pattern:
          // eslint-disable-next-line max-len
          /tulotero:\/\/request-medium-prize\/boletoId:([0-9]+)\/totalPending:([0-9]+,[0-9]{2})\/totalPendingByIntegrator:([0-9]+,[0-9]{2})/,
        resolver: PropagateParamsUriMapResolver,
      },
    ],
  },
];

@NgModule({
  providers: [
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class RequestGreatPrizeUriMappingModule {}
