import {Injectable} from '@angular/core';
import {Logger, PropagateParamsUriMapResolver, ResponsiveService} from 'common';

import {Slugger} from '../util/slugger';

@Injectable()
export class PropagateGameParamsUriMapResolver extends PropagateParamsUriMapResolver {
  constructor(
    protected responsiveService: ResponsiveService,
    protected logger: Logger,
  ) {
    super(responsiveService, logger);
  }

  protected replaceParams(
    paramNames: RegExpMatchArray,
    paramValues: RegExpExecArray,
    destinationUrl: string,
  ): string {
    paramValues
      .slice(1)
      .forEach(
        (match, index) =>
          (destinationUrl = destinationUrl.replace(
            paramNames[index],
            Slugger.toUrl(match),
          )),
      );

    return destinationUrl;
  }
}
