import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

import {PropagateGameParamsUriMapResolver} from '../uri/propagate-game-params-uri-map-resolver';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    boletos: [
      {
        originUrl: environment.internalUrls.ticket,
        destinationPlatformUrl: {
          desktop: `/${routeMap.desktop.ticket}/:boletoId`,
          mobile: `/m/${routeMap.mobile.main.tickets}/${routeMap.mobile.main.ticket}/:boletoId`,
        },
        pattern: /tulotero:\/\/boletos\/\?boleto_id=([0-9]+)/,
        resolver: PropagateGameParamsUriMapResolver,
      },
    ],
  },
];

@NgModule({
  providers: [
    PropagateGameParamsUriMapResolver,
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class TicketUriMappingModule {}
