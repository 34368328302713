import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value) return value;

    const words = value.toLowerCase().split(' ');
    const word0 = `${words[0].charAt(0).toUpperCase()}${words[0].slice(1)}`;
    return [word0, ...words.slice(1)].join(' ');
  }
}
