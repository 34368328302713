import {ChangeDetectorRef, Directive, HostBinding, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BlurService} from './blur.service';

@Directive({
  selector: '[tlBlur]',
})
export class BlurDirective implements OnInit {
  @HostBinding('class.blur')
  blur: boolean;

  // @Destroyable()
  onDestroy = new Subject<void>();

  constructor(private blurService: BlurService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.blurService
      .blurEmmitter()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(blur => {
        this.blur = blur;
        this.cdr.markForCheck();
      });
  }
}
