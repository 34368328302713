import {CountryCode, parsePhoneNumber} from 'libphonenumber-js/max';
import {Country} from '../../countries/country';

export function fixPhoneNumber(number: string, countryCode: string): string {
  const code = countryCode.toUpperCase() as CountryCode;
  let currentNumber;
  let phoneNumber;

  const fixNumber = number
    // Fix to us Tulotero number
    .replace('-TLOTERO', '');

  // Original number
  try {
    currentNumber = fixNumber;
    phoneNumber = parsePhoneNumber(currentNumber, code);
    if (phoneNumber.isValid()) {
      return currentNumber;
    }

    // Number with prefix but without plus

    currentNumber = `+${fixNumber}`;
    phoneNumber = parsePhoneNumber(currentNumber, code);
    if (phoneNumber.isValid()) {
      return currentNumber;
    }
  } catch (e) {}

  return fixNumber;
}

export function getCountryPrefix(
  userPreffix: string,
  userPhone: string,
  countries: Country[],
  defaultPhoneCountry: Country,
): Country {
  if (!userPhone) {
    return defaultPhoneCountry;
  }
  const countryCode = defaultPhoneCountry.code.toUpperCase() as CountryCode;
  const phoneFixed = fixPhoneNumber(`${userPreffix}${userPhone}`, countryCode);
  const phoneNumber = parsePhoneNumber(phoneFixed, countryCode);
  const countriesMatch = countries.filter(
    country => country.code.toUpperCase() === phoneNumber.country.toUpperCase(),
  );
  return countriesMatch[0] ? countriesMatch[0] : defaultPhoneCountry;
}
