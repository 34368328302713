import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Injector, LOCALE_ID} from '@angular/core';
import {Router} from '@angular/router';

import {getBrowserInfoString} from '../device/ua-parser/ua-parser';
import {APP_VERSION} from '../version/app-version-token';

import {LOGSTASH_ENDPOINT} from './config-tokens';
import {Logger, LogLevel} from './logger';

@Injectable()
export class LogstashLoggerService implements Logger {
  constructor(
    @Inject(APP_VERSION) private appVersion: string,
    @Inject(LOGSTASH_ENDPOINT) private endpoint: string,
    private http: HttpClient,
    private injector: Injector,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  info(message: string, context?: Record<string, unknown>) {
    this.log(message, context, LogLevel.INFO);
  }

  warn(message: string, context?: Record<string, unknown>) {
    this.log(message, context, LogLevel.WARNING);
  }

  error(message: string, stacktrace?: string, context?: Record<string, unknown>) {
    this.log(message, context, LogLevel.ERROR, stacktrace);
  }

  debug(message: string, context?: Record<string, unknown>) {
    this.log(message, context, LogLevel.DEBUG);
  }

  protected log(
    message: string,
    context: Record<string, unknown>,
    level: string,
    stacktrace?: string,
  ) {
    const body = {};
    body['device'] = getBrowserInfoString();
    body['appVersion'] = this.appVersion;
    body['locale'] = this.locale;
    body['type'] = 'WEB';
    body['logLevel'] = level;
    body['message'] = message;
    body['pwaInstalled'] = window.matchMedia('(display-mode: standalone)').matches
      ? 'yes'
      : 'no';

    if (stacktrace) {
      body['stacktrace'] = stacktrace;
    }
    if (context) {
      try {
        body['context'] = JSON.stringify(context);
      } catch (e) {
        body['context'] = 'Failed to stringfy context, test serialize it';
      }
    }
    let router = this.injector.get(Router);
    this.http
      .put(this.endpoint + router.url, body, {responseType: 'text'})
      .subscribe({
        error: () => console.error('Not kibana endpoint found'),
      });
  }
}
