import {Component} from '@angular/core';

import {ResponsiveService} from '../../../responsive/responsive.service';
import {FormRowComponent} from '../form-row.component';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-form-sub-row',
  templateUrl: '../form-row.component.html',
  styleUrls: ['../form-row.component.scss', './form-sub-row.component.scss'],
})
export class FormSubRowComponent extends FormRowComponent {
  constructor(protected responsiveService: ResponsiveService) {
    super(responsiveService);
  }
}
