import {Injectable} from '@angular/core';
import {ModalDialogComponent, ModalHelperService} from 'common';
import {KYC_DIALOG_DATA} from '../kyc-dialog-data';
import {Observable, of} from 'rxjs';
import {catchError, mapTo} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KycModalService {
  constructor(private modalHelperService: ModalHelperService) {}

  openModalKycExpired(): Observable<void> {
    return this.modalHelperService
      .openOkModal(ModalDialogComponent, {
        componentParams: KYC_DIALOG_DATA['EXPIRED']['DEFAULT'],
      })
      .pipe(mapTo(void 0));
  }

  openModalKycPending(
    stepOption:
      | 'DEFAULT'
      | 'CHRISTMAS'
      | 'OTHER'
      | 'PLAY'
      | 'PLAY_CHRISTMAS'
      | 'UNLOAD'
      | 'UNLOAD_CHRISTMAS',
  ): Observable<any> {
    return this.modalHelperService
      .openOkCancelModal(ModalDialogComponent, {
        componentParams: KYC_DIALOG_DATA['PENDING'][stepOption],
      })
      .pipe(catchError(() => of(void 0)));
  }

  openModalKycRequired(
    stepOption: 'DEFAULT' | 'OTHER' | 'PLAY' | 'UNLOAD',
  ): Observable<any> {
    return this.modalHelperService.openOkModal(ModalDialogComponent, {
      componentParams: KYC_DIALOG_DATA['REQUIRED'][stepOption],
    });
  }
}
