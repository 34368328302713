import {isNumeric} from 'common';

import {RequiredBetRuleTypeMetadata} from './required-bet-rule-type-metadata';

/**
 * Used in games to define maximum doubles, triples and bets should be selected.
 */
export class SelectionBetRuleTypeMetadata extends RequiredBetRuleTypeMetadata {
  maxDoubles: number;

  maxTriples: number;

  minMultiplier: number;

  maxMultiplier: number;

  constructor() {
    super();
  }

  static createFromBackend(obj: any) {
    let selectionBRTMD = new SelectionBetRuleTypeMetadata();

    selectionBRTMD.required = obj.needed;
    selectionBRTMD.optional = !!obj.optional;
    selectionBRTMD.maxDoubles = isNumeric(obj.maxPairs) ? obj.maxPairs : obj.needed;
    selectionBRTMD.maxTriples = isNumeric(obj.maxTriples)
      ? obj.maxTriples
      : obj.needed;
    selectionBRTMD.minMultiplier = obj.multBetMin || 1;
    selectionBRTMD.maxMultiplier = obj.multBetMax;

    return selectionBRTMD;
  }

  // Serializable inherited from parent.
}
