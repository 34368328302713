import {Injectable} from '@angular/core';
import {State} from './state';
import {from, Observable, of} from 'rxjs';
import {StatesDao} from './states.dao';
import {StatesService} from './states.service';
import {SelectorStateOperations} from './selector-state-operations';
import {StateNotificationsDataService} from './state-notifications-data.service';
import {first, map, switchMap} from 'rxjs/operators';
import {StateAppDataService} from './state-app-data.service';
import {NotificationLastStateSelected} from './notification-last-state-selected';

@Injectable()
export class StateNotificationsService extends SelectorStateOperations {
  private currentCategory: string;

  constructor(
    private stateNotificationsDataService: StateNotificationsDataService,
    protected statesDao: StatesDao,
    protected statesService: StatesService,
    private stateAppDataService: StateAppDataService,
  ) {
    super(statesDao, statesService);
  }

  getState(): Observable<State> {
    return this.stateNotificationsDataService.getData().pipe(
      map(
        (statesSelected: Array<NotificationLastStateSelected>) =>
          !!statesSelected &&
          statesSelected.find(
            stateSelected =>
              stateSelected.notificationCategory === this.currentCategory,
          ),
      ),
      switchMap((stateSelected: NotificationLastStateSelected) =>
        from(
          stateSelected
            ? of(stateSelected.state)
            : this.stateAppDataService.getData().pipe(first()),
        ),
      ),
    );
  }

  setState(state: State): Observable<void> {
    const newStateSelected = {
      notificationCategory: this.currentCategory,
      state: state,
    };
    this.stateNotificationsDataService
      .getData()
      .pipe(
        first(),
        map(data => {
          const filteredData = data?.filter(
            notif => notif.notificationCategory !== this.currentCategory,
          );
          if (filteredData) {
            return [...filteredData, newStateSelected];
          } else {
            return [newStateSelected];
          }
        }),
        map(data => this.stateNotificationsDataService.setData(data)),
      )
      .subscribe();
    return of(void 0);
  }

  setCategory(category: string): void {
    this.currentCategory = category;
  }

  notifyState(
    state: State,
    email: string,
  ): Observable<{status: string; message: string}> {
    throw new Error('Not implemented');
  }
}
