import {Injectable} from '@angular/core';

import {SelectComponent} from './select.component';

@Injectable()
export class SelectService {
  private parent: SelectComponent;

  registerParent(select: SelectComponent): void {
    this.parent = select;
  }

  getParent(): SelectComponent {
    return this.parent;
  }
}
