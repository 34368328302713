import {Serializable} from 'common';

import {GroupHistoryEntryLayoutExtra} from './group-history-entry-layout-extra.enum';
import {GroupHistoryEntryLayout} from './group-history-entry-layout.enum';
import {GroupHistoryEntryType} from './group-history-entry-type.enum';

class GroupHistoyEntryInternal {
  id: number;
  amount: number;
  type: GroupHistoryEntryType;
  layout: GroupHistoryEntryLayout;
  layoutExtra: GroupHistoryEntryLayoutExtra;
  description: string;
  longDescription: string;
  date: number;
  userName: string;
  // indicates if the amount is negative
  negative: boolean;
}

export class GroupHistoryEntry extends Serializable(GroupHistoyEntryInternal) {
  static createFromBackend(obj: any): GroupHistoryEntry {
    let g = new GroupHistoryEntry();
    GroupHistoryEntry.fillFromBackend(g, obj);
    return g;
  }

  protected static fillFromBackend(g: GroupHistoryEntry, obj: any) {
    g.id = obj.id;
    g.amount = obj.amount;
    g.type = obj.type;
    g.layout = obj.layout;
    g.layoutExtra = obj.layoutExtra;
    g.description = obj.desc;
    g.longDescription = obj.expandedText;
    g.date = obj.date;
    g.userName = obj.mainMember?.name;
    g.negative = obj.negative;
  }
}
