<div class="tl-form-row__content-row">
  <span
    *ngIf="!hideLabel"
    class="tl-form-row__label"
    [class.tl-form-row__label--large]="largeLabel">
    <ng-template [ngTemplateOutlet]="nameContent"></ng-template>
  </span>

  <div *ngIf="showInfo" class="tl-form-row__info-button">
    <ng-template tlIfDesktop [tlIfDesktopAnd]="!!info" [tlIfDesktopElse]="basicInfo">
      <tl-info-tooltip
        [template]="infoContent"
        [placement]="infoPlacement"
        [icon]="icon">
      </tl-info-tooltip>
    </ng-template>

    <ng-template #basicInfo>
      <tl-info-basic
        *ngIf="info || infoCallback"
        [icon]="icon"
        (openInfo)="openInfo()">
      </tl-info-basic>
    </ng-template>
  </div>
  <div
    class="tl-form-row__content"
    *ngIf="showContent"
    [ngClass]="{
      'tl-form-row__content--start': alignContent === 'start',
      'tl-form-row__content--center': alignContent === 'center',
      'tl-form-row__content--end': alignContent === 'end'
    }">
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="isInfoOpen" class="tl-form-row__mobile-info">
  <ng-template [ngTemplateOutlet]="infoContent"></ng-template>
</div>

<div class="tl-form-row__error-row">
  <ng-content select=".fr-error"></ng-content>
</div>

<ng-template #nameContent>
  <ng-template
    *ngIf="name | isTemplate; else nameTextContent"
    [ngTemplateOutlet]="nameTemplate"></ng-template>

  <ng-template #nameTextContent>
    <tl-translatable-text [text]="nameText"></tl-translatable-text>
  </ng-template>
</ng-template>

<ng-template #infoContent>
  <ng-template
    *ngIf="info | isTemplate; else infoTextContent"
    [ngTemplateOutlet]="infoTemplate"></ng-template>

  <ng-template #infoTextContent>
    <tl-translatable-text [text]="infoText"></tl-translatable-text>
  </ng-template>
</ng-template>
