import {NgModule} from '@angular/core';
import {DEFAULT_CURRENCY_CODE, DEFAULT_PHONE_CODE} from 'common';

import {environment} from '../../../environments/environment';

@NgModule({
  providers: [
    {provide: DEFAULT_CURRENCY_CODE, useValue: environment.locale.currencyCode},
    {
      provide: DEFAULT_PHONE_CODE,
      useValue: environment.locale.user.defaultPhoneCode,
    },
  ],
})
export class I18nProvidersModule {}
