import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

import {PropagateGameParamsUriMapResolver} from '../games/uri/propagate-game-params-uri-map-resolver';
import {PLATFORM_MAP_CONFIG} from '../common/responsive/platform-mapper.token';
import {PlatformUriConfig} from '../common/responsive/platform-mapper-types';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    news: [
      {
        originUrl: environment.internalUrls.news,
        destinationPlatformUrl: {
          desktop: `/${routeMap.desktop.news}/:newsId`,
          mobile: `/m/${routeMap.mobile.newsArticle}/:newsId`,
        },
        pattern: /tulotero:\/\/news\/\?id=([0-9]+)/,
        resolver: PropagateGameParamsUriMapResolver,
      },
    ],
  },
];

const platformMapping: PlatformUriConfig = [
  {
    desktopUrl: `/${routeMap.desktop.news}/:newsId`,
    desktopPattern: new RegExp(`\\/${routeMap.desktop.news}\\/(\\w+)`),
    mobileUrl: `/m/${routeMap.mobile.newsArticle}/:newsId`,
    mobilePattern: new RegExp(`\\/m\\/${routeMap.mobile.newsArticle}\\/(\\w+)`),
  },
];

@NgModule({
  providers: [
    PropagateGameParamsUriMapResolver,
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
    {
      provide: PLATFORM_MAP_CONFIG,
      useValue: platformMapping,
      multi: true,
    },
  ],
})
export class TuloteroSlidesUriMappingModule {}
