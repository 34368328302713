import {BrowserMultiFormatReader} from '@zxing/library';

export class BrowserMultiReaderFix extends BrowserMultiFormatReader {
  /**
   * Sets the new stream and request a new decoding-with-delay.
   */
  protected async attachStreamToVideo(
    stream: MediaStream,
    videoSource: string | HTMLVideoElement,
  ): Promise<HTMLVideoElement> {
    // saving the stream before preparing he video allows for reseting the
    // stream even if the video element is no longer there and the next line
    // crashes
    this.stream = stream;
    const videoElement = this.prepareVideoElement(videoSource);

    this.addVideoSource(videoElement, stream);

    this.videoElement = videoElement;

    await this.playVideoOnLoadAsync(videoElement);

    return videoElement;
  }
}
