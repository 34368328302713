import {SerializableDisableWarning} from 'common';

import {RequiredBetRuleTypeMetadata} from './required-bet-rule-type-metadata';
import {RequiredTrueBetRuleTypeMetadata} from './required-true-bet-rule-type-metadata';

/**
 * Defines a new bet by choosing some of the selections from a source type.
 * If any of the selections from the source type is multiple the number of bets
 * is calculated as the possible number of individual combinations.
 *
 * Used for elige8.
 */
export class AdditionalBetPicksBetRuleTypeMetadata extends RequiredTrueBetRuleTypeMetadata {
  /**
   * This type depends on the number of bets in the source type.
   *
   * @example [1, X] [Elige8]
   * price matches: 0.75
   * price elige8: 0.5
   * total: 0.75 + 0.5 * 2 = 1.75
   */
  @SerializableDisableWarning()
  betsSourceType: RequiredBetRuleTypeMetadata;

  static createFromBackend(obj: any) {
    let instance = new AdditionalBetPicksBetRuleTypeMetadata();

    instance.required = obj.needed;
    instance.requiredTrue = obj.neededTrue;
    instance.optional = !!obj.optional;

    return instance;
  }

  // Serializable inherited from parent.
}
