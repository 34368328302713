import {NgModule} from '@angular/core';

import {LoadingModule} from '../loading/loading.module';

import {ImageDefaultDirective} from './image-default.directive';
import {ImageLoadingDirective} from './image-loading.directive';

@NgModule({
  imports: [LoadingModule],
  exports: [ImageDefaultDirective, ImageLoadingDirective],
  declarations: [ImageDefaultDirective, ImageLoadingDirective],
})
export class ImageModule {}
