export function transitionRight(oldState: string, newState: string) {
  return oldState !== 'waitingright' && newState === 'right';
}

export function transitionLeft(oldState: string, newState: string) {
  return oldState !== 'waitingleft' && newState === 'left';
}

export function transitionTop(oldState: string, newState: string) {
  return oldState !== 'waitingtop' && newState === 'top';
}

export function transitionBottom(oldState: string, newState: string) {
  return oldState !== 'waitingbottom' && newState === 'bottom';
}

export function transitionSlideOutToBottom(oldState: string, newState: string) {
  return oldState !== 'waitingslideouttobottom' && newState === 'slideouttobottom';
}
