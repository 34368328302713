import {NgModule} from '@angular/core';

import {ControlStatusPipe} from './control-status.pipe';
import {ControlValuePipe} from './control-value.pipe';
import {PrefixInputDirective} from './prefix-input.directive';
import {ScrollTargetControlDirective} from './scroll-target-control.directive';
import {ScrollToControlDirective} from './scroll-to-control.directive';

@NgModule({
  exports: [
    ControlStatusPipe,
    ControlValuePipe,
    PrefixInputDirective,
    ScrollToControlDirective,
    ScrollTargetControlDirective,
  ],
  declarations: [
    ControlStatusPipe,
    ControlValuePipe,
    PrefixInputDirective,
    ScrollToControlDirective,
    ScrollTargetControlDirective,
  ],
})
export class FormUtilsModule {}
