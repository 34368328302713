import {Serializable, SerializableDisableWarning} from 'common';
import {MessageBannerInternal} from './message-banner';

class MessageBannerByStateInternal extends MessageBannerInternal {
  @SerializableDisableWarning()
  region: Array<string>;
}

export class MessageBannerByState extends Serializable(
  MessageBannerByStateInternal,
) {
  static createFromBackend(obj: any): MessageBannerByState {
    let messageBannerByState: MessageBannerByState = new MessageBannerByState();

    messageBannerByState.id = obj.id;
    messageBannerByState.text = obj.text;
    messageBannerByState.url = obj.url;
    messageBannerByState.region = obj.region;

    return messageBannerByState;
  }
}
