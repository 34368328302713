import {NgModule} from '@angular/core';

import {TimelineItemComponent} from './timeline-item.component';
import {TimelineComponent} from './timeline.component';

@NgModule({
  exports: [TimelineComponent, TimelineItemComponent],
  declarations: [TimelineComponent, TimelineItemComponent],
})
export class TimelineModule {}
