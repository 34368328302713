import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractObservableDataService} from '../../model/abstract-observable-data.service';

import {MessageContext} from './message-context';
import {MessageHook} from './message-hook';

@Injectable({providedIn: 'root'})
export class MessageHookDataService extends AbstractObservableDataService<
  Map<MessageContext, MessageHook>
> {
  getMessage(id: MessageContext): Observable<MessageHook | null> {
    return this.getData().pipe(map(messages => messages.get(id)));
  }
}
