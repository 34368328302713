import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

import {environment} from '../../../../environments/environment';

@Component({
  selector: 'tl-kyc-ok-dialog',
  templateUrl: './kyc-ok-dialog.component.html',
  styleUrls: ['./kyc-ok-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KycOkDialogComponent {
  @HostBinding('class.tl-kyc-ok-dialog')
  readonly hostClass = true;

  text: string;

  title: string;

  environmentId: string;

  constructor() {
    this.environmentId = environment.id;
  }
}
