import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {ToggleButtonComponent} from './toggle-button.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [ToggleButtonComponent],
  declarations: [ToggleButtonComponent],
})
export class ToggleButtonModule {}
