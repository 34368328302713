import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {mapTo} from 'rxjs/operators';

import {loadScript} from '../util/script';

import {GOOGLE_MAPS_API_KEY} from './config-tokens';

@Injectable({providedIn: 'root'})
export class GoogleMapsService {
  baseUrl = 'https://maps.googleapis.com/maps/api/';

  constructor(@Inject(GOOGLE_MAPS_API_KEY) private key: string) {}

  getStaticImageUrl(x: string, y: string, marker = false): string {
    let url =
      this.baseUrl +
      'staticmap?center=' +
      x +
      ',' +
      y +
      '&zoom=17&size=700x400&maptype=roadmap&key=' +
      this.key;
    if (marker) {
      url += '&markers=color:red%7C' + x + ',' + y + '&markers=size:mid';
    }

    return url;
  }

  loadApiScript(): Observable<void> {
    return loadScript(
      'google-maps-api',
      '//maps.googleapis.com/maps/api/js?libraries=places&key=' + this.key,
      true,
    ).pipe(mapTo(void 0));
  }
}
