export enum BigPrizeType {
  DEFAULT,
  PT_MEDIUM,
}

export interface BigPrize {
  prize: number;

  type: BigPrizeType;

  subtitle?: string;

  // Indicates that all money bets are divided among those with winning tickets.
  // Example: Gopher 5, North 5
  parimutuel: boolean;
}
