import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {
  AlertsService,
  ResponsiveService,
  ToggleCloseOthersService,
  TooltipService,
} from 'common';

import {BoothSelectedService} from '../../booth-selected.service';
import {BoothsSearchViewService} from '../../booths-search-view.service';
import {LotteryBooth} from '../../data/lottery-booth';
import {LotteryBoothsListBaseComponent} from './lottery-booths-list-base.component';
import {LotteryBoothsGeolocationViewService} from '../../lottery-booths-geolocation-view.service';
import {LotteryBoothsLocateViewService} from '../../lottery-booths-locate-view.service';
import {LotteryBoothItemComponent} from '../booth-item/booth-item.component';
import {SessionService} from '../../../user/auth/session.service';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-lottery-booths-list',
  templateUrl: './lottery-booths-list.component.html',
  styleUrls: ['./lottery-booths-list.component.scss'],
  providers: [LotteryBoothsLocateViewService, ToggleCloseOthersService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('SlideFromBottom', [
      state('void', style({maxHeight: '0px'})),
      state('*', style({maxHeight: '500px'})),
      transition(':enter, :leave', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class LotteryBoothsListComponent
  extends LotteryBoothsListBaseComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input()
  currentBoothId: string;

  @Input()
  geolocationEnabled = false;

  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  @ViewChild(LotteryBoothItemComponent, {read: ElementRef})
  boothItem: ElementRef;

  helpOpenned = false;
  watchingGeoLocation: boolean;

  get minBufferPx(): number {
    // Screen height - (header + tabs + input) - banner booth fav
    return (
      this.responsiveService.getCurrentScreen().y -
      131 -
      (!!this.currentBoothId ? 0 : 25)
    );
  }

  get maxBufferPx(): number {
    // 3 x minBufferPx
    return 3 * this.minBufferPx;
  }

  get itemSize(): number {
    if (this.responsiveService.isDesktop()) {
      return 96;
    } else {
      return this.geolocationEnabled ? 98 : 78;
    }
  }

  constructor(
    public boothGeolocationViewService: LotteryBoothsGeolocationViewService,
    protected boothSelectedService: BoothSelectedService,
    protected boothsSearchViewService: BoothsSearchViewService,
    protected cdr: ChangeDetectorRef,
    public lotteryBoothsLocateViewService: LotteryBoothsLocateViewService,
    protected tooltipService: TooltipService,
    protected responsiveService: ResponsiveService,
    protected sessionService: SessionService,
    protected alertsService: AlertsService,
    protected router: Router,
  ) {
    super(
      boothsSearchViewService,
      boothSelectedService,
      sessionService,
      alertsService,
      router,
      responsiveService,
      cdr,
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.viewport &&
      changes.hasOwnProperty('currentBoothId') &&
      this.currentBoothId &&
      this.mode === 'select'
    ) {
      const index = this.booths.findIndex(booth => booth.id === this.currentBoothId);
      const itemMargin = 5;
      const indexOffset =
        (this.boothItem.nativeElement.offsetHeight + itemMargin) * (index + 1);
      const scrollTop = this.viewport.measureScrollOffset('top');
      const viewportHeight = this.viewport.elementRef.nativeElement.offsetHeight;
      if (indexOffset < scrollTop || indexOffset > scrollTop + viewportHeight) {
        this.viewport.scrollToOffset(indexOffset - 50, 'smooth');
      }
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.geolocationEnabled) {
      this.locate();
    } else {
      setTimeout(() => this.tooltipService.showVolatile('booths-geolocation'), 400);
    }
  }

  clearSearch(): void {
    this.searchInput.nativeElement.value = '';
  }

  toggleHelp(): void {
    this.helpOpenned = !this.helpOpenned;
  }

  onBoothSelection(event: MouseEvent, booth: LotteryBooth): void {
    event.stopPropagation();

    this.onBoothSelected(booth);

    if (this.mode === 'favorite' || booth?.id !== this.currentBoothId) {
      this.currentBoothId = booth ? booth.id : null;
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tooltipService.clearVolatileStorage();
  }

  toogleLocate(): void {
    if (!this.watchingGeoLocation) {
      this.locate();
    } else {
      this.lotteryBoothsLocateViewService.disableGeolocation();
      this.watchingGeoLocation = false;
    }
    this.cdr.markForCheck();
  }

  private locate(): void {
    this.lotteryBoothsLocateViewService
      .locate()
      .subscribe(position => this.onGeolocationUpdated(position));
  }

  private onGeolocationUpdated(position: GeolocationPosition): void {
    this.watchingGeoLocation = true;
    this.tooltipService.markAsShown('booths-geolocation');
    if (this.viewport) {
      this.viewport.scrollToIndex(0);
    }
  }
}
