import {Injectable} from '@angular/core';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class PopoverExtensionService {
  popovers: Array<NgbPopover> = [];

  constructor() {}

  closeAll() {
    this.popovers.forEach(p => p.close());
  }

  add(popover: NgbPopover) {
    if (this.popovers.indexOf(popover) < 0) {
      this.popovers.push(popover);
    }
  }

  remove(popover: NgbPopover) {
    let i = this.popovers.indexOf(popover);

    if (i >= 0) {
      this.popovers.splice(i, 1);
    }
  }
}
