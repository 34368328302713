<div class="tl-round-icon-button__content" (click)="click($event)">
  <img
    *ngIf="image; else iconHolder"
    class="tl-round-icon-button__image"
    [src]="image" />

  <ng-template #iconHolder>
    <div
      class="tl-round-icon-button__icon"
      [style.background]="color"
      [ngClass]="icon"
      [class.tl-round-icon-button__icon--bordered]="!icon"
      [title]="text || ''">
      &nbsp;
    </div>
  </ng-template>

  <span class="tl-round-icon-button__over-text">{{ text }}</span>
</div>
<span *ngIf="!!description" class="tl-round-icon-button__name">
  {{ description }}
</span>
