import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PaginationData} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {adaptTicket} from '../../patch/info.patch';

import {GenericTicket} from './generic-ticket';
import {TicketsDao} from './tickets.dao';

// TODO · ADAPTER · remove
@Injectable()
export class TicketsDaoAdapter extends TicketsDao {
  moreTickets(
    index: number,
    filter?: Array<string>,
    groupId?: number,
  ): Observable<PaginationData<GenericTicket>> {
    let filterString =
      filter && filter.length > 0
        ? '/' +
          filter
            .reduce((a, f) => {
              const currentFilter = f === 'prized' ? 'PRIZED_WITH_PROVISIONALS' : f;
              return a.concat(currentFilter) + '+';
            }, '')
            .replace(/\+$/g, '')
            .toLowerCase()
        : '';

    let params = new HttpParams().append('firstResult', index.toString());

    if (groupId) {
      params = params.append('groupId', groupId.toString());
    }

    return this.http
      .get(this.baseUrl + '/users/boletos/me' + filterString, {params: params})
      .pipe(
        map((obj: any) => {
          let tickets: Array<GenericTicket> = obj.boletos.map(ticket =>
            GenericTicket.createFromBackend(adaptTicket(ticket)),
          );

          return new PaginationData<GenericTicket>(tickets, obj.total);
        }),
      );
  }
}
