import {invokePrefixedFunction, invokePrefixedProperty} from './prefixer';

export function browserHasFullScreen(document: HTMLElement) {
  return !!invokePrefixedProperty(document, 'requestFullscreen');
}

export function isFullScreen(document: Document) {
  return (
    ((<any>document).fullScreenElement &&
      (<any>document).fullScreenElement !== null) ||
    (<any>document).mozFullScreen ||
    (<any>document).webkitIsFullScreen
  );
}

export function requestFullScreen(element: HTMLElement) {
  return invokePrefixedFunction(element, 'requestFullscreen');
}

export function exitFullScreen(document: Document) {
  return invokePrefixedFunction(document, 'exitFullscreen');
}
