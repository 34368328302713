import {NgModule} from '@angular/core';

import {PhoneLinkPipe} from './phone-link.pipe';
import {PhoneFormatPipe} from './phone-format.pipe';
import {PhoneUnformatPipe} from './phone-unformat.pipe';

@NgModule({
  exports: [PhoneLinkPipe, PhoneFormatPipe, PhoneUnformatPipe],
  declarations: [PhoneLinkPipe, PhoneFormatPipe, PhoneUnformatPipe],
})
export class PhoneUtilsModule {}
