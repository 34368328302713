import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {CardConfig} from './card-config';

@Component({
  selector: 'tl-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent {
  @HostBinding('class.tl-card--band')
  @HostBinding('style.border-left-color')
  bandColor: string = null;

  @HostBinding('style.background')
  background = 'none';

  @HostBinding('class.tl-card--dark')
  dark: boolean;

  @HostBinding('class.tl-card--green')
  green: boolean;

  @HostBinding('class.tl-card')
  readonly hostClass = true;

  @Input()
  set cardConfig(config: CardConfig) {
    this.bandColor = config.bandColor;
    this.background = config.image;

    this.dark = config.style === 'dark';
    this.green = config.style === 'green';
  }
}
