import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {StepBallModule} from './step-ball/step-ball.module';
import {StepFlowModule} from './step-flow/step-flow.module';
import {StepsFlowComponent} from './steps-flow.component';

@NgModule({
  imports: [CommonModule, StepFlowModule, StepBallModule],
  exports: [StepsFlowComponent],
  declarations: [StepsFlowComponent],
})
export class StepsFlowModule {}
