import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {BehaviorSubject, fromEvent, merge, Subject} from 'rxjs';
import {debounceTime, delay, filter, map, takeUntil} from 'rxjs/operators';

import {ScreenSize} from '../../responsive/model';
import {ResponsiveService} from '../../responsive/responsive.service';
import {Destroyable} from '../../util/destroyable';

import {DropdownMenuItemComponent} from './menu-item/dropdown-menu-item.component';
import {MenuNode} from './menu-node';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent implements AfterViewInit, OnChanges {
  @Input()
  nodes: Array<MenuNode>;

  /**
   * Optional template for the dropdown toggle.
   * By default only the node title is displayed.
   */
  @Input()
  headerTemplate: TemplateRef<any>;

  /**
   * Optional template for every node but the toggle.
   * By default only the node title is displayed.
   */
  @Input()
  nodeTemplate: TemplateRef<any>;

  @Output()
  selectNode = new EventEmitter<MenuNode>();

  @ViewChildren(DropdownMenuItemComponent)
  menuNodes: QueryList<DropdownMenuItemComponent>;

  @ViewChildren('parentContainer', {read: ElementRef})
  dropContainers: QueryList<ElementRef>;

  displayNodes = new BehaviorSubject<Array<MenuNode>>([]);

  selectedNode = new BehaviorSubject(null);

  @Destroyable()
  private destroySubject = new Subject();

  private activeMenuItems: Array<DropdownMenuItemComponent> = [];

  private mouseInside = false;

  private constraints: {bounds: DOMRect; dropDown: boolean; dropRight: boolean};

  private screenBounds: ScreenSize;

  private currentLevel = 0;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
  ) {}

  ngAfterViewInit(): void {
    const changesOrDestroy = merge(
      this.destroySubject,
      // delay to let inflight debounced events finish
      this.menuNodes.changes.pipe(delay(300)),
    );

    this.menuNodes.changes.subscribe(
      (nodes: QueryList<DropdownMenuItemComponent>) => {
        // listen to nodes with chilren to open them on hover
        merge(
          ...nodes
            .filter(nodeComponent => !!nodeComponent.node.children)
            .filter(nodeComponent => !nodeComponent.asHeader)
            .map(nodeComponent => nodeComponent.hoverChange),
        )
          .pipe(
            debounceTime(300),
            filter(itemEvent => !!itemEvent.component.node.children),
            filter(itemEvent => itemEvent.hover),
            map(itemEvent => itemEvent.component),
            takeUntil(changesOrDestroy),
          )
          .subscribe(component => this.openNode(component));
      },
    );

    // close everything but the root when we leave the element
    merge(
      fromEvent(this.element.nativeElement, 'mouseenter').pipe(map(() => true)),
      fromEvent(this.element.nativeElement, 'mouseleave').pipe(map(() => false)),
    )
      .pipe(
        debounceTime(210),
        filter(hovering => !hovering),
        takeUntil(this.destroySubject),
      )
      .subscribe(() => this.closeAll(true));

    this.dropContainers.changes.subscribe(containers => {
      if (containers.length) {
        this.positionParent(containers.last);
      }

      const level = containers.length;

      // when removing containers dont add previous level listeners again
      if (level > this.currentLevel && level > 1) {
        const unfoldedNode = this.activeMenuItems[this.activeMenuItems.length - 1];
        const lessContainers = this.dropContainers.changes.pipe(
          map(c => c.length),
          filter(l => l < level),
        );

        // listen to unfolded node and it's container hovers
        merge(
          fromEvent(containers.last.nativeElement, 'mouseenter').pipe(
            map(() => true),
          ),
          fromEvent(containers.last.nativeElement, 'mouseleave').pipe(
            map(() => false),
          ),
          unfoldedNode.hoverChange.pipe(map(itemEvent => itemEvent.hover)),
        )
          .pipe(
            // debounce to avoid flickering when changing hovered element
            debounceTime(200),
            filter(hover => !hover),
            filter(() => containers.length === level),
            // when the containers are closed elsewhere stop listening
            takeUntil(merge(lessContainers, this.destroySubject)),
          )
          .subscribe(() => this.closeNode(unfoldedNode));
      }

      this.currentLevel = level;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('nodes')) {
      this.selectedNode.next(this.nodes[0]);
    }
  }

  nodeClick(nodeItem: DropdownMenuItemComponent): void {
    if (nodeItem.node.children) {
      this.openNode(nodeItem);
    } else {
      this.selectedNode.next(nodeItem.node);
      this.selectNode.next(nodeItem.node);
      this.closeAll();
    }
  }

  @HostListener('document:click')
  onOutsideClick(): void {
    if (!this.mouseInside) {
      this.closeAll();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.mouseInside = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.mouseInside = false;
  }

  toggleMain(): void {
    if (this.displayNodes.getValue().length) {
      this.closeAll();
    } else {
      this.storeConstraints();
      this.displayNodes.next([
        {
          id: 'root',
          title: 'root',
          children: this.nodes,
        },
      ]);
    }
  }

  private openNode(nodeComponent: DropdownMenuItemComponent): void {
    const activeNodes = this.displayNodes.getValue();

    if (activeNodes.includes(nodeComponent.node)) {
      return;
    }

    this.displayNodes.next(activeNodes.concat(nodeComponent.node));
    this.activeMenuItems.push(nodeComponent);
  }

  private closeNode(node: DropdownMenuItemComponent): void {
    const level = this.activeMenuItems.indexOf(node);
    if (level === -1) {
      return;
    }

    this.displayNodes.next(this.displayNodes.getValue().slice(0, level + 1));

    if (this.activeMenuItems.length) {
      this.activeMenuItems.splice(level);
    }
  }

  private closeAll(keepRoot = false): void {
    if (keepRoot) {
      this.displayNodes.next(this.displayNodes.getValue().slice(0, 1));
      this.activeMenuItems = [];
    } else {
      this.displayNodes.next([]);
      this.activeMenuItems = [];
    }
  }

  /**
   * Save the current position of the toggler and decide the direction in wich
   * the dropdown should be openned.
   */
  private storeConstraints(): void {
    const toggleBounds = this.element.nativeElement.getBoundingClientRect();
    this.screenBounds = this.responsiveService.getCurrentScreen();
    // false false | true  false
    // false  true | true  true
    const rightSide =
      toggleBounds.x + toggleBounds.width / 2 > this.screenBounds.x / 2;

    const bottomSide =
      toggleBounds.y + toggleBounds.height / 2 > this.screenBounds.y / 2;

    this.constraints = {
      bounds: toggleBounds,
      dropDown: bottomSide,
      dropRight: rightSide,
    };
  }

  /**
   * Position a dropdown container relative to the toggle bounds.
   */
  private positionParent(element: ElementRef): void {
    const params = {};
    if (!this.activeMenuItems.length) {
      // root container gets positioned relative to the toggler
      if (this.constraints.dropRight) {
        params['left'] = 'auto';
        params['right'] = `${this.screenBounds.x - this.constraints.bounds.right}px`;
      } else {
        params['left'] = `${this.constraints.bounds.left}px`;
        params['right'] = 'auto';
      }

      if (this.constraints.dropDown) {
        params['top'] = 'auto';
        params['bottom'] = `${
          this.screenBounds.y -
          this.constraints.bounds.bottom +
          this.constraints.bounds.height +
          2
        }px`;
      } else {
        params['top'] = `${
          this.constraints.bounds.top + this.constraints.bounds.height + 2
        }px`;
        params['bottom'] = 'auto';
      }

      params['min-width'] = `${this.constraints.bounds.width}px`;
    } else {
      // other containers get positiones relative to their parent
      const parentRect =
        this.activeMenuItems[
          this.activeMenuItems.length - 1
        ].elementRef.nativeElement.getBoundingClientRect();

      let top = parentRect.top - 1;
      let left = parentRect.left + parentRect.width + 1;

      // if the container overflows the viewport to correct the position
      const containerBounds = element.nativeElement.getBoundingClientRect();
      let topCorrection =
        this.screenBounds.y - (containerBounds.height + parentRect.top);
      let leftCorrection =
        this.screenBounds.x - (containerBounds.width + parentRect.left);
      topCorrection = topCorrection < 0 ? topCorrection : 0;
      leftCorrection = leftCorrection < 0 ? leftCorrection : 0;

      params['top'] = `${top + topCorrection}px`;
      params['left'] = `${left + leftCorrection}px`;
    }

    Object.entries(params).forEach(entry => {
      this.renderer.setStyle(element.nativeElement, entry[0], entry[1]);
    });
    // containers are hidden until positioned
    this.renderer.removeClass(element.nativeElement, 'hidden');
  }
}
