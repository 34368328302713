import {Serializable} from 'common';

class PaymentMethodUIMetadataInternal {
  /**
   * Fontello icon name (only icon)
   */
  icon: string;

  /**
   * Fontello icon name (icon and text)
   */
  iconWithText: string;
}

export class PaymentMethodUIMetadata extends Serializable(
  PaymentMethodUIMetadataInternal,
) {
  constructor(icon: string, iconWithText: string) {
    super();
    this.icon = icon;
    this.iconWithText = iconWithText;
  }
}
