import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {TranslatableText} from '../i18n/translatable-text';

@Component({
  selector: 'tl-panel-dropdown',
  templateUrl: './panel-dropdown.component.html',
  styleUrls: ['./panel-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelDropdownComponent implements OnInit {
  @HostBinding('class.tl-panel-dropdown')
  readonly hostClass = true;

  @Input()
  title: TranslatableText;

  @Input()
  defaultClosed = true;

  closed: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.closed = this.defaultClosed;
    this.cdr.markForCheck();
  }

  click(): void {
    this.closed = !this.closed;
    this.cdr.markForCheck();
  }
}
