import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {TicketCanceled} from './ticket-canceled';

@Injectable({
  providedIn: 'root',
})
export class TicketsCanceledService extends AbstractObservableDataService<
  Array<TicketCanceled>
> {
  constructor() {
    super();
  }
}
