import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PaginationData} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractDao} from '../../../common/model/abstract.dao';

import {GenericResult} from './generic-result';
import {State} from '../../../states/data/state';

@Injectable({providedIn: 'root'})
export class ResultsDao extends AbstractDao {
  constructor(protected http: HttpClient) {
    super();
  }

  moreResults(
    index: number,
    filters?: Array<string>,
    state?: State,
  ): Observable<PaginationData<GenericResult>> {
    const filterString = !!filters
      ? filters.reduce((allFilters: string, filter: string) => {
          const separator = allFilters === '' ? '/' : '+';
          return `${allFilters}${separator}${filter.toLowerCase()}`;
        }, '')
      : '';
    const filtersQuery = !!state ? `&filters=state:${state.code}` : '';

    return this.http
      .get(
        `${this.baseUrl}/sorteos/finished${filterString}?firstResult=${index}${filtersQuery}`,
      )
      .pipe(
        map((obj: any) => {
          let results: Array<GenericResult>;
          if (obj.hasOwnProperty('resultados')) {
            results = [];
            for (let result of obj.resultados) {
              results.push(GenericResult.createFromBackend(result));
            }
          }
          return new PaginationData(results, obj.total);
        }),
      );
  }

  getResult(resultId: number): Observable<GenericResult> {
    return this.http
      .get(this.baseUrl + '/sorteos/' + resultId)
      .pipe(map(res => GenericResult.createFromBackend(res)));
  }
}
