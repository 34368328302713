// TODO · ADAPTER · remove
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalStorage} from 'common';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {
  adaptUnfinished,
  patchGameMetadata,
  patchRenderData,
  patchResults,
  patchTickets,
} from '../games/patch/info.patch';

import {BackendDao} from './backend.dao';
import {PrivateInfo} from './private-info/private-info';
import {PublicInfo} from './public-info/public.info';
import {environment} from '~environments/environment';

@Injectable()
export class BackendAdapterDao extends BackendDao {
  constructor(protected http: HttpClient, protected localStorage: LocalStorage) {
    super(http, localStorage);
  }

  allInfoPublic(): Observable<PublicInfo> {
    return this.http.get(environment.endpointCommon).pipe(
      switchMap((res: any) =>
        this.getBooths().pipe(
          map(booths => {
            res.administraciones = booths;
            return res;
          }),
        ),
      ),
      switchMap(response => patchGameMetadata(response, this.http)),
      map(response => patchRenderData(response)),
      map(response => patchResults(response)),
      map(response => PublicInfo.createFromBackend(response)),
    );
  }

  allInfo(lastUpdate?: number): Observable<PrivateInfo> {
    let url = '/users/allinfo/v2';
    if (lastUpdate) {
      url += '?lastUpdate=' + lastUpdate;
    }
    return this.http.get(this.baseUrl + url, {observe: 'response'}).pipe(
      switchMap((res: HttpResponse<any>) => {
        if (res.status !== 204) {
          return this.getBooths().pipe(
            map(booths => {
              res.body.administraciones = booths;
              return res.body;
            }),
          );
        } else {
          return of(res);
        }
      }),
      switchMap(response =>
        response['genericDescriptors']
          ? patchGameMetadata(response, this.http)
          : of(response),
      ),
      map(response => patchRenderData(response)),
      map(response => patchResults(response)),
      map(response => patchTickets(response)),
      map(response => adaptUnfinished(response)),
      map(response =>
        response instanceof HttpResponse
          ? null
          : PrivateInfo.createFromBackend(response),
      ),
    );
  }
}
