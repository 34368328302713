export {};

declare global {
  interface Set<T> {
    toJSON(): any;
  }
}

Set.prototype.toJSON = function () {
  return Array.from(this.values());
};
