import {Inject, Injectable} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable, switchMap} from 'rxjs';

import {ModalHelperService} from '../../modal/modal-helper.service';
import {Alert} from '../alert';
import {AlertModalComponent} from '../alert-modal/alert-modal.component';

import {AlertOpener} from './alert-opener';

/**
 * Open the alerts in a modal that overlaps the whole page.
 */
@Injectable()
export class ModalAlertOpener extends AlertOpener {
  lastModal: NgbModalRef;

  constructor(
    private modalHelperService: ModalHelperService,
    @Inject('window') private window: Window,
  ) {
    super();

    this.window.addEventListener('popstate', () => this.closeLastAlert());
  }

  /**
   * @inheritDoc
   */
  public open(alert: Alert): Observable<any> {
    // This is to avoid having multiple modals stacked
    if (this.modalHelperService.lastModal) {
      return this.modalHelperService.lastModal.closed.pipe(
        switchMap(() => this.open(alert)),
      );
    } else if (this.lastModal) {
      return this.lastModal.closed.pipe(switchMap(() => this.open(alert)));
    }

    this.lastModal = this.modalHelperService.openModal(AlertModalComponent, {
      componentParams: {
        message: alert.message,
        type: alert.type,
      },
      modalOptions: {
        backdrop: 'static',
        backdropClass: 'modal-backdrop',
        centered: true,
      },
    });
    return from(
      this.lastModal.result.then(
        () => {
          this.lastModal = null;
          if (alert.options?.callback) {
            alert.options.callback();
          }
        },
        () => {
          this.lastModal = null;
          if (alert.options?.dismissed) {
            alert.options.dismissed();
          }
        },
      ),
    );
  }

  closeLastAlert() {
    if (this.lastModal) {
      this.lastModal.close();
    }
  }
}
