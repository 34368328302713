import {Serializable, SerializableProperty, SerializableType} from 'common';
import {SubscribableJackpotIncrement} from './subscribable-jackpot-increment';

class SubscribableJackpotInternal {
  defaultJackpot: number;

  minJackpot: number;

  @SerializableProperty(SubscribableJackpotIncrement, SerializableType.COLLECTION)
  increments: Array<SubscribableJackpotIncrement>;
}

export class SubscribableJackpot extends Serializable(SubscribableJackpotInternal) {
  static createFromBackend(obj: any): SubscribableJackpot {
    let sj: SubscribableJackpot = new SubscribableJackpot();
    sj.defaultJackpot = parseFloat(obj?.jackpot);
    sj.minJackpot = parseFloat(obj?.minimum);
    sj.increments =
      obj?.increments &&
      obj.increments.map(SubscribableJackpotIncrement.createFromBackend);
    return sj;
  }
}
