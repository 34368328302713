import {AbstractObservableDataService, PageableService} from 'common';
import {Observable, of, ReplaySubject} from 'rxjs';
import {first, map, switchMap, tap} from 'rxjs/operators';

import {GameMetadata} from '../../../game-metadata/data/game-metadata';
import {LotteryTicket} from '../../../lottery/data/lottery-ticket';
import {LotteryTicketPaginationData} from '../../../lottery/data/lottery-ticket-pagination-data';

import {LotterySearchType} from './lottery-search-type';
import {CoordinatePoint} from '../../../../geolocation/coordinate-point';

export abstract class AbstractLotterySearch
  extends AbstractObservableDataService<Array<LotteryTicket>>
  implements PageableService<Array<LotteryTicket>>
{
  numberTickets = 0;

  paginationIndex: number;

  paginationEnd: boolean;

  hasData = false;

  isFiltered: ReplaySubject<boolean>;

  minNumber: number;

  location: CoordinatePoint;

  protected gameMetadata: GameMetadata;

  protected raffle: number;

  abstract getStock(boothId: string): Observable<{stock: number; boothId: string}>;

  /**
   * Returns the stock of an administration complying with the condition
   *
   * @param excludeBooth administration to exclude in the search
   * @param minStock Quantity required by the user
   */
  abstract getStockExcludeBooth(
    excludeBooth: string,
    minStock: number,
  ): Observable<{stock: number; boothId: string}>;

  abstract loadData(): Observable<LotteryTicketPaginationData>;

  abstract setSearchParams(params: Map<LotterySearchType, any>): void;

  abstract updateIsFiltered(): void;

  initSearch(raffleId: number, gameMetadata: GameMetadata): void {
    this.setRaffleId(raffleId);
    this.setGameMetadata(gameMetadata);
    this.clear();
  }

  setRaffleId(id: number): void {
    this.raffle = id;
    this.clear();
  }

  getRaffleId(): number {
    return this.raffle;
  }

  setGameMetadata(gameMetadata: GameMetadata) {
    this.gameMetadata = gameMetadata;
  }

  getGameMetadata(): GameMetadata {
    return this.gameMetadata;
  }

  loadMore(): Observable<Array<LotteryTicket>> {
    return this.loadData().pipe(
      switchMap((data: LotteryTicketPaginationData) => {
        const tickets = data.tickets;

        if (this.hasData) {
          return this._data.pipe(
            map(list => list.concat(tickets)),
            first(),
          );
        } else {
          this.setData(tickets);
          return of(tickets);
        }
      }),
      tap(tickets => this.setData(tickets)),
    );
  }

  setData(data: Array<LotteryTicket>): void {
    super.setData(data);
    this.hasData = !!data && !!data.length;
  }

  reset(): Observable<Array<LotteryTicket>> {
    this.paginationEnd = false;
    this.paginationIndex = 0;
    this.hasData = false;
    this.setSearchParams(null);
    return this.loadMore();
  }

  clear(): void {
    this.paginationEnd = false;
    this.paginationIndex = 0;
    this.setSearchParams(null);
    this.setData(null);
  }
}
