import {HttpHeaders} from '@angular/common/http';

/**
 * Add http locations headers
 *
 * @return original headers with location headers
 */
export function addGeolocationHeaders(
  headers: HttpHeaders,
  location: GeolocationPosition,
): HttpHeaders {
  let customHeader: HttpHeaders;
  if (location) {
    customHeader = headers.set(
      'x-geolocation',
      `${location.coords.latitude}:${location.coords.longitude}:${location.coords.accuracy}`,
    );
    customHeader = customHeader.set(
      'x-geolocation-timestamp',
      location.timestamp + '',
    );
  }
  return customHeader;
}
