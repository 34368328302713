import {Component, EventEmitter, Input, Output} from '@angular/core';

import {ngModelProvider} from '../../model/ng-model-config';
import {AbstractNgModel} from '../abstract-ngmodel';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-selectable-mode',
  templateUrl: './selectable-mode.component.html',
  styleUrls: ['./selectable-mode.component.scss'],
  providers: [ngModelProvider(SelectableModeComponent)],
})
export class SelectableModeComponent extends AbstractNgModel<boolean> {
  @Input()
  nullable = false;

  @Input()
  icon1: string;

  @Input()
  name1: string;

  @Input()
  icon2: string;

  @Input()
  name2: string;

  @Input()
  disable1 = false;

  @Input()
  disable2 = false;

  @Output()
  buttonClick = new EventEmitter<boolean>();

  setMode(mode: boolean): void {
    this.buttonClick.emit(mode);

    if ((mode && this.disable1) || (!mode && this.disable2)) {
      return;
    }

    this.model = mode;
    this.modelChange(mode);
  }

  writeValue(obj: any) {
    if (!this.nullable && obj == null) {
      throw new Error('Null value not allowed in dual state mode.');
    }

    if (obj === undefined) {
      this.model = true;
      this.modelChange(this.model);
    } else {
      this.model = obj;
    }
  }
}
