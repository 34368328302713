import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  I18nModule,
  ModalDialogModule,
  ResponsiveUtilsModule,
  ScrollableModule,
} from 'common';

import {KycKoDialogComponent} from './kyc-ko-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ModalDialogModule,
    ResponsiveUtilsModule,
    ScrollableModule,
  ],
  exports: [KycKoDialogComponent],
  declarations: [KycKoDialogComponent],
})
export class KycKoDialogModule {}
