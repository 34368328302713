import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tl-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements OnInit {
  @HostBinding('class.tl-page')
  readonly hostClass = true;

  @Input()
  titleKey: string;

  @Input()
  enableBack = true;

  @Input()
  showInfo = false;

  @Output()
  back = new EventEmitter<void>();

  @Output()
  info = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cdr.markForCheck();
  }

  backOnClick(): void {
    this.back.emit();
  }

  openInfo(): void {
    this.info.emit();
  }
}
