import {Injectable} from '@angular/core';
import {ISubscriptionNotExecutedViewService} from './i-subcriptions-not-executed-view-service';

@Injectable()
export class NoopSubscriptionNotExecutedViewService
  implements ISubscriptionNotExecutedViewService
{
  constructor() {}

  checkSubcriptionsNotExecuted(): void {}
}
