import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
} from '@angular/core';

import {AbstractNgModel} from '../../forms/abstract-ngmodel';
import {ngModelProvider} from '../../model/ng-model-config';
import {isKey, Keys} from '../../util/keyboard/keycodes';

import {BankAccountFormatter} from './bank-account-formatter';
import {ClabeFormatter} from './formatters/clabe-formatter';
import {IbanFormatter} from './formatters/iban-formatter';
import {LocaleService} from '../../i18n/locale.service';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
  providers: [ngModelProvider(BankAccountComponent)],
})
export class BankAccountComponent extends AbstractNgModel<string> {
  @HostBinding('class.tl-bank-account--simple-inputs') get hasSimpleInputs() {
    return this.simpleInputs;
  }

  @Input()
  defaulTabIndex = 1;

  @Input()
  simpleInputs = false;

  @Input()
  showPlaceholder = false;

  @Input('value')
  set setter(str: string) {
    this.account = this.formatter.parse(str);
  }

  account: Array<string> = [];

  @Output()
  changeAccount = new EventEmitter<string>();

  private canJumpFwd = false;
  private canJumpBwd = false;

  get inputInitWidthCoef(): number {
    return this.simpleInputs ? (this.isSmallScreen ? 5 : 6) : 3;
  }

  get inputFieldWidthCoef(): number {
    return this.simpleInputs ? (this.isSmallScreen ? 10 : 7.5) : 12;
  }

  get isSmallScreen(): boolean {
    return this.window.innerWidth < 360;
  }

  get placeholderFields(): string[] {
    const emptyPlaceholder = this.formatter.fields.map(x => '');

    if (!this.showPlaceholder) {
      return emptyPlaceholder;
    }

    if (this.formatter instanceof ClabeFormatter) {
      // not implemented
      return emptyPlaceholder;
    }

    if (this.formatter instanceof IbanFormatter) {
      return this.formatter.fields.map((size, index) => {
        const base =
          index === 0 ? this.localeService.getLocaleAbbr().toUpperCase() : '';
        return base.padEnd(size, '0');
      });
    }

    return emptyPlaceholder;
  }

  constructor(
    private localeService: LocaleService,
    public formatter: BankAccountFormatter,
    @Inject('window') private window: Window,
  ) {
    super();
    if (!this.formatter) {
      throw new Error('Formatter not found. Please specify one.');
    }
  }

  writeValue(str: string): void {
    this.account = this.formatter.parse(str);
  }

  onChange(value: string, index: number) {
    this.account[index] = value;
    this.notifyChange();
  }

  /**
   * Jumps to the next/previous input if possible, triggers change event
   * otherwise. Conditions needed (all of them) to jump to the next input: ·
   * canJumpFwd === true (See onKeyDown method for further details). · The input
   * value reaches the max possible length. · The input is not the last one.
   * Conditions needed (all of them) to jump to the previous input:
   *   · canJumpBwd === true (See onKeyDown method for further details).
   *   · The key pressed is left arrow or Backspace and no input value.
   *   · The input is not the first one.
   * When jumping to the next input, the cursor is positioned after the last
   * char, with one exception, when navigating with right arrow the cursor is
   * positioned at the beginning. When jumping to the previous input, the cursor
   * is positioned after the last char.
   *
   * @param element html input
   * @param idx of the input
   * @param event which has the key pressed
   */
  onKeyUp(element: HTMLInputElement, idx: number, event: KeyboardEvent) {
    const eventKey = event ? event.key : null;
    const tabIndex = element.tabIndex;
    const isInputFilled = element.value.length === this.formatter.fields[idx];

    if (this.canJumpFwd && isInputFilled) {
      if (tabIndex < this.formatter.fields.length - 1 + this.defaulTabIndex) {
        const nextElement = element.nextSibling as HTMLInputElement;
        nextElement.focus();
        let selectionStart = nextElement.value.length;

        if (isKey(eventKey, Keys.ARROW_RIGHT)) {
          selectionStart = 0;
        }

        nextElement.selectionStart = nextElement.selectionEnd = selectionStart;
        this.canJumpFwd = false;
      }

      this.triggerChange(element.value, idx);
    } else if (
      this.canJumpBwd &&
      (isKey(eventKey, Keys.ARROW_LEFT) ||
        (isKey(eventKey, Keys.BACKSPACE) && element.value.length === 0))
    ) {
      if (tabIndex > this.defaulTabIndex) {
        const prevElement = element.previousSibling as HTMLInputElement;
        prevElement.focus();
        prevElement.selectionStart = prevElement.selectionEnd =
          this.formatter.fields[idx - 1];
        this.canJumpBwd = false;
      }
    } else {
      this.triggerChange(element.value, idx);
    }
  }

  /**
   * Allows navigation between inputs.
   * One of the these conditions is needed to allow navigation to the next
   * input: · Input value is one char away from the max length and cursor is
   * positioned at the very end. · Cursor is at the very end of fulfilled input
   * and right arrow is pressed. · Cursor is at the very end of fulfilled input
   * and canJumpFwd === true, this is to support long press. Navigation to the
   * previous element is allowed when the cursor is at the very beginning.
   *
   * @param element html input
   * @param idx of the input
   * @param event which has the key pressed
   */
  onKeyDown(element: any, idx: number, event: KeyboardEvent) {
    const eventKey = event ? event.key : null;
    const fieldLength = this.formatter.fields[idx];
    const isNextToLast =
      element.value.length === fieldLength - 1 &&
      element.selectionStart === fieldLength - 1;
    const isLastPosition = element.selectionStart === fieldLength;
    const isRightArrow = isKey(eventKey, Keys.ARROW_RIGHT);
    this.canJumpFwd =
      isNextToLast || (isLastPosition && (isRightArrow || this.canJumpFwd));
    this.canJumpBwd = element.selectionStart === 0;
  }

  getFullAccount() {
    return this.account.reduce((account, group) => account + group, '');
  }

  /**
   * The pasted text is parsed and the inputs are filled if it is a valid
   * account number. When it is not a valid account number, the current input is
   * filled with the first chars of the pasted text. Additionally, it allows
   * jumping to the next input if the cursor is at the very end after pasting
   * and next input has no value.
   *
   * @param element html input
   * @param idx of the input
   * @param event which has the pasted text
   */
  onPaste(element: HTMLInputElement, idx: number, event: ClipboardEvent) {
    if (!event.clipboardData) return;

    const pastedText = event.clipboardData.getData('text');
    if (this.formatter.isValid(pastedText)) {
      let accountNumber;
      if (this.formatter.accountParserFn) {
        accountNumber = this.formatter.accountParserFn(pastedText);
      } else {
        accountNumber = pastedText;
      }
      this.writeValue(accountNumber);
    } else {
      element.value = pastedText.substr(0, this.formatter.fields[idx]);
      const isLastPosition = element.selectionStart === this.formatter.fields[idx];
      const nextFieldEmpty = !this.account[idx + 1];
      this.canJumpFwd = isLastPosition && nextFieldEmpty;
    }
    this.notifyChange();
  }

  private notifyChange(): void {
    this.modelChange(this.getFullAccount());
    if (this.changeAccount.observed) {
      this.changeAccount.emit(this.getFullAccount());
    }
  }

  private triggerChange(value: string, idx: number) {
    if (value !== this.account[idx]) {
      this.onChange(value, idx);
    }
  }
}
