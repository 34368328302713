import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {EventManager} from '@angular/platform-browser';

import {SwipeDirection, SwipeManager} from './swipe-manager';

@Injectable()
export class SwipePlugin {
  manager: EventManager;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject('window') private window: Window,
  ) {}

  supports(eventName: string): boolean {
    return (
      eventName === SwipeDirection.LEFT ||
      eventName === SwipeDirection.RIGHT ||
      eventName === SwipeDirection.UP ||
      eventName === SwipeDirection.DOWN
    );
  }

  addEventListener(
    element: HTMLElement,
    eventName: string,
    handler: (...args: any[]) => any,
  ): (...args: any[]) => any {
    const manager = new SwipeManager(
      element,
      eventName,
      handler,
      this.manager.getZone(),
      this.window,
    );
    return manager.destroy;
  }

  addGlobalEventListener(
    element: string,
    eventName: string,
    handler: (...args: any[]) => any,
  ): (...args: any[]) => any {
    let target: any;

    switch (element) {
      case 'document':
        target = this.doc;
        break;
      case 'window':
        target = this.window;
        break;
      case 'body':
        target = this.doc.body;
        break;
    }

    if (!target) {
      throw new Error(`Unsupported event target ${target} for event ${eventName}`);
    }
    return this.addEventListener(target, eventName, handler);
  }
}
