import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {I18nModule} from '../i18n/i18n.module';

import {GoogleButtonComponent} from './button/google-button.component';
import {GoogleAuthRestService} from './google-auth-rest.service';
import {GoogleAuthSdkService} from './google-auth-sdk.service';
import {GoogleAuthService} from './google-auth.service';
import {GoogleAuthServiceMockE2e} from './google-auth.service.mock.e2e';
import {GoogleLoginComponent} from './login/google-login.component';

@NgModule({
  imports: [I18nModule, CommonModule],
  exports: [GoogleButtonComponent, GoogleLoginComponent],
  declarations: [GoogleButtonComponent, GoogleLoginComponent],
})
export class GoogleModule {
  static forRoot(
    mockContacts = false,
    useGoogleSDK = false,
  ): ModuleWithProviders<GoogleModule> {
    return {
      ngModule: GoogleModule,
      providers: [
        useGoogleSDK
          ? {
              provide: GoogleAuthSdkService,
              useClass: mockContacts
                ? GoogleAuthServiceMockE2e
                : GoogleAuthSdkService,
            }
          : {
              provide: GoogleAuthRestService,
              useClass: mockContacts
                ? GoogleAuthServiceMockE2e
                : GoogleAuthRestService,
            },
        {
          provide: GoogleAuthService,
          useExisting: useGoogleSDK ? GoogleAuthSdkService : GoogleAuthRestService,
        },
      ],
    };
  }
}
