import {ValidatorFn} from '@angular/forms';

export abstract class BankAccountFormatter {
  /**
   * Length of groups the bank account is splitted.
   */
  fields: Array<number>;

  get accountParserFn(): (...args: any[]) => any {
    return null;
  }

  abstract isValid(account: string): boolean;

  abstract parse(account: string): Array<string>;

  abstract getValidator(): ValidatorFn;
}
