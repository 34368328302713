import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../../i18n/i18n.module';
import {SanitizerModule} from '../../sanitizer/sanitizer.module';
import {UtilModule} from '../../util/util-module';

import {StepFlowComponent} from './step-flow.component';
import {TranslatableTextModule} from '../../i18n/translatable-text/translatable-text.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    UtilModule,
    SanitizerModule,
    I18nModule,
    TranslatableTextModule,
  ],
  exports: [StepFlowComponent],
  declarations: [StepFlowComponent],
})
export class StepFlowModule {}
