import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {
  FormMessagesModule,
  I18nModule,
  ModalDialogModule,
  TriggerStyleModule,
  SanitizerModule,
  TranslatableTextModule,
  ResponsiveUtilsModule,
} from 'common';

import {VerificationDialogComponent} from './verification-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormMessagesModule,
    ModalDialogModule,
    ReactiveFormsModule,
    ResponsiveUtilsModule,
    TriggerStyleModule,
    I18nModule,
    SanitizerModule,
    TranslatableTextModule,
  ],
  exports: [VerificationDialogComponent],
  declarations: [VerificationDialogComponent],
})
export class VerificationDialogModule {}
