import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

import {InfoComponent} from './info.component';

@Component({
  selector: 'tl-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTooltipComponent extends InfoComponent {
  @Input()
  description: string;

  @Input()
  template: TemplateRef<any>;

  @Input()
  placement:
    | Array<'top' | 'bottom' | 'left' | 'right' | 'auto'>
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'auto' = ['top', 'auto'];

  @Input()
  icon: 'info' | 'question' = 'question';

  @ViewChild(NgbTooltip, {static: true})
  tooltip: NgbTooltip;

  @HostBinding('class.tl-info')
  readonly hostClass = true;

  private timeoutId: any;

  onOpen() {
    this.timeoutId = setTimeout(() => {
      this.tooltip.open();
      this.timeoutId = null;
    }, 500);
  }

  onClose() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.tooltip.close();
  }
}
