import {Injectable} from '@angular/core';
import {Logger, ResponsiveService, UriMap, UriMapResolver, UriUtils} from 'common';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {UpcomingGamesService} from '../upcoming/data/upcoming-games.service';
import {Slugger} from '../util/slugger';

@Injectable()
export class GameUriMapResolver implements UriMapResolver {
  constructor(
    private upcomingGamesService: UpcomingGamesService,
    private logger: Logger,
    private responsiveService: ResponsiveService,
  ) {}

  resolve(uriMap: UriMap, url: string): Observable<string> {
    let paramNames = UriUtils.getUrlParams(uriMap.originUrl);
    let paramValues = <RegExpExecArray>(
      UriUtils.urlMatches(uriMap.pattern, uriMap.originUrl, url)
    );
    let destinationUrl = UriUtils.getDestinationUrl(
      uriMap,
      this.responsiveService.getPlatform(),
    );
    let sorteoId = null;
    paramValues.slice(1).forEach((match, index) => {
      destinationUrl = destinationUrl.replace(paramNames[index], match);
      if (paramNames[index] === ':sorteoId') {
        sorteoId = +match;
      }
    });
    if (!sorteoId) {
      return of(null);
    }
    return this.upcomingGamesService.getGameByRaffleId(sorteoId).pipe(
      map(game => {
        if (!game) {
          return null;
        }
        destinationUrl = destinationUrl.replace(
          ':gameId',
          Slugger.toUrl(game.gameId),
        );
        return destinationUrl.replace(':raffleId', game.raffleId.toString());
      }),
      catchError(err => {
        this.logger.warn(
          `GameResolver: Error while trying to retrieve game info for raffleId: ${sorteoId}`,
        );
        return throwError(() => err);
      }),
    );
  }
}
