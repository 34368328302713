import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import {CustomError} from '../../errors/custom-error';

import {UnsupportedExtensionError} from './unsupported-extension-error';

@Directive({
  selector: 'input[type=file][tlUploadFile]',
})
export class UploadFileDirective {
  extensions: Array<string>;

  multiple = false;

  @Input()
  skipRead = false;

  @Output()
  rawFiles = new EventEmitter<File[]>();

  @Output()
  files = new EventEmitter<ProgressEvent | Array<ProgressEvent>>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  error = new EventEmitter<CustomError>();

  @Output()
  uploading = new EventEmitter<boolean>();

  constructor(private element: ElementRef) {
    this.multiple = this.element.nativeElement.hasAttribute('multiple');
  }

  /**
   * Allows values: 'jpg, png' or an array ['jpg', 'png']
   */
  @Input('extensions')
  set extensionsParam(ext: string | Array<string>) {
    this.extensions = Array.isArray(ext) ? ext : ext.split(',').map(e => e.trim());
  }

  @HostListener('change', ['$event'])
  fileChangeListener(changeEvent: Event): void {
    const filesEvent = (<any>changeEvent.target).files;
    if (!(filesEvent && filesEvent.length)) {
      return;
    }

    const files: Array<File> = this.multiple ? filesEvent : [filesEvent[0]];

    if (this.extensions) {
      const unsuppportedFile = files.find(
        f =>
          !this.extensions.find(e => f.name.toLowerCase().endsWith(e.toLowerCase())),
      );
      if (unsuppportedFile) {
        this.error.emit(new UnsupportedExtensionError(unsuppportedFile.name));
        return;
      }
    }

    if (this.skipRead) {
      this.rawFiles.emit(files);
      return;
    }

    this.uploading.emit(true);

    Promise.all(files.map(f => this.readFileAsync(f))).then(
      readFiles => {
        this.uploading.emit(false);

        if (this.multiple) {
          this.files.emit(readFiles);
        } else {
          this.files.emit(readFiles[0]);
        }
      },
      e => {
        this.uploading.emit(false);
        this.error.emit(e);
      },
    );
  }

  private readFileAsync(file: File): Promise<ProgressEvent> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onloadend = (loadEvent: ProgressEvent) => {
        resolve(loadEvent);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }
}
