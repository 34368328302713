import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';

import {LoadingComponent} from '../loading/view/loading.component';

@Directive({selector: 'img[tlImgLoading]'})
export class ImageLoadingDirective implements OnInit {
  @Input('tlImgLoadingHeight')
  height: string;

  @Input('tlImgLoadingHeightRatio')
  heightRatio: number;

  private image: HTMLImageElement;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private vcRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    const loaderFactory =
      this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
    let loadingComponentRef = this.vcRef.createComponent(loaderFactory);
    this.image = this.element.nativeElement;
    this.renderer.setStyle(
      loadingComponentRef.location.nativeElement,
      'position',
      'absolute',
    );
    this.renderer.setStyle(loadingComponentRef.location.nativeElement, 'top', '50%');
    this.renderer.setStyle(
      loadingComponentRef.location.nativeElement,
      'left',
      '50%',
    );
    this.renderer.setStyle(
      loadingComponentRef.location.nativeElement,
      'transform',
      'translate3d(-50%, -50%, 0)',
    );
    if (this.height || this.heightRatio) {
      this.renderer.setStyle(
        this.image,
        'height',
        this.height || this.image.width * this.heightRatio + 'px',
      );
    }
  }

  @HostListener('load')
  onLoad() {
    this.vcRef.clear();
    if (this.height || this.heightRatio) {
      this.renderer.setStyle(this.image, 'height', '');
    }
  }
}
