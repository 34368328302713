import {Injectable} from '@angular/core';
import {from, iif, Observable, of} from 'rxjs';
import {catchError, filter, finalize, map, switchMap} from 'rxjs/operators';

import {ModalHelperOptions} from '../../modal/modal-helper-options';
import {ModalHelperService} from '../../modal/modal-helper.service';
import {ResponsiveService} from '../../responsive/responsive.service';
import {MessageContext} from '../data/message-context';
import {MessageHookDataService} from '../data/message-hook-data.service';
import {MessageHookDisplayOptions} from '../message-hook-display-options';
import {MessageHookDisplayer} from '../message-hook-displayer';
import {MessageHookStorageService} from '../message-hook-storage.service';

import {Router} from '@angular/router';
import {UriMapper} from '../../uri/uri-mapper';
import {ModalDialogComponent} from '../../modal/modal-dialog/modal-dialog.component';
import {ModalDialogLayout} from '../../modal/modal-dialog/modal-dialog-layout';

@Injectable({providedIn: 'root'})
export class ModalMessageHookService extends MessageHookDisplayer {
  constructor(
    storage: MessageHookStorageService,
    private responsiveService: ResponsiveService,
    private messageHooksData: MessageHookDataService,
    private modalHelperService: ModalHelperService,
    private tuLoteroUriMapper: UriMapper,
    private router: Router,
  ) {
    super(storage);
  }

  display(id: MessageContext): Observable<boolean> {
    return this.messageHooksData
      .getMessage(id)
      .pipe(
        switchMap(message =>
          message
            ? this.displayCustomMessage(id, {data: {message: message}})
            : of(null),
        ),
      );
  }

  displayCustomMessage(
    id: string,
    options?: MessageHookDisplayOptions,
  ): Observable<boolean> {
    if (this.storage.exists(id)) {
      return of(false);
    }

    const {component, data} = options || {};

    let modalHelperOptions: ModalHelperOptions;

    let modalOptions = {
      centered: true,
      windowClass: this.responsiveService.isDesktop() ? 'wide sm' : '',
    };
    if (options?.modalOptions) {
      modalOptions = {
        ...modalOptions,
        ...options.modalOptions,
      };
    }

    if (modalOptions || data) {
      modalHelperOptions = {
        modalOptions: modalOptions,
        componentParams: component
          ? data
          : {
              title: options.data.message.title,
              message: options.data.message.content,
              image: options.data.message.image,
              layout: ModalDialogLayout.VERTICAL,
              type: 'ok',
              accept: options.data.message.okButtonText ?? {
                key: 'global.acceptButton',
              },
            },
      };
    }

    this.storage.save(id);

    const instance = this.modalHelperService.openOkModal(
      component ?? ModalDialogComponent,
      modalHelperOptions,
    );

    const url = options?.data?.message?.url;

    return from(instance).pipe(
      switchMap(() =>
        iif(
          () => !!url && this.tuLoteroUriMapper.isInternalRoute(url),
          this.redirectToUrl(url),
          of(null),
        ),
      ),
      map(() => true),
      finalize(() => {
        this.modalHelperService.closeLastModal();
      }),
    );
  }

  private redirectToUrl(url: string): Observable<boolean> {
    return this.tuLoteroUriMapper.map(url).pipe(
      catchError(() => of(this.responsiveService.isDesktop() ? '/' : '/m/')),
      filter(uri => !!uri),
      switchMap(uri => from(this.router.navigateByUrl(uri))),
    );
  }
}
