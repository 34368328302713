import {Pipe, PipeTransform} from '@angular/core';

/**
 * Util pipe to generate numbers inside ngFor directive
 * let value of 10 | arrayFromNumber
 */
@Pipe({name: 'arrayFromNumber'})
export class ArrayFromNumberPipe implements PipeTransform {
  transform(value: number, startIndex = 0, ascending = true): Array<number> {
    return Array(value)
      .fill(0)
      .map((v, i) => (ascending ? i + startIndex : startIndex - i));
  }
}
