import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PanelDropdownComponent} from './panel-dropdown.component';
import {I18nModule} from '../i18n/i18n.module';
import {TranslatableTextModule} from '../i18n/translatable-text/translatable-text.module';

@NgModule({
  declarations: [PanelDropdownComponent],
  exports: [PanelDropdownComponent],
  imports: [CommonModule, I18nModule, TranslatableTextModule],
})
export class PanelDropdownModule {}
