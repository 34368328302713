import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectorRef,
  KeyValueChanges,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {of} from 'rxjs';
import {tap} from 'rxjs/operators';

import {TranslationsService} from './translations.service';

@Pipe({name: 'i18nData', pure: false})
export class TranslableDataPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  private firstTransform: boolean;

  private cacheText: string;
  private cacheKey: string;
  private cacheData: {[key: string]: any} = {};

  private keyValueDiffer: KeyValueDiffer<string, any> | null;

  constructor(
    private cdr: ChangeDetectorRef,
    private keyValueDiffers: KeyValueDiffers,
    private translationService: TranslationsService,
  ) {
    this.asyncPipe = new AsyncPipe(this.cdr);
    this.keyValueDiffer = this.keyValueDiffers.find(this.cacheData).create();
    this.firstTransform = true;
  }

  transform(key: string, data: {[key: string]: any} = {}): string | null {
    const keyValueChanges: KeyValueChanges<string, string> =
      this.keyValueDiffer.diff(data);

    let observableText;
    if (this.cacheKey !== key || keyValueChanges || this.firstTransform) {
      this.cacheData = data;

      observableText = this.translationService
        .getCompiledTranslation(key, data)
        .pipe(
          tap((text: string) => {
            this.cacheText = text;
            this.firstTransform = false;
            this.cdr.markForCheck();
          }),
        );
    } else {
      observableText = of(this.cacheText);
    }

    this.cacheKey = key;
    return this.asyncPipe.transform(observableText);
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
