import {Injectable} from '@angular/core';
import {Observable, first, from, map, zip} from 'rxjs';

import {SAMSUNG_VENDOR_REGEX} from './samsung-regex';
import {DeviceOs} from './deviceOs';
import {DeviceUAParserService} from './device-ua-parser.service';
import {FingerprintStrategy} from './fingerprint/model';

@Injectable()
export class DeviceClientHintService extends DeviceUAParserService {
  private osCached: DeviceOs;
  private osVersionCached: string;

  constructor(
    protected fingerprint: FingerprintStrategy,
    protected parser: any,
    protected window: Window,
  ) {
    super(fingerprint, parser, window);
    this.setOS();
    this.setOSVersion();
  }

  getModel(): Observable<string> {
    return from(
      this.window.navigator['userAgentData'].getHighEntropyValues(['model']),
    ).pipe(
      // ua => NavigatorUAData
      map((ua: unknown) => ua['model']),
      first(),
    );
  }

  isSamsungDevice(): Observable<boolean> {
    return this.getModel().pipe(
      map((model: string) =>
        this.getSamsungRegex().some((r: RegExp) => r.test(model)),
      ),
      first(),
    );
  }

  getOS(): DeviceOs {
    return this.osCached;
  }

  getOSVersion(): string {
    return this.osVersionCached;
  }

  isAndroidVersion7OrLower(): Observable<boolean> {
    return zip([this.getOs$(), this.getOsVersion$()]).pipe(
      map(
        ([os, osVersion]) =>
          os === DeviceOs.ANDROID && osVersion && parseInt(osVersion, 10) <= 7,
      ),
    );
  }

  private setOS() {
    this.getOs$().subscribe(platform => {
      this.osCached = platform;
    });
  }

  private setOSVersion() {
    this.getOsVersion$().subscribe(version => {
      this.osVersionCached = version;
    });
  }

  private getSamsungRegex(): Array<RegExp> {
    return SAMSUNG_VENDOR_REGEX;
  }

  private getOs$(): Observable<DeviceOs> {
    return from(
      this.window.navigator['userAgentData'].getHighEntropyValues(['platform']),
    ).pipe(
      // ua => NavigatorUAData
      map((ua: unknown) => ua['platform']),
      map((deviceOs: string) =>
        deviceOs === 'macOS' ? DeviceOs.MACOS : (deviceOs as DeviceOs),
      ),
      first(),
    );
  }

  private getOsVersion$(): Observable<string> {
    return from(
      this.window.navigator['userAgentData'].getHighEntropyValues([
        'platformVersion',
      ]),
    ).pipe(
      // ua => NavigatorUAData
      map((ua: unknown) => ua['platformVersion']),
      first(),
    );
  }
}
