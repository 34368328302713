import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {
  FormMessagesModule,
  I18nModule,
  ModalDialogModule,
  PhoneUtilsModule,
  ResponsiveUtilsModule,
  TranslatableTextModule,
  TriggerStyleModule,
} from 'common';

import {CodeVerificationDialogComponent} from './code-verification-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormMessagesModule,
    ModalDialogModule,
    ReactiveFormsModule,
    ResponsiveUtilsModule,
    TriggerStyleModule,
    I18nModule,
    PhoneUtilsModule,
    TranslatableTextModule,
  ],
  exports: [CodeVerificationDialogComponent],
  declarations: [CodeVerificationDialogComponent],
})
export class CodeVerificationDialogModule {}
