import {Serializable, SerializableProperty} from 'common';

import {CombinationType} from './combination-type';
import {CombinationValue} from './combination-value';

class BooleanArrayCombinationTypeInternal
  implements CombinationType<CombinationValue>
{
  typeId: string;

  type: string;

  @SerializableProperty(CombinationValue)
  value: Array<CombinationValue>;
}

export class BooleanArrayCombinationType extends Serializable(
  BooleanArrayCombinationTypeInternal,
) {
  static createFromBackend(obj: any) {
    let booleanCT = new BooleanArrayCombinationType();

    booleanCT.typeId = obj.typeId;
    booleanCT.type = obj.type;
    booleanCT.value = obj.value.map(v => {
      if (typeof v === 'boolean') {
        let combinationValue = new CombinationValue();
        combinationValue.value = (v as boolean).toString();
        return combinationValue;
      } else {
        return CombinationValue.fromJSON(v);
      }
    });
    return booleanCT;
  }
}
