import {Component} from '@angular/core';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent {}
