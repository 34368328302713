export class GoogleError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, GoogleError.prototype);
  }
}

export class GoogleAuthError extends GoogleError {}

export class GoogleAuthorizePermissionsError extends GoogleError {}
