export enum GameId {
  LOTERIA_NACIONAL = 'LOTERIA_NACIONAL',
  BONOLOTO = 'BONOLOTO',
  EURODREAMS = 'EURODREAMS',
  QUINIELA = 'QUINIELA',
  CRUZ_ROJA = 'CRUZ_ROJA',
  EUROMILLONES = 'EUROMILLONES',
  PRIMITIVA = 'PRIMITIVA',
  GORDO_PRIMITIVA = 'GORDO_PRIMITIVA',
  LOTARIA_CLASSICA = 'LOTARIA_CLASSICA',
  LOTARIA_POPULAR = 'LOTARIA_POPULAR',
  TOTOBOLA = 'TOTOBOLA',
  TOTOBOLA_EXTRA = 'TOTOBOLA_EXTRA',
}
