import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractObservableDataService, switchMapArray} from 'common';

import {MessageBanner} from './message-banner';
import {MessageBannerByState} from './message-banner-by-state';
import {Observable, switchMap} from 'rxjs';
import {BannerProcessDataService} from './banner-process-data.service';

@Injectable({providedIn: 'root'})
export class BannerDataService extends AbstractObservableDataService<
  Array<MessageBanner | MessageBannerByState>
> {
  constructor(
    @Inject(BannerProcessDataService)
    @Optional()
    private processDataServices: Array<BannerProcessDataService>,
  ) {
    super();
  }

  getData(): Observable<Array<MessageBanner | MessageBannerByState>> {
    const processData: Array<BannerProcessDataService> = this.processDataServices;
    return super
      .getData()
      .pipe(
        switchMap((data: Array<MessageBanner | MessageBannerByState>) =>
          switchMapArray(data, processData, 'processList'),
        ),
      );
  }
}
