import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputPasswordValidationsComponent} from './input-password-validations.component';
import {FormMessagesModule} from '../messages/form-messages.module';
import {I18nModule} from '../../i18n/i18n.module';

@NgModule({
  declarations: [InputPasswordValidationsComponent],
  exports: [InputPasswordValidationsComponent],
  imports: [CommonModule, I18nModule, FormMessagesModule],
})
export class InputPasswordValidationsModule {}
