import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';

export type NavigationInstant = number;

@Injectable({providedIn: 'root'})
export class RouterNavigationCheckerService {
  private navigationInstant: NavigationInstant;

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        map(() => Date.now()),
      )
      .subscribe(instant => (this.navigationInstant = instant));
  }

  lastNavigationInstant(): NavigationInstant {
    return this.navigationInstant;
  }

  hasNavigatedSince(navigationTime: NavigationInstant): boolean {
    return this.navigationInstant !== navigationTime;
  }
}
