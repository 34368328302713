import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ComponentInjectorService {
  constructor(
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  getRootViewContainer(): ComponentRef<any> {
    if (this.applicationRef.components.length) {
      return this.applicationRef.components[0];
    }

    throw new Error('View Container not found!');
  }

  getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
    return componentRef.location.nativeElement;
  }

  getRootViewContainerNode(): HTMLElement {
    return this.getComponentRootNode(this.getRootViewContainer());
  }

  projectComponentInputs(
    component: ComponentRef<any>,
    options: any,
  ): ComponentRef<any> {
    if (options) {
      const props = Object.getOwnPropertyNames(options);
      for (const prop of props) {
        component.instance[prop] = options[prop];
      }
    }

    return component;
  }

  appendComponent<T>(
    componentClass: Type<T>,
    options: any = {},
    location: Element = this.getRootViewContainerNode(),
  ): ComponentRef<any> {
    let componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(componentClass);
    let componentRef = componentFactory.create(this.injector);
    let componentRootNode = this.getComponentRootNode(componentRef);

    this.projectComponentInputs(componentRef, options);

    this.applicationRef.attachView(componentRef.hostView);

    location.appendChild(componentRootNode);

    return componentRef;
  }

  removeComponent<T>(componentRef: ComponentRef<T>) {
    componentRef.destroy();
  }
}
