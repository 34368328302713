import {Serializable, SerializableProperty} from 'common';
import {PaymentMethodConfig} from './payment-method-config';

import {PaymentMethodId} from './payment-method-id';
import {PaymentPostMethodType} from './payment-method-post-type';
import {environment} from '~environments/environment';
import {PaymentMethodUIMetadata} from './payment-method-ui-metadata';

class PaymentMethodInternal {
  id: PaymentMethodId;

  postMethod: PaymentPostMethodType;

  config: PaymentMethodConfig;

  @SerializableProperty(PaymentMethodUIMetadata)
  ui: PaymentMethodUIMetadata;
}

export class PaymentMethod extends Serializable(PaymentMethodInternal) {
  static createFromBackend(obj: any, creditObj?: any): PaymentMethod {
    let paymentMethod: PaymentMethod = new PaymentMethod();
    // TODO Remove environment.id === 'pt' when bizum refactor is implemented
    if (creditObj && environment.id === 'pt') {
      paymentMethod.id = PaymentMethodId.MB_WAY;
      paymentMethod.postMethod = PaymentPostMethodType.EXTERNAL;
      // TODO change this when https://tulotero.atlassian.net/browse/WEB-2117 is done
      const config: PaymentMethodConfig = new PaymentMethodConfig();
      config.allowLoad = true;
      config.allowWithdraw = false;
      config.min = creditObj.phone.minAmount;
      config.max = creditObj.phone.maxAmount;
      config.multiple = creditObj.phone.multAmount;
      config.autocredit = creditObj.phone.autoCredit;
      paymentMethod.config = config;
      if (paymentMethod.id === PaymentMethodId.MB_WAY) {
        paymentMethod.ui = new PaymentMethodUIMetadata(
          'icon-mbway-logo',
          'icon-mbway-logo-with-text',
        );
      }
    } else {
      paymentMethod.id = obj.id;
      paymentMethod.postMethod = obj.postMethod;
      paymentMethod.config = PaymentMethodConfig.createFromBackend(obj.config);
    }

    return paymentMethod;
  }
}
