import {Pipe, PipeTransform} from '@angular/core';

import {LocaleService} from '../i18n/locale.service';

@Pipe({
  name: 'ordinalSuffix',
})
export class OrdinalSuffixPipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(value: number): string {
    return this.localeService.getLocale().split('-')[0] !== 'en'
      ? `${value}º`
      : value % 10 === 1 && value !== 11
      ? `${value}st`
      : value % 10 === 2 && value !== 12
      ? `${value}nd`
      : value % 10 === 3 && value !== 13
      ? `${value}rd`
      : `${value}th`;
  }
}
