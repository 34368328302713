import {environment} from '~environments/environment';
import {MoneyActivityGroup} from './money-activity-group';

export function mergeMoneyActivitiesGroup(
  dest: Array<MoneyActivityGroup>,
  source: Array<MoneyActivityGroup>,
): Array<MoneyActivityGroup> {
  source.reverse().forEach((activity: MoneyActivityGroup) => {
    let index = dest.findIndex(m => m.id === activity.id);
    if (index < 0) {
      dest.unshift(activity);
    } else {
      dest[index] = activity;
    }
  });

  return [...dest];
}

export function reduceMoneyActivitiesGroup(
  moneyActivities: Array<MoneyActivityGroup>,
): Array<MoneyActivityGroup> {
  const maxMoneyActivities = environment.allInfo.reduce.maxMoneyActivies;

  if (moneyActivities && moneyActivities.length > maxMoneyActivities) {
    moneyActivities.splice(maxMoneyActivities, moneyActivities.length);
  }

  return [...moneyActivities];
}
