<tl-modal-dialog type="buttonless" class="tl-verification-dialog__modal">
  <div class="tl-verification-dialog__wrapper">
    <div *tlIfDesktop class="tl-verification-dialog__img-wrap">
      <img class="tl-verification-dialog__image--desktop" src="{{ image }}" />
    </div>

    <div class="tl-verification-dialog__content">
      <tl-scrollable class="tl-verification-dialog__scroll">
        <img
          *tlIfMobile
          class="tl-verification-dialog__image--mobile"
          src="{{ image }}" />

        <p class="dark-default tl-verification-dialog__title">
          <tl-translatable-text [text]="title"></tl-translatable-text>
        </p>
        <p
          *ngIf="info.maxAttemptsReached && isDeviceBlocked"
          class="tl-verification-dialog__description--timer">
          <tl-translatable-text [text]="errorTimer"></tl-translatable-text>
        </p>
        <p *ngIf="showDescriptionText" class="tl-verification-dialog__description">
          <span *ngIf="!displayContactLink(); else contactLink">
            <tl-translatable-text [text]="descriptionText"></tl-translatable-text>
          </span>
        </p>

        <div *ngIf="showForm; else smsInformation">
          <div class="form-group tl-verification-dialog__form">
            <input
              class="form-control tl-verification-dialog__input"
              [class.input-xs]="!isMobile"
              [ngClass]="
                inputError || (form.invalid && form.touched && submitted)
                  ? 'tl-verification-dialog__input--error'
                  : ''
              "
              type="text"
              tlTriggerOnKeyboardOpen
              [formControl]="form"
              [placeholder]="'userProfile.verifyPhone.hintCode' | i18n"
              inputmode="numeric"
              (keydown)="cleanErrors()"
              (keyup)="validateInput($event)"
              (paste)="pasteNumbers($event)"
              maxlength="5"
              id="code-input" />
            <div
              class="tl-verification-dialog__error-area"
              [tlFormMessages]="form"
              [submitted]="submitted">
              <div tlFormMessage="required">
                {{ 'userProfile.verifyPhone.errorSmsCodeEmpty' | i18n }}
              </div>
              <div tlFormMessage="minlength">
                {{ 'userProfile.verifyPhone.verificationLenghtCodeError' | i18n }}
              </div>
              <div tlFormMessage="maxlength">
                {{ 'userProfile.verifyPhone.verificationLenghtCodeError' | i18n }}
              </div>
              <div *ngIf="wrongCode | async as errorMsg">
                <tl-translatable-text [text]="errorMsg"></tl-translatable-text>
              </div>
            </div>
          </div>
        </div>
        <ng-template #smsInformation>
          <p
            *ngIf="showInformation"
            class="tl-verification-dialog__description--information">
            <tl-translatable-text
              [text]="{
                key: 'userProfile.verifyPhone.dialogDescriptionBrowser',
                keyData: {phone}
              }"></tl-translatable-text>
          </p>
        </ng-template>
        <div class="tl-verification-dialog__buttons">
          <ng-container *ngIf="enableButton; else showTimer">
            <button
              *ngIf="!isLoading"
              class="btn tl-verification-dialog__button tl-verification-dialog__button--accept"
              [disabled]="disableButton"
              [ngClass]="{
                'btn-mobile-round': isMobile,
                'btn-mobile-accept': isMobile,
                'btn-type-4': !isMobile,
                accept: !isMobile
              }"
              (click)="buttonCallBack()">
              <tl-translatable-text [text]="sendButtonText"></tl-translatable-text>
            </button>
          </ng-container>
          <ng-template #showTimer>
            <div class="tl-verification-dialog__timer">
              <tl-translatable-text [text]="errorTimer"></tl-translatable-text>
            </div>
          </ng-template>
          <button
            *ngIf="hasActiveCode"
            class="btn tl-verification-dialog__button tl-verification-dialog__button--hasCode"
            [ngClass]="{
              'btn-type-1': !isMobile
            }"
            (click)="goInsertCode()">
            <tl-translatable-text
              [text]="{
                key: 'userProfile.verifyPhone.hasCode'
              }"></tl-translatable-text>
          </button>
          <div *ngIf="isLoading" class="tl-verification-dialog__loading">
            <i class="icon-spin6-icon tl-icon-spin"></i>
          </div>
          <div
            *ngIf="showForm"
            class="tl-verification-dialog__description--request-code"
            [ngClass]="{
              'tl-verification-dialog__description--request-code--disabled':
                isLoading
            }">
            <!-- Request new sms -->
            <tl-translatable-text
              [text]="{
                key: 'userProfile.verifyPhone.codeNotReceived'
              }">
            </tl-translatable-text>
            <a (click)="goInitialScreen()" class="tl-verification-dialog__link">
              <tl-translatable-text
                [text]="{
                  key: 'userProfile.verifyPhone.requestCode'
                }"></tl-translatable-text>
            </a>
          </div>
        </div>
      </tl-scrollable>
    </div>
  </div>
</tl-modal-dialog>
<ng-template #contactLink>
  <span (click)="goToContact()" class="tl-verification-dialog__contact">
    <tl-translatable-text [text]="descriptionText"></tl-translatable-text>
  </span>
</ng-template>
