import {
  animate,
  animation,
  AnimationReferenceMetadata,
  style,
} from '@angular/animations';

export let fadeIn: AnimationReferenceMetadata = animation(
  [
    style({opacity: '{{start}}'}),
    animate('{{duration}}', style({opacity: '{{end}}'})),
  ],
  {params: {start: 0, end: 1}},
);

export let fadeOut: AnimationReferenceMetadata = animation(
  [animate('{{duration}}', style({opacity: '{{end}}'}))],
  {params: {duration: 500, end: 0}},
);
