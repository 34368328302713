import {FormControl, FormGroup, Validators} from '@angular/forms';

import {BackendTransformable} from '../../../backend/util/backend-transformable';
import {TlContact} from '../../../contacts/data/tl-contact';
import {Group} from '../../groups/data/group';

export abstract class ShareItemFormGroup
  extends FormGroup
  implements BackendTransformable
{
  protected constructor(
    shareItem: TlContact | Group,
    props?: {[key: string]: FormControl},
  ) {
    super(
      Object.assign(
        {
          share: new FormControl(shareItem, Validators.required),
        },
        props,
      ),
    );
  }

  abstract toBackend(price?: number): Record<string, unknown>;

  reset(
    defaults?: Record<string, unknown>,
    value?: Record<string, unknown>,
    options?: {onlySelf?: boolean; emitEvent?: boolean},
  ): void {
    if (!value.hasOwnProperty('share')) {
      throw new Error('ShareItemFormGroup: ShareItem is required to reset.');
    }
    super.reset(Object.assign({share: null}, defaults, value), options);
  }

  getShare(): TlContact | Group {
    return this.controls['share'].value;
  }
}
