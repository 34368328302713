import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'keyValue'})
export class KeyValuePipe implements PipeTransform {
  transform(value: any): any {
    let pairs = [];

    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        pairs.push({key: key, value: value[key]});
      }
    }

    return pairs;
  }
}
