import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputPasswordComponent} from './input-password.component';
import {TriggerStyleModule} from '../../styles-triggers/trigger-style.module';

@NgModule({
  declarations: [InputPasswordComponent],
  imports: [CommonModule, TriggerStyleModule],
  exports: [InputPasswordComponent],
})
export class InputPasswordModule {}
