import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {Observable, Subject, timer} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

import {TimestampToTimePipe} from '../date/pipes/timestamp-to-time.pipe';
import {Destroyable} from '../util/destroyable';

@Component({
  selector: 'tl-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TimestampToTimePipe],
})
export class ClockComponent implements OnInit {
  clock$: Observable<string>;

  @HostBinding('class.tl-clock')
  readonly hostClass = true;

  clockSource: Subject<string> = new Subject<string>();

  @Destroyable()
  private destroy = new Subject();

  constructor(private timestampToTimePipe: TimestampToTimePipe) {}

  ngOnInit(): void {
    this.clock$ = this.clockSource.asObservable().pipe(distinctUntilChanged());
    timer(0, 1000)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        const time = this.timestampToTimePipe.transform(new Date().getTime());
        this.clockSource.next(time);
      });
  }
}
