import {MonoTypeOperatorFunction, Observable, pipe} from 'rxjs';
import {concatMap, filter, map, switchMap} from 'rxjs/operators';

export function switchFilter<T>(
  filterFunc: (value: T, index: number) => Observable<boolean>,
): MonoTypeOperatorFunction<T> {
  return pipe(
    switchMap((data: T, index: number) =>
      filterFunc(data, index).pipe(map(valid => ({valid, data}))),
    ),
    filter(({valid, data}) => valid),
    map(({valid, data}) => data),
  );
}

export function asyncFilter<T>(
  filterFunc: (value: T, index: number) => Observable<boolean>,
): MonoTypeOperatorFunction<T> {
  return pipe(
    concatMap((data: T, index: number) =>
      filterFunc(data, index).pipe(map(valid => ({valid, data}))),
    ),
    filter(({valid, data}) => valid),
    map(({valid, data}) => data),
  );
}
