import {Serializable, SerializableProperty, SerializableType} from 'common';
import {environment} from '~environments/environment';

class CreditCardAmountIncrementInternal {
  from: number;

  to: number;

  increment: number;
}

export class CreditCardAmountIncrement extends Serializable(
  CreditCardAmountIncrementInternal,
) {
  static createFromBackend(obj: any): CreditCardAmountIncrement {
    let inc: CreditCardAmountIncrement = new CreditCardAmountIncrement();
    inc.from = obj?.from;
    inc.to = obj?.to;
    inc.increment = obj?.increment;
    return inc;
  }
}
class CreditCardOptionsInternal {
  /**
   * Allows system to make top-ups automatically
   */
  autoCredit: boolean;

  tpvType: string;

  defaultAmount: number;

  minAmount: number;

  multAmount: number;

  @SerializableProperty(CreditCardAmountIncrement, SerializableType.COLLECTION)
  increments: Array<CreditCardAmountIncrement>;

  allowSave: boolean;

  creditNativeUrl: string;

  numMaxCardsToSave: number;

  allowManualInput: boolean;

  showDecimals: boolean;
}

export class CreditCardOptions extends Serializable(CreditCardOptionsInternal) {
  public static createFromBackend(obj: any): any {
    let j = new CreditCardOptions();

    j.autoCredit = obj.automaticCredit ?? obj.autoCredit;
    j.tpvType = obj.creditNative ? 'internal' : 'external';
    j.defaultAmount = obj.minAmount;
    j.increments =
      obj?.incrementRanges &&
      obj.incrementRanges.map(CreditCardAmountIncrement.createFromBackend);
    const overwriteMinAmount = environment.money.loadMin;
    j.minAmount = !!overwriteMinAmount
      ? overwriteMinAmount
      : j.increments && j.increments.length > 1
      ? obj.minAmountAllowed
      : obj.minAmount;
    j.multAmount = obj.multAmount;
    j.allowSave = obj.saveCards;
    j.creditNativeUrl = obj.creditNativeUrl;
    j.numMaxCardsToSave = obj.numMaxCardsToSave;
    j.allowManualInput =
      obj.allowEditManuallyAmount ?? environment.money.amountLoad.allowInputManual;
    j.showDecimals = obj.allowDecimals ?? environment.money.amountLoad.showDecimals;

    return j;
  }
}
