export function provideClass(type: any, provider: any) {
  return {provide: type, useClass: provider};
}

export function provideInstance(type: any, provider: any) {
  return {provide: type, useExisting: provider};
}

export function provideValue(type: any, value: any) {
  return {provide: type, useValue: value};
}
