import {Serializable, SerializableProperty} from 'common';

import {CombinationType} from './combination-type';
import {CombinationValue} from './combination-value';

/**
 * A string value, for example the million code from euromillones.
 * ATM only used for visualization, comes with the ticket
 */
class AlphanumericCombinationTypeInternal
  implements CombinationType<CombinationValue>
{
  typeId: string;

  type: string;

  @SerializableProperty(CombinationValue)
  value: Array<CombinationValue>;
}

export class AlphanumericCombinationType extends Serializable(
  AlphanumericCombinationTypeInternal,
) {
  static createFromBackend(obj: any) {
    let alphanumericCT = new AlphanumericCombinationType();

    alphanumericCT.typeId = obj.typeId;
    alphanumericCT.type = obj.type;

    alphanumericCT.value = obj.value.map(v => {
      if (obj.type === 'ALPHANUMERIC_EXTENDED') {
        return CombinationValue.fromJSON({
          value: v.value,
          highlight: !!v.highlighted,
        });
      } else {
        return CombinationValue.fromJSON({value: '' + v, highlight: false});
      }
    });

    return alphanumericCT;
  }
}
