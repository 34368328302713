import {Serializable} from 'common';

export class MessageBannerInternal {
  id: string;

  text: string;

  url: string;

  /**
   * Check if the current banner is more recent than a given id.
   *
   * @param id Another banner id
   *
   * @example Current class id= 50 and external id= 48 will return true
   */
  isMoreRecentThan(id: string | null): boolean {
    if (!id) {
      return true;
    }
    return parseInt(this.id, 10) > parseInt(id, 10);
  }
}

export class MessageBanner extends Serializable(MessageBannerInternal) {
  static createFromBackend(obj: any): MessageBanner {
    let messageBanner = new MessageBanner();

    messageBanner.id = obj.id;
    messageBanner.text = obj.texto;
    messageBanner.url = obj.url;

    return messageBanner;
  }
}
