<tl-modal-dialog
  type="ok_only"
  [accept]="{key: 'kyc.kycOk.accept'}"
  class="tl-kyc-ok-dialog__modal-dialog"
  [closeOnPopstate]="true">
  <div class="tl-kyc-ok-dialog__content-wrapper">
    <div *tlIfDesktop class="tl-kyc-ok-dialog__desktop-image-container">
      <img
        class="tl-kyc-ok-dialog__desktop-image"
        src="/assets/img/kyc/kyc-ok.png" />
    </div>

    <tl-scrollable class="tl-kyc-ok-dialog__scroll">
      <img class="tl-kyc-ok-dialog__header-image" src="/assets/img/kyc/kyc-ok.png" />

      <h1 class="tl-kyc-ok-dialog__title">
        <b>{{ 'kyc.kycOk.title' | i18n }}</b>
      </h1>

      <p
        class="tl-kyc-ok-dialog__text"
        *tlIfEnvironment="{path: 'id', value: 'us'}; else defaultContent">
        {{ 'kyc.kycOk.contentUsa' | i18n }}
      </p>
      <ng-template #defaultContent>
        <p class="tl-kyc-ok-dialog__text">
          {{ 'kyc.kycOk.content' | i18n }}
        </p>
      </ng-template>
    </tl-scrollable>
  </div>
</tl-modal-dialog>
