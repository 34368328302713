import {SerializableProperty, SerializableType} from 'common';

import {GroupHistoryEntry} from './group-history-entry';
import {UserGroupHistory} from './user-group-history';

export class UserGroupHistoryEntry extends GroupHistoryEntry {
  @SerializableProperty(UserGroupHistory, SerializableType.OBJECT)
  user: UserGroupHistory;

  static createFromBackend(obj: any): UserGroupHistoryEntry {
    let u = new UserGroupHistoryEntry();

    GroupHistoryEntry.fillFromBackend(u, obj);

    u.user = UserGroupHistory.createFromBackend(obj.memberRequest);

    return u;
  }
}
