import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {Breakpoint} from '../responsive/model';
import {ResponsiveService} from '../responsive/responsive.service';

import {getDocHeight} from '../util/browser/dom-utils';

@Directive({
  selector: 'iframe[srcdoc][tlIframeAutoHeight]',
  exportAs: 'iframeAutoHeight',
})
export class IframeAutoHeightDirective {
  height = 0;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
  ) {}

  @HostListener('load')
  onLoad(): void {
    this.height = 0; // Reset height when change src, navigating between tickets
    // Fix no iframe content loaded event
    if (this.element.nativeElement.contentWindow.document.body) {
      this.element.nativeElement.contentWindow.document.body.style.overflow =
        'hidden';
      this.element.nativeElement.style.height =
        this.element.nativeElement.contentWindow.document.body.scrollHeight + 'px';
    }
    this.adjustHeight();
  }

  adjustHeight(): void {
    let doc = this.element.nativeElement.contentWindow.document;
    if (doc && doc.body) {
      const zoom =
        this.responsiveService.isDesktop() &&
        this.responsiveService.getCurrentScreenSize() < Breakpoint.EXTRA_LARGE
          ? 0.85
          : 1;

      this.height = getDocHeight(doc);
      this.height = Math.max(this.height, getDocHeight(doc) / zoom);

      this.renderer.setStyle(
        this.element.nativeElement,
        'height',
        `${this.height}px`,
      );
    }
  }
}
