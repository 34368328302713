import {Serializable, SerializableDisableWarning} from 'common';

class BetValueInternal {
  typeId: string;

  value: string | number | boolean | Array<string> | Array<number> | Array<boolean>;
}

export class BetValue extends Serializable(BetValueInternal) {
  static createFromBackend(obj: any): BetValue {
    const betValue = new BetValue();
    betValue.typeId = obj.typeId;
    betValue.value = obj.value;

    return betValue;
  }
}

class DigitBetValueInternal extends BetValue {
  typeId: string;

  @SerializableDisableWarning()
  digitGroups: Array<number>;
}

export class DigitBetValue extends Serializable(DigitBetValueInternal) {
  static createFromBackend(obj: any): DigitBetValue {
    const dbv = new DigitBetValue();
    dbv.typeId = obj.typeId;
    dbv.digitGroups = obj.digitGroups.clone().sort().reverse();

    return dbv;
  }
}
