<div class="tl-google-map-controls__close" #close (click)="onClose()">&times;</div>

<div class="tl-google-map-controls__controls" #controls>
  <div class="tl-google-map-controls__zoom">
    <div class="tl-google-map-controls__zoom-out" (click)="zoomOut()">&minus;</div>
    <div class="tl-google-map-controls__zoom-in" (click)="zoomIn()">+</div>
  </div>

  <div
    *ngIf="showFullscreen && !isFullscreen"
    class="tl-google-map-controls__fullscreen"
    (click)="onFullscreen()">
    <i class="icon-fullscreen-icon"></i>
  </div>

  <div
    *ngIf="showRequestLocation"
    class="tl-google-map-controls__location"
    (click)="requestLocation.emit()">
    <i class="icon-cursor-right-icon"></i>
  </div>
</div>
