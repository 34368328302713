<div class="tl-panel-dropdown__header" (click)="click()">
  <div
    class="tl-panel-dropdown__header-text"
    [ngClass]="{'tl-panel-dropdown__header-text--open': !closed}">
    <tl-translatable-text [text]="title"></tl-translatable-text>
  </div>
  <div class="tl-panel-dropdown__header-icon">
    <i [ngClass]="closed ? 'icon-down-open-icon' : 'icon-up-open-icon'"></i>
  </div>
</div>
<div *ngIf="!closed" class="tl-panel-dropdown__body">
  <ng-content></ng-content>
</div>
