import {AbstractControl, ValidatorFn} from '@angular/forms';

import {isNumeric} from '../../../util/core/number';
import {IBAN_CODE_LENGTHS, isValidIBANNumber, parseIBAN} from '../../IBAN';
import {BankAccountFormatter} from '../bank-account-formatter';

export class IbanFormatter extends BankAccountFormatter {
  /**
   * Array of field lengths, last field is dynamic for IBAN when
   * a custom length is not defined.
   */
  override fields = [4, 4, 4, 2, 10];
  private readonly hasDynamicField: boolean = true;

  constructor(customFields: number[] = undefined) {
    super();
    if (customFields && customFields.length > 0) {
      this.fields = customFields;
      this.hasDynamicField = false;
    }
  }

  parse(account: string): Array<string> {
    this.checkLength(account);
    const accountLength = this.fields.reduce((a, b) => a + b);

    if (account && account.length === accountLength) {
      return this.fields.map((field, index) => {
        const start = this.fields.slice(0, index).reduce((a, b) => a + b, 0);
        return account.substring(start, start + field);
      });
    }

    return [];
  }

  getValidator(): ValidatorFn {
    return (c: AbstractControl) => {
      this.checkLength(c.value);
      return !this.isValid(c.value) ? {format: true} : null;
    };
  }

  isValid(account: string): boolean {
    return isValidIBANNumber(account);
  }

  get accountParserFn(): (...args: any[]) => any {
    return parseIBAN;
  }

  /**
   * Check iban code length, and change last field from current account iban
   * code.
   *
   * @param account Bank account
   */
  private checkLength(account: string) {
    if (account && account.length > 2) {
      const len = IBAN_CODE_LENGTHS[account.substring(0, 2)];
      const lenForDynamicField = this.hasDynamicField
        ? len - this.fields.slice(0, -1).reduce((a, b) => a + b)
        : this.fields[this.fields.length - 1];

      this.fields[this.fields.length - 1] = isNumeric(len) ? lenForDynamicField : 10;
    }
  }
}
