import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ActivatedRouteService {
  private routeSubject: Subject<ActivatedRoute>;

  get route(): Observable<ActivatedRoute> {
    return this.routeSubject.asObservable();
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.routeSubject = new Subject<ActivatedRoute>();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
      )
      .subscribe(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.routeSubject.next(route);
      });
  }
}
