import {Pipe, PipeTransform} from '@angular/core';

import {ResponsiveService} from './responsive.service';

/**
 * Fields that can have different values for mobile/desktop should have the same
 * name and the prefix 'desktop' in that case for this to work.
 */
@Pipe({name: 'platformMetadata'})
export class PlatformMetadataPipe implements PipeTransform {
  constructor(private responsiveService: ResponsiveService) {}

  transform(metadata: Record<string, any>, name: string, defaultValue: any): any {
    if (!metadata || !name) {
      return defaultValue;
    }

    const foundValue = this.responsiveService.isDesktop()
      ? metadata['desktop' + name.capitalize(true)]
      : metadata[name];

    return foundValue ?? defaultValue;
  }
}
