<div class="switch-wrapper">
  <input
    type="checkbox"
    id="{{ id }}"
    class="switch"
    [checked]="model"
    (change)="onToggle()" />
  <label for="{{ id }}" class="checkbox-label" [class.checked]="model">
    <span class="knob">
      <i *ngIf="icon" id="knob-icon" [class]="icon"></i>
    </span>
  </label>
</div>
