import {Injectable, Injector} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import {firstValueFrom, Observable} from 'rxjs';
import {first} from 'rxjs/operators';

@Injectable()
export class MasterGuard
  implements CanActivate, CanActivateChild, CanDeactivate<any>, CanLoad
{
  constructor(private injector: Injector) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    return this.combineGuards(route.data.canActivateGuards, 'canActivate', [
      route,
      state,
    ]);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    return this.combineGuards(
      childRoute.data.canActivateChildGuards,
      'canActivateChild',
      [childRoute, state],
    );
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    return this.combineGuards(
      currentRoute.data.canDeactivateGuards,
      'canDeactivate',
      [component, currentRoute, currentState, nextState],
    );
  }

  canLoad(route: Route, segments: Array<UrlSegment>): Promise<boolean> {
    return <Promise<boolean>>(
      this.combineGuards(route.data.canLoadGuards, 'canLoad', [route, segments])
    );
  }

  private async combineGuards(
    guards = [],
    activateMethod: string,
    activateMethodParams: Array<any>,
  ): Promise<boolean | UrlTree> {
    for (let guard of guards) {
      const instance = this.injector.get<
        CanActivate | CanActivateChild | CanDeactivate<any> | CanLoad
      >(guard);
      let result = await instance[activateMethod](...activateMethodParams);

      if (result instanceof Observable) {
        result = await firstValueFrom(result.pipe(first()));
      }

      if (result === false || result instanceof UrlTree) {
        return result;
      }
    }
    return true;
  }
}
