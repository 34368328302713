import {NgModule} from '@angular/core';

import {SelectItemComponent} from './select-item.component';
import {SelectComponent} from './select.component';

@NgModule({
  declarations: [SelectComponent, SelectItemComponent],
  exports: [SelectComponent, SelectItemComponent],
})
export class SelectTlModule {}
