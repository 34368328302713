import {Inject, Injectable} from '@angular/core';
import {AbstractObservableDataService, ENVIRONMENT, LocalStorage} from 'common';
import {NotificationLastStateSelected} from './notification-last-state-selected';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line max-len
export class StateNotificationsDataService extends AbstractObservableDataService<
  Array<NotificationLastStateSelected>
> {
  constructor(
    private localStorage: LocalStorage,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {
    super();
  }

  getData(): Observable<Array<NotificationLastStateSelected>> {
    return super.getData().pipe(startWith(this.getDataFromStorage()));
  }

  setData(data: Array<NotificationLastStateSelected>) {
    super.setData(data);
    this.localStorage.setItem(
      this.environment.localStorageKeys.selectedNotificationState,
      JSON.stringify(data),
    );
  }

  private getDataFromStorage(): Array<NotificationLastStateSelected> {
    return JSON.parse(
      this.localStorage.getItem(
        this.environment.localStorageKeys.selectedNotificationState,
      ),
    ) as Array<NotificationLastStateSelected>;
  }
}
