import {Pipe, PipeTransform} from '@angular/core';

import {formatDate} from '../date-fns-wrapper';

@Pipe({name: 'timestampToDateTime'})
export class TimestampToDateTimePipe implements PipeTransform {
  transform(value: number): any {
    return formatDate(value, 'dd/MM/yyyy - HH:mm:ss z');
  }
}
