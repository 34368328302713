export function createWorker(worker: (...args: any[]) => any | string): Worker {
  if (typeof worker === 'string') {
    return new Worker(worker);
  }

  const blob = new Blob(['self.onmessage = ', worker.toString()], {
    type: 'text/javascript',
  });
  const url = URL.createObjectURL(blob);

  return new Worker(url);
}

export function createStaticWorker(fn: (...args: any[]) => any): Worker {
  const blob = new Blob(
    [
      'self.cb = ',
      fn.toString(),
      ';',
      'self.onmessage = function (e) { self.postMessage(self.cb(e.data)) }',
    ],
    {type: 'text/javascript'},
  );

  const url = URL.createObjectURL(blob);

  return new Worker(url);
}

export function createWorkerPostMessage(resolve: (...args: any[]) => any): Worker {
  const resolveString = resolve.toString();
  const webWorkerTemplate = `
            self.addEventListener('message', function(e) {
                (${resolveString})(postMessage, e.data);
            });
        `;
  const blob = new Blob([webWorkerTemplate], {type: 'text/javascript'});
  return new Worker(URL.createObjectURL(blob));
}
