import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {StepFlow} from './step-flow';
import {StepFlowLinkType} from './step-flow-link-type';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-steps-flow',
  templateUrl: './steps-flow.component.html',
  styleUrls: ['./steps-flow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsFlowComponent {
  @Input()
  steps: Array<StepFlow>;

  getLinkClass(linkType: StepFlowLinkType): string {
    switch (linkType) {
      case StepFlowLinkType.DOTS:
        return 'icon-custom-ellipsis-icon';
      case StepFlowLinkType.LEFT_ARROW:
        return 'icon-arrow-left-icon';
      case StepFlowLinkType.RIGHT_ARROW:
        return 'icon-arrow-right-icon';
      case StepFlowLinkType.LINE:
        return 'line';
    }
  }
}
