import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {
  AlertsService,
  Destroyable,
  ModalDialogComponent,
  ResponsiveService,
  TranslatableText,
  TranslationsService,
  formatMinute,
} from 'common';
import {BehaviorSubject, Subject, timer} from 'rxjs';
import {first, map, take, takeUntil} from 'rxjs/operators';

import {ErrorService} from '../../../error/error.service';
import {UserService} from '../../../user/data/user.service';
import {VerificationDao} from '../verification-dao';

@Component({
  selector: 'tl-code-verification-dialog',
  templateUrl: './code-verification-dialog.component.html',
  styleUrls: ['./code-verification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CodeVerificationDialogComponent implements OnInit {
  phone: string;

  resendText: TranslatableText;

  resendDisabled = true;

  wrongCode = new BehaviorSubject('');

  form: FormControl;

  submitted = false;

  @HostBinding('class.tl-code-dialog')
  readonly hostClass = true;

  @ViewChild(ModalDialogComponent, {static: true})
  private dialog: ModalDialogComponent;

  private readonly WAIT_SECONDS = 240;

  @Destroyable()
  private destroy = new Subject<void>();

  get isMobile(): boolean {
    return this.responsiveService.isMobile();
  }

  constructor(
    private alertService: AlertsService,
    private cdr: ChangeDetectorRef,
    private errorService: ErrorService,
    private responsiveService: ResponsiveService,
    private userService: UserService,
    private translationsService: TranslationsService,
    private verificationDao: VerificationDao,
  ) {
    this.confirm = this.confirm.bind(this);
    this.resendSMS = this.resendSMS.bind(this);
  }

  ngOnInit(): void {
    this.form = new FormControl(null, Validators.required);

    this.userService
      .getData()
      .pipe(first())
      .subscribe(user => (this.phone = `+${user.phonePrefix}\u00A0${user.phone}`));

    this.form.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.wrongCode.next(''));
    this.startInterval();
  }

  confirm(): boolean {
    this.submitted = true;

    if (this.form.invalid) {
      return false;
    }

    this.verificationDao.confirmCode(this.form.value).subscribe({
      next: () => {
        this.dialog.close('success');
        this.alertService.notifySuccess({
          key: 'userProfile.verifyPhone.verificationOk',
        });
      },
      error: err => {
        const error = this.errorService.processErrorManually(err);
        if (error.message) {
          this.wrongCode.next(error.message);
        } else {
          this.translationsService
            .getTranslation('userProfile.verifyPhone.verificationCodeError')
            .pipe(first())
            .subscribe(text => this.wrongCode.next(text));
        }
      },
    });

    return false;
  }

  resendSMS(): boolean {
    this.verificationDao.requestCode().subscribe({
      next: () => this.startInterval(),
      error: e =>
        this.errorService.processErrorGlobalContext(e, {
          key: 'userProfile.verifyPhone.codeRequestError',
        }),
    });

    return false;
  }

  private startInterval(): void {
    this.resendDisabled = true;
    this.cdr.markForCheck();

    timer(0, 1000)
      .pipe(
        take(this.WAIT_SECONDS),
        map(elapsed => this.WAIT_SECONDS - elapsed),
        takeUntil(this.destroy),
      )
      .subscribe({
        next: remaining => {
          this.resendText = {
            key: 'userProfile.verifyPhone.retrySendDisabled',
            keyData: {
              message: formatMinute(remaining),
            },
          };
        },
        complete: () => {
          this.resendText = {
            key: 'userProfile.verifyPhone.retrySend',
          };
        },
      });
  }
}
