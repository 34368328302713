import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FormImageUploaderComponent} from './form-image-uploader.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  exports: [FormImageUploaderComponent],
  declarations: [FormImageUploaderComponent],
})
export class FormImageUploaderModule {}
