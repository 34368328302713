import {Serializable} from 'common';

class UserGroupStatusInternal {
  id: number;
  autoLoadMaxAmount: number;
  creditRequired: boolean;
  isAdmin: boolean;
  muteChat: boolean;
  mutePush: boolean;
  lastHistoryReadId: number;
}

export class UserGroupStatus extends Serializable(UserGroupStatusInternal) {
  public static createFromBackend(obj: any) {
    let u = new UserGroupStatus();

    u.id = obj.clienteId;
    u.autoLoadMaxAmount = obj.autoCreditMaxAmount;
    u.creditRequired = obj.creditRequired;
    u.isAdmin = obj.role === 'ADMIN';
    u.muteChat = obj.muteChat;
    u.mutePush = obj.mutePush;
    u.lastHistoryReadId = obj.lastHistoryEntryIdRead;

    return u;
  }
}
