export enum Market {
  GOOGLE,
  APPLE,
  SAMSUNG,
}

export const MARKET_IMAGE_FILES = {
  APPLE: 'assets/img/apps-install/descargar-ios.svg', // use translate pipe
  GOOGLE: 'assets/img/apps-install/google-play.png', // use translate pipe
  SAMSUNG: 'assets/img/apps-install/download-samsung-en.svg',
  APK: 'assets/img/apps-install/download-apk.svg', // use translate pipe
};
