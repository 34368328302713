<input
  type="text"
  class="form-control"
  [tlTriggerOnKeyboardOpen]="65"
  *ngFor="let index of formatter.fields.length | arrayFromNumber"
  [maxLength]="formatter.fields[index]"
  [style.width]="
    (
      (inputInitWidthCoef | initWidth: accRef) +
      formatter.fields[index] * inputFieldWidthCoef
    ).toString() + 'px'
  "
  [tabindex]="defaulTabIndex + index"
  [value]="account[index] ? account[index] : ''"
  [placeholder]="placeholderFields[index]"
  #accRef
  (keydown)="onKeyDown(accRef, index, $event)"
  (keyup)="onKeyUp(accRef, index, $event)"
  (change)="onChange(accRef.value, index)"
  (paste)="onPaste(accRef, index, $event)" />
