import {NgModule} from '@angular/core';

import {LocalStorage} from './local-storage';
import {LocalStorageService} from './local-storage.service';
import {MemoryStorageService} from './memory-storage.service';

export function localStorageFactory() {
  return LocalStorage.isAvailable()
    ? new LocalStorageService()
    : new MemoryStorageService();
}

@NgModule({
  providers: [{provide: LocalStorage, useFactory: localStorageFactory}],
})
export class LocalStorageModule {}
