<div class="btn-group">
  <button
    type="button"
    class="btn btn-type-3"
    [ngClass]="{selected: model, disabled: disable1}"
    (click)="setMode(true)">
    <i *ngIf="icon1" [class]="icon1"></i> {{ name1 }}
  </button>
  <button
    type="button"
    class="btn btn-type-3"
    [ngClass]="{selected: model === false, disabled: disable2}"
    (click)="setMode(false)">
    <i *ngIf="icon2" [class]="icon2"></i> {{ name2 }}
  </button>
</div>
