import {NgModule} from '@angular/core';

import {InputPatternDirective} from './input-pattern.directive';

@NgModule({
  imports: [],
  exports: [InputPatternDirective],
  declarations: [[InputPatternDirective]],
  providers: [],
})
export class FormValidationModule {}
