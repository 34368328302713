import {Pipe, PipeTransform} from '@angular/core';

/**
 * Returns a string from number with max value limit, prepending '+' sign
 * when max value is exceeded.
 *
 * @example 12 | limitMoreItems => '+9'
 */
@Pipe({name: 'limitMoreItems'})
export class LimitMoreItemsPipe implements PipeTransform {
  transform(value: number, max = 9): any {
    return value > max ? `+${max}` : `${value}`;
  }
}
