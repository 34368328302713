import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {Match, MatchesData} from './match';
import {MatchesDao} from './matches.dao';
import {HttpClient} from '@angular/common/http';
import {TranslationsService} from 'common';

// TODO · ADAPTER · remove
@Injectable()
export class MatchesDaoAdapter extends MatchesDao {
  constructor(
    protected http: HttpClient,
    private translationsService: TranslationsService,
  ) {
    super(http);
  }

  getMatches(raffleId: number, size = 20): Observable<MatchesData> {
    return this.http
      .get(this.baseUrl + '/sorteos/' + raffleId + '/partidos?imgSize=' + size)
      .pipe(
        switchMap((res: any) =>
          this.translationsService
            .getTranslation('results.matchSuspended')
            .pipe(map((suspendedText: string) => [res, suspendedText])),
        ),
        map(([res, suspendedText]) => ({
          matches: this.adaptMatches(res).matches,
          extraInfo: this.adaptExtraInfo(res, suspendedText),
        })),
        map((matchList: any) => ({
          matches: matchList.matches.map(Match.createFromBackend),
          extraInfo: matchList.extraInfo,
        })),
      );
  }

  // noinspection JSMethodCanBeStatic
  private adaptMatches(obj: any): Record<string, unknown> {
    return {
      matches: obj.partidos.map((partido, index) => {
        let match = {
          id: partido.id,
          date: partido.fecha,
          typeId: index < 14 ? 'partidos' : 'pleno15',
          local: {
            name: partido.local.concat(partido.suspended ? '*' : ''),
            pictureUrl: partido.escudo1,
            info: partido.info1,
            score: this.parseScore(partido.marcador, 0),
          },
          visitor: {
            name: partido.visitante.concat(partido.suspended ? '*' : ''),
            pictureUrl: partido.escudo2,
            info: partido.info2,
            score: this.parseScore(partido.marcador, 1),
          },
        };

        if (partido.marcador) {
          if (!partido.signo && partido.suspended) {
            match['resultInfo'] = {
              score: partido.marcador,
              values:
                match.typeId === 'partidos'
                  ? ['1', 'X', '2']
                  : [
                      ['0', '1', '2', 'M'],
                      ['0', '1', '2', 'M'],
                    ],
            };
          } else {
            match['resultInfo'] = {
              score: partido.marcador,
              values: partido.signo ? partido.signo.split('-') : [],
            };
          }
        } else {
          if (!partido.signo && partido.suspended) {
            match['resultInfo'] = {
              score: partido.marcador,
              values:
                match.typeId === 'partidos'
                  ? ['1', 'X', '2']
                  : [
                      ['0', '1', '2', 'M'],
                      ['0', '1', '2', 'M'],
                    ],
            };
          }
        }

        return match;
      }),
    };
  }

  private parseScore(marcador: string, index: number): number {
    if (typeof marcador !== 'string') {
      return undefined;
    }

    try {
      return parseInt(marcador.split('-')[index], 10);
    } catch (e) {
      return undefined;
    }
  }

  private adaptExtraInfo(res: any, suspendedText: string): Array<string> {
    const extraInfo: Array<string> = [];
    if (res.extraInfo) {
      extraInfo.push(...res.extraInfo);
    }
    if (res.partidos && res.partidos.some(partido => partido.suspended)) {
      extraInfo.push(suspendedText);
    }
    return extraInfo;
  }
}
