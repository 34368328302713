import {Directive, ElementRef, Renderer2} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {Destroyable} from '../util/destroyable';

@Directive({
  selector: '[tlRouterOutletClass]',
})
export class RouterOutletClassDirective {
  @Destroyable()
  destroy = new Subject();

  constructor(
    private routerOutlet: RouterOutlet,
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {
    this.routerOutlet.activateEvents
      .pipe(
        takeUntil(this.destroy),
        filter(() => this.routerOutlet.activatedRouteData.context),
      )
      .subscribe(() => {
        const componentElement =
          this.element.nativeElement.parentElement.lastElementChild;
        this.renderer.addClass(
          componentElement,
          this.routerOutlet.activatedRouteData.context,
        );
      });
  }
}
