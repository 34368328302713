import {Injectable} from '@angular/core';
import {PaginationData} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {adaptResult} from '../../patch/info.patch';

import {GenericResult} from './generic-result';
import {ResultsDao} from './results.dao';

@Injectable()
export class ResultsDaoAdapter extends ResultsDao {
  getResult(resultId: number): Observable<GenericResult> {
    return this.http.get(this.baseUrl + '/sorteos/' + resultId).pipe(
      map((res: any) => (!res.resultInfo ? adaptResult(res) : res)),
      map(res => GenericResult.createFromBackend(res)),
    );
  }

  moreResults(
    index: number,
    filter?: Array<string>,
  ): Observable<PaginationData<GenericResult>> {
    let filterString =
      filter && filter.length > 0
        ? '/' +
          filter
            .reduce((a, f) => a.concat(f) + '+', '')
            .replace(/\+$/g, '')
            .toLowerCase()
        : '';

    return this.http
      .get(
        this.baseUrl + '/sorteos/finished' + filterString + '?firstResult=' + index,
      )
      .pipe(
        map((obj: any) => {
          let results: Array<GenericResult>;
          if (obj.hasOwnProperty('resultados')) {
            results = [];
            for (let result of obj.resultados) {
              results.push(
                GenericResult.createFromBackend(
                  !result.resultInfo ? adaptResult(result) : result,
                ),
              );
            }
            return new PaginationData<GenericResult>(results, obj.total);
          }
        }),
      );
  }
}
