/**
 * Crear a link and autoclick it simulating the download file process.
 *
 * @param window active window object
 * @param file url or blob
 * @param fileName name of the downloaded file
 */
export function downloadLink(window: Window, file: string, fileName?: string) {
  let link = window.document.createElement('a');
  link.setAttribute('href', file);
  link.setAttribute('download', fileName ? fileName : 'download');
  link.style.visibility = 'hidden';
  window.document.body.appendChild(link);
  link.click();
  window.document.body.removeChild(link);
}
