import {ChangeDetectorRef, EventEmitter, forwardRef, Type} from '@angular/core';

/**
 * Interface for toggle components
 */
export abstract class Toggleable {
  onToggleOpen = new EventEmitter();

  toggleState: boolean;

  constructor(protected cdr: ChangeDetectorRef) {}

  open() {
    this.toggleState = true;
    this.onToggleOpen.emit();
    this.cdr.markForCheck();
  }

  close() {
    this.toggleState = false;
    this.cdr.markForCheck();
  }
}

export function toggleableConfig(type: Type<any>) {
  return {
    provide: Toggleable,
    useExisting: forwardRef(() => type),
  };
}
