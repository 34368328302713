<div class="button-row">
  <tl-info-basic
    (openInfo)="helpOpened = !helpOpened"
    *ngIf="showHelp"></tl-info-basic>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-type-3 btn-false selectable-box"
      [ngClass]="{
        selected: model === choiceFalseValue,
        disabled: isDisabled || disableFalseChoice,
        'btn--icon': choiceFalseIcon
      }"
      (click)="selectValue(choiceFalseValue)">
      <i *ngIf="choiceFalseIcon" [class]="choiceFalseIcon"></i>
      <tl-translatable-text [text]="choiceFalseText"></tl-translatable-text>
    </button>
    <span class="btn-separator" *ngIf="showSeparator">o</span>
    <button
      type="button"
      class="btn btn-type-3 btn-true selectable-box"
      [ngClass]="{
        selected: model === choiceTrueValue,
        disabled: isDisabled || disableTrueChoice,
        'btn--icon': choiceTrueIcon
      }"
      (click)="selectValue(choiceTrueValue)">
      <i *ngIf="choiceTrueIcon" [class]="choiceTrueIcon"></i>
      <tl-translatable-text [text]="choiceTrueText"></tl-translatable-text>
    </button>
  </div>
  <div class="filler">&nbsp;</div>
</div>
<div class="choice-help" [hidden]="!helpOpened">
  <ng-content></ng-content>
</div>
