import {Injectable} from '@angular/core';
import {Logger, workerMapIfPossible} from 'common';
import {Observable, of, zip} from 'rxjs';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';
import {MoneyActivityService} from '../../../money/data/money-activity.service';

import {GroupDao} from './group.dao';
import {GroupService} from './group.service';
import {MoneyActivityGroup} from './money-activity-group';
import {MoneyActivityTypes} from '../../../money/data/money-activity-types';

@Injectable({providedIn: 'root'})
export class MoneyActivityGroupService extends MoneyActivityService<MoneyActivityGroup> {
  constructor(
    private groupDao: GroupDao,
    private groupService: GroupService,
    protected logger: Logger,
  ) {
    super(logger);
  }

  setPaginationIndex(activities: Array<MoneyActivityGroup>): void {
    // In Groups the paginationIndex is the id of last activity
    this.paginationIndex = activities.length
      ? activities[activities.length - 1].id
      : 0;
  }

  protected getActivitiesDao(): Observable<Array<MoneyActivityGroup>> {
    return this.groupService.getData().pipe(
      first(),
      switchMap(group =>
        this.groupDao.moneyActivities(
          group.id,
          this.paginationIndex,
          this.filterType,
        ),
      ),
      map(d => d.data),
    );
  }

  protected checkUnhandledActivityTypes(
    activities: Array<MoneyActivityGroup>,
  ): Observable<any> {
    return zip(of(MoneyActivityTypes.allPossibleTypes()), of(activities)).pipe(
      workerMapIfPossible(data => {
        const types = data[0];
        const moneyactivities: Array<MoneyActivityGroup> = data[1];

        const unhandledMap = new Map<string, boolean>();
        moneyactivities.forEach(activity => {
          if (types.indexOf(activity.type) < 0) {
            unhandledMap.set(activity.type, true);
          }
        });
        return unhandledMap;
      }),
      filter((undhandled: Map<string, boolean>) => undhandled.size > 0),
      tap(unhandled =>
        this.logger.warn('New activity types created by backend', {
          types: Array.from(unhandled.keys()),
        }),
      ),
    );
  }
}
