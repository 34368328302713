import {Serializable, SerializableProperty, SerializableType} from 'common';
import {isPast} from 'date-fns';

import {CombinationData} from '../../combination/data/combination-data';
import {CombinationType} from '../../combination/data/combination-type';
import {COMBINATION_TYPE_RESOLVER} from '../../combination/data/combination-type-resolver';
import {GamePrize} from '../../prizes/data/game-prize';
import {TicketRenderData} from '../../tickets/render/ticket-render-data';

import {
  ResultSummaryLine,
  SummaryAmountLine,
  SummaryStringLine,
} from './result-summary-line';

class GenericResultInternal implements CombinationData {
  id: number;

  name: string;

  gameId: string;

  gameVersion: number;

  logo: string;

  combinationDisplay: string;

  date: number;

  closingDate: number;

  price: number;

  scrutinyTable: any;

  jackpotDelivered: string;

  jackpot: number;

  groupTickets: number;

  hasPrizes: boolean;

  @SerializableProperty(
    info => COMBINATION_TYPE_RESOLVER[info.type],
    SerializableType.COLLECTION,
    true,
  )
  combinationTypes: Array<CombinationType<any>>;

  @SerializableProperty(
    json => (json.symbol ? SummaryAmountLine : SummaryStringLine),
    SerializableType.MAP,
    true,
  )
  info: Map<string, ResultSummaryLine>;

  @SerializableProperty(TicketRenderData, SerializableType.OBJECT)
  renderData: TicketRenderData;

  @SerializableProperty(GamePrize, SerializableType.COLLECTION)
  gamePrizes: Array<GamePrize>;

  isRaffleCelebrated() {
    return isPast(this.date);
  }
}

export class GenericResult extends Serializable(GenericResultInternal) {
  static createFromBackend(obj: any) {
    let res = new GenericResult();

    res.id = obj.id;
    res.name = obj.nombre;
    res.gameId = obj.juego;
    res.gameVersion = obj.juegoVersion;
    res.logo = obj.logoUrl || obj.logoUrlSVG;
    res.combinationDisplay = obj.combinacionFormatted;
    res.date = obj.fecha;
    res.closingDate = obj.fechaCierre;
    res.price = obj.precio;
    res.scrutinyTable = obj.tablaEscrutinio;
    res.groupTickets = obj.agruparBoletos;
    res.hasPrizes = Array.isArray(obj.premios) && obj.premios.length > 0;

    if (
      res.hasPrizes &&
      !!obj?.tablaEscrutinio?.rows[0]?.fields[2]?.label &&
      obj?.tablaEscrutinio?.rows[0]?.fields[2]?.label !== '0'
    ) {
      res.jackpotDelivered = obj?.tablaEscrutinio?.rows[0]?.fields[2]?.label;
    }
    res.jackpot = obj.bote;

    res.combinationTypes = obj.resultInfo
      ? obj.resultInfo
          .map(info => {
            try {
              return COMBINATION_TYPE_RESOLVER[info.type].createFromBackend(info);
            } catch (error) {
              console.error(
                `Error no exist combination type for info.type: ${info.type}`,
              );
              return null;
            }
          })
          .filter(result => !!result) // Filter by existing types
      : undefined;

    if (obj.resultInfoLines) {
      res.info = new Map<string, ResultSummaryLine>();
      obj.resultInfoLines.forEach(line => {
        if (line.currencySymbol) {
          res.info.set(line.label, SummaryAmountLine.createFromBackend(line));
        } else {
          res.info.set(line.label, SummaryStringLine.createFromBackend(line));
        }
      });
    }

    res.renderData = obj.extraInfo
      ? TicketRenderData.createFromBackend(obj.extraInfo)
      : undefined;

    res.gamePrizes = obj.prizesInfo
      ? obj.prizesInfo.map(prizeInfo => GamePrize.createFromBackend(prizeInfo))
      : null;

    return res;
  }
}
