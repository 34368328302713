import {FormControl, FormGroup} from '@angular/forms';

export class ShareOptionsFormGroup extends FormGroup {
  constructor() {
    super({
      distributePrize: new FormControl(true),
      sharePercents: new FormControl(true),
      shareMessage: new FormControl(''),
    });
  }

  reset(value?: any, options?: {onlySelf?: boolean; emitEvent?: boolean}): void {
    super.reset(
      Object.assign(
        {distributePrize: true, sharePercents: true, shareMessage: ''},
        value,
      ),
      options,
    );
  }

  getDistributePrize(): FormControl {
    return this.get('distributePrize') as FormControl;
  }
}
