import {Observable} from 'rxjs';

export class Task {
  id: string;

  method: () => Observable<any>;

  persistent: boolean;

  constructor(id: string, method: () => Observable<any>, persistent = false) {
    this.id = id;
    this.method = method;
    this.persistent = persistent;
  }

  run(): Observable<any> {
    return this.method();
  }
}
