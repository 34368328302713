import {query, style, transition, trigger, useAnimation} from '@angular/animations';
import {Component, HostBinding, Input} from '@angular/core';
import {fadeOut} from 'common';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-preboot',
  templateUrl: './preboot.component.html',
  styleUrls: ['./preboot.component.scss'],
  animations: [
    trigger('PrebootAnimation', [
      transition(':leave', [
        query('video', style({opacity: 0})),
        useAnimation(fadeOut, {params: {duration: '2s ease'}}),
      ]),
    ]),
  ],
})
export class PrebootComponent {
  @HostBinding('@PrebootAnimation')
  animation = true;

  @Input()
  message = '';
}
