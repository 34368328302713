import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({name: 'urlSafe'})
export class UrlSafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
