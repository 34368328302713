import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  I18nModule,
  IfEnvironmentModule,
  ModalDialogModule,
  ResponsiveUtilsModule,
  ScrollableModule,
} from 'common';

import {KycOkDialogComponent} from './kyc-ok-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    IfEnvironmentModule,
    ModalDialogModule,
    ResponsiveUtilsModule,
    ScrollableModule,
  ],
  exports: [KycOkDialogComponent],
  declarations: [KycOkDialogComponent],
})
export class KycOkDialogModule {}
