import {Inject} from '@angular/core';
import {isNumeric, LocalStorage, Logger} from 'common';
import {iif, Observable, of, Subscriber} from 'rxjs';
import {environment} from '~environments/environment';

import {CoordinatePoint} from './coordinate-point';
import {map} from 'rxjs/operators';

export abstract class AbstractGeolocationService {
  protected watcherId: number;

  protected watcher: Subscriber<GeolocationPosition>;

  constructor(
    protected localStorage: LocalStorage,
    protected logger: Logger,
    @Inject('window') protected window: Window,
  ) {}

  abstract getCurrentLocation(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition>;

  abstract getBestCurrentLocation(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition>;

  abstract watchPosition(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition>;

  getPermissionStatus(): string {
    return (
      this.localStorage.getItem(
        environment.localStorageKeys.geolocationPermission,
      ) || 'default'
    );
  }

  checkGeolocationPermissions(): Observable<boolean> {
    // Check if exists geolocation API
    if (!this.isGeolocationSupported()) {
      return of(false);
    }

    if (!!this.window.navigator && !!this.window.navigator.permissions) {
      // Check if user granted access to geolocation and check if we have permissions.
      return iif(
        () => this.getPermissionStatus() === 'granted',
        this.window.navigator.permissions.query({name: 'geolocation'}),
        of(null),
      ).pipe(
        map(
          (permissionStatus: PermissionStatus | null) =>
            !!permissionStatus && permissionStatus.state === 'granted',
        ),
      );
    }
    // Check localStorage geolocation permissions status
    return of(this.getPermissionStatus() === 'granted');
  }

  /**
   * 'granted' -> user granted premission
   * 'default' -> never asked
   * 'dismissed' -> user denied or didnt answer the prompt when shown
   */
  isGeolocationSupported(): boolean {
    return !!this.window.navigator.geolocation;
  }

  clearWatch(): void {
    if (this.watcherId) {
      this.window.navigator.geolocation.clearWatch(this.watcherId);
      this.watcherId = undefined;

      this.watcher.complete();
      this.watcher = null;
    }
  }

  /**
   * Returns the distance between two points in decimal degrees, in meters.
   * Uses Haversine formula as seen in:
   * https://www.movable-type.co.uk/scripts/latlong.html
   */
  getDistance(start: CoordinatePoint, end: CoordinatePoint): number | undefined {
    if (
      !isNumeric(start.latitude) ||
      !isNumeric(start.longitude) ||
      !isNumeric(end.latitude) ||
      !isNumeric(end.longitude)
    ) {
      return undefined;
    }

    const earthRadius = 6371e3; // meters
    const phi = (start.latitude * Math.PI) / 180;
    const phi2 = (end.latitude * Math.PI) / 180;
    const deltaPhi = ((end.latitude - start.latitude) * Math.PI) / 180;
    const deltaLambda = ((end.longitude - start.longitude) * Math.PI) / 180;

    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi) *
        Math.cos(phi2) *
        Math.sin(deltaLambda / 2) *
        Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c;
  }

  protected setPermissionStatus(granted: boolean): void {
    this.localStorage.setItem(
      environment.localStorageKeys.geolocationPermission,
      granted ? 'granted' : 'dismissed',
    );
  }
}
