import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, startWith, takeUntil} from 'rxjs/operators';

import {ScreenSize} from '../responsive/model';
import {ResponsiveService} from '../responsive/responsive.service';
import {Destroyable} from '../util/destroyable';

@Directive({
  selector: '[tlFullHeight]',
})
export class FullHeightDirective implements OnInit, OnChanges {
  @Input('tlFullHeight')
  enable = true;

  @HostBinding('style.height')
  height: string;

  @Destroyable()
  private destroySubject = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private responsiveService: ResponsiveService,
    @Inject('window') private window: Window,
  ) {}

  ngOnInit() {
    this.responsiveService.resize
      .pipe(startWith(<ScreenSize>null))
      .pipe(
        filter(() => this.enable),
        takeUntil(this.destroySubject),
      )
      .subscribe(() => {
        this.height = this.window.innerHeight + 'px';
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('enable')) {
      if (this.enable) {
        this.height = this.window.innerHeight + 'px';
      } else {
        this.height = '';
      }
    }
  }
}
