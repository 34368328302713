import {
  Directive,
  DoCheck,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import {
  AbstractControl,
  FormControlDirective,
  FormControlName,
  NgControl,
} from '@angular/forms';
import {BehaviorSubject, Observable, Subject, merge, takeUntil} from 'rxjs';
import {Destroyable} from '../../util/destroyable';
import {isBoolean} from '../../util/core/boolean';

/**
 * Allow filter and input by a given pattern
 *
 * @link https://jsfiddle.net/emkey08/zgvtjc51
 */
@Directive({
  selector: '[tlControlClass]',
})
export class TlControlClassDirective implements OnInit, DoCheck {
  // Names of classes
  @Input('tlControlClassValid') validClass = 'is-valid';
  @Input('tlControlClassInvalid') invalidClass = 'is-invalid';
  /** To check external formControl wothout directive */
  @Input('tlControlClassCtrl') set formControl(crtl: AbstractControl) {
    this.control = crtl;
  }
  set submitted(val: boolean) {
    this.submitted$.next(val);
  }
  get submitted(): boolean {
    return this.submitted$.value;
  }
  private submitted$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  /** control to check */
  private control: AbstractControl | FormControlName | FormControlDirective;

  /** Control to check
   *  ! If not pass search in a formControl Directive
   */
  @Input() set tlControlClass(submitted: boolean) {
    this.submitted = submitted;
  }

  /** Determines if used in a tl-dropdown component*/
  private isDropDown: boolean;

  @Destroyable()
  private destroySubject = new Subject<void>();

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    @Optional() control: NgControl,
  ) {
    if (control) {
      this.control = control as FormControlName | FormControlDirective;
    }
  }

  ngOnInit() {
    this.isDropDown =
      (this.element.nativeElement as HTMLElement).tagName === 'TL-DROPDOWN';
    this.subscribeChanges();
  }

  /** Subscribe to submit or  */
  subscribeChanges() {
    let observers$: Observable<any>[];

    if (this.isDropDown) {
      observers$ = [
        this.control.valueChanges,
        this.control.statusChanges,
        this.submitted$,
      ];
    } else {
      observers$ = [this.control.statusChanges, this.submitted$];
    }

    merge(...observers$)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => this.refreshClass());
  }

  ngDoCheck() {
    // this.refreshClass();
  }

  refreshClass() {
    if (this.isValid()) {
      this.setClass(this.validClass);
    } else {
      this.removeClass(this.validClass);
    }
    if (this.isInvalid()) {
      this.setClass(this.invalidClass);
    } else {
      this.removeClass(this.invalidClass);
    }
    // tl-dropdown support
    if (this.isDropDown) {
      if (!this.control.value) {
        this.setClass('placeholder');
      } else {
        this.removeClass('placeholder');
      }
    }
  }

  setClass(className: string) {
    if (!this.haveClass(className)) {
      this.renderer.addClass(this.element.nativeElement, className);
    }
  }

  removeClass(className: string) {
    if (this.haveClass(className)) {
      this.renderer.removeClass(this.element.nativeElement, className);
    }
  }

  haveClass(className: string): boolean {
    return (this.element.nativeElement as HTMLElement).classList.contains(className);
  }

  private isInvalid() {
    return isBoolean(this.submitted)
      ? this.submitted && this.control.invalid
      : this.control.dirty && this.control.invalid;
  }

  private isValid() {
    return isBoolean(this.submitted)
      ? this.submitted && this.control.dirty && this.control.valid
      : this.control.dirty && this.control.valid;
  }
}
