import {Injectable} from '@angular/core';

import {AbstractFiltersService} from '../abstract-filters.service';
import {DEFAULT_GAME_FILTERS} from '../default-filter-data';

@Injectable({providedIn: 'root'})
export class GameFiltersService extends AbstractFiltersService {
  // noinspection JSUnusedGlobalSymbols
  readonly DEFAULT_FILTERS = DEFAULT_GAME_FILTERS;
}
