import {Inject, Injectable} from '@angular/core';
import {LocalStorage, Logger} from 'common';
import {Observable, Subscriber, throwError} from 'rxjs';
import {environment} from '~environments/environment';
import {filter, first} from 'rxjs/operators';
import {AbstractGeolocationService} from './abstract-geolocation.service';

@Injectable({providedIn: 'root'})
export class GeolocationService extends AbstractGeolocationService {
  constructor(
    protected localStorage: LocalStorage,
    protected logger: Logger,
    @Inject('window') protected window: Window,
  ) {
    super(localStorage, logger, window);
  }

  getCurrentLocation(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition> {
    if (!this.isGeolocationSupported()) {
      return throwError(() => new Error('Geolocation not available'));
    }

    return new Observable((subscriber: Subscriber<GeolocationPosition>) => {
      const success = position => {
        this.setPermissionStatus(true);
        subscriber.next(position);
        subscriber.complete();
      };
      const error = err => {
        if (err.code === 1) {
          this.setPermissionStatus(false);
        }
        subscriber.error(err);
      };

      this.window.navigator.geolocation.getCurrentPosition(
        success,
        error,
        positionOptions,
      );
    });
  }

  /**
   * Returns the first location that meets the precision with a maximum of 5 attempts.
   *
   * @param positionOptions
   */
  getBestCurrentLocation(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition> {
    if (!this.isGeolocationSupported()) {
      return throwError(() => new Error('Geolocation not available'));
    }
    const defaultOptions: PositionOptions = {
      enableHighAccuracy: environment.geolocation.highAccuracy ?? false,
    };
    let attempts = 0;
    const maxAttempts = 1;
    const ti = new Date().getTime();
    return this.watchPosition(positionOptions ?? defaultOptions).pipe(
      filter((position: GeolocationPosition) => {
        if (position.coords.accuracy > environment.geolocation.maxAccuracy) {
          attempts++;
          if (attempts < maxAttempts) {
            return false;
          } else {
            // Return best geolocation when reach the last attempt
            attempts = 0;
            this.logger.error(
              `Reach limit attempts when request location. Accuracy: ${
                position.coords.accuracy
              } in ${new Date().getTime() - ti} ms`,
            );
            return true;
          }
        } else {
          attempts = 0;
          return true;
        }
      }),
      first(),
    );
  }

  watchPosition(positionOptions?: PositionOptions): Observable<GeolocationPosition> {
    this.clearWatch();

    return new Observable((subscriber: Subscriber<GeolocationPosition>) => {
      const success = position => {
        this.setPermissionStatus(true);
        subscriber.next(position);
      };
      const error = err => {
        this.setPermissionStatus(false);
        subscriber.error(err);
      };

      if (this.watcherId) {
        this.watcherId = undefined;
      }

      this.watcher = subscriber;

      this.watcherId = this.window.navigator.geolocation.watchPosition(
        success,
        error,
        positionOptions,
      );
    });
  }
}
