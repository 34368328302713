import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';

import {OnPopstate, PopstateService} from './popstate.service';

@Injectable()
export class NoBackGuard implements CanActivate, CanDeactivate<OnPopstate> {
  constructor(private popstateService: PopstateService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.popstateService.lastPopState !== 'back';
  }

  canDeactivate(
    component: OnPopstate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isBack = this.popstateService.lastPopState === 'back';

    if (isBack && typeof component.onPopstate === 'function') {
      return component.onPopstate('back');
    }

    return !isBack;
  }
}
