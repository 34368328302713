import {Injectable} from '@angular/core';
import {ModalDialogComponent, ModalHelperService} from 'common';
import {delay, first, Observable, of, switchMap} from 'rxjs';

import {FailedWithdrawals} from '../data/failed-withdrawals';
import {FailedWithdrawalsService} from '../data/failed-withdrawals.service';
import {MoneyDao} from '../data/money.dao';

@Injectable({
  providedIn: 'root',
})
export class CheckFailedWithdrawalsService {
  constructor(
    private failedWithdrawalsService: FailedWithdrawalsService,
    private modalHelperService: ModalHelperService,
    private moneyDao: MoneyDao,
  ) {}

  setData(failedWithdrawals: Array<FailedWithdrawals>): void {
    this.failedWithdrawalsService.setData(failedWithdrawals);
  }

  checkFailedWithdrawals(): Observable<boolean> {
    return this.failedWithdrawalsService.getData().pipe(
      delay(1000),
      first(),
      switchMap((withdrawals: Array<FailedWithdrawals>) => {
        if (withdrawals?.length) {
          return this.showModalRequestWithdrawal(withdrawals[0].id);
        }
        return of(false);
      }),
    );
  }

  showModalRequestWithdrawal(id: number): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      this.modalHelperService
        .openOkCancelModal(ModalDialogComponent, {
          componentParams: {
            type: 'ok_cancel',
            title: {key: 'userProfile.balance.requestWithdrawalModal.title'},
            message: {key: 'userProfile.balance.requestWithdrawalModal.description'},
            accept: {key: 'userProfile.balance.requestWithdrawalModal.buttonAccept'},
          },
        })
        .subscribe(result => {
          if (result === 'success') {
            return this.moneyDao
              .requestWithdraw(id)
              .subscribe(() => subscriber.next(true));
          }
          subscriber.next(false);
        });
    });
  }
}
