import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {AppleAuthService, AppleUser} from '../apple-auth.service';

@Component({
  selector: 'tl-apple-login',
  templateUrl: './apple-login.component.html',
  styleUrls: ['./apple-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppleLoginComponent {
  @Input()
  buttonClass: string;

  @Input()
  text: string;

  @Input()
  loading = false;

  @Output()
  login = new EventEmitter<AppleUser>();

  @HostBinding('class.tl-apple-login')
  readonly hostClass = true;

  private submitted = false;

  constructor(private authService: AppleAuthService) {}

  signIn() {
    if (this.submitted) {
      return;
    }

    this.submitted = true;

    this.authService
      .login()
      .pipe(finalize(() => (this.submitted = false)))
      .subscribe(user => this.login.next(user));
  }
}
