import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {GameId} from '../data/game-id';

// TODO · ADAPTER · remove
/**
 * This functions are used to bridge back from non-descriptor supported
 * data to descriptor format. Used for spain environment.
 */

export function patchGameMetadata(response, http: HttpClient): Observable<any> {
  if (environment.locale.locale === 'es-ES') {
    return http.get('assets/data/mocks/descriptors.json').pipe(
      map((data: any) => {
        response['genericDescriptors'] = data.descriptors.concat(
          response.genericDescriptors || [],
        );
        return response;
      }),
    );
  } else {
    return of(response);
  }
}

export function patchRenderData(response) {
  if (response.proximosSorteos) {
    response.proximosSorteos
      .filter(proximoSorteo => proximoSorteo.juego === GameId.LOTERIA_NACIONAL)
      .forEach(proximoSorteo => addExtraInfo(proximoSorteo));
  }

  if (response.resultados) {
    response.resultados
      .filter(result => result.juego === GameId.LOTERIA_NACIONAL)
      .filter(result => !result.extraInfo) // Patch only upgraded games
      .forEach(result => addExtraInfo(result));
  }

  return response;
}

export function addExtraInfo(object) {
  object.extraInfo = {
    font: 'Lottery',
    types: [{typeId: 'numero', startX: 37.3, startY: 10.5, endX: 78, endY: 32}],
  };
}

export function patchTickets(response: any) {
  if (response.boletos && response.boletos.length > 0) {
    response.boletos = response.boletos
      // betInfo detects old or new descriptors
      .map(ticket => (!ticket.apuesta.betInfo ? adaptTicket(ticket) : ticket));
  }

  return response;
}

export function adaptTicket(ticket: any) {
  // betInfo detects old or new descriptors
  if (!!ticket.apuesta.betInfo) {
    return ticket;
  }

  ticket.sorteo = adaptResult(ticket.sorteo);

  const shipmentInfo = ticket.apuesta.envioCasa;
  ticket.apuesta = adaptBet(ticket.apuesta, ticket.fechasSorteo);
  ticket.apuesta.envioCasa = shipmentInfo;

  return ticket;
}

export function adaptBet(bet: any, fechasSorteo: any) {
  if (!bet.combinaciones) {
    return bet;
  }

  let elige8Type;

  let betGroup = 'default';
  if (bet.juego === GameId.BONOLOTO && bet?.extras?.[0].id === 'semanal') {
    betGroup = 'weekly';
  }

  let bets = bet.combinaciones.map(combination => {
    let betItem = {types: []};

    if (bet.juego === GameId.LOTERIA_NACIONAL) {
      adaptNumberExtendedType(
        betItem.types,
        combination.numerosCombinacion,
        'numero',
      );
      betItem['betId'] = 'simple';
    } else if (bet.juego === GameId.QUINIELA) {
      if (combination.plenoAl15) {
        adaptPleno15Type(betItem.types, combination.plenoAl15);
      } else {
        adaptPartidosType(betItem.types, combination.numerosCombinacion);

        if (bet.combinaciones.length > 2) {
          betItem['betId'] = 'simple';
        } else {
          betItem['betId'] = adaptReducidasType(
            betItem.types,
            combination.numerosCombinacion,
          );
        }

        if (!elige8Type) {
          elige8Type = adaptElige8Type(combination.numerosCombinacion);
        }
      }
    } else {
      adaptNumberExtendedType(
        betItem.types,
        combination.numerosCombinacion,
        'numeros',
      );

      if (combination.estrellas && combination.estrellas.length > 0) {
        adaptNumberExtendedType(betItem.types, combination.estrellas, 'estrellas');
      }

      if (combination.complementario) {
        adaptNumberExtendedType(
          betItem.types,
          combination.complementario,
          'complementario',
        );
      }

      if (bet.juego === GameId.BONOLOTO && betGroup === 'weekly') {
        if (combination.numerosCombinacion.length === 6) {
          betItem['betId'] = 'simple';
        } else {
          betItem['betId'] = `multiple${bet.cantidad / fechasSorteo.length}`;
        }

        betItem['betGroup'] = 'weekly';
      } else {
        if (bet.combinaciones.length === 1 && bet.cantidad > 1) {
          betItem['betId'] = `multiple${bet.cantidad}`;
          if (combination.estrellas && combination.estrellas.length > 0) {
            const numbers = betItem.types.find(type => type.typeId === 'numeros');
            if (numbers && numbers.value.length) {
              betItem['betId'] = betItem['betId'].concat(
                `_${numbers.value.length}_${combination.estrellas.length}`,
              );
            }
          }
        } else {
          betItem['betId'] = 'simple';
        }
      }
    }

    return betItem;
  });

  // we want elige8 as last item on tickets so tweak this here to be last in the
  // array
  if (elige8Type) {
    bets.push({types: [elige8Type]});
  }

  // Backend adds reintegro to all tickets with the appropiate value or 100 if
  // the ticket doesn't have reintegro. So we are going to exclude it for this
  // case.
  if (bet.reintegro && bet.reintegro !== '100') {
    let betItem = {types: []};
    adaptNumberExtendedType(betItem.types, {numero: +bet.reintegro}, 'reintegro');
    bets.push(betItem);
  }

  bet.betInfo = {juego: bet.juego, bets: bets};

  if (
    typeof bet.serieFraccion === 'string' &&
    bet.serieFraccion !== '' &&
    bet.serieFraccion !== '0(0)'
  ) {
    let series = Array<string>();
    let fractions = Array<string>();
    let stringSeries = bet.serieFraccion.match(/(\d+)\(([\d+,?]+)\)/g);

    stringSeries.forEach(stringSerie => {
      series.push(stringSerie.match(/\d+/)[0]);
      fractions.push(
        ...stringSerie
          .match(/\(([(\d+),?]+)\)/g)[0]
          .replace(/[()]/g, '')
          .split(','),
      );
    });

    let betItem = {types: []};
    adaptSerieFraccionTypes(betItem.types, series, 'serie', 'REPETIBLE_NUMBER');
    bet.betInfo.bets.push(betItem);

    betItem = {types: []};
    adaptSerieFraccionTypes(
      betItem.types,
      fractions,
      'fraccion',
      'REPETIBLE_NUMBER',
    );
    bet.betInfo.bets.push(betItem);
  }

  if (bet.joker) {
    let betItem = {types: []};

    adaptJokerType(
      betItem.types,
      bet.joker,
      bet.juego === GameId.EUROMILLONES ? 'millon' : 'joker',
    );

    bet.betInfo.bets.push(betItem);
  }

  if (bet.joker2) {
    let betItem = {types: []};

    adaptJokerType(betItem.types, bet.joker2, 'lluvia');

    bet.betInfo.bets.push(betItem);
  }

  return bet;
}

export function patchResults(response: any) {
  if (response.resultados && response.resultados.length > 0) {
    response.resultados = response.resultados.map(resultado =>
      // resultInfo detects if old or new descriptor
      !resultado.resultInfo ? adaptResult(resultado) : resultado,
    );
  }

  return response;
}

export function adaptResult(result: any) {
  let asset = result.juego.toLowerCase();
  result.logoUrlSVG = `assets/img/juegos/${asset}.svg`;

  if (!result.combinacionObject && result.juego !== GameId.LOTERIA_NACIONAL) {
    return result;
  }

  result.resultInfo = [];

  if (result.juego === GameId.LOTERIA_NACIONAL) {
    addExtraInfo(result);

    if (result.primerPremio) {
      result.resultInfo.push({
        typeId: 'numero',
        type: 'NUMBER',
        value: [result.primerPremio],
      });
    }

    if (result.segundoPremio) {
      result.resultInfo.push({
        typeId: 'second',
        type: 'NUMBER',
        value: [result.segundoPremio],
      });
    }

    if (result.reintegros) {
      result.resultInfo.push({
        typeId: 'reintegro',
        type: 'NUMBER',
        value: result.reintegros.split(' '),
      });
    }
  } else if (result.juego === GameId.QUINIELA) {
    adaptPartidosType(
      result.resultInfo,
      result.combinacionObject.numerosCombinacion,
    );

    adaptPleno15Type(result.resultInfo, result.combinacionObject.plenoAl15);
  } else {
    adaptNumberType(
      result.resultInfo,
      result.combinacionObject.numerosCombinacion,
      'numeros',
    );

    if (
      result.combinacionObject.estrellas &&
      result.combinacionObject.estrellas.length > 0
    ) {
      adaptNumberType(
        result.resultInfo,
        result.combinacionObject.estrellas,
        'estrellas',
      );
    }

    if (result.combinacionObject.complementario) {
      adaptNumberType(
        result.resultInfo,
        result.combinacionObject.complementario,
        'complementario',
      );
    }

    if (result.combinacionObject.reintegro) {
      adaptNumberType(
        result.resultInfo,
        result.combinacionObject.reintegro,
        'reintegro',
      );
    }

    if (result.joker) {
      adaptJokerType(
        result.resultInfo,
        result.joker,
        result.juego === GameId.EUROMILLONES ? 'millon' : 'joker',
      );
    }

    if (result.joker2) {
      adaptJokerType(result.resultInfo, result.joker2.replace(/,/g, ' '), 'lluvia');
    }
  }

  return result;
}

export function adaptUnfinished(response: any): any {
  if (
    !response.unfinishedPlays ||
    response.unfinishedPlays.length === 0 ||
    response.unfinishedPlays[0].juego === 'CRUZ_ROJA'
  ) {
    return response;
  }

  const play = JSON.parse(response.unfinishedPlays[0].play) as any;

  if (play.participaciones || play.numParticipaciones) {
    return response;
  }

  const adapted = {
    almanaque: play.almanaque,
    aleatorio: play.aleatorio,
    quickPlay: false,
    abonarse: !!play.abonarse,
    juego: play.juego || GameId.LOTERIA_NACIONAL,
    sorteoIds: play.sorteoIds || [play.sorteoId],
    numApuestas: play.numApuestas,
    betGroup: 'default',
    combinacionJugada: {
      juego: play.juego,
      aleatoria: play.aleatorio,
      bets: [],
    },
  };

  if (
    play.combinacionJugada?.extras?.length &&
    play.combinacionJugada.extras[0].id === 'semanal'
  ) {
    adapted.betGroup = 'weekly';
  }

  if (play.decimos) {
    play.decimos.forEach(decimo =>
      adapted.combinacionJugada.bets.push({
        amountBet: decimo.cantidad,
        betId: 'simple',
        types: [
          {
            type: 'NUMBER',
            typeId: 'numero',
            value: [decimo.numero],
          },
          {
            type: 'ALPHANUMERIC',
            typeId: 'adminId',
            value: [decimo.adminId],
          },
        ],
      }),
    );

    adapted.numApuestas = play.decimos.reduce((t, d) => d.cantidad + t, 0);
  } else if (play.juego === GameId.QUINIELA) {
    const globals = {
      amountBet: 1,
      betId: null,
      types: [],
    };
    adaptPleno15Type(globals.types, play.combinacionJugada.plenoAl15);

    if (
      play.combinacionJugada.apuestas[0].numeros.some(item =>
        item.numero.includes('E'),
      )
    ) {
      globals.types.push({
        typeId: 'elige8',
        type: 'BOOLEAN_ARRAY',
        value: play.combinacionJugada.apuestas[0].numeros.map(numero =>
          numero.numero.includes('E'),
        ),
      });
    }

    play.combinacionJugada.apuestas.forEach(bet => {
      const types = {
        amountBet: 1,
        betId: null,
        types: [],
      };

      adaptPartidosType(types.types, bet.numeros);
      adapted.combinacionJugada.bets.push(types);
    });

    if (play.combinacionJugada.apuestas.length > 1) {
      globals.betId = 'simple';
      adapted.numApuestas = play.combinacionJugada.apuestas.length;
    } else {
      globals.betId = adaptReducidasType(
        adapted.combinacionJugada.bets[0].types,
        play.combinacionJugada.apuestas[0].numeros,
      );
      adapted.combinacionJugada.bets[0].types[1].value =
        adapted.combinacionJugada.bets[0].types[1].value.map(value => value.value);
    }
    adapted.combinacionJugada.bets.forEach(bet => (bet.betId = globals.betId));
    adapted.combinacionJugada.bets.push(globals);
  } else {
    play.combinacionJugada.apuestas.forEach(bet => {
      const types = {
        amountBet: 1,
        types: [],
      };

      adaptNumberType(types.types, bet.numeros, 'numeros', true);

      if (bet.estrellas) {
        adaptNumberType(types.types, bet.estrellas, 'estrellas', true);
      }

      if (bet.clave) {
        adaptNumberType(types.types, bet.clave, 'complementario', true);
      }

      adapted.combinacionJugada.bets.push(types);

      if (play.combinacionJugada.apuestas.length === 1 && play.numApuestas > 1) {
        types['betId'] = `multiple${play.numApuestas}`;
        adapted.numApuestas = 1;
      } else {
        types['betId'] = 'simple';
      }
    });

    if (play.juego === 'PRIMITIVA') {
      adapted.combinacionJugada.bets.push({
        amountBet: 1,
        betId: 'simple',
        types: [
          {
            type: 'NUMBER',
            typeId: 'reintegro',
            value: [+play.combinacionJugada.reintegro.numero],
          },
        ],
      });

      if (play.joker) {
        adapted.combinacionJugada.bets[
          adapted.combinacionJugada.bets.length - 1
        ].types.push({
          type: 'BOOLEAN',
          typeId: 'joker',
          value: [true],
        });
      }
    }
  }

  response.unfinishedPlays[0].play = JSON.stringify(adapted);
  return response;
}

function adaptNumberExtendedType(parent: Array<any>, sourceData: any, name: string) {
  parent.push({
    typeId: name,
    type: 'NUMBER_EXTENDED',
    value: !Array.isArray(sourceData)
      ? [{value: sourceData.numero, highlight: sourceData.highlighted}]
      : sourceData.map(numero => ({
          value: numero.numero,
          highlighted: !!numero.highlighted,
        })),
  });
}

function adaptNumberType(
  parent: Array<any>,
  sourceData: any,
  name: string,
  coerce = false,
) {
  const obj = {
    typeId: name,
    type: 'NUMBER',
    value: !Array.isArray(sourceData)
      ? [sourceData.numero]
      : sourceData.map(numero => numero.numero),
  };

  if (coerce) {
    obj.value = obj.value.map(n => +n);
  }

  parent.push(obj);
}

function adaptPartidosType(parent: Array<any>, sourceData: any) {
  parent.push({
    typeId: 'partidos',
    type: 'SELECTION',
    value: sourceData
      .filter((item, index) => index < 14)
      .map(numero => ({
        selections: numero.numero
          .split('-')
          .filter(v => ['1', 'X', '2'].includes(v))
          .map(v => ({value: v, highlighted: numero.highlighted})),
      })),
  });
}

function adaptPleno15Type(parent: Array<any>, sourceData: any) {
  const numeros = sourceData.numero.split(',');
  parent.push({
    typeId: 'pleno15',
    type: 'SELECTION',
    value: numeros.map(numero => ({
      selections: numero
        .split('-')
        .map(letter => ({value: letter, highlighted: numeros.highlighted})),
    })),
  });
}

function adaptElige8Type(sourceData: any): Record<string, unknown> {
  if (sourceData.some(numero => numero.numero.includes('E'))) {
    // this should not be alphanumeric but since noone checks the type anywhere it
    // works its only used to paint the ticket anyways <- fuck this now
    return {
      typeId: 'elige8',
      type: 'BOOLEAN_ARRAY',
      value: sourceData.map(numero => ({value: numero.numero.includes('E')})),
    };
  }
}

function adaptReducidasType(parent: Array<any>, sourceData: any): string {
  const count = sourceData.filter(numero => numero.numero.includes('R')).length;

  if (count > 0) {
    parent.push({
      typeId: 'reducciones',
      type: 'BOOLEAN_ARRAY',
      value: sourceData.map(numero => ({value: numero.numero.includes('R')})),
    });

    if (count === 4) {
      return 'reduccion1';
    } else if (count === 6) {
      return 'reduccion3';
    } else if (count === 7) {
      return 'reduccion2';
    } else if (count === 8) {
      const triples = sourceData.filter(
        numero =>
          numero.numero.includes('R') && numero.numero.split('-').length === 3,
      ).length;

      return triples > 2 ? 'reduccion5' : 'reduccion4';
    } else if (count === 11) {
      return 'reduccion6';
    }
  } else {
    return 'multiple';
  }
}

function adaptJokerType(parent: Array<any>, sourceData: any, name: string) {
  parent.push({
    typeId: name,
    type: 'ALPHANUMERIC_EXTENDED',
    value: [{value: sourceData}],
  });
}

function adaptSerieFraccionTypes(
  parent: Array<any>,
  sourceData: any,
  name: string,
  type: string,
) {
  parent.push({
    typeId: name,
    type: type,
    value: !Array.isArray(sourceData)
      ? [sourceData]
      : sourceData.map(numero => numero),
  });
}
