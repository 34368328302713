import {AbstractControl, ValidatorFn} from '@angular/forms';

import {isValidCLABENumber} from '../../CLABE';
import {BankAccountFormatter} from '../bank-account-formatter';

export class ClabeFormatter extends BankAccountFormatter {
  fields = [3, 3, 11, 1];

  parse(account: string): Array<string> {
    let acc = [];
    if (account && account.length === 18) {
      acc.push(account.substring(0, 3));
      acc.push(account.substring(3, 6));
      acc.push(account.substring(6, 17));
      acc.push(account.substring(17, 18));
    }
    return acc;
  }

  getValidator(): ValidatorFn {
    return (c: AbstractControl) => (!this.isValid(c.value) ? {format: true} : null);
  }

  isValid(account: string): boolean {
    return isValidCLABENumber(account);
  }
}
