import {FormControl, FormGroup, Validators} from '@angular/forms';

import {BackendTransformable} from '../../backend/util/backend-transformable';
import {User} from '../../user/data/user';

import {ShippingAddressValidator} from './shipping-address-validator';
import {TicketShippingDao} from './ticket-shipping.dao';
import {ShipmentAddress} from './shipment-address';
import {Country, fixPhoneNumber} from 'common';
import {environment} from '../../../environments/environment';
import {CountryCode, parsePhoneNumber} from 'libphonenumber-js/max';

export class ShippingAddressFormGroup
  extends FormGroup
  implements BackendTransformable
{
  isLocalProfileAddress = true;

  states: Array<string> = [];

  countries: Array<Country> = [];

  constructor(
    ticketShippingDao: TicketShippingDao,
    states: Array<string>,
    countries: Array<Country>,
  ) {
    super({
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      phoneCountry: new FormControl(undefined, [Validators.required]),
      addressLine: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      stateZipCode: new FormGroup(
        {
          zipCode: new FormControl('', [
            Validators.required,
            Validators.maxLength(255),
          ]),
          state: new FormControl('', [
            Validators.required,
            Validators.maxLength(255),
          ]),
        },
        null,
        ShippingAddressValidator.isZipCodeValid(ticketShippingDao),
      ),
      city: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      observations: new FormControl('', [Validators.maxLength(500)]),
    });

    this.states = states;
    this.countries = countries;
  }

  isStateValid() {
    return this.get('stateZipCode.state').valid;
  }

  fillAddressFromUser(user: User) {
    let phoneNumber;
    let phoneCountry;
    if (!!user.phone) {
      const defaultCountryCode =
        environment.locale.user.defaultPhoneCode.toUpperCase() as CountryCode;
      const phoneFixed = fixPhoneNumber(user.phone, defaultCountryCode);

      const phone = parsePhoneNumber(phoneFixed, defaultCountryCode);
      phoneCountry = this.countries.find(
        (currentCountry: Country) =>
          currentCountry.code === phone?.country.toLowerCase(),
      );
      phoneNumber = phone.nationalNumber;
    }
    let newAddress = {
      name: user.name,
      phoneNumber: phoneNumber,
      phoneCountry: phoneCountry,
    };

    if (!user.state || this.states.indexOf(user.state) >= 0) {
      newAddress['addressLine'] = user.address;
      newAddress['stateZipCode'] = {zipCode: user.zipCode, state: user.state};
      newAddress['city'] = user.city;
      this.isLocalProfileAddress = true;
    } else {
      this.isLocalProfileAddress = false;
    }
    this.patchValue(newAddress);
  }

  fillAddress(address: ShipmentAddress): void {
    let phoneNumber;
    let phoneCountry;
    if (!!address.phone) {
      const phone = parsePhoneNumber(
        address.phone,
        environment.locale.user.defaultPhoneCode.toUpperCase() as CountryCode,
      );
      phoneCountry = this.countries.find(
        (currentCountry: Country) =>
          currentCountry.code === phone.country.toLowerCase(),
      );
      phoneNumber = phone.nationalNumber;
    }
    const newAddress = {
      name: address.name,
      phoneNumber: phoneNumber,
      phoneCountry: phoneCountry,
      addressLine: address.address,
      stateZipCode: {zipCode: address.zipCode, state: address.state},
      city: address.city,
    };
    this.isLocalProfileAddress =
      !address.state || (!!this.states && this.states.indexOf(address.state) >= 0);
    this.patchValue(newAddress);
  }

  toBackend(): any {
    return {
      nombre: this.value.name,
      telefono: `+${this.value.phoneCountry.prefix}${this.value.phoneNumber}`,
      direccion: this.value.addressLine,
      poblacion: this.value.city,
      codPostal: this.value.stateZipCode.zipCode,
      provincia: this.value.stateZipCode.state,
      clientObservations: this.value.observations,
    };
  }
}
