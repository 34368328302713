import {
  animate,
  animation,
  AnimationReferenceMetadata,
  style,
} from '@angular/animations';

export let slideInHorizontal: AnimationReferenceMetadata = animation([
  style({transform: 'translate3D({{start}}, 0, 0)', opacity: 0}),
  animate('{{timing}}'),
]);

export let slideOutHorizontal: AnimationReferenceMetadata = animation([
  animate(
    '{{timing}}',
    style({transform: 'translate3D({{end}}, 0, 0)', opacity: 0}),
  ),
]);

export let slideInVertical: AnimationReferenceMetadata = animation([
  style({transform: 'translate3D(0, {{start}}, 0)', opacity: 0}),
  animate('{{timing}}'),
]);

export let slideOutVertical: AnimationReferenceMetadata = animation([
  animate(
    '{{timing}}',
    style({transform: 'translate3D(0, {{end}}, 0)', opacity: 0}),
  ),
]);
