import {ContentChildren, Directive, QueryList} from '@angular/core';

import {TooltipFormMessageDirective} from './tooltip-form-message.directive';
import {TooltipService} from './tooltip.service';

@Directive({selector: '[tlTooltipForm]'})
export class TooltipFormDirective {
  @ContentChildren(TooltipFormMessageDirective, {descendants: true})
  formMessages: QueryList<TooltipFormMessageDirective>;

  constructor(private tooltipService: TooltipService) {}

  /**
   * Shows the tooltip of the first control with error
   *
   * @param order 'asc': shows the first error, 'desc': shows the last error
   */
  showFirstInvalid(order: 'asc' | 'desc') {
    let formMessagesArray = this.formMessages.toArray();
    if (order === 'desc') {
      formMessagesArray.reverse();
    }
    const firstInvalid = formMessagesArray.find(
      formMessage => formMessage.control.invalid,
    );

    if (firstInvalid) {
      this.tooltipService.show(firstInvalid.id);
    }
  }
}
