import {Serializable} from 'common';

class SusbcriptionNotExecutedInternal {
  id: number;
  ticketId: number;
  drawDate: number;
}

export class SubscriptionNotExecuted extends Serializable(
  SusbcriptionNotExecutedInternal,
) {
  static createFromBackend(obj: any): SubscriptionNotExecuted {
    let susbcriptionNotExecuted: SubscriptionNotExecuted =
      new SubscriptionNotExecuted();
    susbcriptionNotExecuted.id = obj.abonoId;
    susbcriptionNotExecuted.ticketId = obj.boletoId;
    susbcriptionNotExecuted.drawDate = obj.drawDate;
    return susbcriptionNotExecuted;
  }
}
