import {Serializable} from 'common';

class PositionDataInternal {
  startX: number;

  startY: number;

  endX: number;

  endY: number;

  backgroundStartX: number;

  backgroundStartY: number;

  backgroundEndX: number;

  backgroundEndY: number;
}

export class PositionData extends Serializable(PositionDataInternal) {
  static createFromBackend(obj: any) {
    let position = new PositionData();

    position.startX = obj.startX;
    position.startY = obj.startY;
    position.endX = obj.endX;
    position.endY = obj.endY;
    position.backgroundStartX = obj.backgroundStartX;
    position.backgroundStartY = obj.backgroundStartY;
    position.backgroundEndX = obj.backgroundEndX;
    position.backgroundEndY = obj.backgroundEndY;

    return position;
  }
}
