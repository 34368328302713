import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {Subscription} from 'rxjs';

import {ResponsiveService} from '../responsive.service';

/**
 * Applies a transform: scale(x) to the component to fill available space in the
 * parent component, as specified by the scaling type.
 */
@Directive({selector: '[tlScaleToParent]'})
export class ScaleToParentDirective implements OnInit, OnChanges, OnDestroy {
  /**
   * Scale mode:
   *  - 'height' scale to match parent height
   *  - 'width' scale to match parent width
   *  - 'fit' scale to match parent width or height, ensuring no overflow
   */
  @Input('tlScaleToParent')
  type: 'height' | 'width' | 'fit' = 'fit';

  /**
   * Offset to apply to the element width before computing the resize.
   */
  @Input()
  offsetX = 0;

  /**
   * Offset to apply to the element height before computing the resize.
   */
  @Input()
  offsetY = 0;

  /**
   * To hide the component while the transform is applied.
   */
  @HostBinding('style.visibility')
  visibility = 'hidden';

  /**
   * Max scaling ratio.
   */
  @Input()
  maxScale = Infinity;

  @Output()
  scale = new EventEmitter<number>();

  private subscription: Subscription;

  private childMutationObserver: MutationObserver;

  private lastScaleXY = [0, 0];

  constructor(
    private cdr: ChangeDetectorRef,
    private element: ElementRef,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
    private zone: NgZone,
  ) {}

  @HostListener('load')
  recompute() {
    this.computeHeight();
  }

  ngOnInit(): void {
    Promise.resolve(undefined).then(() => this.computeHeight());

    this.zone.runOutsideAngular(() => {
      this.subscription = this.responsiveService.resize.subscribe(() =>
        this.computeHeight(),
      );

      this.childMutationObserver = new MutationObserver(() => this.computeHeight());

      this.childMutationObserver.observe(this.element.nativeElement, {
        childList: true,
        subtree: true,
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type.firstChange) {
      return;
    }

    if (
      changes.hasOwnProperty('type') ||
      changes.hasOwnProperty('offsetX') ||
      changes.hasOwnProperty('offsetY')
    ) {
      this.computeHeight();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.childMutationObserver) {
      this.childMutationObserver.disconnect();
    }
  }

  private computeHeight(): void {
    const parentHeight = this.element.nativeElement.parentNode.offsetHeight;
    const elementHeight = this.element.nativeElement.scrollHeight + this.offsetY;

    const parentWidth = this.element.nativeElement.parentNode.offsetWidth;
    const elementWidth = this.element.nativeElement.scrollWidth + this.offsetX;

    const scaleHeight = Math.min(
      Math.floor((parentHeight * 100) / elementHeight) / 100,
      this.maxScale,
    );
    const scaleWidth = Math.min(
      Math.floor((parentWidth * 100) / elementWidth) / 100,
      this.maxScale,
    );

    if (this.lastScaleXY[0] === scaleWidth && this.lastScaleXY[1] === scaleHeight) {
      return;
    }

    this.lastScaleXY = [scaleWidth, scaleHeight];

    let scale: number;
    switch (this.type) {
      case 'height':
        scale = scaleHeight;
        break;
      case 'width':
        scale = scaleWidth;
        break;
      default:
        scale = Math.min(scaleHeight, scaleWidth);
    }

    this.zone.run(() => {
      this.renderer.setStyle(
        this.element.nativeElement,
        'transform',
        `scale3D(${scale},${scale},1)`,
      );

      this.visibility = '';
      this.scale.emit(scale);
      // required for onpush environments
      this.cdr.markForCheck();
    });
  }
}
