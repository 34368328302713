import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {Observable, of} from 'rxjs';

import {NO_TL_CONTACTS_FIXTURE_E2E} from './no-tl-contact.fixture.e2e';
import {TlContact} from './tl-contact';
import {TLUPLOADED_CONTACTS_FIXTURE_E2E} from './tl-contact.fixture.e2e';

@Injectable()
export class ContactsServiceMockE2e extends AbstractObservableDataService<
  Array<TlContact>
> {
  constructor() {
    super();
    this.setData(this.getContactsFromFixture());
  }

  /**
   * Filter only registered contacts
   */
  getTuloteroContacts(): Observable<Array<TlContact>> {
    return of(this.getContactsFromFixture().filter(c => c.isTulotero()));
  }

  /**
   * Filter only unregistered contacts
   */
  getUnregisteredContacts(): Observable<Array<TlContact>> {
    return of(this.getContactsFromFixture().filter(c => !c.isTulotero()));
  }

  loadFromGoogle(): Observable<Array<TlContact>> {
    const contacts = this.getNoTlContactsFromFixture();
    this.setData(contacts);
    return of(contacts);
  }

  hasPermission(): Observable<boolean> {
    return of(true);
  }

  clearData() {}

  private getContactsFromFixture(): Array<TlContact> {
    return TLUPLOADED_CONTACTS_FIXTURE_E2E.map(c => TlContact.fromJSON(c));
  }

  private getNoTlContactsFromFixture(): Array<TlContact> {
    return NO_TL_CONTACTS_FIXTURE_E2E.map(c => TlContact.fromJSON(c));
  }
}
