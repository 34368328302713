import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {TriggerStyleModule} from '../../styles-triggers/trigger-style.module';
import {I18nModule} from '../../i18n/i18n.module';
import {ScrollableModule} from '../../scrolling/scrollable/scrollable.module';
import {UtilModule} from '../../util/util-module';
import {InputCountryComponent} from './input-country.component';
import {TranslatableTextModule} from '../../i18n/translatable-text/translatable-text.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    ScrollableModule,
    TranslatableTextModule,
    TriggerStyleModule,
    UtilModule,
  ],
  exports: [InputCountryComponent],
  declarations: [InputCountryComponent],
})
export class InputCountryModule {}
