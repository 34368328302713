import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms/forms';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Pipe({
  name: 'controlValue',
})
export class ControlValuePipe implements PipeTransform {
  transform(control: AbstractControl): Observable<any> {
    return control.valueChanges.pipe(startWith(control.value));
  }
}
