export function base64ToBlob(base64Data, contentType, sliceSize?: number): Blob {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let base64 = base64Data.replace(/^data:image\/([a-z]+);base64,/, '');
  let byteCharacters = atob(base64);
  let bytesArray = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    bytesArray.push(byteArray);
  }

  return new Blob(bytesArray, {type: contentType});
}
