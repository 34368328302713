import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {TranslationsService} from '../../i18n/translations.service';
import {first, map} from 'rxjs/operators';

@Pipe({
  name: 'listToSentence',
})
export class ListToSentencePipe implements PipeTransform {
  private asyncPipe: AsyncPipe;

  constructor(
    cdr: ChangeDetectorRef,
    private translationsService: TranslationsService,
  ) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(list: Array<string | number>): string {
    if (!list) {
      return '';
    }
    if (!Array.isArray(list)) {
      return list;
    }

    if (list.length === 0) {
      return '';
    }

    if (list.length === 1) {
      return list[0].toString();
    }

    const allButLast = list.slice(0, -1).join(', ');
    const last = list[list.length - 1];

    return this.asyncPipe.transform(
      this.translationsService.getTranslation('global.and').pipe(
        first(),
        map(and => `${allButLast} ${and} ${last}`),
      ),
    );
  }
}
