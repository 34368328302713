import {compileString, Serializable} from 'common';

import {ActionButtonPlatform} from './action-button-platform';

class ActionButtonInternal {
  type: string;

  contact: string;

  label: string;

  message: string;

  urlDesktop: string;

  urlMobile: string;

  logo: string;

  color: string;

  borderColor: string;

  backgroundTop: string;

  backgroundBottom: string;

  platform: ActionButtonPlatform;

  compileStrings(data: {[key: string]: string}) {
    const pattern = /\${(\w+)}/g;

    this.urlDesktop = compileString(this.urlDesktop, pattern, data);
    this.urlMobile = compileString(this.urlMobile, pattern, data);
    this.message = compileString(this.message, pattern, data);
  }
}

export class ActionButton extends Serializable(ActionButtonInternal) {
  static createFromBackend(obj: any) {
    let actionButton = new ActionButton();

    actionButton.type = (obj.types[0] || '').toLowerCase();
    actionButton.contact = obj.contact;
    actionButton.label = obj.label;
    actionButton.message = obj.message;
    actionButton.urlDesktop = obj.url;
    actionButton.urlMobile = obj.url;
    actionButton.logo = obj.imageSvg;
    actionButton.color = obj.fontColor;
    actionButton.borderColor = obj.borderColor;
    actionButton.backgroundTop = obj.firstColor;
    actionButton.backgroundBottom = obj.secondColor;

    let desktop = obj.platforms.includes('WEB_DESKTOP');
    let mobile = obj.platforms.includes('MOBILE_WEB');

    if (desktop && mobile) {
      actionButton.platform = ActionButtonPlatform.ALL;
    } else if (!desktop) {
      actionButton.platform = ActionButtonPlatform.MOBILE;
    } else {
      actionButton.platform = ActionButtonPlatform.DESKTOP;
    }

    return actionButton;
  }
}
