import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {ngModelProvider} from '../../../model/ng-model-config';
import {AbstractNgModel} from '../../abstract-ngmodel';
import {Country} from '../../../countries/country';
import {PhoneUnformatPipe} from '../../../util/phone/phone-unformat.pipe';
import {ENVIRONMENT} from '../../../environment/environment-token';
import {AsYouType, CountryCode, parsePhoneNumber} from 'libphonenumber-js/max';
import {fixPhoneNumber} from '../../../util/phone/phone';

@Component({
  selector: 'tl-input-phone-local',
  templateUrl: './input-phone-local.component.html',
  styleUrls: ['./input-phone-local.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ngModelProvider(InputPhoneLocalComponent)],
})
export class InputPhoneLocalComponent
  extends AbstractNgModel<string>
  implements OnChanges
{
  @Input('value')
  model!: string;

  @Input()
  country!: Country;

  @Input('readonly')
  readonly = false;

  @Input()
  id = Math.random().toString();

  @Input()
  placeholder = '';

  @Input()
  ngTabindex: number;

  disabled = false;

  inputValue: string;

  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('country')) {
      this.updateModel(this.model);
    }
  }

  onInput(value: string): void {
    this.updateModel(value);
  }

  writeValue(value: string) {
    this.updateModel(value, false);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onBlur(e: Event): void {
    this.updateFormat(this.model, true);
  }

  private updateModel(value: string, notify = true): void {
    const unformatter = new PhoneUnformatPipe(this.environment);
    this.model = unformatter.transform(value, this.country?.code);
    this.updateFormat(value);
    this.cd.markForCheck();

    if (notify) {
      this.modelChange(this.model);
    }
  }

  private updateFormat(value: string, checkIfValid: boolean = false): void {
    this.inputValue = this.getNumberFormatted(
      value,
      this.country?.prefix,
      checkIfValid,
    );
    this.cd.markForCheck();
  }

  private getNumberFormatted(
    value: string,
    preffix: string,
    checkIfValid: boolean,
  ): string {
    const localeCountryCode = this.environment.locale.user.defaultPhoneCode;
    const defaultcountryCode = localeCountryCode.toUpperCase();

    if (value?.length && preffix) {
      try {
        if (checkIfValid) {
          const valueFormatted = `+${preffix}${value}`;
          const valueFixed = fixPhoneNumber(valueFormatted, defaultcountryCode);
          const phoneNumber = parsePhoneNumber(valueFixed, defaultcountryCode);

          if (phoneNumber.isValid()) {
            return phoneNumber.formatNational();
          }
          throw new Error('not valid number');
        }
        return new AsYouType(this.country.code.toUpperCase() as CountryCode).input(
          value,
        );
      } catch {
        return value;
      }
    }
    return value;
  }
}
