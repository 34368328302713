import {ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class CustomNgbModal extends NgbModal {
  open(content: any, options: NgbModalOptions = {}): NgbModalRef {
    const injector: Injector = options.injector || this['_injector'];
    const cfr = injector.get<ComponentFactoryResolver>(
      ComponentFactoryResolver as any,
      this['_moduleCFR'],
    );
    const combinedOptions = Object.assign({}, this['_config'], options);

    return this['_modalStack'].open(
      cfr,
      this['_injector'],
      content,
      combinedOptions,
    );
  }
}
