import {AbstractObservableDataService, isEqual} from 'common';

import {Filter} from './filter';

export abstract class AbstractFiltersService extends AbstractObservableDataService<
  Array<Array<Filter>>
> {
  protected currentFilters: Array<Array<Filter>>;

  abstract readonly DEFAULT_FILTERS: Record<string, any>;

  setFiltersFromBackend(
    dynamicFilters: Array<Filter>,
    staticFilters: Array<string>,
  ) {
    let filters = [];

    for (let filter in this.DEFAULT_FILTERS) {
      if (staticFilters.includes(filter)) {
        if (!filters[this.DEFAULT_FILTERS[filter].section]) {
          filters[this.DEFAULT_FILTERS[filter].section] = [];
        }

        filters[this.DEFAULT_FILTERS[filter].section].push(
          Filter.fromJSON(this.DEFAULT_FILTERS[filter].args),
        );
      } else if (filter === '@games') {
        if (!filters[this.DEFAULT_FILTERS[filter].section]) {
          filters[this.DEFAULT_FILTERS[filter].section] = [];
        }

        filters[this.DEFAULT_FILTERS[filter].section].push(...dynamicFilters);
      }
    }

    if (!this.currentFilters || !isEqual(this.currentFilters, filters)) {
      this.currentFilters = filters.filter(x => !!x);
      this.setData(this.currentFilters);
    }
  }
}
