import {Serializable} from 'common';

export class StateRetailerInternal {
  address: string;
  logo: string;
}

export class StateRetailer extends Serializable(StateRetailerInternal) {
  static createFromBackend(obj: any) {
    let retailer = new StateRetailer();

    retailer.address = obj.address;
    retailer.logo = obj.logo;

    return retailer;
  }
}
