import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HTTP_ENDPOINT} from './config-tokens';
import {LocaleService} from '../i18n/locale.service';

@Injectable()
export class LanguageHeaderInterceptor implements HttpInterceptor {
  constructor(
    @Inject(HTTP_ENDPOINT) private endpoint: string,
    private localeService: LocaleService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.endpoint)) {
      const localeCode = this.localeService.getLocale();
      const headers = req.headers.set('Accept-Language', localeCode);
      return next.handle(req.clone({headers: headers}));
    }

    return next.handle(req);
  }
}
