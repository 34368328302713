import {EventEmitter, Injectable} from '@angular/core';

import {MessageHookStorageService} from '../message-hooks/message-hook-storage.service';

@Injectable({providedIn: 'root'})
export class TooltipService {
  readonly closeListener = new EventEmitter();

  readonly listener = new EventEmitter();

  private volatileStorage = new Set<string>();

  constructor(private messageHookStorageService: MessageHookStorageService) {}

  show(tooltipId: string | Array<string>) {
    const ids = Array.isArray(tooltipId) ? tooltipId : [tooltipId];

    ids.filter(id => !this.hasShown(id)).forEach(id => this.listener.emit(id));
  }

  showOnce(tooltipId: string | Array<string>) {
    const ids = Array.isArray(tooltipId) ? tooltipId : [tooltipId];
    ids
      .filter(id => !this.hasShown(id))
      .forEach(id => {
        this.listener.emit(id);
        this.markAsShown(id);
      });
  }

  showVolatile(tooltipId: string | Array<string>): void {
    const ids = Array.isArray(tooltipId) ? tooltipId : [tooltipId];
    ids
      .filter(id => !this.hasShownVolatile(id))
      .forEach(id => {
        this.listener.emit(id);
        this.markAsShownVolatile(id);
      });
  }

  hasShown(id: string) {
    return this.messageHookStorageService.exists(id);
  }

  markAsShown(id: string) {
    this.messageHookStorageService.save(id);
  }

  markAsNotShown(id: string) {
    this.messageHookStorageService.remove(id);
  }

  hasShownVolatile(id: string): boolean {
    return this.volatileStorage.has(id);
  }

  markAsShownVolatile(id: string): void {
    this.volatileStorage.add(id);
  }

  clearVolatileStorage(): void {
    this.volatileStorage.clear();
  }

  close(tooltipId: string | Array<string>): void {
    const ids = Array.isArray(tooltipId) ? tooltipId : [tooltipId];

    ids.forEach(id => this.closeListener.emit(id));
  }

  closeAll(): void {
    this.closeListener.emit(ALLTOOLTIPID);
  }
}

export const ALLTOOLTIPID = 'all_tooltips';
