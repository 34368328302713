/**
 * Generates an array with the amount of each selection length, in the form
 * [amountSimples, amountDoubles, amountTriples, ...]
 */
export function generateSelectionsArray(
  values: Array<any> | Array<Array<any>>,
): Array<number> {
  let combinations = []; // simples, doubles, triples,...
  values.forEach(row => {
    if (Array.isArray(row) && row.length) {
      combinations[row.length - 1] = (combinations[row.length - 1] || 0) + 1;
    } else if (!Array.isArray(row) && !!row) {
      combinations[0] = (combinations[0] || 0) + 1;
    }
  });

  for (let i = 0; i < combinations.length; i++) {
    combinations[i] = combinations[i] || 0;
  }

  return combinations;
}

/**
 * Calculates the number of bets from the quantity of multiple combinations.
 *
 * Optionally a second array and a base multiplier can be provided to exclude
 * some of the multiple selections and calculate the multiplier from the base.
 * The minmultiples array must be of equal or less length than the multiples.
 */
export function multiplierFromMultiples(
  multiples: Array<number>,
  minMultiples?: Array<number>,
  baseMultiplier?: number,
): number {
  if (!multiples.length || multiples.reduce((total, q) => q + total, 0) === 0) {
    return 0;
  }

  let normalizedMultiples: Array<number>;
  if (minMultiples && baseMultiplier) {
    normalizedMultiples = multiples.map((amount, index) =>
      Math.max(amount - minMultiples[index], 0),
    );
  } else {
    normalizedMultiples = multiples;
  }

  return (
    normalizedMultiples.reduce(
      (total, amount, index) => Math.pow(index + 1, amount) * total,
      1,
    ) * (baseMultiplier || 1)
  );
}
