export class CardData {
  firstName: string;

  lastName: string;

  middleName: number;

  fullName: string;

  address: number;

  state: string;

  city: string;

  zipCode: string;

  country: string;

  sex: string;

  eyeColor: string;

  documentType: string;

  driverLicenseNumber: string;

  licenseExpirationDate: string;

  dateOfBirth: string;

  licenseIssuedDate: string;

  static createFromBackend(response: any): CardData {
    const data = new CardData();

    data.firstName = response.firstName;
    data.lastName = response.lastName;
    data.middleName = response.middleName;
    data.fullName = response.fullName;
    data.address = response.address;
    data.state = response.state;
    data.city = response.city;
    data.zipCode = response.zipCode;
    data.country = response.country;
    data.sex = response.sex;
    data.eyeColor = response.eyeColor;
    data.documentType = response.documentType;
    data.driverLicenseNumber = response.driverLicenseNumber;
    data.licenseExpirationDate = response.licenseExpirationDateString;
    data.dateOfBirth = response.dateOfBirthString;
    data.licenseIssuedDate = response.licenseIssuedDateString;

    return data;
  }
}
