import {DecimalPipe} from '@angular/common';

export class News {
  id: number;

  headLine: string;

  content: string;

  comments: number;

  views: number;

  date: number;

  image: string;

  squareImage: string;

  videoUrl: string;

  articleUrl: string;

  layout: 'normal' | 'important' = 'normal';

  type: string;

  static createFromBackend(obj: any) {
    let news = new News();

    news.id = obj.id;
    news.headLine = obj.title;
    news.content = obj.content;
    news.comments = obj.comments;
    news.views = obj.views;
    news.date = obj.date;
    news.image = obj.mainImg;
    news.squareImage = obj.img;
    news.videoUrl = obj.urlVideo;
    news.articleUrl = obj.url;
    news.layout = obj.layout ? obj.layout.toLowerCase() : 'normal';
    news.type = obj.category ? obj.category.toLowerCase() : 'Noticia';

    return news;
  }

  getFormattedViews(locale: string): string {
    const bigNumber = this.views > 1000;
    let formattedViews: string;

    if (bigNumber) {
      formattedViews =
        new DecimalPipe(locale).transform(this.views / 1000, '1.0-1') + 'K';
    } else {
      formattedViews = this.views + '';
    }

    return formattedViews;
  }
}
