<div class="form-group">
  <label class="sr-only" [for]="id"></label>
  <div class="input-group">
    <div class="input-group-prepend" *ngIf="showCardIcon">
      <div class="input-group-text">
        <img
          [src]="
            image ? image : 'assets/img/saldo/tarjeta-credito/no-detected.svg'
          " />
      </div>
    </div>
    <input
      class="form-control"
      inputmode="numeric"
      pattern="[0-9]*"
      maxlength="19"
      autocomplete="cc-number"
      [tlTriggerOnKeyboardOpen]="65"
      [id]="id"
      [tabindex]="ngTabindex"
      (input)="onInput($event)"
      (keydown)="inputKeyDown($event)"
      (paste)="onPaste($event)"
      [placeholder]="placeholder" />
  </div>
  <ng-content></ng-content>
</div>
