/**
 * Keeps the state of the view related to each booth in the shipments screen
 */
export class BoothShipmentStatus {
  // true when the image and adress of the booth is shown
  isDetailShown: boolean;

  shipmentCost: number;

  // true when the first tickets of this booth is loaded
  firstTicketLoaded: boolean;

  // true when the tickets are being loaded
  isLoading: boolean;

  // true when every available ticket has been loaded
  allLoaded: boolean;

  constructor() {
    this.isDetailShown = false;
    this.shipmentCost = 0;
    this.firstTicketLoaded = false;
    this.isLoading = false;
    this.allLoaded = false;
  }
}
