import {NgModule} from '@angular/core';
import {TlControlClassDirective} from './control-class.directive';

@NgModule({
  imports: [],
  exports: [TlControlClassDirective],
  declarations: [TlControlClassDirective],
  providers: [],
})
export class TlControlStylesModule {}
