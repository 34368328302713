import {Inject, Injectable} from '@angular/core';
import {Observable, ReplaySubject, from} from 'rxjs';
import {first, map, switchMap, take, tap} from 'rxjs/operators';
import {loadScript} from '../util/script';
import {AppleAuthService, AppleUser} from './apple-auth.service';
import {APPLE_CLIENT_ID} from './config-tokens';
import {ENVIRONMENT} from '../environment/environment-token';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppleID: any;

@Injectable()
export class AppleAuthSdkService extends AppleAuthService {
  /**
   * Check apple api script is lazy loaded
   */
  private initialized = false;
  private scriptLoadObserver = new ReplaySubject<any>(1);
  private _user: AppleUser;

  private readonly scriptUrl =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

  constructor(
    @Inject(APPLE_CLIENT_ID) private appleClientId: string,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {
    super();
  }

  public login(): Observable<AppleUser> {
    return this.initialize().pipe(
      first(),
      switchMap(() =>
        from(AppleID.auth.signIn()).pipe(
          map((data: any) => {
            return {
              idToken: data.authorization.id_token,
              email: data.user ? data.user.email : '',
              name:
                '' + (data.user?.name?.firstName ? data.user?.name?.firstName : ''),
            };
          }),
          tap((user: AppleUser) => (this._user = user)),
        ),
      ),
    );
  }

  getCurrentUser(): Observable<AppleUser> {
    if (!this.initialized) {
      throw new Error('Apple auth not initialized');
    }
    return this.scriptLoadObserver.pipe(
      take(1),
      map(() => this._user),
    );
  }

  private initialize(): Observable<void> {
    if (!this.initialized) {
      this.initialized = true;
      loadScript('apple-api', this.scriptUrl)
        .pipe(
          tap(() => {
            AppleID.auth.init({
              clientId: this.appleClientId,
              scope: 'name email',
              redirectURI: this.environment.appleRedirectUri,
              state: 'Solicitud de autenticación de usuario inicial',
              nonce: '',
              usePopup: true,
            });
          }),
          map(() => {}),
        )
        .subscribe({
          next: data => this.scriptLoadObserver.next(true),
          error: error => {
            this.initialized = false;
            this.scriptLoadObserver.error(error);
          },
        });
    }

    return this.scriptLoadObserver.asObservable();
  }
}
