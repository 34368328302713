import {InjectionToken} from '@angular/core';

/*
  Usamos el token para definir la tabla y la base de datos.
  Con esta implementacion se pretende reproducir dentro de la indexedDB
  un localstorage almacenando clave-valor,
  por lo que el nombre de la tabla usaremos el mismo que el de la base de datos
  ya que no tendremos relaciones entre tablas y sera como definir una base por
  defecto
*/
export const ASYNC_LOCAL_STORAGE_TOKEN = new InjectionToken<string>(
  'AsyncLocalStorageToken',
);
