import {Inject, Injectable, InjectionToken} from '@angular/core';
import FP, {Agent} from '@fingerprintjs/fingerprintjs-pro';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {Logger} from '../../logger/logger';

export const FINGERPRINT_PRO_TOKEN = new InjectionToken<string>(
  'FingerprintPROToken',
);
export const FINGERPRINT_PRO_ENDPOINT = new InjectionToken<string>(
  'FingerprintPROEndpoint',
);

@Injectable()
export abstract class FingerprintStrategy {
  abstract generate(): Observable<string>;
}

@Injectable()
export class FingerprintPROStrategy extends FingerprintStrategy {
  private library: Observable<Agent>;

  constructor(
    @Inject(FINGERPRINT_PRO_TOKEN) token: string,
    @Inject(FINGERPRINT_PRO_ENDPOINT) endpoint: string,
    private logger: Logger,
  ) {
    super();
    // Prevent load fingerprint in tests
    if (window && (<any>window).jasmine) {
      throw new Error("Fingerprint PRO can't be used in tests");
    }
    this.library = from(
      FP.load({
        apiKey: token,
        endpoint: [endpoint, FP.defaultEndpoint],
        scriptUrlPattern: [
          `${endpoint}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
          FP.defaultScriptUrlPattern,
        ],
      }),
    ).pipe(
      catchError(error => {
        this.logger.warn('Error loading Fingerprint js PRO, adblock?', error);
        return throwError(() => error);
      }),
    );
  }

  generate(): Observable<string> {
    return this.library.pipe(
      switchMap(fp => fp.get()),
      map(response => response.visitorId),
      catchError(error => {
        this.logger.warn(
          'Error generating fingerprint with fingerprintjs pro',
          error,
        );
        return throwError(() => error);
      }),
    );
  }
}

@Injectable()
export class FakeFingerprintStrategy extends FingerprintStrategy {
  private fakeFingerprint =
    'fake_fingerprint_' + Math.random().toString().replace('.', '');

  generate(): Observable<string> {
    return of(this.fakeFingerprint);
  }
}
