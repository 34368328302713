import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';

import {PopstateService} from './popstate.service';

@Injectable()
export class NoForwardGuard implements CanActivate, CanDeactivate<any> {
  constructor(private popstateService: PopstateService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.popstateService.lastPopState !== 'forward';
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isForward = this.popstateService.lastPopState === 'forward';

    if (isForward && typeof component.onPopstate === 'function') {
      return component.onPopstate('forward');
    }

    return !isForward;
  }
}
