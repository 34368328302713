import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Sentence} from './sentence';

@Injectable({providedIn: 'root'})
export class SentencesService extends AbstractObservableDataService<
  Array<Sentence>
> {
  getRandomSentence(): Observable<Sentence> {
    return this._data.pipe(
      map(sentences => {
        const randomOffset: number = Math.floor(Math.random() * sentences.length);
        return sentences[randomOffset];
      }),
    );
  }
}
