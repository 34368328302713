import {Inject, Pipe, PipeTransform} from '@angular/core';
import {CountryCode, parsePhoneNumber} from 'libphonenumber-js/max';
import {ENVIRONMENT} from '../../environment/environment-token';
import {Logger} from '../../logger/logger';
import {fixPhoneNumber} from './phone';

@Pipe({name: 'phoneLink'})
export class PhoneLinkPipe implements PipeTransform {
  protected defaultCountryCode: CountryCode;

  constructor(
    @Inject(ENVIRONMENT) protected environment: Record<string, any>,
    protected logger: Logger,
  ) {
    const localeCountryCode = environment.locale.user.defaultPhoneCode;
    this.defaultCountryCode = localeCountryCode.toUpperCase();
  }

  transform(value: string): any {
    if (!value) {
      return value;
    }

    const valueFixed = fixPhoneNumber(value, this.defaultCountryCode);
    const phoneNumber = parsePhoneNumber(valueFixed, this.defaultCountryCode);

    if (phoneNumber.isValid()) {
      return phoneNumber.getURI();
    } else {
      this.logger.error('Error generate phone link.', undefined, {
        number: value,
        numberFixed: valueFixed,
        countryCode: this.defaultCountryCode,
      });
      return `tel:${valueFixed}`;
    }
  }
}
