export enum MergeStrategy {
  /**
   * Finds a element and replace by the new one, if not exists, add at the end.
   */
  REPLACE,
}

export function merge<T>(
  collection: Array<T>,
  newCollection: Array<T>,
  comparator: (a: T, b: T) => unknown,
  strategy: MergeStrategy,
) {
  switch (strategy) {
    case MergeStrategy.REPLACE:
      mergeReplaceCollectionStrategy(collection, newCollection, comparator);
      break;
    default:
      throw new Error('Cannot find merge strategy');
  }
}

function mergeReplaceCollectionStrategy<T>(
  collection: Array<T>,
  newColletion: Array<T>,
  comparator: (a: T, b: T) => unknown,
) {
  newColletion.forEach(newElement => {
    const index = collection.findIndex(element => comparator(element, newElement));
    if (index >= 0) {
      collection[index] = newElement;
    } else {
      collection.push(newElement);
    }
  });
}
