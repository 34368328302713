import {
  Directive,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {createPrefixFormatter} from '../../util/keyboard/format';

@Directive({selector: 'input[tlPrefix]'})
export class PrefixInputDirective implements OnChanges {
  @Input()
  tlPrefix: string;

  private prefixer: (...args: any[]) => any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('tlPrefix')) {
      if (!!this.tlPrefix) {
        this.prefixer = createPrefixFormatter(this.tlPrefix);
      } else {
        this.prefixer = null;
      }
    }
  }

  @HostListener('input', ['$event'])
  @HostListener('keydown', ['$event'])
  onInput(event: any): boolean {
    if (!this.prefixer) {
      return;
    }

    return this.prefixer(event);
  }
}
