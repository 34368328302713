import {Serializable, SerializableDisableWarning} from 'common';

/**
 * Used to display the betselector modal with different sections
 */
class BetSectionInternal {
  id: string;

  @SerializableDisableWarning()
  bets: Array<string>;

  text: string;

  /**
   * This assumes the rules for this bet live in subsection of the rules for the
   * game denoted by the fragment, so internally only the fragment is saved
   * to scroll the game's html.
   */
  rules: string;
}

export class BetSection extends Serializable(BetSectionInternal) {
  static createFromBackend(obj: any): BetSection {
    let section = new BetSection();

    section.id = obj.id;
    section.bets = obj.bets;
    section.text = obj.label || null;

    if (obj.info) {
      section.rules = obj.info.split('#').pop();
    }

    return section;
  }
}
