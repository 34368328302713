import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AlertModule} from 'common';

import {BrowserSupportComponent} from './browser-support.component';

@NgModule({
  imports: [AlertModule, CommonModule],
  exports: [BrowserSupportComponent],
  declarations: [BrowserSupportComponent],
})
export class BrowserSupportModule {}
