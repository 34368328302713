import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CurrencyModule, I18nModule} from 'common';

import {TransferLimitsContentComponent} from './transfer-limits-content.component';

@NgModule({
  declarations: [TransferLimitsContentComponent],
  exports: [TransferLimitsContentComponent],
  imports: [CommonModule, CurrencyModule, I18nModule],
})
export class TransferLimitsContentModule {}
