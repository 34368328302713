<div *ngIf="controlCountry && controlLocal" class="tl-input-phone">
  <tl-input-phone-country
    class="tl-input-phone__country"
    [formControl]="controlCountry">
  </tl-input-phone-country>

  <tl-input-phone-local
    class="tl-input-phone__local"
    [tlControlClass]="submitted"
    [formControl]="controlLocal"
    [id]="id"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [tabIndex]="ngTabindex"
    [country]="selectedCountry"></tl-input-phone-local>
</div>
