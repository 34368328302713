import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ModalHelperService, ResponsiveService} from 'common';
import {Observable, throwError} from 'rxjs';
import {catchError, retryWhen, switchMap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {TuLoteroServerError} from '../../error/tulotero-server-error';

import {CodeVerificationDialogComponent} from './dialog/code-verification-dialog.component';
import {VerificationDialogComponent} from './verification-dialog/verification-dialog.component';

@Injectable()
export class VerificationInterceptor implements HttpInterceptor {
  constructor(
    private modalHelperService: ModalHelperService,
    private responsiveService: ResponsiveService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(e =>
        e.pipe(
          switchMap(error => {
            if (error.status === 426) {
              return this.launchVerificationModal(error).pipe(
                catchError(() =>
                  throwError(
                    () =>
                      new TuLoteroServerError(
                        403,
                        'no_verificado',
                        'userProfile.verifyPhone.verificationBrowserNeeded',
                        true,
                      ),
                  ),
                ),
              );
            } else {
              return throwError(() => error);
            }
          }),
        ),
      ),
    );
  }

  private launchVerificationModal(error: any): Observable<any> {
    const modalOptions = {
      centered: true,
      windowClass: this.responsiveService.isDesktop() ? 'wide' : '',
    };
    if (environment.features.verificationDialogNewFlow) {
      return this.modalHelperService.openOkCancelModal(VerificationDialogComponent, {
        modalOptions: modalOptions,
        componentParams: {
          title: {key: 'userProfile.verifyPhone.dialogIdentityBrowser'},
          image: 'assets/img/seguridad/nav-pass-blue.png',
          info: error.error.info,
        },
      });
    } else {
      return this.modalHelperService.openOkCancelModal(
        CodeVerificationDialogComponent,
        {
          modalOptions,
        },
      );
    }
  }
}
