import {Injectable} from '@angular/core';
import {AsyncLocalStorageService, LocalStorage} from 'common';
import {EMPTY, iif, Observable, switchMap} from 'rxjs';
import {finalize, first} from 'rxjs/operators';

@Injectable()
export class TuLoteroLocalStorageMigrationService {
  constructor(
    private asyncLocalStorageService: AsyncLocalStorageService,
    private localStorage: LocalStorage,
  ) {}

  migrateToLocalStorage(keys: Array<string>): Array<Observable<void>> {
    return keys.map(key => this.migrateKeyToLocalStorage(key));
  }

  migrateKeyToLocalStorage(key: string): Observable<void> {
    return this.asyncLocalStorageService.getItem(key).pipe(
      switchMap(data => {
        if (!data) {
          return EMPTY;
        }
        this.localStorage.setItem(key, data);
        return this.asyncLocalStorageService.removeItem(key).pipe(first());
      }),
    );
  }

  migrateToIndexedDB(keys: Array<string>): Array<Observable<void>> {
    return keys.map(key => this.migrateKeyToIndexedDB(key));
  }

  migrateKeyToIndexedDB(key: string): Observable<void> {
    const data = this.localStorage.getItem(key);

    return iif(
      () => !!data,
      this.asyncLocalStorageService.setItem(key, data).pipe(
        first(),
        finalize(() => this.localStorage.removeItem(key)),
      ),
      EMPTY,
    );
  }
}
