import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Subscription, debounceTime, fromEvent} from 'rxjs';

@Injectable({providedIn: 'root'})
export class VirtualKeyboardApiService {
  isOpen = new BehaviorSubject<boolean>(false);

  overlaysContent = false;

  private navigator: Navigator;

  private prevViewportHeight: number;

  private changeSubscription: Subscription;

  get isSupported(): boolean {
    return this.navigator && 'virtualKeyboard' in this.navigator;
  }

  private get api() {
    if (!this.isSupported) {
      return;
    }

    return (this.navigator as any).virtualKeyboard as {
      overlaysContent: boolean;
      boundingRect: {x: number; y: number; width: number; height: number};
      show: () => void;
      hide: () => void;
    };
  }

  private get viewport(): VisualViewport {
    return this.window.visualViewport;
  }

  constructor(@Inject('window') private window: Window) {
    this.navigator = this.window.navigator;
  }

  init(): void {
    if (this.isSupported) {
      this.prevViewportHeight = this.viewport.height;
      this.setCssVariables(0);
      this.subscribeChange();
    }
  }

  destroy(): void {
    if (this.isSupported) {
      this.prevViewportHeight = 0;
      this.setCssVariables(0);

      if (this.changeSubscription) {
        this.changeSubscription.unsubscribe();
      }
    }
  }

  setOverlay(): void {
    if (this.isSupported) {
      this.overlaysContent = true;
      this.api.overlaysContent = true;
    }
  }

  unsetOverlay(): void {
    if (this.isSupported) {
      this.overlaysContent = false;
      this.api.overlaysContent = false;
    }
  }

  show(): void {
    if (this.isSupported) {
      this.api.show();
    }
  }

  hide(): void {
    if (this.isSupported) {
      this.api.hide();
    }
  }

  private subscribeChange(): void {
    if (this.isSupported) {
      fromEvent(this.api as any, 'geometrychange').subscribe(() => {
        const isOpen = this.api.boundingRect.height > 0;
        this.isOpen.next(isOpen);
      });
    } else {
      fromEvent(this.viewport, 'resize')
        .pipe(debounceTime(150))
        .subscribe(() => {
          const isOpen = this.checkKeyboardOpen();

          if (isOpen !== this.isOpen.value) {
            this.setCssVariables(
              isOpen ? this.prevViewportHeight - this.viewport.height : 0,
            );

            this.prevViewportHeight = this.viewport.height;
            this.isOpen.next(isOpen);
          }
        });
    }
  }

  private checkKeyboardOpen(): boolean {
    const diff = Math.abs(this.prevViewportHeight - this.viewport.height);

    if (diff < this.window.screen.height / 4) {
      return this.isOpen.value;
    }

    return this.viewport.height < this.prevViewportHeight;
  }

  private setCssVariables(height: number): void {
    (this.window.document.querySelector(':root') as any).style.setProperty(
      '--tl-keyboard-height',
      `${height}px`,
    );
  }
}
