import {Serializable, SerializableProperty} from 'common';

import {CombinationType} from './combination-type';
import {CombinationValue} from './combination-value';

class DigitsCombinationTypeInternal implements CombinationType<CombinationValue> {
  typeId: string;

  type: string;

  @SerializableProperty(CombinationValue)
  value: Array<CombinationValue>;
}

export class DigitsCombinationType extends Serializable(
  DigitsCombinationTypeInternal,
) {
  static createFromBackend(obj: any) {
    let digitsRT = new DigitsCombinationType();

    digitsRT.typeId = obj.typeId;
    digitsRT.type = obj.type;

    if (obj.type === 'GUESS_DIGIT_EXTENDED') {
      digitsRT.value = obj.value.map(v =>
        CombinationValue.createFromBackend({
          value: v.numero,
          highlighted: v.highlighted,
        }),
      );
    } else {
      digitsRT.value = obj.valueAsListString.map(v =>
        CombinationValue.createFromBackend({value: v, highlighted: false}),
      );
    }

    return digitsRT;
  }
}
