import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import Cropper from 'cropperjs';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-img-crop',
  templateUrl: './img-crop.component.html',
  styleUrls: ['./img-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgCropComponent implements OnInit, OnChanges {
  @ViewChild('image', {read: ElementRef, static: true})
  container: ElementRef;

  @Input('image')
  img: string;

  options: Cropper.Options = {
    minContainerHeight: 500,
    minContainerWidth: 500,
    aspectRatio: 1,
    zoomable: false,
    scalable: false,
  };

  private cropper: Cropper;

  @Input('options')
  set customOptions(options: Cropper.Options) {
    this.options = Object.assign(this.options, options);
  }

  ngOnInit() {
    this.cropper = new Cropper(this.container.nativeElement, this.options);

    if (this.img) {
      this.cropper.replace(this.img);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cropper && changes.hasOwnProperty('img')) {
      this.cropper.replace(this.img);
    }
    if (this.cropper && changes.hasOwnProperty('options')) {
      this.cropper.clear();
      this.cropper.destroy();
      this.ngOnInit();
    }
  }

  getCroppedImage(maxWidth = 200) {
    return this.cropper.getCroppedCanvas({maxWidth: maxWidth}).toDataURL();
  }
}
