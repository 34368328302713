import {Inject, Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';
import {ENVIRONMENT} from '../environment-token';

@Injectable({providedIn: 'root'})
export class IfEnvironmentGuard implements CanActivate {
  constructor(@Inject(ENVIRONMENT) private environment: Record<string, any>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const featurePath: string = route.data?.guard.ifEnvironment;
    let condition: boolean;
    condition = featurePath?.split('.').reduce((o, i) => o[i], this.environment);
    if (condition === undefined) {
      console.error(
        `IfEnvironmentGuard: environment path ${featurePath} is undefined`,
      );
      return false;
    }
    return condition;
  }
}
