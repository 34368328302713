import {NgModule} from '@angular/core';

import {FullHeightComponent} from './full-height.component';
import {FullHeightDirective} from './full-height.directive';

@NgModule({
  exports: [FullHeightDirective, FullHeightComponent],
  declarations: [FullHeightDirective, FullHeightComponent],
})
export class FullHeightModule {}
