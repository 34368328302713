<div
  *ngIf="icon === 'question'; else info"
  class="tl-info__ring square-responsive"
  [title]="'global.helpInformationTitle' | i18n">
  <div class="tl-info__character square-responsive-content">?</div>
</div>

<ng-template #info>
  <i
    [title]="'global.helpInformationTitle' | i18n"
    class="tl-info__icon icon-info2-icon"></i>
</ng-template>
