import {Serializable} from 'common';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';

class NumbersGameTypeMetadataInternal extends AbstractGameTypeMetadata {
  min: number;

  max: number;
}

export class NumbersGameTypeMetadata extends Serializable(
  NumbersGameTypeMetadataInternal,
) {
  static createFromBackend(obj: any) {
    let numbersGMDT = new NumbersGameTypeMetadata();
    AbstractGameTypeMetadata.fillFromBackend(numbersGMDT, obj);
    numbersGMDT.min = obj.minValue;
    numbersGMDT.max = obj.maxValue;

    return numbersGMDT;
  }
}
