import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ScrollRailComponent} from './scroll-rail.component';

@NgModule({
  imports: [CommonModule],
  exports: [ScrollRailComponent],
  declarations: [ScrollRailComponent],
})
export class ScrollRailModule {}
