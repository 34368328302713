import {Injectable} from '@angular/core';
import {
  LocaleService,
  ModalDialogComponent,
  ModalHelperService,
  MoneyPipe,
  TranslatableText,
} from 'common';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MoneyUtilsService {
  constructor(
    private modalHelperService: ModalHelperService,
    private localeService: LocaleService,
  ) {}

  openMoneyChargeDialog(message?: ModalDialogComponent['message']): Observable<any> {
    return this.modalHelperService.openOkCancelModal(ModalDialogComponent, {
      componentParams: {
        type: 'ok_cancel',
        accept: {key: 'games.play.warningBalance.acceptAction'},
        cancel: {key: 'games.play.warningBalance.cancelAction'},
        image: 'assets/img/jugar/popup/billete-alas.svg',
        title: {key: 'games.play.warningBalance.title'},
        message: message ? message : {key: 'games.play.warningBalance.description'},
        closeOnNavigation: true,
      },
      modalOptions: {
        centered: true,
      },
    });
  }

  openLoadCompleteDialog(
    amount: number,
    accept: 'PLAYNOW' | 'FINISH' | 'ACCEPT' = 'PLAYNOW',
  ) {
    let title: TranslatableText = {key: 'payments.load.success.message'};

    if (amount) {
      const amountStr = new MoneyPipe(this.localeService.getLocale()).transform(
        amount,
        null,
        null,
        '1.0-2',
      );

      title = {
        key: 'payments.load.success.title',
        keyData: {amountWithCurrency: amountStr},
      };
    }

    let acceptKey = 'payments.load.success.action';
    if (accept === 'FINISH') {
      acceptKey = 'payments.load.success.actionFinishPlay';
    }
    if (accept === 'ACCEPT') {
      acceptKey = 'global.acceptButton';
    }

    return this.modalHelperService.openOkModal(ModalDialogComponent, {
      componentParams: {
        type: 'ok',
        accept: {
          key: acceptKey,
        },
        image: 'assets/img/saldo/cartera.svg',
        title: title ? title : {key: 'payments.load.success.message'},
        message: {key: 'payments.load.success.body'},
        closeOnNavigation: true,
        imageWidth: 96,
        justifyCenterDesktop: true,
        smallText: true,
        forceWide: true,
      },
    });
  }

  openMoneyInsuficientBalanceDialog() {
    return this.openMoneyChargeDialog({key: 'games.clubs.errorInsuficientBalance'});
  }

  openGroupLoadCompleteDialog(
    amount: number,
    userBalance: number,
    acceptKey: TranslatableText,
  ): Observable<any> {
    let title: TranslatableText = {key: 'payments.load.success.message'};

    if (amount) {
      const amountStr = new MoneyPipe(this.localeService.getLocale()).transform(
        amount,
        null,
        null,
        '1.0-2',
      );

      title = {
        key: 'payments.load.success.titleGroup',
        keyData: {amountWithCurrency: amountStr},
      };
    }

    const userBalanceStr = new MoneyPipe(this.localeService.getLocale()).transform(
      userBalance,
      null,
      null,
      '1.0-2',
    );
    return this.modalHelperService.openOkCancelModal(ModalDialogComponent, {
      componentParams: {
        type: 'ok',
        accept: acceptKey,
        image: 'assets/img/saldo/cartera.svg',
        imageWidth: 96,
        title: title,
        message: {
          key: 'payments.load.success.bodyGroup',
          keyData: {amountWithCurrency: userBalanceStr},
        },
        acceptCallBack: () => {
          return true;
        },
        cancelCallBack: () => {
          return true;
        },
      },
    });
  }
}
