export {};

Object.defineProperty(Error.prototype, 'toJSON', {
  value: function () {
    let json = {};

    Object.getOwnPropertyNames(this).forEach(prop => {
      json[prop] = this[prop];
    });

    return json;
  },
  configurable: true,
  writable: true,
});

Object.defineProperty(ErrorEvent.prototype, 'toJSON', {
  value: function () {
    return {
      message: `ErrorEvent ${this.type}: ${this.message}`,
      error: this.error,
      timeStamp: this.timeStamp,
    };
  },
  configurable: true,
  writable: true,
});
