import {Injectable} from '@angular/core';
import {CookiesService} from 'common';
import {first} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {SessionService} from '../../user/auth/session.service';

@Injectable({providedIn: 'root'})
export class TrackeablePromoService {
  constructor(
    private cookieService: CookiesService,
    private sessionService: SessionService,
  ) {
    this.sessionService.userLoginEvent
      .pipe(first())
      .subscribe(() => this.deletePromocode());
  }

  savePromocode(code: string): void {
    this.cookieService.set('promocode', code + ':' + Date.now());
  }

  restorePromocode(): string {
    const code = this.cookieService.get('promocode');

    if (code) {
      const codeTime = code.split(':');

      if (Date.now() - +codeTime[1] < environment.promos.ttl * 60 * 1000) {
        return codeTime[0];
      } else {
        this.deletePromocode();
      }
    }

    return null;
  }

  deletePromocode(): void {
    this.cookieService.delete('promocode');
  }
}
