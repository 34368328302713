import {AsyncPipe} from '@angular/common';
import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';

import {TranslationsService} from '../i18n/translations.service';

@Pipe({name: 'moneyFree'})
export class MoneyFree implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    cdr: ChangeDetectorRef,
    private translationsService: TranslationsService,
  ) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(value: number): string {
    return value === 0
      ? this.asyncPipe.transform(
          this.translationsService.getTranslation('global.free'),
        )
      : value.toString();
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
