export class TagRoute {
  tag: string;

  route: string;

  constructor(tag: string, route: string) {
    this.tag = tag;
    this.route = route;
  }
}
