import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {TranslatableText} from '../i18n/translatable-text';

@Component({
  selector: 'tl-header-button',
  templateUrl: './header-button.component.html',
  styleUrls: ['./header-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderButtonComponent {
  @HostBinding('class.tl-header-button')
  readonly hostClass = true;

  @Input()
  icon: string;

  @Input()
  text: TranslatableText | string;

  @Input()
  clickHandler: () => void;

  @Output()
  buttonClick = new EventEmitter<void>();

  handleClick(): void {
    if (this.clickHandler) {
      this.clickHandler();
    }

    this.buttonClick.emit();
  }
}
