import {NgModule} from '@angular/core';

import {PushPromptComponent} from './push-prompt.component';
import {I18nModule} from 'common';

@NgModule({
  imports: [I18nModule],
  exports: [PushPromptComponent],
  declarations: [PushPromptComponent],
})
export class PushPromptModule {}
