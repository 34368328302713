import {DOCUMENT} from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Inject,
  Injector,
  Input,
  NgZone,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {NgbTooltip, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {Logger} from '../logger/logger';
import {Destroyable} from '../util/destroyable';

import {TooltipService} from './tooltip.service';

@Directive({selector: '[tlTooltipFormMessage]'})
export class TooltipFormMessageDirective extends NgbTooltip implements OnInit {
  @Input()
  id: string;

  @Input('tlTooltipFormMessage')
  messages: {[key: string]: string | TemplateRef<any>};

  @Destroyable()
  private destroy = new Subject();

  constructor(
    appRef: ApplicationRef,
    cdr: ChangeDetectorRef,
    componentFactoryResolver: ComponentFactoryResolver,
    public control: NgControl,
    config: NgbTooltipConfig,
    @Inject(DOCUMENT) document: Document,
    element: ElementRef,
    injector: Injector,
    private logger: Logger,
    renderer: Renderer2,
    private tooltipService: TooltipService,
    viewContainerRef: ViewContainerRef,
    zone: NgZone,
  ) {
    super(
      element,
      renderer,
      injector,
      componentFactoryResolver,
      viewContainerRef,
      config,
      zone,
      document,
      cdr,
      appRef,
    );

    // Default config, can be overrided with input params.
    this.tooltipClass = 'tl-tooltip tl-tooltip-error';
    this.container = 'body';
    this.triggers = 'manual';
  }

  ngOnInit() {
    if (!this.id) {
      throw new Error('Tooltip id is required');
    }
    super.ngOnInit();
    this.tooltipService.listener
      .pipe(
        filter(id => id === this.id),
        takeUntil(this.destroy),
      )
      .subscribe(() => {
        if (!this.control.errors) {
          this.logger.error(
            'no errors in control in TooltipFormMessageDirective',
            null,
            {
              id: this.id,
              control: {
                name: this.control.name,
                control: this.control.control,
              },
            },
          );
          return;
        }
        const errorKey = Object.keys(this.control.errors)[0];
        this.ngbTooltip = this.messages[errorKey];
        this.open();
      });

    this.tooltipService.closeListener
      .pipe(
        filter(id => id === this.id),
        takeUntil(this.destroy),
      )
      .subscribe(() => this.close());
  }
}
