<div class="form-group">
  <label class="sr-only" for="credit-card-date"></label>
  <input
    class="form-control"
    inputmode="numeric"
    pattern="[0-9]*"
    tlTriggerOnKeyboardOpen
    id="credit-card-date"
    [tabindex]="ngTabindex"
    (input)="onInput($event)"
    (keydown)="inputKeyDown($event)"
    [placeholder]="placeholder" />
  <ng-content></ng-content>
</div>
