import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';

import {I18nModule} from '../../i18n/i18n.module';
import {ModalDialogModule} from '../../modal/modal-dialog/modal-dialog.module';
import {ScrollableModule} from '../../scrolling/scrollable/scrollable.module';

import {DropdownComponent} from './dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    ScrollableModule,
    NgbDropdownModule,
    NgbModalModule,
    ModalDialogModule,
  ],
  exports: [DropdownComponent],
  declarations: [DropdownComponent],
})
export class DropdownModule {}
