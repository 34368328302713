import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms/forms';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Pipe({
  name: 'controlStatus',
})
export class ControlStatusPipe implements PipeTransform {
  transform(control: AbstractControl): Observable<string> {
    return control.statusChanges.pipe(startWith(control.status));
  }
}
