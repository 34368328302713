import {
  Attribute,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';

import {ResponsiveService} from '../responsive.service';

/**
 * Directive to set a link for each platform.
 *
 * Accepts two routerlink-like arguments, one for each platform, and sets one of
 * them based on the current environment.
 */
@Directive({selector: ':not(a)[tlMobileRouterLink]'})
export class PlatformRouterLinkDirective extends RouterLink implements OnChanges {
  /**
   * Link for the mobile environment.
   */
  @Input('tlMobileRouterLink')
  mobileLink: any;

  /**
   * Link for the desktop environment.
   */
  @Input('tlDesktopRouterLink')
  desktopLink: any;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // @ts-ignore
    private router1: Router,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // @ts-ignore
    private route1: ActivatedRoute,
    @Attribute('tabindex') tabIndex: string,
    renderer: Renderer2,
    el: ElementRef,
    private responsiveService: ResponsiveService,
  ) {
    super(router1, route1, tabIndex, renderer, el);

    this.responsiveService.breakpointChange.subscribe(() => this.setLink());
  }

  ngOnChanges() {
    this.setLink();
  }

  private setLink() {
    if (this.responsiveService.isDesktop()) {
      this.routerLink = this.desktopLink;
    } else {
      this.routerLink = this.mobileLink;
    }
  }
}
