import {Serializable, SerializableProperty, SerializableType} from 'common';

import {PositionData} from './position-data';

class TicketRenderDataInternal {
  font: string;

  fontWeight: number;

  backgroundColor: string;

  @SerializableProperty(PositionData, SerializableType.MAP)
  types: Map<string, PositionData>;
}

export class TicketRenderData extends Serializable(TicketRenderDataInternal) {
  static createFromBackend(obj: any): TicketRenderData {
    let renderData = new TicketRenderData();

    renderData.font = obj.font || 'serif';
    renderData.fontWeight = obj.fontWeight || 400;
    renderData.backgroundColor = obj.backgroundColor;
    renderData.types = new Map<string, PositionData>();

    obj.types.forEach(type =>
      renderData.types.set(type.typeId, PositionData.createFromBackend(type)),
    );

    return renderData;
  }
}
