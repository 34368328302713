import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {AbstractControl, FormArray, FormControl} from '@angular/forms';

// tslint:disable:prefer-on-push-component-change-detection
@Component({
  selector: 'tl-set-table',
  templateUrl: './set-table.component.html',
  styleUrls: ['./set-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetTableComponent {
  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  valueSet: Array<any>;

  @Input()
  multiple = false;

  @Input()
  max: number;

  @Input()
  alwaysOneSelected = false;

  @Input()
  disableSelect = false;

  @Output()
  preventedDeselect: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  selectItem: EventEmitter<void> = new EventEmitter<void>();

  forms: Array<AbstractControl> | Array<FormControl>;

  @HostBinding('class.tl-set-table')
  readonly hostClass = true;

  @Input()
  set form(control: FormArray | FormControl) {
    if (control instanceof FormArray) {
      this.forms = (control as FormArray).controls;
    } else {
      this.forms = [control];
    }
  }
}
