import {Serializable} from 'common';

class GroupMessageInternal {
  picture: string;
  desc: string;
  date: number;
  initials: string;
}

export class GroupInfoMessage extends Serializable(GroupMessageInternal) {
  static createFromBackend(obj: any): GroupInfoMessage {
    let g = new GroupInfoMessage();

    g.desc = obj.desc;
    g.date = obj.date;

    if (obj.memberRequest) {
      g.picture = obj.memberRequest.pictureUrl;
      g.initials = obj.memberRequest.initials;
    }

    return g;
  }
}
