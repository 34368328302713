<tl-select
  [multiple]="select > 1"
  [max]="select"
  [ngModel]="model"
  (ngModelChange)="onChange($event)"
  class="tl-numbers-table__select"
  [ngClass]="'tl-numbers-table__select--' + direction">
  <div
    *ngFor="let column of numberOfColumns() | arrayFromNumber; let lastStripe = last"
    class="tl-numbers-table__stripe">
    <div
      *ngIf="isNumeric(boardIndex) && column === 0"
      class="tl-numbers-table__item">
      {{ boardId }}
    </div>
    <tl-select-item
      *ngFor="
        let item of perColumnFn(column) | arrayFromNumber: startIndexNumber(column);
        let i = index;
        let last = last
      "
      #numberItem
      [value]="item"
      [disabled]="disabled || (disabledCells || [])[i]"
      class="selectable-cell tl-numbers-table__item tl-numbers-table__number-box"
      [class]="'tl-numbers-table__number-box--' + shape"
      [ngClass]="{
        highlight: numberItem.selected,
        disabled: numberItem.disabled,
        'tl-numbers-table__item--last': last,
        'tl-numbers-table__item--last-stripe': lastStripe
      }">
      {{ item }}
    </tl-select-item>
  </div>
</tl-select>
