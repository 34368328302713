import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {ngModelProvider} from '../../model/ng-model-config';
import {isNumeric, mod} from '../../util/core/number';
import {AbstractNgModel} from '../abstract-ngmodel';
import {SelectComponent} from '../select/select.component';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({
  selector: 'tl-numbers-table',
  templateUrl: './numbers-table.component.html',
  styleUrls: ['./numbers-table.component.scss'],
  providers: [ngModelProvider(NumbersTableComponent)],
  encapsulation: ViewEncapsulation.None,
})
export class NumbersTableComponent
  extends AbstractNgModel<any>
  implements OnChanges
{
  @Input()
  boardIndex: number;

  @Input()
  alphabetic = false;

  @Input()
  start = 1;

  @Input()
  end: number;

  @Input()
  length: number;

  @Input()
  select: number;

  @Input()
  disabled = false;

  stripLength: number;

  /**
   * Disables cells individually, if the whole component is disabled this has no
   * effect.
   */
  @Input()
  disabledCells: Array<boolean>;

  @Input()
  @HostBinding('class.tl-numbers-table--highlighted')
  @HostBinding('style.background')
  highlight: string;

  @Input()
  shape = 'square';

  @Input()
  direction = 'columns';

  @ViewChild(SelectComponent, {static: true})
  selectComponent: SelectComponent;

  @HostBinding('class.tl-numbers-table')
  readonly hostClass = true;

  get boardId(): string {
    if (!isNumeric(this.boardIndex)) {
      return '';
    }
    return this.alphabetic
      ? String.fromCharCode(this.boardIndex + 65) + '.'
      : (this.boardIndex + 1).toString();
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('end') || changes.hasOwnProperty('start')) {
      this.stripLength = this.end - this.start + 1;
    }

    if (changes.hasOwnProperty('disabled')) {
      this.setDisabledState(this.disabled);
    }
  }

  onChange(value: any): void {
    if (Array.isArray(value)) {
      this.model = value;
    } else {
      this.model = isNumeric(value) ? value : null;
    }

    this.modelChange(this.model);
  }

  writeValue(obj: any): void {
    if (Array.isArray(obj)) {
      this.model = obj;
    } else if (obj || isNumeric(obj)) {
      this.model = obj;
    } else {
      this.model = [];
    }
  }

  numberOfColumns(): number {
    const normalizedLength = this.stripLength + (isNumeric(this.boardIndex) ? 1 : 0);
    const columns = normalizedLength / this.length;

    return mod(normalizedLength, this.length) === 0
      ? columns
      : Math.floor(columns) + 1;
  }

  perColumnFn(column: number): number {
    if (isNumeric(this.boardIndex) && column === 0) {
      return this.length - 1;
    } else if (this.length * column + this.length > this.stripLength) {
      return isNumeric(this.boardIndex)
        ? mod(this.stripLength, this.length) + 1
        : mod(this.stripLength, this.length);
    } else {
      return this.length;
    }
  }

  startIndexNumber(column): number {
    return column === 0
      ? this.start
      : isNumeric(this.boardIndex)
      ? this.start + this.length * column - 1
      : this.start + this.length * column;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', disabled);

    if (!disabled && this.disabledCells) {
      return;
    }

    if (this.selectComponent) {
      this.selectComponent.setDisabledState(disabled);
    }
  }

  isNumeric(n: any): n is number {
    return isNumeric(n);
  }
}
