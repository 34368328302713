import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {I18nModule, ModalDialogModule, ScrollableModule} from 'common';

import {CreateCompanyClubHelpContentModule} from '../../steps/mobile/create-company-club-step1/create-company-club-help-content/create-company-club-help-content.module';

import {NewCompanyClubPreviousInfoDialogComponent} from './new-company-club-previous-info-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ModalDialogModule,
    CreateCompanyClubHelpContentModule,
    ScrollableModule,
    I18nModule,
  ],
  exports: [NewCompanyClubPreviousInfoDialogComponent],
  declarations: [NewCompanyClubPreviousInfoDialogComponent],
})
export class NewCompanyClubPreviousInfoDialogModule {}
