import {Injectable} from '@angular/core';
import {Logger, workerMapIfPossible} from 'common';
import {from, Observable, of, switchMap, toArray, zip} from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';

import {MoneyActivity} from './money-activity';
import {MoneyActivityService} from './money-activity.service';
import {MoneyDao} from './money.dao';
import {MoneyActivityTypes} from './money-activity-types';

@Injectable({providedIn: 'root'})
export class MoneyActivityUsersService extends MoneyActivityService<MoneyActivity> {
  constructor(private moneyDao: MoneyDao, protected logger: Logger) {
    super(logger);
  }

  setFilterType(filterType: Array<string>): void {
    if (this.filterType !== filterType) {
      if (this.filterType === null) {
        this.filterType = filterType;
        this.paginationEnd = false;
        super
          .getData()
          .pipe(
            first(),
            switchMap((activities: Array<MoneyActivity>) => from(activities)),
            filter((activity: MoneyActivity) => filterType.includes(activity.type)),
            toArray(),
          )
          .subscribe((activities: Array<MoneyActivity>) => {
            this.setData(activities);
          });
      } else {
        super.setFilterType(filterType);
      }
    }
  }

  protected getActivitiesDao(): Observable<Array<MoneyActivity>> {
    return this.moneyDao
      .moreActivities(this.paginationIndex, this.filterType)
      .pipe(map(d => d.data));
  }

  protected checkUnhandledActivityTypes(
    activities: Array<MoneyActivity>,
  ): Observable<any> {
    return zip(of(MoneyActivityTypes.allPossibleTypes()), of(activities)).pipe(
      workerMapIfPossible(data => {
        const types = data[0];
        const moneyactivities: Array<MoneyActivity> = data[1];

        const unhandledMap = new Map<string, boolean>();
        moneyactivities.forEach(activity => {
          if (types.indexOf(activity.type) < 0) {
            unhandledMap.set(activity.type, true);
          }
        });
        return unhandledMap;
      }),
      filter((undhandled: Map<string, boolean>) => undhandled.size > 0),
      tap(unhandled =>
        this.logger.warn('New activity types created by backend', {
          types: Array.from(unhandled.keys()),
        }),
      ),
    );
  }
}
