import {Serializable} from 'common';

class ClubExtraNumberInternal {
  price: number;
  imageUrl: string;
  number: string;
}

export class ClubExtraNumber extends Serializable(ClubExtraNumberInternal) {
  public static createFromBackend(obj: any) {
    let c: ClubExtraNumber = new ClubExtraNumber();
    c.price = obj.aportacionParticipacion;
    c.imageUrl = obj.imageUrl;
    c.number = obj.numero;
    return c;
  }
}
