import {
  getCurrencySymbol,
  getLocaleNumberFormat,
  NumberFormatStyle,
} from '@angular/common';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {from, Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {Country} from '../countries/country';
import {CountryService} from '../countries/country.service';

import {DEFAULT_CURRENCY_CODE, DEFAULT_PHONE_CODE} from './config-tokens';
import {Router} from '@angular/router';

export enum CurrencyPosition {
  START,
  END,
}

@Injectable({providedIn: 'root'})
export class LocaleService {
  private localeCode: string;

  private foundPhone: Country;

  constructor(
    @Inject(DEFAULT_CURRENCY_CODE) private currencyCode: string,
    @Inject(DEFAULT_PHONE_CODE) private defaultPhoneCode: string,
    @Inject(LOCALE_ID) private defaultLocale: string,
    private countryService: CountryService,
    private router: Router,
  ) {
    // TODO - MULTILENGUAGE
    // De momento lo leemos de local pero habría que cojerlo de los datos extra del usuario
    const savedLocale = localStorage.getItem('locale');
    this.localeCode = savedLocale || this.defaultLocale;
  }

  // noinspection JSMethodCanBeStatic
  getCurrencyCode(): string {
    return getCurrencySymbol(this.currencyCode, 'wide');
  }

  // noinspection JSMethodCanBeStatic
  getCurrencySymbol(): string {
    return getCurrencySymbol(this.currencyCode, 'narrow');
  }

  setLocale(code: string) {
    // TODO - MULTILENGUAGE
    // De momento lo guardamos en local pero habría que guardarlo en los datos extra del usuario
    localStorage.setItem('locale', code);
    // TODO - MULTILENGUAGE
    // Mirar si hay una mejor manera de recargar
    from(this.navigateHome()).subscribe(() => window.location.reload());
  }

  getLocale() {
    return this.localeCode;
  }

  getLocaleAbbr() {
    return this.localeCode.split('-')[0];
  }

  getCurrencySymbolPosition() {
    return getLocaleNumberFormat(
      this.localeCode,
      NumberFormatStyle.Currency,
    ).startsWith('¤')
      ? CurrencyPosition.START
      : CurrencyPosition.END;
  }

  symbolHasSpace(): boolean {
    const pattern = getLocaleNumberFormat(
      this.localeCode,
      NumberFormatStyle.Currency,
    );
    return pattern.startsWith('¤ ') || pattern.endsWith(' ¤');
  }

  getDefaultPhonePrefix(): Observable<Country> {
    return this.countryService.getData().pipe(
      first(),
      map((countries: Array<Country>) => {
        if (!this.foundPhone) {
          this.foundPhone = countries.find(
            country => country.code === this.defaultPhoneCode,
          );
        }
        return this.foundPhone;
      }),
    );
  }

  private navigateHome(): Promise<boolean> {
    return this.router.navigate(['/']);
  }
}
