<div *ngIf="!itemTemplate; else templated" class="wrapper">
  <div *ngIf="node.titleKey; else titleNoTranslate">
    {{ node.titleKey | i18n }}
  </div>
  <ng-template #titleNoTranslate>
    {{ node.title }}
  </ng-template>
</div>

<ng-template #templated>
  <div class="wrapper">
    <ng-template
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{$implicit: node}">
    </ng-template>
  </div>
</ng-template>
<i *ngIf="node.children && !asHeader" class="arrow icon-right-slim-icon"></i>
<i *ngIf="asHeader" class="triangle-arrow"></i>
