import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BlurService {
  private blur = new BehaviorSubject<boolean>(false);

  setBlur(enable: boolean): void {
    this.blur.next(enable);
  }

  blurEmmitter(): Observable<boolean> {
    return this.blur;
  }
}
