import {Directive, ElementRef, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {isNumeric} from '../../util/core/number';
import {Destroyable} from '../../util/destroyable';

@Directive({selector: '[tlAllowOnlyNumbers]'})
export class AllowOnlyNumbersDirective implements OnInit {
  @Destroyable()
  private destroy = new Subject();

  constructor(private element: ElementRef, private control: NgControl) {}

  ngOnInit() {
    fromEvent(this.element.nativeElement, 'input')
      .pipe(takeUntil(this.destroy))
      .subscribe((event: any) => {
        if (event && event.target && event.target.value) {
          const input = event.target;
          const cursorStart = input.selectionStart;
          const cursorEnd = input.selectionEnd;
          const lastTypedChar = input.value.charAt(cursorStart - 1);
          if (cursorStart === cursorEnd && !isNumeric(lastTypedChar)) {
            input.value =
              input.value.slice(0, cursorStart - 1) + input.value.slice(cursorEnd);
            input.selectionStart = input.selectionEnd = cursorStart - 1;
            this.control.control.setValue(input.value);
          }
        }
      });
  }
}
