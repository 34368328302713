import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, Subject} from 'rxjs';
import {
  catchError,
  first,
  map,
  mapTo,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  AlertsService,
  Destroyable,
  ModalDialogComponent,
  ModalHelperService,
} from 'common';
import {environment} from '~environments/environment';

import {PrivateInfoService} from '../backend/private-info/private-info.service';
import {UserService} from '../user/data/user.service';

import {BoothDao} from './data/booth.dao';
import {LotteryBooth} from './data/lottery-booth';
import {GeolocatedLotteryBooth} from './data/geolocated-lottery-booth';

@Injectable({providedIn: 'root'})
export class BoothsFavoriteViewService {
  get favoriteBooth(): Observable<string> {
    return this.favouriteSubject.asObservable();
  }

  private favouriteSubject = new BehaviorSubject('');

  @Destroyable()
  private destroy = new Subject();

  constructor(
    private alertsService: AlertsService,
    private boothDao: BoothDao,
    private privateInfoService: PrivateInfoService,
    private userService: UserService,
    private modalHelperService: ModalHelperService,
  ) {
    this.userService
      .getData()
      .pipe(
        map(user => (user ? user.lotteryBooth : '')),
        startWith(''),
        takeUntil(this.destroy),
      )
      .subscribe(v => this.favouriteSubject.next(v));
  }

  setFavorite(lotteryBooth: LotteryBooth | GeolocatedLotteryBooth): Observable<any> {
    return this.favouriteSubject.pipe(
      first(),

      // open confirm dialog
      switchMap(favBoothId => {
        return this.openConfirmDialog(lotteryBooth, favBoothId);
      }),

      // DAO caller
      switchMap(booth => this.boothDao.setFavoriteBooth(booth)),

      takeUntil(this.destroy),

      tap({
        error: () => {
          this.alertsService.notifyError({
            key: 'administrationOffice.adminSelectUnsucess',
          });
        },
      }),

      switchMap(() => this.privateInfoService.update()),
    );
  }

  openConfirmDialog(
    prevLotteryBooth: LotteryBooth | GeolocatedLotteryBooth,
    newBoothId: string,
  ) {
    /** Set the keys of language dialogs */
    let translatableKey;

    if (newBoothId && prevLotteryBooth.id !== newBoothId) {
      // replace booth selected
      translatableKey = 'changeAdmin';
    } else if (
      prevLotteryBooth.id !== newBoothId &&
      prevLotteryBooth['distance'] &&
      prevLotteryBooth['distance'] > environment.features.booths.distanceAlert // More than 300km
    ) {
      // new booth is too far
      translatableKey = 'selectFar';
    } else if (!newBoothId) {
      // new booth selected
      translatableKey = 'selectNew';
    } else {
      // un select booth
      translatableKey = 'unselectAdmin';

      prevLotteryBooth = null;
    }

    const modalParams = {
      type: `ok_cancel`,
      title: {
        key: `administrationOffice.selectionDialogs.${translatableKey}.title`,
      },
      message: {
        key: `administrationOffice.selectionDialogs.${translatableKey}.text`,
      },
      accept: {
        key: `administrationOffice.selectionDialogs.${translatableKey}.confirmButton`,
      },
    };

    return this.modalHelperService
      .openOkCancelModal(ModalDialogComponent, {
        componentParams: modalParams,
      })
      .pipe(
        catchError(() => EMPTY),
        mapTo(prevLotteryBooth ? prevLotteryBooth.id : ''),
      );
  }
}
