import {RequiredBetRuleTypeMetadata} from './required-bet-rule-type-metadata';

/**
 * Rule applied to games which requires an ordered sequence of digits with
 * pattern like Tris game.
 */
export class DigitsBetRuleTypeMetadata extends RequiredBetRuleTypeMetadata {
  /**
   * Indicates what columns should be filled.
   *
   * @example Pattern `XXDDD` indicates that only three last columns should be filled
   */
  pattern: string;

  constructor() {
    super();
  }

  static createFromBackend(obj: any) {
    let requiredDRTMD = new DigitsBetRuleTypeMetadata();

    requiredDRTMD.required = obj.needed;
    requiredDRTMD.optional = !!obj.optional;
    requiredDRTMD.pattern = obj.pattern;

    return requiredDRTMD;
  }

  // Serializable inherited from parent.
}
