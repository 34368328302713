import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
  CurrencyModule,
  FullHeightModule,
  I18nModule,
  ModalDialogModule,
  ScrollableModule,
  TranslatableTextModule,
} from 'common';

import {ShipmentConfirmDialogComponent} from './shipment-confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ModalDialogModule,
    CurrencyModule,
    ScrollableModule,
    FullHeightModule,
    TranslatableTextModule,
    FormsModule,
  ],
  exports: [ShipmentConfirmDialogComponent],
  declarations: [ShipmentConfirmDialogComponent],
})
export class ShipmentConfirmDialogModule {}
