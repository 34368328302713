import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {ngModelProvider} from '../../model/ng-model-config';
import {AbstractNgModel} from '../abstract-ngmodel';
import {fromEvent, map, Subject, switchMap, takeUntil} from 'rxjs';
import {Destroyable} from '../../util/destroyable';

let nextId = 0;

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-switch',
  styleUrls: ['./switch.component.scss'],
  templateUrl: './switch.component.html',
  providers: [ngModelProvider(SwitchComponent)],
})
export class SwitchComponent extends AbstractNgModel<boolean> implements OnInit {
  @Input('value')
  model = false;

  @Input('switch-id')
  id: string = 'tl-switch-' + ++nextId;

  @Input()
  icon: string;

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.disabled')
  disabled = false;

  @Destroyable()
  destroy$: Subject<void> = new Subject<void>();

  constructor(private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    const touchStart$ = fromEvent<TouchEvent>(
      this.elementRef.nativeElement,
      'touchstart',
    ).pipe(
      takeUntil(this.destroy$),
      map(event => event.touches[0].clientX),
    );

    const touchEnd$ = fromEvent<TouchEvent>(
      this.elementRef.nativeElement,
      'touchend',
    ).pipe(
      takeUntil(this.destroy$),
      map(event => event.changedTouches[0].clientX),
    );

    touchStart$
      .pipe(
        switchMap(startX =>
          touchEnd$.pipe(
            map(endX => endX - startX),
            takeUntil(touchStart$),
          ),
        ),
      )
      .subscribe(deltaX => {
        if ((deltaX > 0 && !this.model) || (deltaX < 0 && this.model)) {
          this.onToggle();
        }
      });
  }

  writeValue(obj: any) {
    if (typeof obj !== 'undefined') {
      this.model = obj;
    }
  }

  onToggle() {
    if (this.disabled) {
      return;
    }

    this.model = !this.model;
    if (this.modelChange) {
      this.modelChange(this.model);
    }

    if (this.toggle.observed) {
      this.toggle.emit(this.model);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
