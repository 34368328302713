import {Directive, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {ToggleCloseOthersService} from './toggle-close-others.service';
import {Toggleable} from './toggleable';

@Directive({
  selector: '[tlToggleCloseOthers]',
})
export class ToggleCloseOthersDirective implements OnDestroy {
  private subscription: Subscription;

  constructor(component: Toggleable, private service: ToggleCloseOthersService) {
    this.service.register(component);
    this.subscription = component.onToggleOpen.subscribe(() =>
      this.service.closeOthers(component),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
