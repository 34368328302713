import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {I18nModule} from '../../i18n/i18n.module';
import {CurrencyModule} from '../currency.module';
import {NumberUnitModule} from '../number-currency/number-unit.module';

import {MoneyBarComponent} from './money-bar.component';
import {TranslatableTextModule} from '../../i18n/translatable-text/translatable-text.module';

@NgModule({
  imports: [
    CommonModule,
    CurrencyModule,
    I18nModule,
    NumberUnitModule,
    TranslatableTextModule,
  ],
  exports: [MoneyBarComponent],
  declarations: [MoneyBarComponent],
})
export class MoneyBarModule {}
