import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {I18nModule, SanitizerModule, UtilModule} from 'common';

import {NotificationsBlockedDesktopComponent} from './notifications-blocked-desktop.component';

@NgModule({
  imports: [CommonModule, I18nModule, UtilModule, SanitizerModule],
  exports: [NotificationsBlockedDesktopComponent],
  declarations: [NotificationsBlockedDesktopComponent],
})
export class NotificationsBlockedDesktopModule {}
