export function formatZipCode(postalCode: string, pattern: string): string {
  if (!postalCode) {
    return;
  }
  const alphanumeric = postalCode.replace(/\W/g, ''); // Remove non-alphanumeric characters
  let index = 0;
  let formattedCode = '';

  for (let i = 0; i < pattern.length; i++) {
    const currentChar = pattern[i];

    if (currentChar === 'A' && /[A-Za-z]/.test(alphanumeric[index])) {
      formattedCode += alphanumeric[index++];
    } else if (currentChar === '0' && /\d/.test(alphanumeric[index])) {
      formattedCode += alphanumeric[index++];
    } else if (currentChar === '-') {
      formattedCode += '-';
    } else {
      formattedCode += currentChar;
    }

    // Break the loop if we have processed the entire alphanumeric string
    if (index >= alphanumeric.length) {
      break;
    }
  }

  return formattedCode;
}
