import {Serializable} from '../serializable/serializable';

class CountryInternal {
  name: string;

  code: string;

  prefix: string;
}

export class Country extends Serializable(CountryInternal) {
  static createFromBackend(obj: any) {
    const country = new Country();
    country.name = obj.name;
    country.code = obj.code;
    country.prefix = obj.prefix;

    return country;
  }
}
