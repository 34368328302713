import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logger} from 'common';
import {Observable} from 'rxjs';

import {AbstractDao} from '../model/abstract.dao';

@Injectable({providedIn: 'root'})
export class VerificationDao extends AbstractDao {
  constructor(protected http: HttpClient, protected logger: Logger) {
    super();
  }

  getStatus(): Observable<any> {
    return this.http.get(this.baseUrl + '/users/identity-verification');
  }

  requestCode(): Observable<any> {
    return this.http.get(this.baseUrl + '/users/identity-verification/resend-sms');
  }

  confirmCode(code: string): Observable<any> {
    return this.http.post(this.baseUrl + '/users/identity-verification', code);
  }
}
