import {Inject, Injectable, Optional} from '@angular/core';
import {switchMapArray} from 'common';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {
  NotificationPreference,
  NotificationPreferenceCategory,
  NotificationPreferenceSubcategory,
  Preference,
  PreferenceCategory,
  PreferenceType,
} from './preference';
import {NotificationsProcessDataService} from './notifications-process-data.service';
import {PreferenceDataService} from './preference-data.service';

@Injectable({providedIn: 'root'})
export class PreferencesService {
  constructor(
    @Inject(NotificationsProcessDataService)
    @Optional()
    private processDataServices: Array<NotificationsProcessDataService>,
    private preferenceDataService: PreferenceDataService,
  ) {}

  hasPushEnabled(): Observable<boolean> {
    return this.preferenceDataService.getData().pipe(
      map(p => p.filter(preference => preference.type === PreferenceType.PUSH)),
      map(p => p.some(pref => pref.value)),
    );
  }

  /**
   * Get categories in a Map<[order,category_key], category>
   */
  getCategories(): Observable<
    Map<[number, string], NotificationPreferenceCategory>
  > {
    const processData: Array<NotificationsProcessDataService> =
      this.processDataServices;
    return this.preferenceDataService.getData().pipe(
      switchMap((data: Array<Preference>) =>
        switchMapArray(data, processData, 'processList'),
      ),
      map((preferences: Array<Preference>) =>
        this.buildPreferencesTree(preferences),
      ),
    );
  }

  /**
   * Returns the element of the map that has the title in its key
   *
   * @param mymap
   * @param title
   */
  existsTitle(mymap: Map<[number, string], any>, title: string) {
    return Array.from(mymap.keys()).find(key => key[1] === title) !== null
      ? mymap.get(Array.from(mymap.keys()).find(key => key[1] === title))
      : null;
  }

  getPositionCategory(
    mymap: Map<[number, string], any>,
    text: string,
  ): number | undefined {
    const foundKey = Array.from(mymap.keys()).find(
      ([_, category]) => category === text,
    );
    return foundKey ? foundKey[0] : undefined;
  }

  existsSubcategoryTitle(mymap: Map<string, any>, title: string) {
    const foundKey = Array.from(mymap.keys()).find(key => key === title);
    return foundKey !== undefined ? mymap.get(foundKey) : null;
  }

  getActiveNotifications() {
    return this.preferenceDataService.getData().pipe(
      map(p =>
        p.filter(
          // eslint-disable-next-line deprecation/deprecation
          preference => preference.category === PreferenceCategory.NOTIFICATIONS,
        ),
      ),
      map(p => p.filter(pref => pref.value)),
    );
  }

  private formatTitle(title: string): string {
    return title.toLowerCase().replaceAll(' ', '_');
  }

  /**
   * build the preferences tree to show it in the view
   *
   * @param preferences
   * @private
   */
  private buildPreferencesTree(
    preferences: Array<Preference>,
  ): Map<[number, string], NotificationPreferenceCategory> {
    const categories: Map<[number, string], NotificationPreferenceCategory> =
      new Map();
    preferences.forEach((pref: Preference) => {
      const preference: NotificationPreference = {
        title: pref.categorizedTitle,
        subtitle: pref.subtitle,
        key: pref.key,
        value: pref.value,
        type: pref.type,
      };
      if (pref.categoryTree.isRootCategory) {
        // Is category
        const preferencesMap: Map<number, NotificationPreference> = new Map();
        preferencesMap.set(Array.from(preferencesMap.keys()).length, preference);
        let category: NotificationPreferenceCategory = {
          title: pref.categoryTree.title,
          subtitle: pref.categoryTree.subtitle,
          preferences: preferencesMap,
          allPreferenceArePush: preference.type === PreferenceType.PUSH,
          hasPushNotifications: preference.type === PreferenceType.PUSH,
        };
        if (!this.existsTitle(categories, this.formatTitle(category.title))) {
          // New category
          categories.set(
            [Array.from(categories.keys()).length, this.formatTitle(category.title)],
            category,
          );
        } else {
          // Add new preferences
          category = this.existsTitle(categories, this.formatTitle(category.title));
          category.preferences.set(
            Array.from(category.preferences.keys()).length,
            preference,
          );
          // Update category allPreferenceArePush
          category.allPreferenceArePush = Array.from(
            category.preferences.values(),
          ).every(
            (notif: NotificationPreference) => notif.type === PreferenceType.PUSH,
          );
          category.hasPushNotifications = Array.from(
            category.preferences.values(),
          ).some(
            (notif: NotificationPreference) => notif.type === PreferenceType.PUSH,
          );
        }
      } else {
        // Is subcategory
        let category: NotificationPreferenceCategory = {
          title: pref.categoryTree.parentCategory.title,
          subtitle: pref.categoryTree.parentCategory.subtitle,
          subcategories: null,
          allPreferenceArePush: preference.type === PreferenceType.PUSH,
          hasPushNotifications: preference.type === PreferenceType.PUSH,
        };
        if (!this.existsTitle(categories, this.formatTitle(category.title))) {
          // Subcategory without category => create new category before
          const subcategories: Map<string, NotificationPreferenceSubcategory> =
            new Map();
          const subcategory: NotificationPreferenceSubcategory = {
            title: pref.categoryTree.title,
            subtitle: pref.categoryTree.subtitle,
            preferences: [preference],
            state: pref.categoryTree.groupKey,
            hasPushNotifications: pref.type === PreferenceType.PUSH,
            allPreferenceArePush: pref.type === PreferenceType.PUSH,
          };
          subcategories.set(this.formatTitle(subcategory.title), subcategory);
          category.subcategories = subcategories;
          categories.set(
            [Array.from(categories.keys()).length, this.formatTitle(category.title)],
            category,
          );
        } else {
          // Add new subcategories or preferences
          category = this.existsTitle(categories, this.formatTitle(category.title));
          if (
            category.subcategories &&
            this.existsSubcategoryTitle(
              category.subcategories,
              this.formatTitle(pref.categoryTree.title),
            )
          ) {
            // Add new preference
            const subcategory = this.existsSubcategoryTitle(
              category.subcategories,
              this.formatTitle(pref.categoryTree.title),
            );
            subcategory.preferences.push(preference);
            // Update if has push notifications
            subcategory.hasPushNotifications = subcategory.preferences.some(
              (notif: NotificationPreference) => notif.type === PreferenceType.PUSH,
            );
            subcategory.allPreferenceArePush = subcategory.preferences.every(
              (notif: NotificationPreference) => notif.type === PreferenceType.PUSH,
            );
            // Update category allPreferenceArePush
            category.allPreferenceArePush = Array.from(
              category.subcategories.values(),
            ).every(
              (notif: NotificationPreferenceSubcategory) =>
                notif.allPreferenceArePush,
            );
          } else {
            // Add new subcategory
            const subcategory: NotificationPreferenceSubcategory = {
              title: pref.categoryTree.title,
              subtitle: pref.categoryTree.subtitle,
              preferences: [preference],
              state: pref.categoryTree.groupKey,
              hasPushNotifications: pref.type === PreferenceType.PUSH,
              allPreferenceArePush: pref.type === PreferenceType.PUSH,
            };
            if (!category.subcategories) {
              category.subcategories = new Map();
            }
            category.subcategories.set(
              this.formatTitle(subcategory.title),
              subcategory,
            );
            // Update category allPreferenceArePush
            category.allPreferenceArePush = Array.from(
              category.subcategories.values(),
            ).every(
              (notif: NotificationPreferenceSubcategory) =>
                notif.allPreferenceArePush,
            );
          }
        }
      }
    });
    return categories;
  }
}
