import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {UtilModule} from '../../util/util-module';
import {ToggleButtonModule} from '../toggle-button/toggle-button.module';
import {FormUtilsModule} from '../util/form-utils.module';

import {BooleanTableComponent} from './boolean-table.component';

@NgModule({
  imports: [
    CommonModule,
    FormUtilsModule,
    ReactiveFormsModule,
    ToggleButtonModule,
    UtilModule,
  ],
  exports: [BooleanTableComponent],
  declarations: [BooleanTableComponent],
})
export class BooleanTableModule {}
