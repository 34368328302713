/**
 * Validates CLABE bank account
 *
 * @link DOCS: http://centerkey.com/clabe/
 */
export function isValidCLABENumber(input) {
  let checksum;
  if (!input || input.length !== 18) {
    return false;
  }
  checksum = parseInt(input[input.length - 1], 10);
  input = input.substring(0, 18 - 1);

  return generateChecksum(input) === checksum;
}

function generateChecksum(input) {
  let checksum = 0;
  let weights = [3, 7, 1];

  input.split('').forEach((number, index) => {
    number = parseInt(number, 10);
    checksum += (number * weights[index % 3]) % 10;
  });

  return (10 - (checksum % 10)) % 10;
}
