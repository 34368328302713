import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {animationFrameScheduler, Subject} from 'rxjs';
import {auditTime, debounceTime, first} from 'rxjs/operators';

import {ScrollEvent} from '../../scrolling/scroll-event';
import {ScrollIntoElement} from '../../scrolling/scroll-into-element';
import {ScrollableComponent} from '../../scrolling/scrollable/scrollable.component';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-responsive-height',
  templateUrl: './responsive-height.component.html',
  styleUrls: ['./responsive-height.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveHeightComponent
  implements OnInit, OnDestroy, AfterContentChecked, ScrollIntoElement
{
  @Input()
  margin: number;

  @Output()
  scrollChangeOutsideAngular = new EventEmitter<ScrollEvent>();

  @ViewChild(ScrollableComponent, {static: true})
  scrollable: ScrollableComponent;

  private mutationSubject = new Subject<Array<MutationRecord>>();

  private resizeSubject = new Subject<void>();

  private mutationObserver: MutationObserver;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private renderer: Renderer2,
    @Inject('window') private window: Window,
  ) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      const config = {childList: true, subtree: true, characterData: true};

      this.mutationObserver = new MutationObserver(mutation =>
        this.mutationSubject.next(mutation),
      );
      this.mutationObserver.observe(this.elementRef.nativeElement, config);

      this.mutationSubject
        .pipe(
          debounceTime(1), // we need to wait a bit to measure after the
          // mutation triggers
          auditTime(0, animationFrameScheduler),
        )
        .subscribe(() => this.setHeight());

      this.renderer.listen(this.window, 'resize', () => this.resizeSubject.next());

      this.resizeSubject
        .pipe(debounceTime(1), auditTime(0, animationFrameScheduler))
        .subscribe(() => this.setHeight());
    });
  }

  ngOnDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }

    if (this.mutationSubject) {
      this.mutationSubject.complete();
    }

    if (this.mutationSubject) {
      this.resizeSubject.complete();
    }
  }

  ngAfterContentChecked() {
    this.ngZone.onMicrotaskEmpty
      .pipe(first())
      .subscribe(() => this.ngZone.runOutsideAngular(() => this.setHeight()));
  }

  setScrollPosition(pos: number) {
    this.scrollable.setScrollPosition(pos);
  }

  hasScroll() {
    return this.scrollable.hasScroll();
  }

  scrollElementIntoView(element: any, offset?: number) {
    this.scrollable?.scrollElementIntoView(element, offset);
  }

  private setHeight() {
    let offset = this.getContainerOffset(
      this.scrollable.getElementRef().nativeElement,
    ).top;

    this.renderer.setStyle(
      this.scrollable.getElementRef().nativeElement,
      'max-height',
      `${window.innerHeight - (offset + this.margin)}px`,
    );
  }

  // noinspection JSMethodCanBeStatic
  private getContainerOffset(el) {
    el = el.getBoundingClientRect();
    return {
      left: el.left + (window.scrollX || window.pageXOffset),
      top: el.top + (window.scrollY || window.pageYOffset),
    };
  }
}
