import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Shareable} from '../shareable';

@Injectable()
export class SmsUriService implements Shareable {
  notify = false;

  share(title: string, text: string): Observable<any> {
    window.open(`sms:?body=${text}`, '_blank');
    return of(true);
  }
}
