export class Queue<T> {
  store: Array<T> = [];

  push(value: T) {
    this.store.push(value);
  }

  pop(): T | undefined {
    return this.store.shift();
  }

  clear() {
    this.store.splice(0, this.store.length);
  }

  isEmpty(): boolean {
    return this.store.length === 0;
  }
}
