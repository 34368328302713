import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';

import {SelectComponent} from './select.component';
import {SelectService} from './select.service';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
})
export class SelectItemComponent implements OnInit {
  @Input()
  value: any;

  @HostBinding('class.selected')
  selected = false;

  @Input()
  disabled = false;

  private parent: SelectComponent;

  constructor(private selectService: SelectService) {}

  ngOnInit() {
    this.parent = this.selectService.getParent();
    this.selected = this.parent.multiple
      ? this.parent.model.some(v => {
          if (v && typeof v === 'object') {
            return JSON.stringify(v) === JSON.stringify(this.value);
          }
          return v === this.value;
        })
      : this.parent.model === this.value;
  }

  @HostListener('click')
  click() {
    if (!this.disabled) {
      this.parent.onSelect(this);
    }
  }
}
