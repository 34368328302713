/**
 * Encodes only url parameters
 *
 * @return url with encoded parameters
 */
export function encodeURLParameters(url: string): string {
  if (!url) {
    return '';
  }

  const [baseUrl, parameters] = url.split('?');
  return baseUrl + (parameters ? '?' + encodeURIComponent(parameters) : '');
}

/**
 * Generate url from key route with all path parents
 *
 * @return url
 */
export function generateUrlPage(key: string, routes, absolutePath?: string): any[] {
  const pagesKeys = key.split('.');
  const urlTree = [];

  if (!!absolutePath) {
    urlTree.push(absolutePath);
  }

  let page = routes;
  pagesKeys.forEach((pageKey: string) => {
    const newPage = page[pageKey];
    if (typeof newPage === 'object') {
      if (newPage.path) {
        urlTree.push(newPage.path);
      }

      page = newPage;
    } else {
      urlTree.push(newPage);
    }
  });

  return urlTree;
}
