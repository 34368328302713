export interface MoneyActitityType {
  id: string;
  textKey?: string;
  resourceType: 'icon' | 'image';
  assetTranslated?: boolean;
  resource: string;
}

const MONEY_ACTIVITY_TYPES: {[key: string]: MoneyActitityType} = {
  ADELANTO_PENYA: {
    id: 'ADELANTO_PENYA',
    textKey: 'userProfile.balance.types.advancementPenya',
    resourceType: 'icon',
    resource: 'icon-donate-icon',
  },
  BENEFICIO_PENYA: {
    id: 'BENEFICIO_PENYA',
    textKey: 'userProfile.balance.types.benefitPenya',
    resourceType: 'icon',
    resource: 'icon-donate-icon',
  },
  INGRESO: {
    id: 'INGRESO',
    textKey: 'userProfile.balance.types.ingress',
    resourceType: 'icon',
    resource: 'icon-credit-card-icon',
  },
  INGRESO_MANUAL: {
    id: 'INGRESO_MANUAL',
    textKey: 'userProfile.balance.types.manualIngress',
    resourceType: 'icon',
    resource: 'icon-credit-card-icon',
  },
  INGRESO_PROMOCION: {
    id: 'INGRESO_PROMOCION',
    textKey: 'userProfile.balance.types.ingressPromotion',
    resourceType: 'icon',
    resource: 'icon-credit-card-icon',
  },
  INGRESO_EXTERNAL_PROMOTION: {
    id: 'INGRESO_EXTERNAL_PROMOTION',
    textKey: 'userProfile.balance.types.ingressExternalPromotion',
    resourceType: 'icon',
    resource: 'icon-credit-card-icon',
  },
  INGRESO_TRANSFERENCIA: {
    id: 'INGRESO_TRANSFERENCIA',
    textKey: 'userProfile.balance.types.ingressTransfer',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  PREMIO: {
    id: 'PREMIO',
    resourceType: 'image',
    assetTranslated: true,
    resource: 'assets/img/boletos/prize-icon.svg',
  },
  PRIZE: {
    id: 'PRIZE',
    textKey: 'userProfile.balance.types.prize1',
    resourceType: 'image',
    assetTranslated: true,
    resource: 'assets/img/boletos/prize-icon.svg',
  },
  PREMIO_GRUPO: {
    id: 'PREMIO_GRUPO',
    textKey: 'userProfile.balance.types.prizeGroup',
    resourceType: 'image',
    assetTranslated: true,
    resource: 'assets/img/boletos/prize-icon.svg',
  },
  JUGADA: {
    id: 'JUGADA',
    textKey: 'userProfile.balance.types.onePlay',
    resourceType: 'icon',
    resource: 'icon-juegos-icon',
  },
  RETIRADA: {
    id: 'RETIRADA',
    textKey: 'userProfile.balance.types.withdrawal',
    resourceType: 'icon',
    resource: 'icon-credit-card-icon',
  },
  RETIRADA_MANUAL: {
    id: 'RETIRADA_MANUAL',
    textKey: 'userProfile.balance.types.withdrawalManual',
    resourceType: 'icon',
    resource: 'icon-credit-card-icon',
  },
  ENVIO_CASA: {
    id: 'ENVIO_CASA',
    textKey: 'userProfile.balance.types.shippingHouse',
    resourceType: 'icon',
    resource: 'icon-enviar-icon',
  },
  TRANSFER_SEND: {
    id: 'TRANSFER_SEND',
    textKey: 'userProfile.balance.types.transferSend',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  TRANSFER_RECEIVE: {
    id: 'TRANSFER_RECEIVE',
    textKey: 'userProfile.balance.types.transferReceive',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  DEVOLUCION: {
    id: 'DEVOLUCION',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  DEVOLUCION_INGRESO: {
    id: 'DEVOLUCION_INGRESO',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  RETIRADA_DENEGADA: {
    id: 'RETIRADA_DENEGADA',
    resourceType: 'icon',
    resource: 'icon-credit-card-icon',
  },
  RETURN_ADELANTO_PENYA: {
    id: 'RETURN_ADELANTO_PENYA',
    textKey: 'userProfile.balance.types.returnAdvancementPenya',
    resourceType: 'icon',
    resource: 'icon-donate-icon',
  },
  TRANSFER_PREMIO_PENYA: {
    id: 'TRANSFER_PREMIO_PENYA',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  RETURN_ENVIO_CASA: {
    id: 'RETURN_ENVIO_CASA',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  INGRESO_CANJEO_PAPELETA: {
    id: 'INGRESO_CANJEO_PAPELETA',
    resourceType: 'icon',
    resource: 'icon-credit-card-icon',
  },
  FRAUDE: {
    id: 'FRAUDE',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  PLAY: {
    id: 'PLAY',
    resourceType: 'icon',
    resource: 'icon-juegos-icon',
  },
  TRANSFER_MONEY: {
    id: 'TRANSFER_MONEY',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
  WITHDRAW_MONEY_SUMMARY: {
    id: 'WITHDRAW_MONEY_SUMMARY',
    resourceType: 'icon',
    resource: 'icon-bank-icon',
  },
};

const MONEY_ACTIVITY_TYPES_KEYS = Object.keys(MONEY_ACTIVITY_TYPES);

enum MoneyActivityExcludedType {
  PREMIO_TRANSFER = 'PREMIO_TRANSFER',
  RETURN_PRIZE = 'RETURN_PRIZE',
  TRANSFER_GROUPPRIZE_TOMEMBER_SUMMARY = 'TRANSFER_GROUPPRIZE_TOMEMBER_SUMMARY',
  INGRESO_RETIRABLE = 'INGRESO_RETIRABLE',
  RETIRADA_POR_REASIGNACION_TRANSFER = 'RETIRADA_POR_REASIGNACION_TRANSFER',
}

const DEFAULT_MONEY_ACTIVITY: MoneyActitityType = {
  id: 'DEFAULT',
  textKey: 'userProfile.balance.types.movement',
  resourceType: 'icon',
  resource: 'icon-credit-card-icon',
};

export class MoneyActivityTypes {
  static get(typeId: string): MoneyActitityType {
    return MONEY_ACTIVITY_TYPES[typeId] || DEFAULT_MONEY_ACTIVITY;
  }

  static typesKeys(): Array<string> {
    return MONEY_ACTIVITY_TYPES_KEYS;
  }

  static excludedTypes(): Array<string> {
    return Object.values(MoneyActivityExcludedType);
  }

  static allPossibleTypes(): Array<string> {
    return [
      ...MoneyActivityTypes.typesKeys(),
      ...MoneyActivityTypes.excludedTypes(),
    ];
  }
}
