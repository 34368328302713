import {AnimationEvent} from '@angular/animations';
import {Injectable} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {Observable} from 'rxjs';

import {SlidableModalAnimationService} from './slidable-modal-animation.service';

@Injectable()
export class SlidableModalAnimation {
  private animationDone: boolean;

  constructor(private slidableModalService: SlidableModalAnimationService) {}

  animate(): Observable<void> {
    this.animationDone = false;
    return this.slidableModalService.waitForAnimationTurn();
  }

  transitionDone(outlet: RouterOutlet, event: AnimationEvent): void {
    if (
      (['right', 'left', 'top', 'bottom', 'slideOutToBottom'].includes(
        event.toState,
      ) ||
        outlet.activatedRouteData.position === undefined) &&
      !this.animationDone
    ) {
      this.animationDone = true;
      this.slidableModalService.animationFinished();
    }
  }
}
