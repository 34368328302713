import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {FingerprintStrategy} from './fingerprint/model';
import {DeviceOs} from './deviceOs';
import {DeviceService} from './device.service';

@Injectable()
export class DeviceUAParserService implements DeviceService {
  constructor(
    protected fingerprint: FingerprintStrategy,
    protected parser: any,
    protected window: Window,
  ) {}

  get isSafari(): boolean {
    return !!this.getBrowser()?.includes('Safari');
  }

  get isChrome(): boolean {
    const browser = this.getBrowser();
    return (
      typeof browser === 'string' &&
      browser.includes('Chrome') &&
      !browser.includes('WebView')
    );
  }

  get isFirefox(): boolean {
    return !!this.getBrowser()?.includes('Firefox');
  }

  getBrowser(): string {
    return this.parser.browser.name;
  }

  getBrowserVersion(): string {
    return this.parser.browser.version;
  }

  getOS(): DeviceOs {
    return this.parser.os.name;
  }

  getOSVersion(): string {
    return this.parser.os.version;
  }

  getBrowserFingerprint(): Observable<string> {
    return this.fingerprint.generate();
  }

  getPixelRatio(): number {
    return this.window.devicePixelRatio;
  }

  getModel(): Observable<string> {
    return of(this.parser.device.model);
  }

  isSamsungDevice(): Observable<boolean> {
    return of(this.parser.device?.vendor?.toLowerCase() === 'samsung');
  }

  toString(): string {
    return (
      `${this.getBrowser()} (${this.getBrowserVersion()})` +
      ` running over ${this.getOS()} (${this.getOSVersion()})`
    );
  }

  isAndroidVersion7OrLower(): Observable<boolean> {
    const os = this.getOS();
    const osVersion = this.getOSVersion();
    const isAndorid = os.includes('Android');
    return of(isAndorid && osVersion && parseInt(osVersion, 10) <= 7);
  }
}
