import {Serializable, SerializableProperty, SerializableType} from 'common';

import {BetValue, DigitBetValue} from './bet-value';

function betValueFactory(bet: any) {
  if (bet.hasOwnProperty('digitGroups') || bet.hasOwnProperty('digitGroupsValues')) {
    return DigitBetValue;
  } else {
    return BetValue;
  }
}

class ConstrainedPotentialPrizeInternal {
  base: number;

  /**
   * The bet could be complete (valid) or incomplete, they should be ordered
   * from more to less specific as they are matched in order
   */
  @SerializableProperty(
    type => betValueFactory(type),
    SerializableType.COLLECTION,
    true,
  )
  bet: Array<BetValue>;

  /**
   * The 'base' prize corresponds to a bet with this multiplier, if the
   * multiplier is higher the prize must be scaled too, 1 by default
   */
  baseMultiplier: number;
}

export class ConstrainedPotentialPrize extends Serializable(
  ConstrainedPotentialPrizeInternal,
) {
  static createFromBackend(obj: any) {
    const prize = new ConstrainedPotentialPrize();
    prize.base = obj.potentialPrize;
    prize.bet = obj.bets.map(bet => betValueFactory(bet).createFromBackend(bet));
    prize.baseMultiplier = obj.baseMultiplier || 1;

    return prize;
  }
}
