import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {Toggleable, toggleableConfig} from 'common';
import {Observable} from 'rxjs';

import {BoothsFavoriteViewService} from '../../booths-favorite-view.service';
import {LotteryBooth} from '../../data/lottery-booth';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-lottery-booth-item',
  templateUrl: './booth-item.component.html',
  styleUrls: ['./booth-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [toggleableConfig(LotteryBoothItemComponent)],
})
export class LotteryBoothItemComponent extends Toggleable implements OnChanges {
  @Input()
  lotteryBooth: LotteryBooth;

  @Input()
  showDistance = true;

  @Input()
  enableToggle = true;

  @Input()
  showMenu = false;

  @Input()
  enableIconFav = true;

  @Output()
  toggleMenu = new EventEmitter<void>();

  get fullAddress(): string {
    return `${this.lotteryBooth?.address ?? ''} ${this.lotteryBooth?.city ?? ''} ${
      this.lotteryBooth.state ? this.lotteryBooth.state : ''
    }`;
  }

  get favoriteBooth(): Observable<string> {
    return this.boothsFavoriteViewService.favoriteBooth;
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    private boothsFavoriteViewService: BoothsFavoriteViewService,
  ) {
    super(cdr);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('lotteryBooth') && this.toggleState) {
      this.close();
    }
  }

  @HostListener('click')
  toggleDescription() {
    if (this.enableToggle) {
      if (this.toggleState) {
        this.close();
      } else {
        this.open();
      }
    }
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }
}
