import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {UtilModule} from '../../util/util-module';

import {DistanceDisplayComponent} from './distance-display.component';

@NgModule({
  imports: [CommonModule, UtilModule],
  exports: [DistanceDisplayComponent],
  declarations: [DistanceDisplayComponent],
})
export class DistanceDisplayModule {}
