import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {padding} from '../util/core/number';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input()
  value: number;

  currentValue: number;

  @Input()
  showHours = true;

  @Input()
  separator = ':';

  @Output()
  finish = new EventEmitter<any>();

  viewValue: string;

  timer: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.value) {
      this.currentValue = this.value;
      this.setViewValue();
      this.timer = setInterval(() => {
        this.setViewValue();
        if (this.currentValue === 0) {
          this.cancel();
          this.finish.emit();
        }
        this.currentValue--;
        this.cdr.markForCheck();
      }, 1000);
    } else {
      this.viewValue = '00' + this.separator + '00' + this.separator + '00';
    }
  }

  reset() {
    this.cancel();
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.cancel();
  }

  cancel() {
    clearTimeout(this.timer);
    this.timer = null;
  }

  private setViewValue(): void {
    const hours = Math.floor(this.currentValue / 3600);
    const minutes = Math.floor((this.currentValue % 3600) / 60);
    const remainingSeconds = this.currentValue % 60;

    let formattedTime = '';

    if (hours > 0) {
      formattedTime += padding(hours, 2) + this.separator;
    }

    formattedTime += padding(minutes, 2) + this.separator;
    formattedTime += padding(remainingSeconds, 2);

    this.viewValue = formattedTime;
  }
}
